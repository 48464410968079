var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { InfoService } from '../info.service';
import { ToastrService } from 'ngx-toastr';
import { Info } from '../../models/info';
import { NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProduitService } from '../../produit/produit.service';
var PageCreateInfoComponent = /** @class */ (function (_super) {
    __extends(PageCreateInfoComponent, _super);
    function PageCreateInfoComponent(store, httpSv, infoService, route, router, toastr, i18n, produitService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.infoService = infoService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.produitService = produitService;
        _this.fb = fb;
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            titre: 'Titre',
            contenu: 'Contenu',
            url: 'Url',
            dateExpiration: 'Date Validité',
            actif: 'Actif'
        };
        _this.info = new Info();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des informations',
                    route: 'list-infos'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateInfoComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.info) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateInfoComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier une info ' : ' Créer une info ';
    };
    PageCreateInfoComponent.prototype.isEditing = function () {
        return (this.info.id != null);
    };
    PageCreateInfoComponent.prototype.initReactiveForm = function () {
        this.infoForm = this.fb.group({
            titre: ['', Validators.required],
            contenu: ['', [Validators.required, Validators.minLength(2)]],
            url: [''],
            actif: [true]
        });
    };
    PageCreateInfoComponent.prototype.getInfo = function (jsonValue) {
        return Info.fromJSON(jsonValue);
    };
    PageCreateInfoComponent.prototype.setReactiveForm = function () {
        if (this.info) {
            this.infoForm.patchValue({
                titre: this.info.titre,
                contenu: this.info.contenu,
                url: this.info.url,
                statut: this.info.statut,
                actif: this.info.actif
            });
        }
    };
    PageCreateInfoComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.infoService.getURL(_this.selectedId), 'info', 'info', 'setLoaded');
            }
            else {
                _this.info = new Info();
                _this.initReactiveForm();
            }
        });
    };
    PageCreateInfoComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateInfoComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-info']);
    };
    PageCreateInfoComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-info']);
    };
    PageCreateInfoComponent.prototype.mapFormValuesToModel = function () {
        this.info.titre = this.infoForm.value.titre;
        this.info.contenu = this.infoForm.value.contenu;
        this.info.url = this.infoForm.value.url;
        this.info.actif = this.infoForm.value.actif;
    };
    PageCreateInfoComponent.prototype.update = function () {
        var _this = this;
        if (this.infoForm.valid) {
            this.mapFormValuesToModel();
            this.infoService.update(this.info.id, this.info)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-info']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateInfoComponent.prototype.create = function () {
        var _this = this;
        if (this.infoForm.valid) {
            this.mapFormValuesToModel();
            this.infoService.create(this.info)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie  ', '', { timeOut: 2000 });
                _this.infoForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateInfoComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateInfoComponent;
}(BasePageComponent));
export { PageCreateInfoComponent };
