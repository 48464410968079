var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { MarcheService } from '../marche/marche.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { ValiderOffreVenteService } from './validerOffreVente.service';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { ExcelService } from '../../../services/sharedServices/excel-service.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
var ValiderOffreVenteComponent = /** @class */ (function (_super) {
    __extends(ValiderOffreVenteComponent, _super);
    function ValiderOffreVenteComponent(store, httpSv, marcheService, route, authService, router, toastr, fb, i18n, validerOffreVenteService, modal) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.marcheService = marcheService;
        _this.route = route;
        _this.authService = authService;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.validerOffreVenteService = validerOffreVenteService;
        _this.modal = modal;
        _this.causeRejet = '';
        _this.dateValidation = new Date();
        _this.outData = [];
        /******************/
        _this.isAllDisplayDataChecked = false;
        _this.isOperating = false;
        _this.isIndeterminate = false;
        _this.listOfDisplayData = [];
        _this.listOfAllDataOffreVente = [];
        _this.mapOfCheckedId = {};
        _this.numberOfChecked = 0;
        _this.pageData = {
            title: '',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        return _this;
    }
    /******************************/
    ValiderOffreVenteComponent.prototype.closeModal = function () {
        this.causeRejet = '';
        this.modal.close();
    };
    ValiderOffreVenteComponent.prototype.validerRejet = function () {
        if (this.causeRejet > '') {
            this.modal.close();
            this.rejeter();
        }
        else {
            this.toastr.error('Vous devez saisir une cause de rejet ');
        }
    };
    ValiderOffreVenteComponent.prototype.openModal = function (body, header, footer, options) {
        var _this = this;
        if (header === void 0) { header = null; }
        if (footer === void 0) { footer = null; }
        if (options === void 0) { options = null; }
        this.outData = [];
        this.listOfAllDataOffreVente.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        }
        else {
            this.toastr.info('Vous devez sélectionner au moins une offre ');
        }
    };
    ValiderOffreVenteComponent.prototype.valider = function () {
        var _this = this;
        this.outData = [];
        this.listOfAllDataOffreVente.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                console.log('item', item);
                _this.outData.push(item);
            }
        });
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresVente: this.outData
            } };
        if (this.outData.length > 0) {
            this.validerOffreVenteService.valider(this.data).subscribe(function (data) {
                _this.toastr.success('Validation réussie des offres d\'vente ', '', { timeOut: 2000 });
                _this.loadOffreVente();
            }, function (error) { return console.log(error); });
        }
        else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    };
    ValiderOffreVenteComponent.prototype.rejeter = function () {
        var _this = this;
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresVente: this.outData,
                causeRejet: this.causeRejet
            } };
        this.validerOffreVenteService.rejeter(this.data).subscribe(function (data) {
            _this.toastr.success('rejet réussi des offres de vente  sélectionnés', '', { timeOut: 2000 });
            _this.loadOffreVente();
        }, function (error) { return console.log(error); });
    };
    ValiderOffreVenteComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadOffreVente();
    };
    ValiderOffreVenteComponent.prototype.loadOffreVente = function () {
        var _this = this;
        this.listOfAllDataOffreVente = [];
        var reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerOffreVenteService.getAllByReseau(reseauId.toString()).subscribe(function (data) {
            _this.offresAValider = data;
            _this.offresAValider.forEach(function (offreVente, index, array) {
                _this.listOfAllDataOffreVente.push({
                    id: offreVente.id,
                    date: offreVente.date,
                    nom_produit: offreVente.nom_produit,
                    quantite: offreVente.quantite,
                    nom_qualite: offreVente.nom_qualite,
                    nom_auteur: offreVente.nom_auteur,
                    reseau: offreVente.reseau,
                    mesure: offreVente.mesure,
                    telephone: offreVente.telephone,
                    montant: offreVente.montant,
                    prixUnitaire: offreVente.prixUnitaire,
                    email: offreVente.email,
                    date_expiration: offreVente.date_expiration
                });
            });
        });
        this.mapOfCheckedId = {};
        this.refreshStatus();
    };
    ValiderOffreVenteComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ValiderOffreVenteComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    };
    ValiderOffreVenteComponent.prototype.refreshStatus = function () {
        var _this = this;
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(function (item) { return _this.mapOfCheckedId[item.id]; });
        this.isIndeterminate =
            this.listOfDisplayData.some(function (item) { return _this.mapOfCheckedId[item.id]; }) &&
                !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataOffreVente.filter(function (item) { return _this.mapOfCheckedId[item.id]; }).length;
    };
    ValiderOffreVenteComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfDisplayData.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = value); });
        this.refreshStatus();
    };
    ValiderOffreVenteComponent.prototype.operateData = function () {
        var _this = this;
        this.isOperating = true;
        setTimeout(function () {
            _this.listOfAllDataOffreVente.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = false); });
            _this.refreshStatus();
            _this.isOperating = false;
        }, 1000);
    };
    ValiderOffreVenteComponent.prototype.exportByExcel = function () {
        ExcelService.exportAsExcelFile(this.offresAValider, 'offres_vente_a_valider');
    };
    return ValiderOffreVenteComponent;
}(BasePageComponent));
export { ValiderOffreVenteComponent };
