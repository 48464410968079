<div class="col-sm-8">

<ng-container *ngIf="administrateur && genres && marchesAdapter.length>0  && reseauxAdapter.length>0 && depotsAdapter.length>0">
    <form  [formGroup]="userForm"  novalidate (ngSubmit)="onSubmit()">

        <tc-card [title]="'IDENTIFICATION'" >
            <div class="row">
                <div class="col-sm-6">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.mobilePhone}} </tc-form-label>
                        <tc-input type="text" [placeholder]="'mobilePhone'" formControlName="mobilePhone" (keydown)="mobilePhoneKeyUp.next($event)">
                        </tc-input>
                        <ng-container *ngIf="userForm.get('mobilePhone').invalid && (userForm.get('mobilePhone').touched || userForm.get('mobilePhone').dirty)">
                            <span @fadeIn class="error" *ngIf="userForm.get('mobilePhone').getError('required')">Veuillez saisir le Numéro Mobile</span>
                            <span @fadeIn class="error" *ngIf="userForm.get('mobilePhone').getError('alreadyExist')">Le compte existe déjà !!</span>
                            <span @fadeIn class="error" *ngIf="userForm.get('mobilePhone').getError('pattern')">Veuillez vérifier que le numéro de téléphone est un numéro valide</span>

                         </ng-container>
                    </tc-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.nom}} </tc-form-label>
                        <tc-input type="text" [placeholder]="'Nom '" formControlName="nom" >
                        </tc-input>
                        <ng-container *ngIf="userForm.get('nom').invalid && (userForm.get('nom').touched || userForm.get('nom').dirty)">
                            <span @fadeIn class="error" *ngIf="userForm.get('nom').getError('required')">Veuillez saisir le nom</span>
                            <span @fadeIn class="error" *ngIf="userForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                        </ng-container>

                    </tc-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.prenom}}  </tc-form-label>
                        <tc-input  type="text" [placeholder]="'Prénom (s) '" formControlName="prenom">
                        </tc-input>
                        <ng-container *ngIf="userForm.get('prenom').invalid && (userForm.get('prenom').touched || userForm.get('prenom').dirty)">
                            <span @fadeIn class="error" *ngIf="userForm.get('prenom').getError('required')">Veuillez saisir votre prénom</span>
                            <span @fadeIn class="error" *ngIf="userForm.get('prenom').getError('min')">Le prénom doit avoir plus de 2 caractères</span>
                        </ng-container>
                    </tc-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.genre}}  </tc-form-label>
                        <tc-select [placeholder]="'Genre'" [options]="genres" formControlName="genre" [selected]="userForm.get('genre').value"></tc-select>

                    </tc-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.email}} </tc-form-label>
                        <tc-input [prefixIcon]="'icofont-ui-email'" type="text" formControlName="email" >
                        </tc-input>
                        <ng-container *ngIf="userForm.get('email').invalid && (userForm.get('email').touched || userForm.get('email').dirty)">
                            <span @fadeIn class="error" *ngIf="userForm.get('email').getError('email')">Vous devez saisir un email valide</span>
                        </ng-container>
                    </tc-form-group>
                </div>
            </div>
            <ng-container *ngIf="reseauxAdapter.length>0 ">
            <div class="row">
                <div class="col-sm-6">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.reseau}}</tc-form-label>

                        <tc-select [options]="reseauxAdapter"  formControlName="reseau" (ngModelChange)="onSelectReseau($event)"  ></tc-select>
                    </tc-form-group>
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="groupesAdapter.length>0">
                <div class="row">
                    <div class="col-sm-6">
                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.groupes}}</tc-form-label>
                            <tc-select [options]="groupesAdapter" [filter]="true" [placeholder]="'Veuillez s\électionner les groupes'"  [multiple]="true"  [allowClear]="true"   formControlName="groupes" ></tc-select>
                        </tc-form-group>
                    </div>
                </div>
        </ng-container>
            <ng-container *ngIf="marchesAdapter.length>0">
                <div class="row">
                    <div class="col-sm-12">

                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.marchespreference}}</tc-form-label>
                            <tc-select [options]="marchesAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="marchePreference" ></tc-select>
                        </tc-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">

                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.marchesuivis}}</tc-form-label>
                            <tc-select [options]="marchesAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="marcheSuivi" ></tc-select>
                        </tc-form-group>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="depotsAdapter.length>0">
                <div class="row">
                    <div class="col-sm-12">

                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.depotPreference}}</tc-form-label>
                            <tc-select [options]="depotsAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="depotPreference" ></tc-select>
                        </tc-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12">

                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.depotSuivi}}</tc-form-label>
                            <tc-select [options]="depotsAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="depotSuivi" ></tc-select>
                        </tc-form-group>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="communeTree.length>0">
                <div class="row">
                    <div class="col-sm-12">
                <tc-form-group>
                    <tc-form-label>{{Columnslabels.commune}}</tc-form-label>
                    <nz-cascader [nzOptions]="communeTree"
                                 [nzShowSearch]="true"
                                 [nzAutoFocus]="true"
                                 [nzSize]="'large'"
                                 formControlName="commune">

                    </nz-cascader>
                </tc-form-group>
                    </div>
                </div>
            </ng-container>

            <div class="row">
                <div class="col-12 col-sm-2">
                    <tc-form-group>
                        <tc-checkbox formControlName="enabled" >Actif</tc-checkbox>
                    </tc-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-sm-12">
                    <span *ngIf="!userForm.valid && userForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                    <button tc-button type="submit" [block]="true" >
                        Valider
                    </button>
                </div>
            </div>
<!--            <div class="row acontent">-->
<!--                <div class="col-12 col-sm-12">-->
<!--                             <a  (click)="resetPassword()" > Réinitialiser le mot de passe  </a>-->
<!--                </div>-->
<!--            </div>-->
        </tc-card>
    </form>


</ng-container>
</div>
