<ng-container *ngIf="secteurActivite ">
	<div class="col-sm-8">
		<tc-card [title]="''">
			<form [formGroup]="activiteForm"   (ngSubmit)="onSubmit()">


				<tc-form-group>
					<tc-form-label>{{Columnslabels.code}} </tc-form-label>
					<tc-input type="text"  formControlName="code" >
					</tc-input>
					<ng-container *ngIf="activiteForm.get('code').invalid && (activiteForm.get('code').touched || activiteForm.get('code').dirty)">
						<span @fadeIn class="error" *ngIf="activiteForm.get('code').getError('required')">Veuillez saisir le code du marché</span>
						<span @fadeIn class="error" *ngIf="activiteForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
					</ng-container>
				</tc-form-group>

				<tc-form-group>
					<tc-form-label>{{Columnslabels.label}}</tc-form-label>
					<tc-input type="text"  formControlName="nom">
					</tc-input>
					<ng-container *ngIf="activiteForm.get('nom').invalid && (activiteForm.get('nom').touched || activiteForm.get('nom').dirty)">
						<span @fadeIn class="error" *ngIf="activiteForm.get('nom').getError('required')">Veuillez saisir le nom de la activite</span>
						<span @fadeIn class="error" *ngIf="activiteForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
					</ng-container>
				</tc-form-group>

				<tc-form-group>
					<tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
				</tc-form-group>

				<span *ngIf="!activiteForm.valid && activiteForm.touched" class="help-block">Veuillez entrer des données valides !</span>
				<button [disabled]="!activiteForm.valid" tc-button [block]="true" >
					Valider
				</button>




			</form>

		</tc-card>
	</div>
</ng-container>

