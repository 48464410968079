/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./switcher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./switcher.component";
var styles_TCSwitcherComponent = [i0.styles];
var RenderType_TCSwitcherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCSwitcherComponent, data: {} });
export { RenderType_TCSwitcherComponent as RenderType_TCSwitcherComponent };
export function View_TCSwitcherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "tc-switcher-wrap"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switch(_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "tc-switcher-handle"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["class", "switcher-input"], ["type", "checkbox"]], [[8, "value", 0], [1, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "check-detector"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "tc-switcher-label"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStyles(); _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.getDetectorColor(); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.getLabelColor(); _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.value; var currVal_2 = _co.value; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_TCSwitcherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tc-switcher", [], [[2, "tc-switcher", null], [2, "checked", null], [2, "disabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_TCSwitcherComponent_0, RenderType_TCSwitcherComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.TCSwitcherComponent]), i1.ɵdid(2, 114688, null, 0, i4.TCSwitcherComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 2).focus; var currVal_2 = i1.ɵnov(_v, 2).disabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var TCSwitcherComponentNgFactory = i1.ɵccf("tc-switcher", i4.TCSwitcherComponent, View_TCSwitcherComponent_Host_0, { disabled: "disabled", label: "label", name: "name", _value: "value", bgColor: "bgColor", borderColor: "borderColor", color: "color", labelColor: "labelColor" }, { valueChanged: "valueChanged" }, ["*"]);
export { TCSwitcherComponentNgFactory as TCSwitcherComponentNgFactory };
