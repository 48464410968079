import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import {environment} from '../../../environments/environment';
import {Acteur} from '../../pages/ui/models/acteur';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  constructor(private router: Router,
              private authService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const connectedUser = Acteur.fromJSON(this.authService.currentUserDataObject);

    if (connectedUser !== null) {

      if (connectedUser.isGroupeAdmin || connectedUser.isAnimateur ) {
        return true;
      } else {
        this.router.navigate(['/error/506']);
        return false;
      }
    } else {
      this.router.navigate(['/public/sign-in']);
      return false;
    }

  }

}
