import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import {Store} from '@ngrx/store';
import {BasePageComponent} from '../../base-page';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {DashboardService} from '../dashboard/dashboard.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {jqxDataTableComponent} from 'jqwidgets-ng/jqxdatatable';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';
import {IOption} from '../../../ui/interfaces/option';
import {ReseauService} from '../../ui/reseau/reseau.service';
import {AlerteService} from './alerte.service';
import {SMSService} from './smsService.service';
import {OperateurService} from './operateur.service';
import DataTableExportSettings = jqwidgets.DataTableExportSettings;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-rapports-sms',
  templateUrl: './rapportsSMS.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PageRapportsSMSComponent extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit {
  alertes: any[] = [];
  destinations = [{id: 0, nom: 'SMS Sortant (MT)'}, {id: 1, nom: 'SMS Entrant (MO)'}, {id: 2, nom: 'Tous'}];
  operateursAdapter: IOption[] = [];
  destinationsAdapter: IOption[] = [];
  alertesAdapter: IOption[] = [];
  reseaux: any[] = [];
  operateurs: any[] = [];
  reseauxAdapter: IOption[] = [];
  rapportsForm: FormGroup;
  constructor(
    store: Store<IAppState>, private dashboardService: DashboardService,
    httpSv: HttpService, private smsService: SMSService,
    private reseauService: ReseauService, private operateurService: OperateurService,
     private alerteService: AlerteService, private fb: FormBuilder
  ) {
    super(store, httpSv);
    this.pageData = {
      title: 'JOURNAL DES SMS',
      breadcrumbs: [
      ],
    };
  }
  @ViewChild('myDataTable', { static: false }) myDataTable: jqxDataTableComponent;
  @ViewChild('dateDebut', { static: false }) dateDebut: jqxDateTimeInputComponent;
  @ViewChild('dateFin', { static: false }) dateFin: jqxDateTimeInputComponent;
  @ViewChild('log', { static: false }) log; ElementRef;
  Columnslabels =
      {
        dateDebut: 'Date début',
        dateFin: 'Date Fin'
      };
  source: any =
      { localData: []
      };
  dataAdapter: any;
  localizationObject = this.getLocalization();
  exportSettings = this.getExportSettings();
  columns: any[] =
      [
        { text: 'Réseau', dataField: 'reseau', width: '10%', cellsAlign: 'left', align: 'left' },
        { text: 'Emetteur/Recepteur', dataField: 'acteur', width: '14%', cellsAlign: 'left', align: 'left'},
        { text: 'Message', dataField: 'message', width: '20%', cellsAlign: 'left', align: 'left' },
        { text: 'Direction', dataField: 'direction', width: '8%', cellsAlign: 'left', align: 'left' },
        { text: 'Date', dataField: 'date', width: '12%', align: 'left', cellsAlign: 'left', cellsFormat: 'd' },
        { text: 'Origine', dataField: 'fromPhone', align: 'center', cellsAlign: 'center', cellsFormat: 'd', width: '10%'  },
        { text: 'Destination', dataField: 'toPhone', align: 'center', cellsAlign: 'center', width: '10%'  },
        { text: 'Alerte', dataField: 'alerte', align: 'center', cellsAlign: 'center', width: '8%'  },
        { text: 'Opérateur', dataField: 'operateur', align: 'center', cellsAlign: 'center', width: '8%'  }
      ];
    getExportSettings(): DataTableExportSettings {
        const exportObj: DataTableExportSettings  = {};
        exportObj.columnsHeader = true;
        exportObj.hiddenColumns = false;
        exportObj.serverURL = null;
        exportObj.characterSet = null;
        exportObj.recordsInView = true;
        exportObj.fileName = 'rapport_sms';
        return exportObj;
    }
  getLocalization(): any {
    const localizationobj: any = {};
    localizationobj.pagerGoToPageString = 'Aller à la page:';
    localizationobj.pagerShowRowsString = 'Voir ligne:';
    localizationobj.pagerRangeString = ' de ';
    localizationobj.pagerNextButtonString = 'Suivant';
    localizationobj.pagerFirstButtonString = 'Premier';
    localizationobj.pagerLastButtonString = 'Dernier';
    localizationobj.pagerPreviousButtonString = 'Précedent';
    localizationobj.sortAscendingString = 'Ordre Ascendent';
    localizationobj.sortDescendingString = 'Ordre Descendant';
    localizationobj.sortRemoveString = 'Supprimer';
    localizationobj.emptydatastring = 'Pas de données';
    localizationobj.firstDay = 1;
    localizationobj.percentSymbol = '%';
    localizationobj.currencySymbol = '';
      localizationobj.currencySymbolPosition = 'après';
      localizationobj.decimalSeparator = ',';
      localizationobj.thousandsSeparator = ' ';
      localizationobj.filterapplystring = 'Appliquer';
      localizationobj.filtercancelstring = 'Annuler';
      localizationobj.filterclearstring = 'Effacer Filtre';
      localizationobj.filterstring =  'Avancé';
      localizationobj.filtersearchstring = 'Chercher';
      localizationobj.filterstringcomparisonoperators = ['vide', 'non vide', 'contient', 'contient(respecte la casse)',
          'Ne contient pas', 'Ne contient pas(respecte la casse)', 'commence par', 'commence par(respecte la casse)',
          'finit par', 'finit par(respecte la casse)', 'égale', 'égale(respecte la casse)', 'null', 'non null'];
      // tslint:disable-next-line:max-line-length
      localizationobj.filternumericcomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
      // tslint:disable-next-line:max-line-length
      localizationobj.filterdatecomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
      localizationobj.filterbooleancomparisonoperators = ['égale', 'non égale'];
      localizationobj.validationstring = 'La valeur entrée n\'est pas valide ';
      localizationobj.emptydatastring =  'Pas de données à afficher';
      localizationobj.filterselectstring =  'Selectionnez un filtre';
      localizationobj.loadtext = 'Chargement...';
      localizationobj.clearstring = 'Effacer';
      localizationobj.todaystring = 'Aujourd\'hui';
      // tslint:disable-next-line:max-line-length
      localizationobj.loadingerrormessage = 'Chargement en cours des données vous ne pouvez pas mettre à jour les propriétés ou appeler une méthode . Vous pourrez le faire après le chargement des données.';
    localizationobj.decimalSeparator = ',';
    localizationobj.thousandsSeparator = ' ';
    localizationobj.days = {
      // full day names
      names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      // abbreviated day names
      namesAbbr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      // shortest day names
      namesShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
    };

    localizationobj.patterns = {
      d: 'dd/MM/yyyy',
      D: 'dddd, d. MMMM yyyy',
      t: 'HH:mm',
      T: 'HH:mm:ss',
      f: 'dddd, d. MMMM yyyy HH:mm',
      F: 'dddd, d. MMMM yyyy HH:mm:ss',
      M: 'dd MMMM',
      Y: 'MMMM yyyy'
    };
    localizationobj.months = {
      // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
      names: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre', ''],
      // abbreviated month names
      namesAbbr: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
    };
    return localizationobj;
  }
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
        return '95%';
    }

    return 850;
  }
  excelExport(): void {
    this.myDataTable.exportData('xls');
  }
  jsonExport(): void {
    this.myDataTable.exportData('json');
  }  pdfExport(): void {
    this.myDataTable.exportData('pdf');
  }
  printBtnOnClick(): void {
    const gridContent = this.myDataTable.exportData('html');
    const newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>ETAT JOURNAL DES SMS</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
    document.write(pageContent);
    document.close();
    newWindow.print();
  }
  onReseauxPromise() {
    this.reseauxAdapter = [];
    for (const reseau of this.reseaux) {
      this.reseauxAdapter.push({'value' : reseau.id.toString(), 'label' : reseau.code});
    }
  }
    onAlertesPromise() {
        this.alertesAdapter = [];
        for (const alerte of this.alertes) {
            this.alertesAdapter.push({'value' : alerte.id.toString(), 'label' : alerte.libelle});
        }
    }
    onOperateursPromise() {
        this.operateursAdapter = [];
        for (const operateur of this.operateurs) {
            this.operateursAdapter.push({'value' : operateur.id.toString(), 'label' : operateur.nom});
        }
    }
  ngOnInit() {
    super.ngOnInit();

      for (const destination of this.destinations) {
          this.destinationsAdapter.push({'value' : destination.id.toString(), 'label' : destination.nom});
      }

    this.reseauService.getCached().subscribe(data => {
          this.reseaux = data;
          this.onReseauxPromise();
        }
    );
      this.alerteService.getCached().subscribe(data => {
              this.alertes = data;
              this.onAlertesPromise();
          }
      );
      this.operateurService.getCached().subscribe(data => {
              this.operateurs = data;
              this.onOperateursPromise();
          }
      );
      this.initReactiveForm();

      this.setLoaded();

  }
  initReactiveForm() {
    this.rapportsForm = this.fb.group({
          alerte: '',
          operateur: '',
          reseau: '',
          destination: ''
        }
    ) ;
  }
  ngAfterViewInit(): void {
    this.dateDebut.culture('fr-FR');
    this.dateFin.culture('fr-FR');
    const pdateDebut = new Date();
    pdateDebut.setFullYear(pdateDebut.getFullYear(), 0, 1);
    const pdateFin = new Date();
    pdateFin.setFullYear(pdateFin.getFullYear(), pdateFin.getMonth() , pdateFin.getDate());
    this.dateDebut.setDate(pdateDebut);
    this.dateFin.setDate(pdateFin);
      this.rapportsForm.get('reseau').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  console.log('called in reseau.change');
                  this.smsService.getJournalSMS(this.dateDebut.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('operateur').value, this.rapportsForm.get('alerte').value,
                      this.rapportsForm.get('reseau').value, this.rapportsForm.get('destination').value ).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });

      this.rapportsForm.get('operateur').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  console.log('called in operateur.change');
                  this.smsService.getJournalSMS(this.dateDebut.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('operateur').value, this.rapportsForm.get('alerte').value,
                      this.rapportsForm.get('reseau').value, this.rapportsForm.get('destination').value ).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });

      this.rapportsForm.get('alerte').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  console.log('called in alerte.change');
                  this.smsService.getJournalSMS(this.dateDebut.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('operateur').value, this.rapportsForm.get('alerte').value,
                      this.rapportsForm.get('reseau').value, this.rapportsForm.get('destination').value ).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.rapportsForm.get('destination').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {

                  this.smsService.getJournalSMS(this.dateDebut.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('operateur').value, this.rapportsForm.get('alerte').value,
                      this.rapportsForm.get('reseau').value, this.rapportsForm.get('destination').value ).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.dateFin.onValueChanged.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {

                  this.smsService.getJournalSMS(this.dateDebut.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('operateur').value, this.rapportsForm.get('alerte').value,
                      this.rapportsForm.get('reseau').value, this.rapportsForm.get('destination').value ).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.dateDebut.onValueChanged.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.smsService.getJournalSMS(this.dateDebut.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('operateur').value, this.rapportsForm.get('alerte').value,
                      this.rapportsForm.get('reseau').value, this.rapportsForm.get('destination').value ).subscribe(datas => {
                          this.source.localData = datas;
                          console.log('datas : ', datas);
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.smsService.getJournalSMS(this.dateDebut.getDate().toLocaleDateString(),
          // tslint:disable-next-line:max-line-length
          this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('operateur').value, this.rapportsForm.get('alerte').value,
          this.rapportsForm.get('reseau').value, this.rapportsForm.get('destination').value ).subscribe(datas => {
              this.source.localData = datas;
              console.log('datas : ', datas);
              this.dataAdapter = new jqx.dataAdapter(this.source);
          }
      );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }



}
