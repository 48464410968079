import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'partners-logo',
  templateUrl: 'partnersLogo.component.html',
  styleUrls: ['partnersLogo.component.scss']
})
export class PartnersLogoComponent implements OnInit {
  //
  // @HostBinding('class.logo') true;
  @Input() src: string;
  @Input() width: number | string;
  @Input() height: number | string;
  @Input() alt: string;
  @Input() page: string;
  @Input() title: string;


  constructor() {
    this.width = 'auto';
    this.height = 'auto';
    this.alt = '';
  }

  ngOnInit() {

  }
}
