import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { CommuneService } from '../commune.service';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {Commune} from '../../models/commune';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {ProvinceService} from '../../province/province.service';
import {Province} from '../../models/province';
import {INoeud} from '../../produit/create';


@Component({
    selector: 'tc-commune-create',
    templateUrl: './create-commune.component.html',
    animations: [fadeIn]
})
export class PageCreateCommuneComponent extends BasePageComponent implements OnInit, OnDestroy {
    communeForm: FormGroup;
    commune: any;
    provinceTree: INoeud[] = [];
    provinces: Province[];
    provincesAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            province: 'Province',
            code: 'Code Commune',
            label: 'Libellé Commune',
            longitude: 'Longitude',
            latitude: 'Latitude',
            commune: 'Commune'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private communeService: CommuneService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private provinceService: ProvinceService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.commune = new Commune();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des communes',
                    route: 'list-commune'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.commune) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier une commune ' : ' Créer une commune ' ;
    }
    isEditing(): boolean {
        return (this.commune.id != null);
    }



    initReactiveForm() {
        this.communeForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                province: ['', Validators.required],
                longitude: [''],
                latitude: [''],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        if (this.commune) {
            this.communeForm.patchValue({
                code: this.commune.code,
                nom: this.commune.nom,
                province: this.commune.provinceTab,
                longitude: this.commune.longitude,
                latitude: this.commune.latitude,
                actif: this.commune.actif
            }
        );

        }
    }
    getCommune (jsonValue): Commune {
        return Commune.fromJSON(jsonValue) ;
    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.provinceService.getAllURL(), 'provinces', 'onProvincesPromise');
        this.provinceService.findAsTree().subscribe(tree => {
                this.provinceTree = tree;
            }
        );
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.communeService.getURL(this.selectedId), 'commune', 'commune', 'setLoaded');
            } else {
                this.commune = new Commune();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-commune']);
    }
    liste() {
        this.router.navigate(['/vertical/list-commune']);
    }
    mapFormValuesToModel() {
        this.commune.code =  this.communeForm.value.code;
        this.commune.nom =  this.communeForm.value.nom;
        this.commune.longitude =  this.communeForm.value.longitude;
        this.commune.latitude =  this.communeForm.value.latitude;
        this.commune.province =  this.communeForm.value.province[1];
        this.commune.actif =  this.communeForm.value.actif;
        console.log('this.commune.province', this.commune.province);
    }
    update(): void {
        if (this.communeForm.valid) {
            this.mapFormValuesToModel();
            this.communeService.update(this.commune.id, this.commune)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.router.navigate(['/vertical/list-commune']);
                    }
                    ,
                    error => console.log(error));
        }

    }
    onProvincesPromise() {
        for (const province of this.provinces) {
            this.provincesAdapter.push({'value' : province.id.toString(), 'label' : province.nom});
        }
        this.setReactiveForm();
    }
    create(): void {
        if (this.communeForm.valid) {
            this.mapFormValuesToModel();
            this.communeService.create(this.commune)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.communeForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
