import { OnInit } from '@angular/core';
var TCButtonComponent = /** @class */ (function () {
    function TCButtonComponent() {
        this.block = false;
        this.disabled = false;
        this.load = false;
        this.outline = false;
        this.align = 'center';
        this.size = 'md';
        this.view = 'default';
        this.iconAnimation = false;
    }
    Object.defineProperty(TCButtonComponent.prototype, "alignLeft", {
        get: function () {
            return this.align === 'left';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "alignRight", {
        get: function () {
            return this.align === 'right';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "sizeSm", {
        get: function () {
            return this.size === 'sm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "sizeLg", {
        get: function () {
            return this.size === 'lg';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "viewDefault", {
        get: function () {
            return this.view === 'default';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "viewAccent", {
        get: function () {
            return this.view === 'accent';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "viewInfo", {
        get: function () {
            return this.view === 'info';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "viewSuccess", {
        get: function () {
            return this.view === 'success';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "viewWarning", {
        get: function () {
            return this.view === 'warning';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "viewError", {
        get: function () {
            return this.view === 'error';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCButtonComponent.prototype, "gradientView", {
        get: function () {
            return this.view === 'gradient';
        },
        enumerable: true,
        configurable: true
    });
    TCButtonComponent.prototype.ngOnInit = function () { };
    return TCButtonComponent;
}());
export { TCButtonComponent };
