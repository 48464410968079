var Reseau = /** @class */ (function () {
    function Reseau() {
        this.actif = true;
        this.estFils = true;
        this.produits = [];
    }
    Reseau.fromJSON = function (json) {
        var result = new Reseau();
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    Reseau.listFromJSON = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var groupe = Reseau.fromJSON(jsonObject);
                jsonObject.produits = groupe.products;
                jsonObject.marches = groupe.marchesText;
                jsonObject.depots = groupe.depotText;
                jsonObject.mesures = groupe.mesureText;
                jsonObject.qualites = groupe.qualiteText;
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    Reseau.prototype.toString = function () {
        return this.nom;
    };
    Object.defineProperty(Reseau.prototype, "marchesText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.marches != null) {
                for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval += marche.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "mesureText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.mesures != null) {
                for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
                    var mesure = _a[_i];
                    rval += mesure.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "qualiteText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.qualites != null) {
                for (var _i = 0, _a = this.qualites; _i < _a.length; _i++) {
                    var qualite = _a[_i];
                    rval += qualite.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "isSIMBetail", {
        get: function () {
            return (this.typeSIM === 'SIMBetail');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "isDGPER", {
        get: function () {
            return (this.typeSIM === 'DGPER');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "depotText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.depots != null) {
                for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval += depot.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "products", {
        get: function () {
            var rval = '';
            // Array:
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    rval += produit.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "selectedProducts", {
        get: function () {
            var rval = [];
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    rval.push(produit.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "selectedMarches", {
        get: function () {
            var rval = [];
            if (this.marches != null) {
                for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval.push(marche.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "selectedDepots", {
        get: function () {
            var rval = [];
            if (this.depots != null) {
                for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval.push(depot.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "selectedQualites", {
        get: function () {
            var rval = [];
            if (this.qualites != null) {
                for (var _i = 0, _a = this.qualites; _i < _a.length; _i++) {
                    var qualite = _a[_i];
                    rval.push(qualite.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "selectedMesures", {
        get: function () {
            var rval = [];
            if (this.mesures != null) {
                for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
                    var mesure = _a[_i];
                    rval.push(mesure.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reseau.prototype, "nomAdminPrincipal", {
        get: function () {
            if (this.adminPrincipal != null) {
                return this.adminPrincipal.nomCompletEtReseau;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    return Reseau;
}());
export { Reseau };
