<ng-container *ngIf="listOfAllDataPrice && listOfAllDataPrice.length>0 ">
    <ng-template #modalHeader>
        <h2 class="title">Rejet des prix</h2>
        <div class="actions">
            <tc-badge [view]="'warning'">Rejet</tc-badge>
        </div>
    </ng-template>

    <ng-template #modalBody>
        <tc-form-label>Veuillez détailler les causes du rejet</tc-form-label>
        <tc-textarea  [charLimiter]="{ counter: 255, before: 'Il reste ', after: ' caractères' }"
                      [tcShape]="0" [rows]="3" [(ngModel)] = "causeRejet" name="causeRejet"></tc-textarea>

    </ng-template>

    <ng-template #modalFooter>
        <div class="actions justify-content-between">
            <button tc-button (click)="validerRejet()">Rejeter</button>
            <button tc-button [outline]="true" (click)="closeModal()">Annuler</button>
        </div>
    </ng-template>

<tc-card>
    <div class="row">
        <div class="col-sm-4 ">
            <div class="pull-left">
                <button tc-button (click)="valider()">Valider</button>
            </div>
        </div>
        <div class="col-sm-4 ">

        </div>
        <div class="col-sm-4 ">
            <div class="pull-right">
                <button tc-button (click)="openModal(modalBody, 'Rejet des prix ', modalFooter, { overlayClose: false })" [outline]="true" >Rejeter</button>
            </div>
        </div>

    </div>

</tc-card>


    <tc-card>

            <span *ngIf="numberOfChecked"> {{ numberOfChecked }} éléments sélectionnés</span>

            <nz-table
                #rowSelectionTable
                nzShowPagination
                nzShowSizeChanger
                [nzPageSize]="300"
                [nzData]="listOfAllDataPrice"
                (nzCurrentPageDataChange)="currentPageDataChange($event)"
            >
                <thead>
                <tr>
                    <th
                            nzShowCheckbox
                            [(nzChecked)]="isAllDisplayDataChecked"
                            [nzIndeterminate]="isIndeterminate"
                            (nzCheckedChange)="checkAll($event)"
                    ></th>
                    <th nzShowExpand></th>
                    <th> PRIX A VALIDER </th>
                    <th> ENQUETEUR</th>
                    <th> RESEAU</th>
                </tr>
                </thead>
                <tbody>
                <ng-template ngFor let-data [ngForOf]="rowSelectionTable.data">
                    <tr>
                        <td
                                nzShowCheckbox
                                [(nzChecked)]="mapOfCheckedId[data.id]"
                                (nzCheckedChange)="refreshStatus()"
                        ></td>
                        <td nzShowExpand [(nzExpand)]="mapOfExpandData[data.id]">
                        </td>
                        <td>
                            <p> Type Prix : <strong> {{ data.typePrix }}</strong>  </p>
                            <p> Date Saisie : <strong> {{ data.date }}</strong>  </p>
                            <p> Marché : <strong> {{ data.nom_marche }}</strong> </p>
                            <p> Produit : <strong> {{ data.nom_produit }}</strong> </p>
                            <p> Prix : <strong> {{ data.prix }} / {{ data.mesure }} </strong> </p>

                        </td>
                        <td>
                            <p> <strong> {{ data.enqueteur }} </strong></p>
                            <p> <small> {{ data.numeroEnqueteur }}</small> </p>

                        </td>
                        <td>
                            <p> <strong> {{ data.reseau }} </strong></p>

                        </td>
                    </tr>
                    <tr [nzExpand]="mapOfExpandData[data.id]" colspan="3">
                        <td></td>
                        <td>
                            <p> Etat Approvionnement : <strong> {{ data.commentaires }} </strong> </p>
                            <p> Observations : <strong> {{ data.note_marche }} </strong> </p>
                            <p> Longitude : <strong> {{ data.longitude }} </strong> </p>
                            <p> Latitude : <strong> {{ data.latitude }} </strong> </p>
                        </td>
                    </tr>
                </ng-template>
                </tbody>
            </nz-table>

    </tc-card>


<!--<tc-card title="VALIDER LES PRIX">-->
<!--<ng-container *ngIf="listOfAllDataPrice.length>0 ">-->
<!--    <span *ngIf="numberOfChecked"> {{ numberOfChecked }} éléments sélectionnés</span>-->

<!--<nz-table-->
<!--    #rowSelectionTable-->
<!--    nzShowPagination-->
<!--    nzShowSizeChanger-->
<!--    [nzPageSize]="100"-->
<!--    [nzData]="listOfAllDataPrice"-->
<!--    (nzCurrentPageDataChange)="currentPageDataChange($event)"-->
<!--&gt;-->
<!--    <thead>-->
<!--    <tr>-->
<!--        <th-->
<!--                nzShowCheckbox-->
<!--                [(nzChecked)]="isAllDisplayDataChecked"-->
<!--                [nzIndeterminate]="isIndeterminate"-->
<!--                (nzCheckedChange)="checkAll($event)"-->
<!--        ></th>-->
<!--        <th>Date</th>-->
<!--        <th>Marché</th>-->
<!--        <th>Produit</th>-->
<!--        <th>Prix de gros</th>-->
<!--        <th>Mesure gros</th>-->
<!--        <th>Prix de détail</th>-->
<!--        <th>Mesure détail</th>-->
<!--        <th>Enqueteur</th>-->
<!--        <th>Réseau</th>-->
<!--    </tr>-->
<!--    </thead>-->
<!--    <tbody>-->
<!--    <tr *ngFor="let data of rowSelectionTable.data">-->
<!--        <td-->
<!--                nzShowCheckbox-->
<!--                [(nzChecked)]="mapOfCheckedId[data.id]"-->
<!--                (nzCheckedChange)="refreshStatus()"-->
<!--        ></td>-->
<!--        <td>{{ data.date }}</td>-->
<!--        <td>{{ data.nom_marche }}</td>-->
<!--        <td>{{ data.nom_produit }}</td>-->
<!--        <td>{{ data.montant_gros }}</td>-->
<!--        <td>{{ data.mesure_gros }}</td>-->
<!--        <td>{{ data.montant_detail }}</td>-->
<!--        <td>{{ data.mesure_detail }}</td>-->
<!--        <td><p>{{ data.enqueteur }} : {{ data.numeroEnqueteur }}</p> </td>-->
<!--        <td>{{ data.reseau }}</td>-->
<!--    </tr>-->
<!--    </tbody>-->
<!--</nz-table>-->
<!--</ng-container>-->

<!--</tc-card>-->
<tc-card>
    <div class="row">
        <div class="col-sm-4 ">
            <div class="pull-left">
                <button tc-button (click)="valider()">Valider</button>
            </div>
        </div>
        <div class="col-sm-4 ">

        </div>
        <div class="col-sm-4 ">
            <div class="pull-right">
                <button tc-button (click)="openModal(modalBody, 'Rejet des prix ', modalFooter, { overlayClose: false })" [outline]="true" >Rejeter</button>
            </div>
        </div>

    </div>

</tc-card>
    <tc-card>
        <div class="buttons-list">
            <button  (click)="exportByExcel()"><i class="fa fa-file-excel-o" aria-hidden="false" style="font-size:48px;color:#C7B54A">
            </i></button>
        </div>
    </tc-card>
    </ng-container>



