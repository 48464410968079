import { OnInit } from '@angular/core';
var TCCardComponent = /** @class */ (function () {
    function TCCardComponent() {
        this.view = 'default';
    }
    Object.defineProperty(TCCardComponent.prototype, "viewInfo", {
        get: function () { return this.view === 'info'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCCardComponent.prototype, "viewAccent", {
        get: function () { return this.view === 'accent'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCCardComponent.prototype, "viewSuccess", {
        get: function () { return this.view === 'success'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCCardComponent.prototype, "viewWarning", {
        get: function () { return this.view === 'warning'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCCardComponent.prototype, "viewError", {
        get: function () { return this.view === 'error'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCCardComponent.prototype, "viewTransparent", {
        get: function () { return this.view === 'transparent'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCCardComponent.prototype, "rightAlign", {
        get: function () { return this.align === 'right'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCCardComponent.prototype, "centerAlign", {
        get: function () { return this.align === 'center'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCCardComponent.prototype, "bgImage", {
        get: function () {
            return !this.gradient ? (this.bgImg ? "url(" + this.bgImg + ")" : null) : this.gradient;
        },
        enumerable: true,
        configurable: true
    });
    TCCardComponent.prototype.ngOnInit = function () { };
    return TCCardComponent;
}());
export { TCCardComponent };
