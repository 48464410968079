import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ValiderPrixService = /** @class */ (function () {
    function ValiderPrixService(http) {
        this.http = http;
        this.baseUrl = '/api/prix';
    }
    ValiderPrixService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    ValiderPrixService.prototype.getAllByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderPrixService.prototype.getAllValidesByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_valides_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    // tslint:disable-next-line:max-line-length
    ValiderPrixService.prototype.getPrixValides = function (dateDebut, dateFin, marches, produits, reseau, typePrix, mesures) {
        return this.http.get(this.baseUrl + "/valides", {
            params: new HttpParams()
                .set('dateDebut', dateDebut)
                .set('dateFin', dateFin)
                .set('marches', marches.toString())
                .set('produits', produits.toString())
                .set('reseau', reseau)
                .set('typePrix', typePrix)
                .set('mesures', mesures.toString())
        });
    };
    ValiderPrixService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ValiderPrixService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    ValiderPrixService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    ValiderPrixService.prototype.valider = function (obj) {
        return this.http.post(this.baseUrl + "/valider", obj);
    };
    ValiderPrixService.prototype.devalider = function (obj) {
        return this.http.post(this.baseUrl + "/devalider", obj);
    };
    ValiderPrixService.prototype.rejeter = function (obj) {
        return this.http.put(this.baseUrl + "/rejeter", obj);
    };
    ValiderPrixService.prototype.abandonner = function (obj) {
        return this.http.post(this.baseUrl + "/abandonner", obj);
    };
    ValiderPrixService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    ValiderPrixService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    ValiderPrixService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValiderPrixService_Factory() { return new ValiderPrixService(i0.ɵɵinject(i1.HttpClient)); }, token: ValiderPrixService, providedIn: "root" });
    return ValiderPrixService;
}());
export { ValiderPrixService };
