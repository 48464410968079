var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { RaceService } from '../race.service';
import { ToastrService } from 'ngx-toastr';
import { NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Race } from '../../models/race';
var PageCreateRaceComponent = /** @class */ (function (_super) {
    __extends(PageCreateRaceComponent, _super);
    function PageCreateRaceComponent(store, httpSv, raceService, route, router, toastr, i18n, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.raceService = raceService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code race',
            nom: 'Nom race',
            description: 'Description',
            actif: 'Actif'
        };
        _this.race = new Race();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des races',
                    route: 'list-race'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateRaceComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.race) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateRaceComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier une race ' : ' Créer une race ';
    };
    PageCreateRaceComponent.prototype.isEditing = function () {
        return (this.race.id != null);
    };
    PageCreateRaceComponent.prototype.initReactiveForm = function () {
        this.raceForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            description: [''],
            actif: [true]
        });
    };
    PageCreateRaceComponent.prototype.getRace = function (jsonValue) {
        return Race.fromJSON(jsonValue);
    };
    PageCreateRaceComponent.prototype.setReactiveForm = function () {
        if (this.race) {
            this.raceForm.patchValue({
                code: this.race.code,
                nom: this.race.nom,
                description: this.race.description,
                actif: this.race.actif
            });
        }
    };
    PageCreateRaceComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.raceService.getURL(_this.selectedId), 'race', 'race', 'setLoaded');
            }
            else {
                _this.race = new Race();
                _this.initReactiveForm();
            }
        });
    };
    PageCreateRaceComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateRaceComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-race']);
    };
    PageCreateRaceComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-race']);
    };
    PageCreateRaceComponent.prototype.mapFormValuesToModel = function () {
        this.race.code = this.raceForm.value.code;
        this.race.nom = this.raceForm.value.nom;
        this.race.description = this.raceForm.value.description;
        this.race.actif = this.raceForm.value.actif;
    };
    PageCreateRaceComponent.prototype.update = function () {
        var _this = this;
        if (this.raceForm.valid) {
            this.mapFormValuesToModel();
            this.raceService.update(this.race.id, this.race)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-race']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateRaceComponent.prototype.create = function () {
        var _this = this;
        if (this.raceForm.valid) {
            this.mapFormValuesToModel();
            this.raceService.create(this.race)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie  ', '', { timeOut: 2000 });
                _this.raceForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateRaceComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateRaceComponent;
}(BasePageComponent));
export { PageCreateRaceComponent };
