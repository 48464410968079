var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { QualiteService } from '../qualite.service';
import { ToastrService } from 'ngx-toastr';
import { Qualite } from '../../models/qualite';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
var PageCreateQualiteComponent = /** @class */ (function (_super) {
    __extends(PageCreateQualiteComponent, _super);
    function PageCreateQualiteComponent(store, httpSv, qualiteService, route, router, toastr, i18n, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.qualiteService = qualiteService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code Qualité',
            nom: 'Nom Qualité',
            coefficient: 'Coefficient',
            actif: 'Actif'
        };
        _this.qualite = new Qualite();
        _this.pageData = {
            title: 'Créer une qualite',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des qualites',
                    route: '/vertical/list-qualite'
                }
            ]
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    PageCreateQualiteComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.setReactiveForm();
    };
    PageCreateQualiteComponent.prototype.isEditing = function () {
        return (this.qualite.id != null);
    };
    PageCreateQualiteComponent.prototype.initReactiveForm = function () {
        this.qualiteForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            coefficient: [''],
            actif: [true]
        });
    };
    PageCreateQualiteComponent.prototype.setReactiveForm = function () {
        if (this.qualite) {
            this.qualiteForm.patchValue({
                code: this.qualite.code,
                nom: this.qualite.nom,
                coefficient: this.qualite.coefficient,
                actif: this.qualite.actif
            });
        }
    };
    PageCreateQualiteComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.qualiteService.getURL(_this.selectedId), 'qualite', 'qualite', 'setLoaded');
            }
            else {
                _this.qualite = new Qualite();
                _this.initReactiveForm();
            }
        });
        // detect form changes
        this.qualiteForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
    };
    PageCreateQualiteComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateQualiteComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-qualite']);
    };
    PageCreateQualiteComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-qualite']);
    };
    PageCreateQualiteComponent.prototype.mapFormValuesToModel = function () {
        this.qualite.code = this.qualiteForm.value.code;
        this.qualite.nom = this.qualiteForm.value.nom;
        this.qualite.coefficient = this.qualiteForm.value.coefficient;
        this.qualite.actif = this.qualiteForm.value.actif;
    };
    PageCreateQualiteComponent.prototype.update = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.qualiteService.update(this.qualite.id, this.qualite).subscribe(function (data) {
            _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
            _this.router.navigate(['/vertical/list-qualite']);
        });
    };
    PageCreateQualiteComponent.prototype.create = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.qualiteService.create(this.qualite)
            .subscribe(function (data) {
            _this.toastr.success('Création réussie de ' + _this.qualite.toString(), '', { timeOut: 2000 });
            _this.qualiteForm.reset();
        }, function (error) { return console.log(error); });
    };
    PageCreateQualiteComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateQualiteComponent;
}(BasePageComponent));
export { PageCreateQualiteComponent };
