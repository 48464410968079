import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import {ActivatedRoute, Route, Router} from '@angular/router';
import { MarcheService } from '../marche.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import {ExcelService} from '../../../../services/sharedServices/excel-service.service';
import {Marche} from '../../models/marche';
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {MarchesDataSource} from '../marches.datasource';
import {fromEvent, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';


@Component({
  selector: 'tc-marche-index',
  templateUrl: './marche-index.component.html',
 styleUrls: ['./marche-index.component.scss']
})
export class PageIndexMarcheComponent extends BasePageComponent implements  OnInit, AfterViewInit, OnDestroy {
	rows: any[];
    productCount = 0;
    dataSource: MarchesDataSource;
   // pageEvent: PageEvent;
    sortField = 'nom';
    pageIndex = 0;
    pageSize = 5;
    typologie: string[] = ['Collecte', 'Regroupement', 'Production', 'Exportation', 'Consommation'];
    // MatPaginator Output
    displayedColumns = ['code', 'nom', 'typologie', 'periodicite', 'actions'];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @ViewChild(MatSort, { static: false }) sort: MatSort;

    @ViewChild('input', { static: false }) input: ElementRef;
   constructor( store: Store<IAppState>,
	httpSv: HttpService, private marcheService: MarcheService,
	public dialog: MatDialog,
    private router: Router
	) {
		super(store, httpSv);
  this.pageData = {
      subTitle: '' ,
      title: 'LISTE DES MARCHES',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Tableau de bord',
          route: 'dashboard'
        }
      ]
	};
	this.rows = [];
}


  ngOnInit() {
	   super.ngOnInit();
      this.dataSource = new MarchesDataSource(this.marcheService);
      this.marcheService.findCount().subscribe(count => {
              this.productCount = count;

              if (sessionStorage.getItem('marchePageIndex') != null) {
                  this.pageIndex = +sessionStorage.getItem('marchePageIndex');
              } else {
                  this.pageIndex = 0 ;
              }
          if (sessionStorage.getItem('marchePageSize') != null) {
              this.pageSize = (+sessionStorage.getItem('marchePageSize') > 0) ? +sessionStorage.getItem('marchePageSize') : 5;
          } else {
              this.pageSize = 5 ;
          }
          this.paginator.pageIndex = this.pageIndex ;
          this.paginator.pageSize = this.pageSize ;
          this.dataSource.loadMarches('', this.sortField, 'asc', this.pageIndex, this.pageSize);

          }
      );

  }
    ngAfterViewInit() {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                   this.paginator.pageIndex = 0;
                    this.loadPage();
                })
            )
            .subscribe();

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadPage())
            ).subscribe();
        this.paginator.page.subscribe(
            (page) => {
                this.pageIndex = page.pageIndex;
                this.pageSize = page.pageSize;
                sessionStorage.setItem('marchePageIndex', page.pageIndex) ;
                if (page.pageSize > 0) {
                sessionStorage.setItem('marchePageSize', page.pageSize) ;
                }
            }

        );

    }

    loadPage() {
        this.dataSource.loadMarches(
            this.input.nativeElement.value,
            this.sortField,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    setLoaded(during: number = 0) {
       super.setLoaded(during);
     }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  edit(id: any) {
   	   this.router.navigate(['/vertical/create-marche', { id: id}]);
  }
   ajouter() {
	  this.router.navigate(['/vertical/create-marche']);
  }
    exportByExcel() {
        ExcelService.exportAsExcelFile(Marche.listFromJSON(this.rows), 'marches');
    }
    exportAsXLSX() {
        this.getData(this.marcheService.getAllURL(), 'rows', 'exportByExcel');
    }
    getMarche (jsonValue): Marche {
        return Marche.fromJSON(jsonValue) ;
    }

    delete(id: number) {
        const message = `Confirmez-vous la suppression de l'objet ?`;

        const dialogData = new ConfirmDialogModel('Confirmation', message);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '500px',
            data: dialogData,
            panelClass: 'customDialog'
        });

        dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult === true) {
                this.marcheService.delete(id)
                    .subscribe(
                        data => {
                            console.log(data);
                            this.loadPage();
                        },
                        error => console.log(error));
            }
        });
    }
  }

