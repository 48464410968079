import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {en_US, fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../animations/form-error';
import {QualiteService} from '../qualite/qualite.service';
import {MesureService} from '../mesure/mesure.service';
import {Mesure} from '../models/mesure';
import {Qualite} from '../models/qualite';
import {Produit} from '../models/produit';
import {ProduitService} from '../produit/produit.service';
import {OffreVenteService} from './offre-vente.service';
import {OffreVente} from '../models/offreVente';
import { NzFormModule } from 'ng-zorro-antd/form';
import * as moment from 'moment';
import {Subject, Subscription} from 'rxjs';
import {UserService} from '../administrateur/user.service';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';
import {RegionService} from '../region/region.service';


@Component({
    selector: 'tc-offre-create',
    templateUrl: './saisie-offre-vente.component.html',
    styleUrls: ['./saisie-offre-vente.component.scss'],
    animations: [fadeIn]
})
export class SaisieOffreVenteComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    public mobilePhoneKeyUp = new Subject<KeyboardEvent>();
    private mobilePhoneSubscription: Subscription;
    offreForm: FormGroup;
    offre: any;
    mesures: any[] = [];
    qualites: any[] = [];
    produits: any[] = [];
    produitsAdapter: IOption[] = [];
    mesuresAdapter: IOption[] = [];
    qualitesAdapter: IOption[] = [];
    regions: any[] = [];
    regionsAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    @ViewChild('dateOffre', {static: false}) dateOffre: jqxDateTimeInputComponent;
    Columnslabels =
        {
            date: 'Date ',
            delaiValidite: 'Délai de validité (jrs)',
            dateExpiration: 'Date Expiration',
            produit: 'Produit',
            quantite: 'Quantité',
            mesure: 'Mesure',
            qualite: 'Qualité',
            region: 'Région',
            telephone: 'N° Mobile Vendeur',
            nomAuteur: 'Nom Vendeur ',
            email: 'e-Mail',
            prixUnitaire: 'Prix Unitaire',
            montant: 'Montant',
            conditions: 'Conditions'
        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private offreService: OffreVenteService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private produitService: ProduitService,
                 private qualiteService: QualiteService,
                 private mesureService: MesureService,
                 private regionService: RegionService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder, private administrateurService: UserService

    ) {
        super(store, httpSv);
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        this.i18n.setLocale(fr_FR);
        this.initReactiveForm();
        this.offre = new OffreVente();
        moment.locale('fr');
    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.offre) {
            this.onProduitsPromise();
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    onRegionsPromise() {
        if (this.regions) {
            for (const region of this.regions) {
                this.regionsAdapter.push({'value': region.id.toString(), 'label': region.nom});
            }
        }

    }

    getTitle(): string {
        return  this.isEditing() ? ' Modifier une Offre d\'Vente ' : ' Créer une Offre d\'Vente ' ;
    }
    isEditing(): boolean {
        return (this.offre.id != null);
    }
    onProduitsPromise() {
        if (this.produits) {
            for (const produit of this.produits) {
                this.produitsAdapter.push({'value': produit.id.toString(), 'label': produit.nom});
            }
        }

    }
    onQualitesPromise() {
        if (this.qualites) {
            for (const qualite of this.qualites) {
                this.qualitesAdapter.push({'value': qualite.id.toString(), 'label': qualite.nom});
            }
        }

    }
    ngAfterViewInit() {

        if (!this.isEditing()) {
            this.mobilePhoneSubscription = this.mobilePhoneKeyUp.pipe(
                debounceTime(100),
                distinctUntilChanged(),
                tap(() => {
                    if (this.offreForm.get('telephone').value != null) {
                        if (this.offreForm.get('telephone').value.length === 12) {
                            this.administrateurService.findByMobile(this.offreForm.value.telephone).subscribe(
                                acheteur => {
                                    console.log('vendeur', acheteur);
                                    if (acheteur.nom !== undefined) {
                                        this.offreForm.patchValue({
                                            nomAuteur: acheteur.nom + ' ' + acheteur.prenom,
                                            email: acheteur.email}
                                        );
                                    }

                                }
                            );
                        }
                    }})
            ).subscribe();

        }

    }

    onMesuresPromise() {
        if (this.mesures) {
            for (const mesure of this.mesures) {
                this.mesuresAdapter.push({'value': mesure.id.toString(), 'label': mesure.nom});
            }
        }

    }
    onSelectProduit($event: any) {
        if (this.offreForm.value.produit) {
            const  produitSelected: Produit = this.produits.find(obj => obj.id.toString() === this.offreForm.value.produit);
            if (produitSelected) {
                this.offreForm.patchValue(
                    {mesure : produitSelected.mesure.id.toString()}
                );
            }
        }
    }
    onMontantChange($event: any) {
        if (this.offreForm.value.quantite && this.offreForm.value.prixUnitaire) {
            this.offreForm.patchValue(
                {montant: this.offreForm.value.quantite * this.offreForm.value.prixUnitaire}
            );
        }
    }


    initReactiveForm() {
        const dateDuJour = new Date();
        this.offreForm = this.fb.group({
                date: [dateDuJour, Validators.required],
                delaiValidite: [14, [Validators.required, Validators.min(0)]],
                dateExpiration: [moment(dateDuJour).add(14, 'days').format('L')],
                region: ['', Validators.required],
                produit: ['', Validators.required],
                mesure: ['', Validators.required],
                quantite: ['', [Validators.required, Validators.min(0)]],
                qualite: [''],
                email: ['', [Validators.email]],
                telephone: ['', Validators.required],
                nomAuteur: ['', Validators.required],
                montant: ['', Validators.min(0)],
                prixUnitaire: ['', Validators.min(0)],
                conditions: ['']
            }
        ) ;

       this.offreForm.get('delaiValidite').valueChanges.subscribe(val => {
           if (this.dateOffre !== undefined) {
               this.offreForm.get('dateExpiration').setValue(moment(this.dateOffre.getDate()).add(val, 'days').format('L')) ;
           }
          });
    }
    setReactiveForm() {
        const dateDuJour = new Date(this.offre.date);
        this.offreForm.patchValue({
                qualite: (this.offre.qualite != null) ? this.offre.qualite.id.toString() : '',
                actif: this.offre.actif,
                quantite: this.offre.quantite,
                email: this.offre.email,
                telephone: this.offre.telephone,
                nomAuteur: this.offre.nomAuteur,
                date: dateDuJour,
                region: (this.offre.region != null) ? this.offre.region.id.toString() : '',
                delaiValidite: this.offre.delaiValidite,
                dateExpiration: this.offre.dateExpiration,
                montant: this.offre.montant,
                prixUnitaire: this.offre.prixUnitaire,
                conditions : this.offre.conditions,
                mesure: (this.offre.mesure != null) ? this.offre.mesure.id.toString() : '',
                produit: (this.offre.produit != null) ? this.offre.produit.id.toString() : ''
            }
        );
    }
    getOffre (jsonValue): OffreVente {
        return OffreVente.fromJSON(jsonValue) ;
    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.qualiteService.getAllURL(), 'qualites', 'onQualitesPromise');
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.getData(this.produitService.getAllURL(), 'produits', 'onProduitsPromise');
        this.getData(this.regionService.getAllURL(), 'regions', 'onRegionsPromise');
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.offreService.getURL(this.selectedId), 'offre', 'offreVente', 'setLoaded');
            } else {
                this.initReactiveForm();
            }
        });
        // detect form changes
        this.offreForm.valueChanges.subscribe(() => {
            this.changes = true;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-offre']);
    }
    liste() {
        this.router.navigate(['/vertical/modifier-les-offres-ventes']);
    }
    mapFormValuesToModel() {
        this.offre.quantite =  this.offreForm.value.quantite;
        this.offre.email =  this.offreForm.value.email;
        this.offre.telephone =  this.offreForm.value.telephone;
        this.offre.date =  this.offreForm.value.date;
        this.offre.delaiValidite =  this.offreForm.value.delaiValidite;
        this.offre.dateExpiration = this.offreForm.value.dateExpiration ;
        this.offre.prixUnitaire =  this.offreForm.value.prixUnitaire;
        this.offre.montant =  this.offreForm.value.montant;
        this.offre.qualite =  this.offreForm.value.qualite;
        this.offre.region =  this.offreForm.value.region;
        this.offre.mesure =  this.offreForm.value.mesure;
        this.offre.produit =  this.offreForm.value.produit;
        this.offre.conditions =  this.offreForm.value.conditions;
        this.offre.nomAuteur =  this.offreForm.value.nomAuteur;
    }
    update(): void {
        if (this.offreForm.valid) {
            this.mapFormValuesToModel();
            this.offreService.update(this.offre.id, this.offre)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.liste();
                    });
        }

    }
    create(): void {
        if (this.offreForm.valid) {
            this.mapFormValuesToModel();
            this.offreService.create(this.offre)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.offreForm.reset();
                        const dateDuJour = new Date();
                        this.offreForm.get('date').setValue(dateDuJour);
                         this.offreForm.get('delaiValidite').setValue(14);
                     }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
