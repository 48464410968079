<ng-container *ngIf="pushSMS && groupes.length>0 ">
    <div class="col-sm-8">
        <form [formGroup]="pushSMSForm" >
            <div class="content-body">

                                <div class="row">
                                    <div class="col-sm-12">

                                        <tc-form-group>
                                            <tc-form-label>{{Columnslabels.groupes}}</tc-form-label>
                                            <tc-select [options]="groupesAdapter"  formControlName="groupes" (ngModelChange)="onChangeGroupes($event)"  ></tc-select>
                                        </tc-form-group>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-sm-12">

                                        <tc-form-group>
                                            <tc-form-label>{{Columnslabels.destinataires}}</tc-form-label>
                                            <tc-select [options]="destinatairesAdapter" [multiple]="true" [filter]="true"   [allowClear]="true" formControlName="destinataires"   ></tc-select>


                                        </tc-form-group>
                                    </div>
                                </div>


                                <tc-form-group>
                                    <tc-form-label>{{Columnslabels.message}}</tc-form-label>
                                    <tc-textarea  [charLimiter]="{ counter: 1600, before: 'Il reste ', after: ' caractères' }"
                                                  [tcShape]="0" [rows]="4" formControlName="message"></tc-textarea>
                                </tc-form-group>

                                <span *ngIf="!pushSMSForm.valid && pushSMSForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                                <button [disabled]="!pushSMSForm.valid && pushSMSForm.touched" (click)="envoyerSMS()" tc-button [block]="true" >
                                    Envoyer SMS
                                </button>

            </div>
         </form>
    </div>
</ng-container>

