import {Commune} from './commune';
import 'rxjs/add/observable/of';

export class Marche {
  constructor() {
        this.typologie = 'Consommation';
		this.actif = true;
		this.longitude = 0;
		this.latitude = 0;
		this.commune = new Commune();
	}
	id: number;
	code: string;
	nom: string;
 	longitude: number;
	latitude: number;
	actif: boolean;
	typologie: string;
	periodicite: string;
	communeTab: string;
	commune: Commune;
	static fromJSON(json: any): Marche {
		const result = new Marche();
		// tslint:disable-next-line:forin
		for (const key in json) {
				result[key] = json[key];
		}

		return result;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const marche = Marche.fromJSON(jsonObject);
				jsonObject.commune = marche.nomDeCommune;

				rval.push(jsonObject);
			}
		}

		return rval;
	}

	toString() {
		return this.nom;
	}

	get nomDeCommune() {
		if (this.commune != null) {
			return this.commune.nom;
		}
		return '--';
	}

	get getFullName(): string {
		return (this.nom  + ' (' + this.code + ')');
	}
}

