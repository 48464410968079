import { HttpClient, HttpParams } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CACHE_KEY = 'sima-depot-cache';
var DepotService = /** @class */ (function () {
    function DepotService(http) {
        this.http = http;
        this.baseUrl = '/api/depot';
    }
    DepotService.prototype.findAll = function (filter, sortField, sortOrder, offset, pageSize) {
        if (filter === void 0) { filter = ''; }
        if (sortField === void 0) { sortField = 'nom'; }
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.get('/api/depot/pages', {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sortField)
                .set('sortOrder', sortOrder)
                .set('offset', offset.toString())
                .set('max', pageSize.toString())
        });
    };
    DepotService.prototype.findCount = function () {
        return this.http.get('/api/depot/count')
            .pipe(map(function (res) { return res['total']; }));
    };
    DepotService.prototype.findAsTree = function () {
        return this.http.get(this.baseUrl + "/tree")
            .pipe(map(function (res) { return res['tree']; }));
    };
    DepotService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    DepotService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    DepotService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    DepotService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    DepotService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    DepotService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    DepotService.prototype.getCached = function () {
        this.depots = this.http.get(this.baseUrl + "/short")
            .pipe(map(function (data) { return data.items; }));
        this.depots.subscribe(function (next) {
            localStorage[CACHE_KEY] = JSON.stringify(next);
        });
        this.depots = this.depots.pipe(startWith(JSON.parse(localStorage[CACHE_KEY] || '[]')));
        return this.depots;
    };
    DepotService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DepotService_Factory() { return new DepotService(i0.ɵɵinject(i1.HttpClient)); }, token: DepotService, providedIn: "root" });
    return DepotService;
}());
export { DepotService };
