import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { QualiteService } from '../qualite.service';
import { ToastrService } from 'ngx-toastr';
import { fadeIn } from 'src/app/animations/form-error';
import {Qualite} from '../../models/qualite';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';


@Component({
    selector: 'tc-qualite-create',
    templateUrl: './create-qualite.component.html',
    animations: [fadeIn]
})
export class PageCreateQualiteComponent extends BasePageComponent implements OnInit, OnDestroy {
    qualiteForm: FormGroup;
    qualite: Qualite;
    submitted = false;
    changes = false;
    selectedId: number;
    Columnslabels =
        {
            code: 'Code Qualité',
            nom: 'Nom Qualité',
            coefficient: 'Coefficient',
            actif: 'Actif'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private qualiteService: QualiteService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.qualite = new Qualite();
        this.pageData = {
            title: 'Créer une qualite',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des qualites',
                    route: '/vertical/list-qualite'
                }
            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);
        this.initReactiveForm();

    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.setReactiveForm();

    }
    isEditing(): boolean {
        return (this.qualite.id != null);
    }

    initReactiveForm() {
        this.qualiteForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                coefficient: [''],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        if (this.qualite) {
            this.qualiteForm.patchValue({
                    code: this.qualite.code,
                    nom: this.qualite.nom,
                    coefficient: this.qualite.coefficient,
                    actif: this.qualite.actif
                }
            );
        }

    }
    ngOnInit() {
        super.ngOnInit();
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.qualiteService.getURL(this.selectedId), 'qualite', 'qualite', 'setLoaded');
            } else {
                this.qualite = new Qualite();
                this.initReactiveForm();
            }
        });
        // detect form changes
        this.qualiteForm.valueChanges.subscribe(() => {
            this.changes = true;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-qualite']);
    }
    liste() {
        this.router.navigate(['/vertical/list-qualite']);
    }
    mapFormValuesToModel() {
        this.qualite.code =  this.qualiteForm.value.code;
        this.qualite.nom =  this.qualiteForm.value.nom;
        this.qualite.coefficient = this.qualiteForm.value.coefficient;
        this.qualite.actif =  this.qualiteForm.value.actif;
    }
    update(): void {
        this.mapFormValuesToModel();
        this.qualiteService.update(this.qualite.id, this.qualite).subscribe(
                        data => {
                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/list-qualite']);
                        }
                    );
    }
    create(): void {
        this.mapFormValuesToModel();
        this.qualiteService.create(this.qualite)
            .subscribe(
                data => {
                    this.toastr.success('Création réussie de ' + this.qualite.toString(), '',
                        {timeOut: 2000});
                    this.qualiteForm.reset();
                }
                ,
                error => console.log(error));

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
