import {ActivatedRoute, Router} from '@angular/router';
import { Component, OnInit } from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';

import { fadeIn } from 'src/app/animations/form-error';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../interfaces/app-state';
import {HttpService} from '../../../../services/http/http.service';
import {UserService} from '../../../ui/administrateur/user.service';
import {ToastrService} from 'ngx-toastr';
import {AuthenticationService} from '../../../../services/sharedServices/authentication.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-sign-up',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss', '../sign-in/sign-in.component.scss'],
  animations: [fadeIn]
})
export class PageResetPasswdComponent implements OnInit {
  loginForm: FormGroup;
  constructor( store: Store<IAppState>,
               httpSv: HttpService, private adminService: UserService,
               private loginservice: AuthenticationService, private toastr: ToastrService,
                public router: Router

) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      mobilePhone: new FormControl('', [Validators.required])
     });
  }
  resetPassword() {
      this.loginservice.authenticate('+22699999999', 'Anonyme*2019').subscribe(
          data => {
              sessionStorage.setItem('currentUser', JSON.stringify(data));
              this.loginservice.setCurrentObject(data);

              sessionStorage.removeItem('currentUser');
              this.adminService.findMe().subscribe(
                  userData => {
                      this.loginservice.setCurrentUserDataObject(userData);
                      this.router.navigate(['']);
                      sessionStorage.setItem('currentUserData', JSON.stringify(userData));

                  }
              );

          },
          error => {
              let errorText: string;
              console.log('error', error);
              errorText = error;
              if ((errorText.toUpperCase().indexOf('TIMEOUT') >= 0) || (errorText.toUpperCase().indexOf('GATEWAY') >= 0)) {
                  errorText = 'Vérifiez que le serveur rest a démarré ';
              }
              this.toastr.error(errorText);
          }
      );
    this.adminService.resetPassword(this.loginForm.get('mobilePhone').value).subscribe(
        resultat => {
          if (resultat.status === 'OK' ) {
            this.toastr.success('Succès de la réinitialisation du mot de passe ', '',
                {timeOut: 2000});
            this.router.navigate(['']);
          } else {
            this.toastr.error('Echec de la réinitialisation du mot de passe ', '',
                {timeOut: 2000});
          }
        }
    );
  }



}

