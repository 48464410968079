import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PrixService = /** @class */ (function () {
    function PrixService(http) {
        this.http = http;
        this.baseUrl = '/api/prix';
    }
    PrixService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    PrixService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    PrixService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    PrixService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    PrixService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    PrixService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    PrixService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrixService_Factory() { return new PrixService(i0.ɵɵinject(i1.HttpClient)); }, token: PrixService, providedIn: "root" });
    return PrixService;
}());
export { PrixService };
