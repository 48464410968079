import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {ValiderPrixService} from '../valider-prix/validerPrix.service';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';
import {Price} from '../valider-prix/valider-prix.component';

@Component({
  selector: 'tc-liste-prix-valides',
    templateUrl: './liste-prix-valides.component.html',
    styleUrls: ['./liste-prix-valides.component.scss'],
    animations: [fadeIn]
})

export class ListePrixValidesComponent extends BasePageComponent implements OnInit, OnDestroy {

  constructor(store: Store<IAppState>,
              httpSv: HttpService, private marcheService: MarcheService,
              private route: ActivatedRoute, private authService: AuthenticationService,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService,
              private validerPrixService: ValiderPrixService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }
  mapOfExpandData: { [key: string]: boolean } = {};
  dateValidation = new Date();
  data: any;
  prixAValider: Price[];
    outData: any[] = [];
    isAllDisplayDataChecked = false;
    isOperating = false;
    isIndeterminate = false;
    listOfDisplayData: Price[] = [];
    listOfAllDataPrice: Price[] = [];
    mapOfCheckedId: { [key: string]: boolean } = {};
    numberOfChecked = 0;
 abandonner() {
        this.outData = [];
        this.listOfAllDataPrice.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                prix: this.outData
            }};
        if (this.outData.length > 0) {
            this.validerPrixService.abandonner(this.data) .subscribe(
                data => {

                    this.toastr.success('abandon réussi des prix sélectionnés', '',
                        {timeOut: 2000});
                    this.loadPrice();
                },
                error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }

    rejeter() {
        this.outData = [];
        this.listOfAllDataPrice.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
            this.outData.push(item);
            }
        });

        this.data = {data: {
            dateValidation: this.dateValidation.toLocaleDateString('en-US'),
            prix: this.outData
        }};
        if (this.outData.length > 0) {
        this.validerPrixService.rejeter(this.data) .subscribe(
            data => {

                this.toastr.success('rejet réussi des prix sélectionnés', '',
                    {timeOut: 2000});
                this.loadPrice();
            },
            error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }

  ngOnInit() {
    super.ngOnInit();
    this.loadPrice();
  }
    devalider() {
        this.outData = [];
        this.listOfAllDataPrice.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                prix: this.outData
            }};
        if (this.outData.length > 0) {
            this.validerPrixService.devalider(this.data) .subscribe(
                data => {
                   this.toastr.success('Dé-Validation réussie des prix selectionnés', '',
                        {timeOut: 2000});
                    this.loadPrice();
                },
                error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }
    private loadPrice() {
        this.listOfAllDataPrice = [];
        const reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerPrixService.getAllValidesByReseau(reseauId.toString()).subscribe(data => {
                this.prixAValider = data;
                this.prixAValider.forEach((prix, index, array) => {

                    this.listOfAllDataPrice.push({
                        latitude: prix.latitude,
                        longitude: prix.longitude,
                        typePrix: prix.typePrix,
                        typologie: prix.typologie,
                        expand: false,
                        note_marche: prix.note_marche,
                        id: prix.id,
                        date: prix.date,
                        nom_produit: prix.nom_produit,
                        nom_marche: prix.nom_marche,
                        prix: prix.prix,
                        mesure: prix.mesure,
                        statut: prix.statut,
                        enqueteur: prix.enqueteur,
                        numeroEnqueteur: prix.numeroEnqueteur,
                        reseau: prix.reseau,
                        observations: prix.observations
                    });
                });
            }
        );
        this.mapOfCheckedId = {};
        this.refreshStatus();

    }

    ngOnDestroy() {
    super.ngOnDestroy();
  }

    currentPageDataChange($event: Price[]): void {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    }

    refreshStatus(): void {
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(item => this.mapOfCheckedId[item.id]);
        this.isIndeterminate =
            this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
            !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataPrice.filter(item => this.mapOfCheckedId[item.id]).length;
    }

    checkAll(value: boolean): void {
        this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
        this.refreshStatus();
    }

    operateData(): void {
        this.isOperating = true;
        setTimeout(() => {
            this.listOfAllDataPrice.forEach(item => (this.mapOfCheckedId[item.id] = false));
            this.refreshStatus();
            this.isOperating = false;
        }, 1000);
    }
    exportByExcel() {
        ExcelService.exportAsExcelFile(this.prixAValider, 'prix_valides');
    }


}
