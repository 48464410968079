import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Depot} from '../models/depot';
import {INoeud} from '../produit/create';
const CACHE_KEY = 'sima-depot-cache';
@Injectable({
  providedIn: 'root'
})
export class DepotService {
	private baseUrl = '/api/depot';
    private depots;
  constructor(private http: HttpClient) {

  }
  findAll(  filter = '', sortField = 'nom', sortOrder = 'asc',
            offset = 0, pageSize = 10): Observable<Depot[]> {


    return this.http.get('/api/depot/pages', {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sortField)
          .set('sortOrder', sortOrder)
          .set('offset', offset.toString())
          .set('max', pageSize.toString())
    }) as Observable<Depot[]>;
  }
  findCount(): Observable<number> {
    return this.http.get('/api/depot/count')
        .pipe(
            map(res => res['total'])
        );
  }
  findAsTree(): Observable<INoeud[]> {
    return this.http.get(`${this.baseUrl}/tree`)
        .pipe(
            map(res => res['tree'])
        );
  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj);
  }
  update(id: number, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/modifier/${id}`, value);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }
  getCached(): Observable<any[]> {
    this.depots = this.http.get<any>(`${this.baseUrl}/short`)
        .pipe(
            map(data => data.items)
        );

    this.depots.subscribe(next => {
          localStorage[CACHE_KEY] = JSON.stringify(next);
        }

    );
    this.depots = this.depots.pipe(
        startWith(JSON.parse(localStorage[CACHE_KEY] || '[]'))
    );
    return this.depots;
  }

}

