var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { GroupeService } from '../groupe/groupe.service';
import { PushSMSGroupeService } from '../push-sms-groupes/pushSMSGroupe.service';
import { SMSPushGroup } from '../models/SMSPushGroup';
var PushSmsGroupeComponent = /** @class */ (function (_super) {
    __extends(PushSmsGroupeComponent, _super);
    function PushSmsGroupeComponent(store, httpSv, route, router, toastr, fb, i18n, pushSMSService, groupeService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.pushSMSService = pushSMSService;
        _this.groupeService = groupeService;
        _this.groupes = [];
        _this.groupesAdapter = [];
        _this.Columnslabels = {
            groupe: 'Groupe',
            message: 'Message'
        };
        _this.pageData = {
            title: '',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        return _this;
    }
    PushSmsGroupeComponent.prototype.initReactiveForm = function () {
        this.pushSMSForm = this.fb.group({
            groupe: ['', Validators.required],
            message: ['', [Validators.required, Validators.minLength(2)]]
        });
    };
    PushSmsGroupeComponent.prototype.envoyerSMS = function () {
        var _this = this;
        this.pushSMS.groupe = this.pushSMSForm.value.groupe;
        this.pushSMS.message = this.pushSMSForm.value.message;
        this.pushSMSService.send(this.pushSMS).subscribe(function (data) {
            _this.toastr.success(' SMS envoyé avec succès ', '', { timeOut: 2000 });
            _this.pushSMSForm.reset();
        }, function (error) { return console.log(error); });
    };
    PushSmsGroupeComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
    };
    PushSmsGroupeComponent.prototype.onGroupesPromise = function () {
        this.groupesAdapter = [];
        for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
            var groupe = _a[_i];
            this.groupesAdapter.push({ 'value': groupe.id, 'label': groupe.nom });
        }
    };
    PushSmsGroupeComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.pushSMS = new SMSPushGroup();
        this.pageData.title = this.getTitle();
        this.groupeService.getCached().subscribe(function (data) {
            _this.groupes = data;
            _this.onGroupesPromise();
        });
        this.initReactiveForm();
    };
    PushSmsGroupeComponent.prototype.getTitle = function () {
        return 'Envoyer des sms aux groupes ';
    };
    PushSmsGroupeComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return PushSmsGroupeComponent;
}(BasePageComponent));
export { PushSmsGroupeComponent };
