import * as i0 from "@angular/core";
var TypologieService = /** @class */ (function () {
    function TypologieService() {
        this.baseUrl = '/api/typologie';
    }
    TypologieService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    TypologieService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TypologieService_Factory() { return new TypologieService(); }, token: TypologieService, providedIn: "root" });
    return TypologieService;
}());
export { TypologieService };
