var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../administrateur/user.service';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
var ShowPasswordComponent = /** @class */ (function (_super) {
    __extends(ShowPasswordComponent, _super);
    function ShowPasswordComponent(store, httpSv, route, router, toastr, i18n, fb, adminService, auth) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.adminService = adminService;
        _this.auth = auth;
        _this.SERVER_URL = 'api/upload_avatar';
        _this.Columnslabels = {
            theMobile: 'No Mobile',
            thePassword: 'Mot de passe en clair'
        };
        _this.administrateur = {};
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    ShowPasswordComponent.prototype.getTitle = function () {
        return 'Voir un mot de passe en clair : ';
    };
    ShowPasswordComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
    };
    ShowPasswordComponent.prototype.initReactiveForm = function () {
        this.showPwdForm = this.fb.group({
            theMobile: ['', [Validators.required]],
            thePassword: ['']
        });
    };
    ShowPasswordComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.auth.currentUserDataObject === null) {
            this.router.navigate(['/vertical/dashboard']);
        }
        else {
            this.selectedId = this.auth.currentUserDataObject.id;
        }
    };
    ShowPasswordComponent.prototype.ngAfterViewInit = function () {
    };
    ShowPasswordComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ShowPasswordComponent.prototype.showPassword = function () {
        var _this = this;
        if (this.showPwdForm.valid) {
            this.adminService.showPassword(this.showPwdForm.value.theMobile).subscribe(function (passWordEnClair) {
                _this.showPwdForm.get('thePassword').setValue(passWordEnClair);
            });
        }
    };
    ShowPasswordComponent.prototype.onSubmit = function () {
        this.showPassword();
    };
    return ShowPasswordComponent;
}(BasePageComponent));
export { ShowPasswordComponent };
