import {Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { MarcheService } from '../marche.service';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {Marche} from '../../models/marche';
import {CommuneService} from '../../commune/commune.service';
import {Commune} from '../../models/commune';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {TypologieService} from '../typologie.service';
import {fadeIn} from '../../../../animations/form-error';
import {INoeud} from '../../produit/create';
import {PeriodiciteService} from '../periodicite.service';


@Component({
    selector: 'tc-marche-create',
    templateUrl: './create-marche.component.html',
    styleUrls: ['./create-marche.component.scss'],
    animations: [fadeIn]
})

export class PageCreateMarcheComponent extends BasePageComponent implements OnInit, OnDestroy,  AfterViewInit {
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private marcheService: MarcheService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private typologieService: TypologieService,
                 private communeService: CommuneService,
                 private periodiciteService: PeriodiciteService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.marche = new Marche();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des marches',
                    route: 'list-marche'
                }
            ]
        };
        this.initReactiveForm();
    }
   filterable = true;
    marcheForm: FormGroup;
    marche: any;
    communeTree: INoeud[] = [];
    typologies: IOption[] = [];
    periodicites: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
   Columnslabels =
        {
            code: 'Code Marché',
            label: 'Libellé Marché',
            typologie: 'Type Marché',
            longitude: 'Longitude',
            latitude: 'Latitude',
            periodicite: 'Périodicité',
            commune: 'Commune'

        };
    ngAfterViewInit(): void {
    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.marche) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
       return  this.isEditing() ? ' Modifier un marché ' : ' Créer un marché ' ;
    }
    isEditing(): boolean {
        return (this.marche.id != null);
    }
   initReactiveForm() {
        this.marcheForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                typologie: ['', [Validators.required]],
                periodicite: ['', [Validators.required]],
                longitude: [''],
                latitude: [''],
                commune: [''],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        const commune =  (this.marche.communeTab != null) ? this.marche.communeTab : ['', '', ''] ;
        this.marcheForm.patchValue({
            code: this.marche.code,
            nom: this.marche.nom,
            typologie: this.marche.typologie,
            longitude: this.marche.longitude,
            latitude: this.marche.latitude,
            actif: this.marche.actif,
            periodicite: this.marche.periodicite,
            commune: commune
            }
    );
    }
    getMarche (jsonValue): Marche {
        return Marche.fromJSON(jsonValue) ;
    }

    ngOnInit() {
        super.ngOnInit();
        this.getData(this.typologieService.getAllURL(), 'typologies');
        this.getData(this.periodiciteService.getAllURL(), 'periodicites');
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.marcheService.getURL(this.selectedId), 'marche', 'marche', 'setLoaded');
            } else {
                this.marche = new Marche();
                this.initReactiveForm();
            }
        });
        this.communeService.findAsTree().subscribe(tree => {
                this.communeTree = tree;
            }
        );
        // detect form changes
        this.marcheForm.valueChanges.subscribe(() => {
            this.changes = true;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-marche']);
    }
    liste() {
        this.router.navigate(['/vertical/list-marche']);
    }
    mapFormValuesToModel() {
        this.marche.code =  this.marcheForm.value.code;
        this.marche.nom =  this.marcheForm.value.nom;
        this.marche.typologie =  this.marcheForm.value.typologie;
        this.marche.longitude =  this.marcheForm.value.longitude;
        this.marche.latitude =  this.marcheForm.value.latitude;
        this.marche.actif =  this.marcheForm.value.actif;
        this.marche.periodicite =  this.marcheForm.value.periodicite;
        this.marche.commune =  this.marcheForm.value.commune[2];

    }
    update(): void {
        if (this.marcheForm.valid) {
        this.mapFormValuesToModel();
        this.marcheService.update(this.marche.id, this.marche)
            .subscribe(
                data => {

                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/list-marche']);
                        }
                ,
                error => console.log(error));
        }

    }
    create(): void {
        if (this.marcheForm.valid) {
            this.mapFormValuesToModel();
            this.marcheService.create(this.marche)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.marcheForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
