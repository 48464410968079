var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { EspeceService } from '../espece.service';
import { ToastrService } from 'ngx-toastr';
import { NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Espece } from '../../models/espece';
var PageCreateEspeceComponent = /** @class */ (function (_super) {
    __extends(PageCreateEspeceComponent, _super);
    function PageCreateEspeceComponent(store, httpSv, especeService, route, router, toastr, i18n, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.especeService = especeService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code espece',
            nom: 'Nom espece',
            description: 'Description',
            actif: 'Actif'
        };
        _this.espece = new Espece();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des espèces',
                    route: 'list-espece'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateEspeceComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.espece) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateEspeceComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier une espèce ' : ' Créer une espèce ';
    };
    PageCreateEspeceComponent.prototype.isEditing = function () {
        return (this.espece.id != null);
    };
    PageCreateEspeceComponent.prototype.initReactiveForm = function () {
        this.especeForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            description: [''],
            actif: [true]
        });
    };
    PageCreateEspeceComponent.prototype.getespece = function (jsonValue) {
        return Espece.fromJSON(jsonValue);
    };
    PageCreateEspeceComponent.prototype.setReactiveForm = function () {
        if (this.espece) {
            this.especeForm.patchValue({
                code: this.espece.code,
                nom: this.espece.nom,
                description: this.espece.description,
                actif: this.espece.actif
            });
        }
    };
    PageCreateEspeceComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.especeService.getURL(_this.selectedId), 'espece', 'espece', 'setLoaded');
            }
            else {
                _this.espece = new Espece();
                _this.initReactiveForm();
            }
        });
    };
    PageCreateEspeceComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateEspeceComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-espece']);
    };
    PageCreateEspeceComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-espece']);
    };
    PageCreateEspeceComponent.prototype.mapFormValuesToModel = function () {
        this.espece.code = this.especeForm.value.code;
        this.espece.nom = this.especeForm.value.nom;
        this.espece.description = this.especeForm.value.description;
        this.espece.actif = this.especeForm.value.actif;
    };
    PageCreateEspeceComponent.prototype.update = function () {
        var _this = this;
        if (this.especeForm.valid) {
            this.mapFormValuesToModel();
            this.especeService.update(this.espece.id, this.espece)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-espece']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateEspeceComponent.prototype.create = function () {
        var _this = this;
        if (this.especeForm.valid) {
            this.mapFormValuesToModel();
            this.especeService.create(this.espece)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie  ', '', { timeOut: 2000 });
                _this.especeForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateEspeceComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateEspeceComponent;
}(BasePageComponent));
export { PageCreateEspeceComponent };
