var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ElementRef, ChangeDetectorRef, EventEmitter, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TCInputComponent } from '../input';
var TCAutocompleteComponent = /** @class */ (function (_super) {
    __extends(TCAutocompleteComponent, _super);
    function TCAutocompleteComponent(element, changeDetector, sanitizer) {
        var _this = _super.call(this, element) || this;
        _this.changeDetector = changeDetector;
        _this.sanitizer = sanitizer;
        _this.simpleInput = false;
        _this.attached = false;
        _this.results = [];
        _this.arrowKeyLocation = -1;
        _this.data = [];
        _this.openedList = false;
        _this.opened = new EventEmitter();
        _this.closed = new EventEmitter();
        _this.optionSelected = new EventEmitter();
        _this.listMarkColor = '#cd5447';
        return _this;
    }
    TCAutocompleteComponent.prototype.ngOnInit = function () { };
    TCAutocompleteComponent.prototype.handleClick = function (event) {
        if (this.opened && !this.element.nativeElement.contains(event.target)) {
            this.closeList();
        }
    };
    // model change
    TCAutocompleteComponent.prototype.modelChanged = function (val) {
        this.results = this.getResults(this.data, val, this.key);
        !this.openedList && val && this.results ? this.openList() : null;
        this.openedList && (!val || !this.results.length) ? this.closeList() : null;
    };
    // open list
    TCAutocompleteComponent.prototype.openList = function () {
        this.opened.emit();
        this.openedList = true;
    };
    // close list
    TCAutocompleteComponent.prototype.closeList = function () {
        this.closed.emit();
        this.openedList = false;
        this.arrowKeyLocation = -1;
    };
    // get autocomplete list
    TCAutocompleteComponent.prototype.getResults = function (data, val, key) {
        if (!data.length) {
            return [];
        }
        return data.filter(function (item) {
            var v = key ? item[key] : item;
            return v.toLowerCase().indexOf(val.toLowerCase()) > -1;
        });
    };
    // mark filled value
    TCAutocompleteComponent.prototype.markValue = function (label, val) {
        var text = label;
        var regex = new RegExp(val, 'gi');
        var COLOR = this.listMarkColor;
        if (val) {
            text = text.replace(regex, function (match) {
                return "<span class='val-mark' style='color: " + COLOR + "'>" + match + "</span>";
            });
        }
        return this.sanitizer.bypassSecurityTrustHtml(text);
    };
    // detect focus event
    TCAutocompleteComponent.prototype.autocompleteOnFocus = function () {
        this.focus.emit();
    };
    // detect blur event
    TCAutocompleteComponent.prototype.autocompleteOnBlur = function () {
        this.blur.emit();
    };
    // select item
    TCAutocompleteComponent.prototype.selectItem = function (value, event) {
        event ? event.preventDefault() : null;
        var newVal = this.key ? value[this.key] : value;
        if (newVal.length > this.charLimiting) {
            newVal = newVal.slice(0, this.charLimiting);
        }
        if (this.charLimiting > 0) {
            _super.prototype.changeCharLength.call(this, this.charLimiting, newVal.length);
        }
        this.innerValue = newVal;
        this.optionSelected.emit(newVal);
        this.onChange(newVal);
        this.closeList();
    };
    // key click map
    TCAutocompleteComponent.prototype.keyClick = function (event) {
        switch (event.key) {
            case 'Enter':
                if (this.arrowKeyLocation >= 0 && this.results.length) {
                    this.selectItem(this.results[this.arrowKeyLocation]);
                }
                break;
            case 'Escape':
                this.closeList();
                break;
            case 'ArrowUp':
                this.arrowKeyLocation > 0 ? this.arrowKeyLocation-- : null;
                break;
            case 'ArrowDown':
                this.arrowKeyLocation + 1 < this.results.length
                    ? this.arrowKeyLocation++
                    : null;
        }
    };
    // custom component style
    TCAutocompleteComponent.prototype.getListStyles = function () {
        return {
            color: this.listColor ? this.listColor : null,
            background: this.listBgColor ? this.listBgColor : null,
            'border-color': this.listBorderColor ? this.listBorderColor : null,
        };
    };
    TCAutocompleteComponent.prototype.getMarkColor = function () {
        return this.listMarkColor ? this.listMarkColor : null;
    };
    return TCAutocompleteComponent;
}(TCInputComponent));
export { TCAutocompleteComponent };
