/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./form-label.component";
var styles_TCFormLabelComponent = [i0.styles];
var RenderType_TCFormLabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCFormLabelComponent, data: {} });
export { RenderType_TCFormLabelComponent as RenderType_TCFormLabelComponent };
export function View_TCFormLabelComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_TCFormLabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-form-label", [], [[2, "tc-form-label", null]], null, null, View_TCFormLabelComponent_0, RenderType_TCFormLabelComponent)), i1.ɵdid(1, 114688, null, 0, i2.TCFormLabelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
var TCFormLabelComponentNgFactory = i1.ɵccf("tc-form-label", i2.TCFormLabelComponent, View_TCFormLabelComponent_Host_0, {}, {}, ["*"]);
export { TCFormLabelComponentNgFactory as TCFormLabelComponentNgFactory };
