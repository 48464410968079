import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector } from '@angular/core';
import { TCModalComponent } from '../../components/modal';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var TCModalService = /** @class */ (function () {
    function TCModalService(resolver, injector, appRef, document) {
        this.resolver = resolver;
        this.injector = injector;
        this.appRef = appRef;
        this.document = document;
    }
    TCModalService.prototype.open = function (modal) {
        var _this = this;
        var FACTORY = this.resolver.resolveComponentFactory(TCModalComponent);
        this.componentRef = FACTORY.create(this.injector);
        // add modal @Input parameters
        this.componentRef.instance.body = modal.body;
        this.componentRef.instance.header = modal.header;
        this.componentRef.instance.footer = modal.footer;
        this.componentRef.instance.opened = true;
        this.componentRef.instance.options = modal.options;
        // subscribe @Output close event
        this.componentRef.instance.close.subscribe(function (data) {
            data ? _this.close() : null;
        });
        var nativeElement = this.componentRef.location.nativeElement;
        this.componentRef.hostView.detectChanges();
        this.appRef.attachView(this.componentRef.hostView);
        this.document.body.appendChild(nativeElement);
    };
    TCModalService.prototype.close = function () {
        var _this = this;
        if (this.componentRef) {
            this.componentRef.instance.hideModal();
            setTimeout(function () {
                _this.appRef.detachView(_this.componentRef.hostView);
                _this.componentRef.destroy();
            }, 300);
        }
    };
    TCModalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TCModalService_Factory() { return new TCModalService(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i1.DOCUMENT)); }, token: TCModalService, providedIn: "root" });
    return TCModalService;
}());
export { TCModalService };
