import { EventEmitter } from '@angular/core';
var TCSortTableDirective = /** @class */ (function () {
    function TCSortTableDirective() {
        this.sortChanged = new EventEmitter();
    }
    Object.defineProperty(TCSortTableDirective.prototype, "config", {
        get: function () {
            return this.tcTableSort;
        },
        set: function (value) {
            this.tcTableSort = value;
        },
        enumerable: true,
        configurable: true
    });
    TCSortTableDirective.prototype.onToggleSort = function (event) {
        if (event) {
            event.preventDefault();
        }
        if (this.column.enableSorting) {
            switch (this.column.sort) {
                case 'asc':
                    this.column.sort = 'desc';
                    break;
                case 'desc':
                    this.column.sort = '';
                    break;
                default:
                    this.column.sort = 'asc';
                    break;
            }
            this.sortChanged.emit(this.column);
        }
    };
    return TCSortTableDirective;
}());
export { TCSortTableDirective };
