import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Reseau} from '../models/reseau';
import {ReseauService} from './reseau.service';


export class ReseauDatasource implements DataSource<Reseau> {

    private reseauxSubject = new BehaviorSubject<Reseau[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Reseau[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private reseauService: ReseauService) {

    }


    loadReseaus(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.reseauService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(reseaux => this.reseauxSubject.next(reseaux)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Reseau[]> {
        return this.reseauxSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.reseauxSubject.complete();
        this.loadingSubject.complete();
    }

}

