import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OffreAchatService = /** @class */ (function () {
    function OffreAchatService(http) {
        this.http = http;
        this.baseUrl = '/api/offre_achat';
    }
    OffreAchatService.prototype.get = function (id) {
        return this.http.get("/api/offreAchat/" + id);
    };
    OffreAchatService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    OffreAchatService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    OffreAchatService.prototype.getAcheteur = function (mobilePhone) {
        return this.http.get(this.baseUrl + "/acheteur", {
            params: new HttpParams()
                .set('mobilePhone', mobilePhone)
        }).pipe(map(function (result) { return result['resultat']; }));
    };
    OffreAchatService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    OffreAchatService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    OffreAchatService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    OffreAchatService.prototype.findAll = function (filter, sortField, sortOrder, offset, pageSize) {
        if (filter === void 0) { filter = ''; }
        if (sortField === void 0) { sortField = 'nomAuteur'; }
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.get('/api/offre_achat/pages', {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sortField)
                .set('sortOrder', sortOrder)
                .set('offset', offset.toString())
                .set('max', pageSize.toString())
        });
    };
    OffreAchatService.prototype.findCount = function () {
        return this.http.get('/api/offre_achat/count')
            .pipe(map(function (res) { return res['total']; }));
    };
    OffreAchatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OffreAchatService_Factory() { return new OffreAchatService(i0.ɵɵinject(i1.HttpClient)); }, token: OffreAchatService, providedIn: "root" });
    return OffreAchatService;
}());
export { OffreAchatService };
