import { Province } from './province';
var Commune = /** @class */ (function () {
    function Commune() {
        this.province = new Province();
    }
    Commune.fromJSON = function (json) {
        var result = new Commune();
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    Object.defineProperty(Commune.prototype, "nomProvince", {
        get: function () {
            if (this.province != null) {
                return this.province.nom;
            }
            return '--';
        },
        enumerable: true,
        configurable: true
    });
    Commune.listFromJSON = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var commune = Commune.fromJSON(jsonObject);
                if (commune.province != null) {
                    jsonObject.province = commune.province.nom;
                }
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    return Commune;
}());
export { Commune };
