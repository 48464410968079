var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ActiviteService } from '../activite.service';
import { ToastrService } from 'ngx-toastr';
import { Activite } from '../../models/activite';
import { NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SecteurActiviteService } from '../../secteurActivite/secteurActivite.service';
var PageCreateActiviteComponent = /** @class */ (function (_super) {
    __extends(PageCreateActiviteComponent, _super);
    function PageCreateActiviteComponent(store, httpSv, activiteService, route, router, toastr, secteurActiviteService, i18n, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.activiteService = activiteService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.secteurActiviteService = secteurActiviteService;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.secteursActivitesAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code Activite',
            label: 'Libellé Activite',
            secteurActivite: 'Secteur Activite'
        };
        _this.activite = new Activite();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des activites',
                    route: 'list-activite'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateActiviteComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.activite) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateActiviteComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier une activite ' : ' Créer une activite ';
    };
    PageCreateActiviteComponent.prototype.isEditing = function () {
        return (this.activite.id != null);
    };
    PageCreateActiviteComponent.prototype.initReactiveForm = function () {
        this.activiteForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            secteurActivite: [''],
            actif: [true]
        });
    };
    PageCreateActiviteComponent.prototype.setReactiveForm = function () {
        if (this.activite) {
            var secteurActivite = (this.activite.secteurActivite === undefined) ? '' : this.activite.secteurActivite.id.toString();
            this.activiteForm.patchValue({
                code: this.activite.code,
                nom: this.activite.nom,
                secteurActivite: secteurActivite,
                actif: this.activite.actif
            });
        }
    };
    PageCreateActiviteComponent.prototype.getActivite = function (jsonValue) {
        return Activite.fromJSON(jsonValue);
    };
    PageCreateActiviteComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getData(this.secteurActiviteService.getAllURL(), 'secteursActivites', 'onSecteurActivitesPromise');
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.activiteService.getURL(_this.selectedId), 'activite', 'activite', 'setLoaded');
            }
            else {
                _this.activite = new Activite();
                _this.initReactiveForm();
            }
        });
    };
    PageCreateActiviteComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateActiviteComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-activite']);
    };
    PageCreateActiviteComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-activite']);
    };
    PageCreateActiviteComponent.prototype.mapFormValuesToModel = function () {
        this.activite.code = this.activiteForm.value.code;
        this.activite.nom = this.activiteForm.value.nom;
        this.activite.secteurActivite = this.activiteForm.value.secteurActivite;
        this.activite.actif = this.activiteForm.value.actif;
    };
    PageCreateActiviteComponent.prototype.update = function () {
        var _this = this;
        if (this.activiteForm.valid) {
            this.mapFormValuesToModel();
            this.activiteService.update(this.activite.id, this.activite)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-activite']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateActiviteComponent.prototype.onSecteurActivitesPromise = function () {
        for (var _i = 0, _a = this.secteursActivites; _i < _a.length; _i++) {
            var secteurActivite = _a[_i];
            this.secteursActivitesAdapter.push({ 'value': secteurActivite.id.toString(), 'label': secteurActivite.nom });
        }
        this.setReactiveForm();
    };
    PageCreateActiviteComponent.prototype.create = function () {
        var _this = this;
        if (this.activiteForm.valid) {
            this.mapFormValuesToModel();
            this.activiteService.create(this.activite)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie  ', '', { timeOut: 2000 });
                _this.activiteForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateActiviteComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateActiviteComponent;
}(BasePageComponent));
export { PageCreateActiviteComponent };
