import { Commune } from './commune';
import 'rxjs/add/observable/of';
var Marche = /** @class */ (function () {
    function Marche() {
        this.typologie = 'Consommation';
        this.actif = true;
        this.longitude = 0;
        this.latitude = 0;
        this.commune = new Commune();
    }
    Marche.fromJSON = function (json) {
        var result = new Marche();
        // tslint:disable-next-line:forin
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    Marche.listFromJSON = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var marche = Marche.fromJSON(jsonObject);
                jsonObject.commune = marche.nomDeCommune;
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    Marche.prototype.toString = function () {
        return this.nom;
    };
    Object.defineProperty(Marche.prototype, "nomDeCommune", {
        get: function () {
            if (this.commune != null) {
                return this.commune.nom;
            }
            return '--';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Marche.prototype, "getFullName", {
        get: function () {
            return (this.nom + ' (' + this.code + ')');
        },
        enumerable: true,
        configurable: true
    });
    return Marche;
}());
export { Marche };
