var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../pages/ui/administrateur/user.service';
import { GenreService } from '../../../services/sharedServices/genre.service';
import { ReseauService } from '../../../pages/ui/reseau/reseau.service';
import { Acteur } from '../../../pages/ui/models/acteur';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
var PageCreateCompteComponent = /** @class */ (function (_super) {
    __extends(PageCreateCompteComponent, _super);
    function PageCreateCompteComponent(store, httpSv, route, router, toastr, genreService, i18n, reseauService, fb, httpClient, adminService, loginservice) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.genreService = genreService;
        _this.i18n = i18n;
        _this.reseauService = reseauService;
        _this.fb = fb;
        _this.httpClient = httpClient;
        _this.adminService = adminService;
        _this.loginservice = loginservice;
        _this.SERVER_URL = 'api/upload_avatar';
        _this.genres = [];
        _this.reseauxAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            nom: 'Nom',
            prenom: 'Prénom (s)',
            mobilePhone: 'N° Mobile',
            genre: 'Sexe',
            email: 'Email',
            reseau: 'Réseau',
            enabled: 'Actif'
        };
        _this.administrateur = {};
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    PageCreateCompteComponent.prototype.getTitle = function () {
        return 'Compte : ' + this.getNomComplet();
    };
    PageCreateCompteComponent.prototype.getNomComplet = function () {
        return this.administrateur.nom + ' ' + this.administrateur.prenom;
    };
    PageCreateCompteComponent.prototype.onGenresPromise = function () {
        console.log(this.genres);
    };
    PageCreateCompteComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.pageData.title = this.getTitle();
        this.setReactiveForm();
    };
    PageCreateCompteComponent.prototype.initReactiveForm = function () {
        this.userForm = this.fb.group({
            mobilePhone: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            prenom: ['', [Validators.required, Validators.minLength(2)]],
            email: ['', [Validators.required, Validators.email]],
            genre: ['', Validators.required],
            reseau: ['', Validators.required],
            enabled: [true]
        });
    };
    PageCreateCompteComponent.prototype.setReactiveForm = function () {
        if (this.administrateur) {
            this.userForm.patchValue({
                mobilePhone: this.administrateur.mobilePhone,
                nom: this.administrateur.nom,
                prenom: this.administrateur.prenom,
                email: this.administrateur.email,
                enabled: this.administrateur.enabled,
                genre: this.administrateur.genre,
                reseau: (this.administrateur.reseau != null) ? this.administrateur.reseau.id.toString() : ''
            });
        }
    };
    PageCreateCompteComponent.prototype.onReseauxPromise = function () {
        for (var _i = 0, _a = this.reseaux; _i < _a.length; _i++) {
            var reseau = _a[_i];
            this.reseauxAdapter.push({ 'value': reseau.id.toString(), 'label': reseau.code });
        }
    };
    PageCreateCompteComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.userType = 'utilisateur';
        this.defaultAvatar = 'assets/content/anonymous-400.jpg';
        this.currentAvatar = this.defaultAvatar;
        this.administrateur = new Acteur();
        this.loginservice.authenticate('+22699999999', 'Anonyme*2019').subscribe(function (data) {
            sessionStorage.setItem('currentUser', JSON.stringify(data));
            _this.loginservice.setCurrentObject(data);
            _this.getData(_this.genreService.getAllURL(), 'genres', 'onGenresPromise');
            _this.getData(_this.reseauService.getAllURL(), 'reseaux', 'onReseauxPromise');
            // sessionStorage.removeItem('currentUser');
            // this.adminService.findMe().subscribe(
            //     userData => {
            //         this.loginservice.setCurrentUserDataObject(userData);
            //         sessionStorage.setItem('currentUserData', JSON.stringify(userData));
            //     }
            // );
        }, function (error) {
            var errorText;
            console.log('error', error);
            errorText = error;
            if ((errorText.toUpperCase().indexOf('TIMEOUT') >= 0) || (errorText.toUpperCase().indexOf('GATEWAY') >= 0)) {
                errorText = 'Vérifiez que le serveur rest a démarré ';
            }
            _this.toastr.error(errorText);
        });
    };
    PageCreateCompteComponent.prototype.ngAfterViewInit = function () {
    };
    PageCreateCompteComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateCompteComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-administrateur']);
    };
    PageCreateCompteComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-administrateur']);
    };
    PageCreateCompteComponent.prototype.mapFormValuesToModel = function () {
        this.administrateur.mobilePhone = this.userForm.value.mobilePhone;
        this.administrateur.nom = this.userForm.value.nom;
        this.administrateur.prenom = this.userForm.value.prenom;
        this.administrateur.email = this.userForm.value.email;
        this.administrateur.reseau = this.userForm.value.reseau;
        this.administrateur.enabled = this.userForm.value.enabled;
        this.administrateur.genre = this.userForm.value.genre;
    };
    PageCreateCompteComponent.prototype.create = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.adminService.create(this.userType, this.administrateur)
            .subscribe(function (data) {
            _this.toastr.success('Création réussie de ' + _this.administrateur.toString(), '', { timeOut: 2000 });
            _this.router.navigate(['/public/sign-in']);
            // this.userForm.reset();
        }, function (error) { return console.log(error); });
    };
    PageCreateCompteComponent.prototype.onSubmit = function () {
        this.submitted = true;
        this.create();
    };
    // upload new file
    PageCreateCompteComponent.prototype.onFileChanged = function (inputValue) {
        var _this = this;
        var file = inputValue.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            _this.currentAvatar = reader.result;
            _this.changes = true;
        };
        reader.readAsDataURL(file);
        var formData = new FormData();
        formData.append('file', file);
        formData.append('avatar_id', 'avatar_' + this.administrateur.id);
        console.log('formData', formData.get('file'));
        var headers = new HttpHeaders({ 'content-type': 'multipart/form-data ' });
        this.httpClient.post(this.SERVER_URL, formData).subscribe(function (res) {
            console.log(res);
            _this.toastr.success('Succès de l\'\'importation ');
        }, function (err) {
            console.log(err);
            _this.toastr.error('Echec de l\'\'importation cause ' + err.toString());
        });
    };
    return PageCreateCompteComponent;
}(BasePageComponent));
export { PageCreateCompteComponent };
