import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EtalonnageService = /** @class */ (function () {
    function EtalonnageService(http) {
        this.http = http;
        this.baseUrl = '/api/etalonnage';
    }
    EtalonnageService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    EtalonnageService.prototype.getEtalonnages = function (marcheId) {
        return this.http.get(this.baseUrl + "/etalonnagesASaisir", {
            params: new HttpParams()
                .set('marcheId', marcheId)
        }).pipe(map(function (res) { return res['etalonnages']; }));
    };
    EtalonnageService.prototype.getAllURL = function () {
        return this.baseUrl + "/etalonnagesASaisir";
    };
    EtalonnageService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    EtalonnageService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/saveEtalonnage", obj);
    };
    EtalonnageService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    EtalonnageService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    EtalonnageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EtalonnageService_Factory() { return new EtalonnageService(i0.ɵɵinject(i1.HttpClient)); }, token: EtalonnageService, providedIn: "root" });
    return EtalonnageService;
}());
export { EtalonnageService };
