/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dropdown.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dropdown.component";
var styles_TCDropdownComponent = [i0.styles];
var RenderType_TCDropdownComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCDropdownComponent, data: {} });
export { RenderType_TCDropdownComponent as RenderType_TCDropdownComponent };
export function View_TCDropdownComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), i1.ɵncd(null, 1)], null, null); }
export function View_TCDropdownComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tc-dropdown", [], [[2, "tc-dropdown", null], [2, "opened", null]], null, null, View_TCDropdownComponent_0, RenderType_TCDropdownComponent)), i1.ɵdid(1, 114688, null, 2, i2.TCDropdownComponent, [], null, null), i1.ɵqud(335544320, 1, { dropdownButton: 0 }), i1.ɵqud(335544320, 2, { dropdownContent: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).getOpened; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var TCDropdownComponentNgFactory = i1.ɵccf("tc-dropdown", i2.TCDropdownComponent, View_TCDropdownComponent_Host_0, { closeEvent: "close" }, {}, ["tc-dropdown-button", "tc-dropdown-content"]);
export { TCDropdownComponentNgFactory as TCDropdownComponentNgFactory };
