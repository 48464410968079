import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PushSMSService {
	private baseUrl = '/api/envoyer-sms';
  constructor(private http: HttpClient) {

  }

  send(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}`, obj);
  }

}

