import { OnInit, ElementRef, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { state } from '../../interfaces/general';
var TCTextareaComponent = /** @class */ (function () {
    function TCTextareaComponent(element) {
        this.element = element;
        this.onChange = function () { };
        this.onTouched = function () { };
        this.name = '';
        this.textareaFocus = false;
        this.readonly = false;
        this.disabled = false;
        this.required = false;
        this.innerValue = '';
        this.states = state;
        this.autoGrow = false;
        this.resize = false;
        this.rows = 1;
        this.limiter = {
            counter: null,
            before: '',
            after: '',
        };
    }
    Object.defineProperty(TCTextareaComponent.prototype, "focus", {
        get: function () {
            return this.textareaFocus;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCTextareaComponent.prototype, "getResize", {
        get: function () {
            return !this.resize || this.autoGrow;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCTextareaComponent.prototype, "controlHeight", {
        get: function () {
            var HEIGHT = this.height;
            return typeof HEIGHT === 'number' ? HEIGHT + "px" : HEIGHT;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCTextareaComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChange(v);
            }
            if (this.charLimiter) {
                this.limiter.counter =
                    (this.charLimiter instanceof Object
                        ? this.charLimiter['counter']
                        : this.charLimiter) - this.innerValue.length;
            }
        },
        enumerable: true,
        configurable: true
    });
    TCTextareaComponent.prototype.onInput = function () {
        this.autoGrow ? this.adjust() : null;
    };
    TCTextareaComponent.prototype.ngOnInit = function () {
        this.setStyles(!this.disabled ? this.states.default : this.states.disabled);
        this.charLimiter ? this.setLimiter(this.charLimiter, this.value) : null;
    };
    TCTextareaComponent.prototype.setLimiter = function (limiter, value) {
        var VAL_LENGHT = value.length ? value.length : 0;
        if (limiter instanceof Object) {
            this.limiter = {
                counter: limiter['counter'] - VAL_LENGHT,
                before: limiter['before'],
                after: limiter['after'],
            };
        }
        else {
            this.limiter.counter = limiter - VAL_LENGHT;
        }
    };
    TCTextareaComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    TCTextareaComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    TCTextareaComponent.prototype.writeValue = function (value) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    };
    TCTextareaComponent.prototype.onFocus = function (disabled) {
        if (!this.textareaFocus && !disabled) {
            this.element.nativeElement.querySelector('.textarea-control').focus();
            this.textareaFocus = true;
            this.setStyles(this.states.focus);
        }
    };
    TCTextareaComponent.prototype.onBlur = function (disabled) {
        this.textareaFocus = false;
        !disabled ? this.setStyles(this.states.default) : null;
    };
    TCTextareaComponent.prototype.onMouseEnter = function () {
        !this.textareaFocus && !this.disabled && !this.readonly
            ? this.setStyles(this.states.hover)
            : null;
    };
    TCTextareaComponent.prototype.onMouseLeave = function () {
        !this.textareaFocus && !this.disabled && !this.readonly
            ? this.setStyles(this.states.default)
            : null;
    };
    TCTextareaComponent.prototype.setStyles = function (st, bg, border, color) {
        if (bg === void 0) { bg = this.bgColor; }
        if (border === void 0) { border = this.borderColor; }
        if (color === void 0) { color = this.color; }
        var styleIndex = 0;
        switch (st) {
            case this.states.hover:
                styleIndex = 1;
                break;
            case this.states.focus:
                styleIndex = 2;
                break;
            case this.states.disabled:
                styleIndex = 3;
                break;
            default:
                styleIndex = 0;
        }
        this.currentBgColor = bg instanceof Array ? bg[styleIndex] : bg;
        this.currentBorderColor =
            border instanceof Array ? border[styleIndex] : border;
        this.currentColor = color instanceof Array ? color[styleIndex] : color;
    };
    TCTextareaComponent.prototype.getStyles = function () {
        return {
            'background-color': this.currentBgColor,
            'border-color': this.currentBorderColor,
            color: this.currentColor,
        };
    };
    TCTextareaComponent.prototype.adjust = function () {
        var el = this.element.nativeElement.querySelector('textarea');
        if (el) {
            el.style.overflow = 'hidden';
            el.style.height = 'auto';
            el.style.height = el.scrollHeight + 2 + "px";
        }
    };
    return TCTextareaComponent;
}());
export { TCTextareaComponent };
