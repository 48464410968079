import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ChronoAlerteService = /** @class */ (function () {
    function ChronoAlerteService(http) {
        this.http = http;
        this.baseUrl = '/api/chronoAlerte';
        this.confBaseUrl = '/api/restapi';
    }
    ChronoAlerteService.prototype.get = function (id, mobilePhone) {
        return this.http.get(this.baseUrl + "/" + id, {
            params: new HttpParams()
                .set('mobilePhone', mobilePhone)
        });
    };
    ChronoAlerteService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ChronoAlerteService.prototype.getURL = function () {
        return "" + this.baseUrl;
    };
    ChronoAlerteService.prototype.update = function (value) {
        return this.http.post(this.baseUrl + "/modifier", value);
    };
    ChronoAlerteService.prototype.reconfigure = function () {
        return this.http.post(this.baseUrl + "/planning", 'Planning');
    };
    ChronoAlerteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChronoAlerteService_Factory() { return new ChronoAlerteService(i0.ɵɵinject(i1.HttpClient)); }, token: ChronoAlerteService, providedIn: "root" });
    return ChronoAlerteService;
}());
export { ChronoAlerteService };
