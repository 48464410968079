import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { AlerteStockService } from '../alerteStock.service';
import { ToastrService } from 'ngx-toastr';
import {Alerte} from '../../models/alerte';
import {FormBuilder,
    FormGroup, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {IOption} from '../../../../ui/interfaces/option';
import {ProduitService} from '../../produit/produit.service';
import {MarcheService} from '../../marche/marche.service';
import {DepotService} from '../../depot/depot.service';
import {GroupeService} from '../../groupe/groupe.service';
import {INoeud} from '../../produit/create';
import {Acteur} from '../../models/acteur';
import {Marche} from '../../models/marche';
import {Produit} from '../../models/produit';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AlerteEdit} from '../../models/alerteEdit';
@Component({
    selector: 'tc-alerte-stock-create',
    templateUrl: './create-alerteStock.component.html',
     animations: [fadeIn]
})
export class PageCreateAlerteStockComponent extends BasePageComponent implements OnInit, OnDestroy {

    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private alerteStockService: AlerteStockService,
                 private route: ActivatedRoute, private http: HttpClient,
                 public router: Router, private toastr: ToastrService,
                 private produitService: ProduitService,
                 private depotService: DepotService,
                 private groupeService: GroupeService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.alerte = new Alerte();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.initReactiveForm();
    }
    alerteForm: FormGroup;
    alerte: any;
    depotsAdapter: IOption[] = [];
    alerteType: string;
    depots: any[] = [];
    produits: any[] = [];
    produitsAdapter: IOption[] = [];
    groupes: any[] = [];
    groupesAdapter: IOption[] = [];
    alerteStockAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            libelle: 'Libellé Alerte',
            description: 'Description',
            categories: 'Catégories',
            produits: 'Produits',
            depots: 'Dépôts',
            groupes: 'Groupes',
            actif: 'actif'

        };
    getAlerte (jsonValue): AlerteEdit {
        return AlerteEdit.fromJSON(jsonValue) ;
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.setReactiveForm();
    }

    getTitle(): string {
        if ( this.isEditing()) {
            const prefixe = ' Modifier ' ;
                return prefixe + ' une alerte de stock';

        } else {
            const prefixe = ' Créer ' ;
                return prefixe + ' une alerte de stock';
        }


    }


    isEditing(): boolean {
        return (this.alerte.id != null);
    }
   initReactiveForm() {
        this.alerteForm = this.fb.group({
                libelle: ['', [Validators.required, Validators.minLength(2)]],
                produits: ['', Validators.required],
                groupes: ['', Validators.required],
                depots: ['', Validators.required],
                lundi: [false],
                mardi: [false],
                mercredi: [false],
                jeudi: [false],
                vendredi: [false],
                samedi: [false],
                dimanche: [false],
                recevoirParEmail: [false],
                recevoirParSMS: [true],
                actif: [true]
        }
        ) ;

    }


    setReactiveForm() {
        if ( this.alerte) {
            const alerteStock: AlerteEdit = this.getAlerte(this.alerte);
            const selectedGroupes = (alerteStock.selectedGroupes !== []) ? alerteStock.selectedGroupes : '';
            const selectedProducts = (alerteStock.selectedProducts !== []) ? alerteStock.selectedProducts : '';
            const selectedDepots =  (alerteStock.selectedDepots !== []) ? alerteStock.selectedDepots : '';
            this.alerteForm.patchValue({
                    libelle: this.alerte.libelle,
                    actif: this.alerte.actif,
                    lundi: this.alerte.lundi,
                    mardi: this.alerte.mardi,
                    mercredi: this.alerte.mercredi,
                    jeudi: this.alerte.jeudi,
                    vendredi: this.alerte.vendredi,
                    samedi: this.alerte.samedi,
                    dimanche: this.alerte.dimanche,
                    recevoirParEmail: this.alerte.recevoirParEmail,
                    recevoirParSMS: this.alerte.recevoirParSMS,
                    groupes: selectedGroupes,
                    produits: selectedProducts,
                    depots: selectedDepots
                }
            );
        }


    }
    getMarcheObject (jsonValue): Marche {
        return Marche.fromJSON(jsonValue) ;
    }
    getProduitObject (jsonValue): Produit {
        return Produit.fromJSON(jsonValue) ;
    }
    onAlertesPromise() {
        this.alerteStockAdapter = [];
        for (const alerteStock of this.alerte) {
            this.alerteStockAdapter.push({'value' : alerteStock.id.toString(), 'label' : alerteStock.nom});
        }
    }
    onDepotsPromise() {
        this.depotsAdapter = [];
        for (const marche of this.depots) {
            this.depotsAdapter.push({'value' : marche.id, 'label' : this.getMarcheObject(marche).getFullName});
        }

    }
    onGroupesPromise() {
        this.groupesAdapter = [];
        for (const groupe of this.groupes) {
            this.groupesAdapter.push({'value' : groupe.id, 'label' : groupe.nom});
        }

    }
    onProduitsPromise() {
        this.produitsAdapter = [];
        for (const produit of this.produits) {
            this.produitsAdapter.push({'value' : produit.id, 'label' : this.getMarcheObject(produit).getFullName});
        }

    }

    ngOnInit() {
        super.ngOnInit();
        this.depotService.getCached().subscribe(data => {
                this.depots = data;
                this.onDepotsPromise();
            }
        );
        this.produitService.getCached().subscribe(data => {
                this.produits = data;
                this.onProduitsPromise();
            }
        );
        this.groupeService.getCached().subscribe(data => {
                this.groupes = data;
                this.onGroupesPromise();
            }
        );

        this.route.params.subscribe(params => {
            this.alerteType = params['alerteType'];
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.alerteStockService.getURL(this.selectedId), 'alerte', 'alerte', 'setLoaded');
                this.pageData.title = this.getTitle();
            } else {
                this.alerte = new Alerte();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-alerteDepot']);
    }
    liste() {
        this.router.navigate(['/vertical/list-alerteDepot']);
    }
    mapFormValuesToModel() {
        this.alerte.libelle =  this.alerteForm.value.libelle;
        this.alerte.actif =  this.alerteForm.value.actif;
        this.alerte.produits =  this.alerteForm.value.produits;
        this.alerte.depots =  this.alerteForm.value.depots;
        this.alerte.groupes =  this.alerteForm.value.groupes;
        this.alerte.recevoirParEmail =  this.alerteForm.value.recevoirParEmail;
        this.alerte.recevoirParSMS =  this.alerteForm.value.recevoirParSMS;
        this.alerte.lundi =  this.alerteForm.value.lundi;
        this.alerte.mardi =  this.alerteForm.value.mardi;
        this.alerte.mercredi =  this.alerteForm.value.mercredi;
        this.alerte.jeudi =  this.alerteForm.value.jeudi;
        this.alerte.vendredi =  this.alerteForm.value.vendredi;
        this.alerte.samedi =  this.alerteForm.value.samedi;
        this.alerte.dimanche =  this.alerteForm.value.dimanche;
       }


    update(): void {
        if (this.alerteForm.valid) {
        this.mapFormValuesToModel();
        this.alerteStockService.update(this.alerte.id, this.alerteType, this.alerte)
            .subscribe(
                data => {

                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/liste-alertes-' + this.alerteType,
                                { alerteType: this.alerteType}]);
                        }
                ,
                error => console.log(error));
        }

}
    create(): void {
        if (this.alerteForm.valid) {
            this.mapFormValuesToModel();
            this.alerteStockService.create(this.alerteType, this.alerte)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie ' , '',
                            {timeOut: 2000});
                        this.alerteStockAdapter = [];
                        this.alerteForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
