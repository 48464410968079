import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import { EChartOption } from 'echarts';
import {ValiderOffreAchatService} from '../../ui/valider-offre-achat/validerOffreAchat.service';
import {IOffreAchat} from '../../ui/valider-offre-achat/valider-offre-achat.component';
import {ValiderOffreVenteService} from '../../ui/valider-offre-vente/validerOffreVente.service';
import {IOffreVente} from '../../ui/valider-offre-vente/valider-offre-vente.component';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';
import {DashboardService} from './dashboard.service';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {Acteur} from '../../ui/models/acteur';
import {Observable} from 'rxjs';


@Component({
  selector: 'tc-page-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class PageDashboardComponent extends BasePageComponent implements OnInit, OnDestroy {
  chartsReady = false;
  pieOptions: EChartOption;
  cgOptions: EChartOption;
  dOptions: EChartOption;
  table: any[];
  pieStyle: any;
  statistiques: any;
  offresAchatsTop10: IOffreAchat[];
  offresVentesTop10: IOffreVente[];
  connectedUser: Acteur;


  constructor(store: Store<IAppState>, httpSv: HttpService, private offreAchatValidesService: ValiderOffreAchatService
      , private offreVenteValidesService: ValiderOffreVenteService,
              private dashboardService: DashboardService, private authService: AuthenticationService) {
    super(store, httpSv);
    this.pageData = {
      title: 'Tableau de bord',
      footerLoaded : true,
      breadcrumbs: [

      ],
    };
    this.pieStyle = {
      normal: {
        label: {
          color: '#000',
          position: 'inner'
        },
        labelLine: {
          show: false
        }
      }
    };
  }
  setCgOptions(datas) {
    this.cgOptions = {
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      tooltip : {
        trigger: 'item',
        formatter: '{b}<br>{c} ({d}%)'
      },
      series: [{
        name: 'pie',
        type: 'pie',
        selectedMode: 'single',
        selectedOffset: 30,
        clockwise: true,
        radius: [0, '90%'],
        data: datas,
        itemStyle: this.pieStyle
      }]
    };
  }

  setDOptions(datas) {
    this.dOptions = {
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      tooltip : {
        trigger: 'item',
        formatter: '{b}<br>{c} ({d}%)'
      },
      series: [{
        name: 'pie',
        type: 'pie',
        selectedMode: 'single',
        selectedOffset: 30,
        clockwise: true,
        radius: [0, '90%'],
        data: datas,
        itemStyle: this.pieStyle
      }]
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.connectedUser = this.authService.currentUserDataObject;
    this.setLoaded();
    this.offreAchatValidesService.getTopN('5').subscribe(datas => {
    this.offresAchatsTop10 = datas;
        }
    );


    this.offreVenteValidesService.getTopN('5').subscribe(datas => {
          this.offresVentesTop10 = datas;
        }
    );
    this.dashboardService.getStatistiques().subscribe(datas => {
    this.statistiques = datas;
          this.setCgOptions([
            {
              value: datas.femmes,
              name: 'Femmes',
              itemStyle: {
                normal: {
                  color: 'rgba(228,132,5,0.45)',
                  borderColor: '#e15200'
                }
              }
            },
            {
              value: datas.hommes,
              name: 'Hommes',
              itemStyle: {
                normal: {
                  color: '#d5edff',
                  borderColor: '#2fa7ff'
                }
              }
            }
          ]);
          const donnees = [];
          let color = 'rgba(5,172,76,0.4)';
          let borderColor = '#058d41' ;

          datas.totalParReseau.forEach((item, index) => {
            if (index === 1) {
               color = '#fee8d7';
               borderColor = '#e15200';
            } else
            if (index === 2) {
              color = '#e6deff';
              borderColor = '#805aff';
            } else
            if (index === 3) {
              color = '#d5edff';
              borderColor = '#2fa7ff';
            }
            donnees.push({value: item.Nbre, name: item.reseau, itemStyle: { normal: { color: color, borderColor: borderColor } }});
          });

         this.setDOptions(donnees);

          this.chartsReady = true;
       }
    );
   this.setPieOptions();


  }
  exportOffresVente() {
    ExcelService.exportAsExcelFile(this.offresVentesTop10, 'offresVente');
  }
  exportOffresAchat() {
    ExcelService.exportAsExcelFile(this.offresAchatsTop10, 'offresAchat');
  }
  isGroupeAdmin(): boolean {
     return Acteur.fromJSON(this.connectedUser).isGroupeAdmin ;

  }
  connectedName() {
    return this.connectedUser.nomComplet;
  }
  connectedLogin() {
    return this.connectedUser.mobilePhone;
  }
  connectedRole() {
    return this.connectedUser.role;
  }


  ngOnDestroy() {
    super.ngOnDestroy();
  }


  setPieOptions() {
    this.pieOptions = {
      color: ['#f741b5', '#2fa7ff', '#7cdb86'],
      series: [
        {
          type: 'pie',
          radius: ['90%', '88%'],
          label: {
            show: false,
          },
          data: [
            { value: 250 },
            { value: 500 },
            { value: 250 }
          ],
          markPoint : {
            data : [
              {
                x: '50%',
                y: '10px',
                symbol: 'emptyCircle',
                symbolSize: 15,
                itemStyle: {
                  normal: {
                    color: 'rgba(247,65,181,0.1)',
                    borderWidth: 5
                  }
                }
              },
              {
                x: '50%',
                y: '10px',
                symbol: 'circle',
                symbolSize: 5,
                itemStyle: {
                  normal: {
                    color: '#F741B5'
                  }
                }
              },
              {
                x: '170px',
                y: '50%',
                symbol: 'emptyCircle',
                symbolSize: 15,
                itemStyle: {
                  normal: {
                    color: 'rgba(47,167,255,0.1)',
                    borderWidth: 5
                  }
                }
              },
              {
                x: '170px',
                y: '50%',
                symbol: 'circle',
                symbolSize: 5,
                itemStyle: {
                  normal: {
                    color: '#2fa7ff'
                  }
                }
              },
              {
                x: '10px',
                y: '50%',
                symbol: 'emptyCircle',
                symbolSize: 15,
                itemStyle: {
                  normal: {
                    color: 'rgba(124,219,134,0.1)',
                    borderWidth: 5
                  }
                }
              },
              {
                x: '10px',
                y: '50%',
                symbol: 'circle',
                symbolSize: 5,
                itemStyle: {
                  normal: {
                    color: '#7cdb86'
                  }
                }
              }
            ]
          }
        }
      ]
    };
  }
}
