var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { RegionService } from '../region.service';
import { ToastrService } from 'ngx-toastr';
import { Region } from '../../models/region';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
var PageCreateRegionComponent = /** @class */ (function (_super) {
    __extends(PageCreateRegionComponent, _super);
    function PageCreateRegionComponent(store, httpSv, regionService, route, router, toastr, i18n, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.regionService = regionService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code',
            nom: 'Nom',
            actif: 'Actif'
        };
        _this.region = new Region();
        _this.pageData = {
            title: 'Créer une région',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des régions',
                    route: '/vertical/list-region'
                }
            ]
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    PageCreateRegionComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.setReactiveForm();
    };
    PageCreateRegionComponent.prototype.isEditing = function () {
        return (this.region.id != null);
    };
    PageCreateRegionComponent.prototype.initReactiveForm = function () {
        this.regionForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            actif: [true]
        });
    };
    PageCreateRegionComponent.prototype.setReactiveForm = function () {
        if (this.region) {
            this.regionForm.patchValue({
                code: this.region.code,
                nom: this.region.nom,
                actif: this.region.actif
            });
        }
    };
    PageCreateRegionComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.regionService.getURL(_this.selectedId), 'region', 'region', 'setLoaded');
            }
            else {
                _this.region = new Region();
                _this.initReactiveForm();
            }
        });
        // detect form changes
        this.regionForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
    };
    PageCreateRegionComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateRegionComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-region']);
    };
    PageCreateRegionComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-region']);
    };
    PageCreateRegionComponent.prototype.mapFormValuesToModel = function () {
        this.region.code = this.regionForm.value.code;
        this.region.nom = this.regionForm.value.nom;
    };
    PageCreateRegionComponent.prototype.update = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.regionService.update(this.region.id, this.region)
            .subscribe(function (data) {
            _this.regionService.update(_this.region.id, _this.region).subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-region']);
            });
        }, function (error) { return console.log(error); });
    };
    PageCreateRegionComponent.prototype.create = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.regionService.create(this.region)
            .subscribe(function (data) {
            _this.toastr.success('Création réussie de ' + _this.region.toString(), '', { timeOut: 2000 });
            _this.regionForm.reset();
        }, function (error) { return console.log(error); });
    };
    PageCreateRegionComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateRegionComponent;
}(BasePageComponent));
export { PageCreateRegionComponent };
