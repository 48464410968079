<form [formGroup]="rapportsForm" >

    <div class="row">
        <ng-container *ngIf="reseauxAdapter.length>0">

            <div class="col-sm-3 ">
                <tc-form-group>
                    <tc-form-label>Choix du réseau</tc-form-label>
                    <tc-select [options]="reseauxAdapter" formControlName="reseau"  [allowClear]="true" ></tc-select>
                </tc-form-group>
            </div>
        </ng-container>
        <ng-container *ngIf="produitsAdapter.length>0">

            <div class="col-sm-3 ">
                <tc-form-group>
                    <tc-form-label>Choix des produits</tc-form-label>
                    <tc-select [options]="produitsAdapter" formControlName="produits"  [filter]="true" [multiple]="true"  [allowClear]="true" ></tc-select>
                </tc-form-group>
            </div>

        </ng-container>
        <ng-container *ngIf=" marchesAdapter.length>0 ">

        <div class="col-sm-3 ">
                        <tc-form-group>
                            <tc-form-label>Choix des marchés</tc-form-label>
                            <tc-select [options]="marchesAdapter" formControlName="marches"  [filter]="true" [multiple]="true"  [allowClear]="true"  ></tc-select>
                        </tc-form-group>
            </div>

        </ng-container>

        <ng-container *ngIf="mesuresAdapter.length>0 ">

            <div class="col-sm-3 ">
                <tc-form-group>
                    <tc-form-label>Unités de mesure</tc-form-label>
                    <tc-select [options]="mesuresAdapter" formControlName="mesures"  [filter]="true" [multiple]="true"  [allowClear]="true"  ></tc-select>
                </tc-form-group>
            </div>
        </ng-container>

    </div>
    <div class="row" style="margin-bottom: 20px;">
        <div class="col-sm-2 ">
            <tc-form-group>
            <label>{{Columnslabels.dateDebut}}</label>
            <jqxDateTimeInput #dateDebut
                              [width]="'100%'" [height]="30"  [theme]='"sima-bf"' >
            </jqxDateTimeInput>
            </tc-form-group>
        </div>
        <div class="col-sm-2 ">
            <tc-form-group>
            <label>{{Columnslabels.dateFin}}</label>
            <jqxDateTimeInput #dateFin
                              [width]="'100%'" [height]="30"   [theme]='"sima-bf"' >
            </jqxDateTimeInput>
            </tc-form-group>
        </div>
        <ng-container *ngIf="typePrixAdapter.length>0 ">
                <div class="col-sm-2 ">
                    <tc-form-group>
                        <tc-form-label>Type de prix</tc-form-label>
                        <tc-select [options]="typePrixAdapter" formControlName="typePrix"  [allowClear]="true"  ></tc-select>
                    </tc-form-group>
                </div>
        </ng-container>
    </div>

<ng-container *ngIf="dataAdapter">
<!--    <tc-table [rows]="source.localData" [hovered]="true" [pagination]="true" [headerBgColor]="'#cd5447'" [headerColor]="'#fff'">-->
<!--        <tc-table-col [columnTitle]="'Réseau'" [columnName]="'reseau'"></tc-table-col>-->
<!--        <tc-table-col [columnTitle]="'Marché'" [columnName]="'nom_marche'"></tc-table-col>-->
<!--        <tc-table-col [columnTitle]="'Produit'" [columnName]="'nom_produit'"></tc-table-col>-->
<!--        <tc-table-col [columnTitle]="'Type'" [columnName]="'typePrix'"></tc-table-col>-->
<!--        <tc-table-col [columnTitle]="'Date'" [columnName]="'date'"></tc-table-col>-->
<!--        <tc-table-col [columnTitle]="'Prix'" [columnName]="'prix'"></tc-table-col>-->
<!--        <tc-table-col [columnTitle]="'Mesure'" [columnName]="'mesure'"></tc-table-col>-->

<!--    </tc-table>-->

    <jqxDataTable #myDataTable
              [width]="getWidth()"  [source]="dataAdapter" [columns]="columns" [columnsResize]="true"
                   [pageable]="true" [sortable]="true" [altRows]="true" [filterable]="true"  [pagerMode]="'advanced'"
              [localization]="localizationObject"  [theme]='"sima-bf"' [filterMode]="'advanced'"
                  [exportSettings]="exportSettings"

>
    </jqxDataTable>
    <div class="row" style="margin-top: 20px;">
        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="excelExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en Excel</jqxButton>
        </div>
        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="pdfExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en PDF</jqxButton>
        </div>
        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="jsonExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en JSON</jqxButton>
        </div>
    </div>

</ng-container>
</form>
