/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./public.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components/logo/logo.component.ngfactory";
import * as i3 from "../components/logo/logo.component";
import * as i4 from "@angular/router";
import * as i5 from "./public.component";
var styles_PublicLayoutComponent = [i0.styles];
var RenderType_PublicLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PublicLayoutComponent, data: {} });
export { RenderType_PublicLayoutComponent as RenderType_PublicLayoutComponent };
export function View_PublicLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "content-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "content-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "logo", [], [[2, "logo", null]], null, null, i2.View_LogoComponent_0, i2.RenderType_LogoComponent)), i1.ɵdid(4, 114688, null, 0, i3.LogoComponent, [], { src: [0, "src"], width: [1, "width"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "content-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(7, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_1 = "assets/img/logo-light.svg"; var currVal_2 = 112; _ck(_v, 4, 0, currVal_1, currVal_2); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); }); }
export function View_PublicLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "public-layout", [], null, null, null, View_PublicLayoutComponent_0, RenderType_PublicLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i5.PublicLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PublicLayoutComponentNgFactory = i1.ɵccf("public-layout", i5.PublicLayoutComponent, View_PublicLayoutComponent_Host_0, {}, {}, []);
export { PublicLayoutComponentNgFactory as PublicLayoutComponentNgFactory };
