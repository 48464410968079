import {Component,  OnDestroy, OnInit} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {IOffreAchat} from '../valider-offre-achat/valider-offre-achat.component';
import {ValiderOffreAchatService} from '../valider-offre-achat/validerOffreAchat.service';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';
export interface Data {

    id: number;
    name: string;
    age: number;
    address: string;
    disabled: boolean;
}


@Component({
  selector: 'tc-offres-achats-valides',
    templateUrl: './offres-achats-valides.component.html',
    styleUrls: ['./offres-achats-valides.component.scss'],
    animations: [fadeIn]
})

export class ListeOffresAchatsValidesComponent extends BasePageComponent implements OnInit, OnDestroy {

  dateValidation = new Date();
  data: any;
  offreAValider: IOffreAchat[];
    outData: any[] = [];

    /******************/

    isAllDisplayDataChecked = false;
    isOperating = false;
    isIndeterminate = false;
    listOfDisplayData: IOffreAchat[] = [];
    listOfAllDataOffreAchat: IOffreAchat[] = [];
    mapOfCheckedId: { [key: string]: boolean } = {};
    numberOfChecked = 0;
    /******************************/

    abandonner() {
        this.outData = [];
        this.listOfAllDataOffreAchat.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresAchat: this.outData
            }};
        if (this.outData.length > 0) {
            this.validerOffreAchatService.abandonner(this.data) .subscribe(
                data => {

                    this.toastr.success('abandon réussi des offres d\'achats sélectionnés', '',
                        {timeOut: 2000});
                    this.loadOffreAchat();
                },
                error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }
    devalider() {
        this.outData = [];
        this.listOfAllDataOffreAchat.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresAchat: this.outData
            }};
        if (this.outData.length > 0) {
            this.validerOffreAchatService.devalider(this.data) .subscribe(
                data => {
                    this.toastr.success('Dé-Validation réussie des offres selectionnées', '',
                        {timeOut: 2000});
                    this.loadOffreAchat();
                },
                error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }
    rejeter() {
        this.outData = [];
        this.listOfAllDataOffreAchat.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
            this.outData.push(item);
            }
        });

        this.data = {data: {
            dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresAchat: this.outData
        }};
        if (this.outData.length > 0) {
        this.validerOffreAchatService.rejeter(this.data) .subscribe(
            data => {

                this.toastr.success('rejet réussi des offres d\'achats  sélectionnés', '',
                    {timeOut: 2000});
                this.loadOffreAchat();
            },
            error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }

  constructor(store: Store<IAppState>,
              httpSv: HttpService, private marcheService: MarcheService,
              private route: ActivatedRoute, private authService: AuthenticationService,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService,
              private validerOffreAchatService: ValiderOffreAchatService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.listOfAllDataOffreAchat = [];
    this.loadOffreAchat();
  }


    private loadOffreAchat() {
        this.listOfAllDataOffreAchat = [];
        const reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerOffreAchatService.getAllValidesByReseau(reseauId.toString()).subscribe(data => {
                this.offreAValider = data;
                this.offreAValider.forEach((offreAchat, index, array) => {

                    this.listOfAllDataOffreAchat.push({
                        id: offreAchat.id,
                        date: offreAchat.date,
                        nom_produit: offreAchat.nom_produit,
                        quantite: offreAchat.quantite,
                        nom_auteur: offreAchat.nom_auteur,
                        mesure: offreAchat.mesure,
                        telephone: offreAchat.telephone,
                        email: offreAchat.email,
                        montant: offreAchat.montant,
                        reseau: offreAchat.reseau,
                        prixUnitaire: offreAchat.prixUnitaire,
                        date_expiration: offreAchat.date_expiration
                    });
                });
            }
        );
        this.mapOfCheckedId = {};
        this.refreshStatus();

    }

    ngOnDestroy() {
    super.ngOnDestroy();
  }

    currentPageDataChange($event: IOffreAchat[]): void {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    }

    refreshStatus(): void {
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(item => this.mapOfCheckedId[item.id]);
        this.isIndeterminate =
            this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
            !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataOffreAchat.filter(item => this.mapOfCheckedId[item.id]).length;
    }

    checkAll(value: boolean): void {
        this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
        this.refreshStatus();
    }

    operateData(): void {
        this.isOperating = true;
        setTimeout(() => {
            this.listOfAllDataOffreAchat.forEach(item => (this.mapOfCheckedId[item.id] = false));
            this.refreshStatus();
            this.isOperating = false;
        }, 1000);
    }
    exportByExcel() {
        ExcelService.exportAsExcelFile(this.offreAValider, 'offres_achats_valides');
    }

}
