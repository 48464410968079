import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { MesureService } from '../mesure.service';
import { ToastrService } from 'ngx-toastr';
import { fadeIn } from 'src/app/animations/form-error';
import {Mesure} from '../../models/mesure';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import { FormBuilder,
    FormGroup,Validators} from '@angular/forms';
import {TypeMesureService} from '../typeMesure.service';


@Component({
    selector: 'tc-mesure-create',
    templateUrl: './create-mesure.component.html',
    animations: [fadeIn]
})
export class PageCreateMesureComponent extends BasePageComponent implements OnInit, OnDestroy {
    mesureForm: FormGroup;
    mesure: any;
    typesMesures: any[];
    submitted = false;
    changes = false;
    selectedId: number;
    Columnslabels =
        {
            code: 'Code Mesure',
            nom: 'Nom Mesure ',
            typeMesure: 'Type Mesure ',
            inclureEtalonnage: 'Inclure Etalonnage'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private mesureService: MesureService,
                 private route: ActivatedRoute, private typeMesureService: TypeMesureService,
                 public router: Router, private toastr: ToastrService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.mesure = new Mesure();
        this.pageData = {
            title: 'Créer une unité de mesure',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);
        this.initReactiveForm();

    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.setReactiveForm();

    }
    isEditing(): boolean {
        return (this.mesure.id != null);
    }

    initReactiveForm() {
        this.mesureForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                typeMesure: ['', [Validators.required, Validators.minLength(2)]],
                actif: [true],
                inclureEtalonnage: [true]

            }
        ) ;
    }
    setReactiveForm() {
        if (this.mesure) {
            this.mesureForm.patchValue({
                    code: this.mesure.code,
                    nom: this.mesure.nom,
                    typeMesure: this.mesure.typeMesure,
                    inclureEtalonnage: this.mesure.inclureEtalonnage ? this.mesure.inclureEtalonnage : true,
                    actif: this.mesure.actif ? this.mesure.actif : true,
                }
            );
        }

    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.typeMesureService.getAllURL(), 'typesMesures');
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.mesureService.getURL(this.selectedId), 'mesure', 'mesure', 'setLoaded');
            } else {
                this.mesure = new Mesure();
                this.initReactiveForm();
            }
        });
        // detect form changes
        this.mesureForm.valueChanges.subscribe(() => {
            this.changes = true;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-mesure']).then(() => {
            // Do something
        });
    }
    liste() {
        this.router.navigate(['/vertical/list-mesure']).then(() => {
            // Do something
        });
    }
    mapFormValuesToModel() {
        this.mesure.code =  this.mesureForm.value.code;
        this.mesure.nom =  this.mesureForm.value.nom;
        this.mesure.actif =  this.mesureForm.value.actif;
        this.mesure.inclureEtalonnage =  this.mesureForm.value.inclureEtalonnage;
        this.mesure.typeMesure =  this.mesureForm.value.typeMesure;
    }
    update(): void {
        this.mapFormValuesToModel();
        // this.mesureService.update(this.mesure.id, this.mesure)
        //     .subscribe(
        //         data => {

                    this.mesureService.update(this.mesure.id, this.mesure).subscribe(
                        data => {
                            console.log("modification de l'objet " , data);
                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/list-mesure']).then(() => {
                                // Do something
                            });
                        },
                        error => console.log(error)
                    );
                //
                // },
                // error => console.log(error));
    }
    create(): void {
        this.mapFormValuesToModel();
        this.mesureService.create(this.mesure)
            .subscribe(
                data => {
                    console.log("creation de l'objet " , data);
                    this.toastr.success('Création réussie de ' + this.mesure.toString(), '',
                        {timeOut: 2000});
                    this.mesureForm.reset();
                }
                ,
                error => console.log(error));

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
