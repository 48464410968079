
<div class="row justify-content-center" >
    <div class="col-sm-12">
        <tc-card >
            <ng-container *ngIf="marchesAdapter.length>0">
                <div class="row">
                    <div class="col-sm-4 ">
                        <tc-form-group>
                            <tc-form-label>Sélectionnez le marché</tc-form-label>
                            <tc-select [options]="marchesAdapter" [(ngModel)]="SelectedMarketId"  name="marche" (ngModelChange)="onSelectMarche($event)" [filter]="true" ></tc-select>
                        </tc-form-group>
                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Date Saisie</tc-form-label>
                        <jqxDateTimeInput #datePrix
                                          [culture]="'fr-FR'">
                        </jqxDateTimeInput>`
                        <!--            <nz-date-picker [nzFormat]="'dd/MM/yyyy'" [ngModel]="datePrix"  name="datePrix" ></nz-date-picker>-->
                    </tc-form-group>
                </div>
                <div class="col-sm-4 ">
                </div>
                <div class="col-sm-4 ">
                    <div class="pull-right">
                        <button tc-button (click)="enregistrer()">Enregistrer</button>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="listOfData.length>0 && mesuresAdapter ">

                <nz-table #editRowTable  nzSize="middle" [nzData]="listOfData" >
                    <thead>
                    <tr>
                        <th nzWidth="30%">Nom du produit</th>
                        <th nzWidth="25%">Prix de gros</th>
                        <th nzWidth="15%">Mesure (gros)</th>
                        <th nzWidth="15%">Prix de détail</th>
                        <th nzWidth="15%">Mesure (détail)</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of editRowTable.data">
                        <td>
                            {{ data.nomProduit }}

                        </td>

                        <td>
                            <ng-container *ngIf="!editCache[data.id].edit; else prixGrosTpl">
                                {{data.prixGros | currency:'XOF':'symbol':'4.2-2':'fr'}}
                            </ng-container>
                            <ng-template #prixGrosTpl>
                                <label>
                                    <input  [(ngModel)]="editCache[data.id].data.prixGros" currencyMask nz-input  nzSize="small"  [options]="{ prefix: '', thousands: '.', decimal: ',',precision: 0, nullable: true }"/>
                                </label>
                            </ng-template>
                        </td>
                        <td>
                            <ng-container *ngIf="!editCache[data.id].edit; else mesureGrosTpl">
                                {{  getCodeMesure(data.mesureGros) }}
                            </ng-container>
                            <ng-template #mesureGrosTpl>
                                <label style="width: 100%;">
                                    <nz-select
                                            style="width: 100%;"  nzSize="small"
                                            nzPlaceHolder=""
                                            [(ngModel)]="editCache[data.id].data.mesureGros"
                                    >
                                        <nz-option *ngFor="let mesure of mesures"  nzLabel={{mesure.code}}  nzValue={{mesure.id}} >
                                        </nz-option>


                                    </nz-select>
                                </label>
                            </ng-template>
                        </td>

                        <td>
                            <ng-container *ngIf="!editCache[data.id].edit; else prixDetailTpl">

                                {{data.prixDetail}}
                            </ng-container>
                            <ng-template #prixDetailTpl>
                                <label>
                                    <input currencyMask  [(ngModel)]="editCache[data.id].data.prixDetail" nz-input  nzSize="small" [options]="{ prefix: '', thousands: '.', decimal: ',',precision: 0, nullable: true }"/>
                                </label>

                            </ng-template>
                        </td>

                        <td>
                            <ng-container *ngIf="!editCache[data.id].edit; else mesureDetailTpl">
                                {{  getCodeMesure(data.mesureDetail) }}
                            </ng-container>
                            <ng-template #mesureDetailTpl>
                                <label style="width: 100%;">
                                    <nz-select
                                            style="width: 100%;"  nzSize="small"
                                            nzPlaceHolder=""
                                            [(ngModel)]="editCache[data.id].data.mesureDetail"
                                    >
                                        <nz-option *ngFor="let mesure of mesures"  nzLabel={{mesure.code}}  nzValue={{mesure.id}} >
                                        </nz-option>


                                    </nz-select>
                                </label>
                            </ng-template>
                        </td>


                    </tr>
                    </tbody>
                </nz-table>
                <div class="row">
                    <div class="col-6 col-sm-8">
                        <button tc-button (click)="enregistrer()">Enregistrer</button>
                    </div>
                </div>
            </ng-container>
        </tc-card>
    </div>
</div>

