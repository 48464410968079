import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { InfoService } from '../info.service';
import { ToastrService } from 'ngx-toastr';
import {Info} from '../../models/info';
import { NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {INoeud} from '../../produit/create';
import {ProduitService} from '../../produit/produit.service';

@Component({
    selector: 'tc-info-create',
    templateUrl: './create-info.component.html',
    animations: [fadeIn]
})
export class PageCreateInfoComponent extends BasePageComponent implements OnInit, OnDestroy {
    infoForm: FormGroup;
    info: any;
    productTree: INoeud[];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            titre: 'Titre',
            contenu: 'Contenu',
            url: 'Url',
            dateExpiration: 'Date Validité',
            actif: 'Actif'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private infoService: InfoService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private i18n: NzI18nService, private produitService: ProduitService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.info = new Info();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des informations',
                    route: 'list-infos'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.info) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier une info ' : ' Créer une info ' ;
    }
    isEditing(): boolean {
        return (this.info.id != null);
    }



    initReactiveForm() {
        this.infoForm = this.fb.group({
                titre: ['', Validators.required],
                contenu: ['', [Validators.required, Validators.minLength(2)]],
                url: [''],
                actif: [true]
            }
        ) ;
    }
     getInfo (jsonValue): Info {
        return Info.fromJSON(jsonValue) ;
    }
    setReactiveForm() {
        if (this.info) {
            this.infoForm.patchValue({
                titre: this.info.titre,
                contenu: this.info.contenu,
                url: this.info.url,
                statut: this.info.statut,
                actif: this.info.actif
                }
            );

        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.infoService.getURL(this.selectedId), 'info', 'info', 'setLoaded');
            } else {
                this.info = new Info();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-info']);
    }
    liste() {
        this.router.navigate(['/vertical/list-info']);
    }
    mapFormValuesToModel() {

        this.info.titre =  this.infoForm.value.titre;
        this.info.contenu =  this.infoForm.value.contenu;
        this.info.url =  this.infoForm.value.url;
        this.info.actif =  this.infoForm.value.actif;
    }
    update(): void {
        if (this.infoForm.valid) {
            this.mapFormValuesToModel();
            this.infoService.update(this.info.id, this.info)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.router.navigate(['/vertical/list-info']);
                    }
                    ,
                    error => console.log(error));
        }

    }

    create(): void {
        if (this.infoForm.valid) {
            this.mapFormValuesToModel();
            this.infoService.create(this.info)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.infoForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
