<div class="bg"></div>

<div class="content-box">
  <div class="content-header">
    <logo [src]="'assets/img/logo-light.svg'" [width]="112"></logo>
  </div>

  <div class="content-body">
    <router-outlet></router-outlet>
  </div>
</div>