<ng-container *ngIf="province && regionsAdapter.length>0 ">
    <div class="col-sm-8">
        <tc-card [title]="''">
            <form [formGroup]="provinceForm"   (ngSubmit)="onSubmit()">


                <tc-form-group>
                    <tc-form-label>{{Columnslabels.region}}</tc-form-label>

                    <tc-select [options]="regionsAdapter" [filter]="true" formControlName="region" ></tc-select>

                </tc-form-group>



                <tc-form-group>
                    <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                    <tc-input type="text"  formControlName="code" >
                    </tc-input>
                    <ng-container *ngIf="provinceForm.get('code').invalid && (provinceForm.get('code').touched || provinceForm.get('code').dirty)">
                        <span @fadeIn class="error" *ngIf="provinceForm.get('code').getError('required')">Veuillez saisir le code du marché</span>
                        <span @fadeIn class="error" *ngIf="provinceForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                    </ng-container>
                </tc-form-group>

                <tc-form-group>
                    <tc-form-label>{{Columnslabels.label}}</tc-form-label>
                    <tc-input type="text"  formControlName="nom">
                    </tc-input>
                    <ng-container *ngIf="provinceForm.get('nom').invalid && (provinceForm.get('nom').touched || provinceForm.get('nom').dirty)">
                        <span @fadeIn class="error" *ngIf="provinceForm.get('nom').getError('required')">Veuillez saisir le nom de la province</span>
                        <span @fadeIn class="error" *ngIf="provinceForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                    </ng-container>
                </tc-form-group>


                <tc-form-group>
                    <tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
                </tc-form-group>

                <span *ngIf="!provinceForm.valid && provinceForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                <button [disabled]="!provinceForm.valid" tc-button [block]="true" >
                    Valider
                </button>




            </form>

        </tc-card>
    </div>
</ng-container>

