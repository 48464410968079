<ng-container *ngIf="pushSMS && groupesAdapter ">
    <div class="col-sm-8">
        <form [formGroup]="pushSMSForm" >
            <div class="content-body">
                        <tc-card [title]="'  '">

                          <div class="row">
                              <div class="col-sm-12">

                                  <tc-form-group>
                                      <tc-form-label>{{Columnslabels.groupe}}</tc-form-label>
                                      <tc-select [options]="groupesAdapter"  formControlName="groupe"   ></tc-select>
                                  </tc-form-group>
                              </div>
                          </div>
                          <br>

                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.message}}</tc-form-label>
                                <tc-textarea  [charLimiter]="{ counter: 1600, before: 'Il reste ', after: ' caractères' }"
                                              [tcShape]="0" [rows]="4" formControlName="message"></tc-textarea>
                            </tc-form-group>


                          <span *ngIf="!pushSMSForm.valid && pushSMSForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                          <button [disabled]="!pushSMSForm.valid && pushSMSForm.touched" (click)="envoyerSMS()" tc-button [block]="true" >
                              Envoyer SMS
                          </button>
                     </tc-card>

            </div>
         </form>
    </div>
</ng-container>

