import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserService} from '../administrateur/user.service';
import {fadeIn} from '../../../animations/form-error';
import {GenreService} from '../../../services/sharedServices/genre.service';
import {ActiviteService} from '../activite/activite.service';
import {CommuneService} from '../commune/commune.service';
import {ReseauService} from '../reseau/reseau.service';
import {GroupeService} from '../groupe/groupe.service';
import {Activite} from '../models/activite';
import {Commune} from '../models/commune';
import {Acteur} from '../models/acteur';
import {Reseau} from '../models/reseau';
import {fromEvent, merge, of, Subject, Subscription} from 'rxjs';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MarcheService} from '../marche/marche.service';
import {ProduitService} from '../produit/produit.service';
import {INoeud} from '../produit/create';
import {DepotService} from '../depot/depot.service';
import {ActeurEdit} from '../models/acteurEdit';
import {Marche} from '../models/marche';
import {Produit} from '../models/produit';
import {Depot} from '../models/depot';

export interface IchangePassword {
    status: string;
}
export interface IShowPassword {
    motPasseEnClair: string;
}

@Component({
    selector: 'tc-profil-administrateur',
    templateUrl: './profil-administrateur.component.html',
    styleUrls: ['./profil-administrateur.component.scss'],
    animations: [fadeIn]
})
export class PageProfilAdministrateurComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    SERVER_URL = 'api/upload_avatar';
    constructor(store: Store<IAppState>,
                httpSv: HttpService,
                private route: ActivatedRoute,
                public router: Router, private toastr: ToastrService,
                private genreService: GenreService, private activiteService: ActiviteService,
                private communeService: CommuneService, private i18n: NzI18nService,
                private reseauService: ReseauService,
                private marcheService: MarcheService,
                private depotService: DepotService,
                private fb: FormBuilder, private groupeService: GroupeService , private produitService: ProduitService,
                private adminService: UserService, private auth: AuthenticationService,
                private httpClient: HttpClient

    ) {
        super(store, httpSv);
        this.administrateur = {};
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);



        this.initReactiveForm();

    }
    currentAvatar: string | ArrayBuffer;
    defaultAvatar: string;
    userForm: FormGroup;
    userType: string;
    administrateur: any;
    genres: IOption[] = [];
    activites: Activite[];
    communeTree: INoeud[] = [];
    activitesAdapter: IOption[] = [];
    groupes: any[];
    reseaux: any[];
    reseauxAdapter: IOption[] = [];
    groupesAdapter: IOption[] = [];
    produits: any[];
    produitsAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    marches: any[];
    marchesAdapter: IOption[] = [];
    depots: any[];
    depotsAdapter: IOption[] = [];
    Columnslabels =
        {
            nom: 'Nom',
            prenom: 'Prénom',
            mobilePhone: 'N° Mobile',
            secondPhone: 'Tel. Secondaire',
            genre: 'Sexe',
            email: 'Email',
            activite: 'Activité',
            commune: 'Commune',
            reseau: 'Réseau',
            groupes: 'Groupes',
            enabled: 'Actif',
            marchespreference: 'Marchés de préférence',
            marchesuivis: 'Marchés suivis',
            depotPreference: 'Dépôts de préférence',
            depotSuivi: 'Dépôts suivis',
            produits: 'Produits'
        };
    static getAdministrateur (jsonValue): ActeurEdit {
        return ActeurEdit.fromJSON(jsonValue) ;
    }

    getTitle(): string {
       return 'Compte : ' + this.getNomComplet();

    }
    getNomComplet(): string {
        return  this.administrateur.nom + ' ' + this.administrateur.prenom;

    }

    onGenresPromise() {
    }
    getMarcheObject (jsonValue): Marche {
        return Marche.fromJSON(jsonValue) ;
    }
    getProduitObject (jsonValue): Produit {
        return Produit.fromJSON(jsonValue) ;
    }
    getDepotObject (jsonValue): Depot {
        return Depot.fromJSON(jsonValue) ;
    }
    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.pageData.title =  this.getTitle();
         this.setReactiveForm();
        this.userType = this.administrateur.role;
     }


    initReactiveForm() {
        this.userForm = this.fb.group({
                mobilePhone: ['', Validators.required],
                secondPhone: [''],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                prenom: ['', [Validators.required, Validators.minLength(2)]],
                email: ['', [Validators.required, Validators.email]],
                genre: ['', Validators.required],
                marcheSuivi: [''],
                marchePreference: [''],
                activite: [''],
                depotPreference: [''],
                depotSuivi: [''],
                commune: [''],
                reseau: [''],
                groupes: [''],
                produits: [''],
                enabled: [true]
            }
        ) ;


    }
    canChangeReseauAndGroupe(): boolean {
        const admin: ActeurEdit = PageProfilAdministrateurComponent.getAdministrateur(this.administrateur);
        return admin.isAdminGroup;
    }

    onMarchesPromise() {
        this.marchesAdapter = [];
        for (const marche of this.marches) {
            this.marchesAdapter.push({'value' : marche.id.toString(), 'label' : this.getMarcheObject(marche).getFullName});
        }
    }
    onProduitsPromise() {
        this.produitsAdapter = [];
        for (const produit of this.produits) {
            this.produitsAdapter.push({'value' : produit.id.toString(), 'label' : this.getProduitObject(produit).getFullName});
        }
    }

    onDepotsPromise() {
        this.depotsAdapter = [];
        for (const depot of this.depots) {
            this.depotsAdapter.push({'value' : depot.id.toString(), 'label' : this.getDepotObject(depot).getFullName});
        }
    }

    onSelectReseau($event: any) {
        if (this.reseaux != null) {
            const reseauSelected: Reseau = this.reseaux.find(obj => obj.id.toString() === this.userForm.value.reseau);

            if (reseauSelected != null) {
                this.getData(this.groupeService.getAllByReseauURL(reseauSelected.id), 'groupes', 'onGroupesPromise');
            }
        }
    }
    setReactiveForm() {
        if (this.administrateur) {
            const admin = PageProfilAdministrateurComponent.getAdministrateur(this.administrateur);
            const selectedGroups = admin.selectedGroups;
            const marchesSuiviSelected = (admin.selectedMarchesSuivis !== []) ? admin.selectedMarchesSuivis : '';
            const marchesPreferenceSelected = (admin.selectedMarchesPreference !== []) ? admin.selectedMarchesPreference : '';
            const depotssuivis = (admin.selectedDepotsSuivis !== []) ? admin.selectedDepotsSuivis : '';
            const depotspreference = (admin.selectedDepotsPreference !== []) ? admin.selectedDepotsPreference : '';

            const produits = (admin.selectedProducts !== []) ? admin.selectedProducts : '' ;
            const commune =  (this.administrateur.communeTab != null) ? this.administrateur.communeTab : ['', '', ''] ;
            console.log('marchesAdapter',this.marchesAdapter);
            console.log('marchesSuiviSelected',marchesSuiviSelected);
            this.userForm.patchValue({
                    mobilePhone: this.administrateur.mobilePhone,
                    nom: this.administrateur.nom,
                    prenom: this.administrateur.prenom,
                    secondPhone: this.administrateur.secondPhone,
                    email: this.administrateur.email,
                    enabled: this.administrateur.enabled,
                    genre: this.administrateur.genre,
                    activite: (this.administrateur.activite != null) ? this.administrateur.activite.toString() : '',
                    commune: commune,
                    reseau: (this.administrateur.reseau != null) ? this.administrateur.reseau.toString() : '',
                    groupes: selectedGroups,
                    marcheSuivi: marchesSuiviSelected,
                    marchePreference: marchesPreferenceSelected,
                    depotPreference: depotspreference,
                    depotSuivi: depotssuivis,
                    produits: produits

                }
            );

        }

    }
    onGroupesPromise() {
        this.groupesAdapter = [];
        for (const groupe of this.groupes) {
            this.groupesAdapter.push({'value' : groupe.id.toString(), 'label' : groupe.code});
        }
    }
    onSelectReseauxPromise() {
        this.groupesAdapter = [];
        for (const groupe of this.groupes) {
            this.groupesAdapter.push({'value' : groupe.id.toString(), 'label' : groupe.nom});
        }
    }
    onReseauxPromise() {
        for (const reseau of this.reseaux) {
            this.reseauxAdapter.push({'value' : reseau.id.toString(), 'label' : reseau.code});
        }

    }
    onActivitesPromise() {
        this.activitesAdapter = [];
        for (const activite of this.activites) {
            this.activitesAdapter.push({'value' : activite.id.toString(), 'label' : activite.nom});
        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.defaultAvatar = 'assets/content/anonymous-400.jpg';
        this.currentAvatar = this.defaultAvatar;

        this.getData(this.genreService.getAllURL(), 'genres', 'onGenresPromise');

        this.activiteService.getCached().subscribe(data => {
                this.activites = data;
                this.onActivitesPromise();
            }
        );
        this.reseauService.getCached().subscribe(data => {
                this.reseaux = data;
                this.onReseauxPromise();
            }
        );
        this.groupeService.getCached().subscribe(data => {
                this.groupes = data;
                this.onGroupesPromise();
            }
        );
        this.marcheService.getCached().subscribe(data => {
                this.marches = data;
                this.onMarchesPromise();
            }
        );
        this.depotService.getCached().subscribe(data => {
                this.depots = data;
                this.onDepotsPromise();
            }
        );
        this.produitService.getCached().subscribe(data => {
                this.produits = data;
                this.onProduitsPromise();
            }
        );

        if (this.auth.currentUserDataObject === null) {
            this.router.navigate(['/vertical/dashboard']);
        } else {
        this.selectedId = this.auth.currentUserDataObject.id;
        this.getDataByName(this.adminService.getURL(this.selectedId), 'administrateur', 'acteur', 'setLoaded');
      }
         this.communeService.findAsTree().subscribe(tree => {
                this.communeTree = tree;
            }
        );

    }
    ngAfterViewInit() {


    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-administrateur']);
    }
    liste() {
        this.router.navigate(['/vertical/list-administrateur']);
    }
    mapFormValuesToModel() {
        this.administrateur.mobilePhone =  this.userForm.value.mobilePhone;
        this.administrateur.nom =  this.userForm.value.nom;
        this.administrateur.prenom =  this.userForm.value.prenom;
        this.administrateur.email =  this.userForm.value.email;
        this.administrateur.reseau =  this.userForm.value.reseau;
        this.administrateur.groupes =  this.userForm.value.groupes;
        this.administrateur.enabled =  this.userForm.value.enabled;
        this.administrateur.activite = this.userForm.value.activite;
        this.administrateur.genre = this.userForm.value.genre;
        this.administrateur.produits = this.userForm.value.produits;
        this.administrateur.commune =  this.userForm.value.commune[2];
        this.administrateur.secondPhone = this.userForm.value.secondPhone;
        this.administrateur.depotSuivi = this.userForm.value.depotSuivi;
        this.administrateur.depotPreference = this.userForm.value.depotPreference;
        this.administrateur.marcheSuivi = this.userForm.value.marcheSuivi;
        this.administrateur.marchePreference = this.userForm.value.marchePreference;


    }
    update(): void {
        this.mapFormValuesToModel();
        this.adminService.update(this.administrateur.id, this.userType, this.administrateur)
            .subscribe(
                data => {
                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['']);


                },
                error => console.log(error));
    }

    onFileChanged(inputValue: any) {
        const file: File = inputValue.target.files[0];
        const reader: FileReader = new FileReader();

        reader.onloadend = () => {
            this.currentAvatar = reader.result;
            this.changes = true;
        };

        reader.readAsDataURL(file);

        const formData = new FormData();

        formData.append('file', file);
        formData.append('avatar_id', 'avatar_' + this.administrateur.id);
        const headers = new HttpHeaders({ 'content-type': 'multipart/form-data '});

        this.httpClient.post<any>(this.SERVER_URL, formData).subscribe(
            (res) => {console.log(res); this.toastr.success('Succès de l\'\'importation ');
            },
            (err) => {
                console.log(err); this.toastr.error('Echec de l\'\'importation cause ' + err.toString());
            }
        );
    }
    onSubmit() {
        this.update();

    }

}
