var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
var PageResetLocalCacheComponent = /** @class */ (function (_super) {
    __extends(PageResetLocalCacheComponent, _super);
    function PageResetLocalCacheComponent(store, httpSv, route, router, toastr, i18n) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.SERVER_URL = 'api/upload_avatar';
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    PageResetLocalCacheComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
    };
    PageResetLocalCacheComponent.prototype.initReactiveForm = function () {
    };
    PageResetLocalCacheComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    PageResetLocalCacheComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageResetLocalCacheComponent.prototype.onResetCache = function () {
        window.localStorage.clear();
        this.toastr.success('Succès de la réinitialisation du cache ', '', { timeOut: 2000 });
        this.router.navigate(['']);
    };
    return PageResetLocalCacheComponent;
}(BasePageComponent));
export { PageResetLocalCacheComponent };
