import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserLoggerService = /** @class */ (function () {
    function UserLoggerService(http) {
        this.http = http;
        this.baseUrl = '/api/login';
    }
    UserLoggerService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    UserLoggerService.prototype.create = function (obj) {
        return this.http.post("" + this.baseUrl, obj);
    };
    UserLoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserLoggerService_Factory() { return new UserLoggerService(i0.ɵɵinject(i1.HttpClient)); }, token: UserLoggerService, providedIn: "root" });
    return UserLoggerService;
}());
export { UserLoggerService };
