var Province = /** @class */ (function () {
    function Province() {
        //	this.region = new Region();
    }
    Province.fromJSON = function (json) {
        var result = new Province();
        if (json != null) {
            for (var key in json) {
                result[key] = json[key];
            }
        }
        return result;
    };
    Province.listFromJSON = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var province = Province.fromJSON(jsonObject);
                if (province.region) {
                    jsonObject.region = province.region.nom;
                }
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    Object.defineProperty(Province.prototype, "nomRegion", {
        get: function () {
            if (this.region != null) {
                return this.region.nom;
            }
            return '--';
        },
        enumerable: true,
        configurable: true
    });
    return Province;
}());
export { Province };
