import { Activite } from './activite';
import { Commune } from './commune';
import { Reseau } from './reseau';
var Acteur = /** @class */ (function () {
    function Acteur() {
        this.enabled = true;
        this.activite = new Activite();
        this.commune = new Commune();
        this.reseau = new Reseau();
        this.groupes = [];
        this.marcheSuivi = [];
        this.marchePreference = [];
    }
    Object.defineProperty(Acteur.prototype, "groupesText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.groupes != null) {
                for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
                    var groupe = _a[_i];
                    rval += groupe.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "selectedGroups", {
        get: function () {
            var rval = [];
            if (this.groupes != null) {
                for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
                    var groupe = _a[_i];
                    rval.push(groupe.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "selectedMarchesSuivis", {
        get: function () {
            var rval = [];
            if (this.marcheSuivi != null) {
                for (var _i = 0, _a = this.marcheSuivi; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    if (marche.id !== undefined)
                        rval.push(marche.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "selectedMarchesPreference", {
        get: function () {
            var rval = [];
            if (this.marchePreference != null) {
                for (var _i = 0, _a = this.marchePreference; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    if (marche.id !== undefined)
                        rval.push(marche.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "selectedDepotsSuivis", {
        get: function () {
            var rval = [];
            if (this.depotSuivi != null) {
                for (var _i = 0, _a = this.depotSuivi; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval.push(depot.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "selectedDepotsPreference", {
        get: function () {
            var rval = [];
            if (this.depotPreference != null) {
                for (var _i = 0, _a = this.depotPreference; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    if (depot.id !== undefined)
                        rval.push(depot.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "flatSelectedGroups", {
        get: function () {
            return this.selectedGroups.join(',');
        },
        enumerable: true,
        configurable: true
    });
    Acteur.fromJSON = function (json) {
        var result = new Acteur();
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    Object.defineProperty(Acteur.prototype, "products", {
        get: function () {
            var rval = '';
            // Array:
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    rval += produit.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "depotsSuivisText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.depotSuivi != null) {
                for (var _i = 0, _a = this.depotSuivi; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval += depot.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "depotsPreferenceText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.depotPreference != null) {
                for (var _i = 0, _a = this.depotPreference; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval += depot.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "marchesSuivisText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.marcheSuivi != null) {
                for (var _i = 0, _a = this.marcheSuivi; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval += marche.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "marchesPreferenceText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.marchePreference != null) {
                for (var _i = 0, _a = this.marchePreference; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval += marche.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "selectedProducts", {
        get: function () {
            var rval = [];
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    if (produit.id !== undefined)
                        rval.push(produit.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Acteur.listFromJSON = function (json, userType) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var administrateur = Acteur.fromJSON(jsonObject);
                jsonObject.groupes = administrateur.groupesText;
                if (administrateur.reseau) {
                    jsonObject.reseau = administrateur.reseau.nom;
                }
                if (administrateur.commune) {
                    jsonObject.commune = administrateur.commune.nom;
                }
                if (administrateur.activite) {
                    jsonObject.activite = administrateur.activite.nom;
                }
                if (administrateur.depotsSuivisText) {
                    jsonObject.depots_suivis = administrateur.depotsSuivisText;
                }
                if (administrateur.depotsPreferenceText) {
                    jsonObject.depots_preference = administrateur.depotsPreferenceText;
                }
                if (administrateur.selectedMarchesSuivis) {
                    jsonObject.marchesuivis = administrateur.marchesSuivisText;
                }
                if (administrateur.marchePreference) {
                    jsonObject.marche_preference = administrateur.marchesPreferenceText;
                }
                jsonObject.role = userType;
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    Object.defineProperty(Acteur.prototype, "isAdmin", {
        get: function () {
            return (this.role === 'Administrateur');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "isSuperviseur", {
        get: function () {
            return (this.role === 'Superviseur');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "isSIMBetail", {
        get: function () {
            return (this.reseau.isSIMBetail);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "isDGPER", {
        get: function () {
            return (this.reseau.isDGPER);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "isEnqueteur", {
        get: function () {
            return (this.role === 'Enqueteur');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "isWriter", {
        get: function () {
            return (this.role === 'Administrateur') || (this.role === 'Superviseur') || (this.role === 'Animateur') || (this.role === 'Utilisateur');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "isGroupeAdmin", {
        get: function () {
            console.log('return', ((this.isAdmin) || (this.isSuperviseur)));
            return ((this.isAdmin) || (this.isSuperviseur));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "isAnimateur", {
        get: function () {
            return (this.role === 'Animateur');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "getNomComplet", {
        get: function () {
            return (this.nom + ' ' + this.prenom);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Acteur.prototype, "getFullName", {
        get: function () {
            return (this.nom + ' ' + this.prenom + ' (' + this.mobilePhone + ')');
        },
        enumerable: true,
        configurable: true
    });
    Acteur.prototype.toString = function () {
        return this.nomComplet;
    };
    return Acteur;
}());
export { Acteur };
