/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./content.component";
var styles_TCDropdownContentComponent = [i0.styles];
var RenderType_TCDropdownContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCDropdownContentComponent, data: {} });
export { RenderType_TCDropdownContentComponent as RenderType_TCDropdownContentComponent };
function View_TCDropdownContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["class", "overlay-bg"]], null, null, null, null, null))], null, null); }
export function View_TCDropdownContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dropdown-content-wrap"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "width": 0 }), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "dropdown-overlay"]], null, [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hide() !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.eventHandler($event.keyCode) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCDropdownContentComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.width); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.overlay; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_TCDropdownContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-dropdown-content", [], [[2, "tc-dropdown-content", null], [2, "opened", null], [2, "tc-dropdown-content-inside", null], [2, "tc-dropdown-global-positioning", null], [2, "fade-animation", null], [2, "fadeInUp-animation", null], [2, "fadeInDown-animation", null], [2, "fadeInLeft-animation", null], [2, "fadeInRight-animation", null], [2, "zoom-animation", null]], [["window", "keydown"]], function (_v, en, $event) { var ad = true; if (("window:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).keyboardInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TCDropdownContentComponent_0, RenderType_TCDropdownContentComponent)), i1.ɵdid(1, 376832, null, 0, i3.TCDropdownContentComponent, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).opened; var currVal_2 = i1.ɵnov(_v, 1).appendToBody; var currVal_3 = i1.ɵnov(_v, 1).globalPositioning; var currVal_4 = i1.ɵnov(_v, 1).fade; var currVal_5 = i1.ɵnov(_v, 1).fadeInUp; var currVal_6 = i1.ɵnov(_v, 1).fadeInDown; var currVal_7 = i1.ɵnov(_v, 1).fadeInLeft; var currVal_8 = i1.ɵnov(_v, 1).fadeInRight; var currVal_9 = i1.ɵnov(_v, 1).zoom; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
var TCDropdownContentComponentNgFactory = i1.ɵccf("tc-dropdown-content", i3.TCDropdownContentComponent, View_TCDropdownContentComponent_Host_0, { appendToBody: "appendToBody", globalPositioning: "globalPositioning", overlay: "overlay", offset: "offset", animation: "animation", align: "align", width: "width", maxWidth: "maxWidth", maxHeight: "maxHeight", bg: "bg" }, { closeDropdown: "closeDropdown" }, ["*"]);
export { TCDropdownContentComponentNgFactory as TCDropdownContentComponentNgFactory };
