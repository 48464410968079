<tc-card>
				<button tc-button (click)="ajouter()">Ajouter</button>

</tc-card>

		<tc-card  [title]="pageData.subTitle">
			<mat-form-field>

				<input matInput placeholder="rechercher une catégorie" #input>

			</mat-form-field>

			<div class="spinner-container" *ngIf="dataSource.loading$ | async">

				<mat-spinner></mat-spinner>

			</div>

			<mat-table class="my-table mat-elevation-z24 " [dataSource]="dataSource"
					   matSort matSortActive="nom" matSortDirection="asc" matSortDisableClear
					   >



				<ng-container matColumnDef="code">

					<mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header>Code Categorie</mat-header-cell>

					<mat-cell class="item-cell" *matCellDef="let categorie">{{categorie.code}}</mat-cell>

				</ng-container>

				<ng-container matColumnDef="nom">

					<mat-header-cell class="header-cell" *matHeaderCellDef mat-sort-header>Nom Categorie</mat-header-cell>

					<mat-cell class="item-cell"
							  *matCellDef="let categorie">{{categorie.nom}}</mat-cell>

				</ng-container>

				<ng-container matColumnDef="mobile">

					<mat-header-cell class="header-cell" *matHeaderCellDef >Contact</mat-header-cell>

					<mat-cell class="item-cell"
							  *matCellDef="let categorie">{{categorie.mobile}}</mat-cell>

				</ng-container>



				<ng-container matColumnDef="actions">

					<mat-header-cell class="header-cell" *matHeaderCellDef>Actions</mat-header-cell>

					<mat-cell
							  *matCellDef="let categorie">

						<div class="buttons-list">
							<button tc-button [afterIcon]="'icofont-pencil-alt-1'" [size]="'sm'"
									[outline]="true" [tcColor]="headerbgColor"
									(click)="edit(categorie.id)">
							</button>
							<button tc-button [afterIcon]="'icofont-bin'" [outline]="true" [size]="'sm'"
									[tcColor]="headerbgColor"
									(click)="delete(categorie.id)" >
							</button>
						</div>

					</mat-cell>

				</ng-container>

				<mat-header-row class="header-bg" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>



			</mat-table>


			<mat-paginator [length]=productCount [pageSize]="5"
						   [pageSizeOptions]="[5,10, 25, 50, 100]"></mat-paginator>

	</tc-card>
<tc-card>
	<div class="buttons-list">
		<button  (click)="exportAsXLSX()"><i class="fa fa-file-excel-o" aria-hidden="false" style="font-size:48px;color:#C7B54A">
		</i></button>
	</div>
</tc-card>





