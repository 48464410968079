<div class="row justify-content-center" >
    <div class="col-sm-12">

<ng-container *ngIf="marchesAdapter.length>0">
    <tc-card>
            <div class="row">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Sélectionnez le marché</tc-form-label>
                        <tc-select [options]="marchesAdapter" [(ngModel)]="SelectedMarketId" (ngModelChange)="onSelectMarche($event)" name="marche" [filter]="true" ></tc-select>
                    </tc-form-group>
                </div>
            </div>

    <div class="row">
        <div class="col-sm-4 ">
            <tc-form-group>
                <tc-form-label>Date Saisie</tc-form-label>
                <jqxDateTimeInput #dateEtalonnage
                       [culture]="'fr-FR'">
                </jqxDateTimeInput>`
           </tc-form-group>
        </div>
        <div class="col-sm-4 ">
        </div>
        <div class="col-sm-4 ">
            <div class="pull-right">
            <button tc-button (click)="enregistrer()">Enregistrer</button>
            </div>
        </div>
    </div>

</tc-card>
</ng-container>
<tc-card >
<ng-container *ngIf="listOfData.length>0 ">

                <nz-table #editRowTable  nzSize="middle" [nzData]="listOfData" >
                    <thead>
                    <tr>
                        <th nzWidth="40%">Unité de mesure Locale</th>
                        <th nzWidth="40%">Unité Légale</th>
                        <th nzWidth="20%">Equivalence</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of editRowTable.data">
                        <td>
                            {{ data.uml }}

                        </td>
                        <td>
                            {{ data.ul }}

                        </td>
                        <td>
                            <ng-container *ngIf="!editCache[data.id].edit; else valeurTpl">
                                {{data.valeur}
                            </ng-container>
                            <ng-template #valeurTpl>
                                <label>
                                    <nz-input-number  [(ngModel)]="editCache[data.id].data.valeur"   nzSize="small" [nzStep]="0.6">
                                    </nz-input-number>
                                </label>
                            </ng-template>
                        </td>


                    </tr>
                    </tbody>
                </nz-table>
    <div class="row">
        <div class="col-6 col-sm-8">
            <button tc-button (click)="enregistrer()">Enregistrer</button>
        </div>
    </div>
</ng-container>
</tc-card>
</div>
</div>

