var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertePrixService } from '../alertePrix.service';
import { ToastrService } from 'ngx-toastr';
import { Alerte } from '../../models/alerte';
import { FormBuilder, Validators } from '@angular/forms';
import { ProduitService } from '../../produit/produit.service';
import { MarcheService } from '../../marche/marche.service';
import { DepotService } from '../../depot/depot.service';
import { GroupeService } from '../../groupe/groupe.service';
import { Marche } from '../../models/marche';
import { Produit } from '../../models/produit';
import { HttpClient } from '@angular/common/http';
import { AlerteEdit } from '../../models/alerteEdit';
var PageCreateAlerteComponent = /** @class */ (function (_super) {
    __extends(PageCreateAlerteComponent, _super);
    function PageCreateAlerteComponent(store, httpSv, alertePrixService, route, http, router, toastr, produitService, depotService, marcheService, groupeService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.alertePrixService = alertePrixService;
        _this.route = route;
        _this.http = http;
        _this.router = router;
        _this.toastr = toastr;
        _this.produitService = produitService;
        _this.depotService = depotService;
        _this.marcheService = marcheService;
        _this.groupeService = groupeService;
        _this.fb = fb;
        _this.marchesAdapter = [];
        _this.marches = [];
        _this.produits = [];
        _this.produitsAdapter = [];
        _this.groupes = [];
        _this.groupesAdapter = [];
        _this.alertePrixAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            libelle: 'Libellé Alerte',
            description: 'Description',
            categories: 'Catégories',
            produits: 'Produits',
            marches: 'Marchés',
            groupes: 'Groupes',
            actif: 'actif'
        };
        _this.alerte = new Alerte();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateAlerteComponent.prototype.getAlerte = function (jsonValue) {
        return AlerteEdit.fromJSON(jsonValue);
    };
    PageCreateAlerteComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.setReactiveForm();
    };
    PageCreateAlerteComponent.prototype.getTitle = function () {
        if (this.isEditing()) {
            var prefixe = ' Modifier ';
            return prefixe + ' une alerte de prix';
        }
        else {
            var prefixe = ' Créer ';
            return prefixe + ' une alerte de prix';
        }
    };
    PageCreateAlerteComponent.prototype.isEditing = function () {
        return (this.alerte.id != null);
    };
    PageCreateAlerteComponent.prototype.initReactiveForm = function () {
        this.alerteForm = this.fb.group({
            libelle: ['', [Validators.required, Validators.minLength(2)]],
            produits: ['', Validators.required],
            groupes: ['', Validators.required],
            marches: ['', Validators.required],
            lundi: [false],
            mardi: [false],
            mercredi: [false],
            jeudi: [false],
            vendredi: [false],
            samedi: [false],
            dimanche: [false],
            recevoirParEmail: [false],
            recevoirParSMS: [true],
            actif: [true]
        });
    };
    PageCreateAlerteComponent.prototype.setReactiveForm = function () {
        if (this.alerte) {
            var alertePrix = this.getAlerte(this.alerte);
            var selectedGroupes = (alertePrix.selectedGroupes !== []) ? alertePrix.selectedGroupes : '';
            var selectedProducts = (alertePrix.selectedProducts !== []) ? alertePrix.selectedProducts : '';
            var selectedMarches = (alertePrix.selectedMarches !== []) ? alertePrix.selectedMarches : '';
            console.log('selectedGroupes,selectedProducts,selectedMarches', [selectedGroupes, selectedProducts, selectedMarches]);
            this.alerteForm.patchValue({
                libelle: this.alerte.libelle,
                actif: this.alerte.actif,
                lundi: this.alerte.lundi,
                mardi: this.alerte.mardi,
                mercredi: this.alerte.mercredi,
                jeudi: this.alerte.jeudi,
                vendredi: this.alerte.vendredi,
                samedi: this.alerte.samedi,
                dimanche: this.alerte.dimanche,
                recevoirParEmail: this.alerte.recevoirParEmail,
                recevoirParSMS: this.alerte.recevoirParSMS,
                groupes: selectedGroupes,
                produits: selectedProducts,
                marches: selectedMarches
            });
        }
    };
    PageCreateAlerteComponent.prototype.getMarcheObject = function (jsonValue) {
        return Marche.fromJSON(jsonValue);
    };
    PageCreateAlerteComponent.prototype.getProduitObject = function (jsonValue) {
        return Produit.fromJSON(jsonValue);
    };
    PageCreateAlerteComponent.prototype.onAlertesPromise = function () {
        this.alertePrixAdapter = [];
        for (var _i = 0, _a = this.alerte; _i < _a.length; _i++) {
            var alertePrix = _a[_i];
            this.alertePrixAdapter.push({ 'value': alertePrix.id.toString(), 'label': alertePrix.nom });
        }
    };
    PageCreateAlerteComponent.prototype.onMarchesPromise = function () {
        this.marchesAdapter = [];
        for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
            var marche = _a[_i];
            this.marchesAdapter.push({ 'value': marche.id, 'label': this.getMarcheObject(marche).getFullName });
        }
        console.log('marches, marchesAdapter', [this.marches, this.marchesAdapter]);
    };
    PageCreateAlerteComponent.prototype.onGroupesPromise = function () {
        this.groupesAdapter = [];
        for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
            var groupe = _a[_i];
            this.groupesAdapter.push({ 'value': groupe.id, 'label': groupe.code });
        }
        console.log('groupes, groupesAdapter', [this.groupes, this.groupesAdapter]);
    };
    PageCreateAlerteComponent.prototype.onProduitsPromise = function () {
        this.produitsAdapter = [];
        for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
            var produit = _a[_i];
            this.produitsAdapter.push({ 'value': produit.id, 'label': this.getMarcheObject(produit).getFullName });
        }
        console.log('produits, produitsAdapter', [this.produits, this.produitsAdapter]);
    };
    PageCreateAlerteComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.marcheService.getCached().subscribe(function (data) {
            _this.marches = data;
            _this.onMarchesPromise();
        });
        this.produitService.getCached().subscribe(function (data) {
            _this.produits = data;
            _this.onProduitsPromise();
        });
        this.groupeService.getCached().subscribe(function (data) {
            _this.groupes = data;
            _this.onGroupesPromise();
        });
        this.route.params.subscribe(function (params) {
            _this.alerteType = params['alerteType'];
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.alertePrixService.getURL(_this.selectedId), 'alerte', 'alerte', 'setLoaded');
                _this.pageData.title = _this.getTitle();
            }
            else {
                _this.alerte = new Alerte();
                _this.initReactiveForm();
            }
        });
    };
    PageCreateAlerteComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateAlerteComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-alertePrix']);
    };
    PageCreateAlerteComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-alertePrix']);
    };
    PageCreateAlerteComponent.prototype.mapFormValuesToModel = function () {
        this.alerte.libelle = this.alerteForm.value.libelle;
        this.alerte.actif = this.alerteForm.value.actif;
        this.alerte.produits = this.alerteForm.value.produits;
        this.alerte.marches = this.alerteForm.value.marches;
        this.alerte.groupes = this.alerteForm.value.groupes;
        this.alerte.recevoirParEmail = this.alerteForm.value.recevoirParEmail;
        this.alerte.recevoirParSMS = this.alerteForm.value.recevoirParSMS;
        this.alerte.lundi = this.alerteForm.value.lundi;
        this.alerte.mardi = this.alerteForm.value.mardi;
        this.alerte.mercredi = this.alerteForm.value.mercredi;
        this.alerte.jeudi = this.alerteForm.value.jeudi;
        this.alerte.vendredi = this.alerteForm.value.vendredi;
        this.alerte.samedi = this.alerteForm.value.samedi;
        this.alerte.dimanche = this.alerteForm.value.dimanche;
    };
    PageCreateAlerteComponent.prototype.update = function () {
        var _this = this;
        if (this.alerteForm.valid) {
            this.mapFormValuesToModel();
            this.alertePrixService.update(this.alerte.id, this.alerteType, this.alerte)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/liste-alertes-' + _this.alerteType,
                    { alerteType: _this.alerteType }]);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateAlerteComponent.prototype.create = function () {
        var _this = this;
        if (this.alerteForm.valid) {
            this.mapFormValuesToModel();
            this.alertePrixService.create(this.alerteType, this.alerte)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie ', '', { timeOut: 2000 });
                _this.alertePrixAdapter = [];
                _this.alerteForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateAlerteComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateAlerteComponent;
}(BasePageComponent));
export { PageCreateAlerteComponent };
