import {Reseau} from './reseau';

export class Groupe {
	id: number;
	code: string;
	nom: string;
	commentaire: string;
	estFils: boolean;
	actif: boolean;
	reseau: Reseau;
	parent: string;
	static fromJSON(json: any): Groupe {
		const result = new Groupe();
		for (const key in json) {
			result[key] = json[key];
		}

		return result;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];

				rval.push(jsonObject);
			}
		}

		return rval;
	}

	toString() {
		return this.nom;
	}

	constructor() {
		this.actif = true;
		this.estFils = true;
		this.reseau = new Reseau();
	}
}
