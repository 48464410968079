/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./icon.component";
var styles_TCIconComponent = [i0.styles];
var RenderType_TCIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCIconComponent, data: {} });
export { RenderType_TCIconComponent as RenderType_TCIconComponent };
export function View_TCIconComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tc-icon-wrap ", _co.iconClass, ""); _ck(_v, 0, 0, currVal_0); }); }
export function View_TCIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-icon", [], [[2, "tc-icon", null], [4, "padding", null]], null, null, View_TCIconComponent_0, RenderType_TCIconComponent)), i1.ɵdid(1, 114688, null, 0, i2.TCIconComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).padding; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var TCIconComponentNgFactory = i1.ɵccf("tc-icon", i2.TCIconComponent, View_TCIconComponent_Host_0, { iconClass: "iconClass", padding: "padding" }, {}, []);
export { TCIconComponentNgFactory as TCIconComponentNgFactory };
