import { OnInit, OnChanges } from '@angular/core';
var TCColorDirective = /** @class */ (function () {
    function TCColorDirective() {
    }
    Object.defineProperty(TCColorDirective.prototype, "getStyle", {
        get: function () {
            return this.currentColor;
        },
        enumerable: true,
        configurable: true
    });
    TCColorDirective.prototype.onMouseEnter = function () {
        this.currentColor = this.hoveredColor;
    };
    TCColorDirective.prototype.onMouseLeave = function () {
        this.currentColor = this.defaultColor;
    };
    TCColorDirective.prototype.ngOnInit = function () {
        this.setColors();
    };
    TCColorDirective.prototype.ngOnChanges = function () {
        this.setColors();
    };
    TCColorDirective.prototype.setColors = function () {
        var COLOR = this.tcColor;
        if (!COLOR) {
            return null;
        }
        this.defaultColor = (typeof COLOR === 'string') ? COLOR : COLOR[0];
        this.hoveredColor = (typeof COLOR === 'string') ? COLOR : COLOR[1];
        this.currentColor = this.defaultColor;
    };
    return TCColorDirective;
}());
export { TCColorDirective };
