import {ActivatedRoute, Router} from '@angular/router';
import {Component, Directive, enableProdMode, OnInit} from '@angular/core';
import {FormGroup, FormControl, Validators, ValidationErrors, NG_VALIDATORS, Validator, AbstractControl} from '@angular/forms';

import { fadeIn } from 'src/app/animations/form-error';
import {AuthenticationService} from '../../../../services/sharedServices/authentication.service';
import {ToastrService} from 'ngx-toastr';
import {SETTINGS} from '../../../../../environments/settings';
import {UserService} from '../../../ui/administrateur/user.service';

@Component({
    selector: 'tc-page-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
    animations: [fadeIn]
})

export class PageSignInComponent implements OnInit {
    loginForm: FormGroup;
    invalidLogin = false;
    loginAuth: any;
    nbattempts = 0;
    nbMaxAttempts = 3;
    userData: Object;
    returnUrl: string;
    headerbgColor: String = SETTINGS.tableHeaderbgColor;

    constructor(private router: Router, private activatedRoute: ActivatedRoute,
                private loginservice: AuthenticationService, private toastr: ToastrService,
                private adminService: UserService
                ) { }

    ngOnInit() {
            this.loginForm = new FormGroup({
                login: new FormControl('', [Validators.required, Validators.minLength(4)]),
                password: new FormControl('', [Validators.required, Validators.minLength(5)])
            });
        this.nbattempts = 0;
        // get return url from route parameters or default to '/'
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
    }

    Login(): void {
        this.loginservice.authenticate(this.login.value, this.password.value).subscribe(
            data => {
                this.loginAuth = data;
                this.invalidLogin = false;
                this.nbattempts = 0;
             //   console.log('this.router.routerState.snapshot.url ' + this.router.routerState.snapshot.url);

                sessionStorage.setItem('currentUser', JSON.stringify(data));

                    this.loginservice.setCurrentObject(data);
             //   this.router.navigate([sessionStorage.getItem('urlmissed')]);

                sessionStorage.removeItem('currentUser');
                this.adminService.findMe().subscribe(
                    userData => {
                        this.userData = userData;
                        this.loginservice.setCurrentUserDataObject(userData);
                        this.router.navigateByUrl(this.returnUrl);
                        sessionStorage.setItem('currentUserData', JSON.stringify(userData));

                    }
                );

            },
            error => {
                let errorText: string;
                console.log('error', error);
                errorText = error;
                this.invalidLogin = true;
                this.nbattempts += 1;
                if ((errorText.toUpperCase().indexOf('TIMEOUT') >= 0) || (errorText.toUpperCase().indexOf('GATEWAY') >= 0)) {
                    errorText = 'Vérifiez que le serveur rest a démarré ';
                } else {
                    this.password.setErrors({ mustExist: true });
                }
                this.toastr.error(errorText);
                    this.nbattempts = 0;
            }
        );
    }

    get  login() {
        return this.loginForm.get('login');
    }

    get password() {
        return this.loginForm.get('password');
    }
}
