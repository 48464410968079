/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./checkbox.component";
var styles_TCCheckboxComponent = [i0.styles];
var RenderType_TCCheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCCheckboxComponent, data: {} });
export { RenderType_TCCheckboxComponent as RenderType_TCCheckboxComponent };
export function View_TCCheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "tc-checkbox-wrap"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switch(_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "tc-checkbox-handle"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["class", "checkbox-input"], ["type", "checkbox"]], [[8, "value", 0], [1, "checked", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "span", [["class", "check-detector icofont-check-alt"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "tc-checkbox-label"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStyles(); _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.getLabelColor(); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.value; var currVal_2 = _co.value; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_TCCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tc-checkbox", [], [[2, "tc-checkbox", null], [2, "checked", null], [2, "disabled", null]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseenter" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onMouseEnter() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_TCCheckboxComponent_0, RenderType_TCCheckboxComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.TCCheckboxComponent]), i1.ɵdid(2, 114688, null, 0, i4.TCCheckboxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 2).focus; var currVal_2 = i1.ɵnov(_v, 2).disabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var TCCheckboxComponentNgFactory = i1.ɵccf("tc-checkbox", i4.TCCheckboxComponent, View_TCCheckboxComponent_Host_0, { disabled: "disabled", label: "label", name: "name", _value: "value", bgColor: "bgColor", borderColor: "borderColor", color: "color", labelColor: "labelColor" }, {}, ["*"]);
export { TCCheckboxComponentNgFactory as TCCheckboxComponentNgFactory };
