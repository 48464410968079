import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { HttpService } from '../../../services/http/http.service';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {ActionService} from './actions.service';

export interface IDataNotification {
  title: string;
  date: string;
  icon: string;
  iconBg: string;
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  avatarUrl = 'assets/content/user_icon.png';
  connectedId: number;
  notifications: any[];
  closeDropdown: EventEmitter<boolean>;
  @Input() layout: string;

  constructor(
    private httpSv: HttpService,
    private router: Router, private auth: AuthenticationService,
    private actionService: ActionService
  ) {
    this.notifications = [];
    this.closeDropdown = new EventEmitter<boolean>();
    this.layout = 'vertical';
  }

  ngOnInit() {

  //  this.getData('assets/data/navbar-notifications.json', 'notifications');
    if (this.auth.currentUserDataObject) {
    if (this.auth.currentUserDataObject.reseau !== null) {
    this.actionService.notifications(this.auth.currentUserDataObject.reseau.id).subscribe(
        data => {
          this.notifications = data;
        }
    );
    }

    this.connectedId = this.auth.currentUserDataObject.id;
    }
  }

  getData(url: string, dataName: string) {
    this.httpSv.getData(url).subscribe(
      data => {
        this[dataName] = data;
      },
      err => {
        console.log(err);
      }
    );
  }

  onCloseDropdown() {
    this.closeDropdown.emit(true);
  }

  goTo(event: Event, link: string, layout: string = '') {
    event.preventDefault();

    this.onCloseDropdown();

    setTimeout(() => {
      this.router.navigate([layout ? layout : this.layout, link]);
    });
  }
}
