
import {Produit} from './produit';
import {Marche} from './marche';
import {Depot} from './depot';
import {Groupe} from './groupe';
import {Reseau} from './reseau';

export class Alerte {
	libelle: string;
	id: number;
	recevoirParEmail: boolean;
	recevoirParSMS: boolean;
	valide: boolean;
	actif: boolean;
	lundi: boolean;
	mardi: boolean;
	mercredi: boolean;
	jeudi: boolean;
	vendredi: boolean;
	samedi: boolean;
	dimanche: boolean;
	reseau: Reseau;
	produits: Produit[];
	groupes: Groupe[];
	marches: Marche[];
	depots: Depot[];
	static fromJSON(json: any): Alerte {
		const result = new Alerte();
		for (const key in json) {
			result[key] = json[key];
		}

		return result;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const alertePrix = Alerte.fromJSON(jsonObject);
				jsonObject.produits = alertePrix.produitsText;
				jsonObject.marches = alertePrix.marchesText;
				jsonObject.groupes = alertePrix.groupesText;
				jsonObject.depots = alertePrix.depotText;
					rval.push(jsonObject);
			}
		}

		return rval;
	}

	toString() {
		return this.libelle;
	}
	get produitsText() {
		let rval = '';
		if (this.produits != null) {
			for (const produit of this.produits) {
				rval += produit.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get groupesText() {
		let rval = '';
		// Array:
		if (this.groupes != null) {
			for (const groupe of this.groupes) {
				rval += groupe.nom;
				rval += '\n';
			}
		}
		return rval;
	}

	get marchesText() {
		let rval = '';
		// Array:
		if (this.marches != null) {
			for (const marche of this.marches) {
				rval += marche.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get depotText() {
		let rval = '';
		// Array:
		if (this.depots != null) {
			for (const depot of this.depots) {
				rval += depot.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get selectedProducts() {
		const rval = [];
		if (this.produits != null) {
			for (const produit of this.produits) {
				rval.push(produit.id.toString());
			}
		}
		return rval;
	}

	get selectedMarches() {
		const rval = [];
		if (this.marches != null) {
			for (const marche of this.marches) {
				rval.push(marche.id.toString());
			}
		}
		return rval;
	}
	get selectedDepots() {
		const rval = [];
		if (this.depots != null) {
			for (const depot of this.depots) {
				rval.push(depot.id.toString());
			}
		}
		return rval;
	}
	get selectedGroupes() {
		const rval = [];
		if (this.groupes != null) {
			for (const groupe of this.groupes) {
				rval.push(groupe.id.toString());
			}
		}
		return rval;
	}


	constructor() {
		this.actif = true;
		this.valide = false;
		this.produits = [];
		this.groupes = [];
		this.depots = [];
		this.marches = [];
	}
}
