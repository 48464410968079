var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { AlerteStockService } from '../alerteStock.service';
import { ToastrService } from 'ngx-toastr';
import { Alerte } from '../../models/alerte';
import { FormBuilder, Validators } from '@angular/forms';
import { ProduitService } from '../../produit/produit.service';
import { DepotService } from '../../depot/depot.service';
import { GroupeService } from '../../groupe/groupe.service';
import { Marche } from '../../models/marche';
import { Produit } from '../../models/produit';
import { HttpClient } from '@angular/common/http';
import { AlerteEdit } from '../../models/alerteEdit';
var PageCreateAlerteStockComponent = /** @class */ (function (_super) {
    __extends(PageCreateAlerteStockComponent, _super);
    function PageCreateAlerteStockComponent(store, httpSv, alerteStockService, route, http, router, toastr, produitService, depotService, groupeService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.alerteStockService = alerteStockService;
        _this.route = route;
        _this.http = http;
        _this.router = router;
        _this.toastr = toastr;
        _this.produitService = produitService;
        _this.depotService = depotService;
        _this.groupeService = groupeService;
        _this.fb = fb;
        _this.depotsAdapter = [];
        _this.depots = [];
        _this.produits = [];
        _this.produitsAdapter = [];
        _this.groupes = [];
        _this.groupesAdapter = [];
        _this.alerteStockAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            libelle: 'Libellé Alerte',
            description: 'Description',
            categories: 'Catégories',
            produits: 'Produits',
            depots: 'Dépôts',
            groupes: 'Groupes',
            actif: 'actif'
        };
        _this.alerte = new Alerte();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateAlerteStockComponent.prototype.getAlerte = function (jsonValue) {
        return AlerteEdit.fromJSON(jsonValue);
    };
    PageCreateAlerteStockComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.setReactiveForm();
    };
    PageCreateAlerteStockComponent.prototype.getTitle = function () {
        if (this.isEditing()) {
            var prefixe = ' Modifier ';
            return prefixe + ' une alerte de stock';
        }
        else {
            var prefixe = ' Créer ';
            return prefixe + ' une alerte de stock';
        }
    };
    PageCreateAlerteStockComponent.prototype.isEditing = function () {
        return (this.alerte.id != null);
    };
    PageCreateAlerteStockComponent.prototype.initReactiveForm = function () {
        this.alerteForm = this.fb.group({
            libelle: ['', [Validators.required, Validators.minLength(2)]],
            produits: ['', Validators.required],
            groupes: ['', Validators.required],
            depots: ['', Validators.required],
            lundi: [false],
            mardi: [false],
            mercredi: [false],
            jeudi: [false],
            vendredi: [false],
            samedi: [false],
            dimanche: [false],
            recevoirParEmail: [false],
            recevoirParSMS: [true],
            actif: [true]
        });
    };
    PageCreateAlerteStockComponent.prototype.setReactiveForm = function () {
        if (this.alerte) {
            var alerteStock = this.getAlerte(this.alerte);
            var selectedGroupes = (alerteStock.selectedGroupes !== []) ? alerteStock.selectedGroupes : '';
            var selectedProducts = (alerteStock.selectedProducts !== []) ? alerteStock.selectedProducts : '';
            var selectedDepots = (alerteStock.selectedDepots !== []) ? alerteStock.selectedDepots : '';
            this.alerteForm.patchValue({
                libelle: this.alerte.libelle,
                actif: this.alerte.actif,
                lundi: this.alerte.lundi,
                mardi: this.alerte.mardi,
                mercredi: this.alerte.mercredi,
                jeudi: this.alerte.jeudi,
                vendredi: this.alerte.vendredi,
                samedi: this.alerte.samedi,
                dimanche: this.alerte.dimanche,
                recevoirParEmail: this.alerte.recevoirParEmail,
                recevoirParSMS: this.alerte.recevoirParSMS,
                groupes: selectedGroupes,
                produits: selectedProducts,
                depots: selectedDepots
            });
        }
    };
    PageCreateAlerteStockComponent.prototype.getMarcheObject = function (jsonValue) {
        return Marche.fromJSON(jsonValue);
    };
    PageCreateAlerteStockComponent.prototype.getProduitObject = function (jsonValue) {
        return Produit.fromJSON(jsonValue);
    };
    PageCreateAlerteStockComponent.prototype.onAlertesPromise = function () {
        this.alerteStockAdapter = [];
        for (var _i = 0, _a = this.alerte; _i < _a.length; _i++) {
            var alerteStock = _a[_i];
            this.alerteStockAdapter.push({ 'value': alerteStock.id.toString(), 'label': alerteStock.nom });
        }
    };
    PageCreateAlerteStockComponent.prototype.onDepotsPromise = function () {
        this.depotsAdapter = [];
        for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
            var marche = _a[_i];
            this.depotsAdapter.push({ 'value': marche.id, 'label': this.getMarcheObject(marche).getFullName });
        }
    };
    PageCreateAlerteStockComponent.prototype.onGroupesPromise = function () {
        this.groupesAdapter = [];
        for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
            var groupe = _a[_i];
            this.groupesAdapter.push({ 'value': groupe.id, 'label': groupe.nom });
        }
    };
    PageCreateAlerteStockComponent.prototype.onProduitsPromise = function () {
        this.produitsAdapter = [];
        for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
            var produit = _a[_i];
            this.produitsAdapter.push({ 'value': produit.id, 'label': this.getMarcheObject(produit).getFullName });
        }
    };
    PageCreateAlerteStockComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.depotService.getCached().subscribe(function (data) {
            _this.depots = data;
            _this.onDepotsPromise();
        });
        this.produitService.getCached().subscribe(function (data) {
            _this.produits = data;
            _this.onProduitsPromise();
        });
        this.groupeService.getCached().subscribe(function (data) {
            _this.groupes = data;
            _this.onGroupesPromise();
        });
        this.route.params.subscribe(function (params) {
            _this.alerteType = params['alerteType'];
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.alerteStockService.getURL(_this.selectedId), 'alerte', 'alerte', 'setLoaded');
                _this.pageData.title = _this.getTitle();
            }
            else {
                _this.alerte = new Alerte();
                _this.initReactiveForm();
            }
        });
    };
    PageCreateAlerteStockComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateAlerteStockComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-alerteDepot']);
    };
    PageCreateAlerteStockComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-alerteDepot']);
    };
    PageCreateAlerteStockComponent.prototype.mapFormValuesToModel = function () {
        this.alerte.libelle = this.alerteForm.value.libelle;
        this.alerte.actif = this.alerteForm.value.actif;
        this.alerte.produits = this.alerteForm.value.produits;
        this.alerte.depots = this.alerteForm.value.depots;
        this.alerte.groupes = this.alerteForm.value.groupes;
        this.alerte.recevoirParEmail = this.alerteForm.value.recevoirParEmail;
        this.alerte.recevoirParSMS = this.alerteForm.value.recevoirParSMS;
        this.alerte.lundi = this.alerteForm.value.lundi;
        this.alerte.mardi = this.alerteForm.value.mardi;
        this.alerte.mercredi = this.alerteForm.value.mercredi;
        this.alerte.jeudi = this.alerteForm.value.jeudi;
        this.alerte.vendredi = this.alerteForm.value.vendredi;
        this.alerte.samedi = this.alerteForm.value.samedi;
        this.alerte.dimanche = this.alerteForm.value.dimanche;
    };
    PageCreateAlerteStockComponent.prototype.update = function () {
        var _this = this;
        if (this.alerteForm.valid) {
            this.mapFormValuesToModel();
            this.alerteStockService.update(this.alerte.id, this.alerteType, this.alerte)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/liste-alertes-' + _this.alerteType,
                    { alerteType: _this.alerteType }]);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateAlerteStockComponent.prototype.create = function () {
        var _this = this;
        if (this.alerteForm.valid) {
            this.mapFormValuesToModel();
            this.alerteStockService.create(this.alerteType, this.alerte)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie ', '', { timeOut: 2000 });
                _this.alerteStockAdapter = [];
                _this.alerteForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateAlerteStockComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateAlerteStockComponent;
}(BasePageComponent));
export { PageCreateAlerteStockComponent };
