import { HttpClient } from '@angular/common/http';
import { UserLoggerService } from './user-logger.service';
import { LoginAuth } from '../../pages/ui/models/loginAuth';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./user-logger.service";
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(httpClient, userLogger) {
        this.httpClient = httpClient;
        this.userLogger = userLogger;
        this.currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUserDataSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUserData')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    Object.defineProperty(AuthenticationService.prototype, "currentUserValue", {
        get: function () {
            return this.currentUserSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.setCurrentObject = function (user) {
        this.currentUserSubject.next(user);
    };
    Object.defineProperty(AuthenticationService.prototype, "currentUserDataObject", {
        get: function () {
            return this.currentUserDataSubject.value;
        },
        enumerable: true,
        configurable: true
    });
    AuthenticationService.prototype.setCurrentUserDataObject = function (userData) {
        this.currentUserDataSubject.next(userData);
    };
    AuthenticationService.prototype.authenticate = function (username, password) {
        this.loginAuth = new LoginAuth(username, password);
        return this.userLogger.create(this.loginAuth);
    };
    AuthenticationService.prototype.logout = function () {
        // remove user from local storage and set current user to null
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('currentUserData');
        this.currentUserSubject.next(null);
        this.currentUserDataSubject.next(null);
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserLoggerService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}());
export { AuthenticationService };
