import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {Activite} from '../../models/activite';
import {CommuneService} from '../../commune/commune.service';
import {Commune} from '../../models/commune';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {FormBuilder,
    FormGroup,  Validators} from '@angular/forms';
import {GenreService} from '../../../../services/sharedServices/genre.service';
import {fadeIn} from '../../../../animations/form-error';
import {Reseau} from '../../models/reseau';
import {ReseauService} from '../../reseau/reseau.service';
import {GroupeService} from '../../groupe/groupe.service';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {INoeud} from '../../produit/create';
import {MarcheService} from '../../marche/marche.service';
import {ProduitService} from '../../produit/produit.service';
import {DepotService} from '../../depot/depot.service';
import {ActeurEdit} from '../../models/acteurEdit';
import {ActiviteService} from '../../activite/activite.service';
import {Marche} from '../../models/marche';
import {Produit} from '../../models/produit';
import {Depot} from '../../models/depot';

export interface ICheckActeur {
 found: boolean;
 nomComplet: string;
}
@Component({
    selector: 'tc-administrateur-create',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss'],
    animations: [fadeIn]
})
export class PageCreateAdministrateurComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private administrateurService: UserService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private genreService: GenreService, private activiteService: ActiviteService,
                 private communeService: CommuneService, private i18n: NzI18nService,
                 private reseauService: ReseauService,
                 private marcheService: MarcheService,
                 private produitService: ProduitService,
                 private depotService: DepotService,
                 private fb: FormBuilder, private groupeService: GroupeService

    ) {
        super(store, httpSv);
        this.administrateur = {};
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des administrateurs',
                    route: '/vertical/list-administrateur'
                }
            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);
        this.initReactiveForm();

    }
    userForm: FormGroup;
    userType: string;
    administrateur: any;
    intPhoneRegex = '^[+][0-9]{1,3}[0-9]{4,14}$';
    communeTree: INoeud[] = [];
    genres: IOption[] = [] = [];
    activites: Activite[] = [];
    marches: any[];
    marchesAdapter: IOption[] = [];
    activitesAdapter: IOption[] = [];
    public mobilePhoneKeyUp = new Subject<KeyboardEvent>();
    private mobilePhoneSubscription: Subscription;
    groupes: any[] = [];
    reseaux: any[] = [];
    tree: INoeud[] = [];
    reseauxAdapter: IOption[] = [];
    groupesAdapter: IOption[] = [];
    produits: any[];
    produitsAdapter: IOption[] = [];
    depots: any[];
    depotsAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    fromURL: string;
    Columnslabels =
        {
            nom: 'Nom',
            prenom: 'Prénom',
            mobilePhone: 'N° Mobile',
            genre: 'Sexe',
            email: 'Email',
            password: 'Mot de passe',
            confirmpassword: 'Confirmer le Mot de passe',
            activite: 'Activité',
            commune: 'Commune',
            reseau: 'Réseau',
            groupes: 'Groupes',
            enabled: 'Actif',
            produits: 'Produits',
            marchespreference: 'Marchés de préférence',
            marchesuivis: 'Marchés suivis',
            depotPreference: 'Dépôts de préférence',
            depotSuivi: 'Dépôts suivis'
        };
    static getAdministrateur (jsonValue): ActeurEdit {
        return ActeurEdit.fromJSON(jsonValue) ;
    }

    getTitle(): string {
        if ( this.isEditing()) {
        const prefixe = ' Modifier ' ;
        if (this.userType === 'administrateur') {
            return prefixe + '  un administrateur';
        } else if (this.userType === 'superviseur') {
            return prefixe + ' un superviseur';
        } else if (this.userType === 'enqueteur') {
            return prefixe + ' un enqueteur';
        } else if (this.userType === 'animateur') {
            return prefixe + ' un animateur';
        } else if (this.userType === 'utilisateur') {
            return prefixe + ' un utilisateur';
        } else {
            return prefixe + ' un utilisateur';
        }
        } else {
            const prefixe = ' créer ' ;
            if (this.userType === 'administrateur') {
                return prefixe + '  un administrateur';
            } else if (this.userType === 'superviseur') {
                return prefixe + ' un superviseur';
            } else if (this.userType === 'animateur') {
                return prefixe + ' un animateur';
            } else if (this.userType === 'enqueteur') {
                return prefixe + ' un enqueteur';
            } else if (this.userType === 'utilisateur') {
                return prefixe + ' un utilisateur';
            } else {
                return prefixe + ' un utilisateur';
            }
        }


    }

    getMarcheObject (jsonValue): Marche {
        return Marche.fromJSON(jsonValue) ;
    }
    getProduitObject (jsonValue): Produit {
        return Produit.fromJSON(jsonValue) ;
    }
    getDepotObject (jsonValue): Depot {
        return Depot.fromJSON(jsonValue) ;
    }
    onMarchesPromise() {
        this.marchesAdapter = [];
        for (const marche of this.marches) {
            this.marchesAdapter.push({'value' : marche.id.toString(), 'label' : this.getMarcheObject(marche).getFullName});
        }
    }
    onProduitsPromise() {
        this.produitsAdapter = [];
        for (const produit of this.produits) {
            this.produitsAdapter.push({'value' : produit.id.toString(), 'label' : this.getProduitObject(produit).getFullName});
        }
    }

    onDepotsPromise() {
        this.depotsAdapter = [];
        for (const depot of this.depots) {
            this.depotsAdapter.push({'value' : depot.id.toString(), 'label' : this.getDepotObject(depot).getFullName});
        }
    }

    onGenresPromise() {
    }
    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.pageData.title =  this.getTitle();
         this.setReactiveForm();


    }
    isEditing(): boolean {
        return (this.administrateur.id != null);
    }

    initReactiveForm() {
        this.userForm = this.fb.group({
                mobilePhone: ['', [Validators.required, Validators.pattern(this.intPhoneRegex)]],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                prenom: ['', [Validators.required, Validators.minLength(2)]],
                email: ['', [Validators.email]],
                genre: ['Masculin', Validators.required],
                depotPreference: [''],
                depotSuivi: [''],
                marcheSuivi: [''],
                marchePreference: [''],
                activite: [''],
                reseau: [''],
                groupes: [''],
                commune: [''],
                enabled: [true]
            }

        ) ;


    }
    onSelectReseau($event: any) {
        if (this.reseaux != null) {
            const reseauSelected: Reseau = this.reseaux.find(obj => obj.id.toString() === this.userForm.value.reseau);

            if (reseauSelected != null) {
                this.getData(this.groupeService.getAllByReseauURL(reseauSelected.id), 'groupes', 'onGroupesPromise');
            }
        }
    }
    setReactiveForm() {
        if (this.administrateur) {
            const admin = PageCreateAdministrateurComponent.getAdministrateur(this.administrateur);
            const selectedGroups = admin.selectedGroups;
            const reseau = (this.administrateur.reseau != null) ? this.administrateur.reseau.toString() : '';
            const marchesSuiviSelected = (admin.selectedMarchesSuivis !== []) ? admin.selectedMarchesSuivis : '';
            const marchesPreferenceSelected = (admin.selectedMarchesPreference !== []) ? admin.selectedMarchesPreference : '';
            const commune =  (this.administrateur.communeTab != null) ? this.administrateur.communeTab : ['', '', ''] ;
            const depotssuivis = (admin.selectedDepotsSuivis !== []) ? admin.selectedDepotsSuivis : '';
            const depotspreference = (admin.selectedDepotsPreference !== []) ? admin.selectedDepotsPreference : '';
            console.log('marchesSuiviSelected : ',marchesSuiviSelected);

            this.userForm.patchValue({
                    mobilePhone: this.administrateur.mobilePhone,
                    nom: this.administrateur.nom,
                    prenom: this.administrateur.prenom,
                    enabled: this.administrateur.enabled,
                    genre: this.administrateur.genre,
                    email: this.administrateur.email,
                    activite: (this.administrateur.activite != null) ? this.administrateur.activite.toString() : '',
                    reseau: reseau,
                    groupes: selectedGroups,
                    marcheSuivi: marchesSuiviSelected,
                    marchePreference: marchesPreferenceSelected,
                    depotPreference: depotspreference,
                    depotSuivi: depotssuivis,
                    commune: commune

                }
            );
        }

    }
    onChangeReseau() {
        if (this.reseaux != null) {
            this.userForm.get('reseau').valueChanges.subscribe(() => {
                const reseauSelected: Reseau = this.reseaux.find(obj => obj.id.toString() === this.userForm.value.reseau);

                if (reseauSelected != null) {
                    this.getData(this.groupeService.getAllByReseauURL(reseauSelected.id), 'groupes', 'onSelectReseauxPromise');
                }
            });
        }
    }
    onGroupesPromise() {
        this.groupesAdapter = [];
        for (const groupe of this.groupes) {
            this.groupesAdapter.push({'value' : groupe.id.toString(), 'label' : groupe.code});
        }
    }
    onActivitesPromise() {
        this.activitesAdapter = [];
        for (const activite of this.activites) {
            this.activitesAdapter.push({'value' : activite.id.toString(), 'label' : activite.nom});
        }
    }
    resetPassword() {

                            this.administrateurService.resetPassword(this.administrateur.mobilePhone).subscribe(
                                resultat => {
                                    if (resultat.status === 'OK' ) {
                                        this.toastr.success('Succès de la réinitialisation mot de passe ', '',
                                            {timeOut: 2000});
                                    } else {
                                        this.toastr.error('Echec de la réinitialisation du mot de passe ', '',
                                            {timeOut: 2000});
                                    }
                                }
                            );


    }

    onSelectReseauxPromise() {
        this.groupesAdapter = [];
        for (const groupe of this.groupes) {
            this.groupesAdapter.push({'value' : groupe.id.toString(), 'label' : groupe.nom});
        }
    }
    onReseauxPromise() {
        for (const reseau of this.reseaux) {
            this.reseauxAdapter.push({'value' : reseau.id.toString(), 'label' : reseau.code});
        }

    }


    ngOnInit() {
        super.ngOnInit();
        this.getData(this.genreService.getAllURL(), 'genres', 'onGenresPromise');
        this.reseauService.getCached().subscribe(data => {
                this.reseaux = data;
                this.onReseauxPromise();
            }
        );
        this.groupeService.getCached().subscribe(data => {
                this.groupes = data;
                this.onGroupesPromise();
            }
        );
        this.marcheService.getCached().subscribe(data => {
                this.marches = data;
                this.onMarchesPromise();
            }
        );
        this.depotService.getCached().subscribe(data => {
                this.depots = data;
                this.onDepotsPromise();
            }
        );
        this.activiteService.getCached().subscribe(data => {
                this.activites = data;
                this.onActivitesPromise();
            }
        );
        this.fromURL = this.route.snapshot.url.toString() ;

        this.communeService.findAsTree().subscribe(tree => {
                this.communeTree = tree;
            }
        );


        this.route.params.subscribe(params => {
            this.userType = params['userType'];
            this.fromURL = params['fromURL'];
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.administrateurService.getURL(this.selectedId), 'administrateur', 'acteur', 'setLoaded');
            } else {
                this.administrateur = {};
                this.initReactiveForm();
            }
        });

    }
    ngAfterViewInit() {
        if (!this.isEditing()) {
                    this.mobilePhoneSubscription = this.mobilePhoneKeyUp.pipe(
                        debounceTime(100),
                        distinctUntilChanged(),
                        tap(() => {
                             if (this.userForm.value.mobilePhone.length === 12) {
                            this.administrateurService.checkAlreadyCreated(this.userForm.value.mobilePhone).subscribe(
                                checkActeur => {
                                    if (checkActeur.found) {
                                        this.userForm.get('mobilePhone').setErrors({ alreadyExist: true });
                                        this.toastr.warning('Un utilisateur avec le numéro ' +
                                            this.userForm.value.mobilePhone + ' existe déjà !!!, son nom : ' + checkActeur.nomComplet);
                                    }

                                }
                            );
                             }
                        })
                    ).subscribe();

        }

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-administrateur']);
    }
    liste() {
        this.router.navigate(['/vertical/list-administrateur']);
    }
     isValidPhoneNumber(entered_number: string): Boolean {
        const regexStr = '^[+][0-9]{10,13}$';
        const regexp = new RegExp(regexStr);

         if (entered_number.length < 10 || entered_number.length > 13 || !entered_number.match(regexp)) {
             this.toastr.warning('Veuillez entrer un numéro de téléphone valide', '',
                 {timeOut: 2000});
            return false;
        } else {
            return true;
         }
    }
    mapFormValuesToModel() {
        this.administrateur.mobilePhone =  this.userForm.value.mobilePhone;
        this.administrateur.nom =  this.userForm.value.nom;
        this.administrateur.prenom =  this.userForm.value.prenom;
        this.administrateur.email =  this.userForm.value.email;
        this.administrateur.reseau =  this.userForm.value.reseau;
        this.administrateur.groupes =  this.userForm.value.groupes;
        this.administrateur.enabled =  this.userForm.value.enabled;
        this.administrateur.genre = this.userForm.value.genre;
        this.administrateur.marcheSuivi = this.userForm.value.marcheSuivi;
        this.administrateur.marchePreference = this.userForm.value.marchePreference;
        this.administrateur.depotSuivi = this.userForm.value.depotSuivi;
        this.administrateur.depotPreference = this.userForm.value.depotPreference;
     }
    update(): void {
        this.mapFormValuesToModel();
        this.administrateurService.update(this.administrateur.id, this.userType, this.administrateur)
            .subscribe(
                data => {
                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});

                            this.router.navigate(['/vertical/list-' + this.userType,
                                { userType: this.userType}]);


                },
                error => console.log(error));
    }
create(): void {
        this.mapFormValuesToModel();
         this.administrateurService.create(this.userType, this.administrateur)
            .subscribe(
                data => {
                    this.toastr.success('Création réussie de ' + this.administrateur.toString(), '',
                        {timeOut: 2000});
                           this.userForm.reset();

                        }
                ,
                error => console.log(error));

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {
            this.create();
        }
    }

}
