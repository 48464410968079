<form [formGroup]="loginForm">
  <div class="row">
    <div class="col-sm-5">
  <tc-form-group>
    <tc-form-label> Numéro Mobile </tc-form-label>
    <tc-input type="text" [placeholder]="'+226XXXXXXXX'" formControlName="mobilePhone" >
    </tc-input>
    <ng-container *ngIf="loginForm.get('mobilePhone').invalid && (loginForm.get('mobilePhone').touched || loginForm.get('mobilePhone').dirty)">
      <span @fadeIn class="error" *ngIf="loginForm.get('mobilePhone').getError('required')">Veuillez saisir votre N° Mobile</span>
    </ng-container>

  </tc-form-group>
    </div>
    </div>
  <div class="row">
    <div class="col-sm-5">
        <tc-form-group >
          <button tc-button (click)="resetPassword()" [block]="true" >Réinitialiser </button>
        </tc-form-group>
    </div>
  </div>
</form>
