import { SecteurActivite } from './secteurActivite';
var Activite = /** @class */ (function () {
    function Activite() {
        this.actif = true;
        this.secteurActivite = new SecteurActivite();
    }
    Object.defineProperty(Activite.prototype, "nomSecteurActivite", {
        get: function () {
            if (this.secteurActivite != null) {
                return this.secteurActivite.nom;
            }
            return '--';
        },
        enumerable: true,
        configurable: true
    });
    Activite.fromJSON = function (json) {
        var result = new Activite();
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    Activite.listFromJSON = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var activite = Activite.fromJSON(jsonObject);
                if (activite.secteurActivite) {
                    jsonObject.secteurActivite = activite.secteurActivite.nom;
                }
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    return Activite;
}());
export { Activite };
