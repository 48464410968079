import { OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { state } from '../../interfaces/general';
var TCCheckboxComponent = /** @class */ (function () {
    function TCCheckboxComponent() {
        this.onChange = function () { };
        this.onTouched = function () { };
        this._value = false;
        this.label = '';
        this.name = '';
        this.disabled = false;
        this.states = state;
    }
    Object.defineProperty(TCCheckboxComponent.prototype, "focus", {
        get: function () {
            return this._value;
        },
        enumerable: true,
        configurable: true
    });
    TCCheckboxComponent.prototype.ngOnInit = function () {
        this.setStyles(this.disabled ? this.states.disabled : (this._value ? this.states.focus : this.states.default));
    };
    Object.defineProperty(TCCheckboxComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (val) {
            this._value = val;
            this.onChange(val);
            this.onTouched();
        },
        enumerable: true,
        configurable: true
    });
    TCCheckboxComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    TCCheckboxComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    TCCheckboxComponent.prototype.writeValue = function (value) {
        if (value) {
            this.value = value;
        }
    };
    TCCheckboxComponent.prototype.switch = function (bool) {
        if (!this.disabled) {
            this.value = !bool;
            this.setStyles(this.states[!bool ? 'focus' : 'hover']);
        }
    };
    TCCheckboxComponent.prototype.onMouseEnter = function () {
        if (!this.disabled) {
            this.setStyles(this.states[this.value ? 'focus' : 'hover']);
        }
    };
    TCCheckboxComponent.prototype.onMouseLeave = function () {
        if (!this.disabled) {
            this.setStyles(this.states[this.value ? 'focus' : 'default']);
        }
    };
    TCCheckboxComponent.prototype.setStyles = function (st, bg, border, color, labelColor) {
        if (bg === void 0) { bg = this.bgColor; }
        if (border === void 0) { border = this.borderColor; }
        if (color === void 0) { color = this.color; }
        if (labelColor === void 0) { labelColor = this.labelColor; }
        var styleIndex = 0;
        switch (st) {
            case this.states.hover:
                styleIndex = 1;
                break;
            case this.states.focus:
                styleIndex = 2;
                break;
            case this.states.disabled:
                styleIndex = 3;
                break;
            default:
                styleIndex = 0;
        }
        this.currentBgColor = bg instanceof Array ? bg[styleIndex] : bg;
        this.currentBorderColor = border instanceof Array ? border[styleIndex] : border;
        this.currentColor = color instanceof Array ? color[styleIndex] : color;
        this.currentLabelColor = labelColor instanceof Array ? labelColor[styleIndex] : labelColor;
    };
    TCCheckboxComponent.prototype.getStyles = function () {
        return {
            'background-color': this.currentBgColor,
            'border-color': this.currentBorderColor,
            'color': this.currentColor
        };
    };
    TCCheckboxComponent.prototype.getLabelColor = function () {
        return {
            'color': this.currentLabelColor
        };
    };
    return TCCheckboxComponent;
}());
export { TCCheckboxComponent };
