import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ValiderNoteMarcheService = /** @class */ (function () {
    function ValiderNoteMarcheService(http) {
        this.http = http;
        this.baseUrl = '/api/noteMarche';
    }
    ValiderNoteMarcheService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    ValiderNoteMarcheService.prototype.getAllByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderNoteMarcheService.prototype.getAllValidesByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_valides_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderNoteMarcheService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ValiderNoteMarcheService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    ValiderNoteMarcheService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    ValiderNoteMarcheService.prototype.valider = function (obj) {
        return this.http.post(this.baseUrl + "/valider", obj);
    };
    ValiderNoteMarcheService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    ValiderNoteMarcheService.prototype.rejeter = function (obj) {
        return this.http.post(this.baseUrl + "/rejeter", obj);
    };
    ValiderNoteMarcheService.prototype.abandonner = function (obj) {
        return this.http.post(this.baseUrl + "/abandonner", obj);
    };
    ValiderNoteMarcheService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    ValiderNoteMarcheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValiderNoteMarcheService_Factory() { return new ValiderNoteMarcheService(i0.ɵɵinject(i1.HttpClient)); }, token: ValiderNoteMarcheService, providedIn: "root" });
    return ValiderNoteMarcheService;
}());
export { ValiderNoteMarcheService };
