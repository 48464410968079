import { OnInit, EventEmitter } from '@angular/core';
import { TCDropdownButtonComponent } from './button/button.component';
import { TCDropdownContentComponent } from './content/content.component';
var TCDropdownComponent = /** @class */ (function () {
    function TCDropdownComponent() {
        this.opened = false;
        this.closeEvent = new EventEmitter();
    }
    Object.defineProperty(TCDropdownComponent.prototype, "getOpened", {
        get: function () {
            return this.opened;
        },
        enumerable: true,
        configurable: true
    });
    TCDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.dropdownButton) {
            this.dropdownButton.dropdownState.subscribe(function (state) {
                _this.toggleState(state);
            });
        }
        if (this.dropdownContent) {
            this.dropdownContent.closeDropdown.subscribe(function () {
                _this.opened = false;
                _this.dropdownButton.active = false;
            });
        }
        this.closeEvent.subscribe(function (event) {
            _this.hide();
        });
    };
    // toggles menu visibility
    TCDropdownComponent.prototype.toggleState = function (state) {
        state ? this.show() : this.hide();
    };
    // show dropdown
    TCDropdownComponent.prototype.show = function (position) {
        if (position === void 0) { position = this.dropdownButton.getButtonParams(); }
        this.opened = true;
        // show dropdown content
        this.dropdownContent.show();
        // update dropdown content position based on its button's
        this.dropdownContent.updatePosition(position);
    };
    // hide dropdown
    TCDropdownComponent.prototype.hide = function () {
        this.opened = false;
        // hide dropdown content
        this.dropdownContent.hide();
    };
    return TCDropdownComponent;
}());
export { TCDropdownComponent };
