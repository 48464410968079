import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {Marche} from '../models/marche';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProduitService} from '../produit/produit.service';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';
import {IOption} from '../../../ui/interfaces/option';
import {MesureService} from '../mesure/mesure.service';
import {Mesure} from '../models/mesure';
import {fadeIn} from '../../../animations/form-error';
import {UserService} from '../administrateur/user.service';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';
import {ModifierStockService} from './modifier-stock.service';

@Component({
    selector: 'tc-modifier-saisie-stock',
    templateUrl: './modifier-saisie-stock.component.html',
    styleUrls: ['./modifier-saisie-stock.component.scss'],
    animations: [fadeIn]
})


export class ModifierSaisieStockComponent extends BasePageComponent implements  OnInit, OnDestroy, AfterViewInit {

    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private marcheService: MarcheService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private produitService: ProduitService, private mesureService: MesureService,
                 private fb: FormBuilder, private i18n: NzI18nService, private modifierStockService: ModifierStockService,
                 private adminService: UserService
    ) {
        super(store, httpSv);
        this.pageData = {
            title: 'Modification des stocks',
            subTitle: '',
            loaded: true,
            breadcrumbs: [ ]
        };
        this.i18n.setLocale(fr_FR);
        this.depots = [];
    }

    SelectedDepotId: string;
    data: any;
    depotsAdapter: IOption[] = [];
    mesures: Mesure[];
    mesuresAdapter: IOption[] = [];
    depots: any[];
    produits: any[];
    editCache: { [key: string]: any } = {};
    listOfData: any[] = [];
    outData: any[] = [];
    @ViewChild('dateStock', {static: false}) mydateStock: jqxDateTimeInputComponent;
    dateTimeInputSettings: jqwidgets.DateTimeInputOptions = {
        formatString: 'd',  width: '200px', height: '25px'
    };
    ngAfterViewInit(): void {
        //   this.mydatePrix.createComponent(this.dateTimeInputSettings);

    }
    saveEdit(id: string): void {
        const index = this.listOfData.findIndex(item => item.id === id);
        Object.assign(this.listOfData[index], this.editCache[id].data);
        this.editCache[id].edit = true;
    }

    updateEditCache(): void {
        this.listOfData.forEach(item => {
            this.editCache[item.id] = {
                edit: true,
                data: { ...item }
            };
        });
    }
    enregistrer() {
        // const result = this.editCache.filter(o => (o.prixGros > 0) || (o.prixDetail > 0));
        // console.log ('Filtre elements modifiés', [this.editCache, result]);
        this.outData = [];
        this.listOfData.forEach(item => {
            if ((this.editCache[item.id].data.prixGros > 0) || (this.editCache[item.id].data.prixDetail > 0)) {
                this.outData.push(this.editCache[item.id].data);
            }
        });
        this.data = {data: {
                dateStock:  this.mydateStock.getDate().toLocaleDateString('en-US'),
                stocks: this.outData,
                depot: this.SelectedDepotId
            }};
        this.modifierStockService.create(this.data) .subscribe(
            data => {
                this.toastr.success('Enregistrement réussie des prix ', '',
                    {timeOut: 2000});
                this.onSelectDepot(this.SelectedDepotId);
            },
            error => console.log(error));
    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        for (const depot of this.depots) {
            this.depotsAdapter.push({'value' : depot.id.toString(), 'label' : depot.nom});
        }

    }

    getCodeMesure(idMesure: string): string {
        const  mesureSelected: Mesure = this.mesures.find(obj => obj.id.toString() === idMesure);
        return mesureSelected.code;
    }
    onMesuresPromise() {
        for (const mesure of this.mesures) {
            this.mesuresAdapter.push({'value' : mesure.id.toString(), 'label' : mesure.code});
        }
    }
    onSelectDepot($event: any) {
        this.modifierStockService.getAllByDepotAndDate(this.SelectedDepotId, this.mydateStock.getDate().toLocaleDateString('en-US'))
            .subscribe(data => {
                console.log('data', [data]) ;
                this.listOfData = [];
                data.forEach((stock, index, array) => {
                    console.log('prix',
                        [stock]);
                        this.listOfData.push({
                        id: stock.produitId,
                        nomProduit: stock.nom_produit,
                        stock: stock.stock,
                        mesure: stock.mesure
                    });

                });
                this.updateEditCache();
            });

    }

    ngOnInit() {
        super.ngOnInit();
        this.SelectedDepotId = '1' ;
        this.adminService.mesDepots().subscribe(depots => {
            this.depots = depots ;
            this.setLoaded();
        });
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.listOfData = [];
       }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

}
