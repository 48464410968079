import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {Alerte} from '../../models/alerte';
import {FormBuilder,
    FormGroup, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {ReseauService} from '../../reseau/reseau.service';
import {IOption} from '../../../../ui/interfaces/option';
import {ProduitService} from '../../produit/produit.service';
import {GroupeService} from '../../groupe/groupe.service';
import {AlerteOffreAchatService} from '../alerteOffreAchat.service';
import {Marche} from '../../models/marche';
import {Produit} from '../../models/produit';
import {AlerteEdit} from '../../models/alerteEdit';

@Component({
    selector: 'tc-alerte-offre-vente-create',
    templateUrl: './create-alerteOffreAchat.component.html',
     animations: [fadeIn]
})
export class PageCreateAlerteOffreAchatComponent extends BasePageComponent implements OnInit, OnDestroy {

    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private alertePrixService: AlerteOffreAchatService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private reseauService: ReseauService,
                 private produitService: ProduitService,
                 private groupeService: GroupeService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.alerte = new Alerte();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.initReactiveForm();
    }
    alerteForm: FormGroup;
    alerte: any;
    produits: any[] = [];
    produitsAdapter: IOption[] = [];
    alerteType: string;
    groupes: any[] = [];
    groupesAdapter: IOption[] = [];
    alertePrixAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            libelle: 'Libellé alerte',
            reseau: 'Réseau',
            description: 'Description',
            produits: 'Produits',
            groupes: 'Groupes',
            actif: 'Actif'

        };
    static getAlerte (jsonValue): AlerteEdit {
        return AlerteEdit.fromJSON(jsonValue) ;
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);

            this.setReactiveForm();



    }

    getProduitObject (jsonValue): Produit {
        return Produit.fromJSON(jsonValue) ;
    }
    getTitle(): string {
        if ( this.isEditing()) {
            const prefixe = ' Modifier ' ;
                return prefixe + ' une alerte d\'offres d\'achat';

        } else {
            const prefixe = ' Créer ' ;
                return prefixe + ' une alerte d\'offres d\'achat';

        }


    }


    isEditing(): boolean {
        return (this.alerte.id != null);
    }
   initReactiveForm() {
        this.alerteForm = this.fb.group({
                libelle: ['', [Validators.required, Validators.minLength(2)]],
                produits: ['', Validators.required],
                groupes: ['', Validators.required],
                lundi: [false],
                mardi: [false],
                mercredi: [false],
                jeudi: [false],
                vendredi: [false],
                samedi: [false],
                dimanche: [false],
                recevoirParEmail: [false],
                recevoirParSMS: [true],
                actif: [true]
        }
        ) ;

    }


    setReactiveForm() {
        if ( this.alerte) {
            const alertePrix: AlerteEdit = PageCreateAlerteOffreAchatComponent.getAlerte(this.alerte);
            const selectedGroupes =  (alertePrix.selectedGroupes !== []) ? alertePrix.selectedGroupes : '';
            const selectedProducts =  (alertePrix.selectedProducts !== []) ? alertePrix.selectedProducts : ''
            this.alerteForm.patchValue({
                    libelle: this.alerte.libelle,
                    actif: this.alerte.actif,
                    lundi: alertePrix.lundi,
                    mardi: alertePrix.mardi,
                    mercredi: alertePrix.mercredi,
                    jeudi: alertePrix.jeudi,
                    vendredi: alertePrix.vendredi,
                    samedi: alertePrix.samedi,
                    dimanche: alertePrix.dimanche,
                    recevoirParEmail: alertePrix.recevoirParEmail,
                    recevoirParSMS: alertePrix.recevoirParSMS,
                    groupes: selectedGroupes,
                    produits: selectedProducts
                }
            );
        }


    }
    onProduitsPromise() {
        this.produitsAdapter = [];
        for (const produit of this.produits) {
            this.produitsAdapter.push({'value' : produit.id, 'label' : this.getProduitObject(produit).getFullName});
        }

    }
    onGroupesPromise() {
        this.groupesAdapter = [];
        for (const groupe of this.groupes) {
            this.groupesAdapter.push({'value' : groupe.id, 'label' : groupe.code});
        }

    }

    ngOnInit() {
        super.ngOnInit();

        this.produitService.getCached().subscribe(data => {
                this.produits = data;
                this.onProduitsPromise();
            }
        );
        this.groupeService.getCached().subscribe(data => {
                this.groupes = data;
                this.onGroupesPromise();
            }
        );


        this.route.params.subscribe(params => {
            this.alerteType = params['alerteType'];
            this.pageData.title = this.getTitle();
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.alertePrixService.getURL(this.selectedId), 'alerte', 'alerte', 'setLoaded');

            } else {
                this.alerte = new Alerte();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-alertePrix']);
    }
    liste() {
        this.router.navigate(['/vertical/list-alertePrix']);
    }
    mapFormValuesToModel() {
        this.alerte.libelle =  this.alerteForm.value.libelle;
        this.alerte.actif =  this.alerteForm.value.actif;
        this.alerte.produits =  this.alerteForm.value.produits;
        this.alerte.groupes =  this.alerteForm.value.groupes;
        this.alerte.recevoirParEmail =  this.alerteForm.value.recevoirParEmail;
        this.alerte.recevoirParSMS =  this.alerteForm.value.recevoirParSMS;
        this.alerte.lundi =  this.alerteForm.value.lundi;
        this.alerte.mardi =  this.alerteForm.value.mardi;
        this.alerte.mercredi =  this.alerteForm.value.mercredi;
        this.alerte.jeudi =  this.alerteForm.value.jeudi;
        this.alerte.vendredi =  this.alerteForm.value.vendredi;
        this.alerte.samedi =  this.alerteForm.value.samedi;
        this.alerte.dimanche =  this.alerteForm.value.dimanche;
       }


    update(): void {
        if (this.alerteForm.valid) {
        this.mapFormValuesToModel();
        this.alertePrixService.update(this.alerte.id, this.alerteType, this.alerte)
            .subscribe(
                data => {

                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/liste-alertes-' + this.alerteType,
                                { alerteType: this.alerteType}]);
                        }
                ,
                error => console.log(error));
        }

    }
    create(): void {
        if (this.alerteForm.valid) {
            this.mapFormValuesToModel();
            this.alertePrixService.create(this.alerteType, this.alerte)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie ' , '',
                            {timeOut: 2000});
                        this.alertePrixAdapter = [];
                        this.alerteForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
