import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserService} from '../administrateur/user.service';
import {fadeIn} from '../../../animations/form-error';
import {GenreService} from '../../../services/sharedServices/genre.service';
import {ActiviteService} from '../activite/activite.service';
import {CommuneService} from '../commune/commune.service';
import {ReseauService} from '../reseau/reseau.service';
import {GroupeService} from '../groupe/groupe.service';
import {Activite} from '../models/activite';
import {Commune} from '../models/commune';
import {Acteur} from '../models/acteur';
import {Reseau} from '../models/reseau';
import {fromEvent, merge, of, Subject, Subscription} from 'rxjs';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {MarcheService} from '../marche/marche.service';
import {ProduitService} from '../produit/produit.service';
import {INoeud} from '../produit/create';
import {DepotService} from '../depot/depot.service';

export interface IchangePassword {
    status: string;
}

@Component({
    selector: 'tc-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    animations: [fadeIn]
})
export class PageChangePasswordComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    SERVER_URL = 'api/upload_avatar';
    constructor(store: Store<IAppState>,
                httpSv: HttpService,
                private route: ActivatedRoute,
                public router: Router, private toastr: ToastrService,
                private genreService: GenreService, private activiteService: ActiviteService,
                private communeService: CommuneService, private i18n: NzI18nService,
                private reseauService: ReseauService,
                private marcheService: MarcheService,
                private depotService: DepotService,
                private fb: FormBuilder, private groupeService: GroupeService , private produitService: ProduitService,
                private adminService: UserService, private auth: AuthenticationService,
                private httpClient: HttpClient

    ) {
        super(store, httpSv);
        this.administrateur = {};
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);
        this.initReactiveForm();

    }
    changePwdForm: FormGroup;
    public actualPwdKeyUp = new Subject<KeyboardEvent>();
    userType: string;
    administrateur: any;
    selectedId: number;
    Columnslabels =
        {
            newPassword: 'Nouveau mot de passe',
            confirmpassword: 'Confirmer le Mot de passe'

        };
    static getAdministrateur (jsonValue): Acteur {
        return Acteur.fromJSON(jsonValue) ;
    }
     MatchPassword(ac: AbstractControl): ValidatorFn {
        const pass = ac.get('password').value;
        const confirmPass = ac.get('confirmpassword').value;
            if (pass !== confirmPass) {
                ac.get('confirmpassword').setErrors({ notMatch: true });
            }

        return null;
    }
    getTitle(): string {
       return 'Compte : ' + this.getNomComplet();

    }
    getNomComplet(): string {
        return  this.administrateur.nom + ' ' + this.administrateur.prenom;

    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.pageData.title =  this.getTitle();
        this.userType = this.administrateur.role;
     }


    initReactiveForm() {
       this.changePwdForm = this.fb.group({
                password: ['', [Validators.required, Validators.minLength(8)]],
                confirmpassword: ['', [Validators.required, Validators.minLength(8)]]
            }
            , { validators: this.MatchPassword }
        ) ;

    }

    ngOnInit() {
        super.ngOnInit();
        if (this.auth.currentUserDataObject === null) {
            this.router.navigate(['/vertical/dashboard']);
        } else {
        this.selectedId = this.auth.currentUserDataObject.id;
        this.getDataByName(this.adminService.getURL(this.selectedId), 'administrateur', 'acteur', 'setLoaded');
      }


    }
    ngAfterViewInit() {


    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-administrateur']);
    }
    liste() {
        this.router.navigate(['/vertical/list-administrateur']);
    }

    changePassword() {
                if  (this.changePwdForm.valid ) {
                                this.adminService.changePassword(this.changePwdForm.value.password).subscribe(
                                    resultat => {
                                        if (resultat.status === 'OK' ) {
                                            this.toastr.success('Succès du changement du mot de passe ', '',
                                                {timeOut: 2000});
                                            this.router.navigate(['']);
                                        } else {
                                            this.toastr.error('Echec du changement du mot de passe ', '',
                                                {timeOut: 2000});
                                        }
                                    }
                                );


                            } else  {
                                if (!this.changePwdForm.get('actualPassword').valid) {
                                    this.toastr.error('Le mot de passe actuel doit être renseigné et valide ', '',
                                        {timeOut: 2000});
                                }

                            }



    }
    onSubmit() {
        this.changePassword();

    }

}
