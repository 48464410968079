var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { UserService } from '../../ui/administrateur/user.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { FormBuilder } from '@angular/forms';
import { RegionService } from '../../ui/region/region.service';
import { MesureService } from '../../ui/mesure/mesure.service';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
var PageAnalyticsComponent = /** @class */ (function (_super) {
    __extends(PageAnalyticsComponent, _super);
    function PageAnalyticsComponent(store, dashboardService, httpSv, adminService, regionService, mesureService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dashboardService = dashboardService;
        _this.adminService = adminService;
        _this.regionService = regionService;
        _this.mesureService = mesureService;
        _this.fb = fb;
        _this.titrecartePrix = 'Evolution du prix';
        _this.titreBarPrix = 'Comparaison des prix par marché';
        _this.titreBarPrixRegion = 'Comparaison des prix par région';
        _this.titreOffresVente = '';
        _this.titreOffresAchat = '';
        _this.Columnslabels = {
            dateDebut: 'Date Initialisation',
            periode: 'Période'
        };
        _this.produits = [];
        _this.marches = [];
        _this.mesures = [];
        _this.regions = [];
        _this.produitsAdapter = [];
        _this.marchesAdapter = [];
        _this.regionsAdapter = [];
        _this.mesuresAdapter = [];
        _this.pageData = {
            title: 'Analyses',
            breadcrumbs: [
                {
                    title: 'Tableau de bord',
                    route: 'dashboard'
                },
                {
                    title: 'Analyses'
                }
            ]
        };
        _this.table = [];
        _this.pieStyle = {
            normal: {
                label: {
                    color: '#000',
                    position: 'inner'
                },
                labelLine: {
                    show: false
                }
            }
        };
        return _this;
    }
    PageAnalyticsComponent.prototype.setDOptionsOffreAchat = function (datas) {
        this.dOptionsOA = {
            grid: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br>{c} ({d}%)'
            },
            series: [{
                    name: 'pie',
                    type: 'pie',
                    selectedMode: 'single',
                    selectedOffset: 30,
                    clockwise: true,
                    radius: [0, '90%'],
                    data: datas,
                    itemStyle: this.pieStyle
                }]
        };
    };
    PageAnalyticsComponent.prototype.setDOptionsOffreVente = function (datas) {
        this.dOptionsOV = {
            grid: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br>{c} ({d}%)'
            },
            series: [{
                    name: 'pie',
                    type: 'pie',
                    selectedMode: 'single',
                    selectedOffset: 30,
                    clockwise: true,
                    radius: [0, '90%'],
                    data: datas,
                    itemStyle: this.pieStyle
                }]
        };
    };
    PageAnalyticsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.initReactiveForm();
        this.adminService.mesProduits().subscribe(function (produits) {
            _this.produits = produits;
            _this.produitsAdapter = [];
            for (var _i = 0, _a = _this.produits; _i < _a.length; _i++) {
                var produit = _a[_i];
                _this.produitsAdapter.push({ 'value': produit.id.toString(), 'label': produit.nom });
            }
            _this.analyticForm.patchValue({
                produit: _this.produits[0].id.toString(),
                produitComp: _this.produits[0].id.toString(),
                produitCompRegion: _this.produits[0].id.toString(),
                produitOffreRegion: _this.produits[0].id.toString()
            });
            _this.setLoaded();
        });
        this.adminService.mesMarchesPreferes().subscribe(function (marches) {
            _this.marches = marches;
            _this.marchesAdapter = [];
            for (var _i = 0, _a = _this.marches; _i < _a.length; _i++) {
                var marche = _a[_i];
                _this.marchesAdapter.push({ 'value': marche.id.toString(), 'label': marche.nom });
            }
            _this.analyticForm.patchValue({
                marches: [_this.marches[0] ? _this.marches[0].id.toString() : ''],
                marche: _this.marches[0] ? _this.marches[0].id.toString() : ''
            });
            _this.setLoaded();
        });
        this.getData(this.regionService.getAllURL(), 'regions', 'onRegionsPromise');
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.analyticForm.get('produit').valueChanges.subscribe(function (selectedValue) {
            var produit = _this.produits.find(function (obj) { return obj.id.toString() === selectedValue; });
            var marche = _this.marches.find(function (obj) { return obj.id.toString() === _this.analyticForm.get('marche').value; });
            _this.titrecartePrix = 'Evolution des prix de ' + produit.nom;
            if (marche !== undefined) {
                _this.titrecartePrix += ' sur le marché ' + marche.nom;
            }
            _this.dashboardService.getCartePrix(selectedValue, _this.analyticForm.get('marche').value, _this.analyticForm.get('mesure').value, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                _this.statistiques = datas;
                _this.setHSOptions(datas);
                _this.chartsReady = true;
            });
        });
        this.analyticForm.get('marche').valueChanges.subscribe(function (selectedValue) {
            var marche = _this.marches.find(function (obj) { return obj.id.toString() === selectedValue; });
            var produit = _this.produits.find(function (obj) { return obj.id.toString() === _this.analyticForm.get('produit').value; });
            if (produit !== undefined) {
                _this.titrecartePrix = 'Evolution des prix de ' + produit.nom;
                if (marche !== undefined) {
                    _this.titrecartePrix += ' sur le marché ' + marche.nom;
                }
                _this.dashboardService.getCartePrix(_this.analyticForm.get('produit').value, selectedValue, _this.analyticForm.get('mesure').value, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                    _this.statistiques = datas;
                    _this.setHSOptions(datas);
                    _this.chartsReady = true;
                });
            }
        });
        this.analyticForm.get('mesure').valueChanges.subscribe(function (selectedValue) {
            var marche = _this.marches.find(function (obj) { return obj.id.toString() === _this.analyticForm.get('marche').value; });
            var produit = _this.produits.find(function (obj) { return obj.id.toString() === _this.analyticForm.get('produit').value; });
            if (produit !== undefined) {
                _this.titrecartePrix = 'Evolution des prix de ' + produit.nom;
                if (marche !== undefined) {
                    _this.titrecartePrix += ' sur le marché ' + marche.nom;
                }
                _this.dashboardService.getCartePrix(_this.analyticForm.get('produit').value, _this.analyticForm.get('marche').value, selectedValue, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                    _this.statistiques = datas;
                    _this.setHSOptions(datas);
                    _this.chartsReady = true;
                });
            }
        });
        this.analyticForm.get('marches').valueChanges.subscribe(function (selectedValue) {
            if ((selectedValue !== undefined) && (_this.analyticForm.get('produitComp').value !== undefined)) {
                var produit = _this.produits.find(function (obj) { return obj.id.toString() === _this.analyticForm.get('produitComp').value; });
                if (produit !== undefined) {
                    _this.titreBarPrix = 'Comparaison des prix de ' + produit.nom + ' par marché';
                    _this.dashboardService.getComparaison(_this.analyticForm.get('produitComp').value, selectedValue, _this.analyticForm.get('mesureMarche').value, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                        if (datas.barChartData.length > 0) {
                            _this.setBarChart(datas);
                            _this.barChartsReady = true;
                        }
                    });
                }
            }
        });
        this.analyticForm.get('produitComp').valueChanges.subscribe(function (selectedValue) {
            if ((selectedValue !== undefined) && (_this.analyticForm.get('marches').value !== undefined)) {
                var produit = _this.produits.find(function (obj) { return obj.id.toString() === selectedValue; });
                _this.titreBarPrix = 'Comparaison des prix de ' + produit.nom + ' par marché';
                _this.dashboardService.getComparaison(selectedValue, _this.analyticForm.get('marches').value, _this.analyticForm.get('mesureMarche').value, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                    if (datas.barChartData.length > 0) {
                        _this.setBarChart(datas);
                        _this.barChartsReady = true;
                    }
                });
            }
        });
        this.analyticForm.get('mesureMarche').valueChanges.subscribe(function (selectedValue) {
            if ((selectedValue !== undefined) && (_this.analyticForm.get('marches').value !== undefined)) {
                var produit = _this.produits.find(function (obj) { return obj.id.toString() === _this.analyticForm.get('produitComp').value; });
                _this.titreBarPrix = 'Comparaison des prix de ' + produit.nom + ' par marché';
                _this.dashboardService.getComparaison(_this.analyticForm.get('produitComp').value, _this.analyticForm.get('marches').value, selectedValue, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                    if (datas.barChartData.length > 0) {
                        _this.setBarChart(datas);
                        _this.barChartsReady = true;
                    }
                });
            }
        });
        this.analyticForm.get('regions').valueChanges.subscribe(function (selectedValue) {
            if ((selectedValue !== undefined) && (_this.analyticForm.get('produitCompRegion').value !== undefined)) {
                var produit = _this.produits.find(function (obj) { return obj.id.toString() === _this.analyticForm.get('produitCompRegion').value; });
                if (produit !== undefined) {
                    _this.titreBarPrixRegion = 'Comparaison des prix de ' + produit.nom + ' par région';
                    _this.dashboardService.getComparaisonRegion(_this.analyticForm.get('produitCompRegion').value, selectedValue, _this.analyticForm.get('mesureRegion').value, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                        if (datas.barChartData.length > 0) {
                            _this.setBarChartRegion(datas);
                            _this.barChartsRegionReady = true;
                        }
                    });
                }
            }
        });
        this.analyticForm.get('produitCompRegion').valueChanges.subscribe(function (selectedValue) {
            if ((selectedValue !== undefined) && (_this.analyticForm.get('regions').value !== undefined)) {
                var produit = _this.produits.find(function (obj) { return obj.id.toString() === selectedValue; });
                if (produit !== undefined) {
                    _this.titreBarPrixRegion = 'Comparaison des prix de ' + produit.nom + ' par région';
                    _this.dashboardService.getComparaisonRegion(selectedValue, _this.analyticForm.get('regions').value, _this.analyticForm.get('mesureRegion').value, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                        if (datas.barChartData.length > 0) {
                            _this.setBarChartRegion(datas);
                            _this.barChartsRegionReady = true;
                        }
                    });
                }
            }
        });
        this.analyticForm.get('mesureRegion').valueChanges.subscribe(function (selectedValue) {
            if ((selectedValue !== undefined) && (_this.analyticForm.get('regions').value !== undefined)) {
                var produit = _this.produits.find(function (obj) { return obj.id.toString() === _this.analyticForm.get('produitCompRegion').value; });
                _this.titreBarPrix = 'Comparaison des prix de ' + produit.nom + ' par région';
                _this.dashboardService.getComparaison(_this.analyticForm.get('produitCompRegion').value, _this.analyticForm.get('regions').value, selectedValue, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                    if (datas.barChartData.length > 0) {
                        _this.setBarChart(datas);
                        _this.barChartsReady = true;
                    }
                });
            }
        });
        this.analyticForm.get('produitOffreRegion').valueChanges.subscribe(function (selectedValue) {
            if ((selectedValue !== undefined)) {
                var produit = _this.produits.find(function (obj) { return obj.id.toString() === selectedValue; });
                if (produit !== undefined) {
                    _this.titreOffresVente = ' Volume comparé des offres de vente de ' + produit.nom + ' par régions';
                    _this.titreOffresAchat = ' Volume comparé des offres d\'achat de ' + produit.nom + ' par régions';
                    _this.dashboardService.getStatistiquesOffres(selectedValue, _this.dateDebut.getDate().toLocaleDateString()).subscribe(function (datas) {
                        var donneesOffreVente = [];
                        var donneesOffreAchat = [];
                        var color = 'rgba(5,172,76,0.4)';
                        var borderColor = '#058d41';
                        datas.achats.forEach(function (item, index) {
                            if (index === 1) {
                                color = '#fee8d7';
                                borderColor = '#e15200';
                            }
                            else if (index === 2) {
                                color = '#e6deff';
                                borderColor = '#805aff';
                            }
                            else if (index === 3) {
                                color = '#d5edff';
                                borderColor = '#2fa7ff';
                            }
                            donneesOffreAchat.push({
                                value: item.montant, name: item.region,
                                itemStyle: { normal: { color: color, borderColor: borderColor } }
                            });
                        });
                        datas.ventes.forEach(function (item, index) {
                            if (index === 1) {
                                color = '#fee8d7';
                                borderColor = '#e15200';
                            }
                            else if (index === 2) {
                                color = '#e6deff';
                                borderColor = '#805aff';
                            }
                            else if (index === 3) {
                                color = '#d5edff';
                                borderColor = '#2fa7ff';
                            }
                            donneesOffreVente.push({
                                value: item.montant, name: item.region,
                                itemStyle: { normal: { color: color, borderColor: borderColor } }
                            });
                        });
                        _this.setDOptionsOffreAchat(donneesOffreAchat);
                        _this.setDOptionsOffreVente(donneesOffreVente);
                        _this.volumeChartsReady = true;
                    });
                }
            }
        });
    };
    PageAnalyticsComponent.prototype.onRegionsPromise = function () {
        for (var _i = 0, _a = this.regions; _i < _a.length; _i++) {
            var region = _a[_i];
            this.regionsAdapter.push({ 'value': region.id.toString(), 'label': region.nom });
        }
        this.analyticForm.patchValue({
            regions: [this.regions[0].id.toString()],
            regionsOffre: [this.regions[0].id.toString()]
        });
    };
    PageAnalyticsComponent.prototype.onMesuresPromise = function () {
        for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
            var mesure = _a[_i];
            this.mesuresAdapter.push({ 'value': mesure.id.toString(), 'label': mesure.nom });
        }
        this.analyticForm.patchValue({
            mesure: [this.mesures[0].id.toString()],
            mesureMarche: [this.mesures[0].id.toString()],
            mesureRegion: [this.mesures[0].id.toString()]
        });
    };
    PageAnalyticsComponent.prototype.initReactiveForm = function () {
        this.analyticForm = this.fb.group({
            produit: [''],
            marche: [''],
            produitComp: [''],
            mesure: [''],
            mesureMarche: [''],
            mesureRegion: [''],
            marches: [''],
            produitCompRegion: [''],
            regions: [''],
            regionsOffre: [''],
            produitOffreRegion: ['']
        });
    };
    PageAnalyticsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageAnalyticsComponent.prototype.ngAfterViewInit = function () {
        this.dateDebut.culture('fr-FR');
        var pdateDebut = new Date();
        pdateDebut.setFullYear(pdateDebut.getFullYear(), 0, 1);
        this.dateDebut.setDate(pdateDebut);
    };
    PageAnalyticsComponent.prototype.select = function (event) {
        if (event.args) {
            var item = event.args.item;
            if (item) {
                var valueElement = document.createElement('div');
                var labelElement = document.createElement('div');
                valueElement.innerHTML = 'Value: ' + item.value;
                labelElement.innerHTML = 'Label: ' + item.label;
            }
        }
    };
    PageAnalyticsComponent.prototype.setBarChart = function (datas) {
        this.barChartOptions = {
            scaleShowVerticalLines: false,
            responsive: true
        };
        this.barChartLabels = datas.barChartLabels;
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartData = datas.barChartData;
        this.barChartColors = [
            {
                backgroundColor: '#fc8b37',
                borderColor: '#fc8b37',
                pointBackgroundColor: '#fc8b37',
                pointBorderColor: '#fc8b37',
                pointHoverBackgroundColor: '#fc8b37',
                pointHoverBorderColor: '#fc8b37'
            },
            {
                backgroundColor: '#7cdb86',
                borderColor: '#7cdb86',
                pointBackgroundColor: '#7cdb86',
                pointBorderColor: '#7cdb86',
                pointHoverBackgroundColor: '#7cdb86',
                pointHoverBorderColor: '#7cdb86'
            }
        ];
    };
    PageAnalyticsComponent.prototype.setBarChartRegion = function (datas) {
        this.barChartOptions = {
            scaleShowVerticalLines: false,
            responsive: true
        };
        this.barChartLabelsRegion = datas.barChartLabels;
        this.barChartTypeRegion = 'bar';
        this.barChartLegendRegion = true;
        this.barChartDataRegion = datas.barChartData;
        this.barChartColorsRegion = [
            {
                backgroundColor: '#fc8b37',
                borderColor: '#fc8b37',
                pointBackgroundColor: '#fc8b37',
                pointBorderColor: '#fc8b37',
                pointHoverBackgroundColor: '#fc8b37',
                pointHoverBorderColor: '#fc8b37'
            },
            {
                backgroundColor: '#7cdb86',
                borderColor: '#7cdb86',
                pointBackgroundColor: '#7cdb86',
                pointBorderColor: '#7cdb86',
                pointHoverBackgroundColor: '#7cdb86',
                pointHoverBorderColor: '#7cdb86'
            }
        ];
    };
    PageAnalyticsComponent.prototype.setHSOptions = function (datas) {
        this.hsOptions = {
            color: ['#ed253d', '#027b0f'],
            tooltip: {
                trigger: 'none',
                axisPointer: {
                    type: 'cross'
                }
            },
            legend: {
                data: datas.legendes
            },
            grid: {
                left: 30,
                right: 30,
                top: 50,
                bottom: 50
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        onZero: false,
                        lineStyle: {
                            color: '#03720f'
                        }
                    },
                    axisPointer: {
                        label: {
                            formatter: function (params) {
                                return 'Prix ' + params.value
                                    + (params.seriesData.length ? '：' + params.seriesData[0].data : '');
                            }
                        }
                    },
                    data: datas.anneeN
                },
                {
                    type: 'category',
                    boundaryGap: false,
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        onZero: false,
                        lineStyle: {
                            color: '#0c0c0c'
                        }
                    },
                    axisPointer: {
                        label: {
                            formatter: function (params) {
                                return 'Prix ' + params.value
                                    + (params.seriesData.length ? '：' + params.seriesData[0].data : '');
                            }
                        }
                    },
                    data: datas.anneeN1
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        formatter: ''
                    }
                }
            ],
            series: [
                {
                    name: datas.legendeN1,
                    type: 'line',
                    xAxisIndex: 1,
                    smooth: true,
                    areaStyle: {
                        color: 'rgba(206,184,52,0.2)'
                    },
                    data: datas.serieN1
                },
                {
                    name: datas.legendeN,
                    type: 'line',
                    smooth: true,
                    areaStyle: {
                        color: 'rgba(57,85,9,0.2)'
                    },
                    data: datas.serieN
                }
            ]
        };
    };
    return PageAnalyticsComponent;
}(BasePageComponent));
export { PageAnalyticsComponent };
