<form [formGroup]="rapportsForm" >

    <div class="row" style="margin-bottom: 20px;">
            <ng-container *ngIf="statutAlerteAdapter.length>0 ">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Statut Alerte</tc-form-label>
                        <tc-select [options]="statutAlerteAdapter" formControlName="statutAlerte"  [allowClear]="true"  ></tc-select>
                    </tc-form-group>
                </div>
            </ng-container>

    </div>

<ng-container *ngIf="dataAdapter">

    <jqxDataTable #myDataTable
              [width]="getWidth()"  [source]="dataAdapter" [columns]="columns"
                   [pageable]="true" [sortable]="true" [columnsResize]="true" [altRows]="true" [filterable]="true"  [pagerMode]="'advanced'"
              [localization]="localizationObject" [theme]='"sima-bf"' [filterMode]="'advanced'" [exportSettings]="exportSettings"
>
    </jqxDataTable>
    <div class="row" style="margin-top: 20px;">

        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="excelExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en Excel</jqxButton>
        </div>
        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="pdfExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en PDF</jqxButton>
        </div>
        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="jsonExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en JSON</jqxButton>
        </div>
    </div>

</ng-container>
</form>
