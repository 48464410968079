<ng-container *ngIf="espece">
	<div class="col-sm-8">
		<tc-card [title]="''">
			<form [formGroup]="especeForm"   (ngSubmit)="onSubmit()">
				<tc-form-group>
					<tc-form-label>{{Columnslabels.code}} </tc-form-label>
					<tc-input type="text"  formControlName="code" >
					</tc-input>
					<ng-container *ngIf="especeForm.get('code').invalid && (especeForm.get('code').touched || especeForm.get('code').dirty)">
						<span @fadeIn class="error" *ngIf="especeForm.get('code').getError('required')">Veuillez saisir le code de la catégorie</span>
						<span @fadeIn class="error" *ngIf="especeForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
					</ng-container>
				</tc-form-group>

				<tc-form-group>
					<tc-form-label>{{Columnslabels.nom}}</tc-form-label>
					<tc-input type="text"  formControlName="nom">
					</tc-input>
					<ng-container *ngIf="especeForm.get('nom').invalid && (especeForm.get('nom').touched || especeForm.get('nom').dirty)">
						<span @fadeIn class="error" *ngIf="especeForm.get('nom').getError('required')">Veuillez saisir le nom de la espece</span>
						<span @fadeIn class="error" *ngIf="especeForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
					</ng-container>
				</tc-form-group>


				<tc-form-group>
					<tc-form-label>{{Columnslabels.description}}</tc-form-label>
					<tc-textarea  [charLimiter]="{ counter: 255, before: 'Il reste ', after: ' caractères' }"
								  [tcShape]="0" [rows]="2" formControlName="description"></tc-textarea>
				</tc-form-group>
				<tc-form-group>
					<tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
				</tc-form-group>

				<span *ngIf="!especeForm.valid && especeForm.touched" class="help-block">Veuillez entrer des données valides !</span>
				<button [disabled]="!especeForm.valid" tc-button [block]="true" >
					Valider
				</button>




			</form>

		</tc-card>
	</div>
</ng-container>

