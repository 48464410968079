import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GenreService {
	private baseUrl = '/api/genre';
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }


}
