import { EventEmitter, ElementRef, Renderer } from '@angular/core';
function setProperty(renderer, elementRef, propName, propValue) {
    renderer.setElementProperty(elementRef, propName, propValue);
}
var TCFilterTableDirective = /** @class */ (function () {
    function TCFilterTableDirective(element, renderer) {
        this.tcFilterTable = {};
        this.tableChanged = new EventEmitter();
        this.element = element;
        this.renderer = renderer;
    }
    Object.defineProperty(TCFilterTableDirective.prototype, "config", {
        get: function () {
            return this.tcFilterTable;
        },
        set: function (value) {
            this.tcFilterTable = value;
        },
        enumerable: true,
        configurable: true
    });
    TCFilterTableDirective.prototype.onChangeFilter = function (event) {
        this.tcFilterTable.filterString = event;
        this.tableChanged.emit({ filtering: this.tcFilterTable });
    };
    return TCFilterTableDirective;
}());
export { TCFilterTableDirective };
