import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {IOffreAchat} from './valider-offre-achat.component';
import {Prix} from '../models/prix';
import {IOffre} from '../../../ui/interfaces/iOffre';

@Injectable({
  providedIn: 'root'
})
export class ValiderOffreAchatService {
	private baseUrl = '/api/offre_achat';
  constructor(private http: HttpClient) {

  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllByReseau(reseauId: string): Observable<IOffreAchat[]> {
    return this.http.get(`${this.baseUrl}/get_all_by_reseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId)
    }) as Observable<IOffreAchat[]>;
  }
  getAllValidesByReseau(reseauId: string): Observable<IOffreAchat[]> {
    return this.http.get(`${this.baseUrl}/get_all_valides_by_reseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId)
    }) as Observable<IOffreAchat[]>;
  }
  getTopN(max: string): Observable<IOffreAchat[]> {
    return this.http.get(`${this.baseUrl}/getTopN`, {
      params: new HttpParams()
          .set('max', max)
    }) as Observable<IOffreAchat[]>;
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj);
  }
  valider(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/valider`, obj);
  }
  update(id: number, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/modifier/${id}`, value);
  }
  rejeter(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/rejeter`, obj);
  }
  abandonner(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/abandonner`, obj);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }
  devalider(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/devalider`, obj);
  }
  getOffresValides(dateDebut: string, dateFin: string,  produits: any[], regions: string, reseau: string): Observable<IOffre[]> {
    return this.http.get(`${this.baseUrl}/valides`, {
      params: new HttpParams()
          .set('dateDebut', dateDebut)
          .set('dateFin', dateFin)
          .set('produits', produits.toString())
          .set('regions', regions)
          .set('reseau', reseau)
    }) as Observable<IOffre[]>;
  }
}

