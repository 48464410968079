<div class="row" >
            <tc-card [title]="''">
                <div class="row">
                    <button tc-button (click)="downloadFile()">Télécharger la politique de confidentialité  </button>
                </div>

            </tc-card>

</div>

