var TCListImageSizeDirective = /** @class */ (function () {
    function TCListImageSizeDirective() {
    }
    Object.defineProperty(TCListImageSizeDirective.prototype, "imgWidth", {
        get: function () {
            return this.tcImageSize;
        },
        enumerable: true,
        configurable: true
    });
    return TCListImageSizeDirective;
}());
export { TCListImageSizeDirective };
