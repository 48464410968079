<div class="row" >
            <tc-card [title]="''">
                <form [formGroup] = "uploadForm" (ngSubmit)="onSubmit()">
                    <tc-form-group>
                        <input type="file" accept="application/vnd.ms-excel" name="profile" (change)="onFileSelect($event)"/>
                    </tc-form-group>

                <button
                       tc-button
                       type="submit" [block]="true"
                       [afterIcon]="'icofont-upload-alt'"
                                                                                          >
                       Importer les superviseurs
               </button>
                    <div class="row">
                        <a style="color:black; padding:0 10px; margin:10px;"  (click)="downloadFile()">
                            Télécharger le canevas d'importation des superviseurs
                        </a>
                    </div>

                </form>
            </tc-card>

</div>

