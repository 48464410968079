<div class="list-item-content">

  <div class="item-avatar-block">
    <ng-content select="[tc-list-avatar], [tcListAvatar], [tc-list-icon], [tcListIcon]"></ng-content>
  </div>

  <div class="item-text-block">
    <div class="list-item-text">
      <ng-content select="[tc-list-line], [tcListLine]"></ng-content>
    </div>
    
    <ng-content></ng-content>
  </div>
</div>
