import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./authentication.service";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    AuthGuardService.prototype.canActivate = function (route, state) {
        var currentUser = this.authService.currentUserValue;
        if (currentUser) {
            return true;
        }
        this.router.navigate(['/public/sign-in']);
        return false;
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
