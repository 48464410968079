<div class="col-sm-8">
<!--	<ng-container *ngIf="mesure">-->
		<form  [formGroup]="mesureForm"  novalidate (ngSubmit)="onSubmit()">

			<tc-card >
				<tc-form-group>
					<tc-form-label>{{Columnslabels.code}} </tc-form-label>
					<tc-input type="text" [placeholder]="''" formControlName="code" >
					</tc-input>
					<ng-container *ngIf="mesureForm.get('code').invalid && (mesureForm.get('code').touched || mesureForm.get('code').dirty)">
						<span @fadeIn class="error" *ngIf="mesureForm.get('code').getError('required')">Veuillez saisir le code de la mesure</span>
					</ng-container>
				</tc-form-group>

				<tc-form-group>
					<tc-form-label>{{Columnslabels.nom}} </tc-form-label>
					<tc-input type="text" [placeholder]="'Nom '" formControlName="nom" >
					</tc-input>
					<ng-container *ngIf="mesureForm.get('nom').invalid && (mesureForm.get('nom').touched || mesureForm.get('nom').dirty)">
						<span @fadeIn class="error" *ngIf="mesureForm.get('nom').getError('required')">Veuillez saisir le nom de l'unité de mesure</span>
						<span @fadeIn class="error" *ngIf="mesureForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
					</ng-container>

				</tc-form-group>
				<tc-form-group>
					<tc-form-label>{{Columnslabels.typeMesure}}</tc-form-label>
					<tc-select [placeholder]="'Type Mesure'" [options]="typesMesures"  formControlName="typeMesure"></tc-select>
					<ng-container *ngIf="mesureForm.get('typeMesure').invalid && (mesureForm.get('typeMesure').touched || mesureForm.get('typeMesure').dirty)">
						<span @fadeIn class="error" *ngIf="mesureForm.get('typeMesure').getError('required')">Veuillez saisir sélectionner le type de l'unité de mesure</span>
					</ng-container>
				</tc-form-group>
				<div class="row">
					<div class="col-12 col-sm-2">
						<tc-form-group>
							<tc-checkbox formControlName="inclureEtalonnage" >Inclure dans l'étalonnage</tc-checkbox>
						</tc-form-group>
					</div>
				</div>
				<div class="row">
					<div class="col-12 col-sm-2">
						<tc-form-group>
							<tc-checkbox formControlName="actif" >Actif</tc-checkbox>
						</tc-form-group>
					</div>
				</div>

				<span *ngIf="!mesureForm.valid && mesureForm.touched" class="help-block">Veuillez entrer des données valides !</span>
				<button tc-button type="submit" [block]="true" >
					Valider
				</button>


			</tc-card>
		</form>
<!--	</ng-container>-->
</div>
