import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {IDataNotification} from './actions.component';

@Injectable({
  providedIn: 'root'
})
export class ActionService {
	private baseUrl = '/api/notifications';
  constructor(private http: HttpClient) {

  }
  notifications(reseauId: number): Observable<IDataNotification[]> {
    return this.http.get(`${this.baseUrl}`, {
      params: new HttpParams()
          .set('reseauId', reseauId.toString())
    }) as Observable<IDataNotification[]>;
  }

}

