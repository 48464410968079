<ng-container>
    <ng-template #modalHeader>
        <h2 class="title">Rejet des notes</h2>
        <div class="actions">
            <tc-badge [view]="'warning'">Rejet</tc-badge>
        </div>
    </ng-template>

    <ng-template #modalBody>
        <tc-form-label>Veuillez détailler les causes du rejet</tc-form-label>
        <tc-textarea  [charLimiter]="{ counter: 255, before: 'Il reste ', after: ' caractères' }"
                      [tcShape]="0" [rows]="3" [(ngModel)] = "causeRejet" name="causeRejet"></tc-textarea>

    </ng-template>

    <ng-template #modalFooter>
        <div class="actions justify-content-between">
            <button tc-button (click)="validerRejet()">Rejeter</button>
            <button tc-button [outline]="true" (click)="closeModal()">Annuler</button>
        </div>
    </ng-template>
</ng-container>

<ng-container *ngIf="listOfAllDataNoteMarche.length>0 ">
<tc-card>
    <div class="row">
        <div class="col-sm-4 ">
            <div class="pull-left">
                <button tc-button (click)="valider()">Valider</button>
            </div>
        </div>

        <div class="col-sm-4 ">

        </div>
        <div class="col-sm-4 ">
            <div class="pull-right">
                <button tc-button (click)="openModal(modalBody, 'Rejet des notes ', modalFooter, { overlayClose: false })" [outline]="true" >Rejeter</button>
            </div>
        </div>
    </div>

</tc-card>

<tc-card title="VALIDER LES NOTES DE MARCHE">
<ng-container *ngIf="listOfAllDataNoteMarche.length>0 ">
    <span *ngIf="numberOfChecked"> {{ numberOfChecked }} éléments sélectionnés</span>

<nz-table
    #rowSelectionTable
    nzShowPagination
    nzShowSizeChanger
    [nzPageSize]="50"
    [nzData]="listOfAllDataNoteMarche"
    (nzCurrentPageDataChange)="currentPageDataChange($event)"
>
    <thead>
    <tr>
        <th
                nzShowCheckbox
                [(nzChecked)]="isAllDisplayDataChecked"
                [nzIndeterminate]="isIndeterminate"
                (nzCheckedChange)="checkAll($event)"
        ></th>
        <th>Date</th>
        <th>Contenu</th>
        <th>Image</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
        <td
                nzShowCheckbox
                [(nzChecked)]="mapOfCheckedId[data.id]"
                (nzCheckedChange)="refreshStatus()"
        ></td>
        <td>{{ data.date }}</td>
        <td>
            <p>{{data.contenu}}</p>
        </td>
        <td> </td>
    </tr>
    </tbody>
</nz-table>
</ng-container>
</tc-card>

<tc-card>
    <div class="row">
        <div class="col-sm-4 ">
            <div class="pull-left">
                <button tc-button (click)="valider()">Valider</button>
            </div>
        </div>

        <div class="col-sm-4 ">

        </div>
        <div class="col-sm-4 ">
            <div class="pull-right">
                <button tc-button (click)="openModal(modalBody, 'Rejet des notes ', modalFooter, { overlayClose: false })" [outline]="true" >Rejeter</button>
            </div>
        </div>
    </div>

</tc-card>
    <tc-card>
        <div class="buttons-list">
            <button  (click)="exportByExcel()"><i class="fa fa-file-excel-o" aria-hidden="false" style="font-size:48px;color:#C7B54A">
            </i></button>
        </div>
    </tc-card>
</ng-container>


