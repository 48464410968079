import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Acteur } from '../../pages/ui/models/acteur';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./authentication.service";
var WriterGuardService = /** @class */ (function () {
    function WriterGuardService(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    WriterGuardService.prototype.canActivate = function (route, state) {
        var connectedUser = Acteur.fromJSON(this.authService.currentUserDataObject);
        if (connectedUser !== null) {
            if (connectedUser.isWriter) {
                return true;
            }
            else {
                this.router.navigate(['/error/506']);
                return false;
            }
        }
        this.router.navigate(['/vertical/dashboard']);
        return false;
    };
    WriterGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WriterGuardService_Factory() { return new WriterGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService)); }, token: WriterGuardService, providedIn: "root" });
    return WriterGuardService;
}());
export { WriterGuardService };
