/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./badge.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./badge.component";
var styles_TCBadgeComponent = [i0.styles];
var RenderType_TCBadgeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCBadgeComponent, data: {} });
export { RenderType_TCBadgeComponent as RenderType_TCBadgeComponent };
export function View_TCBadgeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_TCBadgeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-badge", [], [[2, "tc-badge", null], [2, "badge-xs", null], [2, "badge-sm", null], [2, "badge-lg", null], [2, "default", null], [2, "accent-badge", null], [2, "success-badge", null], [2, "info-badge", null], [2, "warning-badge", null], [2, "gradient-badge", null], [2, "error-badge", null], [2, "badge-outline", null]], null, null, View_TCBadgeComponent_0, RenderType_TCBadgeComponent)), i1.ɵdid(1, 114688, null, 0, i2.TCBadgeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).xs; var currVal_2 = i1.ɵnov(_v, 1).sm; var currVal_3 = i1.ɵnov(_v, 1).lg; var currVal_4 = i1.ɵnov(_v, 1).typeDefault; var currVal_5 = i1.ɵnov(_v, 1).typeAccent; var currVal_6 = i1.ɵnov(_v, 1).typeSuccess; var currVal_7 = i1.ɵnov(_v, 1).typeInfo; var currVal_8 = i1.ɵnov(_v, 1).typeWarning; var currVal_9 = i1.ɵnov(_v, 1).typeGradient; var currVal_10 = i1.ɵnov(_v, 1).typeError; var currVal_11 = i1.ɵnov(_v, 1).outline; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }); }
var TCBadgeComponentNgFactory = i1.ɵccf("tc-badge", i2.TCBadgeComponent, View_TCBadgeComponent_Host_0, { outline: "outline", view: "view", size: "size" }, {}, ["*"]);
export { TCBadgeComponentNgFactory as TCBadgeComponentNgFactory };
