import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthenticationService} from './authentication.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {

            if (err.status === 401)  {
                // auto logout if 401 response returned from api
                this.authenticationService.logout();

            } else {
                if (err.status === 504) {
                    this.authenticationService.logout();
                } else {
                    if (err.status === 422) {
                        console.log('err', err);
                        let messageErreur = '' ;
                        if (err.error.message !== undefined) {
                            messageErreur = err.message;

                        } else {
                            for (const erreur of err.error._embedded.errors) {
                                messageErreur += erreur.message ;
                                messageErreur += '\n';
                            }
                        }

                        this.toastr.error('Erreur venant du serveur : ' + messageErreur , 'Erreur Système',
                            {timeOut: 2000});
                    }
                }
            }

            const error = ((err.error !== null ) ? err.error.message : '') || err.statusText;
            return throwError(error);
        }));
    }
}
