<ng-container *ngIf="commune && provinceTree ">
    <div class="col-sm-8">
        <tc-card [title]="''">
            <form [formGroup]="communeForm"   (ngSubmit)="onSubmit()">


                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.province}}</tc-form-label>
                            <nz-cascader [nzOptions]="provinceTree"
                                         [nzShowSearch]="true"
                                         [nzAutoFocus]="true"
                                         [nzSize]="'large'"
                                         formControlName="province"
                            >

                            </nz-cascader>


                        </tc-form-group>

                

                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                            <tc-input type="text"  formControlName="code" >
                            </tc-input>
                            <ng-container *ngIf="communeForm.get('code').invalid && (communeForm.get('code').touched || communeForm.get('code').dirty)">
                                <span @fadeIn class="error" *ngIf="communeForm.get('code').getError('required')">Veuillez saisir le code du marché</span>
                                <span @fadeIn class="error" *ngIf="communeForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                            </ng-container>
                        </tc-form-group>

                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.label}}</tc-form-label>
                            <tc-input type="text"  formControlName="nom">
                            </tc-input>
                            <ng-container *ngIf="communeForm.get('nom').invalid && (communeForm.get('nom').touched || communeForm.get('nom').dirty)">
                                <span @fadeIn class="error" *ngIf="communeForm.get('nom').getError('required')">Veuillez saisir le nom de la commune</span>
                                <span @fadeIn class="error" *ngIf="communeForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                            </ng-container>
                        </tc-form-group>


                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.longitude}}</tc-form-label>
                            <tc-input type="number"  formControlName="longitude">
                            </tc-input>
                        </tc-form-group>

                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.latitude}}</tc-form-label>
                            <tc-input type="number"  formControlName="latitude">
                            </tc-input>
                        </tc-form-group>



                        <tc-form-group>
                            <tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
                        </tc-form-group>

                        <span *ngIf="!communeForm.valid && communeForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                        <button [disabled]="!communeForm.valid" tc-button [block]="true" >
                            Valider
                        </button>




            </form>

        </tc-card>
    </div>
</ng-container>

