import { OnInit, ElementRef, AfterContentInit, EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { TCPickerComponent } from './picker/picker.component';
var TCColorPickerComponent = /** @class */ (function () {
    function TCColorPickerComponent(element) {
        this.element = element;
        this.onChange = function (color) { };
        this.onTouched = function () { };
        this.width = 305;
        this.opened = false;
        this.palette = true;
        this.overlay = false;
        this.buttons = false;
        this.alphaChanel = true;
        this.savedColor = '#06bebd';
        this.pickerClosed = new EventEmitter();
        this.pickerOpened = new EventEmitter();
        this.colorSelected = new EventEmitter();
        this.secondColorSelected = new EventEmitter();
        this.defaultPalette = ['#f44336', '#9c27b0', '#2196f3', '#4caf50', '#ffeb3b', '#ff9800', '#795548', '#607d8b'];
    }
    TCColorPickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var palette = [];
        if (this.paletteColors && this.paletteColors.length >= 8) {
            palette = this.paletteColors.splice(0, 8);
        }
        else if (this.paletteColors && this.paletteColors.length < 8) {
            palette = this.paletteColors.concat(this.defaultPalette.splice(0, 8 - this.paletteColors.length));
        }
        else {
            palette = this.defaultPalette;
        }
        if (this.picker) {
            this.picker.pickerOpened.subscribe(function () {
                _this.pickerOpened.emit(_this.SavedColor);
                _this.picker.setPosition(_this.getOffsets().top, _this.getOffsets().left);
            });
            this.picker.pickerClosed.subscribe(function () {
                _this.SavedColor = _this.picker.savedColor;
                _this.pickerClosed.emit(_this.SavedColor);
            });
            this.picker.colorSelected.subscribe(function (_a) {
                var color = _a.color, contrast = _a.contrast;
                _this.SavedColor = color;
                _this.contrastColor = contrast;
                _this.colorSelected.emit({ color: color, contrast: contrast });
            });
            if (this.opened) {
                this.picker.show();
            }
            this.picker.palette = palette;
            this.picker.opened = this.opened;
            this.picker.buttons = this.buttons;
            this.picker.overlay = this.overlay;
            this.picker.showPalette = this.palette;
            this.picker.savedColor = this.SavedColor;
            this.picker.alphaChanel = this.alphaChanel;
            this.contrastColor = this.picker.contrastColor;
            this.picker.width = this.width;
            this.picker.setPosition(this.getOffsets().top, this.getOffsets().left);
            this.picker.appendToBody();
        }
        if (this.gradient) {
            this.secondPicker.pickerClosed.subscribe(function () {
                _this.picker.setPosition(_this.getOffsets().top, _this.getOffsets().left + 13);
                _this.secondContrastColor = _this.secondPicker.contrastColor;
                _this.secondColor = _this.secondPicker.savedColor;
            });
            this.secondPicker.colorSelected.subscribe(function (_a) {
                var color = _a.color, contrast = _a.contrast;
                _this.secondColor = color;
                _this.secondColorSelected.emit({ color: color, contrast: contrast });
            });
            this.secondPicker.pickerOpened.subscribe(function () {
                _this.onResize();
            });
            this.secondPicker.gradient = true;
            this.secondPicker.palette = palette;
            this.secondPicker.buttons = this.buttons;
            this.secondPicker.overlay = this.overlay;
            this.secondPicker.showPalette = this.palette;
            this.secondPicker.savedColor = this.secondColor;
            this.secondPicker.alphaChanel = this.alphaChanel;
            this.secondContrastColor = this.secondPicker.contrastColor;
            this.secondPicker.width = this.width;
            this.secondPicker.setPosition(this.getOffsets().top, this.getOffsets().left);
            this.secondPicker.appendToBody();
        }
    };
    TCColorPickerComponent.prototype.ngAfterContentInit = function () {
        this.onResize();
    };
    TCColorPickerComponent.prototype.onResize = function () {
        this.picker.setPosition(this.getOffsets().top, this.getOffsets().left);
        if (this.secondPicker) {
            this.secondPicker.setPosition(this.getOffsets().top, this.getOffsets().left);
        }
    };
    Object.defineProperty(TCColorPickerComponent.prototype, "SavedColor", {
        get: function () {
            if (this.gradient) {
                return this.savedColor;
            }
            return this.savedColor;
        },
        set: function (color) {
            this.savedColor = color;
            this.onChange(color);
            this.writeValue(color);
        },
        enumerable: true,
        configurable: true
    });
    TCColorPickerComponent.prototype.writeValue = function (color) {
        this.savedColor = color;
    };
    TCColorPickerComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    TCColorPickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    TCColorPickerComponent.prototype.openPicker = function (event, gradient) {
        event.stopPropagation();
        if (!this.disabled) {
            if (gradient) {
                this.secondPicker.show();
            }
            else {
                this.picker.show();
            }
        }
    };
    TCColorPickerComponent.prototype.gradientStyles = function () {
        return {
            'left': '33px'
        };
    };
    TCColorPickerComponent.prototype.getCursorClass = function (second) {
        if (second) {
            return {
                'black': this.secondContrastColor !== '#ffffff'
            };
        }
        else {
            return {
                'black': this.contrastColor !== '#ffffff'
            };
        }
    };
    TCColorPickerComponent.prototype.getOffsets = function () {
        var rect = this.element.nativeElement.getBoundingClientRect(), scrollLeft = window.pageXOffset || document.documentElement.scrollLeft, scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };
    return TCColorPickerComponent;
}());
export { TCColorPickerComponent };
