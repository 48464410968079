import {Groupe} from './groupe';
import {Acteur} from './acteur';


export class SMSPushGroup {
    id: number;
    message: string;
    groupe: Groupe;
    constructor()  {
      this.groupe = new Groupe();
      this.message = '';
    }
}
