import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { finalize, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./authentication.service";
var BasicAuthHtppInterceptorService = /** @class */ (function () {
    function BasicAuthHtppInterceptorService(authenticationService) {
        this.authenticationService = authenticationService;
    }
    BasicAuthHtppInterceptorService.prototype.intercept = function (request, next) {
        // add authorization header with jwt token if available
        if (this.authenticationService !== undefined) {
            var currentUser = this.authenticationService.currentUserValue;
            if (currentUser && currentUser.access_token && (request.url.indexOf('/api/') >= 0)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: "Bearer " + currentUser.access_token,
                        mobilePhone: currentUser.username
                    }
                });
            }
        }
        var started = Date.now();
        return next.handle(request).pipe(map(function (response) {
            if (response instanceof HttpResponse) {
            }
            return response;
        }), 
        // Log when response observable either completes or errors
        finalize(function () {
            var elapsed = Date.now() - started;
            var msg = request.method + " \"" + request.urlWithParams + "\"  in " + elapsed + " ms.";
        }));
    };
    BasicAuthHtppInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BasicAuthHtppInterceptorService_Factory() { return new BasicAuthHtppInterceptorService(i0.ɵɵinject(i1.AuthenticationService)); }, token: BasicAuthHtppInterceptorService, providedIn: "root" });
    return BasicAuthHtppInterceptorService;
}());
export { BasicAuthHtppInterceptorService };
