var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { GroupeService } from '../groupe.service';
import { ToastrService } from 'ngx-toastr';
import { Groupe } from '../../models/groupe';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReseauService } from '../../reseau/reseau.service';
var PageCreateGroupeComponent = /** @class */ (function (_super) {
    __extends(PageCreateGroupeComponent, _super);
    function PageCreateGroupeComponent(store, httpSv, groupeService, route, router, toastr, reseauService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.groupeService = groupeService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.reseauService = reseauService;
        _this.fb = fb;
        _this.reseauxAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code Groupe',
            label: 'Libellé Groupe',
            reseau: 'Réseau',
            description: 'Description',
            parent: 'Groupe Parent (Optionnel)',
            actif: 'actif'
        };
        _this.groupe = new Groupe();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateGroupeComponent.getGroupe = function (jsonValue) {
        return Groupe.fromJSON(jsonValue);
    };
    PageCreateGroupeComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.setReactiveForm();
        this.pageData.title = this.getTitle();
    };
    PageCreateGroupeComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier un Groupe ' : ' Créer un Groupe ';
    };
    PageCreateGroupeComponent.prototype.isEditing = function () {
        return (this.groupe.id != null);
    };
    PageCreateGroupeComponent.prototype.initReactiveForm = function () {
        this.groupeForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            description: [''],
            reseau: ['', Validators.required],
            parent: [''],
            actif: [true],
            estFils: [false],
        });
    };
    PageCreateGroupeComponent.prototype.setReactiveForm = function () {
        if (this.groupe) {
            var groupe = PageCreateGroupeComponent.getGroupe(this.groupe);
            var reseau = (groupe.reseau != null) ? groupe.reseau.id.toString() : '';
            this.groupeForm.patchValue({
                code: this.groupe.code,
                nom: this.groupe.nom,
                description: this.groupe.description,
                actif: this.groupe.actif,
                parent: this.groupe.parent,
                reseau: reseau
            });
        }
    };
    PageCreateGroupeComponent.prototype.onReseauxPromise = function () {
        for (var _i = 0, _a = this.reseaux; _i < _a.length; _i++) {
            var reseau = _a[_i];
            this.reseauxAdapter.push({ 'value': reseau.id.toString(), 'label': reseau.nom });
        }
    };
    PageCreateGroupeComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getData(this.reseauService.getAllURL(), 'reseaux', 'onReseauxPromise');
        this.groupeForm.get('reseau').valueChanges.subscribe(function () {
            if ((_this.groupeForm.value.reseau !== null) && (_this.groupeForm.value.reseau !== '')) {
                _this.groupeService.findAsTreeByReseau(_this.groupeForm.value.reseau).subscribe(function (tree) {
                    _this.groupeTree = tree;
                });
            }
        });
        this.reseauService.findAsTree().subscribe(function (tree) {
            _this.treeReseau = tree;
        });
        this.groupeService.findAsTree().subscribe(function (tree) {
            _this.groupeTree = tree;
        });
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.groupeService.getURL(_this.selectedId), 'groupe', 'groupe', 'setLoaded');
            }
            else {
                _this.groupe = new Groupe();
                _this.initReactiveForm();
            }
        });
    };
    PageCreateGroupeComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateGroupeComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-groupe']);
    };
    PageCreateGroupeComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-groupe']);
    };
    PageCreateGroupeComponent.prototype.mapFormValuesToModel = function () {
        this.groupe.code = this.groupeForm.value.code;
        this.groupe.nom = this.groupeForm.value.nom;
        this.groupe.description = this.groupeForm.value.description;
        this.groupe.actif = this.groupeForm.value.actif;
        this.groupe.reseau = this.groupeForm.value.reseau;
        this.groupe.parent = this.groupeForm.value.parent;
        this.groupe.estFils = true;
    };
    PageCreateGroupeComponent.prototype.update = function () {
        var _this = this;
        if (this.groupeForm.valid) {
            this.mapFormValuesToModel();
            this.groupeService.update(this.groupe.id, this.groupe)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-groupe']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateGroupeComponent.prototype.create = function () {
        var _this = this;
        if (this.groupeForm.valid) {
            this.mapFormValuesToModel();
            this.groupeService.create(this.groupe)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie ', '', { timeOut: 2000 });
                _this.groupeForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateGroupeComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateGroupeComponent;
}(BasePageComponent));
export { PageCreateGroupeComponent };
