var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Produit } from '../../models/produit';
import { FormBuilder, Validators } from '@angular/forms';
import { ProduitService } from '../produit.service';
import { MesureService } from '../../mesure/mesure.service';
import { CategorieService } from '../../categorie/categorie.service';
import { EspeceService } from '../../espece/espece.service';
import { RaceService } from '../../race/race.service';
import { UserService } from '../../administrateur/user.service';
import { AuthenticationService } from '../../../../services/sharedServices/authentication.service';
import { Acteur } from '../../models/acteur';
var PageCreateProduitComponent = /** @class */ (function (_super) {
    __extends(PageCreateProduitComponent, _super);
    function PageCreateProduitComponent(store, httpSv, produitService, route, mesureService, router, toastr, especeService, categorieService, raceService, adminService, auth, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.produitService = produitService;
        _this.route = route;
        _this.mesureService = mesureService;
        _this.router = router;
        _this.toastr = toastr;
        _this.especeService = especeService;
        _this.categorieService = categorieService;
        _this.raceService = raceService;
        _this.adminService = adminService;
        _this.auth = auth;
        _this.fb = fb;
        _this.mesuresAdapter = [];
        _this.categoriesAdapter = [];
        _this.especesAdapter = [];
        _this.racesAdapter = [];
        _this.changes = false;
        _this.showBetailParams = false;
        _this.Columnslabels = {
            code: 'Code Produit',
            label: 'Libellé Produit',
            nomScientifique: 'Nom Scientifique',
            uniteMesure: 'Unité de Mesure',
            categorie: 'Catégorie ',
            espece: 'Espèce',
            race: 'Race'
        };
        _this.produit = new Produit();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateProduitComponent.getProduit = function (jsonValue) {
        return Produit.fromJSON(jsonValue);
    };
    PageCreateProduitComponent.getActeur = function (jsonValue) {
        return Acteur.fromJSON(jsonValue);
    };
    PageCreateProduitComponent.prototype.onMesuresPromise = function () {
        if (this.mesures) {
            this.mesures.sort(function (a, b) { return ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)); });
            for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
                var mesure = _a[_i];
                this.mesuresAdapter.push({ 'value': mesure.id.toString(), 'label': mesure.nom });
            }
        }
        this.setReactiveForm();
    };
    PageCreateProduitComponent.prototype.ngAfterViewInit = function () {
        if (this.auth.currentUserDataObject === null) {
            this.router.navigate(['/vertical/dashboard']);
        }
        else {
            var selectedId = this.auth.currentUserDataObject.id;
            this.getDataByName(this.adminService.getURL(selectedId), 'connectedUser', 'acteur', 'onConnectedPromise');
        }
    };
    PageCreateProduitComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.produit) {
            this.onCategoriesPromise();
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateProduitComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier un produit ' : ' Créer un produit ';
    };
    PageCreateProduitComponent.prototype.isEditing = function () {
        return (this.produit.id != null);
    };
    PageCreateProduitComponent.prototype.onCategoriesPromise = function () {
        if (this.categories) {
            this.categories.sort(function (a, b) { return ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)); });
            this.categoriesAdapter = [];
            for (var _i = 0, _a = this.categories; _i < _a.length; _i++) {
                var categorie = _a[_i];
                this.categoriesAdapter.push({ 'value': categorie.id.toString(), 'label': categorie.nom });
            }
        }
    };
    PageCreateProduitComponent.prototype.onEspecesPromise = function () {
        if (this.especes) {
            this.especes.sort(function (a, b) { return ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)); });
            this.especesAdapter = [];
            for (var _i = 0, _a = this.especes; _i < _a.length; _i++) {
                var espece = _a[_i];
                this.especesAdapter.push({ 'value': espece.id.toString(), 'label': espece.nom });
            }
        }
    };
    PageCreateProduitComponent.prototype.onRacesPromise = function () {
        if (this.races) {
            this.races.sort(function (a, b) { return ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)); });
            this.racesAdapter = [];
            for (var _i = 0, _a = this.races; _i < _a.length; _i++) {
                var race = _a[_i];
                this.racesAdapter.push({ 'value': race.id.toString(), 'label': race.nom });
            }
        }
    };
    PageCreateProduitComponent.prototype.onConnectedPromise = function () {
        if (this.connectedUser !== undefined) {
            var acteur = PageCreateProduitComponent.getActeur(this.connectedUser);
            this.showBetailParams = acteur.isSIMBetail || acteur.isDGPER;
        }
    };
    PageCreateProduitComponent.prototype.initReactiveForm = function () {
        this.produitForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            nomScientifique: [''],
            categorie: ['', Validators.required],
            mesure: [''],
            espece: [''],
            race: [''],
            actif: [true]
        });
    };
    PageCreateProduitComponent.prototype.setReactiveForm = function () {
        if (this.isEditing()) {
            // tslint:disable-next-line:max-line-length
            var categorieId = (this.produit.categorie != null) ? this.produit.categorie.id.toString() : '';
            var especeId = (this.produit.espece != null) ? this.produit.espece.id.toString() : '';
            var raceId = (this.produit.espece != null) ? this.produit.race.id.toString() : '';
            this.produitForm.patchValue({
                code: this.produit.code,
                nom: this.produit.nom,
                categorie: categorieId,
                espece: especeId,
                race: raceId,
                mesure: this.produit.mesure.id.toString(),
                nomScientifique: this.produit.nomScientifique,
                actif: this.produit.actif
            });
        }
    };
    PageCreateProduitComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.getData(this.categorieService.getAllURL(), 'categories', 'onCategoriesPromise');
        this.getData(this.especeService.getAllURL(), 'especes', 'onEspecesPromise');
        this.getData(this.raceService.getAllURL(), 'races', 'onRacesPromise');
        this.route.params.subscribe(function (params) {
            if (params['pageIndex']) {
                _this.pageIndex = params['pageIndex'];
            }
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.produitService.getURL(_this.selectedId), 'produit', 'produit', 'setLoaded');
            }
            else {
                _this.produit = new Produit();
                _this.initReactiveForm();
            }
        });
        // detect form changes
        this.produitForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
    };
    PageCreateProduitComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateProduitComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-produit']);
    };
    PageCreateProduitComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-produit', { pageIndex: this.pageIndex }]);
    };
    PageCreateProduitComponent.prototype.mapFormValuesToModel = function () {
        this.produit.code = this.produitForm.value.code;
        this.produit.nom = this.produitForm.value.nom;
        this.produit.categorie = this.produitForm.value.categorie;
        this.produit.mesure = this.produitForm.value.mesure;
        this.produit.nomScientifique = this.produitForm.value.nomScientifique;
        this.produit.varietes = this.produitForm.value.varietes;
        this.produit.especes = this.produitForm.value.especes;
        this.produit.actif = this.produitForm.value.actif;
    };
    PageCreateProduitComponent.prototype.update = function () {
        var _this = this;
        if (this.produitForm.valid) {
            this.mapFormValuesToModel();
            this.produitService.update(this.produit.id, this.produit)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-produit']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateProduitComponent.prototype.create = function () {
        var _this = this;
        if (this.produitForm.valid) {
            this.mapFormValuesToModel();
            this.produitService.create(this.produit)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie ', '', { timeOut: 2000 });
                _this.produitForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateProduitComponent.prototype.onSubmit = function () {
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateProduitComponent;
}(BasePageComponent));
export { PageCreateProduitComponent };
