var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { DepotService } from '../depot/depot.service';
import { Depot } from '../models/depot';
var PageDepotMapsComponent = /** @class */ (function (_super) {
    __extends(PageDepotMapsComponent, _super);
    function PageDepotMapsComponent(store, httpSv, depotService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.depotService = depotService;
        _this.depotsAdapter = [];
        _this.pageData = {
            title: 'Carte des dépôts',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Tableau de bord',
                    route: 'dashboard'
                }
            ],
            fullFilled: true
        };
        _this.lng = -2.9197048;
        _this.lat = 12.5324634;
        return _this;
    }
    PageDepotMapsComponent.prototype.getDepot = function (jsonValue) {
        return Depot.fromJSON(jsonValue);
    };
    PageDepotMapsComponent.prototype.onDepotsPromise = function () {
        for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
            var depot = _a[_i];
            if ((depot.longitude !== 0) && (depot.latitude !== 0)) {
                this.depotsAdapter.push({ id: depot.id.toString(), nom: depot.nom, longitude: depot.longitude,
                    latitude: depot.latitude });
            }
        }
    };
    PageDepotMapsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getData(this.depotService.getAllURL(), 'depots', 'onDepotsPromise');
    };
    PageDepotMapsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return PageDepotMapsComponent;
}(BasePageComponent));
export { PageDepotMapsComponent };
