/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./list-item.component";
var styles_TCListItemComponent = [i0.styles];
var RenderType_TCListItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCListItemComponent, data: {} });
export { RenderType_TCListItemComponent as RenderType_TCListItemComponent };
export function View_TCListItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "list-item-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "item-avatar-block"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "item-text-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "list-item-text"]], null, null, null, null, null)), i1.ɵncd(null, 1), i1.ɵncd(null, 2)], null, null); }
export function View_TCListItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-list-item", [], [[2, "tc-list-item", null]], null, null, View_TCListItemComponent_0, RenderType_TCListItemComponent)), i1.ɵdid(1, 4308992, null, 0, i2.TCListItemComponent, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
var TCListItemComponentNgFactory = i1.ɵccf("tc-list-item", i2.TCListItemComponent, View_TCListItemComponent_Host_0, {}, {}, ["[tc-list-avatar], [tcListAvatar], [tc-list-icon], [tcListIcon]", "[tc-list-line], [tcListLine]", "*"]);
export { TCListItemComponentNgFactory as TCListItemComponentNgFactory };
