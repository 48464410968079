<ul class="pagination-ul">
	<li class="pagination-li prev">
	 	<a class="pagination-link" (click)="prevPage()" [ngClass]="{ 'disabled': pageNum == 1 }" >
		 	<i class="icofont-simple-left"></i>
		</a>
	</li>

	<li class="pagination-li" *ngFor="let page of pages">
		<a class="pagination-link" (click)="changePage(page)" [ngClass]="{ 'active': pageNum == page }">
			{{ page }}
		</a> 
	</li>

	<li class="pagination-li next">
		<a class="pagination-link" (click)="nextPage()" [ngClass]="{ 'disabled': pageNum == getLast() }">
			<i class="icofont-simple-right"></i>
		</a>
	</li>
</ul>
