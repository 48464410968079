import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {AlerteStockService} from './alerteStock.service';
import {Alerte} from '../models/alerte';


export class AlerteStockDatasource implements DataSource<Alerte> {

    private alertesSubject = new BehaviorSubject<Alerte[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Alerte[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private alerteStockService: AlerteStockService) {

    }


    loadAll(filter: string, alerteType: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.alerteStockService.findAll(
         filter,
        alerteType,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(alertes => this.alertesSubject.next(alertes)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Alerte[]> {
        return this.alertesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.alertesSubject.complete();
        this.loadingSubject.complete();
    }

}

