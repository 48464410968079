import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Price} from './valider-prix.component';

@Injectable({
  providedIn: 'root'
})
export class ValiderPrixService {
	private baseUrl = '/api/prix';
  constructor(private http: HttpClient) {

  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllByReseau(reseauId: string): Observable<Price[]> {
    return this.http.get(`${this.baseUrl}/get_all_by_reseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId)
    }) as Observable<Price[]>;
  }
  getAllValidesByReseau(reseauId: string): Observable<Price[]> {
    return this.http.get(`${this.baseUrl}/get_all_valides_by_reseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId)
    }) as Observable<Price[]>;
  }
  // tslint:disable-next-line:max-line-length
  getPrixValides(dateDebut: string, dateFin: string, marches: any[], produits: any[], reseau: string, typePrix: string, mesures: any[]): Observable<Price[]> {
    return this.http.get(`${this.baseUrl}/valides`, {
      params: new HttpParams()
          .set('dateDebut', dateDebut)
          .set('dateFin', dateFin)
          .set('marches', marches.toString())
          .set('produits', produits.toString())
          .set('reseau', reseau)
          .set('typePrix', typePrix)
          .set('mesures', mesures.toString())
    }) as Observable<Price[]>;
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj);
  }
  valider(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/valider`, obj);
  }
  devalider(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/devalider`, obj);
  }
  rejeter(obj: Object): Observable<Object> {
    return this.http.put(`${this.baseUrl}/rejeter`, obj);
  }
  abandonner(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/abandonner`, obj);
  }
  update(id: number, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/modifier/${id}`, value);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

}

