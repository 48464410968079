var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { FormBuilder } from '@angular/forms';
import { jqxDataTableComponent } from 'jqwidgets-ng/jqxdatatable';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { UserService } from '../../ui/administrateur/user.service';
import { ReseauService } from '../../ui/reseau/reseau.service';
import { MesureService } from '../../ui/mesure/mesure.service';
import { ValiderStockService } from '../../ui/valider-stock/validerStock.service';
var PageRapportsStockComponent = /** @class */ (function (_super) {
    __extends(PageRapportsStockComponent, _super);
    function PageRapportsStockComponent(store, dashboardService, httpSv, validerStockService, reseauService, mesureService, adminService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dashboardService = dashboardService;
        _this.validerStockService = validerStockService;
        _this.reseauService = reseauService;
        _this.mesureService = mesureService;
        _this.adminService = adminService;
        _this.fb = fb;
        _this.produits = [];
        _this.depots = [];
        _this.mesures = [];
        _this.produitsAdapter = [];
        _this.depotsAdapter = [];
        _this.reseaux = [];
        _this.reseauxAdapter = [];
        _this.mesuresAdapter = [];
        _this.Columnslabels = {
            dateDebut: 'Date début',
            dateFin: 'Date Fin'
        };
        _this.source = { localData: []
        };
        _this.localizationObject = _this.getLocalization();
        _this.exportSettings = _this.getExportSettings();
        _this.columns = [
            { text: 'Réseau', dataField: 'reseau', width: '10%', cellsAlign: 'left', align: 'left' },
            { text: 'Dépot', dataField: 'nom_depot', width: '20%', cellsAlign: 'left', align: 'left' },
            { text: 'Produit', dataField: 'nom_produit', width: '30%', cellsAlign: 'left', align: 'left' },
            { text: 'Date', dataField: 'date', width: '12%', align: 'left', cellsAlign: 'left', cellsFormat: 'd' },
            // tslint:disable-next-line:max-line-length
            { text: 'Stock', dataField: 'stock', align: 'center', cellsAlign: 'center', cellsFormat: 'd', width: '18%' },
            { text: 'Mesure', dataField: 'mesure', align: 'center', cellsAlign: 'center', width: '10%' }
        ];
        _this.pageData = {
            title: 'RAPPORT SUR LES STOCKS',
            breadcrumbs: [],
        };
        return _this;
    }
    PageRapportsStockComponent.prototype.getExportSettings = function () {
        var exportObj = {};
        exportObj.columnsHeader = true;
        exportObj.hiddenColumns = false;
        exportObj.serverURL = null;
        exportObj.characterSet = null;
        exportObj.recordsInView = true;
        exportObj.fileName = 'rapport_stock';
        return exportObj;
    };
    PageRapportsStockComponent.prototype.getLocalization = function () {
        var localizationobj = {};
        localizationobj.pagerGoToPageString = 'Aller à la page:';
        localizationobj.pagerShowRowsString = 'Voir ligne:';
        localizationobj.pagerRangeString = ' de ';
        localizationobj.pagerNextButtonString = 'Suivant';
        localizationobj.pagerFirstButtonString = 'Premier';
        localizationobj.pagerLastButtonString = 'Dernier';
        localizationobj.pagerPreviousButtonString = 'Précedent';
        localizationobj.sortAscendingString = 'Ordre Ascendent';
        localizationobj.sortDescendingString = 'Ordre Descendant';
        localizationobj.sortRemoveString = 'Supprimer';
        localizationobj.emptydatastring = 'Pas de données';
        localizationobj.firstDay = 1;
        localizationobj.percentSymbol = '%';
        localizationobj.currencySymbol = '';
        localizationobj.currencySymbolPosition = 'après';
        localizationobj.decimalSeparator = ',';
        localizationobj.thousandsSeparator = ' ';
        localizationobj.filterapplystring = 'Appliquer';
        localizationobj.filtercancelstring = 'Annuler';
        localizationobj.filterclearstring = 'Effacer Filtre';
        localizationobj.filterstring = 'Avancé';
        localizationobj.filtersearchstring = 'Chercher';
        localizationobj.filterstringcomparisonoperators = ['vide', 'non vide', 'contient', 'contient(respecte la casse)',
            'Ne contient pas', 'Ne contient pas(respecte la casse)', 'commence par', 'commence par(respecte la casse)',
            'finit par', 'finit par(respecte la casse)', 'égale', 'égale(respecte la casse)', 'null', 'non null'];
        // tslint:disable-next-line:max-line-length
        localizationobj.filternumericcomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
        // tslint:disable-next-line:max-line-length
        localizationobj.filterdatecomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
        localizationobj.filterbooleancomparisonoperators = ['égale', 'non égale'];
        localizationobj.validationstring = 'La valeur entrée n\'est pas valide ';
        localizationobj.emptydatastring = 'Pas de données à afficher';
        localizationobj.filterselectstring = 'Selectionnez un filtre';
        localizationobj.loadtext = 'Chargement...';
        localizationobj.clearstring = 'Effacer';
        localizationobj.todaystring = 'Aujourd\'hui';
        // tslint:disable-next-line:max-line-length
        localizationobj.loadingerrormessage = 'Chargement en cours des données vous ne pouvez pas mettre à jour les propriétés ou appeler une méthode . Vous pourrez le faire après le chargement des données.';
        localizationobj.days = {
            // full day names
            names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            // abbreviated day names
            namesAbbr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            // shortest day names
            namesShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
        };
        localizationobj.patterns = {
            d: 'dd/MM/yyyy',
            D: 'dddd, d. MMMM yyyy',
            t: 'HH:mm',
            T: 'HH:mm:ss',
            f: 'dddd, d. MMMM yyyy HH:mm',
            F: 'dddd, d. MMMM yyyy HH:mm:ss',
            M: 'dd MMMM',
            Y: 'MMMM yyyy'
        };
        localizationobj.months = {
            // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
            names: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre', ''],
            // abbreviated month names
            namesAbbr: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
        };
        return localizationobj;
    };
    PageRapportsStockComponent.prototype.getWidth = function () {
        if (document.body.offsetWidth < 850) {
            return '95%';
        }
        return 850;
    };
    PageRapportsStockComponent.prototype.excelExport = function () {
        this.myDataTable.exportData('xls');
    };
    PageRapportsStockComponent.prototype.xmlExport = function () {
        this.myDataTable.exportData('xml');
    };
    PageRapportsStockComponent.prototype.csvExport = function () {
        this.myDataTable.exportData('csv');
    };
    PageRapportsStockComponent.prototype.tsvExport = function () {
        this.myDataTable.exportData('tsv');
    };
    PageRapportsStockComponent.prototype.htmlExport = function () {
        this.myDataTable.exportData('html');
    };
    PageRapportsStockComponent.prototype.jsonExport = function () {
        this.myDataTable.exportData('json');
    };
    PageRapportsStockComponent.prototype.pdfExport = function () {
        this.myDataTable.exportData('pdf');
    };
    PageRapportsStockComponent.prototype.printBtnOnClick = function () {
        var gridContent = this.myDataTable.exportData('html');
        var newWindow = window.open('', '', 'width=800, height=500'), document = newWindow.document.open(), pageContent = '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>ETAT DES PRIX</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
        document.write(pageContent);
        document.close();
        newWindow.print();
    };
    PageRapportsStockComponent.prototype.onReseauxPromise = function () {
        this.reseauxAdapter = [];
        for (var _i = 0, _a = this.reseaux; _i < _a.length; _i++) {
            var reseau = _a[_i];
            this.reseauxAdapter.push({ 'value': reseau.id.toString(), 'label': reseau.code });
        }
    };
    PageRapportsStockComponent.prototype.onMesuresPromise = function () {
        this.mesuresAdapter = [];
        for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
            var mesure = _a[_i];
            this.mesuresAdapter.push({ 'value': mesure.id.toString(), 'label': mesure.code });
        }
    };
    PageRapportsStockComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.adminService.mesProduits().subscribe(function (produits) {
            _this.produits = produits;
            _this.produitsAdapter = [];
            for (var _i = 0, _a = _this.produits; _i < _a.length; _i++) {
                var produit = _a[_i];
                _this.produitsAdapter.push({ 'value': produit.id.toString(), 'label': produit.nom });
            }
        });
        this.reseauService.getCached().subscribe(function (data) {
            _this.reseaux = data;
            _this.onReseauxPromise();
        });
        this.mesureService.getCached().subscribe(function (data) {
            _this.mesures = data;
            _this.onMesuresPromise();
        });
        this.adminService.mesMarchesPreferes().subscribe(function (depots) {
            _this.depots = depots;
            _this.depotsAdapter = [];
            for (var _i = 0, _a = _this.depots; _i < _a.length; _i++) {
                var depot = _a[_i];
                _this.depotsAdapter.push({ 'value': depot.id.toString(), 'label': depot.nom });
            }
        });
        this.initReactiveForm();
        this.setLoaded();
    };
    PageRapportsStockComponent.prototype.initReactiveForm = function () {
        this.rapportsForm = this.fb.group({
            produits: [''],
            depots: [''],
            mesures: [''],
            reseau: '',
            typePrix: ''
        });
    };
    PageRapportsStockComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dateDebut.culture('fr-FR');
        this.dateFin.culture('fr-FR');
        var pdateDebut = new Date();
        pdateDebut.setFullYear(pdateDebut.getFullYear(), 0, 1);
        var pdateFin = new Date();
        pdateFin.setFullYear(pdateFin.getFullYear(), pdateFin.getMonth(), pdateFin.getDate() - 1);
        this.dateDebut.setDate(pdateDebut);
        this.dateFin.setDate(pdateFin);
        this.dateFin.onValueChanged.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerStockService.getStocksValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('depots').value, _this.rapportsForm.get('produits').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('mesures').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.dateDebut.onValueChanged.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerStockService.getStocksValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('depots').value, _this.rapportsForm.get('produits').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('mesures').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('reseau').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerStockService.getStocksValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('depots').value, 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('mesures').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('depots').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerStockService.getStocksValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('depots').value, 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('mesures').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('produits').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerStockService.getStocksValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('depots').value, 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('mesures').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('mesures').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerStockService.getStocksValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('depots').value, 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('mesures').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('typePrix').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerStockService.getStocksValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('depots').value, 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('mesures').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(), this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value, this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value, this.rapportsForm.get('mesures').value).subscribe(function (datas) {
            _this.source.localData = datas;
            _this.dataAdapter = new jqx.dataAdapter(_this.source);
        });
    };
    PageRapportsStockComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return PageRapportsStockComponent;
}(BasePageComponent));
export { PageRapportsStockComponent };
