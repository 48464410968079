/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./partnersLogo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./partnersLogo.component";
var styles_PartnersLogoComponent = [i0.styles];
var RenderType_PartnersLogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PartnersLogoComponent, data: {} });
export { RenderType_PartnersLogoComponent as RenderType_PartnersLogoComponent };
export function View_PartnersLogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["target", "_blank"]], [[8, "title", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { target: [0, "target"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(2, 1), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "partnersLogo"]], [[8, "src", 4], [8, "alt", 0], [8, "width", 0], [8, "height", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "_blank"; var currVal_4 = _ck(_v, 2, 0, _co.page); _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.title, ""); var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = i1.ɵinlineInterpolate(1, "", _co.src, ""); var currVal_6 = i1.ɵinlineInterpolate(1, "", _co.alt, ""); var currVal_7 = i1.ɵinlineInterpolate(1, "", _co.width, ""); var currVal_8 = i1.ɵinlineInterpolate(1, "", _co.height, ""); _ck(_v, 3, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_PartnersLogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "partners-logo", [], null, null, null, View_PartnersLogoComponent_0, RenderType_PartnersLogoComponent)), i1.ɵdid(1, 114688, null, 0, i4.PartnersLogoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartnersLogoComponentNgFactory = i1.ɵccf("partners-logo", i4.PartnersLogoComponent, View_PartnersLogoComponent_Host_0, { src: "src", width: "width", height: "height", alt: "alt", page: "page", title: "title" }, {}, []);
export { PartnersLogoComponentNgFactory as PartnersLogoComponentNgFactory };
