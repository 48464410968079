import {Groupe} from './groupe';
import {Acteur} from './acteur';


export class SMSPush {
    id: number;
    message: string;
    groupes: Groupe[];
    destinataires: Acteur[];
    constructor()  {
      this.groupes = [];
      this.destinataires = [];
      this.message = '';
    }
}
