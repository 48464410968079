import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UserService} from '../../../pages/ui/administrateur/user.service';
import {fadeIn} from '../../../animations/form-error';
import {GenreService} from '../../../services/sharedServices/genre.service';
import {ReseauService} from '../../../pages/ui/reseau/reseau.service';
import {Acteur} from '../../../pages/ui/models/acteur';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';

export interface IchangePassword {
    status: string;
}

@Component({
    selector: 'tc-create-compte',
    templateUrl: './creer_compte.component.html',
    styleUrls: ['./creer_compte.component.scss'],
    animations: [fadeIn]
})
export class PageCreateCompteComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    SERVER_URL = 'api/upload_avatar';
    constructor(store: Store<IAppState>,
                httpSv: HttpService,
                private route: ActivatedRoute,
                public router: Router, private toastr: ToastrService,
                private genreService: GenreService,
                private i18n: NzI18nService,
                private reseauService: ReseauService,
                private fb: FormBuilder,
                private httpClient: HttpClient,
                private adminService: UserService,
                private loginservice: AuthenticationService

    ) {
        super(store, httpSv);
        this.administrateur = {};
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);



        this.initReactiveForm();

    }
    currentAvatar: string | ArrayBuffer;
    defaultAvatar: string;
    userForm: FormGroup;
    userType: string;
    administrateur: any;
    genres: IOption[] = [];
    reseaux: any[];
    reseauxAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            nom: 'Nom',
            prenom: 'Prénom (s)',
            mobilePhone: 'N° Mobile',
            genre: 'Sexe',
            email: 'Email',
            reseau: 'Réseau',
            enabled: 'Actif'
        };

    getTitle(): string {
       return 'Compte : ' + this.getNomComplet();

    }
    getNomComplet(): string {
        return  this.administrateur.nom + ' ' + this.administrateur.prenom;

    }

    onGenresPromise() {
      console.log(this.genres);
    }
    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.pageData.title =  this.getTitle();
         this.setReactiveForm();
    }

    initReactiveForm() {
        this.userForm = this.fb.group({
                mobilePhone: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                prenom: ['', [Validators.required, Validators.minLength(2)]],
                email: ['', [Validators.required, Validators.email]],
                genre: ['', Validators.required],
                reseau: ['', Validators.required],
                enabled: [true]
            }
        );

    }
    setReactiveForm() {
        if (this.administrateur) {
            this.userForm.patchValue({
                    mobilePhone: this.administrateur.mobilePhone,
                    nom: this.administrateur.nom,
                    prenom: this.administrateur.prenom,
                    email: this.administrateur.email,
                    enabled: this.administrateur.enabled,
                    genre: this.administrateur.genre,
                    reseau: (this.administrateur.reseau != null) ? this.administrateur.reseau.id.toString() : ''
                 }
            );

        }

    }

    onReseauxPromise() {
        for (const reseau of this.reseaux) {
            this.reseauxAdapter.push({'value' : reseau.id.toString(), 'label' : reseau.code});
        }

    }

    ngOnInit() {
        super.ngOnInit();
        this.userType = 'utilisateur';
        this.defaultAvatar = 'assets/content/anonymous-400.jpg';
        this.currentAvatar = this.defaultAvatar;
        this.administrateur = new Acteur();
        this.loginservice.authenticate('+22699999999', 'Anonyme*2019').subscribe(
            data => {
                sessionStorage.setItem('currentUser', JSON.stringify(data));
                this.loginservice.setCurrentObject(data);
                this.getData(this.genreService.getAllURL(), 'genres', 'onGenresPromise');
                this.getData(this.reseauService.getAllURL(), 'reseaux', 'onReseauxPromise');

                // sessionStorage.removeItem('currentUser');
                // this.adminService.findMe().subscribe(
                //     userData => {
                //         this.loginservice.setCurrentUserDataObject(userData);
                //         sessionStorage.setItem('currentUserData', JSON.stringify(userData));
                //     }
                // );

            },
            error => {
                let errorText: string;
                console.log('error', error);
                errorText = error;
                if ((errorText.toUpperCase().indexOf('TIMEOUT') >= 0) || (errorText.toUpperCase().indexOf('GATEWAY') >= 0)) {
                    errorText = 'Vérifiez que le serveur rest a démarré ';
                }
                this.toastr.error(errorText);
            }
        );

    }
    ngAfterViewInit() {


    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-administrateur']);
    }
    liste() {
        this.router.navigate(['/vertical/list-administrateur']);
    }
    mapFormValuesToModel() {
        this.administrateur.mobilePhone =  this.userForm.value.mobilePhone;
        this.administrateur.nom =  this.userForm.value.nom;
        this.administrateur.prenom =  this.userForm.value.prenom;
        this.administrateur.email =  this.userForm.value.email;
        this.administrateur.reseau =  this.userForm.value.reseau;
        this.administrateur.enabled =  this.userForm.value.enabled;
        this.administrateur.genre = this.userForm.value.genre;
    }
    create(): void {
        this.mapFormValuesToModel();
        this.adminService.create(this.userType, this.administrateur)
            .subscribe(
                data => {
                    this.toastr.success('Création réussie de ' + this.administrateur.toString(), '',
                        {timeOut: 2000});
                    this.router.navigate(['/public/sign-in']);
                   // this.userForm.reset();

                }
                ,
                error => console.log(error));

    }
    onSubmit() {
        this.submitted = true;
        this.create();
    }
    // upload new file
    onFileChanged(inputValue: any) {
        const file: File = inputValue.target.files[0];
        const reader: FileReader = new FileReader();

        reader.onloadend = () => {
            this.currentAvatar = reader.result;
            this.changes = true;
        };

        reader.readAsDataURL(file);

        const formData = new FormData();

        formData.append('file', file);
        formData.append('avatar_id', 'avatar_' + this.administrateur.id);
        console.log('formData', formData.get('file'));
        const headers = new HttpHeaders({ 'content-type': 'multipart/form-data '});

        this.httpClient.post<any>(this.SERVER_URL, formData).subscribe(
            (res) => {console.log(res); this.toastr.success('Succès de l\'\'importation ');
            },
            (err) => {
                console.log(err); this.toastr.error('Echec de l\'\'importation cause ' + err.toString());
            }
        );
    }

}
