var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { ValiderOffreAchatService } from '../../ui/valider-offre-achat/validerOffreAchat.service';
import { ValiderOffreVenteService } from '../../ui/valider-offre-vente/validerOffreVente.service';
import { ExcelService } from '../../../services/sharedServices/excel-service.service';
import { DashboardService } from './dashboard.service';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { Acteur } from '../../ui/models/acteur';
var PageDashboardComponent = /** @class */ (function (_super) {
    __extends(PageDashboardComponent, _super);
    function PageDashboardComponent(store, httpSv, offreAchatValidesService, offreVenteValidesService, dashboardService, authService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.offreAchatValidesService = offreAchatValidesService;
        _this.offreVenteValidesService = offreVenteValidesService;
        _this.dashboardService = dashboardService;
        _this.authService = authService;
        _this.chartsReady = false;
        _this.pageData = {
            title: 'Tableau de bord',
            footerLoaded: true,
            breadcrumbs: [],
        };
        _this.pieStyle = {
            normal: {
                label: {
                    color: '#000',
                    position: 'inner'
                },
                labelLine: {
                    show: false
                }
            }
        };
        return _this;
    }
    PageDashboardComponent.prototype.setCgOptions = function (datas) {
        this.cgOptions = {
            grid: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br>{c} ({d}%)'
            },
            series: [{
                    name: 'pie',
                    type: 'pie',
                    selectedMode: 'single',
                    selectedOffset: 30,
                    clockwise: true,
                    radius: [0, '90%'],
                    data: datas,
                    itemStyle: this.pieStyle
                }]
        };
    };
    PageDashboardComponent.prototype.setDOptions = function (datas) {
        this.dOptions = {
            grid: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            },
            tooltip: {
                trigger: 'item',
                formatter: '{b}<br>{c} ({d}%)'
            },
            series: [{
                    name: 'pie',
                    type: 'pie',
                    selectedMode: 'single',
                    selectedOffset: 30,
                    clockwise: true,
                    radius: [0, '90%'],
                    data: datas,
                    itemStyle: this.pieStyle
                }]
        };
    };
    PageDashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.connectedUser = this.authService.currentUserDataObject;
        this.setLoaded();
        this.offreAchatValidesService.getTopN('5').subscribe(function (datas) {
            _this.offresAchatsTop10 = datas;
        });
        this.offreVenteValidesService.getTopN('5').subscribe(function (datas) {
            _this.offresVentesTop10 = datas;
        });
        this.dashboardService.getStatistiques().subscribe(function (datas) {
            _this.statistiques = datas;
            _this.setCgOptions([
                {
                    value: datas.femmes,
                    name: 'Femmes',
                    itemStyle: {
                        normal: {
                            color: 'rgba(228,132,5,0.45)',
                            borderColor: '#e15200'
                        }
                    }
                },
                {
                    value: datas.hommes,
                    name: 'Hommes',
                    itemStyle: {
                        normal: {
                            color: '#d5edff',
                            borderColor: '#2fa7ff'
                        }
                    }
                }
            ]);
            var donnees = [];
            var color = 'rgba(5,172,76,0.4)';
            var borderColor = '#058d41';
            datas.totalParReseau.forEach(function (item, index) {
                if (index === 1) {
                    color = '#fee8d7';
                    borderColor = '#e15200';
                }
                else if (index === 2) {
                    color = '#e6deff';
                    borderColor = '#805aff';
                }
                else if (index === 3) {
                    color = '#d5edff';
                    borderColor = '#2fa7ff';
                }
                donnees.push({ value: item.Nbre, name: item.reseau, itemStyle: { normal: { color: color, borderColor: borderColor } } });
            });
            _this.setDOptions(donnees);
            _this.chartsReady = true;
        });
        this.setPieOptions();
    };
    PageDashboardComponent.prototype.exportOffresVente = function () {
        ExcelService.exportAsExcelFile(this.offresVentesTop10, 'offresVente');
    };
    PageDashboardComponent.prototype.exportOffresAchat = function () {
        ExcelService.exportAsExcelFile(this.offresAchatsTop10, 'offresAchat');
    };
    PageDashboardComponent.prototype.isGroupeAdmin = function () {
        return Acteur.fromJSON(this.connectedUser).isGroupeAdmin;
    };
    PageDashboardComponent.prototype.connectedName = function () {
        return this.connectedUser.nomComplet;
    };
    PageDashboardComponent.prototype.connectedLogin = function () {
        return this.connectedUser.mobilePhone;
    };
    PageDashboardComponent.prototype.connectedRole = function () {
        return this.connectedUser.role;
    };
    PageDashboardComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageDashboardComponent.prototype.setPieOptions = function () {
        this.pieOptions = {
            color: ['#f741b5', '#2fa7ff', '#7cdb86'],
            series: [
                {
                    type: 'pie',
                    radius: ['90%', '88%'],
                    label: {
                        show: false,
                    },
                    data: [
                        { value: 250 },
                        { value: 500 },
                        { value: 250 }
                    ],
                    markPoint: {
                        data: [
                            {
                                x: '50%',
                                y: '10px',
                                symbol: 'emptyCircle',
                                symbolSize: 15,
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(247,65,181,0.1)',
                                        borderWidth: 5
                                    }
                                }
                            },
                            {
                                x: '50%',
                                y: '10px',
                                symbol: 'circle',
                                symbolSize: 5,
                                itemStyle: {
                                    normal: {
                                        color: '#F741B5'
                                    }
                                }
                            },
                            {
                                x: '170px',
                                y: '50%',
                                symbol: 'emptyCircle',
                                symbolSize: 15,
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(47,167,255,0.1)',
                                        borderWidth: 5
                                    }
                                }
                            },
                            {
                                x: '170px',
                                y: '50%',
                                symbol: 'circle',
                                symbolSize: 5,
                                itemStyle: {
                                    normal: {
                                        color: '#2fa7ff'
                                    }
                                }
                            },
                            {
                                x: '10px',
                                y: '50%',
                                symbol: 'emptyCircle',
                                symbolSize: 15,
                                itemStyle: {
                                    normal: {
                                        color: 'rgba(124,219,134,0.1)',
                                        borderWidth: 5
                                    }
                                }
                            },
                            {
                                x: '10px',
                                y: '50%',
                                symbol: 'circle',
                                symbolSize: 5,
                                itemStyle: {
                                    normal: {
                                        color: '#7cdb86'
                                    }
                                }
                            }
                        ]
                    }
                }
            ]
        };
    };
    return PageDashboardComponent;
}(BasePageComponent));
export { PageDashboardComponent };
