var TCFontSizeDirective = /** @class */ (function () {
    function TCFontSizeDirective() {
    }
    Object.defineProperty(TCFontSizeDirective.prototype, "getFontSize", {
        get: function () {
            var SIZE = this.tcFontSize;
            return (typeof SIZE === 'number') ? SIZE + 'px' : SIZE;
        },
        enumerable: true,
        configurable: true
    });
    return TCFontSizeDirective;
}());
export { TCFontSizeDirective };
