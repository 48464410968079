import { MenuFoldOutline, MenuUnfoldOutline, FormOutline, DashboardOutline } from '@ant-design/icons-angular/icons';
var icons = [MenuFoldOutline, MenuUnfoldOutline, DashboardOutline, FormOutline];
var ɵ0 = icons;
var IconsProviderModule = /** @class */ (function () {
    function IconsProviderModule() {
    }
    return IconsProviderModule;
}());
export { IconsProviderModule };
export { ɵ0 };
