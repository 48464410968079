<ng-container *ngIf="connectedUser && produit && categoriesAdapter.length>0 && mesuresAdapter.length>0 ">
<div class="col-sm-8">
    <form [formGroup]="produitForm"   (ngSubmit)="onSubmit()">

 <tc-card [title]="''">
                      <tc-form-group>
                        <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                        <tc-input type="text"  formControlName="code" >
                        </tc-input>
                        <ng-container *ngIf="produitForm.get('code').invalid && (produitForm.get('code').touched || produitForm.get('code').dirty)">
                            <span @fadeIn class="error" *ngIf="produitForm.get('code').getError('required')">Veuillez saisir le code du dépôt</span>
                            <span @fadeIn class="error" *ngIf="produitForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                        </ng-container>
                    </tc-form-group>
                <tc-form-group>
                     <tc-form-label>{{Columnslabels.label}}</tc-form-label>
                    <tc-input type="text"  formControlName="nom">
                    </tc-input>
                    <ng-container *ngIf="produitForm.get('nom').invalid && (produitForm.get('nom').touched || produitForm.get('nom').dirty)">
                        <span @fadeIn class="error" *ngIf="produitForm.get('nom').getError('required')">Veuillez saisir le nom du dépôt</span>
                        <span @fadeIn class="error" *ngIf="produitForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                    </ng-container>
                </tc-form-group>
        <tc-form-group>
            <tc-form-label>{{Columnslabels.nomScientifique}}</tc-form-label>
            <tc-input type="text" formControlName="nomScientifique" >
            </tc-input>
        </tc-form-group>
        <tc-form-group>
            <tc-form-label>{{Columnslabels.uniteMesure}}</tc-form-label>
            <tc-select [options]="mesuresAdapter"  formControlName="mesure"  ></tc-select>
       </tc-form-group>
             <tc-form-group>
                 <tc-form-label>{{Columnslabels.categorie}}</tc-form-label>
                 <tc-select
                         [options]="categoriesAdapter"
                         formControlName="categorie"
                 ></tc-select>
             </tc-form-group>
     <ng-container *ngIf=" showBetailParams && especesAdapter.length>0 &&  racesAdapter.length>0 ">
         <tc-form-group>
             <tc-form-label>{{Columnslabels.race}}</tc-form-label>
             <tc-select
                     [options]="racesAdapter"
                     formControlName="race"
             ></tc-select>
         </tc-form-group>
         <tc-form-group>
             <tc-form-label>{{Columnslabels.espece}}</tc-form-label>
             <tc-select
                     [options]="especesAdapter"
                     formControlName="espece"
             ></tc-select>
         </tc-form-group>
     </ng-container>


                    <tc-form-group>
                        <tc-checkbox formControlName="actif" >Actif</tc-checkbox>
                    </tc-form-group>

             <span *ngIf="!produitForm.valid && produitForm.touched" class="help-block">Veuillez entrer des données valides !</span>
             <button [disabled]="!produitForm.valid" tc-button [block]="true" >
                 Valider
             </button>
     </tc-card>

 </form>
</div>
</ng-container>

