import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ModifierStockService = /** @class */ (function () {
    function ModifierStockService(http) {
        this.http = http;
        this.baseUrl = '/api/stock';
    }
    ModifierStockService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    ModifierStockService.prototype.getAllByDepotAndDate = function (depotId, dateSaisie) {
        return this.http.get(this.baseUrl + "/get_all_by_depot_date", {
            params: new HttpParams()
                .set('depotId', depotId)
                .set('dateSaisie', dateSaisie)
        });
    };
    ModifierStockService.prototype.getAllByReseauAndDate = function (reseau, dateSaisie) {
        return this.http.get(this.baseUrl + "/get_all_by_reseau_date", {
            params: new HttpParams()
                .set('reseau', reseau)
                .set('dateSaisie', dateSaisie)
        });
    };
    ModifierStockService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ModifierStockService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    ModifierStockService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    ModifierStockService.prototype.update = function (value) {
        return this.http.put(this.baseUrl + "/modifier", value);
    };
    ModifierStockService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    ModifierStockService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ModifierStockService_Factory() { return new ModifierStockService(i0.ɵɵinject(i1.HttpClient)); }, token: ModifierStockService, providedIn: "root" });
    return ModifierStockService;
}());
export { ModifierStockService };
