import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {Alerte} from '../models/alerte';

@Injectable({
  providedIn: 'root'
})
export class AlertePrixService {
	private baseUrl = '/api/alerte';
  constructor(private http: HttpClient) {

  }
  findAll(filter = '', alerteType = '',  sortField = 'libelle', sortOrder = 'asc',
          offset = 0, pageSize = 10): Observable<Alerte[]> {


    return this.http.get(`${this.baseUrl}/pages`, {
      params: new HttpParams()
          .set('alerteType', alerteType.toUpperCase())
          .set('filter', filter)
          .set('sort', sortField)
          .set('sortOrder', sortOrder)
          .set('offset', offset.toString())
          .set('max', pageSize.toString())
    }) as Observable<Alerte[]>;
  }
  findCount(alerteType: string): Observable<number> {
    return this.http.get(`${this.baseUrl}/count`, {
      params: new HttpParams()
          .set('alerteType', alerteType.toUpperCase())
    })
        .pipe(
            map(res => res['total'])
        );
  }


  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getAllByReseauURL(id: number): string {
    return `${this.baseUrl}/par_reseau/${id}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(alerteType: string, obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj, {
      params: new HttpParams()
          .set('alerteType', alerteType.toUpperCase())

    });
  }
  update( id: number, alerteType: string, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/modifier/${id}`, value, {
      params: new HttpParams()
          .set('alerteType', alerteType.toUpperCase())

    });
  }
  delete( id: number): Observable<Object> {
    return this.http.post(`${this.baseUrl}/supprimer`, 'delete', {
      params: new HttpParams()
          .set('id', id.toString())
    });
  }


}

