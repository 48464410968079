import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import { Store } from '@ngrx/store';
import { EChartOption } from 'echarts';
import { BasePageComponent } from '../../base-page';
import { IAppState } from '../../../interfaces/app-state';
import { HttpService } from '../../../services/http/http.service';
import {UserService} from '../../ui/administrateur/user.service';
import {IOption} from '../../../ui/interfaces/option';
import {DashboardService} from '../dashboard/dashboard.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RegionService} from '../../ui/region/region.service';
import {ChartType} from 'chart.js';
import {MesureService} from '../../ui/mesure/mesure.service';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class PageAnalyticsComponent extends BasePageComponent implements OnInit, OnDestroy,  AfterViewInit {
  statistiques: any;
  dOptionsOA: EChartOption;
  dOptionsOV: EChartOption;
  titrecartePrix = 'Evolution du prix';
  titreBarPrix  = 'Comparaison des prix par marché';
  titreBarPrixRegion = 'Comparaison des prix par région';
  titreOffresVente = '';
  titreOffresAchat = '';
  barChartOptions: any;
  volumeChartsReady: boolean;
  barChartLabels: string[];
  barChartType: ChartType;
  barChartLegend: boolean;
  chartsReady: boolean;
  barChartsReady: boolean;
  barChartData: any[];
  barChartLabelsRegion: string[];
  barChartDataRegion: any[];
  barChartColorsRegion: any;
  barChartTypeRegion: ChartType;
  barChartLegendRegion: boolean;
  barChartsRegionReady: boolean;
  barChartColors: any;
  analyticForm: FormGroup;
  constructor(
    store: Store<IAppState>, private dashboardService: DashboardService,
    httpSv: HttpService, private adminService: UserService,
    private regionService: RegionService, private mesureService: MesureService,
    private fb: FormBuilder
  ) {
    super(store, httpSv);

    this.pageData = {
      title: 'Analyses',
      breadcrumbs: [
        {
          title: 'Tableau de bord',
          route: 'dashboard'
        },
        {
          title: 'Analyses'
        }
      ]
    };
    this.table = [];
    this.pieStyle = {
      normal: {
        label: {
          color: '#000',
          position: 'inner'
        },
        labelLine: {
          show: false
        }
      }
    };
  }
  hsOptions: EChartOption;
  table: any[];
    @ViewChild('dateDebut', { static: false }) dateDebut: jqxDateTimeInputComponent;
    Columnslabels =
        {
            dateDebut: 'Date Initialisation',
            periode: 'Période'
        };
  produits: any[] = [];
  marches: any[] = [];
  mesures: any[] = [];
  regions: any[] = [];
  pieStyle: any;
  dataAdapter: any;
  produitsAdapter: IOption[] = [];
  marchesAdapter: IOption[] = [];
  regionsAdapter: IOption[] = [];
  mesuresAdapter: IOption[] = [];

  setDOptionsOffreAchat(datas) {
    this.dOptionsOA = {
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      tooltip : {
        trigger: 'item',
        formatter: '{b}<br>{c} ({d}%)'
      },
      series: [{
        name: 'pie',
        type: 'pie',
        selectedMode: 'single',
        selectedOffset: 30,
        clockwise: true,
        radius: [0, '90%'],
        data: datas,
        itemStyle: this.pieStyle
      }]
    };
  }
  setDOptionsOffreVente(datas) {
    this.dOptionsOV = {
      grid: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      },
      tooltip : {
        trigger: 'item',
        formatter: '{b}<br>{c} ({d}%)'
      },
      series: [{
        name: 'pie',
        type: 'pie',
        selectedMode: 'single',
        selectedOffset: 30,
        clockwise: true,
        radius: [0, '90%'],
        data: datas,
        itemStyle: this.pieStyle
      }]
    };
  }
  ngOnInit() {
    super.ngOnInit();
    this.initReactiveForm();
    this.adminService.mesProduits().subscribe(produits => {
      this.produits = produits ;
      this.produitsAdapter = [];
      for (const produit of this.produits) {
        this.produitsAdapter.push({'value' : produit.id.toString(), 'label' : produit.nom});
      }
      this.analyticForm.patchValue({
        produit: this.produits[0].id.toString(),
        produitComp: this.produits[0].id.toString(),
        produitCompRegion: this.produits[0].id.toString(),
        produitOffreRegion: this.produits[0].id.toString()
      });
      this.setLoaded();
    });
    this.adminService.mesMarchesPreferes().subscribe(marches => {
      this.marches = marches ;
      this.marchesAdapter = [];
      for (const marche of this.marches) {
        this.marchesAdapter.push({'value' : marche.id.toString(), 'label' : marche.nom});
      }
      this.analyticForm.patchValue({
        marches: [this.marches[0] ? this.marches[0].id.toString() : '' ],
        marche: this.marches[0] ? this.marches[0].id.toString() : ''
      });

      this.setLoaded();
    });
    this.getData(this.regionService.getAllURL(), 'regions', 'onRegionsPromise');
    this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
    this.analyticForm.get('produit').valueChanges.subscribe(
        (selectedValue) => {
          const produit = this.produits.find(obj => obj.id.toString() === selectedValue);
          const marche = this.marches.find(obj => obj.id.toString() === this.analyticForm.get('marche').value);
          this.titrecartePrix = 'Evolution des prix de ' + produit.nom;
          if (marche !== undefined) {
            this.titrecartePrix += ' sur le marché ' + marche.nom;
          }
          this.dashboardService.getCartePrix(selectedValue, this.analyticForm.get('marche').value, this.analyticForm.get('mesure').value
          , this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                this.statistiques = datas;
                this.setHSOptions(datas);
                this.chartsReady = true;
              }
          );
        });
    this.analyticForm.get('marche').valueChanges.subscribe(
        (selectedValue) => {
          const marche = this.marches.find(obj => obj.id.toString() === selectedValue);
          const produit = this.produits.find(obj => obj.id.toString() === this.analyticForm.get('produit').value);
          if (produit !== undefined) {
            this.titrecartePrix = 'Evolution des prix de ' + produit.nom;
            if (marche !== undefined) {
              this.titrecartePrix += ' sur le marché ' + marche.nom;
            }
            this.dashboardService.getCartePrix(this.analyticForm.get('produit').value, selectedValue, this.analyticForm.get('mesure').value,
                this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                  this.statistiques = datas;
                  this.setHSOptions(datas);
                  this.chartsReady = true;
                }
            );
          }
        });

    this.analyticForm.get('mesure').valueChanges.subscribe(
        (selectedValue) => {
          const marche = this.marches.find(obj => obj.id.toString() === this.analyticForm.get('marche').value);
          const produit = this.produits.find(obj => obj.id.toString() === this.analyticForm.get('produit').value);
          if (produit !== undefined) {
            this.titrecartePrix = 'Evolution des prix de ' + produit.nom;
            if (marche !== undefined) {
              this.titrecartePrix += ' sur le marché ' + marche.nom;
            }
            this.dashboardService.getCartePrix(this.analyticForm.get('produit').value, this.analyticForm.get('marche').value, selectedValue,
                this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                  this.statistiques = datas;
                  this.setHSOptions(datas);
                  this.chartsReady = true;
                }
            );
          }
        });
    this.analyticForm.get('marches').valueChanges.subscribe(
        (selectedValue) => {
          if ((selectedValue !== undefined) && (this.analyticForm.get('produitComp').value !== undefined )) {
                 const produit = this.produits.find(obj => obj.id.toString() === this.analyticForm.get('produitComp').value);
            if (produit !== undefined) {
              this.titreBarPrix = 'Comparaison des prix de ' + produit.nom + ' par marché';
              this.dashboardService.getComparaison(this.analyticForm.get('produitComp').value, selectedValue,
                  this.analyticForm.get('mesureMarche').value, this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                    if (datas.barChartData.length > 0) {
                      this.setBarChart(datas);
                      this.barChartsReady = true;
                    }
                  }
              );
            }
          }
        });
    this.analyticForm.get('produitComp').valueChanges.subscribe(
        (selectedValue) => {
          if ((selectedValue !== undefined) && (this.analyticForm.get('marches').value !== undefined )) {
            const produit = this.produits.find(obj => obj.id.toString() === selectedValue);
            this.titreBarPrix = 'Comparaison des prix de ' + produit.nom + ' par marché';
            this.dashboardService.getComparaison(selectedValue, this.analyticForm.get('marches').value, this.analyticForm.get('mesureMarche').value,
                this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                    if (datas.barChartData.length > 0) {
                      this.setBarChart(datas);
                      this.barChartsReady = true;
                    }
                }
            );
          }
        });

    this.analyticForm.get('mesureMarche').valueChanges.subscribe(
        (selectedValue) => {
          if ((selectedValue !== undefined) && (this.analyticForm.get('marches').value !== undefined )) {
            const produit = this.produits.find(obj => obj.id.toString() === this.analyticForm.get('produitComp').value);
            this.titreBarPrix = 'Comparaison des prix de ' + produit.nom + ' par marché';
            this.dashboardService.getComparaison(this.analyticForm.get('produitComp').value, this.analyticForm.get('marches').value, selectedValue,
                this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                  if (datas.barChartData.length > 0) {
                    this.setBarChart(datas);
                    this.barChartsReady = true;
                  }
                }
            );
          }
        });

   this.analyticForm.get('regions').valueChanges.subscribe(
        (selectedValue) => {
          if ((selectedValue !== undefined) && (this.analyticForm.get('produitCompRegion').value !== undefined )) {
            const produit = this.produits.find(obj => obj.id.toString() === this.analyticForm.get('produitCompRegion').value);
            if (produit !== undefined) {
            this.titreBarPrixRegion = 'Comparaison des prix de ' + produit.nom + ' par région';
            this.dashboardService.getComparaisonRegion(this.analyticForm.get('produitCompRegion').value,
                selectedValue, this.analyticForm.get('mesureRegion').value, this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                  if (datas.barChartData.length > 0) {
                    this.setBarChartRegion(datas);
                    this.barChartsRegionReady = true;
                  }
                }
            );
            }
          }
        });
    this.analyticForm.get('produitCompRegion').valueChanges.subscribe(
        (selectedValue) => {
          if ((selectedValue !== undefined) && (this.analyticForm.get('regions').value !== undefined )) {
            const produit = this.produits.find(obj => obj.id.toString() === selectedValue);
            if (produit !== undefined) {
              this.titreBarPrixRegion = 'Comparaison des prix de ' + produit.nom + ' par région';
              this.dashboardService.getComparaisonRegion(selectedValue, this.analyticForm.get('regions').value,
                  this.analyticForm.get('mesureRegion').value, this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                    if (datas.barChartData.length > 0) {
                      this.setBarChartRegion(datas);
                      this.barChartsRegionReady = true;
                    }
                  }
              );
            }
          }
        });

    this.analyticForm.get('mesureRegion').valueChanges.subscribe(
        (selectedValue) => {
          if ((selectedValue !== undefined) && (this.analyticForm.get('regions').value !== undefined )) {
            const produit = this.produits.find(obj => obj.id.toString() === this.analyticForm.get('produitCompRegion').value);
            this.titreBarPrix = 'Comparaison des prix de ' + produit.nom + ' par région';
            this.dashboardService.getComparaison(this.analyticForm.get('produitCompRegion').value,
                this.analyticForm.get('regions').value,  selectedValue, this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                  if (datas.barChartData.length > 0) {
                    this.setBarChart(datas);
                    this.barChartsReady = true;
                  }
                }
            );
          }
        });
    this.analyticForm.get('produitOffreRegion').valueChanges.subscribe(
        (selectedValue) => {
          if ((selectedValue !== undefined) ) {
            const produit = this.produits.find(obj => obj.id.toString() === selectedValue);
            if (produit !== undefined) {
              this.titreOffresVente = ' Volume comparé des offres de vente de ' + produit.nom + ' par régions';
              this.titreOffresAchat = ' Volume comparé des offres d\'achat de ' + produit.nom + ' par régions';
              this.dashboardService.getStatistiquesOffres(selectedValue, this.dateDebut.getDate().toLocaleDateString()).subscribe(datas => {
                    const donneesOffreVente = [];
                    const donneesOffreAchat = [];
                    let color = 'rgba(5,172,76,0.4)';
                    let borderColor = '#058d41';
                    datas.achats.forEach((item, index) => {
                      if (index === 1) {
                        color = '#fee8d7';
                        borderColor = '#e15200';
                      } else if (index === 2) {
                        color = '#e6deff';
                        borderColor = '#805aff';
                      } else if (index === 3) {
                        color = '#d5edff';
                        borderColor = '#2fa7ff';
                      }
                      donneesOffreAchat.push({
                        value: item.montant, name: item.region,
                        itemStyle: {normal: {color: color, borderColor: borderColor}}
                      });
                    });
                    datas.ventes.forEach((item, index) => {
                      if (index === 1) {
                        color = '#fee8d7';
                        borderColor = '#e15200';
                      } else if (index === 2) {
                        color = '#e6deff';
                        borderColor = '#805aff';
                      } else if (index === 3) {
                        color = '#d5edff';
                        borderColor = '#2fa7ff';
                      }
                      donneesOffreVente.push({
                        value: item.montant, name: item.region,
                        itemStyle: {normal: {color: color, borderColor: borderColor}}
                      });
                    });

                    this.setDOptionsOffreAchat(donneesOffreAchat);
                    this.setDOptionsOffreVente(donneesOffreVente);
                    this.volumeChartsReady = true;
                  }
              );
            }
          }
        });





  }
    onRegionsPromise() {
    for (const region of this.regions) {
      this.regionsAdapter.push({'value' : region.id.toString(), 'label' : region.nom});
    }
    this.analyticForm.patchValue({
      regions: [this.regions[0].id.toString()],
      regionsOffre: [this.regions[0].id.toString()]
    });
  }
  onMesuresPromise() {
    for (const mesure of this.mesures) {
      this.mesuresAdapter.push({'value' : mesure.id.toString(), 'label' : mesure.nom});
    }
    this.analyticForm.patchValue({
      mesure: [this.mesures[0].id.toString()],
      mesureMarche: [this.mesures[0].id.toString()],
      mesureRegion: [this.mesures[0].id.toString()]
    });
  }
  initReactiveForm() {
    this.analyticForm = this.fb.group({
          produit: [''],
          marche: [''],
          produitComp: [''],
          mesure: [''],
          mesureMarche: [''],
          mesureRegion: [''],
          marches: [''],
          produitCompRegion: [''],
          regions: [''],
          regionsOffre: [''],
          produitOffreRegion: ['']
        }
    ) ;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }

    ngAfterViewInit(): void {
        this.dateDebut.culture('fr-FR');
        const pdateDebut = new Date();
        pdateDebut.setFullYear(pdateDebut.getFullYear(), 0, 1);
        this.dateDebut.setDate(pdateDebut);
    }
  select(event: any): void {
    if (event.args) {
      const item = event.args.item;
      if (item) {
        const valueElement = document.createElement('div');
        const labelElement = document.createElement('div');
        valueElement.innerHTML = 'Value: ' + item.value;
        labelElement.innerHTML = 'Label: ' + item.label;
      }
    }
  }
  setBarChart(datas) {
    this.barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true
    };

    this.barChartLabels = datas.barChartLabels;
    this.barChartType = 'bar';
    this.barChartLegend = true;
    this.barChartData = datas.barChartData;
    this.barChartColors = [
      {
        backgroundColor: '#fc8b37',
        borderColor: '#fc8b37',
        pointBackgroundColor: '#fc8b37',
        pointBorderColor: '#fc8b37',
        pointHoverBackgroundColor: '#fc8b37',
        pointHoverBorderColor: '#fc8b37'
      },
      {
        backgroundColor: '#7cdb86',
        borderColor: '#7cdb86',
        pointBackgroundColor: '#7cdb86',
        pointBorderColor: '#7cdb86',
        pointHoverBackgroundColor: '#7cdb86',
        pointHoverBorderColor: '#7cdb86'
      }
    ];
  }
  setBarChartRegion(datas) {
    this.barChartOptions = {
      scaleShowVerticalLines: false,
      responsive: true
    };

    this.barChartLabelsRegion = datas.barChartLabels;
    this.barChartTypeRegion = 'bar';
    this.barChartLegendRegion = true;
    this.barChartDataRegion = datas.barChartData;
    this.barChartColorsRegion = [
      {
        backgroundColor: '#fc8b37',
        borderColor: '#fc8b37',
        pointBackgroundColor: '#fc8b37',
        pointBorderColor: '#fc8b37',
        pointHoverBackgroundColor: '#fc8b37',
        pointHoverBorderColor: '#fc8b37'
      },
      {
        backgroundColor: '#7cdb86',
        borderColor: '#7cdb86',
        pointBackgroundColor: '#7cdb86',
        pointBorderColor: '#7cdb86',
        pointHoverBackgroundColor: '#7cdb86',
        pointHoverBorderColor: '#7cdb86'
      }
    ];
  }
  setHSOptions(datas) {
    this.hsOptions = {
      color: ['#ed253d', '#027b0f'],
      tooltip: {
        trigger: 'none',
        axisPointer: {
          type: 'cross'
        }
      },
      legend: {
       data: datas.legendes
      },
      grid: {
        left: 30,
        right: 30,
        top: 50,
        bottom: 50
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#03720f'
            }
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return 'Prix ' + params.value
                  + (params.seriesData.length ? '：' + params.seriesData[0].data : '');
              }
            }
          },
          data: datas.anneeN
          },
        {
          type: 'category',
          boundaryGap: false,
          axisTick: {
            alignWithLabel: true
          },
          axisLine: {
            onZero: false,
            lineStyle: {
              color: '#0c0c0c'
            }
          },
          axisPointer: {
            label: {
              formatter: function (params) {
                return 'Prix ' + params.value
                  + (params.seriesData.length ? '：' + params.seriesData[0].data : '');
              }
            }
          },
          data: datas.anneeN1
          }
      ],
      yAxis: [
        {
          type: 'value',
          axisLabel : {
            formatter: ''
          }
        }
      ],
      series: [
        {
          name: datas.legendeN1,
          type: 'line',
          xAxisIndex: 1,
          smooth: true,
          areaStyle: {
            color: 'rgba(206,184,52,0.2)'
          },
          data: datas.serieN1
        },
        {
          name: datas.legendeN,
          type: 'line',
          smooth: true,
          areaStyle: {
            color: 'rgba(57,85,9,0.2)'
          },
          data: datas.serieN
        }
      ]
    };
  }

}
