import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../animations/form-error';
import {ChronoAlerteService} from './chronoAlerte.service';
import {UserService} from '../administrateur/user.service';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';

export interface IChrono {
    cron: string;
    cronTutorial: string;
}

@Component({
    selector: 'tc-chrono-alerte',
    templateUrl: './chronoAlerte.component.html',
    styleUrls: ['./chronoAlerte.component.scss'],
    animations: [fadeIn]
})
export class ChronoAlerteComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(store: Store<IAppState>,
                httpSv: HttpService,
                private route: ActivatedRoute,
                public router: Router, private toastr: ToastrService, private i18n: NzI18nService,
                private fb: FormBuilder, private auth: AuthenticationService,  private  chronoAlerteService: ChronoAlerteService

    ) {
        super(store, httpSv);
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);
        this.initReactiveForm();

    }
    chronogramme: IChrono;
    chronoForm: FormGroup;
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            nom: 'Script Cron',
            cronTutorial: 'Cron Tutorial'
        };

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.pageData.title =  'Chronogramme des alertes';
        this.setReactiveForm();
     }


    initReactiveForm() {
        this.chronoForm = this.fb.group({
                cron: ['', Validators.required],
                cronTutorial: ['']
            }
        ) ;


    }


    setReactiveForm() {
        if (this.chronogramme) {
                this.chronoForm.patchValue({
                    cron: this.chronogramme.cron,
                    cronTutorial: this.chronogramme.cronTutorial
                }
            );

        }

    }


    ngOnInit() {
        super.ngOnInit();
        this.getData(this.chronoAlerteService.getURL(), 'chronogramme', 'setLoaded');


    }
    ngAfterViewInit() {


    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }

    mapFormValuesToModel() {
        this.chronogramme.cron =  this.chronoForm.value.cron;
     }
    update(): void {
        this.mapFormValuesToModel();
        this.chronoAlerteService.update(this.chronogramme)
            .subscribe(
                   data => {
                    this.toastr.success('Modification réussie', '',
                        {timeOut: 2000});
                      // this.router.navigate(['']);
                },
                error => console.log(error));
        this.chronoAlerteService.reconfigure()
            .subscribe(
                data => {
                    this.toastr.success('Redémarrage du service cron', '',
                        {timeOut: 2000});
                    this.router.navigate(['']);

                },
                error => console.log(error));
    }


    onSubmit() {
        this.update();

    }

}
