var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { ExcelService } from '../../../../services/sharedServices/excel-service.service';
import { Acteur } from '../../models/acteur';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UsersDatasource } from '../users.datasource';
var PageIndexUserComponent = /** @class */ (function (_super) {
    __extends(PageIndexUserComponent, _super);
    function PageIndexUserComponent(store, httpSv, userService, dialog, router, route) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.userService = userService;
        _this.dialog = dialog;
        _this.router = router;
        _this.route = route;
        _this.sortField = 'nom';
        _this.pageIndex = 0;
        _this.pageSize = 5;
        _this.productCount = 0;
        // MatPaginator Output
        _this.displayedColumns = ['mobilePhone', 'nom', 'prenom', 'reseau', 'actions'];
        _this.pageData = {
            subTitle: '',
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Tableau de bord',
                    route: 'dashboard'
                }
            ]
        };
        _this.rows = [];
        return _this;
    }
    PageIndexUserComponent.prototype.getTitle = function () {
        var prefixe = ' Liste des ';
        if (this.userType === 'administrateur') {
            return prefixe + ' Administrateurs';
        }
        else if (this.userType === 'superviseur') {
            return prefixe + ' Superviseurs';
        }
        else if (this.userType === 'enqueteur') {
            return prefixe + ' Enqueteurs';
        }
        else if (this.userType === 'utilisateur') {
            return prefixe + ' Utilisateurs';
        }
        else if (this.userType === 'animateur') {
            return prefixe + ' Animateurs';
        }
        else {
            return prefixe + ' Utilisateurs';
        }
    };
    PageIndexUserComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.userType = this.route.snapshot.data[0].type;
        this.dataSource = new UsersDatasource(this.userService);
        this.userService.findCount(this.userType).subscribe(function (count) {
            _this.productCount = count;
            if (sessionStorage.getItem('userPageIndex') != null) {
                _this.pageIndex = +sessionStorage.getItem('userPageIndex');
            }
            else {
                _this.pageIndex = 0;
            }
            if (sessionStorage.getItem('userPageSize') != null) {
                _this.pageSize = (+sessionStorage.getItem('userPageSize') > 0) ? +sessionStorage.getItem('userPageSize') : 5;
            }
            else {
                _this.pageSize = 5;
            }
            _this.paginator.pageIndex = _this.pageIndex;
            _this.paginator.pageSize = _this.pageSize;
            _this.dataSource.loadAdministrateurs('', _this.userType, _this.sortField, 'asc', _this.pageIndex, _this.pageSize);
        });
        this.pageData.title = this.getTitle();
    };
    PageIndexUserComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.sort.sortChange.subscribe(function () { return _this.paginator.pageIndex = 0; });
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(debounceTime(150), distinctUntilChanged(), tap(function () {
            _this.paginator.pageIndex = 0;
            _this.loadPage();
        }))
            .subscribe();
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(function () { return _this.loadPage(); })).subscribe();
        this.paginator.page.subscribe(function (page) {
            _this.pageIndex = page.pageIndex;
            _this.pageSize = page.pageSize;
            sessionStorage.setItem('userPageIndex', page.pageIndex);
            sessionStorage.setItem('userPageSize', page.pageSize);
        });
    };
    PageIndexUserComponent.prototype.loadPage = function () {
        this.dataSource.loadAdministrateurs(this.input.nativeElement.value, this.userType, this.sortField, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
    };
    PageIndexUserComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
    };
    PageIndexUserComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageIndexUserComponent.prototype.edit = function (id) {
        var fromURL = this.route.snapshot.url.toString();
        this.router.navigate(['/vertical/create-administrateur', { id: id, userType: this.userType, fromURL: fromURL }]);
    };
    PageIndexUserComponent.prototype.ajouter = function () {
        var fromURL = this.route.snapshot.url.toString();
        this.router.navigate(['/vertical/create-administrateur', { userType: this.userType, fromURL: fromURL }]);
    };
    PageIndexUserComponent.prototype.exportByExcel = function () {
        console.log("rows : ", [this.rows, this.userType]);
        ExcelService.exportAsExcelFile(Acteur.listFromJSON(this.rows, this.userType), 'utilisateurs');
    };
    // exportAsXLSX() {
    //     this.getData(this.userService.getAllURL(), 'rows', 'exportByExcel');
    // }
    PageIndexUserComponent.prototype.exportAsXLSX = function () {
        var _this = this;
        this.userService.getAllReport(this.userType).subscribe(function (rows) {
            _this.rows = rows;
            console.log("admin rows ", rows);
            ExcelService.exportAsExcelFile(Acteur.listFromJSON(_this.rows, _this.userType), _this.userType + 's');
        });
    };
    // exportByExcel() {
    //     ExcelService.exportAsExcelFile(Acteur.listFromJSON(this.rows), this.userType + 's');
    // }
    // exportAsXLSX() {
    //     this.getData(this.reseauService.getAllURL(), 'rows', 'exportByExcel');
    // }
    PageIndexUserComponent.prototype.getAdministrateur = function (jsonValue) {
        return Acteur.fromJSON(jsonValue);
    };
    PageIndexUserComponent.prototype.delete = function (id) {
        var _this = this;
        var message = "Confirmez-vous la suppression de l'utilisateur ?";
        var dialogData = new ConfirmDialogModel('Confirmation', message);
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '500px',
            data: dialogData,
            panelClass: 'customDialog'
        });
        dialogRef.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult === true) {
                _this.userService.delete(id)
                    .subscribe(function (data) {
                    _this.loadPage();
                }, function (error) { return console.log(error); });
            }
        });
    };
    return PageIndexUserComponent;
}(BasePageComponent));
export { PageIndexUserComponent };
