var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { ValiderInfoService } from './validerInfo.service';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { ExcelService } from '../../../services/sharedServices/excel-service.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
var ValiderInfoComponent = /** @class */ (function (_super) {
    __extends(ValiderInfoComponent, _super);
    function ValiderInfoComponent(store, httpSv, route, authService, router, toastr, fb, i18n, validerInfoService, modal) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.authService = authService;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.validerInfoService = validerInfoService;
        _this.modal = modal;
        _this.dateValidation = new Date();
        _this.causeRejet = '';
        _this.outData = [];
        /******************/
        _this.isAllDisplayDataChecked = false;
        _this.isOperating = false;
        _this.isIndeterminate = false;
        _this.listOfDisplayData = [];
        _this.listOfAllDataInfo = [];
        _this.mapOfCheckedId = {};
        _this.numberOfChecked = 0;
        _this.pageData = {
            title: '',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        return _this;
    }
    /******************************/
    ValiderInfoComponent.prototype.valider = function () {
        var _this = this;
        this.outData = [];
        this.listOfAllDataInfo.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                console.log('item', item);
                _this.outData.push(item);
            }
        });
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                infos: this.outData
            } };
        if (this.outData.length > 0) {
            this.validerInfoService.valider(this.data).subscribe(function (data) {
                _this.toastr.success('Validation réussie des infos ', '', { timeOut: 2000 });
                _this.loadInfo();
            }, function (error) { return console.log(error); });
        }
        else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    };
    ValiderInfoComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadInfo();
    };
    ValiderInfoComponent.prototype.closeModal = function () {
        this.causeRejet = '';
        this.modal.close();
    };
    ValiderInfoComponent.prototype.validerRejet = function () {
        if (this.causeRejet > '') {
            this.modal.close();
            this.rejeter();
        }
        else {
            this.toastr.error('Vous devez saisir une cause de rejet ');
        }
    };
    ValiderInfoComponent.prototype.rejeter = function () {
        var _this = this;
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                infos: this.outData,
                causeRejet: this.causeRejet
            } };
        this.validerInfoService.rejeter(this.data).subscribe(function (data) {
            _this.toastr.success('rejet réussi des lignes d\'informations  sélectionnés', '', { timeOut: 2000 });
            _this.loadInfo();
        }, function (error) { return console.log(error); });
    };
    ValiderInfoComponent.prototype.openModal = function (body, header, footer, options) {
        var _this = this;
        if (header === void 0) { header = null; }
        if (footer === void 0) { footer = null; }
        if (options === void 0) { options = null; }
        this.outData = [];
        this.listOfAllDataInfo.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        }
        else {
            this.toastr.info('Vous devez sélectionner au moins un ligne d\'information ');
        }
    };
    ValiderInfoComponent.prototype.loadInfo = function () {
        var _this = this;
        this.listOfAllDataInfo = [];
        var reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerInfoService.getAllByReseau(reseauId.toString()).subscribe(function (data) {
            _this.infosAValider = data;
            _this.infosAValider.forEach(function (info, index, array) {
                _this.listOfAllDataInfo.push({
                    id: info.id,
                    dateExpiration: info.dateExpiration,
                    titre: info.titre,
                    contenu: info.contenu,
                    date: info.date,
                    url: info.url
                });
            });
        });
        this.mapOfCheckedId = {};
        this.refreshStatus();
    };
    ValiderInfoComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ValiderInfoComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    };
    ValiderInfoComponent.prototype.refreshStatus = function () {
        var _this = this;
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(function (item) { return _this.mapOfCheckedId[item.id]; });
        this.isIndeterminate =
            this.listOfDisplayData.some(function (item) { return _this.mapOfCheckedId[item.id]; }) &&
                !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataInfo.filter(function (item) { return _this.mapOfCheckedId[item.id]; }).length;
    };
    ValiderInfoComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfDisplayData.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = value); });
        this.refreshStatus();
    };
    ValiderInfoComponent.prototype.operateData = function () {
        var _this = this;
        this.isOperating = true;
        setTimeout(function () {
            _this.listOfAllDataInfo.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = false); });
            _this.refreshStatus();
            _this.isOperating = false;
        }, 1000);
    };
    ValiderInfoComponent.prototype.exportByExcel = function () {
        ExcelService.exportAsExcelFile(this.infosAValider, 'info_a_valider');
    };
    return ValiderInfoComponent;
}(BasePageComponent));
export { ValiderInfoComponent };
