import { OnInit, ElementRef, EventEmitter, Renderer2, TemplateRef } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { selectState } from '../../interfaces/general';
var TCSelectComponent = /** @class */ (function () {
    function TCSelectComponent(elementRef, renderer) {
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.onChange = function (value) { };
        this.onTouched = function () { };
        this.multiple = false;
        this.selectState = selectState;
        this.selectedOptions = [];
        this.options = [];
        this.allowClear = false;
        this.disabled = false;
        this.filter = false;
        this.filterForm = new FormControl('');
        this.notFoundMsg = 'No results found!';
        this.opened = false;
        this.valueSelected = new EventEmitter();
        this.size = 'md';
    }
    Object.defineProperty(TCSelectComponent.prototype, "smSize", {
        get: function () {
            return this.size === 'sm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCSelectComponent.prototype, "lgSize", {
        get: function () {
            return this.size === 'lg';
        },
        enumerable: true,
        configurable: true
    });
    TCSelectComponent.prototype.ngOnInit = function () {
        this.currentBgColor = this.bgColor instanceof Array ? this.bgColor[0] : this.bgColor;
        this.currentBorderColor = this.borderColor instanceof Array ? this.borderColor[0] : this.borderColor;
        this.currentColor = this.color instanceof Array ? this.color[0] : this.color;
        this.setStyles(!this.disabled ? this.selectState.default : this.selectState.disabled);
        this.selected ? this.writeValue(this.selected) : null;
    };
    TCSelectComponent.prototype.onMouseEnter = function () {
        if (!this.opened) {
            this.setStyles(this.selectState.hover);
        }
    };
    TCSelectComponent.prototype.onMouseOut = function () {
        if (!this.opened) {
            this.setStyles(this.selectState.default);
        }
    };
    TCSelectComponent.prototype.handleClick = function (event) {
        if (this.opened && !this.elementRef.nativeElement.contains(event.target)) {
            this.setStyles(this.selectState.default);
            this.opened = false;
            this.onTouched();
        }
    };
    // write value
    TCSelectComponent.prototype.writeValue = function (value, options) {
        if (options === void 0) { options = this.options; }
        if (options && options.length) {
            var val_1;
            if (this.multiple) {
                val_1 = [];
                options.forEach(function (opt) {
                    (Array.isArray(value) ? value.includes(opt.value) : opt.value == value) ? val_1.push(opt.value) : null;
                });
            }
            else {
                var selectedOpt = void 0;
                selectedOpt = options.find(function (opt) {
                    return (Array.isArray(value)) ? value.includes(opt.value) : opt.value === value;
                });
                val_1 = selectedOpt ? selectedOpt.value : null;
            }
            this.setSelectedOptions(val_1);
            this.innerValue = val_1;
            this.onChange(val_1);
        }
    };
    // register OnChange event
    TCSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    // register OnTouched event
    TCSelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    // get all options
    TCSelectComponent.prototype.getOptions = function (options) {
        var _this = this;
        return options.filter(function (option) {
            return option.label.toString().toLowerCase().startsWith(_this.filterForm.value.toString().toLowerCase());
        });
    };
    // set selected option
    TCSelectComponent.prototype.setSelectedOptions = function (value) {
        var _this = this;
        if (this.options && this.options.length) {
            this.selectedOptions = this.options.filter(function (option) {
                return !_this.multiple ? (option.value === value) : (_this.includeSelected(value, option.value));
            });
        }
    };
    // detect include in selected options
    TCSelectComponent.prototype.includeSelected = function (selected, value) {
        return Array.isArray(selected) && selected.length && selected.includes(value);
    };
    // clear selected options
    TCSelectComponent.prototype.clearOptions = function (event) {
        event.stopPropagation();
        this.innerValue = this.selected = null;
        this.writeValue(this.innerValue);
    };
    // detect selected option
    TCSelectComponent.prototype.isSelected = function (value) {
        var bool = false;
        if (this.multiple) {
            bool = (this.includeSelected(this.innerValue, value)) ? true : false;
        }
        else {
            bool = (this.innerValue && this.innerValue === value) ? true : false;
        }
        return bool;
    };
    // remove option from selected
    TCSelectComponent.prototype.removeSelectedOption = function (event, option) {
        event.stopPropagation();
        if (Array.isArray(this.innerValue)) {
            this.innerValue = this.innerValue.filter(function (opt) { return opt !== option.value; });
            this.writeValue(this.innerValue);
        }
    };
    // select option
    TCSelectComponent.prototype.selectOption = function (opt, active) {
        if (this.multiple) {
            !this.innerValue ? this.innerValue = [] : null;
            Array.isArray(this.innerValue) ? this.writeValue(this.innerValue.concat([opt.value])) : null;
        }
        else {
            if (!active) {
                this.writeValue(opt.value);
                this.setStyles(this.selectState.default);
                this.opened = false;
            }
        }
    };
    // toggle (opened/closed) list state
    TCSelectComponent.prototype.toggleState = function (state) {
        this.opened = !state;
        !this.opened ? this.onTouched() : null;
        this.setStyles(!state ? this.selectState.opened : this.selectState.default);
    };
    Object.defineProperty(TCSelectComponent.prototype, "value", {
        // get value
        get: function () {
            return this.innerValue;
        },
        // set value
        set: function (value) {
            this.value = value;
            this.writeValue(value);
            this.onChange(value);
        },
        enumerable: true,
        configurable: true
    });
    // custom component's styles
    TCSelectComponent.prototype.getSelectStyles = function () {
        return {
            'color': this.currentColor,
            'background': this.currentBgColor,
            'border-color': this.currentBorderColor
        };
    };
    TCSelectComponent.prototype.getListStyles = function () {
        return {
            'color': this.listColor ? this.listColor : null,
            'background': this.listBgColor ? this.listBgColor : null,
            'border-color': this.listBorderColor ? this.listBorderColor : null
        };
    };
    TCSelectComponent.prototype.getOptionStyle = function (selected) {
        if (selected) {
            return {
                'color': this.listHighlightTextColor ? this.listHighlightTextColor : null,
                'background': this.listHighlightBgColor ? this.listHighlightBgColor : null
            };
        }
    };
    TCSelectComponent.prototype.optionMouseenter = function (event) {
        this.listHighlightTextColor ? this.renderer.setStyle(event.target, 'color', this.listHighlightTextColor) : null;
        this.listHighlightBgColor ? this.renderer.setStyle(event.target, 'background', this.listHighlightBgColor) : null;
    };
    TCSelectComponent.prototype.optionMouseleave = function (event, selected) {
        if (!selected) {
            this.renderer.removeStyle(event.target, 'color');
            this.renderer.removeStyle(event.target, 'background');
        }
    };
    TCSelectComponent.prototype.setStyles = function (st, bg, border, color) {
        if (bg === void 0) { bg = this.bgColor; }
        if (border === void 0) { border = this.borderColor; }
        if (color === void 0) { color = this.color; }
        var styleIndex = 0;
        switch (st) {
            case this.selectState.hover:
                styleIndex = 1;
                break;
            case this.selectState.opened:
                styleIndex = 2;
                break;
            case this.selectState.disabled:
                styleIndex = 3;
                break;
            default:
                styleIndex = 0;
        }
        this.currentBgColor = bg instanceof Array ? bg[styleIndex] : bg;
        this.currentBorderColor = border instanceof Array ? border[styleIndex] : border;
        this.currentColor = color instanceof Array ? color[styleIndex] : color;
    };
    return TCSelectComponent;
}());
export { TCSelectComponent };
