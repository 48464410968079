<div class="col-sm-8">
<ng-container *ngIf="region">
    <form  [formGroup]="regionForm"  novalidate (ngSubmit)="onSubmit()">

        <tc-card >
                   <tc-form-group>
                        <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                        <tc-input type="text" [placeholder]="''" formControlName="code" >
                        </tc-input>
                       <ng-container *ngIf="regionForm.get('code').invalid && (regionForm.get('code').touched || regionForm.get('code').dirty)">
                           <span @fadeIn class="error" *ngIf="regionForm.get('code').getError('required')">Veuillez saisir le code de la région</span>
                       </ng-container>
                    </tc-form-group>

                       <tc-form-group>
                                    <tc-form-label>{{Columnslabels.nom}} </tc-form-label>
                                    <tc-input type="text" [placeholder]="'Nom '" formControlName="nom" >
                                    </tc-input>
                                    <ng-container *ngIf="regionForm.get('nom').invalid && (regionForm.get('nom').touched || regionForm.get('nom').dirty)">
                                        <span @fadeIn class="error" *ngIf="regionForm.get('nom').getError('required')">Veuillez saisir le nom de la région</span>
                                        <span @fadeIn class="error" *ngIf="regionForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                                    </ng-container>

                        </tc-form-group>

                        <tc-form-group>
                            <tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
                        </tc-form-group>

                    <span *ngIf="!regionForm.valid && regionForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                    <button tc-button type="submit" [block]="true" >
                        Valider
                    </button>


        </tc-card>
    </form>
</ng-container>
</div>
