import { OnInit } from '@angular/core';
var TCVTimelineComponent = /** @class */ (function () {
    function TCVTimelineComponent() {
        this.align = 'left';
        this.showLabels = false;
        this.showIcons = true;
        this.data = [];
    }
    Object.defineProperty(TCVTimelineComponent.prototype, "dots", {
        get: function () {
            return !this.showIcons;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCVTimelineComponent.prototype, "left", {
        get: function () {
            return this.align === 'left';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCVTimelineComponent.prototype, "center", {
        get: function () {
            return this.align === 'center';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCVTimelineComponent.prototype, "right", {
        get: function () {
            return this.align === 'right';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCVTimelineComponent.prototype, "between", {
        get: function () {
            return this.align === 'between';
        },
        enumerable: true,
        configurable: true
    });
    TCVTimelineComponent.prototype.ngOnInit = function () { };
    return TCVTimelineComponent;
}());
export { TCVTimelineComponent };
