import { Commune } from './commune';
var Depot = /** @class */ (function () {
    function Depot() {
        this.actif = true;
        this.commune = new Commune();
    }
    Depot.fromJSON = function (json) {
        var result = new Depot();
        if (json != null) {
            for (var key in json) {
                result[key] = json[key];
            }
        }
        return result;
    };
    Object.defineProperty(Depot.prototype, "nomCommune", {
        get: function () {
            var rval = '';
            // Array:
            if (this.commune != null) {
                rval = this.commune.nom;
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Depot.listFromJSON = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var depot = Depot.fromJSON(jsonObject);
                if (depot.commune) {
                    jsonObject.commune = depot.commune.nom;
                }
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    Depot.prototype.toString = function () {
        return this.nom;
    };
    Object.defineProperty(Depot.prototype, "getFullName", {
        get: function () {
            return (this.nom + ' (' + this.code + ')');
        },
        enumerable: true,
        configurable: true
    });
    return Depot;
}());
export { Depot };
