<form [formGroup]="rapportsForm" >

    <div class="row">
        <ng-container *ngIf="reseauxAdapter.length>0">

            <div class="col-sm-3 ">
                <tc-form-group>
                    <tc-form-label>Choix du réseau</tc-form-label>
                    <tc-select [options]="reseauxAdapter" formControlName="reseau"  [allowClear]="true" ></tc-select>
                </tc-form-group>
            </div>
        </ng-container>
        <ng-container *ngIf="regionsAdapter.length>0">

            <div class="col-sm-3 ">
                <tc-form-group>
                    <tc-form-label>Choix des régions</tc-form-label>
                    <tc-select [options]="regionsAdapter" formControlName="regions"  [filter]="true" [multiple]="true" [allowClear]="true" ></tc-select>
                </tc-form-group>
            </div>
        </ng-container>
        <ng-container *ngIf="produitsAdapter.length>0">

            <div class="col-sm-3 ">
                <tc-form-group>
                    <tc-form-label>Choix des produits</tc-form-label>
                    <tc-select [options]="produitsAdapter" formControlName="produits"  [filter]="true" [multiple]="true"  [allowClear]="true" ></tc-select>
                </tc-form-group>
            </div>

        </ng-container>



    </div>
    <div class="row" style="margin-bottom: 20px;">
        <div class="col-sm-3 ">
            <tc-form-group>
            <label>{{Columnslabels.dateDebut}}</label>
            <jqxDateTimeInput #dateDebut
                              [width]="'100%'" [height]="30"  [theme]='"sima-bf"' >
            </jqxDateTimeInput>
            </tc-form-group>
        </div>
        <div class="col-sm-3 ">
            <tc-form-group>
            <label>{{Columnslabels.dateFin}}</label>
            <jqxDateTimeInput #dateFin
                              [width]="'100%'" [height]="30"   [theme]='"sima-bf"' >
            </jqxDateTimeInput>
            </tc-form-group>
        </div>
    </div>

<ng-container *ngIf="dataAdapter">

        <jqxDataTable #myDataTable
              [width]="getWidth()"  [source]="dataAdapter" [columns]="columns"
                   [pageable]="true" [sortable]="true" [columnsResize]="true" [altRows]="true" [filterable]="true"  [pagerMode]="'advanced'"
              [localization]="localizationObject" [theme]='"sima-bf"' [filterMode]="'advanced'"  [exportSettings]="exportSettings"
>
    </jqxDataTable>
    <div class="row" style="margin-top: 20px;">

        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="excelExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en Excel</jqxButton>
        </div>
        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="pdfExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en PDF</jqxButton>
        </div>
        <div class="col-sm-2 " style="margin-right: 10px ">
            <jqxButton (onClick)="jsonExport()" [width]="'100%'" [theme]='"sima-bf"'>Export en JSON</jqxButton>
        </div>
    </div>
</ng-container>
</form>
