<tc-input [(ngModel)]="value" (ngModelChange)="modelChanged($event)" [placeholder]="placeholder" [readonly]="readonly"
  [disabled]="disabled ? true : null" [type]="type" [charLimiting]="charLimiting" [prefixIcon]="prefixIcon"
  [suffixIcon]="suffixIcon" [autoSize]="autoSize" [size]="size" [prefix]="prefix" [suffix]="suffix" [bgColor]="bgColor"
  [borderColor]="borderColor" [color]="color" (focus)="autocompleteOnFocus()" (blur)="autocompleteOnBlur()"
  (keydown)="keyClick($event)"></tc-input>

<div class="autocomplete-list" [ngClass]="{ 'opened': openedList }">
  <ul class="list-ul" [ngStyle]="getListStyles()">
    <li class="list-li" (click)="selectItem(item, $event)" *ngFor="let item of results; let i = index"
      [ngClass]="{ 'active': i === arrowKeyLocation }">
      <span class="li-text" [innerHtml]="markValue(key ? item[key] : item, value)">
        {{ key ? item[key] : item }}
      </span>
    </li>
  </ul>
</div>
