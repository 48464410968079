import { Component,  OnInit } from '@angular/core';

@Component({
  selector: 'tc-page-506',
  templateUrl: './page-506.component.html',
  styleUrls: ['../error-base-page/error-base-page.component.scss'],
})
export class Page506Component implements OnInit {
  title: string;
  subTitle: string;
  errorMessage: string;

  constructor() { }

  ngOnInit() {
    this.title = 'Page Interdite';
    this.subTitle = 'Désolé !!';
    this.errorMessage = 'L\'accès à cette page vous est interdit ';
  }
}
