import { Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { environment } from '../../../../environments/environment';
import * as SettingsActions from '../../../store/actions/app-settings.actions';
var LAYOUTS = [
    {
        label: 'Vertical',
        value: 'vertical',
    },
    {
        label: 'Horizontal',
        value: 'horizontal',
    },
];
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(store, sanitizer, router, formBuilder) {
        this.store = store;
        this.sanitizer = sanitizer;
        this.router = router;
        this.formBuilder = formBuilder;
        this.layouts = LAYOUTS;
        this.defaultSettings = environment.appSettings;
    }
    SettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.select('appSettings').subscribe(function (st) {
            if (st) {
                _this.settings = st;
                _this.settings.layout = _this.getLayout();
                if (!_this.form) {
                    _this.initSettingsForm(st);
                }
                _this.downloadSettings(st);
            }
        });
    };
    SettingsComponent.prototype.initSettingsForm = function (data) {
        var _this = this;
        this.form = this.formBuilder.group({
            boxed: data.boxed,
            layout: data.layout,
            topbarBg: data.topbarBg,
            topbarColor: data.topbarColor,
            sidebarBg: data.sidebarBg,
            sidebarBg2: data.sidebarBg2,
            sidebarColor: data.sidebarColor,
            sidebarAccentColor: data.sidebarAccentColor,
            sidebarAccentContrastColor: data.sidebarAccentContrastColor,
            headerbgColor: data.headerbgColor,
            itemsPerPage: data.itemsPerPage,
            headerColor: data.headerColor
        });
        this.form.valueChanges.pipe().subscribe(function (newData) {
            if (data.layout !== newData.layout) {
                _this.changeLayout(newData.layout);
                data.layout = newData.layout;
            }
            if (data.boxed !== newData.boxed) {
                _this.changeBoxed(newData.boxed);
                data.boxed = newData.boxed;
            }
        });
    };
    SettingsComponent.prototype.resetSettings = function (data) {
        if (data === void 0) { data = this.defaultSettings; }
        this.store.dispatch(new SettingsActions.Reset());
        this.form.patchValue({
            boxed: data.boxed,
            layout: data.layout,
            topbarBg: data.topbarBg,
            topbarColor: data.topbarColor,
            sidebarBg: data.sidebarBg,
            sidebarBg2: data.sidebarBg2,
            sidebarColor: data.sidebarColor,
            sidebarAccentColor: data.sidebarAccentColor,
            sidebarAccentContrastColor: data.sidebarAccentContrastColor,
            headerbgColor: data.headerbgColor,
            itemsPerPage: data.itemsPerPage,
            headerColor: data.headerColor
        });
    };
    SettingsComponent.prototype.downloadSettings = function (settings) {
        var JSON_FILE = JSON.stringify(settings);
        var URI = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8, " + encodeURIComponent(JSON_FILE));
        this.downloadJsonHref = URI;
    };
    SettingsComponent.prototype.getLayout = function () {
        var url = this.router.url.split('/').filter(function (n) { return n; });
        var layout = url[0];
        return layout;
    };
    // update layout value in app store
    SettingsComponent.prototype.changeLayout = function (layout) {
        var _this = this;
        this.store.dispatch(new SettingsActions.Update({ layout: layout }));
        var url = this.router.url.split('/').filter(function (n) { return n; });
        url[0] = layout;
        var route = url.join('/');
        setTimeout(function () {
            _this.router.navigate([route]);
        });
    };
    // update boxed value in app store
    SettingsComponent.prototype.changeBoxed = function (boxed) {
        this.store.dispatch(new SettingsActions.Update({ boxed: boxed }));
    };
    // update color in app store
    SettingsComponent.prototype.changeColor = function (value, name) {
        var _a;
        this.store.dispatch(new SettingsActions.Update((_a = {},
            _a[name] = value.color,
            _a)));
    };
    SettingsComponent.prototype.changeDoubleColor = function (value, name, contrastName) {
        var _a;
        this.store.dispatch(new SettingsActions.Update((_a = {},
            _a[name] = value.color,
            _a[contrastName] = value.contrast,
            _a)));
    };
    SettingsComponent.prototype.getNavbarName = function (bool) {
        return bool ? 'Sidebar' : 'Second navbar';
    };
    return SettingsComponent;
}());
export { SettingsComponent };
