import * as i0 from "@angular/core";
var TypeSIMService = /** @class */ (function () {
    function TypeSIMService() {
        this.baseUrl = '/api/typeSIM';
    }
    TypeSIMService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    TypeSIMService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TypeSIMService_Factory() { return new TypeSIMService(); }, token: TypeSIMService, providedIn: "root" });
    return TypeSIMService;
}());
export { TypeSIMService };
