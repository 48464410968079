<!--<div class="row justify-content-center" >-->
<!--<div class="col col-12 col-xl-8">-->
<div class="col-sm-6">
<ng-container *ngIf="administrateur ">

        <tc-card [title]="'Voir un mot de passe '" class="mb-0">
            <form class="new-doctor-form mb-4" novalidate (ngSubmit)="onSubmit()" [formGroup]="showPwdForm"  >

                <div class="row">
                    <div class="col-sm-6">
                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.theMobile}}  </tc-form-label>
                            <tc-input [prefixIcon]="'icofont-ui-user'" [placeholder]="'N° Mobile exple : +226xxxxxxxx'" formControlName="theMobile"></tc-input>
                            <ng-container *ngIf="showPwdForm.get('theMobile').invalid && (showPwdForm.get('theMobile').touched || showPwdForm.get('theMobile').dirty)">
                                <span @fadeIn class="error" *ngIf="showPwdForm.get('theMobile').getError('required')">Veuillez entrer un identifiant</span>
                                <span @fadeIn class="error" *ngIf="showPwdForm.get('theMobile').getError('minlength')">L'identifiant doit avoir au moins 8 caractères</span>
                                
                            </ng-container>
                        </tc-form-group>
                    </div>
                </div>

                <div class="row">
                            <div class="col-sm-6ng ">
                                <button tc-button  type="submit" [block]="true"  >
                                    Voir le mot de passe en clair
                                </button>
                            </div>
                            <div class="col-sm-4">
                                <strong>{{showPwdForm.get('thePassword').value}}</strong>
                            </div>
                </div>




            </form>
        </tc-card>

</ng-container>
</div>
<!--</div>-->
