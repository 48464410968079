import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {Price} from '../valider-prix/valider-prix.component';

@Injectable({
  providedIn: 'root'
})
export class ModifierPrixService {
	private baseUrl = '/api/prix';
  constructor(private http: HttpClient) {

  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllByMarcheAndDate(marcheId: string, dateSaisie: string): Observable<any[]> {
    return this.http.get(`${this.baseUrl}/get_all_by_marche_date`, {
      params: new HttpParams()
          .set('marcheId', marcheId)
          .set('dateSaisie', dateSaisie)
    }) as Observable<any[]>;
  }
  getAllByReseauAndDate(reseau: string, dateSaisie: string): Observable<any[]> {
    return this.http.get(`${this.baseUrl}/get_all_by_reseau_date`, {
      params: new HttpParams()
          .set('reseau', reseau)
          .set('dateSaisie', dateSaisie)
    }) as Observable<any[]>;
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj);
  }
  update(value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/modifier`, value);
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

}

