import { HttpClient, HttpParams } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CACHE_KEY = 'sima-user-cache';
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.baseUrl = '/api/acteur';
    }
    UserService.prototype.findAll = function (filter, userType, sortField, sortOrder, offset, pageSize) {
        if (filter === void 0) { filter = ''; }
        if (sortField === void 0) { sortField = 'nom'; }
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.get('/api/acteur/pages', {
            params: new HttpParams()
                .set('userType', userType.toUpperCase())
                .set('filter', filter)
                .set('sort', sortField)
                .set('sortOrder', sortOrder)
                .set('offset', offset.toString())
                .set('max', pageSize.toString())
        });
    };
    UserService.prototype.getAll = function (userType) {
        return this.http.get('/api/acteur/all', {
            params: new HttpParams()
                .set('userType', userType.toUpperCase())
        });
    };
    UserService.prototype.getAllReport = function (userType) {
        return this.http.get('/api/acteur/report', {
            params: new HttpParams()
                .set('userType', userType.toUpperCase())
        });
    };
    UserService.prototype.mesMarches = function () {
        return this.http.get('/api/mes_marches');
    };
    UserService.prototype.mesMarchesPreferes = function () {
        return this.http.get('/api/mes_marches_preferes');
    };
    UserService.prototype.mesDepots = function () {
        return this.http.get('/api/mes_depots');
    };
    UserService.prototype.mesDepotsPreferes = function () {
        return this.http.get('/api/mes_depots_preferes');
    };
    UserService.prototype.mesProduits = function () {
        return this.http.get('/api/mes_produits');
    };
    UserService.prototype.findMe = function () {
        return this.http.get('/api/find_me');
    };
    UserService.prototype.checkPassword = function (actualPwd) {
        return this.http.get('/api/check_password', {
            params: new HttpParams()
                .set('actualPwd', actualPwd)
        });
    };
    UserService.prototype.resetPassword = function (mobilePhone) {
        return this.http.get('/api/reset_password', {
            params: new HttpParams()
                .set('mobilePhone', mobilePhone)
        });
    };
    UserService.prototype.checkAlreadyCreated = function (mobilePhone) {
        return this.http.get('/api/check_if_exist', {
            params: new HttpParams()
                .set('mobilePhone', mobilePhone)
        }).pipe(map(function (result) { return result['checkActeur']; }));
    };
    UserService.prototype.findByMobile = function (mobilePhone) {
        return this.http.get('/api/findByMobile', {
            params: new HttpParams()
                .set('mobilePhone', mobilePhone)
        });
    };
    UserService.prototype.changePassword = function (newPassword) {
        return this.http.get('/api/change_password', {
            params: new HttpParams()
                .set('newPassword', newPassword)
        }).pipe(map(function (result) { return result['reponse']; }));
    };
    UserService.prototype.showPassword = function (thePhone) {
        return this.http.get('/api/getPassword', {
            params: new HttpParams()
                .set('thePhone', thePhone)
        }).pipe(map(function (result) { return result['motPasseEnClair']; }));
    };
    UserService.prototype.findCount = function (userType) {
        return this.http.get('/api/acteur/count', {
            params: new HttpParams()
                .set('userType', userType.toUpperCase())
        }).pipe(map(function (res) { return res['total']; }));
    };
    UserService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    UserService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    UserService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    UserService.prototype.create = function (userType, obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj, {
            params: new HttpParams()
                .set('userType', userType.toUpperCase())
        });
    };
    UserService.prototype.update = function (id, userType, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value, {
            params: new HttpParams()
                .set('userType', userType ? userType.toUpperCase() : '')
        });
    };
    UserService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    UserService.prototype.findAsTree = function () {
        return this.http.get(this.baseUrl + "/tree")
            .pipe(map(function (res) { return res['tree']; }));
    };
    UserService.prototype.findAsTreeByGroupes = function (groupes) {
        return this.http.get(this.baseUrl + "/getTreeByGroupes", {
            params: new HttpParams()
                .set('groupes', groupes)
        })
            .pipe(map(function (res) { return res['tree']; }));
    };
    UserService.prototype.getCached = function () {
        this.users = this.http.get(this.baseUrl + "/short")
            .pipe(map(function (data) { return data.items; }));
        this.users.subscribe(function (next) {
            localStorage[CACHE_KEY] = JSON.stringify(next);
        });
        this.users = this.users.pipe(startWith(localStorage[CACHE_KEY] || '[]'));
        return this.users;
    };
    UserService.prototype.getNomReseau = function (id) {
        return this.http.get(this.baseUrl + "/nom");
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
