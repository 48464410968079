import {Component, OnDestroy, OnInit} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fadeIn} from '../../../animations/form-error';
import {DownloaderService} from '../../../services/sharedServices/downloader.service';

import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'tc-importer-enqueteurs',
    templateUrl: './importer-enqueteurs.component.html',
    styleUrls: ['./importer-enqueteurs.component.scss'],
    animations: [fadeIn]
})

export class ImporterEnqueteursComponent extends BasePageComponent implements OnInit, OnDestroy {
    SERVER_URL = 'api/upload_enqueteurs';
    uploadForm: FormGroup;
  data: any;


    setLoaded(during: number = 0) {
        super.setLoaded(during);

    }
    downloadFile() {
        const link = document.createElement('a');
        link.download = 'Enqueteurs_caneva.xls';
        link.href = 'assets/data/canevas_imports/Enqueteurs_caneva.xls';
        link.click();
        link.remove();
    }

  constructor( store: Store<IAppState>,
               httpSv: HttpService,
               private route: ActivatedRoute,
               public router: Router, private toastr: ToastrService,
               private downloaderService: DownloaderService,
               private formBuilder: FormBuilder, private httpClient: HttpClient

  ) {
    super(store, httpSv);
    this.pageData = {
      title: 'IMPORTER LES ENQUETEURS',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };

  }
    onFileSelect(event) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.uploadForm.get('profile').setValue(file);
        }
    }
    onSubmit() {
        const formData = new FormData();

        formData.append('file', this.uploadForm.get('profile').value);
        this.httpClient.post<any>(this.SERVER_URL, formData).subscribe(
            (res) => {console.log(res); this.toastr.success('Succès de l\'\'importation ');
            },
            (err) => {
                console.log(err); this.toastr.error('Echec de l\'\'importation cause ' + err.toString());
            }
        );
    }
  ngOnInit() {
    super.ngOnInit();

      this.uploadForm = this.formBuilder.group({
          profile: ['']
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
