<ng-container *ngIf="reseau && typesSIM.length>0 ">
<div class="col-sm-8">
 <tc-card [title]="''">
    <form [formGroup]="reseauForm"   (ngSubmit)="onSubmit()">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                        <tc-input type="text"  formControlName="code" >
                        </tc-input>
                        <ng-container *ngIf="reseauForm.get('code').invalid && (reseauForm.get('code').touched || reseauForm.get('code').dirty)">
                            <span @fadeIn class="error" *ngIf="reseauForm.get('code').getError('required')">Veuillez saisir le code du reseau</span>
                            <span @fadeIn class="error" *ngIf="reseauForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                        </ng-container>
                    </tc-form-group>

                <tc-form-group>
                     <tc-form-label>{{Columnslabels.label}}</tc-form-label>
                    <tc-input type="text"  formControlName="nom">
                    </tc-input>
                    <ng-container *ngIf="reseauForm.get('nom').invalid && (reseauForm.get('nom').touched || reseauForm.get('nom').dirty)">
                        <span @fadeIn class="error" *ngIf="reseauForm.get('nom').getError('required')">Veuillez saisir le nom du reseau</span>
                        <span @fadeIn class="error" *ngIf="reseauForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                    </ng-container>
                </tc-form-group>
                <tc-form-group>
                        <tc-form-label>{{Columnslabels.typeSIM}}</tc-form-label>
                        <tc-select [placeholder]="'SIM'" [options]="typesSIM"  formControlName="typeSIM"></tc-select>

                    </tc-form-group>
                <ng-container *ngIf="produitsAdapter.length>0">
                    <div class="row">
                        <div class="col-sm-12">

                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.produits}}</tc-form-label>
                                <tc-select [options]="produitsAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="produits" ></tc-select>
                            </tc-form-group>
                        </div>
                    </div>
                </ng-container>
        <ng-container *ngIf="marchesAdapter.length>0">
            <div class="row">
                <div class="col-sm-12">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.marches}}</tc-form-label>
                        <tc-select [options]="marchesAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="marches" ></tc-select>
                    </tc-form-group>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="depotsAdapter.length>0">
            <div class="row">
                <div class="col-sm-12">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.depots}}</tc-form-label>
                        <tc-select [options]="depotsAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="depots" ></tc-select>
                    </tc-form-group>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="mesuresAdapter.length>0">
            <div class="row">
                <div class="col-sm-12">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.mesures}}</tc-form-label>
                        <tc-select [options]="mesuresAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="mesures" ></tc-select>
                    </tc-form-group>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="qualitesAdapter.length>0">
            <div class="row">
                <div class="col-sm-12">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.qualites}}</tc-form-label>
                        <tc-select [options]="qualitesAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="qualites" ></tc-select>
                    </tc-form-group>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="adminsAdapter.length>0">
                <tc-form-group>
                    <tc-form-label>{{Columnslabels.administrateur}}</tc-form-label>
                    <tc-select [options]="adminsAdapter" [filter]="true"   formControlName="adminPrincipal" ></tc-select>
                </tc-form-group>
        </ng-container>


        <tc-form-group>
                    <tc-form-label>{{Columnslabels.description}}</tc-form-label>
                    <tc-textarea  [charLimiter]="{ counter: 255, before: 'Il reste ', after: ' caractères' }"
                                  [tcShape]="0" [rows]="3" formControlName="description"></tc-textarea>
        </tc-form-group>


                    <tc-form-group>
                        <tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
                    </tc-form-group>

                <span *ngIf="!reseauForm.valid && reseauForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                <button  tc-button [disabled]="!reseauForm.valid" [block]="true" >
                    Valider
                </button>


    </form>

</tc-card>
</div>
</ng-container>

