import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';

import {ValiderOffreAchatService} from './validerOffreAchat.service';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {TCModalService} from '../../../ui/services/modal/modal.service';
import {Content} from '../../../ui/interfaces/modal';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';

export interface IOffreAchat {
    id: number;
    nom_produit: string;
    date_expiration: string;
    reseau: string;
    mesure: string;
    quantite: number;
    nom_auteur: string;
    telephone: string;
    email: string;
    prixUnitaire: number;
    montant: number;
    date: Date;
}


@Component({
  selector: 'tc-valider-offre-achat',
    templateUrl: './valider-offre-achat.component.html',
    styleUrls: ['./valider-offre-achat.component.scss'],
    animations: [fadeIn]
})

export class ValiderOffreAchatComponent extends BasePageComponent implements OnInit, OnDestroy {

  dateValidation = new Date();
  data: any;
  offressAValider: IOffreAchat[];
    outData: any[] = [];

    causeRejet = '';
    /******************/

    isAllDisplayDataChecked = false;
    isOperating = false;
    isIndeterminate = false;
    listOfDisplayData: IOffreAchat[] = [];
    listOfAllDataOffreAchat: IOffreAchat[] = [];
    mapOfCheckedId: { [key: string]: boolean } = {};
    numberOfChecked = 0;
    /******************************/
    valider() {
        this.outData = [];
        this.listOfAllDataOffreAchat.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
            this.outData.push(item);
            }
        });

        this.data = {data: {
            dateValidation: this.dateValidation.toLocaleDateString('en-US'),
            OffresAchat: this.outData
        }};
        if (this.outData.length > 0) {
        this.validerOffreAchatService.valider(this.data) .subscribe(
            data => {

                this.toastr.success('Validation réussie des offres d\'achat ', '',
                    {timeOut: 2000});
                this.loadOffreAchat();
            },
            error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }
  constructor(store: Store<IAppState>,
              httpSv: HttpService, private marcheService: MarcheService,
              private route: ActivatedRoute, private authService: AuthenticationService,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService,
              private validerOffreAchatService: ValiderOffreAchatService, private modal: TCModalService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }
    closeModal() {
        this.causeRejet = '';
        this.modal.close();
    }
    validerRejet() {

            if (this.causeRejet > '') {
                this.modal.close();
                this.rejeter();
            } else {
                this.toastr.error('Vous devez saisir une cause de rejet ');
            }

    }
    openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
        this.outData = [];
        this.listOfAllDataOffreAchat.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        } else {
            this.toastr.info('Vous devez sélectionner au moins une offre ');
        }
    }
  ngOnInit() {
    super.ngOnInit();
    this.loadOffreAchat();
  }
    rejeter() {

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresAchat: this.outData,
                causeRejet: this.causeRejet
            }};
            this.validerOffreAchatService.rejeter(this.data) .subscribe(
                data => {

                    this.toastr.success('rejet réussi des offres d\'achats  sélectionnés', '',
                        {timeOut: 2000});
                    this.loadOffreAchat();
                },
                error => console.log(error));

    }
    private loadOffreAchat() {
        this.listOfAllDataOffreAchat = [];
        const reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerOffreAchatService.getAllByReseau(reseauId.toString()).subscribe(data => {
                this.offressAValider = data;
                this.offressAValider.forEach((offreAchat, index, array) => {

                    this.listOfAllDataOffreAchat.push({
                        id: offreAchat.id,
                        date: offreAchat.date,
                        nom_produit: offreAchat.nom_produit,
                        quantite: offreAchat.quantite,
                        nom_auteur: offreAchat.nom_auteur,
                        reseau: offreAchat.reseau,
                        mesure: offreAchat.mesure,
                        telephone: offreAchat.telephone,
                        montant: offreAchat.montant,
                        prixUnitaire: offreAchat.prixUnitaire,
                        email: offreAchat.email,
                        date_expiration: offreAchat.date_expiration
                    });
                });
            }
        );
        this.mapOfCheckedId = {};
        this.refreshStatus();

    }

    ngOnDestroy() {
    super.ngOnDestroy();
  }

    currentPageDataChange($event: IOffreAchat[]): void {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    }

    refreshStatus(): void {
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(item => this.mapOfCheckedId[item.id]);
        this.isIndeterminate =
            this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
            !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataOffreAchat.filter(item => this.mapOfCheckedId[item.id]).length;
    }

    checkAll(value: boolean): void {
        this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
        this.refreshStatus();
    }

    operateData(): void {
        this.isOperating = true;
        setTimeout(() => {
            this.listOfAllDataOffreAchat.forEach(item => (this.mapOfCheckedId[item.id] = false));
            this.refreshStatus();
            this.isOperating = false;
        }, 1000);
    }

    exportByExcel() {
        ExcelService.exportAsExcelFile(this.offressAValider, 'offres_achats_a_valider');
    }
}
