


import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Commune} from '../models/commune';
import {CommuneService} from './commune.service';


export class CommunesDataSource implements DataSource<Commune> {

    private marchesSubject = new BehaviorSubject<Commune[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Commune[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private communeService: CommuneService) {

    }


    loadCommunes(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.communeService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(marches => this.marchesSubject.next(marches)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Commune[]> {
        return this.marchesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.marchesSubject.complete();
        this.loadingSubject.complete();
    }

}

