import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        this.baseUrl = '/api/statistiques';
        this.urlCartePrix = '/api/cartePrix';
        this.urlComparaisonPrix = '/api/comparaisonPrix';
        this.urlComparaisonPrixRegion = '/api/comparaisonPrixRegion';
        this.urlStatOffresRegion = '/api/stat_offres_region';
    }
    DashboardService.prototype.getStatistiques = function () {
        return this.http.get("" + this.baseUrl).pipe(map(function (res) { return res['stat']; }));
    };
    DashboardService.prototype.getStatistiquesOffres = function (produitId, dateDebut) {
        return this.http.get("" + this.urlStatOffresRegion, {
            params: new HttpParams()
                .set('produitId', produitId)
                .set('dateDebut', dateDebut)
        }).pipe(map(function (res) { return res['piecharts']; }));
    };
    DashboardService.prototype.getCartePrix = function (produitId, marcheId, mesureId, dateDebut) {
        return this.http.get("" + this.urlCartePrix, {
            params: new HttpParams()
                .set('produitId', produitId)
                .set('marcheId', marcheId)
                .set('mesureId', mesureId)
                .set('dateDebut', dateDebut)
        }).pipe(map(function (res) { return res['series']; }));
    };
    DashboardService.prototype.getComparaison = function (produitId, marchesId, mesureId, dateDebut) {
        return this.http.get("" + this.urlComparaisonPrix, {
            params: new HttpParams()
                .set('produitId', produitId)
                .set('marcheId', marchesId)
                .set('mesureId', mesureId)
                .set('dateDebut', dateDebut)
        }).pipe(map(function (res) { return res['barcharts']; }));
    };
    DashboardService.prototype.getComparaisonRegion = function (produitId, regionsId, mesureId, dateDebut) {
        return this.http.get("" + this.urlComparaisonPrixRegion, {
            params: new HttpParams()
                .set('produitId', produitId)
                .set('regionsId', regionsId)
                .set('mesureId', mesureId)
                .set('dateDebut', dateDebut)
        }).pipe(map(function (res) { return res['barcharts']; }));
    };
    DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
