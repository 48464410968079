import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommuneService = /** @class */ (function () {
    function CommuneService(http) {
        this.http = http;
        this.baseUrl = '/api/commune';
    }
    CommuneService.prototype.findAll = function (filter, sortField, sortOrder, offset, pageSize) {
        if (filter === void 0) { filter = ''; }
        if (sortField === void 0) { sortField = 'nom'; }
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.get('/api/commune/pages', {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sortField)
                .set('sortOrder', sortOrder)
                .set('offset', offset.toString())
                .set('max', pageSize.toString())
        });
    };
    CommuneService.prototype.findAsTree = function () {
        return this.http.get(this.baseUrl + "/tree")
            .pipe(map(function (res) { return res['tree']; }));
    };
    CommuneService.prototype.findCount = function () {
        return this.http.get('/api/commune/count')
            .pipe(map(function (res) { return res['total']; }));
    };
    CommuneService.prototype.getCommune = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    CommuneService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    CommuneService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    CommuneService.prototype.create = function (obj) {
        return this.http.post("" + this.baseUrl, obj);
    };
    CommuneService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/" + id, value);
    };
    CommuneService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    CommuneService.prototype.getQualitesList = function () {
        return this.http.get("" + this.baseUrl);
    };
    CommuneService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommuneService_Factory() { return new CommuneService(i0.ɵɵinject(i1.HttpClient)); }, token: CommuneService, providedIn: "root" });
    return CommuneService;
}());
export { CommuneService };
