<ng-container *ngIf="alerte && groupesAdapter.length>0 && depotsAdapter.length>0 && produitsAdapter.length>0 ">
    <div class="col-sm-8">
<form [formGroup]="alerteForm"   (ngSubmit)="onSubmit()">
<div class="content-body">
 <tc-card [title]="'CONFIGURATION DE L\'ALERTE DE STOCK '">

     <div class="row">
         <div class="col-sm-12">
             <tc-form-group>
                 <tc-form-label>{{Columnslabels.libelle}} </tc-form-label>
                 <tc-input type="text"  formControlName="libelle" >
                 </tc-input>
                 <ng-container *ngIf="alerteForm.get('libelle').invalid && (alerteForm.get('libelle').touched || alerteForm.get('libelle').dirty)">
                     <span @fadeIn class="error" *ngIf="alerteForm.get('libelle').getError('required')">Veuillez saisir le libellé de l'alerte</span>
                     <span @fadeIn class="error" *ngIf="alerteForm.get('libelle').getError('min')">Le libellé doit avoir plus de 2 caractères</span>
                 </ng-container>
             </tc-form-group>
         </div>
     </div>
             <div class="row">
                 <div class="col-sm-12">
                     <tc-form-label>{{Columnslabels.groupes}}</tc-form-label>
                     <tc-select [options]="groupesAdapter"  [placeholder]="'Veuillez s\électionner les groupes'" [filter]="true" [multiple]="true"  [allowClear]="true"   formControlName="groupes" ></tc-select>

                 </div>
             </div>

            <div class="row">
                <div class="col-sm-12">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.depots}}</tc-form-label>
                        <tc-select [options]="depotsAdapter" [filter]="true" [placeholder]="'Veuillez s\électionner les dépôts'"  [multiple]="true"  [allowClear]="true"   formControlName="depots" ></tc-select>
                    </tc-form-group>
                </div>
            </div>


            <div class="row">
                <div class="col-sm-12">
                    <tc-form-label>{{Columnslabels.produits}}</tc-form-label>
                    <tc-select [options]="produitsAdapter" [filter]="true" [placeholder]="'Veuillez s\électionner les produits'"  [multiple]="true"  [allowClear]="true"   formControlName="produits" ></tc-select>

                </div>
            </div>
             <div class="row">
                 <div class="col-sm-12">

                 </div>
             </div>
            <div class="row">
                <div class="col-sm-4">
                        <tc-form-group>
                            <tc-checkbox   formControlName="lundi">Lundi</tc-checkbox>
                        </tc-form-group>
                        <tc-form-group>
                            <tc-checkbox   formControlName="mardi">Mardi</tc-checkbox>
                        </tc-form-group>
                        <tc-form-group>
                            <tc-checkbox   formControlName="mercredi">Mercredi</tc-checkbox>
                        </tc-form-group>
                </div>
                <div class="col-sm-4">

                        <tc-form-group>
                            <tc-checkbox   formControlName="jeudi">Jeudi</tc-checkbox>
                        </tc-form-group>
                    <tc-form-group>
                        <tc-checkbox   formControlName="vendredi">Vendredi</tc-checkbox>
                    </tc-form-group>
                    <tc-form-group>
                        <tc-checkbox   formControlName="samedi">Samedi</tc-checkbox>
                    </tc-form-group>
                </div>
                <div class="col-sm-4">
                        <tc-form-group>
                            <tc-checkbox   formControlName="dimanche">Dimanche</tc-checkbox>
                        </tc-form-group>
                        <tc-form-group>
                            <tc-checkbox   formControlName="recevoirParEmail">Recevoir par e-mail</tc-checkbox>
                        </tc-form-group>
                        <tc-form-group>
                            <tc-checkbox   formControlName="recevoirParSMS">Recevoir par sms</tc-checkbox>
                        </tc-form-group>
                </div>
          </div>




    <tc-form-group>
        <tc-checkbox   formControlName="actif" >Actif</tc-checkbox>
    </tc-form-group>



                <span *ngIf="!alerteForm.valid && alerteForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                <button [disabled]="!alerteForm.valid && alerteForm.touched" tc-button [block]="true" >
                    Valider
                </button>

    </tc-card>

</div>
    </form>
    </div>
</ng-container>

