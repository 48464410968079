var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChronoAlerteService } from './chronoAlerte.service';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
var ChronoAlerteComponent = /** @class */ (function (_super) {
    __extends(ChronoAlerteComponent, _super);
    function ChronoAlerteComponent(store, httpSv, route, router, toastr, i18n, fb, auth, chronoAlerteService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.auth = auth;
        _this.chronoAlerteService = chronoAlerteService;
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            nom: 'Script Cron',
            cronTutorial: 'Cron Tutorial'
        };
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    ChronoAlerteComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.pageData.title = 'Chronogramme des alertes';
        this.setReactiveForm();
    };
    ChronoAlerteComponent.prototype.initReactiveForm = function () {
        this.chronoForm = this.fb.group({
            cron: ['', Validators.required],
            cronTutorial: ['']
        });
    };
    ChronoAlerteComponent.prototype.setReactiveForm = function () {
        if (this.chronogramme) {
            this.chronoForm.patchValue({
                cron: this.chronogramme.cron,
                cronTutorial: this.chronogramme.cronTutorial
            });
        }
    };
    ChronoAlerteComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getData(this.chronoAlerteService.getURL(), 'chronogramme', 'setLoaded');
    };
    ChronoAlerteComponent.prototype.ngAfterViewInit = function () {
    };
    ChronoAlerteComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ChronoAlerteComponent.prototype.mapFormValuesToModel = function () {
        this.chronogramme.cron = this.chronoForm.value.cron;
    };
    ChronoAlerteComponent.prototype.update = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.chronoAlerteService.update(this.chronogramme)
            .subscribe(function (data) {
            _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
            // this.router.navigate(['']);
        }, function (error) { return console.log(error); });
        this.chronoAlerteService.reconfigure()
            .subscribe(function (data) {
            _this.toastr.success('Redémarrage du service cron', '', { timeOut: 2000 });
            _this.router.navigate(['']);
        }, function (error) { return console.log(error); });
    };
    ChronoAlerteComponent.prototype.onSubmit = function () {
        this.update();
    };
    return ChronoAlerteComponent;
}(BasePageComponent));
export { ChronoAlerteComponent };
