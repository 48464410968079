import * as i0 from "@angular/core";
var GenreService = /** @class */ (function () {
    function GenreService() {
        this.baseUrl = '/api/genre';
    }
    GenreService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    GenreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GenreService_Factory() { return new GenreService(); }, token: GenreService, providedIn: "root" });
    return GenreService;
}());
export { GenreService };
