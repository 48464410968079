
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Acteur} from '../models/acteur';
import {UserService} from './user.service';


export class UsersDatasource implements DataSource<Acteur> {

    private administrateursSubject = new BehaviorSubject<Acteur[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Acteur[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private administrateurService: UserService) {

    }


    loadAdministrateurs(filter: string, userType: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.administrateurService.findAll(
         filter, userType,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(data => this.administrateursSubject.next(data)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Acteur[]> {
        return this.administrateursSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.administrateursSubject.complete();
        this.loadingSubject.complete();
    }

}

