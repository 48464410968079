import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import {Store} from '@ngrx/store';
import {BasePageComponent} from '../../base-page';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {DashboardService} from '../dashboard/dashboard.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {jqxDataTableComponent} from 'jqwidgets-ng/jqxdatatable';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';
import {MonitorAlerteService} from './monitorAlerte.service';
import {IOption} from '../../../ui/interfaces/option';
import DataTableExportSettings = jqwidgets.DataTableExportSettings;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-monitor-alerte',
  templateUrl: './monitorAlerte.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PageMonitorAlerteComponent extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit {
  rapportsForm: FormGroup;
  statutsAlerte = [{id: 0, nom: 'En attente'}, {id: 1, nom: 'Acquis'}, {id: 2, nom: 'En pause'}, {id: 3, nom: 'Bloqué'}, {id: 4, nom: 'Terminé'}, {id: 5, nom: 'Erreur'}, {id: 6, nom: 'Tous'}];
  statutAlerteAdapter: IOption[] = [];
  constructor(
    store: Store<IAppState>, private dashboardService: DashboardService,
    httpSv: HttpService, private monitorAlerteService: MonitorAlerteService,
     private fb: FormBuilder
  ) {
    super(store, httpSv);
    this.pageData = {
      title: 'MONITORING DES ALERTES PROGRAMMEES',
      breadcrumbs: [
      ],
    };
  }
  @ViewChild('myDataTable', { static: false }) myDataTable: jqxDataTableComponent;
  @ViewChild('dateDebut', { static: false }) dateDebut: jqxDateTimeInputComponent;
  @ViewChild('dateFin', { static: false }) dateFin: jqxDateTimeInputComponent;
  @ViewChild('log', { static: false }) log; ElementRef;
  Columnslabels =
      {
        dateDebut: 'Date début',
        dateFin: 'Date Fin'
      };
  source: any =
      { localData: []
      };
  dataAdapter: any;
  localizationObject = this.getLocalization();
  exportSettings = this.getExportSettings();
  columns: any[] =
      [
        { text: 'Tâche planifiée', dataField: 'tache_planifiee', width: '30%', cellsAlign: 'left', align: 'left' },
        { text: 'Etat', dataField: 'etat', width: '10%', cellsAlign: 'left', align: 'left' },
        { text: 'Exécution Précédente', dataField: 'precedent', width: '30%', align: 'right', cellsAlign: 'right', cellsFormat: 'F' },
        { text: 'Exécution Suivante', dataField: 'suivant', width: '30%', align: 'right', cellsAlign: 'right', cellsFormat: 'F' }
      ];
  getExportSettings(): DataTableExportSettings {
    const exportObj: DataTableExportSettings  = {};
    exportObj.columnsHeader = true;
    exportObj.hiddenColumns = false;
    exportObj.serverURL = null;
    exportObj.characterSet = null;
    exportObj.recordsInView = true;
    exportObj.fileName = 'rapport_alertes';
    return exportObj;
  }
  getLocalization(): any {
    const localizationobj: any = {};
    localizationobj.pagerGoToPageString = 'Aller à la page:';
    localizationobj.pagerShowRowsString = 'Voir ligne:';
    localizationobj.pagerRangeString = ' de ';
    localizationobj.pagerNextButtonString = 'Suivant';
    localizationobj.pagerFirstButtonString = 'Premier';
    localizationobj.pagerLastButtonString = 'Dernier';
    localizationobj.pagerPreviousButtonString = 'Précedent';
    localizationobj.sortAscendingString = 'Ordre Ascendent';
    localizationobj.sortDescendingString = 'Ordre Descendant';
    localizationobj.sortRemoveString = 'Supprimer';
    localizationobj.emptydatastring = 'Pas de données';
    localizationobj.firstDay = 1;
    localizationobj.percentSymbol = '%';
    localizationobj.currencySymbol = '';
      localizationobj.currencySymbolPosition = 'après';
      localizationobj.decimalSeparator = ',';
      localizationobj.thousandsSeparator = ' ';
      localizationobj.filterapplystring = 'Appliquer';
      localizationobj.filtercancelstring = 'Annuler';
      localizationobj.filterclearstring = 'Effacer Filtre';
      localizationobj.filterstring =  'Avancé';
      localizationobj.filtersearchstring = 'Chercher';
      localizationobj.filterstringcomparisonoperators = ['vide', 'non vide', 'contient', 'contient(respecte la casse)',
          'Ne contient pas', 'Ne contient pas(respecte la casse)', 'commence par', 'commence par(respecte la casse)',
          'finit par', 'finit par(respecte la casse)', 'égale', 'égale(respecte la casse)', 'null', 'non null'];
      // tslint:disable-next-line:max-line-length
      localizationobj.filternumericcomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
      // tslint:disable-next-line:max-line-length
      localizationobj.filterdatecomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
      localizationobj.filterbooleancomparisonoperators = ['égale', 'non égale'];
      localizationobj.validationstring = 'La valeur entrée n\'est pas valide ';
      localizationobj.emptydatastring =  'Pas de données à afficher';
      localizationobj.filterselectstring =  'Selectionnez un filtre';
      localizationobj.loadtext = 'Chargement...';
      localizationobj.clearstring = 'Effacer';
      localizationobj.todaystring = 'Aujourd\'hui';
      // tslint:disable-next-line:max-line-length
      localizationobj.loadingerrormessage = 'Chargement en cours des données vous ne pouvez pas mettre à jour les propriétés ou appeler une méthode . Vous pourrez le faire après le chargement des données.';
    localizationobj.decimalSeparator = ',';
    localizationobj.thousandsSeparator = ' ';
    localizationobj.days = {
      // full day names
      names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      // abbreviated day names
      namesAbbr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      // shortest day names
      namesShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
    };

    localizationobj.patterns = {
      d: 'dd/MM/yyyy',
      D: 'dddd, d. MMMM yyyy',
      t: 'HH:mm',
      T: 'HH:mm:ss',
      f: 'dddd, d. MMMM yyyy HH:mm',
      F: 'dddd, d. MMMM yyyy HH:mm:ss',
      M: 'dd MMMM',
      Y: 'MMMM yyyy'
    };
    localizationobj.months = {
      // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
      names: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre', ''],
      // abbreviated month names
      namesAbbr: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
    };
    return localizationobj;
  }
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
      return '95%';
    }

    return 850;
  }
  excelExport(): void {
    this.myDataTable.exportData('xls');
  }  xmlExport(): void {
    this.myDataTable.exportData('xml');
  }  csvExport(): void {
    this.myDataTable.exportData('csv');
  }  tsvExport(): void {
    this.myDataTable.exportData('tsv');
  }  htmlExport(): void {
    this.myDataTable.exportData('html');
  }  jsonExport(): void {
    this.myDataTable.exportData('json');
  }  pdfExport(): void {
    this.myDataTable.exportData('pdf');
  }
  printBtnOnClick(): void {
    const gridContent = this.myDataTable.exportData('html');
    const newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>ETAT MONITEUR ALERTE</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
    document.write(pageContent);
    document.close();
    newWindow.print();
  }

  ngOnInit() {
    super.ngOnInit();
      for (const statutAlerte of this.statutsAlerte) {
          this.statutAlerteAdapter.push({'value' : statutAlerte.id.toString(), 'label' : statutAlerte.nom});
      }

      this.initReactiveForm();

      this.setLoaded();

  }
  initReactiveForm() {
    this.rapportsForm = this.fb.group({
        statutAlerte:'6'
        }
    ) ;
  }
  ngAfterViewInit(): void {

      this.rapportsForm.get('statutAlerte').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.monitorAlerteService.getTriggers(selectedValue).subscribe(datas => {
                    this.source.localData.clear;
                    for (const data of datas) {
                      this.source.localData.push({tache_planifiee: data.tache_planifiee, etat:data.etat, precedent:new Date(data.precedent), suivant:new Date(data.suivant)})
                    }
                    this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.monitorAlerteService.getTriggers('6').subscribe(datas => {
          this.source.localData.clear;
                   for (const data of datas) {
                     this.source.localData.push({tache_planifiee: data.tache_planifiee, etat:data.etat, precedent:new Date(data.precedent), suivant:new Date(data.suivant)})
                }
              this.dataAdapter = new jqx.dataAdapter(this.source);
          }
      );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }



}
