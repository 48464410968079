export class LoginAuth  {
    username: string;
    access_token: string;
    password: string;
    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
        this.access_token = ' ';
    }
    toString(): string {
        return ' username : ' +
            this.username + ' password : ' + this.password
            + ' access_token : ' + this.access_token;
    }
}
