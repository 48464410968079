import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { DepotService } from '../depot.service';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {Depot} from '../../models/depot';
import {CommuneService} from '../../commune/commune.service';
import {Commune} from '../../models/commune';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Produit} from '../../models/produit';
import {ProduitService} from '../../produit/produit.service';
import {fadeIn} from '../../../../animations/form-error';
import {INoeud} from '../../produit/create';
import {MesureService} from '../../mesure/mesure.service';


@Component({
    selector: 'tc-depot-create',
    templateUrl: './create-depot.component.html',
    styleUrls: ['./create-depot.component.scss'],
    animations: [fadeIn]
})
export class PageCreateDepotComponent extends BasePageComponent implements OnInit, OnDestroy {
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private depotService: DepotService,
                 private route: ActivatedRoute, private mesureService: MesureService,
                 public router: Router, private toastr: ToastrService,
                 private communeService: CommuneService,
                 private fb: FormBuilder
    ) {
        super(store, httpSv);
        this.depot = new Depot();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des dépôts',
                    route: 'list-depot'
                }
            ]
        };
        this.initReactiveForm();
    }
    depotForm: FormGroup;
    depot: any;
    communeTree: INoeud[] = [];
    typologies: IOption[] = [];
    mesures: any[];
    mesuresAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            code: 'Code Dépôt',
            label: 'Libellé Dépôt',
            mobile: 'N° Mobile Contact',
            longitude: 'Longitude',
            latitude: 'Latitude',
            commune: 'Commune',
            uniteMesure: 'Unité de mesure'

        };
    static getDepot (jsonValue): Depot {
        return Depot.fromJSON(jsonValue) ;
    }

    onMesuresPromise() {
        if (this.mesures) {
            this.mesures.sort((a, b) => ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)));

            for (const mesure of this.mesures) {
                this.mesuresAdapter.push({'value': mesure.id.toString(), 'label': mesure.nom});
            }
        }
        this.setReactiveForm();
    }
    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.depot) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
       return  this.isEditing() ? ' Modifier un dépôt ' : ' Créer un dépôt ' ;
    }
    isEditing(): boolean {
         return (this.depot.id != null);
    }


   initReactiveForm() {
        this.depotForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                mobile: [''],
                mesure: [''],
                longitude: [''],
                latitude: [''],
                commune: [''],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        const commune =  (this.depot.communeTab != null) ? this.depot.communeTab : ['', '', ''] ;
        this.depotForm.patchValue({
            code: this.depot.code,
            nom: this.depot.nom,
            mobile: this.depot.mobile,
            mesure: this.depot.mesure,
            longitude: this.depot.longitude,
            latitude: this.depot.latitude,
            actif: this.depot.actif,
            commune: commune
            }
    );
    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.depotService.getURL(this.selectedId), 'depot', 'depot', 'setLoaded');
            } else {
                this.depot = new Depot();
                this.initReactiveForm();
            }
        });
        // detect form changes
        this.depotForm.valueChanges.subscribe(() => {
            this.changes = true;
        });
        this.communeService.findAsTree().subscribe(tree => {
                this.communeTree = tree;
            }
        );
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-depot']);
    }
    liste() {
        this.router.navigate(['/vertical/list-depot']);
    }
    mapFormValuesToModel() {
        this.depot.code =  this.depotForm.value.code;
        this.depot.nom =  this.depotForm.value.nom;
        this.depot.mobile =  this.depotForm.value.mobile;
        this.depot.longitude =  this.depotForm.value.longitude;
        this.depot.latitude =  this.depotForm.value.latitude;
        this.depot.actif =  this.depotForm.value.actif;
        this.depot.commune =  this.depotForm.value.commune[2];
        this.depot.mesure =  this.depotForm.value.mesure;

    }
    update(): void {
        if (this.depotForm.valid) {
        this.mapFormValuesToModel();
        this.depotService.update(this.depot.id, this.depot)
            .subscribe(
                data => {

                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/list-depot']);
                        }
                ,
                error => console.log(error));
        }

    }
    create(): void {
        if (this.depotForm.valid) {
            this.mapFormValuesToModel();
            this.depotService.create(this.depot)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie ' , '',
                            {timeOut: 2000});
                        this.depotForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
