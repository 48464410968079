/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./button.component";
var styles_TCDropdownButtonComponent = [i0.styles];
var RenderType_TCDropdownButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCDropdownButtonComponent, data: {} });
export { RenderType_TCDropdownButtonComponent as RenderType_TCDropdownButtonComponent };
export function View_TCDropdownButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dropdown-link-wrap"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_TCDropdownButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-dropdown-button", [], [[2, "tc-dropdown-link", null], [2, "active", null]], null, null, View_TCDropdownButtonComponent_0, RenderType_TCDropdownButtonComponent)), i1.ɵdid(1, 49152, null, 0, i2.TCDropdownButtonComponent, [i1.ElementRef], null, null)], null, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).active; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var TCDropdownButtonComponentNgFactory = i1.ɵccf("tc-dropdown-button", i2.TCDropdownButtonComponent, View_TCDropdownButtonComponent_Host_0, {}, { dropdownState: "dropdownState" }, ["*"]);
export { TCDropdownButtonComponentNgFactory as TCDropdownButtonComponentNgFactory };
