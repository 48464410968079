import {Produit} from './produit';

export class Categorie {
	id: number;
	code: string;
	nom: string;
	description: string;
	actif: boolean;

	static fromJSON(json: any): Categorie {
		const result = new Categorie();
		if (json != null) {
			for (const key in json) {
				result[key] = json[key];
			}
		}

		return result;
	}
	constructor() {
		this.actif = true;
	}
}
