<div class="tc-checkbox-wrap" (click)="switch(value)">
  <div class="tc-checkbox-handle" [ngStyle]="getStyles()">
    <input type="checkbox" class="checkbox-input" [value]="value" [attr.checked]="value">

    <span class="check-detector icofont-check-alt"></span>
  </div>

  <div class="tc-checkbox-label" [ngStyle]="getLabelColor()">
    <ng-content></ng-content>
  </div>
</div>
