import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ValiderOffreVenteService = /** @class */ (function () {
    function ValiderOffreVenteService(http) {
        this.http = http;
        this.baseUrl = '/api/offre_vente';
    }
    ValiderOffreVenteService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    ValiderOffreVenteService.prototype.getAllByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderOffreVenteService.prototype.getAllValidesByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_valides_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderOffreVenteService.prototype.getTopN = function (max) {
        return this.http.get(this.baseUrl + "/getTopN", {
            params: new HttpParams()
                .set('max', max)
        });
    };
    ValiderOffreVenteService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ValiderOffreVenteService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    ValiderOffreVenteService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    ValiderOffreVenteService.prototype.valider = function (obj) {
        return this.http.post(this.baseUrl + "/valider", obj);
    };
    ValiderOffreVenteService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    ValiderOffreVenteService.prototype.rejeter = function (obj) {
        return this.http.post(this.baseUrl + "/rejeter", obj);
    };
    ValiderOffreVenteService.prototype.abandonner = function (obj) {
        return this.http.post(this.baseUrl + "/abandonner", obj);
    };
    ValiderOffreVenteService.prototype.devalider = function (obj) {
        return this.http.post(this.baseUrl + "/devalider", obj);
    };
    ValiderOffreVenteService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    // tslint:disable-next-line:max-line-length
    ValiderOffreVenteService.prototype.getOffresValides = function (dateDebut, dateFin, produits, regions, reseau) {
        return this.http.get(this.baseUrl + "/valides", {
            params: new HttpParams()
                .set('dateDebut', dateDebut)
                .set('dateFin', dateFin)
                .set('produits', produits.toString())
                .set('regions', regions)
                .set('reseau', reseau)
        });
    };
    ValiderOffreVenteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValiderOffreVenteService_Factory() { return new ValiderOffreVenteService(i0.ɵɵinject(i1.HttpClient)); }, token: ValiderOffreVenteService, providedIn: "root" });
    return ValiderOffreVenteService;
}());
export { ValiderOffreVenteService };
