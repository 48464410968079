import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
const CACHE_KEY = 'sima-alerte-cache';
@Injectable({
  providedIn: 'root'
})
export class AlerteService {
	private baseUrl = '/api/alerte/short';
    private alertes;
  constructor(private http: HttpClient) {

  }

  getCached(): Observable<any[]> {
    this.alertes = this.http.get<any>(`${this.baseUrl}`)
        .pipe(
            map(data => data.items)
        );

    this.alertes.subscribe(next => {
          localStorage[CACHE_KEY] = JSON.stringify(next);
        }

    );
    this.alertes = this.alertes.pipe(
        startWith(JSON.parse(localStorage[CACHE_KEY] || '[]'))
    );
    return this.alertes;
  }
}

