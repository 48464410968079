import {Produit} from './produit';
import {Marche} from './marche';
import {Depot} from './depot';
import {Mesure} from './mesure';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {Acteur} from './acteur';
import {Qualite} from './qualite';

export class Reseau {
	id: number;
	code: string;
	nom: string;
	estFils: boolean;
	actif: boolean;
	typeSIM: string;
	adminPrincipal: Acteur;
	produits: Produit[];
	marches: Marche[];
	depots: Depot[];
	mesures: Mesure[];
	qualites: Qualite[];
	static fromJSON(json: any): Reseau {
		const result = new Reseau();
		for (const key in json) {
			result[key] = json[key];
		}

		return result;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const groupe = Reseau.fromJSON(jsonObject);
				jsonObject.produits = groupe.products;
				jsonObject.marches = groupe.marchesText;
				jsonObject.depots = groupe.depotText;
				jsonObject.mesures = groupe.mesureText;
				jsonObject.qualites = groupe.qualiteText;
	     		rval.push(jsonObject);
			}
		}

		return rval;
	}

	toString() {
		return this.nom;
	}
	get marchesText() {
		let rval = '';
		// Array:
		if (this.marches != null) {
			for (const marche of this.marches) {
				rval += marche.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get mesureText() {
		let rval = '';
		// Array:
		if (this.mesures != null) {
			for (const mesure of this.mesures) {
				rval += mesure.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get qualiteText() {
		let rval = '';
		// Array:
		if (this.qualites != null) {
			for (const qualite of this.qualites) {
				rval += qualite.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get isSIMBetail() {
		return (this.typeSIM === 'SIMBetail') ;
	}
	get isDGPER() {
		return  (this.typeSIM === 'DGPER');
	}
	get depotText() {
		let rval = '';
		// Array:
		if (this.depots != null) {
			for (const depot of this.depots) {
				rval += depot.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get products() {
		let rval = '';
		// Array:
		if (this.produits != null) {
			for (const produit of this.produits) {
				rval += produit.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get selectedProducts() {
		const rval = [];
		if (this.produits != null) {
			for (const produit of this.produits) {
				rval.push(produit.id.toString());
			}
		}
		return rval;
	}

	get selectedMarches() {
		const rval = [];
		if (this.marches != null) {
			for (const marche of this.marches) {
				rval.push(marche.id.toString());
			}
		}
		return rval;
	}
	get selectedDepots() {
		const rval = [];
		if (this.depots != null) {
			for (const depot of this.depots) {
				rval.push(depot.id.toString());
			}
		}
		return rval;
	}
	get selectedQualites() {
		const rval = [];
		if (this.qualites != null) {
			for (const qualite of this.qualites) {
				rval.push(qualite.id.toString());
			}
		}
		return rval;
	}
	get selectedMesures() {
		const rval = [];
		if (this.mesures != null) {
			for (const mesure of this.mesures) {
				rval.push(mesure.id.toString());
			}
		}
		return rval;
	}
	get nomAdminPrincipal() {
		if (this.adminPrincipal != null) {
			return this.adminPrincipal.nomCompletEtReseau;
		}
		return '';
	}

	constructor() {
		this.actif = true;
		this.estFils = true;
		this.produits = [];
	}
}
