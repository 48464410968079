/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./card.component";
var styles_TCCardComponent = [i0.styles];
var RenderType_TCCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCCardComponent, data: {} });
export { RenderType_TCCardComponent as RenderType_TCCardComponent };
function View_TCCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "card-img"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.img, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_TCCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_TCCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCCardComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCCardComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "card-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { "padding": 0 }), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.img; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _ck(_v, 8, 0, _co.padding); _ck(_v, 7, 0, currVal_2); }, null); }
export function View_TCCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-card", [], [[2, "tc-card", null], [2, "outline", null], [2, "bg-image", null], [2, "card-info", null], [2, "card-accent", null], [2, "card-success", null], [2, "card-warning", null], [2, "card-error", null], [2, "card-transparent", null], [2, "text-right", null], [2, "text-center", null], [4, "backgroundImage", null]], null, null, View_TCCardComponent_0, RenderType_TCCardComponent)), i1.ɵdid(1, 114688, null, 0, i3.TCCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).outline; var currVal_2 = i1.ɵnov(_v, 1).bgImg; var currVal_3 = i1.ɵnov(_v, 1).viewInfo; var currVal_4 = i1.ɵnov(_v, 1).viewAccent; var currVal_5 = i1.ɵnov(_v, 1).viewSuccess; var currVal_6 = i1.ɵnov(_v, 1).viewWarning; var currVal_7 = i1.ɵnov(_v, 1).viewError; var currVal_8 = i1.ɵnov(_v, 1).viewTransparent; var currVal_9 = i1.ɵnov(_v, 1).rightAlign; var currVal_10 = i1.ɵnov(_v, 1).centerAlign; var currVal_11 = i1.ɵnov(_v, 1).bgImage; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }); }
var TCCardComponentNgFactory = i1.ɵccf("tc-card", i3.TCCardComponent, View_TCCardComponent_Host_0, { outline: "outline", bgImg: "bgImg", align: "align", title: "title", img: "img", padding: "padding", view: "view", gradient: "tcGradient" }, {}, ["tc-card-header", "*"]);
export { TCCardComponentNgFactory as TCCardComponentNgFactory };
