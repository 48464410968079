<div class="line" *ngIf="align !== 'center'"></div>

<div class="timeline-box" *ngFor="let box of data">
  <div class="box-label" *ngIf="showLabels && box.sectionLabel">
    <tc-badge [view]="box.sectionLabel.view ? box.sectionLabel.view : 'default'">{{ box.sectionLabel.text }}</tc-badge>
  </div>

  <div class="box-items">
    <div class="item" *ngFor="let item of box.sectionData">
      <div class="icon-block">
        <div
          class="item-icon {{ item.icon || 'icofont-clock-time' }}"
          [ngStyle]="{ 'background': item.iconBg, 'color': item.iconColor }"
        ></div>
      </div>

      <div class="content-block">
        <div class="item-header">
          <h3 class="h5 item-title" *ngIf="item.title">{{ item.title }}</h3>

          <div class="item-date" *ngIf="item.date"><span>{{ item.date }}</span></div>
        </div>

        <div class="item-desc" [innerHTML]="item.content" *ngIf="item.content"></div>
      </div>
    </div>
  </div>
</div>
