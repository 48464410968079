import { OnInit, ElementRef, EventEmitter, OnChanges } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { state } from '../../interfaces/general';
var PROPERTIES_NAMES = ['prefix', 'suffix', 'prefixIcon', 'suffixIcon', 'placeholder'];
var TCPasswordInputComponent = /** @class */ (function () {
    function TCPasswordInputComponent(element) {
        this.element = element;
        this.eyeOpened = 'icofont-eye';
        this.eyeClosed = 'icofont-eye-blocked';
        this.onChange = function () { };
        this.onTouched = function () { };
        this.simpleInput = true;
        this.type = 'text';
        this.name = '';
        this.size = 'md';
        this.inputFocus = false;
        this.readonly = false;
        this.disabled = false;
        this.required = false;
        this.autoSize = false;
        this.innerValue = '';
        this.eyeStatus = this.eyeOpened;
        this.properties = {
            prefixValue: '',
            prefixColor: '',
            suffixValue: '',
            suffixColor: '',
            prefixIconValue: '',
            prefixIconColor: '',
            suffixIconValue: '',
            suffixIconColor: ''
        };
        this.states = state;
        this.blur = new EventEmitter();
        this.focus = new EventEmitter();
    }
    Object.defineProperty(TCPasswordInputComponent.prototype, "smSize", {
        get: function () {
            return this.size === 'sm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCPasswordInputComponent.prototype, "lgSize", {
        get: function () {
            return this.size === 'lg';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCPasswordInputComponent.prototype, "focused", {
        get: function () {
            return this.inputFocus;
        },
        enumerable: true,
        configurable: true
    });
    TCPasswordInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.changeCharLength(this.charLimiting, this.innerValue.length);
        if (this.autoSize) {
            setTimeout(function () {
                _this.resizable(_this.element.nativeElement.querySelector('.input-control'));
            });
        }
        this.setProperties(PROPERTIES_NAMES);
        this.setStyles(!this.disabled ? this.states.default : this.states.disabled);
    };
    TCPasswordInputComponent.prototype.ngOnChanges = function () {
        var _this = this;
        setTimeout(function () {
            _this.setStyles(!_this.disabled ? _this.states.default : _this.states.disabled);
        });
    };
    TCPasswordInputComponent.prototype.clickEye = function (event) {
        event.stopPropagation();
        console.log('J\'ai clique dans l\'oeil');
        this.showPassword = !this.showPassword;
        if (this.showPassword) {
            this.eyeStatus = this.eyeClosed;
        }
        else {
            this.eyeStatus = this.eyeOpened;
        }
    };
    Object.defineProperty(TCPasswordInputComponent.prototype, "value", {
        get: function () {
            return this.innerValue;
        },
        set: function (v) {
            if (v !== this.innerValue) {
                this.innerValue = v;
                this.onChange(v);
            }
            if (this.charLimiting > 0) {
                this.changeCharLength(this.charLimiting, this.innerValue.length);
            }
        },
        enumerable: true,
        configurable: true
    });
    TCPasswordInputComponent.prototype.changeCharLength = function (limit, valLength) {
        this.charLength = (limit - valLength >= 0) ? limit - valLength : 0;
    };
    TCPasswordInputComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    TCPasswordInputComponent.prototype.getTypeOfPassword = function () {
        return this.showPassword ? 'text' : 'password';
    };
    TCPasswordInputComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    TCPasswordInputComponent.prototype.writeValue = function (value) {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    };
    TCPasswordInputComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    TCPasswordInputComponent.prototype.onFocus = function (disabled) {
        if (!this.inputFocus && !disabled) {
            this.element.nativeElement.querySelector('.input-control').focus();
            this.inputFocus = true;
            this.focus.emit();
            this.setStyles(this.states.focus);
        }
    };
    TCPasswordInputComponent.prototype.onBlur = function (disabled) {
        this.inputFocus = false;
        if (!disabled) {
            this.blur.emit();
            this.onTouched();
            this.setStyles(this.states.default);
        }
    };
    TCPasswordInputComponent.prototype.onMouseEnter = function () {
        (!this.inputFocus && !this.disabled && !this.readonly) ? this.setStyles(this.states.hover) : null;
    };
    TCPasswordInputComponent.prototype.onMouseLeave = function () {
        (!this.inputFocus && !this.disabled && !this.readonly) ? this.setStyles(this.states.default) : null;
    };
    TCPasswordInputComponent.prototype.resizable = function (el, factor) {
        var INT = Number(factor) || 7.7;
        function resize() {
            el.parentElement.style.maxWidth = ((el.value.length * INT) + 4) + 'px';
        }
        var e = 'keyup, keypress, focus, blur, change'.split(',');
        for (var i = 0; i < e.length; i++) {
            el.addEventListener(e[i], resize, false);
        }
        resize();
    };
    TCPasswordInputComponent.prototype.setStyles = function (st, bg, border, color) {
        if (bg === void 0) { bg = this.bgColor; }
        if (border === void 0) { border = this.borderColor; }
        if (color === void 0) { color = this.color; }
        var styleIndex = 0;
        switch (st) {
            case this.states.hover:
                styleIndex = 1;
                break;
            case this.states.focus:
                styleIndex = 2;
                break;
            case this.states.disabled:
                styleIndex = 3;
                break;
            default:
                styleIndex = 0;
        }
        this.currentBgColor = bg instanceof Array ? bg[styleIndex] : bg;
        this.currentBorderColor = border instanceof Array ? border[styleIndex] : border;
        this.currentColor = color instanceof Array ? color[styleIndex] : color;
    };
    TCPasswordInputComponent.prototype.getStyles = function () {
        return {
            'background-color': this.currentBgColor,
            'border-color': this.currentBorderColor,
            'color': this.currentColor
        };
    };
    TCPasswordInputComponent.prototype.setProperties = function (properties) {
        var _this = this;
        properties.forEach(function (property) {
            var PROPERTY = _this[property];
            _this.properties[property + "Value"] = PROPERTY instanceof Array ? PROPERTY[0] : PROPERTY;
            _this.properties[property + "Color"] = PROPERTY instanceof Array ? PROPERTY[1] : null;
        });
    };
    return TCPasswordInputComponent;
}());
export { TCPasswordInputComponent };
