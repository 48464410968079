<ng-container *ngIf="offre && produitsAdapter.length>0 && mesuresAdapter.length>0 && qualitesAdapter.length>0 && regionsAdapter.length>0">
    <div class="row justify-content-center" >
        <div class="col-sm-9">
        <tc-card [title]="'SAISIE DES OFFRES D\'ACHATS' ">
            <form [formGroup]="offreForm"   (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-sm-4">
                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.date}} </tc-form-label>
                                <jqxDateTimeInput formControlName="date" #dateOffre
                                                  [culture]="'fr-FR'">
                                </jqxDateTimeInput>`

                                <ng-container *ngIf="offreForm.get('date').invalid && (offreForm.get('date').touched || offreForm.get('date').dirty)">
                                    <span @fadeIn class="error" *ngIf="offreForm.get('code').getError('required')">Veuillez saisir la date de l''offre</span>
                                </ng-container>
                            </tc-form-group>
                    </div>
                </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.delaiValidite}}</tc-form-label>
                                 <tc-input  type="number" formControlName="delaiValidite"  >
                                 </tc-input>

                                <ng-container *ngIf="offreForm.get('delaiValidite').invalid && (offreForm.get('delaiValidite').touched || offreForm.get('delaiValidite').dirty)">
                                    <span @fadeIn class="error" *ngIf="offreForm.get('delaiValidite').getError('required')">Veuillez saisir le délai de validité de l'offre</span>
                                    <span @fadeIn class="error" *ngIf="offreForm.get('delaiValidite').getError('min')">Le délai de validité doit être supérieur à zéro</span>
                                </ng-container>
                            </tc-form-group>
                        </div>
                        <div class="col-sm-4">
                            <tc-form-group>
                               <tc-form-label>{{Columnslabels.dateExpiration}}</tc-form-label>
                                <tc-input type="text" formControlName="dateExpiration" [readonly]="true" ></tc-input>

                                <ng-container *ngIf="offreForm.get('dateExpiration').invalid && (offreForm.get('dateExpiration').touched || offreForm.get('dateExpiration').dirty)">
                                    <span @fadeIn class="error" *ngIf="offreForm.get('dateExpiration').getError('required')">Veuillez saisir la date d'expiration de l'offre</span>
                                </ng-container>
                            </tc-form-group>
                        </div>
                    </div>

                    <div class="row">
                            <div class="col-sm-6">
                                  <tc-form-group>
                                        <tc-form-label>{{Columnslabels.produit}}</tc-form-label>
                                      <tc-select  [options]="produitsAdapter" [filter]="true" formControlName="produit" (ngModelChange)="onSelectProduit($event)">
                                      </tc-select>
                                        <ng-container *ngIf="offreForm.get('produit').invalid && (offreForm.get('produit').touched || offreForm.get('produit').dirty)">
                                            <span @fadeIn class="error" *ngIf="offreForm.get('produit').getError('required')">Veuillez sélectionner le produit</span>
                                        </ng-container>
                                    </tc-form-group>
                            </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                                <tc-form-group>
                                    <tc-form-label>{{Columnslabels.qualite}}</tc-form-label>
                                    <tc-select  [options]="qualitesAdapter" [filter]="true" formControlName="qualite">
                                    </tc-select>

                                    <ng-container *ngIf="offreForm.get('qualite').invalid && (offreForm.get('qualite').touched || offreForm.get('qualite').dirty)">
                                        <span @fadeIn class="error" *ngIf="offreForm.get('qualite').getError('required')">Veuillez sélectionner la qualité</span>
                                    </ng-container>
                                </tc-form-group>
                         </div>
                    </div>
                <div class="row">
                    <div class="col-sm-6">
                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.region}}</tc-form-label>
                            <tc-select  [options]="regionsAdapter" [filter]="true" formControlName="region">
                            </tc-select>

                            <ng-container *ngIf="offreForm.get('region').invalid && (offreForm.get('region').touched || offreForm.get('region').dirty)">
                                <span @fadeIn class="error" *ngIf="offreForm.get('region').getError('required')">Veuillez sélectionner la région</span>
                            </ng-container>
                        </tc-form-group>
                    </div>
                </div>
                            <div class="row">
                                        <div class="col-sm-3">
                                                    <tc-form-group>
                                                        <tc-form-label>{{Columnslabels.quantite}}</tc-form-label>
                                                        <tc-input  type="number" formControlName="quantite" (ngModelChange)="onMontantChange($event)" >
                                                        </tc-input>

                                                        <ng-container *ngIf="offreForm.get('quantite').invalid && (offreForm.get('quantite').touched || offreForm.get('quantite').dirty)">
                                                            <span @fadeIn class="error" *ngIf="offreForm.get('quantite').getError('required')">Veuillez saisir la quantité </span>
                                                            <span @fadeIn class="error" *ngIf="offreForm.get('quantite').getError('min')">La quantité doit être supérieure à zéro</span>
                                                        </ng-container>
                                                    </tc-form-group>
                                        </div>
                                <div class="col-sm-4">
                                    <tc-form-group>
                                        <tc-form-label>{{Columnslabels.mesure}}</tc-form-label>
                                        <tc-select  [options]="mesuresAdapter" [filter]="true" formControlName="mesure">
                                        </tc-select>
                                        <ng-container *ngIf="offreForm.get('mesure').invalid && (offreForm.get('mesure').touched || offreForm.get('mesure').dirty)">
                                            <span @fadeIn class="error" *ngIf="offreForm.get('mesure').getError('required')">Veuillez sélectionner l'unité de mesure</span>
                                        </ng-container>
                                    </tc-form-group>
                                </div>
                            </div>
                <div class="row">
                    <div class="col-sm-3">
                        <tc-form-group>

                            <label>{{Columnslabels.prixUnitaire}}
                                <input   currencyMask nz-input formControlName="prixUnitaire" (ngModelChange)="onMontantChange($event)"   [options]="{ prefix: '', thousands: '.', decimal: ',',precision: 0, nullable: true }"/>
                            </label>
                            <ng-container *ngIf="offreForm.get('prixUnitaire').invalid && (offreForm.get('prixUnitaire').touched || offreForm.get('prixUnitaire').dirty)" >
                                <span @fadeIn class="error" *ngIf="offreForm.get('prixUnitaire').getError('required')">Veuillez saisir le prix unitaire </span>
                                <span @fadeIn class="error" *ngIf="offreForm.get('prixUnitaire').getError('min')">Le prix unitaire doit être supérieure à zéro</span>
                            </ng-container>
                        </tc-form-group>
                    </div>
                    <div class="col-sm-4">
                        <tc-form-group>

                            <label>{{Columnslabels.montant}}
                                <input   currencyMask nz-input formControlName="montant"
                                         [options]="{ prefix: '', thousands: '.', decimal: ',',precision: 0, nullable: true }"/>
                            </label>

                            <ng-container *ngIf="offreForm.get('montant').invalid && (offreForm.get('montant').touched || offreForm.get('montant').dirty)">
                                <span @fadeIn class="error" *ngIf="offreForm.get('montant').getError('min')">Le montant doit être supérieure à zéro</span>
                            </ng-container>
                        </tc-form-group>
                    </div>
                </div>

                        <div class="row">

                            <div class="col-sm-3 col-12">
                                <tc-form-group>
                                    <tc-form-label>{{Columnslabels.telephone}}</tc-form-label>
                                    <tc-input  type="text" formControlName="telephone" (keydown)="mobilePhoneKeyUp.next($event)" >
                                    </tc-input>
                                    <ng-container *ngIf="offreForm.get('telephone').invalid && (offreForm.get('telephone').touched || offreForm.get('telephone').dirty)">
                                        <span @fadeIn class="error" *ngIf="offreForm.get('telephone').getError('required')">Veuillez saisir le numéro de téléphone de l'auteur</span>
                                    </ng-container>
                                </tc-form-group>
                            </div>

                            <div class="col-sm-6 col-12">
                                <tc-form-group>

                                    <tc-form-label>{{Columnslabels.nomAuteur}}</tc-form-label>
                                    <tc-input  type="text" formControlName="nomAuteur" >
                                    </tc-input>

                                    <ng-container *ngIf="offreForm.get('nomAuteur').invalid && (offreForm.get('nomAuteur').touched || offreForm.get('nomAuteur').dirty)">
                                        <span @fadeIn class="error" *ngIf="offreForm.get('nomAuteur').getError('required')">Veuillez saisir le nom de l'auteur</span>
                                    </ng-container>
                                </tc-form-group>
                            </div>
                        </div>
                <div class="row">
                    <div class="col-sm-9">
                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.email}}</tc-form-label>
                                <tc-input type="text"  formControlName="email">
                                </tc-input>
                                <ng-container *ngIf="offreForm.get('email').invalid && (offreForm.get('email').touched || offreForm.get('email').dirty)">
                                    <span @fadeIn class="error" *ngIf="offreForm.get('email').getError('required')">Veuillez saisir l'adresse e-mail' de l'auteur</span>
                                </ng-container>
                            </tc-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-9 ">
                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.conditions}}</tc-form-label>
                                <tc-textarea  [charLimiter]="{ counter: 255, before: 'Il reste ', after: ' caractères' }"
                                              [tcShape]="0" [rows]="3" formControlName="conditions"></tc-textarea>
                            </tc-form-group>

                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-9">
                <span *ngIf="!offreForm.valid && offreForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                <button [disabled]="!offreForm.valid" tc-button [block]="true" >
                    Valider
                </button>
                    </div>
                </div>
            </form>

        </tc-card>
    </div>
    </div>
</ng-container>

