var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QualiteService } from '../qualite/qualite.service';
import { MesureService } from '../mesure/mesure.service';
import { ProduitService } from '../produit/produit.service';
import { OffreAchatService } from './offre-achat.service';
import { OffreAchat } from '../models/offreAchat';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UserService } from '../administrateur/user.service';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { RegionService } from '../region/region.service';
var SaisieOffreAchatComponent = /** @class */ (function (_super) {
    __extends(SaisieOffreAchatComponent, _super);
    function SaisieOffreAchatComponent(store, httpSv, offreService, route, router, toastr, produitService, qualiteService, mesureService, administrateurService, regionService, i18n, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.offreService = offreService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.produitService = produitService;
        _this.qualiteService = qualiteService;
        _this.mesureService = mesureService;
        _this.administrateurService = administrateurService;
        _this.regionService = regionService;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.mobilePhoneKeyUp = new Subject();
        _this.mesures = [];
        _this.qualites = [];
        _this.produits = [];
        _this.regions = [];
        _this.regionsAdapter = [];
        _this.produitsAdapter = [];
        _this.mesuresAdapter = [];
        _this.qualitesAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            date: 'Date ',
            delaiValidite: 'Délai validité (jrs)',
            dateExpiration: 'Date Expiration',
            produit: 'Produit',
            quantite: 'Quantité',
            region: 'Région',
            mesure: 'Mesure',
            prixUnitaire: 'Prix Unitaire',
            qualite: 'Qualité',
            telephone: 'N° Mobile Acheteur',
            nomAuteur: 'Nom Acheteur ',
            email: 'e-Mail',
            actif: 'Actif',
            montant: 'Montant ',
            conditions: 'Conditions'
        };
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        moment.locale('fr');
        _this.initReactiveForm();
        _this.offre = new OffreAchat();
        return _this;
    }
    SaisieOffreAchatComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.isEditing()) {
            this.mobilePhoneSubscription = this.mobilePhoneKeyUp.pipe(debounceTime(100), distinctUntilChanged(), tap(function () {
                if (_this.offreForm.get('telephone').value != null) {
                    if (_this.offreForm.get('telephone').value.length === 12) {
                        _this.administrateurService.findByMobile(_this.offreForm.value.telephone).subscribe(function (acheteur) {
                            if (acheteur.nom !== undefined) {
                                _this.offreForm.patchValue({
                                    nomAuteur: acheteur.nom + ' ' + acheteur.prenom,
                                    email: acheteur.email
                                });
                            }
                        });
                    }
                }
            })).subscribe();
        }
    };
    SaisieOffreAchatComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.offre) {
            this.onProduitsPromise();
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    SaisieOffreAchatComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier une Offre d\'Achat ' : ' Créer une Offre d\'Achat ';
    };
    SaisieOffreAchatComponent.prototype.isEditing = function () {
        return (this.offre.id != null);
    };
    SaisieOffreAchatComponent.prototype.onProduitsPromise = function () {
        if (this.produits) {
            for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                var produit = _a[_i];
                this.produitsAdapter.push({ 'value': produit.id.toString(), 'label': produit.nom });
            }
        }
    };
    SaisieOffreAchatComponent.prototype.onRegionsPromise = function () {
        if (this.regions) {
            for (var _i = 0, _a = this.regions; _i < _a.length; _i++) {
                var region = _a[_i];
                this.regionsAdapter.push({ 'value': region.id.toString(), 'label': region.nom });
            }
        }
    };
    SaisieOffreAchatComponent.prototype.onQualitesPromise = function () {
        if (this.qualites) {
            for (var _i = 0, _a = this.qualites; _i < _a.length; _i++) {
                var qualite = _a[_i];
                this.qualitesAdapter.push({ 'value': qualite.id.toString(), 'label': qualite.nom });
            }
        }
    };
    SaisieOffreAchatComponent.prototype.onSelectProduit = function ($event) {
        var _this = this;
        if (this.offreForm.value.produit) {
            var produitSelected = this.produits.find(function (obj) { return obj.id.toString() === _this.offreForm.value.produit; });
            if (produitSelected) {
                this.offreForm.patchValue({ mesure: produitSelected.mesure.id.toString() });
            }
        }
    };
    SaisieOffreAchatComponent.prototype.onMontantChange = function ($event) {
        if (this.offreForm.value.quantite && this.offreForm.value.prixUnitaire) {
            this.offreForm.patchValue({ montant: this.offreForm.value.quantite * this.offreForm.value.prixUnitaire });
        }
    };
    SaisieOffreAchatComponent.prototype.onMesuresPromise = function () {
        if (this.mesures) {
            for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
                var mesure = _a[_i];
                this.mesuresAdapter.push({ 'value': mesure.id.toString(), 'label': mesure.nom });
            }
        }
    };
    SaisieOffreAchatComponent.prototype.initReactiveForm = function () {
        var _this = this;
        var dateDuJour = new Date();
        this.offreForm = this.fb.group({
            date: [dateDuJour, Validators.required],
            dateExpiration: [moment(dateDuJour).add(14, 'days').format('L')],
            delaiValidite: [14, [Validators.required, Validators.min(0)]],
            produit: ['', Validators.required],
            mesure: ['', Validators.required],
            region: ['', Validators.required],
            quantite: ['', [Validators.required, Validators.min(0)]],
            qualite: [''],
            email: ['', [Validators.email]],
            telephone: ['', Validators.required],
            nomAuteur: ['', Validators.required],
            montant: ['', Validators.min(0)],
            prixUnitaire: ['', Validators.min(0)],
            conditions: ['']
        });
        this.offreForm.get('delaiValidite').valueChanges.subscribe(function (val) {
            if (_this.dateOffre !== undefined) {
                _this.offreForm.get('dateExpiration').setValue(moment(_this.dateOffre.getDate()).add(val, 'days').format('L'));
            }
        });
    };
    SaisieOffreAchatComponent.prototype.setReactiveForm = function () {
        var dateDuJour = new Date(this.offre.date);
        this.offreForm.patchValue({
            qualite: (this.offre.qualite != null) ? this.offre.qualite.id.toString() : '',
            region: (this.offre.region != null) ? this.offre.region.id.toString() : '',
            actif: this.offre.actif,
            quantite: this.offre.quantite,
            email: this.offre.email,
            date: dateDuJour,
            telephone: this.offre.telephone,
            nomAuteur: this.offre.nomAuteur,
            delaiValidite: this.offre.delaiValidite,
            dateExpiration: this.offre.dateExpiration,
            montant: this.offre.montant,
            prixUnitaire: this.offre.prixUnitaire,
            conditions: this.offre.conditions,
            mesure: (this.offre.mesure != null) ? this.offre.mesure.id.toString() : '',
            produit: (this.offre.produit != null) ? this.offre.produit.id.toString() : ''
        });
    };
    SaisieOffreAchatComponent.prototype.getOffre = function (jsonValue) {
        return OffreAchat.fromJSON(jsonValue);
    };
    SaisieOffreAchatComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getData(this.qualiteService.getAllURL(), 'qualites', 'onQualitesPromise');
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.getData(this.produitService.getAllURL(), 'produits', 'onProduitsPromise');
        this.getData(this.regionService.getAllURL(), 'regions', 'onRegionsPromise');
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.offreService.getURL(_this.selectedId), 'offre', 'offreAchat', 'setLoaded');
            }
            else {
                _this.initReactiveForm();
            }
        });
    };
    SaisieOffreAchatComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    SaisieOffreAchatComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-offre']);
    };
    SaisieOffreAchatComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/modifier-les-offres-achats']);
    };
    SaisieOffreAchatComponent.prototype.mapFormValuesToModel = function () {
        this.offre.quantite = this.offreForm.value.quantite;
        this.offre.email = this.offreForm.value.email;
        this.offre.telephone = this.offreForm.value.telephone;
        this.offre.date = this.offreForm.value.date;
        //  this.offre.date = this.dateOffre.getDate();
        this.offre.delaiValidite = this.offreForm.value.delaiValidite;
        this.offre.dateExpiration = this.offreForm.value.dateExpiration;
        this.offre.montant = this.offreForm.value.montant;
        this.offre.qualite = this.offreForm.value.qualite;
        this.offre.region = this.offreForm.value.region;
        this.offre.prixUnitaire = this.offreForm.value.prixUnitaire;
        this.offre.mesure = this.offreForm.value.mesure;
        this.offre.produit = this.offreForm.value.produit;
        this.offre.conditions = this.offreForm.value.conditions;
        this.offre.nomAuteur = this.offreForm.value.nomAuteur;
    };
    SaisieOffreAchatComponent.prototype.update = function () {
        var _this = this;
        if (this.offreForm.valid) {
            this.mapFormValuesToModel();
            this.offreService.update(this.offre.id, this.offre)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.liste();
            });
        }
    };
    SaisieOffreAchatComponent.prototype.create = function () {
        var _this = this;
        if (this.offreForm.valid) {
            this.mapFormValuesToModel();
            this.offreService.create(this.offre)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie  ', '', { timeOut: 2000 });
                _this.offreForm.reset();
                var dateDuJour = new Date();
                _this.offreForm.get('date').setValue(dateDuJour);
                _this.offreForm.get('delaiValidite').setValue(14);
            }, function (error) { return console.log(error); });
        }
    };
    SaisieOffreAchatComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return SaisieOffreAchatComponent;
}(BasePageComponent));
export { SaisieOffreAchatComponent };
