
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Province} from '../models/province';
import {ProvinceService} from './province.service';


export class ProvincesDatasource implements DataSource<Province> {

    private provincesSubject = new BehaviorSubject<Province[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Province[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private provinceService: ProvinceService) {

    }


    loadProvinces(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.provinceService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(provinces => this.provincesSubject.next(provinces)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Province[]> {
        return this.provincesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.provincesSubject.complete();
        this.loadingSubject.complete();
    }

}

