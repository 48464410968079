import { HttpClient, HttpParams } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CACHE_KEY = 'sima-reseaux-cache';
var ReseauService = /** @class */ (function () {
    function ReseauService(http) {
        this.http = http;
        this.baseUrl = '/api/reseau';
    }
    ReseauService.prototype.findAll = function (filter, sortField, sortOrder, offset, pageSize) {
        if (filter === void 0) { filter = ''; }
        if (sortField === void 0) { sortField = 'nom'; }
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.get('/api/reseau/pages', {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sortField)
                .set('sortOrder', sortOrder)
                .set('offset', offset.toString())
                .set('max', pageSize.toString())
        });
    };
    ReseauService.prototype.findCount = function () {
        return this.http.get('/api/reseau/count')
            .pipe(map(function (res) { return res['total']; }));
    };
    ReseauService.prototype.findAsTree = function () {
        return this.http.get(this.baseUrl + "/tree")
            .pipe(map(function (res) { return res['tree']; }));
    };
    ReseauService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    ReseauService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ReseauService.prototype.getCachedURL = function () {
        return this.baseUrl + "/short";
    };
    ReseauService.prototype.getAllURLOffLine = function () {
        return "/offLine/reseaux";
    };
    ReseauService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    ReseauService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    ReseauService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    ReseauService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    ReseauService.prototype.getCached = function () {
        this.reseaux = this.http.get(this.baseUrl + "/short")
            .pipe(map(function (data) { return data.items; }));
        this.reseaux.subscribe(function (next) {
            localStorage[CACHE_KEY] = JSON.stringify(next);
        });
        this.reseaux = this.reseaux.pipe(startWith(JSON.parse(localStorage[CACHE_KEY] || '[]')));
        return this.reseaux;
    };
    ReseauService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReseauService_Factory() { return new ReseauService(i0.ɵɵinject(i1.HttpClient)); }, token: ReseauService, providedIn: "root" });
    return ReseauService;
}());
export { ReseauService };
