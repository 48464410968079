<div class="row" >
            <tc-card [title]="''">

                        <div class="row">
                            <button tc-button (click)="downloadFile()">Télécharger le guide d'utilisation de la plateforme </button>
                        </div>
            </tc-card>

</div>

