import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserLoggerService} from './user-logger.service';
import {LoginAuth} from '../../pages/ui/models/loginAuth';
import {BehaviorSubject, Observable} from 'rxjs';
import {Acteur} from '../../pages/ui/models/acteur';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    loginAuth: LoginAuth;
    private currentUserSubject: BehaviorSubject<LoginAuth>;
    private currentUserDataSubject: BehaviorSubject<Acteur>;
    public currentUser: Observable<LoginAuth>;
  constructor(
      private httpClient: HttpClient,
      private userLogger: UserLoggerService
  ) {
      this.currentUserSubject = new BehaviorSubject<LoginAuth>(JSON.parse(localStorage.getItem('currentUser')));
      this.currentUserDataSubject = new BehaviorSubject<Acteur>(JSON.parse(localStorage.getItem('currentUserData')));
      this.currentUser = this.currentUserSubject.asObservable();
  }
    public get currentUserValue(): LoginAuth {
        return this.currentUserSubject.value;
    }
  public setCurrentObject(user) {
       this.currentUserSubject.next(user);
  }
    public get currentUserDataObject() {
       return this.currentUserDataSubject.value;

    }

    public setCurrentUserDataObject(userData) {
        this.currentUserDataSubject.next(userData);
    }
   authenticate(username, password) {
       this.loginAuth = new LoginAuth(username, password);
       return  this.userLogger.create(this.loginAuth);
   }

  logout() {
        // remove user from local storage and set current user to null
        sessionStorage.removeItem('currentUser');
        sessionStorage.removeItem('currentUserData');
        this.currentUserSubject.next(null);
        this.currentUserDataSubject.next(null);
    }

}
