var TCShapeDirective = /** @class */ (function () {
    function TCShapeDirective() {
    }
    Object.defineProperty(TCShapeDirective.prototype, "getShape", {
        get: function () {
            var SHAPE = this.tcShape;
            return (typeof SHAPE === 'number') ? SHAPE + 'px' : SHAPE;
        },
        enumerable: true,
        configurable: true
    });
    return TCShapeDirective;
}());
export { TCShapeDirective };
