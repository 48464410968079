import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ValiderStockService = /** @class */ (function () {
    function ValiderStockService(http) {
        this.http = http;
        this.baseUrl = '/api/stock';
    }
    ValiderStockService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    ValiderStockService.prototype.getAllByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderStockService.prototype.getAllValidesByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_valides_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderStockService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ValiderStockService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    ValiderStockService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    ValiderStockService.prototype.devalider = function (obj) {
        return this.http.post(this.baseUrl + "/devalider", obj);
    };
    ValiderStockService.prototype.valider = function (obj) {
        return this.http.post(this.baseUrl + "/valider", obj);
    };
    ValiderStockService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    ValiderStockService.prototype.rejeter = function (obj) {
        return this.http.post(this.baseUrl + "/rejeter", obj);
    };
    ValiderStockService.prototype.abandonner = function (obj) {
        return this.http.post(this.baseUrl + "/abandonner", obj);
    };
    ValiderStockService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    // tslint:disable-next-line:max-line-length
    ValiderStockService.prototype.getStocksValides = function (dateDebut, dateFin, depots, produits, reseau, mesures) {
        return this.http.get(this.baseUrl + "/valides", {
            params: new HttpParams()
                .set('dateDebut', dateDebut)
                .set('dateFin', dateFin)
                .set('depots', depots.toString())
                .set('produits', produits.toString())
                .set('reseau', reseau)
                .set('mesures', mesures.toString())
        });
    };
    ValiderStockService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValiderStockService_Factory() { return new ValiderStockService(i0.ɵɵinject(i1.HttpClient)); }, token: ValiderStockService, providedIn: "root" });
    return ValiderStockService;
}());
export { ValiderStockService };
