import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {
    FormGroup} from '@angular/forms';
import {fadeIn} from '../../../animations/form-error';

export interface IchangePassword {
    status: string;
}

@Component({
    selector: 'tc-reset-cache',
    templateUrl: './reset-localCache.component.html',
    styleUrls: ['./reset-localCache.component.scss'],
    animations: [fadeIn]
})
export class PageResetLocalCacheComponent extends BasePageComponent implements OnInit,  OnDestroy {
    SERVER_URL = 'api/upload_avatar';
    constructor(store: Store<IAppState>,
                httpSv: HttpService,
                private route: ActivatedRoute,
                public router: Router, private toastr: ToastrService,
                private i18n: NzI18nService

    ) {
        super(store, httpSv);
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);
        this.initReactiveForm();

    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
     }

   initReactiveForm() {

    }

    ngOnInit() {
        super.ngOnInit();

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    onResetCache() {
        window.localStorage.clear();
        this.toastr.success('Succès de la réinitialisation du cache ', '',
            {timeOut: 2000});
        this.router.navigate(['']);

    }

}
