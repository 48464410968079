import {SecteurActivite} from './secteurActivite';

export class Activite {
	id: number;
	code: string;
	nom: string;
	commentaire: string;
	actif: boolean;
	secteurActivite: SecteurActivite;
	get nomSecteurActivite() {
		if (this.secteurActivite != null) {
			return this.secteurActivite.nom;
		}
		return '--';
	}
	static fromJSON(json: any): Activite {
		const result = new Activite();
		for (const key in json) {
			result[key] = json[key];
		}

		return result;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const activite = Activite.fromJSON(jsonObject);
				if (activite.secteurActivite) {
					jsonObject.secteurActivite = activite.secteurActivite.nom;
				}

				rval.push(jsonObject);
			}
		}
		return rval;
	}
	constructor() {
		this.actif = true;
		this.secteurActivite = new SecteurActivite();
	}
}
