var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { Marche } from '../models/marche';
import { UserService } from '../administrateur/user.service';
var PageMarketMapsComponent = /** @class */ (function (_super) {
    __extends(PageMarketMapsComponent, _super);
    function PageMarketMapsComponent(store, httpSv, adminService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.adminService = adminService;
        _this.marchesAdapter = [];
        _this.pageData = {
            title: 'Carte des marchés',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Tableau de bord',
                    route: 'dashboard'
                }
            ],
            fullFilled: true
        };
        _this.lng = -2.9197048;
        _this.lat = 12.5324634;
        return _this;
    }
    PageMarketMapsComponent.prototype.getMarche = function (jsonValue) {
        return Marche.fromJSON(jsonValue);
    };
    PageMarketMapsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.adminService.mesMarches().subscribe(function (marches) {
            _this.marches = marches;
            _this.marchesAdapter = [];
            for (var _i = 0, _a = _this.marches; _i < _a.length; _i++) {
                var marche = _a[_i];
                _this.marchesAdapter.push({ id: marche.id, nom: marche.nom, longitude: marche.longitude, latitude: marche.latitude });
            }
            _this.setLoaded();
        });
    };
    PageMarketMapsComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return PageMarketMapsComponent;
}(BasePageComponent));
export { PageMarketMapsComponent };
