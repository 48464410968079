<ng-container *ngIf="type === 'horizontal'">
  <div class="left-part d-flex align-items-center">
    <span class="animated-bg d-block d-lg-none navbar-button"></span>
    <span class="animated-bg bg-1 d-none d-lg-block sk-logo"></span>
    <span class="animated-bg bg-4 search d-none d-md-block"></span>
  </div>

  <div class="right-part d-flex align-items-center">
    <div class="icon-box">
      <span class="icon bg-4 animated-bg"></span>
      <span class="badge"></span>
    </div>
    <span class="avatar bg-4 animated-bg"></span>
  </div>
</ng-container>

<ng-container *ngIf="type === 'vertical'">
  <div class="top-part">
    <div class="logo-block">
      <div class="sk-logo bg-1 animated-bg"></div>
    </div>

    <div class="sk-menu">
      <div class="sk-button bg-2 animated-bg"></div>
      <span class="sk-menu-item bg animated-bg w-75"></span>
      <span class="sk-menu-item bg animated-bg w-60"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
    </div>

    <div class="sk-menu">
      <div class="sk-group-item bg-3 animated-bg w-80 mb-4"></div>
      <span class="sk-menu-item bg animated-bg w-75"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
      <span class="sk-menu-item bg animated-bg w-75"></span>
      <span class="sk-menu-item bg animated-bg w-75"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
      <span class="sk-menu-item bg animated-bg w-50"></span>
    </div>

    <div class="sk-menu">
      <div class="sk-group-item bg-3 animated-bg w-60 my-4"></div>
      <span class="sk-menu-item bg animated-bg"></span>
      <span class="sk-menu-item bg animated-bg w-75"></span>
      <span class="sk-menu-item bg animated-bg w-75"></span>
      <span class="sk-menu-item bg animated-bg "></span>
    </div>
  </div>

  <div class="horizontal-menu">
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
    <span class="sk-menu-item bg animated-bg"></span>
  </div>
</ng-container>
