import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import {ActivatedRoute, Route, Router} from '@angular/router';
import { ActiviteService } from '../activite.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import {ExcelService} from '../../../../services/sharedServices/excel-service.service';
import {Activite} from '../../models/activite';
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {ActivitesDataSource} from '../activites.datasource';
import {fromEvent, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {NzModalService} from 'ng-zorro-antd';


@Component({
  selector: 'tc-activite-index',
  templateUrl: './activite-index.component.html',
 styleUrls: ['./activite-index.component.scss']
})
export class PageIndexActiviteComponent extends BasePageComponent implements  OnInit, AfterViewInit, OnDestroy {
	rows: any[];
    productCount = 0;
    dataSource: ActivitesDataSource;
    sortField = 'nom';
    pageIndex = 0;
    pageSize = 5;
    // MatPaginator Output
    displayedColumns = ['code', 'nom', 'secteurActivite', 'actions'];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @ViewChild(MatSort, { static: false }) sort: MatSort;

    @ViewChild('input', { static: false }) input: ElementRef;
   constructor( store: Store<IAppState>,
	httpSv: HttpService, private activiteService: ActiviteService,
	public dialog: MatDialog,
    private router: Router, private modalService: NzModalService
	) {
		super(store, httpSv);
  this.pageData = {
      subTitle: '' ,
      title: 'LISTE DES ACTIVITES',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Tableau de bord',
          route: 'dashboard'
        }
      ]
	};
	this.rows = [];
}


  ngOnInit() {
	   super.ngOnInit();
      this.dataSource = new ActivitesDataSource(this.activiteService);
      this.activiteService.findCount().subscribe(count => {
              this.productCount = count;

              if (sessionStorage.getItem('activitePageIndex') != null) {
                  this.pageIndex = +sessionStorage.getItem('activitePageIndex');
              } else {
                  this.pageIndex = 0 ;
              }
          if (sessionStorage.getItem('activitePageSize') != null) {
              this.pageSize = (+sessionStorage.getItem('activitePageSize') > 0) ? +sessionStorage.getItem('activitePageSize') : 5;
          } else {
              this.pageSize = 5 ;
          }
          this.paginator.pageIndex = this.pageIndex ;
          this.paginator.pageSize = this.pageSize ;
          this.dataSource.loadActivites('', this.sortField, 'asc', this.pageIndex, this.pageSize);

          }
      );

  }
    ngAfterViewInit() {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                   this.paginator.pageIndex = 0;
                    this.loadPage();
                })
            )
            .subscribe();

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadPage())
            ).subscribe();
        this.paginator.page.subscribe(
            (page) => {
                this.pageIndex = page.pageIndex;
                this.pageSize = page.pageSize;
                sessionStorage.setItem('activitePageIndex', page.pageIndex) ;
                if (page.pageSize > 0) {
                sessionStorage.setItem('activitePageSize', page.pageSize) ;
                }
            }

        );

    }

    loadPage() {
        this.dataSource.loadActivites(
            this.input.nativeElement.value,
            this.sortField,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    setLoaded(during: number = 0) {
       super.setLoaded(during);
     }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  edit(id: any) {
   	   this.router.navigate(['/vertical/create-activite', { id: id}]);
  }
   ajouter() {
	  this.router.navigate(['/vertical/create-activite']);
  }
    exportByExcel() {
        ExcelService.exportAsExcelFile(Activite.listFromJSON(this.rows), 'activites');
    }
    exportAsXLSX() {
        this.getData(this.activiteService.getAllURL(), 'rows', 'exportByExcel');
    }
    getActivite (jsonValue): Activite {
        return Activite.fromJSON(jsonValue) ;
    }


    delete(id: number): void {
        this.modalService.confirm({
            nzTitle: 'Confirmez-vous la suppression de l\'objet ?',
            nzContent: '<b style="color: red;">Attention opération irréversible !</b>',
            nzOkText: 'Oui',
            nzOkType: 'danger',
            nzOnOk: () =>  {this.activiteService.delete(id)
                .subscribe(
                    data => {
                        this.loadPage();
                    },
                    error => console.log(error));
            },
            nzCancelText: 'Non',
            nzOnCancel: () => console.log('Cancel')
        });
    }


  }

