import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {Marche} from '../models/marche';
import { FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProduitService} from '../produit/produit.service';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {IOption} from '../../../ui/interfaces/option';
import {MesureService} from '../mesure/mesure.service';
import {Mesure} from '../models/mesure';
import {PrixService} from './prix.service';
import {fadeIn} from '../../../animations/form-error';
import {UserService} from '../administrateur/user.service';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';

@Component({
  selector: 'tc-saisie-prix',
    templateUrl: './saisie-prix.component.html',
    styleUrls: ['./saisie-prix.component.scss'],
    animations: [fadeIn]
})

export class SaisiePrixComponent extends BasePageComponent implements  OnInit, OnDestroy, AfterViewInit {

  constructor( store: Store<IAppState>,
               httpSv: HttpService, private marcheService: MarcheService,
               private route: ActivatedRoute,
               public router: Router, private toastr: ToastrService,
               private produitService: ProduitService, private mesureService: MesureService,
               private fb: FormBuilder, private i18n: NzI18nService, private prixService: PrixService,
               private adminService: UserService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: 'Saisie des prix',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
   this.marches = [];
  }

  SelectedMarketId: string;
  data: any;
  marches: Marche[];
  marchesAdapter: IOption[] = [];
  mesures: Mesure[];
  mesuresAdapter: IOption[] = [];
  produits: any[];
    editCache: { [key: string]: any } = {};
    listOfData: any[] = [];
    outData: any[] = [];
    @ViewChild('datePrix', {static: false}) mydatePrix: jqxDateTimeInputComponent;
    dateTimeInputSettings: jqwidgets.DateTimeInputOptions = {
        formatString: 'd',  width: '200px', height: '25px'
    };
    ngAfterViewInit(): void {
     //   this.mydatePrix.createComponent(this.dateTimeInputSettings);

    }
    saveEdit(id: string): void {
        const index = this.listOfData.findIndex(item => item.id === id);
        Object.assign(this.listOfData[index], this.editCache[id].data);
        this.editCache[id].edit = true;
    }

    updateEditCache(): void {
        this.listOfData.forEach(item => {
            this.editCache[item.id] = {
                edit: true,
                data: { ...item }
            };
        });
    }



    enregistrer() {
        // const result = this.editCache.filter(o => (o.prixGros > 0) || (o.prixDetail > 0));
        // console.log ('Filtre elements modifiés', [this.editCache, result]);
        this.outData = [];
        this.listOfData.forEach(item => {
            if ((this.editCache[item.id].data.prixGros > 0) || (this.editCache[item.id].data.prixDetail > 0)) {
            this.outData.push(this.editCache[item.id].data);
           }
        });
         this.data = {data: {
            datePrix:  this.mydatePrix.getDate().toLocaleDateString('en-US'),
            prix: this.outData,
            marche: this.SelectedMarketId
        }};
        this.prixService.create(this.data) .subscribe(
            data => {
                this.toastr.success('Enregistrement réussie des prix ', '',
                    {timeOut: 2000});
                this.onProduitsPromise();
            },
            error => console.log(error));
    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);

        this.adminService.mesMarches().subscribe(marches => {
            this.marches = marches ;
            this.marchesAdapter = [];
            for (const marche of this.marches) {
                this.marchesAdapter.push({'value' : marche.id.toString(), 'label' : marche.nom});
            }
          //  this.setLoaded();
        });

    }

    getCodeMesure(idMesure: string): string {
        const  mesureSelected: Mesure = this.mesures.find(obj => obj.id.toString() === idMesure);
        return mesureSelected.code;
    }
    onMesuresPromise() {
        for (const mesure of this.mesures) {
            this.mesuresAdapter.push({'value' : mesure.id.toString(), 'label' : mesure.code});
        }
    }
    onProduitsPromise() {
        this.listOfData = [];
        this.produits.sort((a, b) =>  (a.nom > b.nom) ? 1 : 0);
        this.produits.forEach(produit => {
            this.listOfData.push({
                id: produit.id,
                nomProduit: produit.nom,
                prixGros: '',
                mesureGros: produit.mesure.id.toString(),
                prixDetail: '',
                mesureDetail: produit.mesure.id.toString()
            });
        });
        this.updateEditCache();
    }
  ngOnInit() {
    super.ngOnInit();
    this.SelectedMarketId = '1' ;
      this.adminService.mesMarches().subscribe(marches => {
          this.marches = marches ;
          this.setLoaded();
      });
    this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
      this.listOfData = [];
      this.produitService.getByReseaux().subscribe(produits => {
           this.produits = produits ;
           this.onProduitsPromise();
      });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
