var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { MarcheService } from '../marche/marche.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { ExcelService } from '../../../services/sharedServices/excel-service.service';
import { ValiderEtalonnageService } from '../valider-etalonnages/validerEtalonnage.service';
var ListeEtalonnageValidesComponent = /** @class */ (function (_super) {
    __extends(ListeEtalonnageValidesComponent, _super);
    function ListeEtalonnageValidesComponent(store, httpSv, marcheService, route, authService, router, toastr, fb, i18n, validerEtalonnageService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.marcheService = marcheService;
        _this.route = route;
        _this.authService = authService;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.validerEtalonnageService = validerEtalonnageService;
        _this.dateValidation = new Date();
        _this.etalonnagesValides = [];
        _this.outData = [];
        /******************/
        _this.isAllDisplayDataChecked = false;
        _this.isOperating = false;
        _this.isIndeterminate = false;
        _this.listOfDisplayData = [];
        _this.listOfAllDataEtalonnage = [];
        _this.mapOfCheckedId = {};
        _this.numberOfChecked = 0;
        _this.pageData = {
            title: '',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        return _this;
    }
    /******************************/
    ListeEtalonnageValidesComponent.prototype.devalider = function () {
        var _this = this;
        this.outData = [];
        this.listOfAllDataEtalonnage.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                etalonnages: this.outData
            } };
        if (this.outData.length > 0) {
            this.validerEtalonnageService.devalider(this.data).subscribe(function (data) {
                _this.toastr.success('Dé-Validation réussie des étalonnages selectionnées', '', { timeOut: 2000 });
                _this.loadStock();
            }, function (error) { return console.log(error); });
        }
        else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    };
    ListeEtalonnageValidesComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadStock();
    };
    ListeEtalonnageValidesComponent.prototype.loadStock = function () {
        var _this = this;
        this.listOfAllDataEtalonnage = [];
        this.validerEtalonnageService.getAllValides().subscribe(function (data) {
            _this.etalonnagesValides = data;
            _this.etalonnagesValides.forEach(function (etalonnage, index, array) {
                _this.listOfAllDataEtalonnage.push({
                    id: etalonnage.id,
                    uml: etalonnage.uml,
                    marche: etalonnage.marche,
                    ul: etalonnage.ul,
                    valeur: etalonnage.valeur
                });
            });
        });
        this.mapOfCheckedId = {};
        this.refreshStatus();
    };
    ListeEtalonnageValidesComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ListeEtalonnageValidesComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    };
    ListeEtalonnageValidesComponent.prototype.refreshStatus = function () {
        var _this = this;
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(function (item) { return _this.mapOfCheckedId[item.id]; });
        this.isIndeterminate =
            this.listOfDisplayData.some(function (item) { return _this.mapOfCheckedId[item.id]; }) &&
                !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.etalonnagesValides.filter(function (item) { return _this.mapOfCheckedId[item.id]; }).length;
    };
    ListeEtalonnageValidesComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfDisplayData.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = value); });
        this.refreshStatus();
    };
    ListeEtalonnageValidesComponent.prototype.operateData = function () {
        var _this = this;
        this.isOperating = true;
        setTimeout(function () {
            _this.etalonnagesValides.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = false); });
            _this.refreshStatus();
            _this.isOperating = false;
        }, 1000);
    };
    ListeEtalonnageValidesComponent.prototype.exportByExcel = function () {
        ExcelService.exportAsExcelFile(this.etalonnagesValides, 'Etalonnages_valides');
    };
    return ListeEtalonnageValidesComponent;
}(BasePageComponent));
export { ListeEtalonnageValidesComponent };
