import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PushSMSGroupeService = /** @class */ (function () {
    function PushSMSGroupeService(http) {
        this.http = http;
        this.baseUrl = '/api/envoyer-sms-groupe';
    }
    PushSMSGroupeService.prototype.send = function (obj) {
        return this.http.post("" + this.baseUrl, obj);
    };
    PushSMSGroupeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushSMSGroupeService_Factory() { return new PushSMSGroupeService(i0.ɵɵinject(i1.HttpClient)); }, token: PushSMSGroupeService, providedIn: "root" });
    return PushSMSGroupeService;
}());
export { PushSMSGroupeService };
