
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Region} from '../models/region';
import {RegionService} from './region.service';


export class RegionsDatasource implements DataSource<Region> {

    private regionsSubject = new BehaviorSubject<Region[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Region[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private provinceService: RegionService) {

    }
    loadRegions(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.provinceService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(provinces => this.regionsSubject.next(provinces)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Region[]> {
        return this.regionsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.regionsSubject.complete();
        this.loadingSubject.complete();
    }

}

