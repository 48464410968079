import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';

import {ValiderEtalonnageService} from './validerEtalonnage.service';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';
import {Content} from '../../../ui/interfaces/modal';
import {TCModalService} from '../../../ui/services/modal/modal.service';

export interface IEtalonnage {
    id: number;
    uml: string;
    marche: string;
    ul: string;
    valeur: number;
}


@Component({
  selector: 'tc-valider-etalonnage',
    templateUrl: './valider-etalonnage.component.html',
    styleUrls: ['./valider-etalonnage.component.scss'],
    animations: [fadeIn]
})

export class ValiderEtalonnageComponent extends BasePageComponent implements OnInit, OnDestroy {

  dateValidation = new Date();
  data: any;
  causeRejet = '';
  etalonnageAValider: IEtalonnage[];
    outData: any[] = [];

    /******************/

    isAllDisplayDataChecked = false;
    isOperating = false;
    isIndeterminate = false;
    listOfDisplayData: IEtalonnage[] = [];
    listOfAllDataEtalonnage: IEtalonnage[] = [];
    mapOfCheckedId: { [key: string]: boolean } = {};
    numberOfChecked = 0;
    /******************************/
    valider() {
        this.outData = [];
        this.listOfAllDataEtalonnage.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
            this.outData.push(item);
            }
        });

        this.data = {data: {
            dateValidation: this.dateValidation.toLocaleDateString('en-US'),
            etalonnages: this.outData
        }};
        if (this.outData.length > 0) {
        this.validerEtalonnageService.valider(this.data) .subscribe(
            data => {

                this.toastr.success('Validation réussie des étalonnages ', '',
                    {timeOut: 2000});
                this.loadEtalonnage();
            },
            error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }
  constructor(store: Store<IAppState>,
              httpSv: HttpService, private marcheService: MarcheService,
              private route: ActivatedRoute, private authService: AuthenticationService,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService,
              private validerEtalonnageService: ValiderEtalonnageService, private modal: TCModalService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadEtalonnage();
  }
    closeModal() {
        this.causeRejet = '';
        this.modal.close();
    }
    validerRejet() {

        if (this.causeRejet > '') {
            this.modal.close();
            this.rejeter();
        } else {
            this.toastr.error('Vous devez saisir une cause de rejet ');
        }
    }
    rejeter() {

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                etalonnages: this.outData,
                causeRejet: this.causeRejet
            }};
        this.validerEtalonnageService.rejeter(this.data) .subscribe(
            data => {

                this.toastr.success('rejet réussi des lignes d\'étalonnage  sélectionnés', '',
                    {timeOut: 2000});
                this.loadEtalonnage();
            },
            error => console.log(error));

    }
    openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
        this.outData = [];
        this.listOfAllDataEtalonnage.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        } else {
            this.toastr.info('Vous devez sélectionner au moins un ligne d\'étalonnage ');
        }
    }
    private loadEtalonnage() {
        this.listOfAllDataEtalonnage = [];
        this.validerEtalonnageService.getAllNonValides().subscribe(data => {
                this.etalonnageAValider = data;
                this.etalonnageAValider.forEach((etalonnage, index, array) => {

                    this.listOfAllDataEtalonnage.push({
                        id: etalonnage.id,
                        uml: etalonnage.uml,
                        marche: etalonnage.marche,
                        ul: etalonnage.ul,
                        valeur: etalonnage.valeur
                    });
                });
            }
        );
        // this.mapOfCheckedId = {};
        // this.refreshStatus();

    }

    ngOnDestroy() {
    super.ngOnDestroy();
  }

    currentPageDataChange($event: IEtalonnage[]): void {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    }

    refreshStatus(): void {
        // this.isAllDisplayDataChecked = this.listOfDisplayData
        //     .every(item => this.mapOfCheckedId[item.id]);
        // this.isIndeterminate =
        //     this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
        //     !this.isAllDisplayDataChecked;
        // this.numberOfChecked = this.listOfAllDataEtalonnage.filter(item => this.mapOfCheckedId[item.id]).length;
    }

    checkAll(value: boolean): void {
        this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
        this.refreshStatus();
    }

    // operateData(): void {
    //     this.isOperating = true;
    //     setTimeout(() => {
    //         this.listOfAllDataEtalonnage.forEach(item => (this.mapOfCheckedId[item.id] = false));
    //         this.refreshStatus();
    //         this.isOperating = false;
    //     }, 1000);
    // }
    exportByExcel() {
        ExcelService.exportAsExcelFile(this.etalonnageAValider, 'etalonnage_a_valider');
    }

}
