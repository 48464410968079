<div class="row" *ngIf="userInfo">
  <div class="col col-12 col-md-6 mb-4 mb-md-0">
    <tc-card  [tcGradient]="['#fff', '#fbfbfb']" class="personal-info-card">
      <div class="d-flex align-items-center justify-content-between mb-3 user-actions">
        <tc-avatar [src]="'assets/img/BM90X60.png'" [size]="90"></tc-avatar>

      </div>

      <div class="d-flex align-items-center justify-content-between">
<!--        <h5 class="mb-0 mt-0 mr-1">{{ userInfo.mainInfo.firstName }} {{ userInfo.mainInfo.lastName }}</h5>-->

<!--        <tc-rating [value]="4" [disabled]="true"></tc-rating>-->
      </div>

      <p [tcColor]="'#8f8f90'">{{ userInfo.mainInfo.position }}</p>

      <p>Le Groupe de la Banque mondiale a deux objectifs :
        mettre fin à l’extrême pauvreté et promouvoir une prospérité partagée de façon durable.</p>
    </tc-card>

    <tc-card [tcGradient]="['#fff', '#fbfbfb']" [title]="'Sites Web et Réseaux Sociaux'">

      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-twitter'" [tcFontSize]="30" [tcColor]="'#1da1f2'"></tc-icon>
        </div>
        <div class="col">
          <div>Twitter</div>
          <a href="http://www.twitter.com/worldbank" (click)="$event.preventDefault()">https://twitter.com/worldbank</a>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-linkedin'" [tcFontSize]="30" [tcColor]="'#0073b1'"></tc-icon>
        </div>
        <div class="col">
          <div>Linkedin</div>
          <a href="http://www.linkedin.com/company/the-world-bank" (click)="$event.preventDefault()">linkedin.com/worldbank</a>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-youtube'" [tcFontSize]="30" [tcColor]="'#ff0000'"></tc-icon>
        </div>
        <div class="col">
          <div>YouTube</div>
          <a href="https://www.youtube.com/user/WorldBank" (click)="$event.preventDefault()">youtube.com/user/WorldBank</a>
        </div>
      </div>
    </tc-card>

    <tc-card [tcGradient]="['#fff', '#fbfbfb']" [title]="'Informations des contacts'" class="mb-md-0">
      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-ui-touch-phone'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Mobile</div>
          {{ userInfo.mainInfo.number }}
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-slack'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Slack</div>
          {{ userInfo.mainInfo.slack }}
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-skype'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Skype</div>
          {{ userInfo.mainInfo.skype }}
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-location-pin'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Adresse actuelle</div>
          {{ userInfo.mainInfo.address }}
        </div>
      </div>
    </tc-card>
  </div>

  <div class="col col-12 col-md-6">
<!--    <tc-card [title]="'Experience'" [tcGradient]="['#fff', '#fbfbfb']">-->
<!--      <tc-v-timeline [data]="userInfo.experience" [showIcons]="false"></tc-v-timeline>-->
<!--    </tc-card>-->

    <tc-card [title]="'Sujets de développement'" [tcGradient]="['#fff', '#fbfbfb']" class="mb-0">
      <div class="skills-list">
        <tc-badge [tcShape]="500" *ngFor="let skill of userInfo.development_topics">{{ skill }}</tc-badge>
      </div>
    </tc-card>
  </div>
</div>
