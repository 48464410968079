/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./col.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./col.component";
import * as i3 from "../table.component";
var styles_TCTableColComponent = [i0.styles];
var RenderType_TCTableColComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCTableColComponent, data: {} });
export { RenderType_TCTableColComponent as RenderType_TCTableColComponent };
export function View_TCTableColComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_TCTableColComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "tc-table-col", [], null, null, null, View_TCTableColComponent_0, RenderType_TCTableColComponent)), i1.ɵdid(1, 114688, null, 2, i2.TCTableColComponent, [i3.TCTableComponent], null, null), i1.ɵqud(335544320, 1, { tdTemplate: 0 }), i1.ɵqud(335544320, 2, { headerTemplate: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TCTableColComponentNgFactory = i1.ɵccf("tc-table-col", i2.TCTableColComponent, View_TCTableColComponent_Host_0, { columnTitle: "columnTitle", columnName: "columnName", enableFiltering: "enableFiltering", enableSorting: "enableSorting", padding: "padding" }, {}, ["*"]);
export { TCTableColComponentNgFactory as TCTableColComponentNgFactory };
