import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { EspeceService } from '../espece.service';
import { ToastrService } from 'ngx-toastr';
import { NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {Espece} from '../../models/espece';

@Component({
    selector: 'tc-espece-create',
    templateUrl: './create-espece.component.html',
    animations: [fadeIn]
})
export class PageCreateEspeceComponent extends BasePageComponent implements OnInit, OnDestroy {
    especeForm: FormGroup;
    espece: any;
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            code: 'Code espece',
            nom: 'Nom espece',
            description: 'Description',
            actif: 'Actif'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private especeService: EspeceService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.espece = new Espece();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des espèces',
                    route: 'list-espece'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.espece) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier une espèce ' : ' Créer une espèce ' ;
    }
    isEditing(): boolean {
        return (this.espece.id != null);
    }



    initReactiveForm() {
        this.especeForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                description: [''],
                actif: [true]
            }
        ) ;
    }
     getespece (jsonValue): Espece {
        return Espece.fromJSON(jsonValue) ;
    }

    setReactiveForm() {
        if (this.espece) {
            this.especeForm.patchValue({
                    code: this.espece.code,
                    nom: this.espece.nom,
                    description: this.espece.description,
                    actif: this.espece.actif
                }
            );

        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.especeService.getURL(this.selectedId), 'espece', 'espece', 'setLoaded');
            } else {
                this.espece = new Espece();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-espece']);
    }
    liste() {
        this.router.navigate(['/vertical/list-espece']);
    }
    mapFormValuesToModel() {
        this.espece.code =  this.especeForm.value.code;
        this.espece.nom =  this.especeForm.value.nom;
        this.espece.description =  this.especeForm.value.description;
        this.espece.actif =  this.especeForm.value.actif;
    }
    update(): void {
        if (this.especeForm.valid) {
            this.mapFormValuesToModel();
            this.especeService.update(this.espece.id, this.espece)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.router.navigate(['/vertical/list-espece']);
                    }
                    ,
                    error => console.log(error));
        }

    }

    create(): void {
        if (this.especeForm.valid) {
            this.mapFormValuesToModel();
            this.especeService.create(this.espece)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.especeForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
