var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { CategorieService } from '../categorie.service';
import { ToastrService } from 'ngx-toastr';
import { Categorie } from '../../models/categorie';
import { NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProduitService } from '../../produit/produit.service';
var PageCreateCategorieComponent = /** @class */ (function (_super) {
    __extends(PageCreateCategorieComponent, _super);
    function PageCreateCategorieComponent(store, httpSv, categorieService, route, router, toastr, i18n, produitService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.categorieService = categorieService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.i18n = i18n;
        _this.produitService = produitService;
        _this.fb = fb;
        _this.produitsAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code Categorie',
            nom: 'Nom Categorie',
            description: 'Description',
            actif: 'Actif'
        };
        _this.categorie = new Categorie();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des categories',
                    route: 'list-categorie'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateCategorieComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.categorie) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateCategorieComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier une categorie ' : ' Créer une categorie ';
    };
    PageCreateCategorieComponent.prototype.isEditing = function () {
        return (this.categorie.id != null);
    };
    PageCreateCategorieComponent.prototype.initReactiveForm = function () {
        this.categorieForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            description: [''],
            actif: [true]
        });
    };
    PageCreateCategorieComponent.prototype.getCategorie = function (jsonValue) {
        return Categorie.fromJSON(jsonValue);
    };
    PageCreateCategorieComponent.prototype.onProduitsPromise = function () {
        this.produitsAdapter = [];
        for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
            var commune = _a[_i];
            this.produitsAdapter.push({ 'value': commune.id.toString(), 'label': commune.nom });
        }
    };
    PageCreateCategorieComponent.prototype.setReactiveForm = function () {
        if (this.categorie) {
            this.categorieForm.patchValue({
                code: this.categorie.code,
                nom: this.categorie.nom,
                description: this.categorie.description,
                actif: this.categorie.actif
            });
        }
    };
    PageCreateCategorieComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.categorieService.getURL(_this.selectedId), 'categorie', 'categorie', 'setLoaded');
            }
            else {
                _this.categorie = new Categorie();
                _this.initReactiveForm();
            }
        });
        this.getData(this.produitService.getAllURL(), 'produits', 'onProduitsPromise');
    };
    PageCreateCategorieComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateCategorieComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-categorie']);
    };
    PageCreateCategorieComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-categorie']);
    };
    PageCreateCategorieComponent.prototype.mapFormValuesToModel = function () {
        this.categorie.code = this.categorieForm.value.code;
        this.categorie.nom = this.categorieForm.value.nom;
        this.categorie.description = this.categorieForm.value.description;
        this.categorie.actif = this.categorieForm.value.actif;
    };
    PageCreateCategorieComponent.prototype.update = function () {
        var _this = this;
        if (this.categorieForm.valid) {
            this.mapFormValuesToModel();
            this.categorieService.update(this.categorie.id, this.categorie)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-categorie']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateCategorieComponent.prototype.create = function () {
        var _this = this;
        if (this.categorieForm.valid) {
            this.mapFormValuesToModel();
            this.categorieService.create(this.categorie)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie  ', '', { timeOut: 2000 });
                _this.categorieForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateCategorieComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateCategorieComponent;
}(BasePageComponent));
export { PageCreateCategorieComponent };
