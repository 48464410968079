import * as i0 from "@angular/core";
var TypeMesureService = /** @class */ (function () {
    function TypeMesureService() {
        this.baseUrl = '/api/typeMesure';
    }
    TypeMesureService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    TypeMesureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TypeMesureService_Factory() { return new TypeMesureService(); }, token: TypeMesureService, providedIn: "root" });
    return TypeMesureService;
}());
export { TypeMesureService };
