var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DownloaderService } from '../../../services/sharedServices/downloader.service';
import { FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
var ImporterSuperviseursComponent = /** @class */ (function (_super) {
    __extends(ImporterSuperviseursComponent, _super);
    function ImporterSuperviseursComponent(store, httpSv, route, router, toastr, downloaderService, formBuilder, httpClient) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.downloaderService = downloaderService;
        _this.formBuilder = formBuilder;
        _this.httpClient = httpClient;
        _this.SERVER_URL = 'api/upload_superviseurs';
        _this.pageData = {
            title: 'IMPORTER LES SUPERVISEURS',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        return _this;
    }
    ImporterSuperviseursComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
    };
    ImporterSuperviseursComponent.prototype.downloadFile = function () {
        var link = document.createElement('a');
        link.download = 'Superviseurs_caneva.xls';
        link.href = 'assets/data/canevas_imports/Superviseurs_caneva.xls';
        link.click();
        link.remove();
    };
    ImporterSuperviseursComponent.prototype.onFileSelect = function (event) {
        if (event.target.files.length > 0) {
            var file = event.target.files[0];
            this.uploadForm.get('profile').setValue(file);
        }
    };
    ImporterSuperviseursComponent.prototype.onSubmit = function () {
        var _this = this;
        var formData = new FormData();
        formData.append('file', this.uploadForm.get('profile').value);
        this.httpClient.post(this.SERVER_URL, formData).subscribe(function (res) {
            console.log(res);
            _this.toastr.success('Succès de l\'\'importation ');
        }, function (err) {
            console.log(err);
            _this.toastr.error('Echec de l\'\'importation cause ' + err.toString());
        });
    };
    ImporterSuperviseursComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.uploadForm = this.formBuilder.group({
            profile: ['']
        });
    };
    ImporterSuperviseursComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return ImporterSuperviseursComponent;
}(BasePageComponent));
export { ImporterSuperviseursComponent };
