import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {IStock} from '../liste-stock-valides/liste-stock-valides.component';
import {Price} from '../valider-prix/valider-prix.component';

@Injectable({
  providedIn: 'root'
})
export class ValiderStockService {
	private baseUrl = '/api/stock';
  constructor(private http: HttpClient) {

  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllByReseau(reseauId: string): Observable<IStock[]> {
    return this.http.get(`${this.baseUrl}/get_all_by_reseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId)
    }) as Observable<IStock[]>;
  }
  getAllValidesByReseau(reseauId: string): Observable<IStock[]> {
    return this.http.get(`${this.baseUrl}/get_all_valides_by_reseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId)
    }) as Observable<IStock[]>;
  }

  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj);
  }
  devalider(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/devalider`, obj);
  }
  valider(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/valider`, obj);
  }
  update(id: number, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/modifier/${id}`, value);
  }
  rejeter(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/rejeter`, obj);
  }
  abandonner(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/abandonner`, obj);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

  // tslint:disable-next-line:max-line-length
  getStocksValides(dateDebut: string, dateFin: string, depots: any[], produits: any[], reseau: string, mesures: any[]): Observable<IStock[]> {
  return this.http.get(`${this.baseUrl}/valides`, {
params: new HttpParams()
.set('dateDebut', dateDebut)
.set('dateFin', dateFin)
.set('depots', depots.toString())
.set('produits', produits.toString())
.set('reseau', reseau)
.set('mesures', mesures.toString())
}) as Observable<IStock[]>;
}

}

