<div class="col-sm-8">
<ng-container *ngIf="chronogramme  ">

    <form  class="new-doctor-form mb-4" [formGroup]="chronoForm"  novalidate (ngSubmit)="onSubmit()">

      <tc-card [title]="'CHRONOGRAMME DES ALERTES '">
          <div class="row">
              <div class="col-sm-12">
                  <tc-form-group>
                      <tc-form-label>{{Columnslabels.cronTutorial}} </tc-form-label>
                      <tc-form-label>{{chronogramme.cronTutorial}} </tc-form-label>
                  </tc-form-group>
              </div>
          </div>
            <div class="row">
                <div class="col-sm-4">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.nom}} </tc-form-label>
                        <tc-input type="text" [placeholder]="'Script Cron'" formControlName="cron" >
                        </tc-input>
                        <ng-container *ngIf="chronoForm.get('cron').invalid && (chronoForm.get('cron').touched || chronoForm.get('cron').dirty)">
                            <span @fadeIn class="error" *ngIf="chronoForm.get('cron').getError('required')">Veuillez saisir le script cron</span>
                         </ng-container>
                    </tc-form-group>
                </div>
                <div class="col-sm-8">
                </div>
            </div>
                    <span *ngIf="!chronoForm.valid && chronoForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                    <button tc-button type="submit" [block]="true" >
                        Modifier
                    </button>
      </tc-card>


    </form>
</ng-container>
</div>
