import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import {finalize, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BasicAuthHtppInterceptorService implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
       if (this.authenticationService !== undefined) {
         const currentUser = this.authenticationService.currentUserValue;
         if (currentUser && currentUser.access_token && (request.url.indexOf('/api/') >= 0)) {
             request = request.clone({
                 setHeaders: {
                     Authorization: `Bearer ${currentUser.access_token}`,
                     mobilePhone: currentUser.username
                 }
             });
         }
     }
    const started = Date.now();
    return next.handle(request).pipe(map((response: HttpEvent<any>) => {
      if (response instanceof HttpResponse) {
        }
      return response;
    }),
        // Log when response observable either completes or errors
        finalize(() => {
          const elapsed = Date.now() - started;
          const msg = `${request.method} "${request.urlWithParams}"  in ${elapsed} ms.`;

        }));
     }


}
