import {Activite} from './activite';
import {Commune} from './commune';
import {Reseau} from './reseau';
import {Groupe} from './groupe';
import {Marche} from './marche';
import {Produit} from './produit';
import {Depot} from './depot';

export class Acteur {
	get groupesText() {
		let rval = '';
		// Array:
		if (this.groupes != null) {
			for (const groupe of this.groupes) {
				rval += groupe.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	constructor() {
        this.enabled = true;
        this.activite = new Activite();
		this.commune = new Commune();
		this.reseau = new Reseau();
		this.groupes = [];
		this.marcheSuivi = [];
		this.marchePreference = [];
	}
	get selectedGroups() {
		const rval = [];
		if (this.groupes != null) {
			for (const groupe of this.groupes) {
				rval.push(groupe.id.toString());
			}
		}
		return rval;
	}
	get selectedMarchesSuivis() {
		const rval = [];
		if (this.marcheSuivi != null) {
			for (const marche of this.marcheSuivi) {
				if (marche.id!==undefined)
				rval.push(marche.id.toString());
			}
		}
		return rval;
	}
	get selectedMarchesPreference() {
		const rval = [];
		if (this.marchePreference != null) {
			for (const marche of this.marchePreference) {
				if (marche.id!==undefined)
				rval.push(marche.id.toString());
			}
		}
		return rval;
	}

    get selectedDepotsSuivis() {
        const rval = [];
        if (this.depotSuivi != null) {
            for (const depot of this.depotSuivi) {
                rval.push(depot.id.toString());
            }
        }
        return rval;
    }
    get selectedDepotsPreference() {
        const rval = [];
        if (this.depotPreference != null) {
            for (const depot of this.depotPreference) {
				if (depot.id!==undefined)
                rval.push(depot.id.toString());
            }
        }
        return rval;
    }
	get flatSelectedGroups() {
		return this.selectedGroups.join(',');
	}
	id: number;
	prenom: string;
	nomComplet: string;
	nomCompletEtReseau: string;
	nom: string;
	email: string;
	mobilePhone: string;
	secondPhone: string;
	password: string;
	genre: string;
	activite: Activite;
	commune: Commune;
	groupes: Groupe[];
	role: string;
	enabled: boolean;
	reseau: Reseau;
	marcheSuivi: Marche[];
	marchePreference: Marche[];
	depotSuivi: Depot[];
	depotPreference: Depot[];
	produits: Produit[];
	communeTab: string;
	static fromJSON(json: any): Acteur {
		const result = new Acteur();
		for (const key in json) {
			result[key] = json[key];
		}
		return result;
	}
	get products() {
		let rval = '';
		// Array:
		if (this.produits != null) {
			for (const produit of this.produits) {
				rval += produit.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get depotsSuivisText() {
		let rval = '';
		// Array:
		if (this.depotSuivi != null) {
			for (const depot of this.depotSuivi) {
				rval += depot.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get depotsPreferenceText() {
		let rval = '';
		// Array:
		if (this.depotPreference != null) {
			for (const depot of this.depotPreference) {
				rval += depot.nom;
				rval += '\n';
			}
		}
		return rval;
	}

	get marchesSuivisText() {
		let rval = '';
		// Array:
		if (this.marcheSuivi != null) {
			for (const marche of this.marcheSuivi) {
				rval += marche.nom;
				rval += '\n';
			}
		}
		return rval;
	}

	get marchesPreferenceText() {
		let rval = '';
		// Array:
		if (this.marchePreference != null) {
			for (const marche of this.marchePreference) {
				rval += marche.nom;
				rval += '\n';
			}
		}
		return rval;
	}
	get selectedProducts() {
		const rval = [];
		if (this.produits != null) {
			for (const produit of this.produits) {
				if (produit.id!==undefined)
				rval.push(produit.id.toString());
			}
		}
		return rval;
	}
	static listFromJSON(json: any, userType: string): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const administrateur = Acteur.fromJSON(jsonObject);
				jsonObject.groupes = administrateur.groupesText;
				if (administrateur.reseau) {
					jsonObject.reseau = administrateur.reseau.nom;
				}
				if (administrateur.commune) {
					jsonObject.commune = administrateur.commune.nom;
				}
				if (administrateur.activite) {
					jsonObject.activite = administrateur.activite.nom;
				}
				if (administrateur.depotsSuivisText) {
					jsonObject.depots_suivis = administrateur.depotsSuivisText;
				}
				if (administrateur.depotsPreferenceText) {
					jsonObject.depots_preference = administrateur.depotsPreferenceText;
				}
				if (administrateur.selectedMarchesSuivis) {
					jsonObject.marchesuivis = administrateur.marchesSuivisText;
				}
				if (administrateur.marchePreference) {
					jsonObject.marche_preference = administrateur.marchesPreferenceText;
				}
				jsonObject.role = userType;
				rval.push(jsonObject);
			}
		}
		return rval;
	}


	get isAdmin(): boolean {
		return (this.role === 'Administrateur');
	}
	get isSuperviseur(): boolean {
		return (this.role === 'Superviseur');
	}
	get isSIMBetail(): boolean {
		return (this.reseau.isSIMBetail);
	}
	get isDGPER(): boolean {
		return (this.reseau.isDGPER);
	}
	get isEnqueteur(): boolean {
		return (this.role === 'Enqueteur');
	}
	get isWriter(): boolean {
		return (this.role === 'Administrateur') || (this.role === 'Superviseur') || (this.role === 'Animateur') || (this.role === 'Utilisateur') ;
	}
	get isGroupeAdmin(): boolean {
		console.log('return',((this.isAdmin) || (this.isSuperviseur)) );
		return ((this.isAdmin) || (this.isSuperviseur));
	}
	get isAnimateur(): boolean {
		return (this.role === 'Animateur') ;
	}
	get getNomComplet(): string {
		return (this.nom + ' ' + this.prenom);
	}
	get getFullName(): string {
		return (this.nom + ' ' + this.prenom + ' (' + this.mobilePhone + ')');
	}
	toString() {
		return this.nomComplet  ;
	}
}
