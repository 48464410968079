import { ActivatedRoute, Router } from '@angular/router';
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '../../../../services/sharedServices/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { SETTINGS } from '../../../../../environments/settings';
import { UserService } from '../../../ui/administrateur/user.service';
var PageSignInComponent = /** @class */ (function () {
    function PageSignInComponent(router, activatedRoute, loginservice, toastr, adminService) {
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.loginservice = loginservice;
        this.toastr = toastr;
        this.adminService = adminService;
        this.invalidLogin = false;
        this.nbattempts = 0;
        this.nbMaxAttempts = 3;
        this.headerbgColor = SETTINGS.tableHeaderbgColor;
    }
    PageSignInComponent.prototype.ngOnInit = function () {
        this.loginForm = new FormGroup({
            login: new FormControl('', [Validators.required, Validators.minLength(4)]),
            password: new FormControl('', [Validators.required, Validators.minLength(5)])
        });
        this.nbattempts = 0;
        // get return url from route parameters or default to '/'
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/';
    };
    PageSignInComponent.prototype.Login = function () {
        var _this = this;
        this.loginservice.authenticate(this.login.value, this.password.value).subscribe(function (data) {
            _this.loginAuth = data;
            _this.invalidLogin = false;
            _this.nbattempts = 0;
            //   console.log('this.router.routerState.snapshot.url ' + this.router.routerState.snapshot.url);
            sessionStorage.setItem('currentUser', JSON.stringify(data));
            _this.loginservice.setCurrentObject(data);
            //   this.router.navigate([sessionStorage.getItem('urlmissed')]);
            sessionStorage.removeItem('currentUser');
            _this.adminService.findMe().subscribe(function (userData) {
                _this.userData = userData;
                _this.loginservice.setCurrentUserDataObject(userData);
                _this.router.navigateByUrl(_this.returnUrl);
                sessionStorage.setItem('currentUserData', JSON.stringify(userData));
            });
        }, function (error) {
            var errorText;
            console.log('error', error);
            errorText = error;
            _this.invalidLogin = true;
            _this.nbattempts += 1;
            if ((errorText.toUpperCase().indexOf('TIMEOUT') >= 0) || (errorText.toUpperCase().indexOf('GATEWAY') >= 0)) {
                errorText = 'Vérifiez que le serveur rest a démarré ';
            }
            else {
                _this.password.setErrors({ mustExist: true });
            }
            _this.toastr.error(errorText);
            _this.nbattempts = 0;
        });
    };
    Object.defineProperty(PageSignInComponent.prototype, "login", {
        get: function () {
            return this.loginForm.get('login');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageSignInComponent.prototype, "password", {
        get: function () {
            return this.loginForm.get('password');
        },
        enumerable: true,
        configurable: true
    });
    return PageSignInComponent;
}());
export { PageSignInComponent };
