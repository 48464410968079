<!--<div class="row justify-content-center" >-->
<!--<div class="col col-12 col-xl-8">-->
<div class="col-sm-8">
<ng-container *ngIf="administrateur ">

        <tc-card [title]="'Changer mon mot de passe '" class="mb-0">
            <form class="new-doctor-form mb-4" novalidate (ngSubmit)="onSubmit()" [formGroup]="changePwdForm"  >

                <div class="row">
                    <div class="col-sm-12">
                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.newPassword}}  </tc-form-label>
                            <tc-input [prefixIcon]="'icofont-lock'" [placeholder]="'Nouveau mot de passe'" type="password"  formControlName="password" >
                            </tc-input>
                            <ng-container *ngIf="changePwdForm.get('password').invalid && (changePwdForm.get('password').touched || changePwdForm.get('password').dirty)">
                                <span @fadeIn class="error" *ngIf="changePwdForm.get('password').getError('required')">Vous devez saisir un mot de passe</span>
                                <span @fadeIn class="error" *ngIf="changePwdForm.get('password').getError('min')">Le mot de passe doit avoir plus de 8 caractères</span>
                            </ng-container>
                        </tc-form-group>
                    </div>
                </div>
                    <div class="row">
                    <div class="col-sm-12">
                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.confirmpassword}}  </tc-form-label>
                            <tc-input [placeholder]="'Confirmer le nouveau mot de passe'" [prefixIcon]="'icofont-check-circled'" type="password" formControlName="confirmpassword" >
                            </tc-input>
                            <ng-container *ngIf="changePwdForm.get('confirmpassword').invalid && (changePwdForm.get('confirmpassword').touched || changePwdForm.get('confirmpassword').dirty)">
                                <span @fadeIn class="error" *ngIf="changePwdForm.get('confirmpassword').getError('required')">Vous devez saisir un mot de passe</span>
                                <span @fadeIn class="error" *ngIf="changePwdForm.get('confirmpassword').getError('min')">Le mot de passe doit avoir plus de 8 caractères</span>
                                <span @fadeIn class="error" *ngIf="changePwdForm.get('confirmpassword').getError('notMatch')">Les mots de passe ne concordent pas</span>
                            </ng-container>
                        </tc-form-group>
                    </div>
                </div>



                <button tc-button  type="submit" [block]="true"  >
                    Changer le mot de passe
                </button>
            </form>
        </tc-card>

</ng-container>
</div>
<!--</div>-->
