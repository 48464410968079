var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../administrateur/user.service';
import { GenreService } from '../../../services/sharedServices/genre.service';
import { ActiviteService } from '../activite/activite.service';
import { CommuneService } from '../commune/commune.service';
import { ReseauService } from '../reseau/reseau.service';
import { GroupeService } from '../groupe/groupe.service';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MarcheService } from '../marche/marche.service';
import { ProduitService } from '../produit/produit.service';
import { DepotService } from '../depot/depot.service';
import { ActeurEdit } from '../models/acteurEdit';
import { Marche } from '../models/marche';
import { Produit } from '../models/produit';
import { Depot } from '../models/depot';
var PageProfilAdministrateurComponent = /** @class */ (function (_super) {
    __extends(PageProfilAdministrateurComponent, _super);
    function PageProfilAdministrateurComponent(store, httpSv, route, router, toastr, genreService, activiteService, communeService, i18n, reseauService, marcheService, depotService, fb, groupeService, produitService, adminService, auth, httpClient) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.genreService = genreService;
        _this.activiteService = activiteService;
        _this.communeService = communeService;
        _this.i18n = i18n;
        _this.reseauService = reseauService;
        _this.marcheService = marcheService;
        _this.depotService = depotService;
        _this.fb = fb;
        _this.groupeService = groupeService;
        _this.produitService = produitService;
        _this.adminService = adminService;
        _this.auth = auth;
        _this.httpClient = httpClient;
        _this.SERVER_URL = 'api/upload_avatar';
        _this.genres = [];
        _this.communeTree = [];
        _this.activitesAdapter = [];
        _this.reseauxAdapter = [];
        _this.groupesAdapter = [];
        _this.produitsAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.marchesAdapter = [];
        _this.depotsAdapter = [];
        _this.Columnslabels = {
            nom: 'Nom',
            prenom: 'Prénom',
            mobilePhone: 'N° Mobile',
            secondPhone: 'Tel. Secondaire',
            genre: 'Sexe',
            email: 'Email',
            activite: 'Activité',
            commune: 'Commune',
            reseau: 'Réseau',
            groupes: 'Groupes',
            enabled: 'Actif',
            marchespreference: 'Marchés de préférence',
            marchesuivis: 'Marchés suivis',
            depotPreference: 'Dépôts de préférence',
            depotSuivi: 'Dépôts suivis',
            produits: 'Produits'
        };
        _this.administrateur = {};
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    PageProfilAdministrateurComponent.getAdministrateur = function (jsonValue) {
        return ActeurEdit.fromJSON(jsonValue);
    };
    PageProfilAdministrateurComponent.prototype.getTitle = function () {
        return 'Compte : ' + this.getNomComplet();
    };
    PageProfilAdministrateurComponent.prototype.getNomComplet = function () {
        return this.administrateur.nom + ' ' + this.administrateur.prenom;
    };
    PageProfilAdministrateurComponent.prototype.onGenresPromise = function () {
    };
    PageProfilAdministrateurComponent.prototype.getMarcheObject = function (jsonValue) {
        return Marche.fromJSON(jsonValue);
    };
    PageProfilAdministrateurComponent.prototype.getProduitObject = function (jsonValue) {
        return Produit.fromJSON(jsonValue);
    };
    PageProfilAdministrateurComponent.prototype.getDepotObject = function (jsonValue) {
        return Depot.fromJSON(jsonValue);
    };
    PageProfilAdministrateurComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.pageData.title = this.getTitle();
        this.setReactiveForm();
        this.userType = this.administrateur.role;
    };
    PageProfilAdministrateurComponent.prototype.initReactiveForm = function () {
        this.userForm = this.fb.group({
            mobilePhone: ['', Validators.required],
            secondPhone: [''],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            prenom: ['', [Validators.required, Validators.minLength(2)]],
            email: ['', [Validators.required, Validators.email]],
            genre: ['', Validators.required],
            marcheSuivi: [''],
            marchePreference: [''],
            activite: [''],
            depotPreference: [''],
            depotSuivi: [''],
            commune: [''],
            reseau: [''],
            groupes: [''],
            produits: [''],
            enabled: [true]
        });
    };
    PageProfilAdministrateurComponent.prototype.canChangeReseauAndGroupe = function () {
        var admin = PageProfilAdministrateurComponent.getAdministrateur(this.administrateur);
        return admin.isAdminGroup;
    };
    PageProfilAdministrateurComponent.prototype.onMarchesPromise = function () {
        this.marchesAdapter = [];
        for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
            var marche = _a[_i];
            this.marchesAdapter.push({ 'value': marche.id.toString(), 'label': this.getMarcheObject(marche).getFullName });
        }
    };
    PageProfilAdministrateurComponent.prototype.onProduitsPromise = function () {
        this.produitsAdapter = [];
        for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
            var produit = _a[_i];
            this.produitsAdapter.push({ 'value': produit.id.toString(), 'label': this.getProduitObject(produit).getFullName });
        }
    };
    PageProfilAdministrateurComponent.prototype.onDepotsPromise = function () {
        this.depotsAdapter = [];
        for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
            var depot = _a[_i];
            this.depotsAdapter.push({ 'value': depot.id.toString(), 'label': this.getDepotObject(depot).getFullName });
        }
    };
    PageProfilAdministrateurComponent.prototype.onSelectReseau = function ($event) {
        var _this = this;
        if (this.reseaux != null) {
            var reseauSelected = this.reseaux.find(function (obj) { return obj.id.toString() === _this.userForm.value.reseau; });
            if (reseauSelected != null) {
                this.getData(this.groupeService.getAllByReseauURL(reseauSelected.id), 'groupes', 'onGroupesPromise');
            }
        }
    };
    PageProfilAdministrateurComponent.prototype.setReactiveForm = function () {
        if (this.administrateur) {
            var admin = PageProfilAdministrateurComponent.getAdministrateur(this.administrateur);
            var selectedGroups = admin.selectedGroups;
            var marchesSuiviSelected = (admin.selectedMarchesSuivis !== []) ? admin.selectedMarchesSuivis : '';
            var marchesPreferenceSelected = (admin.selectedMarchesPreference !== []) ? admin.selectedMarchesPreference : '';
            var depotssuivis = (admin.selectedDepotsSuivis !== []) ? admin.selectedDepotsSuivis : '';
            var depotspreference = (admin.selectedDepotsPreference !== []) ? admin.selectedDepotsPreference : '';
            var produits = (admin.selectedProducts !== []) ? admin.selectedProducts : '';
            var commune = (this.administrateur.communeTab != null) ? this.administrateur.communeTab : ['', '', ''];
            console.log('marchesAdapter', this.marchesAdapter);
            console.log('marchesSuiviSelected', marchesSuiviSelected);
            this.userForm.patchValue({
                mobilePhone: this.administrateur.mobilePhone,
                nom: this.administrateur.nom,
                prenom: this.administrateur.prenom,
                secondPhone: this.administrateur.secondPhone,
                email: this.administrateur.email,
                enabled: this.administrateur.enabled,
                genre: this.administrateur.genre,
                activite: (this.administrateur.activite != null) ? this.administrateur.activite.toString() : '',
                commune: commune,
                reseau: (this.administrateur.reseau != null) ? this.administrateur.reseau.toString() : '',
                groupes: selectedGroups,
                marcheSuivi: marchesSuiviSelected,
                marchePreference: marchesPreferenceSelected,
                depotPreference: depotspreference,
                depotSuivi: depotssuivis,
                produits: produits
            });
        }
    };
    PageProfilAdministrateurComponent.prototype.onGroupesPromise = function () {
        this.groupesAdapter = [];
        for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
            var groupe = _a[_i];
            this.groupesAdapter.push({ 'value': groupe.id.toString(), 'label': groupe.code });
        }
    };
    PageProfilAdministrateurComponent.prototype.onSelectReseauxPromise = function () {
        this.groupesAdapter = [];
        for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
            var groupe = _a[_i];
            this.groupesAdapter.push({ 'value': groupe.id.toString(), 'label': groupe.nom });
        }
    };
    PageProfilAdministrateurComponent.prototype.onReseauxPromise = function () {
        for (var _i = 0, _a = this.reseaux; _i < _a.length; _i++) {
            var reseau = _a[_i];
            this.reseauxAdapter.push({ 'value': reseau.id.toString(), 'label': reseau.code });
        }
    };
    PageProfilAdministrateurComponent.prototype.onActivitesPromise = function () {
        this.activitesAdapter = [];
        for (var _i = 0, _a = this.activites; _i < _a.length; _i++) {
            var activite = _a[_i];
            this.activitesAdapter.push({ 'value': activite.id.toString(), 'label': activite.nom });
        }
    };
    PageProfilAdministrateurComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.defaultAvatar = 'assets/content/anonymous-400.jpg';
        this.currentAvatar = this.defaultAvatar;
        this.getData(this.genreService.getAllURL(), 'genres', 'onGenresPromise');
        this.activiteService.getCached().subscribe(function (data) {
            _this.activites = data;
            _this.onActivitesPromise();
        });
        this.reseauService.getCached().subscribe(function (data) {
            _this.reseaux = data;
            _this.onReseauxPromise();
        });
        this.groupeService.getCached().subscribe(function (data) {
            _this.groupes = data;
            _this.onGroupesPromise();
        });
        this.marcheService.getCached().subscribe(function (data) {
            _this.marches = data;
            _this.onMarchesPromise();
        });
        this.depotService.getCached().subscribe(function (data) {
            _this.depots = data;
            _this.onDepotsPromise();
        });
        this.produitService.getCached().subscribe(function (data) {
            _this.produits = data;
            _this.onProduitsPromise();
        });
        if (this.auth.currentUserDataObject === null) {
            this.router.navigate(['/vertical/dashboard']);
        }
        else {
            this.selectedId = this.auth.currentUserDataObject.id;
            this.getDataByName(this.adminService.getURL(this.selectedId), 'administrateur', 'acteur', 'setLoaded');
        }
        this.communeService.findAsTree().subscribe(function (tree) {
            _this.communeTree = tree;
        });
    };
    PageProfilAdministrateurComponent.prototype.ngAfterViewInit = function () {
    };
    PageProfilAdministrateurComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageProfilAdministrateurComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-administrateur']);
    };
    PageProfilAdministrateurComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-administrateur']);
    };
    PageProfilAdministrateurComponent.prototype.mapFormValuesToModel = function () {
        this.administrateur.mobilePhone = this.userForm.value.mobilePhone;
        this.administrateur.nom = this.userForm.value.nom;
        this.administrateur.prenom = this.userForm.value.prenom;
        this.administrateur.email = this.userForm.value.email;
        this.administrateur.reseau = this.userForm.value.reseau;
        this.administrateur.groupes = this.userForm.value.groupes;
        this.administrateur.enabled = this.userForm.value.enabled;
        this.administrateur.activite = this.userForm.value.activite;
        this.administrateur.genre = this.userForm.value.genre;
        this.administrateur.produits = this.userForm.value.produits;
        this.administrateur.commune = this.userForm.value.commune[2];
        this.administrateur.secondPhone = this.userForm.value.secondPhone;
        this.administrateur.depotSuivi = this.userForm.value.depotSuivi;
        this.administrateur.depotPreference = this.userForm.value.depotPreference;
        this.administrateur.marcheSuivi = this.userForm.value.marcheSuivi;
        this.administrateur.marchePreference = this.userForm.value.marchePreference;
    };
    PageProfilAdministrateurComponent.prototype.update = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.adminService.update(this.administrateur.id, this.userType, this.administrateur)
            .subscribe(function (data) {
            _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
            _this.router.navigate(['']);
        }, function (error) { return console.log(error); });
    };
    PageProfilAdministrateurComponent.prototype.onFileChanged = function (inputValue) {
        var _this = this;
        var file = inputValue.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            _this.currentAvatar = reader.result;
            _this.changes = true;
        };
        reader.readAsDataURL(file);
        var formData = new FormData();
        formData.append('file', file);
        formData.append('avatar_id', 'avatar_' + this.administrateur.id);
        var headers = new HttpHeaders({ 'content-type': 'multipart/form-data ' });
        this.httpClient.post(this.SERVER_URL, formData).subscribe(function (res) {
            console.log(res);
            _this.toastr.success('Succès de l\'\'importation ');
        }, function (err) {
            console.log(err);
            _this.toastr.error('Echec de l\'\'importation cause ' + err.toString());
        });
    };
    PageProfilAdministrateurComponent.prototype.onSubmit = function () {
        this.update();
    };
    return PageProfilAdministrateurComponent;
}(BasePageComponent));
export { PageProfilAdministrateurComponent };
