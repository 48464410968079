<ng-container *ngIf="depotsAdapter.length>0">
<agm-map [latitude]="lat" [longitude]="lng">
  <agm-marker *ngFor="let depot of depotsAdapter" [latitude]="depot.latitude"  [longitude]="depot.longitude"
              [title]="depot.nom" [openInfoWindow]="true" [opacity]="0.5"  >
    <agm-info-window [disableAutoPan]="true">
      <div class="infoWindowTitle"> <strong> Dépôt de {{depot.nom}} </strong> </div>
      <div class="infoWindowContent">
        <p> latitude: <strong>{{depot.latitude}}</strong></p>
        <p>longitude:  <strong>{{depot.longitude}} </strong> </p>
<!--        <ng-container *ngIf="depot.produits">-->
<!--          <p>Produits du dépôt : </p>-->
<!--          <p>-->
<!--          <strong>{{depot.produits}}</strong>-->
<!--          </p>-->
<!--        </ng-container>-->
      </div>
    </agm-info-window>
  </agm-marker>

</agm-map>
</ng-container>
<!--<ng-container *ngIf="depotsAdapter.length==0 ">-->
<!--  <tc-card>-->
<!--    <p style="align-content: center; padding: 100px; font-size: 30px"><strong>AUCUN DEPOT A PROJETER</strong></p>-->
<!--  </tc-card>-->
<!--</ng-container>-->
