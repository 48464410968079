var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { ActivatedRoute, Router } from '@angular/router';
import { ProvinceService } from '../province.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { ExcelService } from '../../../../services/sharedServices/excel-service.service';
import { Province } from '../../models/province';
import { MatDialog, MatPaginator, MatSort } from '@angular/material';
import { fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ProvincesDatasource } from '../provinces.datasource';
var PageIndexProvinceComponent = /** @class */ (function (_super) {
    __extends(PageIndexProvinceComponent, _super);
    function PageIndexProvinceComponent(store, httpSv, provinceService, dialog, router, route) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.provinceService = provinceService;
        _this.dialog = dialog;
        _this.router = router;
        _this.route = route;
        _this.productCount = 0;
        // pageEvent: PageEvent;
        _this.sortField = 'nom';
        _this.pageIndex = 0;
        _this.pageSize = 5;
        // MatPaginator Output
        _this.displayedColumns = ['code', 'nom', 'region', 'actions'];
        _this.pageData = {
            subTitle: '',
            title: 'LISTE DES PROVINCES',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Tableau de bord',
                    route: 'dashboard'
                }
            ]
        };
        _this.rows = [];
        return _this;
    }
    PageIndexProvinceComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.dataSource = new ProvincesDatasource(this.provinceService);
        this.provinceService.findCount().subscribe(function (count) {
            _this.productCount = count;
            if (sessionStorage.getItem('provincePageIndex') != null) {
                _this.pageIndex = +sessionStorage.getItem('provincePageIndex');
            }
            else {
                _this.pageIndex = 0;
            }
            if (sessionStorage.getItem('provincePageSize') != null) {
                _this.pageSize = (+sessionStorage.getItem('provincePageSize') > 0) ? +sessionStorage.getItem('provincePageSize') : 5;
            }
            else {
                _this.pageSize = 5;
            }
            _this.paginator.pageIndex = _this.pageIndex;
            _this.paginator.pageSize = _this.pageSize;
            _this.dataSource.loadProvinces('', _this.sortField, 'asc', _this.pageIndex, _this.pageSize);
        });
    };
    PageIndexProvinceComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.sort.sortChange.subscribe(function () { return _this.paginator.pageIndex = 0; });
        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(debounceTime(150), distinctUntilChanged(), tap(function () {
            _this.paginator.pageIndex = 0;
            _this.loadPage();
        }))
            .subscribe();
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(function () { return _this.loadPage(); })).subscribe();
        this.paginator.page.subscribe(function (page) {
            _this.pageIndex = page.pageIndex;
            _this.pageSize = page.pageSize;
            sessionStorage.setItem('provincePageIndex', page.pageIndex);
            if (page.pageSize > 0) {
                sessionStorage.setItem('provincePageSize', page.pageSize);
            }
        });
    };
    PageIndexProvinceComponent.prototype.loadPage = function () {
        this.dataSource.loadProvinces(this.input.nativeElement.value, this.sortField, this.sort.direction, this.paginator.pageIndex, this.paginator.pageSize);
    };
    PageIndexProvinceComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
    };
    PageIndexProvinceComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageIndexProvinceComponent.prototype.edit = function (id) {
        this.router.navigate(['/vertical/create-province', { id: id }]);
    };
    PageIndexProvinceComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-province']);
    };
    PageIndexProvinceComponent.prototype.exportByExcel = function () {
        ExcelService.exportAsExcelFile(Province.listFromJSON(this.rows), 'provinces');
    };
    PageIndexProvinceComponent.prototype.exportAsXLSX = function () {
        this.getData(this.provinceService.getAllURL(), 'rows', 'exportByExcel');
    };
    PageIndexProvinceComponent.prototype.getProvince = function (jsonValue) {
        return Province.fromJSON(jsonValue);
    };
    PageIndexProvinceComponent.prototype.delete = function (id) {
        var _this = this;
        var message = "Confirmez-vous la suppression de l'objet ?";
        var dialogData = new ConfirmDialogModel('Confirmation', message);
        var dialogRef = this.dialog.open(ConfirmDialogComponent, {
            maxWidth: '500px',
            data: dialogData,
            panelClass: 'customDialog'
        });
        dialogRef.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult === true) {
                _this.provinceService.delete(id)
                    .subscribe(function (data) {
                    console.log(data);
                    _this.loadPage();
                }, function (error) { return console.log(error); });
            }
        });
    };
    return PageIndexProvinceComponent;
}(BasePageComponent));
export { PageIndexProvinceComponent };
