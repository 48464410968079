var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { FormBuilder } from '@angular/forms';
import { jqxDataTableComponent } from 'jqwidgets-ng/jqxdatatable';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { UserService } from '../../ui/administrateur/user.service';
import { RegionService } from '../../ui/region/region.service';
import { ValiderOffreVenteService } from '../../ui/valider-offre-vente/validerOffreVente.service';
import { ReseauService } from '../../ui/reseau/reseau.service';
var PageRapportsOffresVenteComponent = /** @class */ (function (_super) {
    __extends(PageRapportsOffresVenteComponent, _super);
    function PageRapportsOffresVenteComponent(store, dashboardService, httpSv, validerOffreVenteService, regionService, reseauService, adminService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dashboardService = dashboardService;
        _this.validerOffreVenteService = validerOffreVenteService;
        _this.regionService = regionService;
        _this.reseauService = reseauService;
        _this.adminService = adminService;
        _this.fb = fb;
        _this.produits = [];
        _this.regions = [];
        _this.produitsAdapter = [];
        _this.regionsAdapter = [];
        _this.reseaux = [];
        _this.reseauxAdapter = [];
        _this.Columnslabels = {
            dateDebut: 'Date début',
            dateFin: 'Date Fin'
        };
        _this.source = { localData: []
        };
        _this.localizationObject = _this.getLocalization();
        _this.exportSettings = _this.getExportSettings();
        _this.columns = [
            { text: 'Réseau', dataField: 'reseau', width: '10%', cellsAlign: 'left', align: 'left' },
            { text: 'Région', dataField: 'region', width: '10%', cellsAlign: 'left', align: 'left' },
            { text: 'Produit', dataField: 'nom_produit', width: '12%', cellsAlign: 'left', align: 'left' },
            { text: 'Date', dataField: 'date', width: '12%', align: 'left', cellsAlign: 'right', cellsFormat: 'd' },
            { text: 'Auteur', dataField: 'auteur', width: '18%', align: 'left', cellsAlign: 'left' },
            { text: 'PU', dataField: 'prixUnitaire', cellsAlign: 'right', width: '8%', align: 'right', cellsFormat: 'n' },
            { text: 'Montant', dataField: 'montant', cellsAlign: 'right', width: '10%', align: 'right', cellsFormat: 'n' },
            { text: 'Qte', dataField: 'quantite', cellsAlign: 'right', width: '8%', align: 'right', cellsFormat: 'n' },
            { text: 'U. Mesure', dataField: 'mesure', cellsAlign: 'center', width: '12%', align: 'center' }
        ];
        _this.pageData = {
            title: 'RAPPORT SUR LES OFFRES DE VENTE',
            breadcrumbs: [],
        };
        return _this;
    }
    PageRapportsOffresVenteComponent.prototype.getExportSettings = function () {
        var exportObj = {};
        exportObj.columnsHeader = true;
        exportObj.hiddenColumns = false;
        exportObj.serverURL = null;
        exportObj.characterSet = null;
        exportObj.recordsInView = true;
        exportObj.fileName = 'rapport_offres_vente';
        return exportObj;
    };
    PageRapportsOffresVenteComponent.prototype.getLocalization = function () {
        var localizationobj = {};
        localizationobj.pagerGoToPageString = 'Aller à la page:';
        localizationobj.pagerShowRowsString = 'Voir ligne:';
        localizationobj.pagerRangeString = ' de ';
        localizationobj.pagerNextButtonString = 'Suivant';
        localizationobj.pagerFirstButtonString = 'Premier';
        localizationobj.pagerLastButtonString = 'Dernier';
        localizationobj.pagerPreviousButtonString = 'Précedent';
        localizationobj.sortAscendingString = 'Ordre Ascendent';
        localizationobj.sortDescendingString = 'Ordre Descendant';
        localizationobj.sortRemoveString = 'Supprimer';
        localizationobj.emptydatastring = 'Pas de données';
        localizationobj.firstDay = 1;
        localizationobj.percentSymbol = '%';
        localizationobj.currencySymbol = '';
        localizationobj.currencySymbolPosition = 'après';
        localizationobj.decimalSeparator = ',';
        localizationobj.thousandsSeparator = ' ';
        localizationobj.filterapplystring = 'Appliquer';
        localizationobj.filtercancelstring = 'Annuler';
        localizationobj.filterclearstring = 'Effacer Filtre';
        localizationobj.filterstring = 'Avancé';
        localizationobj.filtersearchstring = 'Chercher';
        localizationobj.filterstringcomparisonoperators = ['vide', 'non vide', 'contient', 'contient(respecte la casse)',
            'Ne contient pas', 'Ne contient pas(respecte la casse)', 'commence par', 'commence par(respecte la casse)',
            'finit par', 'finit par(respecte la casse)', 'égale', 'égale(respecte la casse)', 'null', 'non null'];
        // tslint:disable-next-line:max-line-length
        localizationobj.filternumericcomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
        // tslint:disable-next-line:max-line-length
        localizationobj.filterdatecomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
        localizationobj.filterbooleancomparisonoperators = ['égale', 'non égale'];
        localizationobj.validationstring = 'La valeur entrée n\'est pas valide ';
        localizationobj.emptydatastring = 'Pas de données à afficher';
        localizationobj.filterselectstring = 'Selectionnez un filtre';
        localizationobj.loadtext = 'Chargement...';
        localizationobj.clearstring = 'Effacer';
        localizationobj.todaystring = 'Aujourd\'hui';
        // tslint:disable-next-line:max-line-length
        localizationobj.loadingerrormessage = 'Chargement en cours des données vous ne pouvez pas mettre à jour les propriétés ou appeler une méthode . Vous pourrez le faire après le chargement des données.';
        localizationobj.decimalSeparator = ',';
        localizationobj.thousandsSeparator = ' ';
        localizationobj.days = {
            // full day names
            names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            // abbreviated day names
            namesAbbr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            // shortest day names
            namesShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
        };
        localizationobj.patterns = {
            d: 'dd/MM/yyyy',
            D: 'dddd, d. MMMM yyyy',
            t: 'HH:mm',
            T: 'HH:mm:ss',
            f: 'dddd, d. MMMM yyyy HH:mm',
            F: 'dddd, d. MMMM yyyy HH:mm:ss',
            M: 'dd MMMM',
            Y: 'MMMM yyyy'
        };
        localizationobj.months = {
            // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
            names: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre', ''],
            // abbreviated month names
            namesAbbr: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
        };
        return localizationobj;
    };
    PageRapportsOffresVenteComponent.prototype.getWidth = function () {
        if (document.body.offsetWidth < 850) {
            return '95%';
        }
        return 850;
    };
    PageRapportsOffresVenteComponent.prototype.excelExport = function () {
        this.myDataTable.exportData('xls');
    };
    PageRapportsOffresVenteComponent.prototype.xmlExport = function () {
        this.myDataTable.exportData('xml');
    };
    PageRapportsOffresVenteComponent.prototype.csvExport = function () {
        this.myDataTable.exportData('csv');
    };
    PageRapportsOffresVenteComponent.prototype.tsvExport = function () {
        this.myDataTable.exportData('tsv');
    };
    PageRapportsOffresVenteComponent.prototype.htmlExport = function () {
        this.myDataTable.exportData('html');
    };
    PageRapportsOffresVenteComponent.prototype.jsonExport = function () {
        this.myDataTable.exportData('json');
    };
    PageRapportsOffresVenteComponent.prototype.pdfExport = function () {
        this.myDataTable.exportData('pdf');
    };
    PageRapportsOffresVenteComponent.prototype.printBtnOnClick = function () {
        var gridContent = this.myDataTable.exportData('html');
        var newWindow = window.open('', '', 'width=800, height=500'), document = newWindow.document.open(), pageContent = '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>ETAT DES PRIX</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
        document.write(pageContent);
        document.close();
        newWindow.print();
    };
    PageRapportsOffresVenteComponent.prototype.onRegionsPromise = function () {
        this.regionsAdapter = [];
        for (var _i = 0, _a = this.regions; _i < _a.length; _i++) {
            var region = _a[_i];
            this.regionsAdapter.push({ 'value': region.id.toString(), 'label': region.nom });
        }
    };
    PageRapportsOffresVenteComponent.prototype.onReseauxPromise = function () {
        this.reseauxAdapter = [];
        for (var _i = 0, _a = this.reseaux; _i < _a.length; _i++) {
            var reseau = _a[_i];
            this.reseauxAdapter.push({ 'value': reseau.id.toString(), 'label': reseau.code });
        }
    };
    PageRapportsOffresVenteComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.adminService.mesProduits().subscribe(function (produits) {
            _this.produits = produits;
            _this.produitsAdapter = [];
            for (var _i = 0, _a = _this.produits; _i < _a.length; _i++) {
                var produit = _a[_i];
                _this.produitsAdapter.push({ 'value': produit.id.toString(), 'label': produit.nom });
            }
        });
        this.initReactiveForm();
        this.reseauService.getCached().subscribe(function (data) {
            _this.reseaux = data;
            _this.onReseauxPromise();
        });
        this.regionService.getCached().subscribe(function (data) {
            _this.regions = data;
            _this.onRegionsPromise();
        });
        this.rapportsForm.get('reseau').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerOffreVenteService.getOffresValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('regions').value, _this.rapportsForm.get('reseau').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('regions').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerOffreVenteService.getOffresValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('regions').value, _this.rapportsForm.get('reseau').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('produits').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerOffreVenteService.getOffresValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('regions').value, _this.rapportsForm.get('reseau').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.setLoaded();
    };
    PageRapportsOffresVenteComponent.prototype.initReactiveForm = function () {
        this.rapportsForm = this.fb.group({
            produits: [''],
            regions: [''],
            reseau: ''
        });
    };
    PageRapportsOffresVenteComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dateDebut.culture('fr-FR');
        this.dateFin.culture('fr-FR');
        var pdateDebut = new Date();
        pdateDebut.setFullYear(pdateDebut.getFullYear(), 0, 1);
        var pdateFin = new Date();
        pdateFin.setFullYear(pdateFin.getFullYear(), pdateFin.getMonth(), pdateFin.getDate());
        this.dateDebut.setDate(pdateDebut);
        this.dateFin.setDate(pdateFin);
        this.dateFin.onValueChanged.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerOffreVenteService.getOffresValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('regions').value, _this.rapportsForm.get('reseau').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.dateDebut.onValueChanged.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.validerOffreVenteService.getOffresValides(_this.dateDebut.getDate().toLocaleDateString(), _this.dateFin.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.rapportsForm.get('produits').value, _this.rapportsForm.get('regions').value, _this.rapportsForm.get('reseau').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.validerOffreVenteService.getOffresValides(this.dateDebut.getDate().toLocaleDateString(), this.dateFin.getDate().toLocaleDateString(), 
        // tslint:disable-next-line:max-line-length
        this.rapportsForm.get('produits').value, this.rapportsForm.get('regions').value, this.rapportsForm.get('reseau').value).subscribe(function (datas) {
            _this.source.localData = datas;
            _this.dataAdapter = new jqx.dataAdapter(_this.source);
        });
    };
    PageRapportsOffresVenteComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return PageRapportsOffresVenteComponent;
}(BasePageComponent));
export { PageRapportsOffresVenteComponent };
