/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./v-timeline.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../badge/badge.component.ngfactory";
import * as i3 from "../badge/badge.component";
import * as i4 from "@angular/common";
import * as i5 from "./v-timeline.component";
var styles_TCVTimelineComponent = [i0.styles];
var RenderType_TCVTimelineComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCVTimelineComponent, data: {} });
export { RenderType_TCVTimelineComponent as RenderType_TCVTimelineComponent };
function View_TCVTimelineComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "line"]], null, null, null, null, null))], null, null); }
function View_TCVTimelineComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "box-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "tc-badge", [], [[2, "tc-badge", null], [2, "badge-xs", null], [2, "badge-sm", null], [2, "badge-lg", null], [2, "default", null], [2, "accent-badge", null], [2, "success-badge", null], [2, "info-badge", null], [2, "warning-badge", null], [2, "gradient-badge", null], [2, "error-badge", null], [2, "badge-outline", null]], null, null, i2.View_TCBadgeComponent_0, i2.RenderType_TCBadgeComponent)), i1.ɵdid(2, 114688, null, 0, i3.TCBadgeComponent, [], { view: [0, "view"] }, null), (_l()(), i1.ɵted(3, 0, ["", ""]))], function (_ck, _v) { var currVal_12 = (_v.parent.context.$implicit.sectionLabel.view ? _v.parent.context.$implicit.sectionLabel.view : "default"); _ck(_v, 2, 0, currVal_12); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 2).xs; var currVal_2 = i1.ɵnov(_v, 2).sm; var currVal_3 = i1.ɵnov(_v, 2).lg; var currVal_4 = i1.ɵnov(_v, 2).typeDefault; var currVal_5 = i1.ɵnov(_v, 2).typeAccent; var currVal_6 = i1.ɵnov(_v, 2).typeSuccess; var currVal_7 = i1.ɵnov(_v, 2).typeInfo; var currVal_8 = i1.ɵnov(_v, 2).typeWarning; var currVal_9 = i1.ɵnov(_v, 2).typeGradient; var currVal_10 = i1.ɵnov(_v, 2).typeError; var currVal_11 = i1.ɵnov(_v, 2).outline; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); var currVal_13 = _v.parent.context.$implicit.sectionLabel.text; _ck(_v, 3, 0, currVal_13); }); }
function View_TCVTimelineComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "h5 item-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); }); }
function View_TCVTimelineComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "item-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.date; _ck(_v, 2, 0, currVal_0); }); }
function View_TCVTimelineComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "item-desc"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.content; _ck(_v, 0, 0, currVal_0); }); }
function View_TCVTimelineComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "icon-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { "background": 0, "color": 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "content-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "item-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCVTimelineComponent_5)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCVTimelineComponent_6)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCVTimelineComponent_7)), i1.ɵdid(13, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _ck(_v, 5, 0, _v.context.$implicit.iconBg, _v.context.$implicit.iconColor); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_2); var currVal_3 = _v.context.$implicit.date; _ck(_v, 11, 0, currVal_3); var currVal_4 = _v.context.$implicit.content; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "item-icon ", (_v.context.$implicit.icon || "icofont-clock-time"), ""); _ck(_v, 2, 0, currVal_0); }); }
function View_TCVTimelineComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "timeline-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCVTimelineComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "box-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCVTimelineComponent_4)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showLabels && _v.context.$implicit.sectionLabel); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.sectionData; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TCVTimelineComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCVTimelineComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCVTimelineComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.align !== "center"); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TCVTimelineComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-v-timeline", [], [[2, "tc-v-timeline", null], [2, "show-years", null], [2, "dots", null], [2, "align-left", null], [2, "align-center", null], [2, "align-right", null], [2, "align-between", null]], null, null, View_TCVTimelineComponent_0, RenderType_TCVTimelineComponent)), i1.ɵdid(1, 114688, null, 0, i5.TCVTimelineComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).showLabels; var currVal_2 = i1.ɵnov(_v, 1).dots; var currVal_3 = i1.ɵnov(_v, 1).left; var currVal_4 = i1.ɵnov(_v, 1).center; var currVal_5 = i1.ɵnov(_v, 1).right; var currVal_6 = i1.ɵnov(_v, 1).between; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
var TCVTimelineComponentNgFactory = i1.ɵccf("tc-v-timeline", i5.TCVTimelineComponent, View_TCVTimelineComponent_Host_0, { showLabels: "showLabels", align: "align", showIcons: "showIcons", data: "data" }, {}, []);
export { TCVTimelineComponentNgFactory as TCVTimelineComponentNgFactory };
