import { Mesure } from './mesure';
import {Categorie} from './categorie';
import {Espece} from './espece';
import {Race} from './race';

export class Produit {
	id: number;
	code: string;
	nom: string;
	nomScientifique: string;
	actif: boolean;
	mesure: Mesure;
	categorie: Categorie;
	espece: Espece;
	race: Race;
	static fromJSON(json: any): Produit {
		const result = new Produit();
		for (const key in json) {
			result[key] = json[key];
		}

		return result;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const produit = Produit.fromJSON(jsonObject);
				jsonObject.espece = produit.nomEspece;
				jsonObject.race = produit.nomRace;
				jsonObject.categorie = produit.categorie.nom;
			    rval.push(jsonObject);
			}
		}
		return rval;
	}
	static listFromJSONToExport(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = {code: '', nom: '', categorie: '', espece: '', race:'', unite_de_mesure: ''};
				const produit = Produit.fromJSON(jsonObject);
				jsonObject.code = copyJson[key].code;
				jsonObject.nom = copyJson[key].nom;
				jsonObject.categorie = produit.categorie.nom;
				jsonObject.espece = produit.nomEspece;
				jsonObject.race = produit.nomRace;
				jsonObject.unite_de_mesure = copyJson[key].mesure.code;
				rval.push(jsonObject);
			}
		}
		return rval;
	}
	constructor() {
     	this.mesure = new Mesure();
		this.categorie = new Categorie();
		this.actif = true;

	}
	toString() {
		return this.nom;
	}

	get nomEspece() {
		if (this.espece != null) {
			return this.espece.nom;
		}
		return '';
	}
	get nomRace() {
		if (this.race != null) {
			return this.race.nom;
		}
		return '';
	}
	get categoriePrincipale() {
		if (this.espece != null) {
			return this.espece.nom;
		}
		return '';
	}
	get getFullName(): string {
		return (this.nom  + ' (' + this.code + ')');
	}
}
