export class Mesure {
	id: number;
	code: string;
	nom: string;
 	typeMesure: string;
 	actif: boolean;
	inclureEtalonnage: boolean;
	constructor() {
        this.typeMesure = 'Locale';
        this.actif = true;
        this.inclureEtalonnage = true;
	}
	toString() {
		return this.code;
	}
}
