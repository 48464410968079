import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import {ActivatedRoute, Route, Router} from '@angular/router';
import { DepotService } from '../depot.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import {ExcelService} from '../../../../services/sharedServices/excel-service.service';
import {Depot} from '../../models/depot';
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {fromEvent, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {DepotsDatasource} from '../depots.datasource';


@Component({
  selector: 'tc-depot-index',
  templateUrl: './depot-index.component.html',
 styleUrls: ['./depot-index.component.scss']
})
export class PageIndexDepotComponent extends BasePageComponent implements  OnInit, AfterViewInit, OnDestroy {
	rows: any[];
    productCount = 0;
    dataSource: DepotsDatasource;
    sortField = 'nom';
    pageIndex = 0;
    pageSize = 5;
    // MatPaginator Output
    displayedColumns = ['code', 'nom', 'mobile', 'commune', 'actions'];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @ViewChild(MatSort, { static: false }) sort: MatSort;

    @ViewChild('input', { static: false }) input: ElementRef;
   constructor( store: Store<IAppState>,
	httpSv: HttpService, private depotService: DepotService,
	public dialog: MatDialog,
    private router: Router, private route: ActivatedRoute
	) {
		super(store, httpSv);
  this.pageData = {
      subTitle: '' ,
      title: 'LISTE DES DEPOTS',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Tableau de bord',
          route: 'dashboard'
        }
      ]
	};
	this.rows = [];
}


  ngOnInit() {
	   super.ngOnInit();
      this.dataSource = new DepotsDatasource(this.depotService);
      this.depotService.findCount().subscribe(count => {
              this.productCount = count;

              if (sessionStorage.getItem('depotPageIndex') != null) {
                  this.pageIndex = +sessionStorage.getItem('depotPageIndex');
              } else {
                  this.pageIndex = 0 ;
              }
          if (sessionStorage.getItem('depotPageSize') != null) {
              this.pageSize = (+sessionStorage.getItem('depotPageSize') > 0) ? +sessionStorage.getItem('depotPageSize') : 5;
          } else {
              this.pageSize = 5 ;
          }
          this.paginator.pageIndex = this.pageIndex ;
          this.paginator.pageSize = this.pageSize ;
          this.dataSource.loadDepots('', this.sortField, 'asc', this.pageIndex, this.pageSize);

          }
      );

  }
    ngAfterViewInit() {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                   this.paginator.pageIndex = 0;
                    this.loadPage();
                })
            )
            .subscribe();

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadPage())
            ).subscribe();
        this.paginator.page.subscribe(
            (page) => {
                this.pageIndex = page.pageIndex;
                this.pageSize = page.pageSize;
                sessionStorage.setItem('depotPageIndex', page.pageIndex) ;
                if (page.pageSize > 0) {
                sessionStorage.setItem('depotPageSize', page.pageSize) ;
                }
            }

        );

    }

    loadPage() {
        this.dataSource.loadDepots(
            this.input.nativeElement.value,
            this.sortField,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    setLoaded(during: number = 0) {
       super.setLoaded(during);
     }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  edit(id: any) {
   	   this.router.navigate(['/vertical/create-depot', { id: id}]);
  }
   ajouter() {
	  this.router.navigate(['/vertical/create-depot']);
  }
    exportByExcel() {
        ExcelService.exportAsExcelFile(Depot.listFromJSON(this.rows), 'depots');
    }
    exportAsXLSX() {
        this.getData(this.depotService.getAllURL(), 'rows', 'exportByExcel');
    }
    getDepot (jsonValue): Depot {
        return Depot.fromJSON(jsonValue) ;
    }


    delete(id: number) {
     const message = `Confirmez-vous la suppression de l'objet ?`;

    const dialogData = new ConfirmDialogModel('Confirmation', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '500px',
	  data: dialogData,
	  panelClass: 'customDialog'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
	  if (dialogResult === true) {
	    this.depotService.delete(id)
      .subscribe(
        data => {
            this.loadPage();
        },
		error => console.log(error));
	}
    });
  }
  }

