<ng-container *ngIf="info">
	<div class="col-sm-8">
		<tc-card [title]="''">
			<form [formGroup]="infoForm"   (ngSubmit)="onSubmit()">

				<tc-form-group>
					<tc-form-label>{{Columnslabels.titre}} </tc-form-label>
					<tc-input type="text"  formControlName="titre" >
					</tc-input>
					<ng-container *ngIf="infoForm.get('titre').invalid && (infoForm.get('titre').touched || infoForm.get('titre').dirty)">
						<span @fadeIn class="error" *ngIf="infoForm.get('titre').getError('required')">Veuillez saisir le code de la information</span>
						<span @fadeIn class="error" *ngIf="infoForm.get('titre').getError('min')">Le nom doit avoir plus de 2 caractères</span>
					</ng-container>
				</tc-form-group>

				<tc-form-group>
					<tc-form-label>{{Columnslabels.contenu}}</tc-form-label>
					<tc-textarea  [charLimiter]="{ counter: 500, before: 'Il reste ', after: ' caractères' }"
								  [tcShape]="0" [rows]="4" formControlName="contenu"></tc-textarea>
				</tc-form-group>



				<tc-form-group>
					<tc-form-label>{{Columnslabels.url}} </tc-form-label>
					<tc-input type="text"  formControlName="url" >
					</tc-input>
					<ng-container *ngIf="infoForm.get('url').invalid && (infoForm.get('url').touched || infoForm.get('url').dirty)">
						<span @fadeIn class="error" *ngIf="infoForm.get('url').getError('required')">Veuillez saisir l'URL de l'information</span>
						<span @fadeIn class="error" *ngIf="infoForm.get('url').getError('min')">L'URL doit avoir plus de 2 caractères</span>
					</ng-container>
				</tc-form-group>


				<tc-form-group>
					<tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
				</tc-form-group>

				<span *ngIf="!infoForm.valid && infoForm.touched" class="help-block">Veuillez entrer des données valides !</span>
				<button [disabled]="!infoForm.valid" tc-button [block]="true" >
					Valider
				</button>




			</form>

		</tc-card>
	</div>
</ng-container>

