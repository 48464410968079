import { OnInit } from '@angular/core';
var NavbarSkeletonComponent = /** @class */ (function () {
    function NavbarSkeletonComponent() {
        this.withLogo = true;
    }
    Object.defineProperty(NavbarSkeletonComponent.prototype, "horizontal", {
        get: function () {
            return this.type === 'horizontal';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarSkeletonComponent.prototype, "vertical", {
        get: function () {
            return this.type === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    NavbarSkeletonComponent.prototype.ngOnInit = function () {
    };
    return NavbarSkeletonComponent;
}());
export { NavbarSkeletonComponent };
