import {Province} from './province';

export class Commune {
	id: number;
	code: string;
	nom: string;
	provinceTab: string;
	province: Province;
	static fromJSON(json: any): Commune {
		const result = new Commune();
		for (const key in json) {
			result[key] = json[key];
		}

		return result;
	}
	get nomProvince() {
		if (this.province != null) {
			return this.province.nom;
		}
		return '--';
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const commune = Commune.fromJSON(jsonObject);

				if (commune.province != null) {
					jsonObject.province = commune.province.nom;
				}

				rval.push(jsonObject);
			}
		}

		return rval;
	}
	constructor() {
		this.province = new Province();
	}
}
