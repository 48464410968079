var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ReseauService } from '../reseau.service';
import { ToastrService } from 'ngx-toastr';
import { Reseau } from '../../models/reseau';
import { FormBuilder, Validators } from '@angular/forms';
import { TypeSIMService } from '../typeSIMService.service';
import { ProduitService } from '../../produit/produit.service';
import { DepotService } from '../../depot/depot.service';
import { MarcheService } from '../../marche/marche.service';
import { MesureService } from '../../mesure/mesure.service';
import { UserService } from '../../administrateur/user.service';
import { QualiteService } from '../../qualite/qualite.service';
var PageCreateReseauComponent = /** @class */ (function (_super) {
    __extends(PageCreateReseauComponent, _super);
    function PageCreateReseauComponent(store, httpSv, reseauService, route, router, toastr, fb, typeSIMService, produitService, depotService, marcheService, qualiteService, mesureService, userService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.reseauService = reseauService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.typeSIMService = typeSIMService;
        _this.produitService = produitService;
        _this.depotService = depotService;
        _this.marcheService = marcheService;
        _this.qualiteService = qualiteService;
        _this.mesureService = mesureService;
        _this.userService = userService;
        _this.submitted = false;
        _this.changes = false;
        _this.typesSIM = [];
        _this.depotsAdapter = [];
        _this.adminsAdapter = [];
        _this.marchesAdapter = [];
        _this.produitsAdapter = [];
        _this.mesuresAdapter = [];
        _this.qualitesAdapter = [];
        _this.Columnslabels = {
            code: 'Code Réseau',
            label: 'Libellé Réseau',
            description: 'Description',
            typeSIM: 'Organisation',
            produits: 'Produits',
            marches: 'Marchés',
            depots: 'Dépôts',
            mesures: 'Mesures',
            qualites: 'Qualités',
            administrateur: 'Administrateur Principal',
            actif: 'Actif'
        };
        _this.reseau = new Reseau();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des réseaux',
                    route: 'list-reseaux'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateReseauComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.reseau) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateReseauComponent.prototype.getReseau = function (jsonValue) {
        return Reseau.fromJSON(jsonValue);
    };
    PageCreateReseauComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier un Réseau ' : ' Créer un Réseau ';
    };
    PageCreateReseauComponent.prototype.isEditing = function () {
        return (this.reseau.id != null);
    };
    PageCreateReseauComponent.prototype.initReactiveForm = function () {
        this.reseauForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            typeSIM: ['AUTRE', Validators.required],
            adminPrincipal: [''],
            description: [''],
            produits: [''],
            marches: [''],
            depots: [''],
            mesures: [''],
            qualites: [''],
            actif: [true]
        });
    };
    PageCreateReseauComponent.prototype.onProduitsPromise = function () {
        this.produitsAdapter = [];
        for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
            var produit = _a[_i];
            this.produitsAdapter.push({ 'value': produit.id.toString(), 'label': produit.nom });
        }
    };
    PageCreateReseauComponent.prototype.onDepotsPromise = function () {
        this.depotsAdapter = [];
        for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
            var depot = _a[_i];
            this.depotsAdapter.push({ 'value': depot.id.toString(), 'label': depot.nom });
        }
    };
    PageCreateReseauComponent.prototype.onMarchesPromise = function () {
        this.marchesAdapter = [];
        for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
            var marche = _a[_i];
            this.marchesAdapter.push({ 'value': marche.id.toString(), 'label': marche.nom });
        }
    };
    PageCreateReseauComponent.prototype.onMesuresPromise = function () {
        this.mesuresAdapter = [];
        for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
            var mesure = _a[_i];
            this.mesuresAdapter.push({ 'value': mesure.id.toString(), 'label': mesure.nom });
        }
    };
    PageCreateReseauComponent.prototype.onQualitesPromise = function () {
        this.qualitesAdapter = [];
        for (var _i = 0, _a = this.qualites; _i < _a.length; _i++) {
            var qualite = _a[_i];
            this.qualitesAdapter.push({ 'value': qualite.id.toString(), 'label': qualite.nom });
        }
    };
    PageCreateReseauComponent.prototype.setReactiveForm = function () {
        var reseau = this.getReseau(this.reseau);
        var produits = reseau.selectedProducts !== [] ? reseau.selectedProducts : '';
        var marches = reseau.selectedMarches !== [] ? reseau.selectedMarches : '';
        var depots = reseau.selectedDepots !== [] ? reseau.selectedDepots : '';
        var mesures = reseau.selectedMesures !== [] ? reseau.selectedMesures : '';
        var qualites = reseau.selectedQualites !== [] ? reseau.selectedQualites : '';
        var adminPrincipalId = (this.reseau.adminPrincipal != null) ? this.reseau.adminPrincipal.id.toString() : '';
        console.log('marchesAdapter', this.marchesAdapter);
        console.log('marches', marches);
        this.reseauForm.patchValue({
            code: this.reseau.code,
            nom: this.reseau.nom,
            typeSIM: this.reseau.typeSIM,
            description: this.reseau.description,
            actif: this.reseau.actif,
            produits: produits,
            adminPrincipal: adminPrincipalId,
            marches: marches,
            depots: depots,
            mesures: mesures,
            qualites: qualites
        });
    };
    PageCreateReseauComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.reseauService.getURL(_this.selectedId), 'reseau', 'reseau', 'setLoaded');
            }
            else {
                _this.reseau = new Reseau();
                _this.initReactiveForm();
            }
        });
        this.getData(this.typeSIMService.getAllURL(), 'typesSIM');
        this.reseauService.findAsTree().subscribe(function (tree) {
            _this.treeReseau = tree;
        });
        this.getData(this.produitService.getAllURL(), 'produits', 'onProduitsPromise');
        this.getData(this.marcheService.getAllURL(), 'marches', 'onMarchesPromise');
        this.getData(this.depotService.getAllURL(), 'depots', 'onDepotsPromise');
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.getData(this.qualiteService.getAllURL(), 'qualites', 'onQualitesPromise');
        this.userService.getAll('Administrateur').subscribe(function (admins) {
            _this.admins = admins;
            _this.adminsAdapter = [];
            for (var _i = 0, _a = _this.admins; _i < _a.length; _i++) {
                var admin = _a[_i];
                _this.adminsAdapter.push({ 'value': admin.id.toString(), 'label': admin.nomCompletEtReseau });
            }
        });
        // detect form changes
        this.reseauForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
    };
    PageCreateReseauComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateReseauComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-reseau']);
    };
    PageCreateReseauComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-reseau']);
    };
    PageCreateReseauComponent.prototype.mapFormValuesToModel = function () {
        this.reseau.code = this.reseauForm.value.code;
        this.reseau.nom = this.reseauForm.value.nom;
        this.reseau.description = this.reseauForm.value.description;
        this.reseau.actif = this.reseauForm.value.actif;
        this.reseau.typeSIM = this.reseauForm.value.typeSIM;
        this.reseau.adminPrincipal = this.reseauForm.value.adminPrincipal;
        this.reseau.produits = this.reseauForm.value.produits;
        this.reseau.marches = this.reseauForm.value.marches;
        this.reseau.depots = this.reseauForm.value.depots;
        this.reseau.mesures = this.reseauForm.value.mesures;
        this.reseau.qualites = this.reseauForm.value.qualites;
    };
    PageCreateReseauComponent.prototype.update = function () {
        var _this = this;
        if (this.reseauForm.valid) {
            this.mapFormValuesToModel();
            this.reseauService.update(this.reseau.id, this.reseau)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-reseau']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateReseauComponent.prototype.create = function () {
        var _this = this;
        if (this.reseauForm.valid) {
            this.mapFormValuesToModel();
            this.reseauService.create(this.reseau)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie ', '', { timeOut: 2000 });
                _this.reseauForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateReseauComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateReseauComponent;
}(BasePageComponent));
export { PageCreateReseauComponent };
