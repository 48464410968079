import {Component,  OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {ValiderStockService} from '../valider-stock/validerStock.service';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';
import {IEtalonnage} from '../valider-etalonnages/valider-etalonnage.component';
import {ValiderEtalonnageService} from '../valider-etalonnages/validerEtalonnage.service';


export interface IStock {
    id: number;
    date: Date;
    nom_produit: string;
    stock: number;
    nom_depot: string;
    mesure: string;
}


@Component({
  selector: 'tc-liste-marche-valides',
    templateUrl: './liste-etalonnage-valides.component.html',
    styleUrls: ['./liste-etalonnage-valides.component.scss'],
    animations: [fadeIn]
})

export class ListeEtalonnageValidesComponent extends BasePageComponent implements OnInit, OnDestroy {

  dateValidation = new Date();
  data: any;
  etalonnagesValides: IEtalonnage[] = [];
    outData: any[] = [];

    /******************/

    isAllDisplayDataChecked = false;
    isOperating = false;
    isIndeterminate = false;
    listOfDisplayData: IEtalonnage[] = [];
    listOfAllDataEtalonnage: IEtalonnage[] = [];
    mapOfCheckedId: { [key: string]: boolean } = {};
    numberOfChecked = 0;
    /******************************/

    devalider() {
        this.outData = [];
        this.listOfAllDataEtalonnage.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                etalonnages: this.outData
            }};
        if (this.outData.length > 0) {
            this.validerEtalonnageService.devalider(this.data) .subscribe(
                data => {
                    this.toastr.success('Dé-Validation réussie des étalonnages selectionnées', '',
                        {timeOut: 2000});
                    this.loadStock();
                },
                error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }


  constructor(store: Store<IAppState>,
              httpSv: HttpService, private marcheService: MarcheService,
              private route: ActivatedRoute, private authService: AuthenticationService,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService,
              private validerEtalonnageService: ValiderEtalonnageService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadStock();
  }

    private loadStock() {
        this.listOfAllDataEtalonnage = [];
        this.validerEtalonnageService.getAllValides().subscribe(data => {
                this.etalonnagesValides = data;
                this.etalonnagesValides.forEach((etalonnage, index, array) => {
                    this.listOfAllDataEtalonnage.push({
                        id: etalonnage.id,
                        uml: etalonnage.uml,
                        marche: etalonnage.marche,
                        ul: etalonnage.ul,
                        valeur: etalonnage.valeur
                    });
                });
            }
        );
        this.mapOfCheckedId = {};
        this.refreshStatus();

    }

    ngOnDestroy() {
    super.ngOnDestroy();
  }

    currentPageDataChange($event: IEtalonnage[]): void {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    }

    refreshStatus(): void {
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(item => this.mapOfCheckedId[item.id]);
        this.isIndeterminate =
            this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
            !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.etalonnagesValides.filter(item => this.mapOfCheckedId[item.id]).length;
    }

    checkAll(value: boolean): void {
        this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
        this.refreshStatus();
    }

    operateData(): void {
        this.isOperating = true;
        setTimeout(() => {
            this.etalonnagesValides.forEach(item => (this.mapOfCheckedId[item.id] = false));
            this.refreshStatus();
            this.isOperating = false;
        }, 1000);
    }
    exportByExcel() {
        ExcelService.exportAsExcelFile(this.etalonnagesValides, 'Etalonnages_valides');
    }


}
