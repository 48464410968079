import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import {BasePageComponent} from '../../base-page';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {Marche} from '../models/marche';
import {UserService} from '../administrateur/user.service';
export interface IMarchePosition {
  id: number;
  nom: string;
  longitude: number;
  latitude: number;
}
@Component({
  selector: 'tc-markets-maps',
  templateUrl: './markets-maps.component.html',
  styleUrls: ['./markets-maps.component.scss']
})

export class PageMarketMapsComponent extends BasePageComponent implements OnInit, OnDestroy {
	lat: number;
	lng: number;
    marches: any[];
    marchesAdapter: IMarchePosition[] = [];
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService, private adminService: UserService
  ) {
    super(store, httpSv);

    this.pageData = {
      title: 'Carte des marchés',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Tableau de bord',
          route: 'dashboard'
        }
      ],
      fullFilled: true
    };
		this.lng = -2.9197048;
		this.lat = 12.5324634;
	}
  getMarche (jsonValue): Marche {
    return Marche.fromJSON(jsonValue) ;
  }

  ngOnInit() {
    super.ngOnInit();
    this.adminService.mesMarches().subscribe(marches => {
      this.marches = marches ;
      this.marchesAdapter = [];
      for (const marche of this.marches) {
        this.marchesAdapter.push({id: marche.id, nom: marche.nom, longitude: marche.longitude, latitude: marche.latitude});
      }
      this.setLoaded();
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
