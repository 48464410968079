<ng-container *ngIf="depot && typologies && communeTree  ">
<div class="col-sm-8">
 <tc-card [title]="''">
    <form [formGroup]="depotForm"   (ngSubmit)="onSubmit()">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                        <tc-input type="text"  formControlName="code" >
                        </tc-input>
                        <ng-container *ngIf="depotForm.get('code').invalid && (depotForm.get('code').touched || depotForm.get('code').dirty)">
                            <span @fadeIn class="error" *ngIf="depotForm.get('code').getError('required')">Veuillez saisir le code du dépôt</span>
                            <span @fadeIn class="error" *ngIf="depotForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                        </ng-container>
                    </tc-form-group>

                <tc-form-group>
                     <tc-form-label>{{Columnslabels.label}}</tc-form-label>
                    <tc-input type="text"  formControlName="nom">
                    </tc-input>
                    <ng-container *ngIf="depotForm.get('nom').invalid && (depotForm.get('nom').touched || depotForm.get('nom').dirty)">
                        <span @fadeIn class="error" *ngIf="depotForm.get('nom').getError('required')">Veuillez saisir le nom du dépôt</span>
                        <span @fadeIn class="error" *ngIf="depotForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                    </ng-container>
                </tc-form-group>


        <tc-form-group>
                    <tc-form-label>{{Columnslabels.mobile}} </tc-form-label>
                    <tc-input type="text"  formControlName="mobile" >
                    </tc-input>
                    <ng-container *ngIf="depotForm.get('mobile').invalid && (depotForm.get('mobile').touched || depotForm.get('mobile').dirty)">
                        <span @fadeIn class="error" *ngIf="depotForm.get('mobile').getError('required')">Veuillez saisir le N° Mobile du contact</span>
                        <span @fadeIn class="error" *ngIf="depotForm.get('mobile').getError('min')">Le numero contact n'est pas valide</span>
                    </ng-container>
                </tc-form-group>

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.longitude}}</tc-form-label>
                        <tc-input type="number"  formControlName="longitude">
                        </tc-input>
                    </tc-form-group>

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.latitude}}</tc-form-label>
                        <tc-input type="number"  formControlName="latitude">
                        </tc-input>
                    </tc-form-group>


                        <ng-container *ngIf="communeTree.length>0">
                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.commune}}</tc-form-label>
                                <nz-cascader [nzOptions]="communeTree"
                                             [nzShowSearch]="true"
                                             [nzAutoFocus]="true"
                                             [nzSize]="'large'"
                                             formControlName="commune">

                                </nz-cascader>
                            </tc-form-group>
                        </ng-container>

                                <tc-form-group>
                                    <tc-form-label>{{Columnslabels.uniteMesure}}</tc-form-label>
                                    <tc-select [options]="mesuresAdapter"  formControlName="mesure"  ></tc-select>
                                </tc-form-group>

                    <tc-form-group>
                        <tc-checkbox formControlName="actif" >Actif</tc-checkbox>
                    </tc-form-group>

                <span *ngIf="!depotForm.valid && depotForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                <button [disabled]="!depotForm.valid" tc-button [block]="true" >
                    Valider
                </button>


    </form>

</tc-card>
</div>
</ng-container>

