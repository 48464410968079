export class Qualite {
	id: number;
	code: string;
	nom: string;
	actif: boolean;
	coefficient: number;
	constructor() {
		this.actif = true;
	}
}
