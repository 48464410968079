import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {IJOB} from '../../../ui/interfaces/iJob';

@Injectable({
  providedIn: 'root'
})
export class MonitorAlerteService {
	private baseUrl = '/api/monitorJob';
  constructor(private http: HttpClient) {

  }

  // tslint:disable-next-line:max-line-length
  getTriggers(statut: string): Observable<IJOB[]> {
    return this.http.get(`${this.baseUrl}`, {
      params: new HttpParams()
          .set('statut', statut)
    }) as Observable<IJOB[]>;
  }

}

