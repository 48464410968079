var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { MarcheService } from '../marche.service';
import { ToastrService } from 'ngx-toastr';
import { Marche } from '../../models/marche';
import { CommuneService } from '../../commune/commune.service';
import { NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TypologieService } from '../typologie.service';
import { PeriodiciteService } from '../periodicite.service';
var PageCreateMarcheComponent = /** @class */ (function (_super) {
    __extends(PageCreateMarcheComponent, _super);
    function PageCreateMarcheComponent(store, httpSv, marcheService, route, router, toastr, typologieService, communeService, periodiciteService, i18n, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.marcheService = marcheService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.typologieService = typologieService;
        _this.communeService = communeService;
        _this.periodiciteService = periodiciteService;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.filterable = true;
        _this.communeTree = [];
        _this.typologies = [];
        _this.periodicites = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code Marché',
            label: 'Libellé Marché',
            typologie: 'Type Marché',
            longitude: 'Longitude',
            latitude: 'Latitude',
            periodicite: 'Périodicité',
            commune: 'Commune'
        };
        _this.marche = new Marche();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des marches',
                    route: 'list-marche'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateMarcheComponent.prototype.ngAfterViewInit = function () {
    };
    PageCreateMarcheComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.marche) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateMarcheComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier un marché ' : ' Créer un marché ';
    };
    PageCreateMarcheComponent.prototype.isEditing = function () {
        return (this.marche.id != null);
    };
    PageCreateMarcheComponent.prototype.initReactiveForm = function () {
        this.marcheForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            typologie: ['', [Validators.required]],
            periodicite: ['', [Validators.required]],
            longitude: [''],
            latitude: [''],
            commune: [''],
            actif: [true]
        });
    };
    PageCreateMarcheComponent.prototype.setReactiveForm = function () {
        var commune = (this.marche.communeTab != null) ? this.marche.communeTab : ['', '', ''];
        this.marcheForm.patchValue({
            code: this.marche.code,
            nom: this.marche.nom,
            typologie: this.marche.typologie,
            longitude: this.marche.longitude,
            latitude: this.marche.latitude,
            actif: this.marche.actif,
            periodicite: this.marche.periodicite,
            commune: commune
        });
    };
    PageCreateMarcheComponent.prototype.getMarche = function (jsonValue) {
        return Marche.fromJSON(jsonValue);
    };
    PageCreateMarcheComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getData(this.typologieService.getAllURL(), 'typologies');
        this.getData(this.periodiciteService.getAllURL(), 'periodicites');
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.marcheService.getURL(_this.selectedId), 'marche', 'marche', 'setLoaded');
            }
            else {
                _this.marche = new Marche();
                _this.initReactiveForm();
            }
        });
        this.communeService.findAsTree().subscribe(function (tree) {
            _this.communeTree = tree;
        });
        // detect form changes
        this.marcheForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
    };
    PageCreateMarcheComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateMarcheComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-marche']);
    };
    PageCreateMarcheComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-marche']);
    };
    PageCreateMarcheComponent.prototype.mapFormValuesToModel = function () {
        this.marche.code = this.marcheForm.value.code;
        this.marche.nom = this.marcheForm.value.nom;
        this.marche.typologie = this.marcheForm.value.typologie;
        this.marche.longitude = this.marcheForm.value.longitude;
        this.marche.latitude = this.marcheForm.value.latitude;
        this.marche.actif = this.marcheForm.value.actif;
        this.marche.periodicite = this.marcheForm.value.periodicite;
        this.marche.commune = this.marcheForm.value.commune[2];
    };
    PageCreateMarcheComponent.prototype.update = function () {
        var _this = this;
        if (this.marcheForm.valid) {
            this.mapFormValuesToModel();
            this.marcheService.update(this.marche.id, this.marche)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-marche']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateMarcheComponent.prototype.create = function () {
        var _this = this;
        if (this.marcheForm.valid) {
            this.mapFormValuesToModel();
            this.marcheService.create(this.marche)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie  ', '', { timeOut: 2000 });
                _this.marcheForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateMarcheComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateMarcheComponent;
}(BasePageComponent));
export { PageCreateMarcheComponent };
