import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Acteur } from '../../pages/ui/models/acteur';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./authentication.service";
var AdminGuardService = /** @class */ (function () {
    function AdminGuardService(router, authService) {
        this.router = router;
        this.authService = authService;
    }
    AdminGuardService.prototype.canActivate = function (route, state) {
        var connectedUser = Acteur.fromJSON(this.authService.currentUserDataObject);
        if (connectedUser !== null) {
            if (connectedUser.isGroupeAdmin || connectedUser.isAnimateur) {
                return true;
            }
            else {
                this.router.navigate(['/error/506']);
                return false;
            }
        }
        else {
            this.router.navigate(['/public/sign-in']);
            return false;
        }
    };
    AdminGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuardService_Factory() { return new AdminGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService)); }, token: AdminGuardService, providedIn: "root" });
    return AdminGuardService;
}());
export { AdminGuardService };
