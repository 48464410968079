import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Marche} from '../models/marche';
import {INoeud} from '../produit/create';
const CACHE_KEY = 'sima-marche-cache';
@Injectable({
  providedIn: 'root'
})
export class MarcheService {
	private baseUrl = '/api/marche';
    private marches;
  constructor(private http: HttpClient) {

  }
  findAll(filter = '', sortField = 'nom', sortOrder = 'asc',
          offset = 0, pageSize = 10): Observable<Marche[]> {


    return this.http.get('/api/marche/pages', {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sortField)
          .set('sortOrder', sortOrder)
          .set('offset', offset.toString())
          .set('max', pageSize.toString())
    }) as Observable<Marche[]>;
  }
  findCount(): Observable<number> {
    return this.http.get('/api/marche/count')
        .pipe(
            map(res => res['total'])
        );
  }
  findAsTree(): Observable<INoeud[]> {
    return this.http.get(`${this.baseUrl}/tree`)
        .pipe(
            map(res => res['tree'])
        );
  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getCached(): Observable<any[]> {
    this.marches = this.http.get<any>(`${this.baseUrl}/short`)
        .pipe(
            map(data => data.items)
        );

    this.marches.subscribe(next => {
          localStorage[CACHE_KEY] = JSON.stringify(next);
        }

    );
    this.marches = this.marches.pipe(
        startWith(JSON.parse(localStorage[CACHE_KEY] || '[]'))
    );
    return this.marches;
  }
  getCachedURL(): string {
    return `${this.baseUrl}/short`;
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }

  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj);
  }
  update(id: number, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/modifier/${id}`, value);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

}

