/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./textarea.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./textarea.component";
var styles_TCTextareaComponent = [i0.styles];
var RenderType_TCTextareaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCTextareaComponent, data: {} });
export { RenderType_TCTextareaComponent as RenderType_TCTextareaComponent };
function View_TCTextareaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "limiter-before"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.limiter.before; _ck(_v, 1, 0, currVal_0); }); }
function View_TCTextareaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "limiter-after"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.limiter.after; _ck(_v, 1, 0, currVal_0); }); }
function View_TCTextareaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "char-limiter"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCTextareaComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "limiter-counter"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCTextareaComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.limiter.before; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.limiter.after; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.limiter.counter; _ck(_v, 4, 0, currVal_1); }); }
export function View_TCTextareaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "tc-textarea-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "textarea", [["class", "textarea-control"]], [[8, "placeholder", 0], [1, "maxlength", 0], [1, "rows", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "blur"], [null, "focus"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.value = $event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.onBlur(_co.disabled) !== false);
        ad = (pd_5 && ad);
    } if (("focus" === en)) {
        var pd_6 = (_co.onFocus(_co.disabled) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(4, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCTextareaComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.getStyles(); _ck(_v, 3, 0, currVal_10); var currVal_11 = i1.ɵinlineInterpolate(1, "", _co.name, ""); var currVal_12 = _co.value; _ck(_v, 6, 0, currVal_11, currVal_12); var currVal_13 = _co.charLimiter; _ck(_v, 10, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, " ", _co.placeholder, ""); var currVal_1 = _co.charLimiter; var currVal_2 = _co.rows; var currVal_3 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 8).ngClassValid; var currVal_8 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_TCTextareaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tc-textarea", [], [[2, "textarea-focus", null], [2, "textarea-not-resize", null], [4, "height", null], [2, "tc-textarea", null], [2, "textarea-disabled", null], [2, "textarea-readonly", null]], [[null, "input"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onInput($event.target) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_TCTextareaComponent_0, RenderType_TCTextareaComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.TCTextareaComponent]), i1.ɵdid(2, 114688, null, 0, i4.TCTextareaComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).focus; var currVal_1 = i1.ɵnov(_v, 2).getResize; var currVal_2 = i1.ɵnov(_v, 2).controlHeight; var currVal_3 = true; var currVal_4 = i1.ɵnov(_v, 2).disabled; var currVal_5 = i1.ɵnov(_v, 2).readonly; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
var TCTextareaComponentNgFactory = i1.ɵccf("tc-textarea", i4.TCTextareaComponent, View_TCTextareaComponent_Host_0, { disabled: "disabled", readonly: "readonly", name: "name", placeholder: "placeholder", charLimiter: "charLimiter", required: "required", innerValue: "value", bgColor: "bgColor", borderColor: "borderColor", color: "color", autoGrow: "autoGrow", resize: "resize", height: "height", rows: "rows" }, {}, []);
export { TCTextareaComponentNgFactory as TCTextareaComponentNgFactory };
