var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { MarcheService } from '../marche/marche.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProduitService } from '../produit/produit.service';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { MesureService } from '../mesure/mesure.service';
import { PrixService } from './prix.service';
import { UserService } from '../administrateur/user.service';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
var SaisiePrixComponent = /** @class */ (function (_super) {
    __extends(SaisiePrixComponent, _super);
    function SaisiePrixComponent(store, httpSv, marcheService, route, router, toastr, produitService, mesureService, fb, i18n, prixService, adminService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.marcheService = marcheService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.produitService = produitService;
        _this.mesureService = mesureService;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.prixService = prixService;
        _this.adminService = adminService;
        _this.marchesAdapter = [];
        _this.mesuresAdapter = [];
        _this.editCache = {};
        _this.listOfData = [];
        _this.outData = [];
        _this.dateTimeInputSettings = {
            formatString: 'd', width: '200px', height: '25px'
        };
        _this.pageData = {
            title: 'Saisie des prix',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        _this.marches = [];
        return _this;
    }
    SaisiePrixComponent.prototype.ngAfterViewInit = function () {
        //   this.mydatePrix.createComponent(this.dateTimeInputSettings);
    };
    SaisiePrixComponent.prototype.saveEdit = function (id) {
        var index = this.listOfData.findIndex(function (item) { return item.id === id; });
        Object.assign(this.listOfData[index], this.editCache[id].data);
        this.editCache[id].edit = true;
    };
    SaisiePrixComponent.prototype.updateEditCache = function () {
        var _this = this;
        this.listOfData.forEach(function (item) {
            _this.editCache[item.id] = {
                edit: true,
                data: __assign({}, item)
            };
        });
    };
    SaisiePrixComponent.prototype.enregistrer = function () {
        var _this = this;
        // const result = this.editCache.filter(o => (o.prixGros > 0) || (o.prixDetail > 0));
        // console.log ('Filtre elements modifiés', [this.editCache, result]);
        this.outData = [];
        this.listOfData.forEach(function (item) {
            if ((_this.editCache[item.id].data.prixGros > 0) || (_this.editCache[item.id].data.prixDetail > 0)) {
                _this.outData.push(_this.editCache[item.id].data);
            }
        });
        this.data = { data: {
                datePrix: this.mydatePrix.getDate().toLocaleDateString('en-US'),
                prix: this.outData,
                marche: this.SelectedMarketId
            } };
        this.prixService.create(this.data).subscribe(function (data) {
            _this.toastr.success('Enregistrement réussie des prix ', '', { timeOut: 2000 });
            _this.onProduitsPromise();
        }, function (error) { return console.log(error); });
    };
    SaisiePrixComponent.prototype.setLoaded = function (during) {
        var _this = this;
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.adminService.mesMarches().subscribe(function (marches) {
            _this.marches = marches;
            _this.marchesAdapter = [];
            for (var _i = 0, _a = _this.marches; _i < _a.length; _i++) {
                var marche = _a[_i];
                _this.marchesAdapter.push({ 'value': marche.id.toString(), 'label': marche.nom });
            }
            //  this.setLoaded();
        });
    };
    SaisiePrixComponent.prototype.getCodeMesure = function (idMesure) {
        var mesureSelected = this.mesures.find(function (obj) { return obj.id.toString() === idMesure; });
        return mesureSelected.code;
    };
    SaisiePrixComponent.prototype.onMesuresPromise = function () {
        for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
            var mesure = _a[_i];
            this.mesuresAdapter.push({ 'value': mesure.id.toString(), 'label': mesure.code });
        }
    };
    SaisiePrixComponent.prototype.onProduitsPromise = function () {
        var _this = this;
        this.listOfData = [];
        this.produits.sort(function (a, b) { return (a.nom > b.nom) ? 1 : 0; });
        this.produits.forEach(function (produit) {
            _this.listOfData.push({
                id: produit.id,
                nomProduit: produit.nom,
                prixGros: '',
                mesureGros: produit.mesure.id.toString(),
                prixDetail: '',
                mesureDetail: produit.mesure.id.toString()
            });
        });
        this.updateEditCache();
    };
    SaisiePrixComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.SelectedMarketId = '1';
        this.adminService.mesMarches().subscribe(function (marches) {
            _this.marches = marches;
            _this.setLoaded();
        });
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.listOfData = [];
        this.produitService.getByReseaux().subscribe(function (produits) {
            _this.produits = produits;
            _this.onProduitsPromise();
        });
    };
    SaisiePrixComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return SaisiePrixComponent;
}(BasePageComponent));
export { SaisiePrixComponent };
