import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {SecteurActivite} from '../models/secteurActivite';

@Injectable({
  providedIn: 'root'
})
export class SecteurActiviteService {
	private baseUrl = '/api/secteurActivite';
  constructor(private http: HttpClient) {

  }
  findAll(  filter = '', sortField = 'nom', sortOrder = 'asc',
                    offset = 0, pageSize = 10): Observable<SecteurActivite[]> {


    return this.http.get('/api/secteurActivite/pages', {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sortField)
          .set('sortOrder', sortOrder)
          .set('offset', offset.toString())
          .set('max', pageSize.toString())
    }) as Observable<SecteurActivite[]>;
  }
  findCount(): Observable<number> {
    return this.http.get('/api/secteurActivite/count')
        .pipe(
            map(res => res['total'])
        );
  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create( obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}`, obj );
  }
  update(id: number, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/${id}`, value);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

}

