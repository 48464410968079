<form [formGroup]="analyticForm" >
    <tc-card [title]="Columnslabels.periode" >
    <div class="row" style="margin-bottom: 10px;">
        <div class="col-sm-4 ">
            <tc-form-group>
                <label>{{Columnslabels.dateDebut}}</label>
                <jqxDateTimeInput #dateDebut
                                  [width]="'80%'" [height]="30"  [theme]='"sima-bf"' >
                </jqxDateTimeInput>
            </tc-form-group>
        </div>

    </div>
    </tc-card>

      <tc-card [title]="titrecartePrix" >

<!--              <tc-card [title]="'Filtres '">-->
                  <div class="row">
                      <ng-container *ngIf="mesuresAdapter.length>0">
                          <div class="col-sm-4 ">
                              <tc-form-group>
                                  <tc-form-label>Choix de la mesure</tc-form-label>
                                  <tc-select [options]="mesuresAdapter" formControlName="mesure"  [filter]="true" ></tc-select>
                              </tc-form-group>
                          </div>
                      </ng-container>
                      <ng-container *ngIf="produitsAdapter.length>0">
                      <div class="col-sm-4 ">
                          <tc-form-group>
                              <tc-form-label>Choix du produit</tc-form-label>
                              <tc-select [options]="produitsAdapter" formControlName="produit"  [filter]="true" ></tc-select>
                          </tc-form-group>
                      </div>
                      </ng-container>
                          <ng-container *ngIf="marchesAdapter.length>0">
                              <div class="col-sm-4 ">
                                  <tc-form-group>
                                      <tc-form-label>Choix du marché</tc-form-label>
                                      <tc-select [options]="marchesAdapter" formControlName="marche"  [filter]="true" [allowClear]="true"  ></tc-select>
                                  </tc-form-group>
                              </div>
                          </ng-container>
                  </div>
<!--              </tc-card>-->

          <ng-container *ngIf="chartsReady">
          <div echarts [options]="hsOptions" class="chart-container container-h-400"></div>
          </ng-container>
        </tc-card>

    <tc-card [title]="titreBarPrix" >

        <div class="row">
            <ng-container *ngIf="mesuresAdapter.length>0">
            <div class="col-sm-4 ">
                <tc-form-group>
                    <tc-form-label>Choix de la mesure</tc-form-label>
                    <tc-select [options]="mesuresAdapter" formControlName="mesureMarche"  [filter]="true" ></tc-select>
                </tc-form-group>
            </div>
            </ng-container>
            <ng-container *ngIf="produitsAdapter.length>0">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Choix du produit</tc-form-label>
                        <tc-select [options]="produitsAdapter" formControlName="produitComp"  [filter]="true" ></tc-select>
                    </tc-form-group>
                </div>
            </ng-container>
            <ng-container *ngIf="marchesAdapter.length>0">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Choix du marché</tc-form-label>
                        <tc-select [options]="marchesAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="marches" ></tc-select>
                     </tc-form-group>
                </div>
            </ng-container>
        </div>
        <!--              </tc-card>-->

        <ng-container *ngIf="barChartsReady">
                    <canvas id="canvasMarche"
                            baseChart
                            [datasets]="barChartData"
                            [labels]="barChartLabels"
                            [options]="barChartOptions"
                            [colors]="barChartColors"
                            [legend]="barChartLegend"
                            [chartType]="barChartType"
                    ></canvas>
        </ng-container>
    </tc-card>
    <tc-card [title]="titreBarPrixRegion" >

        <div class="row">
            <ng-container *ngIf="mesuresAdapter.length>0">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Choix de la mesure</tc-form-label>
                        <tc-select [options]="mesuresAdapter" formControlName="mesureRegion"  [filter]="true" ></tc-select>
                    </tc-form-group>
                </div>
            </ng-container>
            <ng-container *ngIf="produitsAdapter.length>0">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Choix du produit</tc-form-label>
                        <tc-select [options]="produitsAdapter" formControlName="produitCompRegion"  [filter]="true" ></tc-select>
                    </tc-form-group>
                </div>
            </ng-container>
            <ng-container *ngIf="regionsAdapter.length>0">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Choix de la région</tc-form-label>
                        <tc-select [options]="regionsAdapter" [filter]="true"  [multiple]="true"  [allowClear]="true"   formControlName="regions" ></tc-select>
                    </tc-form-group>
                </div>
            </ng-container>
        </div>
        <!--              </tc-card>-->

        <ng-container *ngIf="barChartsRegionReady">
            <canvas id="canvasRegion"
                    baseChart
                    [datasets]="barChartDataRegion"
                    [labels]="barChartLabelsRegion"
                    [options]="barChartOptions"
                    [colors]="barChartColorsRegion"
                    [legend]="barChartLegendRegion"
                    [chartType]="barChartTypeRegion"
            ></canvas>
        </ng-container>
    </tc-card>
    <div class="row">
        <div class="col-sm-1 ">
        </div>
        <ng-container *ngIf="produitsAdapter.length>0">
            <div class="col-sm-4 ">
                <tc-form-group>
                    <tc-form-label>Choix du produit</tc-form-label>
                    <tc-select [options]="produitsAdapter" formControlName="produitOffreRegion"  [filter]="true" ></tc-select>
                </tc-form-group>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="volumeChartsReady">
        <div class="row">
            <div class="col col-12 col-md-6">
                <tc-card [title]="titreOffresAchat"  [padding]="2">
                    <div echarts [options]="dOptionsOA" class="chart-container container-h-300"></div>
                </tc-card>
            </div>

            <div class="col col-12 col-md-6">
                <tc-card [title]="titreOffresVente"  [padding]="2">
                    <div echarts [options]="dOptionsOV" class="chart-container container-h-300"></div>
                </tc-card>
            </div>
        </div>
    </ng-container>


</form>
