
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Mesure} from '../models/mesure';
import {MesureService} from './mesure.service';


export class MesureDatasource implements DataSource<Mesure> {

    private mesuresSubject = new BehaviorSubject<Mesure[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Mesure[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private provinceService: MesureService) {

    }
    loadMesures(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.provinceService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(mesures => this.mesuresSubject.next(mesures)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Mesure[]> {
        return this.mesuresSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.mesuresSubject.complete();
        this.loadingSubject.complete();
    }

}

