import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {INoteMarche} from './valider-noteMarche.component';

@Injectable({
  providedIn: 'root'
})
export class ValiderNoteMarcheService {
	private baseUrl = '/api/noteMarche';
  constructor(private http: HttpClient) {

  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllByReseau(reseauId: string): Observable<INoteMarche[]> {
    return this.http.get(`${this.baseUrl}/get_all_by_reseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId)
    }) as Observable<INoteMarche[]>;
  }
  getAllValidesByReseau(reseauId: string): Observable<INoteMarche[]> {
    return this.http.get(`${this.baseUrl}/get_all_valides_by_reseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId)
    }) as Observable<INoteMarche[]>;
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj);
  }
  valider(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/valider`, obj);
  }
  update(id: number, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/modifier/${id}`, value);
  }
  rejeter(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/rejeter`, obj);
  }
  abandonner(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/abandonner`, obj);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

}

