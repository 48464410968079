import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChronoAlerteService {
	private baseUrl = '/api/chronoAlerte';
    private confBaseUrl = '/api/restapi';
  constructor(private http: HttpClient) {

  }

  get(id: number, mobilePhone: string): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`, {
      params: new HttpParams()
          .set('mobilePhone', mobilePhone)
    });
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }

  getURL(): string {
	  return `${this.baseUrl}`;
  }
  update(value: any): Observable<Object> {
    return this.http.post(`${this.baseUrl}/modifier`, value);
  }
  reconfigure(): Observable<Object> {
    return this.http.post(`${this.baseUrl}/planning`, 'Planning');
  }
}

