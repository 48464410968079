<div class="row" *ngIf="userInfo">
  <div class="col col-12 col-md-6 mb-4 mb-md-0">
    <tc-card  [tcGradient]="['#fff', '#fbfbfb']" class="personal-info-card">
      <div class="d-flex align-items-center justify-content-between mb-3 user-actions">
        <tc-avatar [src]="'assets/img/LogoSecodev90x60.png'" [size]="90"></tc-avatar>

      </div>

      <div class="d-flex align-items-center justify-content-between">
<!--        <h5 class="mb-0 mt-0 mr-1">{{ userInfo.mainInfo.firstName }} {{ userInfo.mainInfo.lastName }}</h5>-->

<!--        <tc-rating [value]="5" [disabled]="true"></tc-rating>-->
      </div>

      <p [tcColor]="'#8f8f90'">{{ userInfo.mainInfo.position }}</p>

      <p> SECODEV est un bureau d'étude Africain. Sa mission est d’apporter à ses clients les solutions adaptées à leurs besoins au niveau théorique que pratique et aussi bien à travers les études que la formation et les partages d’expériences.  A cet effet, les principales catégories d’activités sont : l´élaboration et la mise en œuvre des systèmes d´information  pour la gestion des projets/programmes incluant les marchés agro-sylvo-pastoraux, la collecte digitale des données pour la cartographie des marchés, la gouvernance et le développement local, le financement des collectivités locales,  les enquêtes de satisfaction des usagers de l’Administration Publique, enquête de satisfaction des clients d’entreprise …), l’analyse de données et les sondages.
        En matière d’enquête, le Cabinet recourt à des technologies de collecte et d’analyse innovantes.
     </p>
    </tc-card>

    <tc-card [tcGradient]="['#fff', '#fbfbfb']" [title]="'Sites Web et Réseaux Sociaux'">

      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-twitter'" [tcFontSize]="30" [tcColor]="'#1da1f2'"></tc-icon>
        </div>
        <div class="col">
          <div>Twitter</div>
          <a href="http://www.twitter.com/secodev" (click)="$event.preventDefault()">https://twitter.com/secodev</a>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-linkedin'" [tcFontSize]="30" [tcColor]="'#0073b1'"></tc-icon>
        </div>
        <div class="col">
          <div>Linkedin</div>
          <a href="http://www.linkedin.com/company/secodev" (click)="$event.preventDefault()">linkedin.com/secodev</a>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-youtube'" [tcFontSize]="30" [tcColor]="'#ff0000'"></tc-icon>
        </div>
        <div class="col">
          <div>YouTube</div>
          <a href="https://www.youtube.com/user/secodev" (click)="$event.preventDefault()">youtube.com/user/secodev</a>
        </div>
      </div>
    </tc-card>

    <tc-card [tcGradient]="['#fff', '#fbfbfb']" [title]="'Informations des contacts'" class="mb-md-0">
      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-ui-touch-phone'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Mobile</div>
          {{ userInfo.mainInfo.number }}
        </div>
      </div>


      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-skype'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Skype</div>
          {{ userInfo.mainInfo.skype }}
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-location-pin'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Adresse actuelle</div>
          {{ userInfo.mainInfo.address }}
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-location-pin'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>e-mail</div>
          {{ userInfo.mainInfo.email }}
        </div>
      </div>
    </tc-card>
  </div>

  <div class="col col-12 col-md-6">
    <tc-card [title]="'Experience'" [tcGradient]="['#fff', '#fbfbfb']">
      <tc-v-timeline [data]="userInfo.experience" [showIcons]="false"></tc-v-timeline>
    </tc-card>

  </div>
</div>
