/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./avatar.component";
var styles_TCAvatarComponent = [i0.styles];
var RenderType_TCAvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCAvatarComponent, data: {} });
export { RenderType_TCAvatarComponent as RenderType_TCAvatarComponent };
function View_TCAvatarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4], [8, "alt", 0], [8, "width", 0], [8, "height", 0]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.imageLoaded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.src, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.alt, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.size, ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.size, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_TCAvatarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "initials"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.initials; _ck(_v, 1, 0, currVal_0); }); }
function View_TCAvatarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "avatar-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icofont-ui-laoding rotate"]], null, null, null, null, null))], null, null); }
export function View_TCAvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCAvatarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCAvatarComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCAvatarComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 1, 0, currVal_0); var currVal_1 = ((!_co.loaded || !_co.src) && _co.initials); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((!_co.loaded || !_co.src) && !_co.initials); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_TCAvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-avatar", [], [[2, "tc-avatar", null], [4, "height", null], [4, "width", null], [4, "min-width", null], [4, "fontSize", null], [2, "avatar-loading", null], [4, "background-color", null]], null, null, View_TCAvatarComponent_0, RenderType_TCAvatarComponent)), i1.ɵdid(1, 114688, null, 0, i3.TCAvatarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).height; var currVal_2 = i1.ɵnov(_v, 1).width; var currVal_3 = i1.ɵnov(_v, 1).minWidth; var currVal_4 = i1.ɵnov(_v, 1).fontSize; var currVal_5 = i1.ɵnov(_v, 1).loading; var currVal_6 = i1.ɵnov(_v, 1).backgroundColor; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
var TCAvatarComponentNgFactory = i1.ɵccf("tc-avatar", i3.TCAvatarComponent, View_TCAvatarComponent_Host_0, { src: "src", initials: "initials", size: "size", alt: "alt", bgColor: "bgColor", loading: "loading" }, {}, []);
export { TCAvatarComponentNgFactory as TCAvatarComponentNgFactory };
