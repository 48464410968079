import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {en_US, fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../animations/form-error';
import {QualiteService} from '../qualite/qualite.service';
import {MesureService} from '../mesure/mesure.service';
import {Mesure} from '../models/mesure';
import {Qualite} from '../models/qualite';
import {Produit} from '../models/produit';
import {ProduitService} from '../produit/produit.service';
import {OffreAchatService} from './offre-achat.service';
import {OffreAchat} from '../models/offreAchat';
import * as moment from 'moment';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {UserService} from '../administrateur/user.service';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';
import {RegionService} from '../region/region.service';


@Component({
    selector: 'tc-offre-create',
    templateUrl: './saisie-offre-achat.component.html',
    styleUrls: ['./saisie-offre-achat.component.scss'],
    animations: [fadeIn]
})
export class SaisieOffreAchatComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    offreForm: FormGroup;
    offre: any;
    public mobilePhoneKeyUp = new Subject<KeyboardEvent>();
    private mobilePhoneSubscription: Subscription;
    mesures: any[] = [];
    qualites: any[] = [];
    produits: any[] = [];
    regions: any[] = [];
    regionsAdapter: IOption[] = [];
    produitsAdapter: IOption[] = [];
    mesuresAdapter: IOption[] = [];
    qualitesAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    @ViewChild('dateOffre', {static: false}) dateOffre: jqxDateTimeInputComponent;
    Columnslabels =
        {
            date: 'Date ',
            delaiValidite: 'Délai validité (jrs)',
            dateExpiration: 'Date Expiration',
            produit: 'Produit',
            quantite: 'Quantité',
            region: 'Région',
            mesure: 'Mesure',
            prixUnitaire: 'Prix Unitaire',
            qualite: 'Qualité',
            telephone: 'N° Mobile Acheteur',
            nomAuteur: 'Nom Acheteur ',
            email: 'e-Mail',
            actif: 'Actif',
            montant: 'Montant ',
            conditions: 'Conditions'
        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private offreService: OffreAchatService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private produitService: ProduitService,
                 private qualiteService: QualiteService,
                 private mesureService: MesureService, private administrateurService: UserService,
                 private regionService: RegionService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        this.i18n.setLocale(fr_FR);
        moment.locale('fr');
        this.initReactiveForm();
        this.offre = new OffreAchat();

    }

    ngAfterViewInit() {
        if (!this.isEditing()) {
            this.mobilePhoneSubscription = this.mobilePhoneKeyUp.pipe(
                debounceTime(100),
                distinctUntilChanged(),
                tap(() => {
                    if (this.offreForm.get('telephone').value != null) {
                    if (this.offreForm.get('telephone').value.length === 12) {
                        this.administrateurService.findByMobile(this.offreForm.value.telephone).subscribe(
                            acheteur => {
                                if (acheteur.nom !== undefined) {
                                    this.offreForm.patchValue({
                                        nomAuteur: acheteur.nom + ' ' + acheteur.prenom,
                                        email: acheteur.email}
                                    );
                                }

                            }
                        );
                    }
                }})
            ).subscribe();

        }

    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
         if (this.offre) {
            this.onProduitsPromise();
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier une Offre d\'Achat ' : ' Créer une Offre d\'Achat ' ;
    }
    isEditing(): boolean {
        return (this.offre.id != null);
    }
    onProduitsPromise() {
        if (this.produits) {
            for (const produit of this.produits) {
                this.produitsAdapter.push({'value': produit.id.toString(), 'label': produit.nom});
            }
        }

    }
    onRegionsPromise() {
        if (this.regions) {
            for (const region of this.regions) {
                this.regionsAdapter.push({'value': region.id.toString(), 'label': region.nom});
            }
        }

    }
    onQualitesPromise() {
        if (this.qualites) {
            for (const qualite of this.qualites) {
                this.qualitesAdapter.push({'value': qualite.id.toString(), 'label': qualite.nom});
            }
        }

    }
    onSelectProduit($event: any) {
        if (this.offreForm.value.produit) {
        const  produitSelected: Produit = this.produits.find(obj => obj.id.toString() === this.offreForm.value.produit);
        if (produitSelected) {
        this.offreForm.patchValue(
            {mesure : produitSelected.mesure.id.toString()}
        );
        }
        }
   }
    onMontantChange($event: any) {
        if (this.offreForm.value.quantite && this.offreForm.value.prixUnitaire) {
            this.offreForm.patchValue(
                {montant: this.offreForm.value.quantite * this.offreForm.value.prixUnitaire}
            );
        }
    }


    onMesuresPromise() {
        if (this.mesures) {
            for (const mesure of this.mesures) {
                this.mesuresAdapter.push({'value': mesure.id.toString(), 'label': mesure.nom});
            }
        }

    }


    initReactiveForm() {
        const dateDuJour = new Date();
        this.offreForm = this.fb.group({
                date: [dateDuJour, Validators.required],
                dateExpiration: [moment(dateDuJour).add(14, 'days').format('L')],
                delaiValidite: [14, [Validators.required, Validators.min(0)]],
                produit: ['', Validators.required],
                mesure: ['', Validators.required],
                region: ['', Validators.required],
                quantite: ['', [Validators.required, Validators.min(0)]],
                qualite: [''],
                email: ['', [Validators.email]],
                telephone: ['', Validators.required],
                nomAuteur: ['', Validators.required],
                montant: ['', Validators.min(0)],
                prixUnitaire: ['', Validators.min(0)],
                conditions: ['']
            }
        ) ;
        this.offreForm.get('delaiValidite').valueChanges.subscribe(val => {
            if (this.dateOffre !== undefined) {
                 this.offreForm.get('dateExpiration').setValue(moment(this.dateOffre.getDate()).add(val, 'days').format('L')) ;
            }
            });

    }

    setReactiveForm() {
        const dateDuJour = new Date(this.offre.date);
        this.offreForm.patchValue({
                qualite: (this.offre.qualite != null) ? this.offre.qualite.id.toString() : '',
                region: (this.offre.region != null) ? this.offre.region.id.toString() : '',
                actif: this.offre.actif,
                quantite: this.offre.quantite,
                email: this.offre.email,
                date: dateDuJour,
                telephone: this.offre.telephone,
                nomAuteur: this.offre.nomAuteur,
                delaiValidite: this.offre.delaiValidite,
                dateExpiration: this.offre.dateExpiration,
                montant: this.offre.montant,
                prixUnitaire: this.offre.prixUnitaire,
                conditions : this.offre.conditions,
                mesure: (this.offre.mesure != null) ? this.offre.mesure.id.toString() : '',
                produit: (this.offre.produit != null) ? this.offre.produit.id.toString() : ''
            }
        );
    }
    getOffre (jsonValue): OffreAchat {
        return OffreAchat.fromJSON(jsonValue) ;
    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.qualiteService.getAllURL(), 'qualites', 'onQualitesPromise');
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.getData(this.produitService.getAllURL(), 'produits', 'onProduitsPromise');
        this.getData(this.regionService.getAllURL(), 'regions', 'onRegionsPromise');
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.offreService.getURL(this.selectedId), 'offre', 'offreAchat', 'setLoaded');
            } else {
                this.initReactiveForm();
            }
        });
       }


    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-offre']);
    }
    liste() {
        this.router.navigate(['/vertical/modifier-les-offres-achats']);
    }
    mapFormValuesToModel() {
        this.offre.quantite =  this.offreForm.value.quantite;
        this.offre.email =  this.offreForm.value.email;
        this.offre.telephone =  this.offreForm.value.telephone;
        this.offre.date =  this.offreForm.value.date;
      //  this.offre.date = this.dateOffre.getDate();
        this.offre.delaiValidite =  this.offreForm.value.delaiValidite;
        this.offre.dateExpiration =  this.offreForm.value.dateExpiration;
        this.offre.montant =  this.offreForm.value.montant;
        this.offre.qualite =  this.offreForm.value.qualite;
        this.offre.region =  this.offreForm.value.region;
        this.offre.prixUnitaire =  this.offreForm.value.prixUnitaire;
        this.offre.mesure =  this.offreForm.value.mesure;
        this.offre.produit =  this.offreForm.value.produit;
        this.offre.conditions =  this.offreForm.value.conditions;
        this.offre.nomAuteur =  this.offreForm.value.nomAuteur;

    }
    update(): void {
        if (this.offreForm.valid) {
            this.mapFormValuesToModel();
            this.offreService.update(this.offre.id, this.offre)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.liste();
                    });
        }

    }
    create(): void {
        if (this.offreForm.valid) {
            this.mapFormValuesToModel();
            this.offreService.create(this.offre)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.offreForm.reset();
                        const dateDuJour = new Date();
                        this.offreForm.get('date').setValue(dateDuJour);
                        this.offreForm.get('delaiValidite').setValue(14);

                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
