var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ProvinceService } from '../province.service';
import { ToastrService } from 'ngx-toastr';
import { Province } from '../../models/province';
import { NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegionService } from '../../region/region.service';
var PageCreateProvinceComponent = /** @class */ (function (_super) {
    __extends(PageCreateProvinceComponent, _super);
    function PageCreateProvinceComponent(store, httpSv, provinceService, route, router, toastr, regionService, i18n, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.provinceService = provinceService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.regionService = regionService;
        _this.i18n = i18n;
        _this.fb = fb;
        _this.regionsAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code Province',
            label: 'Libellé Province',
            longitude: 'Longitude',
            latitude: 'Latitude',
            region: 'Région'
        };
        _this.province = new Province();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des provinces',
                    route: 'list-province'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateProvinceComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.province) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateProvinceComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier une province ' : ' Créer une province ';
    };
    PageCreateProvinceComponent.prototype.isEditing = function () {
        return (this.province.id != null);
    };
    PageCreateProvinceComponent.prototype.initReactiveForm = function () {
        this.provinceForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            region: ['', Validators.required],
            actif: [true]
        });
    };
    PageCreateProvinceComponent.prototype.setReactiveForm = function () {
        if (this.province) {
            var region = (this.province.region != null) ? this.province.region.id.toString() : '';
            console.log('province,region, region.id', [this.province, this.province.region, this.province.id.toString()]);
            this.provinceForm.patchValue({
                code: this.province.code,
                nom: this.province.nom,
                region: region,
                actif: this.province.actif
            });
        }
    };
    PageCreateProvinceComponent.prototype.getProvince = function (jsonValue) {
        return Province.fromJSON(jsonValue);
    };
    PageCreateProvinceComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getData(this.regionService.getAllURL(), 'regions', 'onRegionsPromise');
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.provinceService.getURL(_this.selectedId), 'province', 'province', 'setLoaded');
            }
            else {
                _this.province = new Province();
                _this.initReactiveForm();
            }
        });
    };
    PageCreateProvinceComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateProvinceComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-province']);
    };
    PageCreateProvinceComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-province']);
    };
    PageCreateProvinceComponent.prototype.mapFormValuesToModel = function () {
        this.province.code = this.provinceForm.value.code;
        this.province.nom = this.provinceForm.value.nom;
        this.province.region = this.provinceForm.value.region;
        this.province.actif = this.provinceForm.value.actif;
    };
    PageCreateProvinceComponent.prototype.update = function () {
        var _this = this;
        if (this.provinceForm.valid) {
            this.mapFormValuesToModel();
            this.provinceService.update(this.province.id, this.province)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-province']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateProvinceComponent.prototype.onRegionsPromise = function () {
        for (var _i = 0, _a = this.regions; _i < _a.length; _i++) {
            var region = _a[_i];
            this.regionsAdapter.push({ 'value': region.id.toString(), 'label': region.nom });
        }
    };
    PageCreateProvinceComponent.prototype.create = function () {
        var _this = this;
        if (this.provinceForm.valid) {
            this.mapFormValuesToModel();
            this.provinceService.create(this.province)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie  ', '', { timeOut: 2000 });
                _this.provinceForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateProvinceComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateProvinceComponent;
}(BasePageComponent));
export { PageCreateProvinceComponent };
