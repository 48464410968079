var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { MarcheService } from '../marche/marche.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { ValiderOffreAchatService } from '../valider-offre-achat/validerOffreAchat.service';
import { ExcelService } from '../../../services/sharedServices/excel-service.service';
var ListeOffresAchatsValidesComponent = /** @class */ (function (_super) {
    __extends(ListeOffresAchatsValidesComponent, _super);
    function ListeOffresAchatsValidesComponent(store, httpSv, marcheService, route, authService, router, toastr, fb, i18n, validerOffreAchatService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.marcheService = marcheService;
        _this.route = route;
        _this.authService = authService;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.validerOffreAchatService = validerOffreAchatService;
        _this.dateValidation = new Date();
        _this.outData = [];
        /******************/
        _this.isAllDisplayDataChecked = false;
        _this.isOperating = false;
        _this.isIndeterminate = false;
        _this.listOfDisplayData = [];
        _this.listOfAllDataOffreAchat = [];
        _this.mapOfCheckedId = {};
        _this.numberOfChecked = 0;
        _this.pageData = {
            title: '',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        return _this;
    }
    /******************************/
    ListeOffresAchatsValidesComponent.prototype.abandonner = function () {
        var _this = this;
        this.outData = [];
        this.listOfAllDataOffreAchat.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresAchat: this.outData
            } };
        if (this.outData.length > 0) {
            this.validerOffreAchatService.abandonner(this.data).subscribe(function (data) {
                _this.toastr.success('abandon réussi des offres d\'achats sélectionnés', '', { timeOut: 2000 });
                _this.loadOffreAchat();
            }, function (error) { return console.log(error); });
        }
        else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    };
    ListeOffresAchatsValidesComponent.prototype.devalider = function () {
        var _this = this;
        this.outData = [];
        this.listOfAllDataOffreAchat.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresAchat: this.outData
            } };
        if (this.outData.length > 0) {
            this.validerOffreAchatService.devalider(this.data).subscribe(function (data) {
                _this.toastr.success('Dé-Validation réussie des offres selectionnées', '', { timeOut: 2000 });
                _this.loadOffreAchat();
            }, function (error) { return console.log(error); });
        }
        else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    };
    ListeOffresAchatsValidesComponent.prototype.rejeter = function () {
        var _this = this;
        this.outData = [];
        this.listOfAllDataOffreAchat.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                OffresAchat: this.outData
            } };
        if (this.outData.length > 0) {
            this.validerOffreAchatService.rejeter(this.data).subscribe(function (data) {
                _this.toastr.success('rejet réussi des offres d\'achats  sélectionnés', '', { timeOut: 2000 });
                _this.loadOffreAchat();
            }, function (error) { return console.log(error); });
        }
        else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    };
    ListeOffresAchatsValidesComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.listOfAllDataOffreAchat = [];
        this.loadOffreAchat();
    };
    ListeOffresAchatsValidesComponent.prototype.loadOffreAchat = function () {
        var _this = this;
        this.listOfAllDataOffreAchat = [];
        var reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerOffreAchatService.getAllValidesByReseau(reseauId.toString()).subscribe(function (data) {
            _this.offreAValider = data;
            _this.offreAValider.forEach(function (offreAchat, index, array) {
                _this.listOfAllDataOffreAchat.push({
                    id: offreAchat.id,
                    date: offreAchat.date,
                    nom_produit: offreAchat.nom_produit,
                    quantite: offreAchat.quantite,
                    nom_auteur: offreAchat.nom_auteur,
                    mesure: offreAchat.mesure,
                    telephone: offreAchat.telephone,
                    email: offreAchat.email,
                    montant: offreAchat.montant,
                    reseau: offreAchat.reseau,
                    prixUnitaire: offreAchat.prixUnitaire,
                    date_expiration: offreAchat.date_expiration
                });
            });
        });
        this.mapOfCheckedId = {};
        this.refreshStatus();
    };
    ListeOffresAchatsValidesComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ListeOffresAchatsValidesComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    };
    ListeOffresAchatsValidesComponent.prototype.refreshStatus = function () {
        var _this = this;
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(function (item) { return _this.mapOfCheckedId[item.id]; });
        this.isIndeterminate =
            this.listOfDisplayData.some(function (item) { return _this.mapOfCheckedId[item.id]; }) &&
                !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataOffreAchat.filter(function (item) { return _this.mapOfCheckedId[item.id]; }).length;
    };
    ListeOffresAchatsValidesComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfDisplayData.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = value); });
        this.refreshStatus();
    };
    ListeOffresAchatsValidesComponent.prototype.operateData = function () {
        var _this = this;
        this.isOperating = true;
        setTimeout(function () {
            _this.listOfAllDataOffreAchat.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = false); });
            _this.refreshStatus();
            _this.isOperating = false;
        }, 1000);
    };
    ListeOffresAchatsValidesComponent.prototype.exportByExcel = function () {
        ExcelService.exportAsExcelFile(this.offreAValider, 'offres_achats_valides');
    };
    return ListeOffresAchatsValidesComponent;
}(BasePageComponent));
export { ListeOffresAchatsValidesComponent };
