import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoginAuth} from '../../pages/ui/models/loginAuth';

@Injectable({
  providedIn: 'root'
})
export class UserLoggerService {
  private baseUrl = '/api/login';
  constructor(private http: HttpClient) { }
  getAllURL(): string {
    return `${this.baseUrl}`;
  }

  create(obj: LoginAuth): Observable<Object> {
    return this.http.post(`${this.baseUrl}`, obj);
  }
}
