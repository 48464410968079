/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./avatar-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./avatar-group.component";
var styles_TCAvatarGroupComponent = [i0.styles];
var RenderType_TCAvatarGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCAvatarGroupComponent, data: {} });
export { RenderType_TCAvatarGroupComponent as RenderType_TCAvatarGroupComponent };
export function View_TCAvatarGroupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_TCAvatarGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-avatar-group", [], [[2, "tc-avatar-group", null]], null, null, View_TCAvatarGroupComponent_0, RenderType_TCAvatarGroupComponent)), i1.ɵdid(1, 4308992, null, 0, i2.TCAvatarGroupComponent, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
var TCAvatarGroupComponentNgFactory = i1.ɵccf("tc-avatar-group", i2.TCAvatarGroupComponent, View_TCAvatarGroupComponent_Host_0, {}, {}, ["tc-avatar"]);
export { TCAvatarGroupComponentNgFactory as TCAvatarGroupComponentNgFactory };
