import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { RaceService } from '../race.service';
import { ToastrService } from 'ngx-toastr';
import { NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {Race} from '../../models/race';

@Component({
    selector: 'tc-race-create',
    templateUrl: './create-race.component.html',
    animations: [fadeIn]
})
export class PageCreateRaceComponent extends BasePageComponent implements OnInit, OnDestroy {
    raceForm: FormGroup;
    race: any;
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            code: 'Code race',
            nom: 'Nom race',
            description: 'Description',
            actif: 'Actif'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private raceService: RaceService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.race = new Race();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des races',
                    route: 'list-race'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.race) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier une race ' : ' Créer une race ' ;
    }
    isEditing(): boolean {
        return (this.race.id != null);
    }



    initReactiveForm() {
        this.raceForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                description: [''],
                actif: [true]
            }
        ) ;
    }
     getRace (jsonValue): Race {
        return Race.fromJSON(jsonValue) ;
    }

    setReactiveForm() {
        if (this.race) {
            this.raceForm.patchValue({
                    code: this.race.code,
                    nom: this.race.nom,
                    description: this.race.description,
                    actif: this.race.actif
                }
            );

        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.raceService.getURL(this.selectedId), 'race', 'race', 'setLoaded');
            } else {
                this.race = new Race();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-race']);
    }
    liste() {
        this.router.navigate(['/vertical/list-race']);
    }
    mapFormValuesToModel() {
        this.race.code =  this.raceForm.value.code;
        this.race.nom =  this.raceForm.value.nom;
        this.race.description =  this.raceForm.value.description;
        this.race.actif =  this.raceForm.value.actif;
    }
    update(): void {
        if (this.raceForm.valid) {
            this.mapFormValuesToModel();
            this.raceService.update(this.race.id, this.race)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.router.navigate(['/vertical/list-race']);
                    }
                    ,
                    error => console.log(error));
        }

    }

    create(): void {
        if (this.raceForm.valid) {
            this.mapFormValuesToModel();
            this.raceService.create(this.race)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.raceForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
