var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseLayoutComponent } from '../base-layout';
import { HttpService } from '../../services/http/http.service';
import { TCModalService } from '../../ui/services/modal/modal.service';
import * as SettingsActions from '../../store/actions/app-settings.actions';
var VerticalLayoutComponent = /** @class */ (function (_super) {
    __extends(VerticalLayoutComponent, _super);
    function VerticalLayoutComponent(store, fb, httpSv, router, elRef, modal) {
        return _super.call(this, store, fb, httpSv, router, elRef, modal) || this;
    }
    VerticalLayoutComponent.prototype.getSidebarStyles = function () {
        return {
            'background-image': "linear-gradient(188deg, " + this.appSettings.sidebarBg + ", " + this.appSettings.sidebarBg2 + " 65%)",
            'color': this.appSettings.sidebarColor
        };
    };
    VerticalLayoutComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.store.dispatch(new SettingsActions.Update({ layout: 'vertical' }));
    };
    return VerticalLayoutComponent;
}(BaseLayoutComponent));
export { VerticalLayoutComponent };
