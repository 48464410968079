import { HttpClient } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CACHE_KEY = 'sima-operateur-cache';
var OperateurService = /** @class */ (function () {
    function OperateurService(http) {
        this.http = http;
        this.baseUrl = '/api/operateur/short';
    }
    OperateurService.prototype.getCached = function () {
        this.alertes = this.http.get("" + this.baseUrl)
            .pipe(map(function (data) { return data.items; }));
        this.alertes.subscribe(function (next) {
            localStorage[CACHE_KEY] = JSON.stringify(next);
        });
        this.alertes = this.alertes.pipe(startWith(JSON.parse(localStorage[CACHE_KEY] || '[]')));
        return this.alertes;
    };
    OperateurService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperateurService_Factory() { return new OperateurService(i0.ɵɵinject(i1.HttpClient)); }, token: OperateurService, providedIn: "root" });
    return OperateurService;
}());
export { OperateurService };
