import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PushSMSService = /** @class */ (function () {
    function PushSMSService(http) {
        this.http = http;
        this.baseUrl = '/api/envoyer-sms';
    }
    PushSMSService.prototype.send = function (obj) {
        return this.http.post("" + this.baseUrl, obj);
    };
    PushSMSService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushSMSService_Factory() { return new PushSMSService(i0.ɵɵinject(i1.HttpClient)); }, token: PushSMSService, providedIn: "root" });
    return PushSMSService;
}());
export { PushSMSService };
