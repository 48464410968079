import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {Activite} from '../../models/activite';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {SecteurActivite} from '../../models/secteurActivite';
import {SecteurActiviteService} from '../secteurActivite.service';


@Component({
    selector: 'tc-secteur-activite-create',
    templateUrl: './create-secteurActivite.component.html',
    animations: [fadeIn]
})
export class PageCreateSecteurActiviteComponent extends BasePageComponent implements OnInit, OnDestroy {
    activiteForm: FormGroup;
    secteurActivite: any;
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            code: 'Code Secteur d\'Activité',
            label: 'Libellé Secteur d\'Activité'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private secteurActiviteService: SecteurActiviteService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.secteurActivite = new SecteurActivite();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des secteurs d\'activité',
                    route: 'list-activite'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.secteurActivite) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier un secteur d\'activité ' : ' Créer un secteur d\'activité ' ;
    }
    isEditing(): boolean {
        return (this.secteurActivite.id != null);
    }



    initReactiveForm() {
        this.activiteForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        if (this.secteurActivite) {
            this.activiteForm.patchValue({
                    code: this.secteurActivite.code,
                    nom: this.secteurActivite.nom,
                    actif: this.secteurActivite.actif
                }
            );

        }
    }

    ngOnInit() {
        super.ngOnInit();
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.secteurActiviteService.getURL(this.selectedId), 'secteurActivite', 'secteurActivite', 'setLoaded');
            } else {
                this.secteurActivite = new SecteurActivite();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-activite']);
    }
    liste() {
        this.router.navigate(['/vertical/list-activite']);
    }
    mapFormValuesToModel() {
        this.secteurActivite.code =  this.activiteForm.value.code;
        this.secteurActivite.nom =  this.activiteForm.value.nom;
        this.secteurActivite.actif =  this.activiteForm.value.actif;
    }
    update(): void {
        if (this.activiteForm.valid) {
            this.mapFormValuesToModel();
            this.secteurActiviteService.update(this.secteurActivite.id, this.secteurActivite)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.router.navigate(['/vertical/list-activite']);
                    }
                    ,
                    error => console.log(error));
        }

    }

    create(): void {
        if (this.activiteForm.valid) {
            this.mapFormValuesToModel();
            this.secteurActiviteService.create(this.secteurActivite)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.activiteForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
