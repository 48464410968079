
import {Produit} from './produit';
import {Marche} from './marche';
import {Depot} from './depot';
import {Groupe} from './groupe';
import {Reseau} from './reseau';

export class AlerteEdit {
	libelle: string;
	id: number;
	recevoirParEmail: boolean;
	recevoirParSMS: boolean;
	valide: boolean;
	actif: boolean;
	lundi: boolean;
	mardi: boolean;
	mercredi: boolean;
	jeudi: boolean;
	vendredi: boolean;
	samedi: boolean;
	dimanche: boolean;
	reseau: Reseau;
	produits: Produit[];
	groupes: Groupe[];
	marches: Marche[];
	depots: Depot[];
	static fromJSON(json: any): AlerteEdit {
		const result = new AlerteEdit();
		for (const key in json) {
			result[key] = json[key];
		}

		return result;
	}

	toString() {
		return this.libelle;
	}
	get produitsText() {
		let rval = '';
		if (this.produits != null) {
			for (const produit of this.produits) {
				rval += produit.nom;
				rval += '\n';
			}
		}
		return rval;
	}

	get selectedProducts() {
		const rval = [];
		if (this.produits != null) {
			for (const produit of this.produits) {
				rval.push(produit);
			}
		}
		return rval;
	}

	get selectedMarches() {
		const rval = [];
		if (this.marches != null) {
			for (const marche of this.marches) {
				rval.push(marche);
			}
		}
		return rval;
	}
	get selectedDepots() {
		const rval = [];
		if (this.depots != null) {
			for (const depot of this.depots) {
				rval.push(depot);
			}
		}
		return rval;
	}
	get selectedGroupes() {
		const rval = [];
		if (this.groupes != null) {
			for (const groupe of this.groupes) {
				rval.push(groupe);
			}
		}
		return rval;
	}


	constructor() {
		this.actif = true;
		this.valide = false;
		this.produits = [];
		this.groupes = [];
		this.depots = [];
		this.marches = [];
	}
}
