<ng-container *ngIf="marchesAdapter.length>0">
<agm-map [latitude]="lat" [longitude]="lng">
  <agm-marker *ngFor="let marche of marchesAdapter" [latitude]="marche.latitude"  [longitude]="marche.longitude"
              [title]="marche.nom" [openInfoWindow]="true" [opacity]="0.5"  >
    <agm-info-window [disableAutoPan]="true">
      <div class="infoWindowTitle"> <strong> Marché de {{marche.nom}} </strong> </div>
      <div class="infoWindowContent">
        <p> latitude: <strong>{{marche.latitude}}</strong></p>
        <p>longitude:  <strong>{{marche.longitude}} </strong> </p>
<!--        <ng-container *ngIf="marche.produits">-->
<!--          <p>Produits du marché : </p>-->
<!--          <p>-->
<!--          <strong>{{marche.produits}}</strong>-->
<!--          </p>-->
<!--        </ng-container>-->
      </div>
    </agm-info-window>
  </agm-marker>

</agm-map>
</ng-container>
