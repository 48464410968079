import { OnInit } from '@angular/core';
var TCBorderColorDirective = /** @class */ (function () {
    function TCBorderColorDirective() {
    }
    TCBorderColorDirective.prototype.ngOnInit = function () {
        var COLOR = this.tcBorderColor;
        this.defaultColor = (typeof COLOR === 'string') ? COLOR : COLOR[0];
        this.hoveredColor = (typeof COLOR === 'string') ? COLOR : COLOR[1];
        this.currentColor = this.defaultColor;
    };
    Object.defineProperty(TCBorderColorDirective.prototype, "getStyle", {
        get: function () {
            return this.currentColor;
        },
        enumerable: true,
        configurable: true
    });
    TCBorderColorDirective.prototype.onMouseEnter = function () {
        this.currentColor = this.hoveredColor;
    };
    TCBorderColorDirective.prototype.onMouseLeave = function () {
        this.currentColor = this.defaultColor;
    };
    return TCBorderColorDirective;
}());
export { TCBorderColorDirective };
