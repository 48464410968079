<ng-container *ngIf="listOfAllDataOffreVente.length>0 ">
    <ng-template #modalHeader>
        <h2 class="title">Rejet de l'offre de vente</h2>
        <div class="actions">
            <tc-badge [view]="'warning'">Rejet</tc-badge>
        </div>
    </ng-template>

    <ng-template #modalBody>
        <tc-form-label>Veuillez détailler les causes du rejet</tc-form-label>
        <tc-textarea  [charLimiter]="{ counter: 255, before: 'Il reste ', after: ' caractères' }"
                      [tcShape]="0" [rows]="3" [(ngModel)] = "causeRejet" name="causeRejet"></tc-textarea>

    </ng-template>

    <ng-template #modalFooter>
        <div class="actions justify-content-between">
            <button tc-button (click)="validerRejet()">Rejeter</button>
            <button tc-button [outline]="true" (click)="closeModal()">Annuler</button>
        </div>
    </ng-template>

    <tc-card>
        <div class="row">
            <div class="col-sm-4">
                <div class="pull-left">
                    <button tc-button (click)="valider()">Valider</button>
                </div>
            </div>

            <div class="col-sm-4 ">

            </div>

            <div class="col-sm-4 ">
                <div class="pull-right">
                    <button tc-button (click)="openModal(modalBody, 'Rejet de l\'offre ', modalFooter, { overlayClose: false })"  [outline]="true" >Rejeter</button>
                </div>
            </div>

        </div>
    </tc-card>


<tc-card title="VALIDER LES OFFRES DE VENTE">

    <span *ngIf="numberOfChecked"> {{ numberOfChecked }} éléments sélectionnés</span>

<nz-table
    #rowSelectionTable
    nzShowPagination
    nzShowSizeChanger
    [nzPageSize]="50"
    [nzData]="listOfAllDataOffreVente"
    (nzCurrentPageDataChange)="currentPageDataChange($event)"
>
    <thead>
    <tr>
        <th
                nzShowCheckbox
                [(nzChecked)]="isAllDisplayDataChecked"
                [nzIndeterminate]="isIndeterminate"
                (nzCheckedChange)="checkAll($event)"
        ></th>
        <th>Date</th>
        <th>Produit</th>
        <th>Quantité</th>
        <th>Prix Unitaire</th>
        <th>Montant</th>
        <th>Contact Vendeur</th>
        <th>Reseau</th>

    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let data of rowSelectionTable.data">
        <td
                nzShowCheckbox
                [(nzChecked)]="mapOfCheckedId[data.id]"
                (nzCheckedChange)="refreshStatus()"
        ></td>
        <td>{{data.date}}</td>
        <td>{{data.nom_produit}}</td>
        <td>{{data.quantite}} {{data.mesure}}</td>
        <td>{{data.prixUnitaire| currency:'CFA':'symbol':'3.0-0':'fr'}}</td>
        <td>{{data.montant| currency:'CFA':'symbol':'3.0-0':'fr'}}</td>
        <td> <p> <strong> {{ data.nom_auteur }} </strong></p>
            <p> <small> {{ data.telephone }}</small> </p>
        </td>
        <td>{{data.reseau}}</td>
    </tr>
    </tbody>
</nz-table>

</tc-card>

<tc-card>
    <div class="row">
        <div class="col-sm-4">
            <div class="pull-left">
                <button tc-button (click)="valider()">Valider</button>
            </div>
        </div>

        <div class="col-sm-4 ">

        </div>

        <div class="col-sm-4 ">
            <div class="pull-right">
                <button tc-button (click)="openModal(modalBody, 'Rejet de l\'offre ', modalFooter, { overlayClose: false })"  [outline]="true" >Rejeter</button>
            </div>
        </div>

    </div>
</tc-card>
    <tc-card>
        <div class="buttons-list">
            <button  (click)="exportByExcel()"><i class="fa fa-file-excel-o" aria-hidden="false" style="font-size:48px;color:#C7B54A">
            </i></button>
        </div>
    </tc-card>

    </ng-container>
