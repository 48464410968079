var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePageComponent } from '../../base-page';
import { HttpService } from '../../../services/http/http.service';
import { DashboardService } from '../dashboard/dashboard.service';
import { FormBuilder } from '@angular/forms';
import { jqxDataTableComponent } from 'jqwidgets-ng/jqxdatatable';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { ReseauService } from '../../ui/reseau/reseau.service';
import { AlerteService } from './alerte.service';
import { SMSService } from './smsService.service';
import { OperateurService } from './operateur.service';
var PageRapportsSMSComponent = /** @class */ (function (_super) {
    __extends(PageRapportsSMSComponent, _super);
    function PageRapportsSMSComponent(store, dashboardService, httpSv, smsService, reseauService, operateurService, alerteService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.dashboardService = dashboardService;
        _this.smsService = smsService;
        _this.reseauService = reseauService;
        _this.operateurService = operateurService;
        _this.alerteService = alerteService;
        _this.fb = fb;
        _this.alertes = [];
        _this.destinations = [{ id: 0, nom: 'SMS Sortant (MT)' }, { id: 1, nom: 'SMS Entrant (MO)' }, { id: 2, nom: 'Tous' }];
        _this.operateursAdapter = [];
        _this.destinationsAdapter = [];
        _this.alertesAdapter = [];
        _this.reseaux = [];
        _this.operateurs = [];
        _this.reseauxAdapter = [];
        _this.Columnslabels = {
            dateDebut: 'Date début',
            dateFin: 'Date Fin'
        };
        _this.source = { localData: []
        };
        _this.localizationObject = _this.getLocalization();
        _this.exportSettings = _this.getExportSettings();
        _this.columns = [
            { text: 'Réseau', dataField: 'reseau', width: '10%', cellsAlign: 'left', align: 'left' },
            { text: 'Emetteur/Recepteur', dataField: 'acteur', width: '14%', cellsAlign: 'left', align: 'left' },
            { text: 'Message', dataField: 'message', width: '20%', cellsAlign: 'left', align: 'left' },
            { text: 'Direction', dataField: 'direction', width: '8%', cellsAlign: 'left', align: 'left' },
            { text: 'Date', dataField: 'date', width: '12%', align: 'left', cellsAlign: 'left', cellsFormat: 'd' },
            { text: 'Origine', dataField: 'fromPhone', align: 'center', cellsAlign: 'center', cellsFormat: 'd', width: '10%' },
            { text: 'Destination', dataField: 'toPhone', align: 'center', cellsAlign: 'center', width: '10%' },
            { text: 'Alerte', dataField: 'alerte', align: 'center', cellsAlign: 'center', width: '8%' },
            { text: 'Opérateur', dataField: 'operateur', align: 'center', cellsAlign: 'center', width: '8%' }
        ];
        _this.pageData = {
            title: 'JOURNAL DES SMS',
            breadcrumbs: [],
        };
        return _this;
    }
    PageRapportsSMSComponent.prototype.getExportSettings = function () {
        var exportObj = {};
        exportObj.columnsHeader = true;
        exportObj.hiddenColumns = false;
        exportObj.serverURL = null;
        exportObj.characterSet = null;
        exportObj.recordsInView = true;
        exportObj.fileName = 'rapport_sms';
        return exportObj;
    };
    PageRapportsSMSComponent.prototype.getLocalization = function () {
        var localizationobj = {};
        localizationobj.pagerGoToPageString = 'Aller à la page:';
        localizationobj.pagerShowRowsString = 'Voir ligne:';
        localizationobj.pagerRangeString = ' de ';
        localizationobj.pagerNextButtonString = 'Suivant';
        localizationobj.pagerFirstButtonString = 'Premier';
        localizationobj.pagerLastButtonString = 'Dernier';
        localizationobj.pagerPreviousButtonString = 'Précedent';
        localizationobj.sortAscendingString = 'Ordre Ascendent';
        localizationobj.sortDescendingString = 'Ordre Descendant';
        localizationobj.sortRemoveString = 'Supprimer';
        localizationobj.emptydatastring = 'Pas de données';
        localizationobj.firstDay = 1;
        localizationobj.percentSymbol = '%';
        localizationobj.currencySymbol = '';
        localizationobj.currencySymbolPosition = 'après';
        localizationobj.decimalSeparator = ',';
        localizationobj.thousandsSeparator = ' ';
        localizationobj.filterapplystring = 'Appliquer';
        localizationobj.filtercancelstring = 'Annuler';
        localizationobj.filterclearstring = 'Effacer Filtre';
        localizationobj.filterstring = 'Avancé';
        localizationobj.filtersearchstring = 'Chercher';
        localizationobj.filterstringcomparisonoperators = ['vide', 'non vide', 'contient', 'contient(respecte la casse)',
            'Ne contient pas', 'Ne contient pas(respecte la casse)', 'commence par', 'commence par(respecte la casse)',
            'finit par', 'finit par(respecte la casse)', 'égale', 'égale(respecte la casse)', 'null', 'non null'];
        // tslint:disable-next-line:max-line-length
        localizationobj.filternumericcomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
        // tslint:disable-next-line:max-line-length
        localizationobj.filterdatecomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
        localizationobj.filterbooleancomparisonoperators = ['égale', 'non égale'];
        localizationobj.validationstring = 'La valeur entrée n\'est pas valide ';
        localizationobj.emptydatastring = 'Pas de données à afficher';
        localizationobj.filterselectstring = 'Selectionnez un filtre';
        localizationobj.loadtext = 'Chargement...';
        localizationobj.clearstring = 'Effacer';
        localizationobj.todaystring = 'Aujourd\'hui';
        // tslint:disable-next-line:max-line-length
        localizationobj.loadingerrormessage = 'Chargement en cours des données vous ne pouvez pas mettre à jour les propriétés ou appeler une méthode . Vous pourrez le faire après le chargement des données.';
        localizationobj.decimalSeparator = ',';
        localizationobj.thousandsSeparator = ' ';
        localizationobj.days = {
            // full day names
            names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
            // abbreviated day names
            namesAbbr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            // shortest day names
            namesShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
        };
        localizationobj.patterns = {
            d: 'dd/MM/yyyy',
            D: 'dddd, d. MMMM yyyy',
            t: 'HH:mm',
            T: 'HH:mm:ss',
            f: 'dddd, d. MMMM yyyy HH:mm',
            F: 'dddd, d. MMMM yyyy HH:mm:ss',
            M: 'dd MMMM',
            Y: 'MMMM yyyy'
        };
        localizationobj.months = {
            // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
            names: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre', ''],
            // abbreviated month names
            namesAbbr: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
        };
        return localizationobj;
    };
    PageRapportsSMSComponent.prototype.getWidth = function () {
        if (document.body.offsetWidth < 850) {
            return '95%';
        }
        return 850;
    };
    PageRapportsSMSComponent.prototype.excelExport = function () {
        this.myDataTable.exportData('xls');
    };
    PageRapportsSMSComponent.prototype.jsonExport = function () {
        this.myDataTable.exportData('json');
    };
    PageRapportsSMSComponent.prototype.pdfExport = function () {
        this.myDataTable.exportData('pdf');
    };
    PageRapportsSMSComponent.prototype.printBtnOnClick = function () {
        var gridContent = this.myDataTable.exportData('html');
        var newWindow = window.open('', '', 'width=800, height=500'), document = newWindow.document.open(), pageContent = '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>ETAT JOURNAL DES SMS</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
        document.write(pageContent);
        document.close();
        newWindow.print();
    };
    PageRapportsSMSComponent.prototype.onReseauxPromise = function () {
        this.reseauxAdapter = [];
        for (var _i = 0, _a = this.reseaux; _i < _a.length; _i++) {
            var reseau = _a[_i];
            this.reseauxAdapter.push({ 'value': reseau.id.toString(), 'label': reseau.code });
        }
    };
    PageRapportsSMSComponent.prototype.onAlertesPromise = function () {
        this.alertesAdapter = [];
        for (var _i = 0, _a = this.alertes; _i < _a.length; _i++) {
            var alerte = _a[_i];
            this.alertesAdapter.push({ 'value': alerte.id.toString(), 'label': alerte.libelle });
        }
    };
    PageRapportsSMSComponent.prototype.onOperateursPromise = function () {
        this.operateursAdapter = [];
        for (var _i = 0, _a = this.operateurs; _i < _a.length; _i++) {
            var operateur = _a[_i];
            this.operateursAdapter.push({ 'value': operateur.id.toString(), 'label': operateur.nom });
        }
    };
    PageRapportsSMSComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        for (var _i = 0, _a = this.destinations; _i < _a.length; _i++) {
            var destination = _a[_i];
            this.destinationsAdapter.push({ 'value': destination.id.toString(), 'label': destination.nom });
        }
        this.reseauService.getCached().subscribe(function (data) {
            _this.reseaux = data;
            _this.onReseauxPromise();
        });
        this.alerteService.getCached().subscribe(function (data) {
            _this.alertes = data;
            _this.onAlertesPromise();
        });
        this.operateurService.getCached().subscribe(function (data) {
            _this.operateurs = data;
            _this.onOperateursPromise();
        });
        this.initReactiveForm();
        this.setLoaded();
    };
    PageRapportsSMSComponent.prototype.initReactiveForm = function () {
        this.rapportsForm = this.fb.group({
            alerte: '',
            operateur: '',
            reseau: '',
            destination: ''
        });
    };
    PageRapportsSMSComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dateDebut.culture('fr-FR');
        this.dateFin.culture('fr-FR');
        var pdateDebut = new Date();
        pdateDebut.setFullYear(pdateDebut.getFullYear(), 0, 1);
        var pdateFin = new Date();
        pdateFin.setFullYear(pdateFin.getFullYear(), pdateFin.getMonth(), pdateFin.getDate());
        this.dateDebut.setDate(pdateDebut);
        this.dateFin.setDate(pdateFin);
        this.rapportsForm.get('reseau').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                console.log('called in reseau.change');
                _this.smsService.getJournalSMS(_this.dateDebut.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('operateur').value, _this.rapportsForm.get('alerte').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('destination').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('operateur').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                console.log('called in operateur.change');
                _this.smsService.getJournalSMS(_this.dateDebut.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('operateur').value, _this.rapportsForm.get('alerte').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('destination').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('alerte').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                console.log('called in alerte.change');
                _this.smsService.getJournalSMS(_this.dateDebut.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('operateur').value, _this.rapportsForm.get('alerte').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('destination').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.rapportsForm.get('destination').valueChanges.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.smsService.getJournalSMS(_this.dateDebut.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('operateur').value, _this.rapportsForm.get('alerte').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('destination').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.dateFin.onValueChanged.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.smsService.getJournalSMS(_this.dateDebut.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('operateur').value, _this.rapportsForm.get('alerte').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('destination').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.dateDebut.onValueChanged.subscribe(function (selectedValue) {
            if (selectedValue !== undefined) {
                _this.smsService.getJournalSMS(_this.dateDebut.getDate().toLocaleDateString(), 
                // tslint:disable-next-line:max-line-length
                _this.dateFin.getDate().toLocaleDateString(), _this.rapportsForm.get('operateur').value, _this.rapportsForm.get('alerte').value, _this.rapportsForm.get('reseau').value, _this.rapportsForm.get('destination').value).subscribe(function (datas) {
                    _this.source.localData = datas;
                    console.log('datas : ', datas);
                    _this.dataAdapter = new jqx.dataAdapter(_this.source);
                });
            }
        });
        this.smsService.getJournalSMS(this.dateDebut.getDate().toLocaleDateString(), 
        // tslint:disable-next-line:max-line-length
        this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('operateur').value, this.rapportsForm.get('alerte').value, this.rapportsForm.get('reseau').value, this.rapportsForm.get('destination').value).subscribe(function (datas) {
            _this.source.localData = datas;
            console.log('datas : ', datas);
            _this.dataAdapter = new jqx.dataAdapter(_this.source);
        });
    };
    PageRapportsSMSComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return PageRapportsSMSComponent;
}(BasePageComponent));
export { PageRapportsSMSComponent };
