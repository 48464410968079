import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var StockService = /** @class */ (function () {
    function StockService(http) {
        this.http = http;
        this.baseUrl = '/api/stock';
    }
    StockService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    StockService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    StockService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    StockService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    StockService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    StockService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    StockService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StockService_Factory() { return new StockService(i0.ɵɵinject(i1.HttpClient)); }, token: StockService, providedIn: "root" });
    return StockService;
}());
export { StockService };
