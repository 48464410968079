import { OnInit, OnChanges, ChangeDetectorRef, } from '@angular/core';
var TCProgressComponent = /** @class */ (function () {
    function TCProgressComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.innerProgress = 0;
    }
    Object.defineProperty(TCProgressComponent.prototype, "getEXtraSmallSize", {
        get: function () {
            return this.size === 'ex-sm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCProgressComponent.prototype, "getSmallSize", {
        get: function () {
            return this.size === 'sm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCProgressComponent.prototype, "getDefaultSize", {
        get: function () {
            return this.size === '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCProgressComponent.prototype, "getLargeSize", {
        get: function () {
            return this.size === 'lg';
        },
        enumerable: true,
        configurable: true
    });
    TCProgressComponent.prototype.ngOnInit = function () {
        this.innerProgress = this.progress;
    };
    TCProgressComponent.prototype.ngOnChanges = function () {
        this.changeDetector.detectChanges();
        this.innerProgress = this.progress;
    };
    return TCProgressComponent;
}());
export { TCProgressComponent };
