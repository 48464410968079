import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ActiviteService } from '../activite.service';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {Activite} from '../../models/activite';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {SecteurActivite} from '../../models/secteurActivite';
import {SecteurActiviteService} from '../../secteurActivite/secteurActivite.service';
import {Acteur} from '../../models/acteur';


@Component({
    selector: 'tc-activite-create',
    templateUrl: './create-activite.component.html',
    animations: [fadeIn]
})
export class PageCreateActiviteComponent extends BasePageComponent implements OnInit, OnDestroy {
    activiteForm: FormGroup;
    activite: any;
    secteursActivites: SecteurActivite[];
    secteursActivitesAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            code: 'Code Activite',
            label: 'Libellé Activite',
            secteurActivite: 'Secteur Activite'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private activiteService: ActiviteService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private secteurActiviteService: SecteurActiviteService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.activite = new Activite();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des activites',
                    route: 'list-activite'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.activite) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier une activite ' : ' Créer une activite ' ;
    }
    isEditing(): boolean {
        return (this.activite.id != null);
    }



    initReactiveForm() {
        this.activiteForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                secteurActivite: [''],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        if (this.activite) {
            const secteurActivite = (this.activite.secteurActivite === undefined) ? '' : this.activite.secteurActivite.id.toString();
            this.activiteForm.patchValue({
                    code: this.activite.code,
                    nom: this.activite.nom,
                    secteurActivite: secteurActivite,
                    actif: this.activite.actif
                }
            );

        }
    }
    getActivite (jsonValue): Activite {
        return Activite.fromJSON(jsonValue) ;
    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.secteurActiviteService.getAllURL(), 'secteursActivites', 'onSecteurActivitesPromise');
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.activiteService.getURL(this.selectedId), 'activite', 'activite', 'setLoaded');
            } else {
                this.activite = new Activite();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-activite']);
    }
    liste() {
        this.router.navigate(['/vertical/list-activite']);
    }
    mapFormValuesToModel() {
        this.activite.code =  this.activiteForm.value.code;
        this.activite.nom =  this.activiteForm.value.nom;
        this.activite.secteurActivite =  this.activiteForm.value.secteurActivite;
        this.activite.actif =  this.activiteForm.value.actif;
    }
    update(): void {
        if (this.activiteForm.valid) {
            this.mapFormValuesToModel();
            this.activiteService.update(this.activite.id, this.activite)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.router.navigate(['/vertical/list-activite']);
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSecteurActivitesPromise() {
        for (const secteurActivite of this.secteursActivites) {
            this.secteursActivitesAdapter.push({'value' : secteurActivite.id.toString(), 'label' : secteurActivite.nom});
        }
        this.setReactiveForm();
    }
    create(): void {
        if (this.activiteForm.valid) {
            this.mapFormValuesToModel();
            this.activiteService.create(this.activite)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.activiteForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
