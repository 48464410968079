import { Component, HostBinding, Input, OnInit } from '@angular/core';

import { IPageData } from '../../../interfaces/page-data';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  canLoadFooter = true;
  nomOrganisation: string;
  telephone: string;
  mobile: string;
  email: string;
  @HostBinding('class.footer') true;
  @HostBinding('class.loaded') @Input() loaded: boolean;
  @HostBinding('class.boxed') @Input() boxed: boolean;

  @Input() pageData: IPageData;

  constructor() { }

  ngOnInit() {
    this.telephone = localStorage.getItem('telephone');
    if (!this.telephone) {
      this.telephone = '+22676946952';
      localStorage.setItem('telephone', this.telephone);
    }
    this.mobile = localStorage.getItem('mobile');
    if (!this.mobile) {
      this.mobile = '+22676946952';
      localStorage.setItem('telephone', this.mobile);
    }
    this.nomOrganisation = localStorage.getItem('nomOrganisation');
    if (!this.nomOrganisation) {
      this.nomOrganisation = 'Ministère de l\'Agriculture et des Amenagements Hydro-Agricoles ';
      localStorage.setItem('nomOrganisation', this.nomOrganisation);
    }
    this.email = localStorage.getItem('email');
    if (!this.email) {
      this.email = 'olivierbado8@gmail.com';
      localStorage.setItem('email', this.email);
    }
   // console.log('this.pageData.footerLoaded', this.pageData.footerLoaded);
  //  this.canLoadFooter = this.pageData.footerLoaded;

  }
}
