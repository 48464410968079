import { OnInit } from '@angular/core';
var NavbarComponent = /** @class */ (function () {
    function NavbarComponent() {
        this.boxed = false;
        this.orientation = 'horizontal';
    }
    Object.defineProperty(NavbarComponent.prototype, "horizontal", {
        get: function () {
            return this.orientation === 'horizontal' || this.orientation === 'horizontal-vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarComponent.prototype, "vertical", {
        get: function () {
            return this.orientation === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarComponent.prototype, "horizontalVertical", {
        get: function () {
            return this.orientation === 'horizontal-vertical';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavbarComponent.prototype, "height", {
        get: function () {
            return this.minHeight;
        },
        enumerable: true,
        configurable: true
    });
    NavbarComponent.prototype.ngOnInit = function () {
    };
    return NavbarComponent;
}());
export { NavbarComponent };
