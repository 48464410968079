var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { DepotService } from '../depot.service';
import { ToastrService } from 'ngx-toastr';
import { Depot } from '../../models/depot';
import { CommuneService } from '../../commune/commune.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MesureService } from '../../mesure/mesure.service';
var PageCreateDepotComponent = /** @class */ (function (_super) {
    __extends(PageCreateDepotComponent, _super);
    function PageCreateDepotComponent(store, httpSv, depotService, route, mesureService, router, toastr, communeService, fb) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.depotService = depotService;
        _this.route = route;
        _this.mesureService = mesureService;
        _this.router = router;
        _this.toastr = toastr;
        _this.communeService = communeService;
        _this.fb = fb;
        _this.communeTree = [];
        _this.typologies = [];
        _this.mesuresAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            code: 'Code Dépôt',
            label: 'Libellé Dépôt',
            mobile: 'N° Mobile Contact',
            longitude: 'Longitude',
            latitude: 'Latitude',
            commune: 'Commune',
            uniteMesure: 'Unité de mesure'
        };
        _this.depot = new Depot();
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des dépôts',
                    route: 'list-depot'
                }
            ]
        };
        _this.initReactiveForm();
        return _this;
    }
    PageCreateDepotComponent.getDepot = function (jsonValue) {
        return Depot.fromJSON(jsonValue);
    };
    PageCreateDepotComponent.prototype.onMesuresPromise = function () {
        if (this.mesures) {
            this.mesures.sort(function (a, b) { return ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)); });
            for (var _i = 0, _a = this.mesures; _i < _a.length; _i++) {
                var mesure = _a[_i];
                this.mesuresAdapter.push({ 'value': mesure.id.toString(), 'label': mesure.nom });
            }
        }
        this.setReactiveForm();
    };
    PageCreateDepotComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        if (this.depot) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }
    };
    PageCreateDepotComponent.prototype.getTitle = function () {
        return this.isEditing() ? ' Modifier un dépôt ' : ' Créer un dépôt ';
    };
    PageCreateDepotComponent.prototype.isEditing = function () {
        return (this.depot.id != null);
    };
    PageCreateDepotComponent.prototype.initReactiveForm = function () {
        this.depotForm = this.fb.group({
            code: ['', Validators.required],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            mobile: [''],
            mesure: [''],
            longitude: [''],
            latitude: [''],
            commune: [''],
            actif: [true]
        });
    };
    PageCreateDepotComponent.prototype.setReactiveForm = function () {
        var commune = (this.depot.communeTab != null) ? this.depot.communeTab : ['', '', ''];
        this.depotForm.patchValue({
            code: this.depot.code,
            nom: this.depot.nom,
            mobile: this.depot.mobile,
            mesure: this.depot.mesure,
            longitude: this.depot.longitude,
            latitude: this.depot.latitude,
            actif: this.depot.actif,
            commune: commune
        });
    };
    PageCreateDepotComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.route.params.subscribe(function (params) {
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.depotService.getURL(_this.selectedId), 'depot', 'depot', 'setLoaded');
            }
            else {
                _this.depot = new Depot();
                _this.initReactiveForm();
            }
        });
        // detect form changes
        this.depotForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
        this.communeService.findAsTree().subscribe(function (tree) {
            _this.communeTree = tree;
        });
    };
    PageCreateDepotComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateDepotComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-depot']);
    };
    PageCreateDepotComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-depot']);
    };
    PageCreateDepotComponent.prototype.mapFormValuesToModel = function () {
        this.depot.code = this.depotForm.value.code;
        this.depot.nom = this.depotForm.value.nom;
        this.depot.mobile = this.depotForm.value.mobile;
        this.depot.longitude = this.depotForm.value.longitude;
        this.depot.latitude = this.depotForm.value.latitude;
        this.depot.actif = this.depotForm.value.actif;
        this.depot.commune = this.depotForm.value.commune[2];
        this.depot.mesure = this.depotForm.value.mesure;
    };
    PageCreateDepotComponent.prototype.update = function () {
        var _this = this;
        if (this.depotForm.valid) {
            this.mapFormValuesToModel();
            this.depotService.update(this.depot.id, this.depot)
                .subscribe(function (data) {
                _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
                _this.router.navigate(['/vertical/list-depot']);
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateDepotComponent.prototype.create = function () {
        var _this = this;
        if (this.depotForm.valid) {
            this.mapFormValuesToModel();
            this.depotService.create(this.depot)
                .subscribe(function (data) {
                _this.toastr.success('Création réussie ', '', { timeOut: 2000 });
                _this.depotForm.reset();
            }, function (error) { return console.log(error); });
        }
    };
    PageCreateDepotComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateDepotComponent;
}(BasePageComponent));
export { PageCreateDepotComponent };
