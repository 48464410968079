
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Depot} from '../models/depot';
import {DepotService} from './depot.service';


export class DepotsDatasource implements DataSource<Depot> {

    private depotsSubject = new BehaviorSubject<Depot[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Depot[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private depotService: DepotService) {

    }

    loadDepots(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.depotService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(data => this.depotsSubject.next(data)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Depot[]> {
        return this.depotsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.depotsSubject.complete();
        this.loadingSubject.complete();
    }

}

