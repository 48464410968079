/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./pagination.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./pagination.component";
var styles_TCPaginationComponent = [i0.styles];
var RenderType_TCPaginationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCPaginationComponent, data: {} });
export { RenderType_TCPaginationComponent as RenderType_TCPaginationComponent };
function View_TCPaginationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "pagination-li"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "pagination-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changePage(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "active": 0 }), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pagination-link"; var currVal_1 = _ck(_v, 4, 0, (_co.pageNum == _v.context.$implicit)); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 5, 0, currVal_2); }); }
export function View_TCPaginationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "ul", [["class", "pagination-ul"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "li", [["class", "pagination-li prev"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "a", [["class", "pagination-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.prevPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "disabled": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icofont-simple-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCPaginationComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 5, "li", [["class", "pagination-li next"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "a", [["class", "pagination-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(13, { "disabled": 0 }), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "icofont-simple-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "pagination-link"; var currVal_1 = _ck(_v, 5, 0, (_co.pageNum == 1)); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.pages; _ck(_v, 8, 0, currVal_2); var currVal_3 = "pagination-link"; var currVal_4 = _ck(_v, 13, 0, (_co.pageNum == _co.getLast())); _ck(_v, 12, 0, currVal_3, currVal_4); }, null); }
export function View_TCPaginationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-pagination", [], [[2, "tc-pagination", null]], null, null, View_TCPaginationComponent_0, RenderType_TCPaginationComponent)), i1.ɵdid(1, 638976, null, 0, i3.TCPaginationComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
var TCPaginationComponentNgFactory = i1.ɵccf("tc-pagination", i3.TCPaginationComponent, View_TCPaginationComponent_Host_0, { pagesNumber: "pagesNumber", pageNum: "pageNum" }, { goToPage: "goToPage" }, []);
export { TCPaginationComponentNgFactory as TCPaginationComponentNgFactory };
