<ng-container *ngIf="categorie">
	<div class="col-sm-8">
		<tc-card [title]="''">
			<form [formGroup]="categorieForm"   (ngSubmit)="onSubmit()">
				<tc-form-group>
					<tc-form-label>{{Columnslabels.code}} </tc-form-label>
					<tc-input type="text"  formControlName="code" >
					</tc-input>
					<ng-container *ngIf="categorieForm.get('code').invalid && (categorieForm.get('code').touched || categorieForm.get('code').dirty)">
						<span @fadeIn class="error" *ngIf="categorieForm.get('code').getError('required')">Veuillez saisir le code de la catégorie</span>
						<span @fadeIn class="error" *ngIf="categorieForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
					</ng-container>
				</tc-form-group>

				<tc-form-group>
					<tc-form-label>{{Columnslabels.nom}}</tc-form-label>
					<tc-input type="text"  formControlName="nom">
					</tc-input>
					<ng-container *ngIf="categorieForm.get('nom').invalid && (categorieForm.get('nom').touched || categorieForm.get('nom').dirty)">
						<span @fadeIn class="error" *ngIf="categorieForm.get('nom').getError('required')">Veuillez saisir le nom de la categorie</span>
						<span @fadeIn class="error" *ngIf="categorieForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
					</ng-container>
				</tc-form-group>


				<tc-form-group>
					<tc-form-label>{{Columnslabels.description}}</tc-form-label>
					<tc-textarea  [charLimiter]="{ counter: 255, before: 'Il reste ', after: ' caractères' }"
								  [tcShape]="0" [rows]="2" formControlName="description"></tc-textarea>
				</tc-form-group>
				<tc-form-group>
					<tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
				</tc-form-group>

				<span *ngIf="!categorieForm.valid && categorieForm.touched" class="help-block">Veuillez entrer des données valides !</span>
				<button [disabled]="!categorieForm.valid" tc-button [block]="true" >
					Valider
				</button>




			</form>

		</tc-card>
	</div>
</ng-container>

