import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { CategorieService } from '../categorie.service';
import { ToastrService } from 'ngx-toastr';
import {Categorie} from '../../models/categorie';
import { NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {ProduitService} from '../../produit/produit.service';
import {IOption} from '../../../../ui/interfaces/option';

@Component({
    selector: 'tc-categorie-create',
    templateUrl: './create-categorie.component.html',
    animations: [fadeIn]
})
export class PageCreateCategorieComponent extends BasePageComponent implements OnInit, OnDestroy {
    categorieForm: FormGroup;
    categorie: any;
    produits: any[];
    produitsAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            code: 'Code Categorie',
            nom: 'Nom Categorie',
            description: 'Description',
            actif: 'Actif'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private categorieService: CategorieService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private i18n: NzI18nService, private produitService: ProduitService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.categorie = new Categorie();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des categories',
                    route: 'list-categorie'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.categorie) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier une categorie ' : ' Créer une categorie ' ;
    }
    isEditing(): boolean {
        return (this.categorie.id != null);
    }



    initReactiveForm() {
        this.categorieForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                description: [''],
                actif: [true]
            }
        ) ;
    }
     getCategorie (jsonValue): Categorie {
        return Categorie.fromJSON(jsonValue) ;
    }
    onProduitsPromise() {
        this.produitsAdapter = [];
        for (const commune of this.produits) {
            this.produitsAdapter.push({'value' : commune.id.toString(), 'label' : commune.nom});
        }
    }
    setReactiveForm() {
        if (this.categorie) {
            this.categorieForm.patchValue({
                    code: this.categorie.code,
                    nom: this.categorie.nom,
                    description: this.categorie.description,
                    actif: this.categorie.actif
                }
            );

        }
    }

    ngOnInit() {
        super.ngOnInit();

        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.categorieService.getURL(this.selectedId), 'categorie', 'categorie', 'setLoaded');
            } else {
                this.categorie = new Categorie();
                this.initReactiveForm();
            }
        });
        this.getData(this.produitService.getAllURL(), 'produits', 'onProduitsPromise');

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-categorie']);
    }
    liste() {
        this.router.navigate(['/vertical/list-categorie']);
    }
    mapFormValuesToModel() {
        this.categorie.code =  this.categorieForm.value.code;
        this.categorie.nom =  this.categorieForm.value.nom;
        this.categorie.description =  this.categorieForm.value.description;
        this.categorie.actif =  this.categorieForm.value.actif;
    }
    update(): void {
        if (this.categorieForm.valid) {
            this.mapFormValuesToModel();
            this.categorieService.update(this.categorie.id, this.categorie)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.router.navigate(['/vertical/list-categorie']);
                    }
                    ,
                    error => console.log(error));
        }

    }

    create(): void {
        if (this.categorieForm.valid) {
            this.mapFormValuesToModel();
            this.categorieService.create(this.categorie)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.categorieForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
