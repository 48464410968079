<ng-container *ngIf="marche && typologies && periodicites ">
<div class="col-sm-8">
 <tc-card [title]="''">
    <form [formGroup]="marcheForm"   (ngSubmit)="onSubmit()">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                        <tc-input type="text"  formControlName="code" >
                        </tc-input>
                        <ng-container *ngIf="marcheForm.get('code').invalid && (marcheForm.get('code').touched || marcheForm.get('code').dirty)">
                            <span @fadeIn class="error" *ngIf="marcheForm.get('code').getError('required')">Veuillez saisir le code du marché</span>
                            <span @fadeIn class="error" *ngIf="marcheForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                        </ng-container>
                    </tc-form-group>

                <tc-form-group>
                     <tc-form-label>{{Columnslabels.label}} </tc-form-label>
                    <tc-input type="text"  formControlName="nom">
                    </tc-input>
                    <ng-container *ngIf="marcheForm.get('nom').invalid && (marcheForm.get('nom').touched || marcheForm.get('nom').dirty)">
                        <span @fadeIn class="error" *ngIf="marcheForm.get('nom').getError('required')">Veuillez saisir le nom du marché</span>
                        <span @fadeIn class="error" *ngIf="marcheForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                    </ng-container>
                </tc-form-group>

                <div class="row">
                    <div class="col col-sm-6">
                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.longitude}}</tc-form-label>
                                <tc-input type="number"  formControlName="longitude">
                                </tc-input>
                            </tc-form-group>
                    </div>

                    <div class="col col-sm-6">
                            <tc-form-group>
                                <tc-form-label>{{Columnslabels.latitude}}</tc-form-label>
                                <tc-input type="number"  formControlName="latitude">
                                </tc-input>
                            </tc-form-group>
                    </div>
                </div>
<!--        <jqxListBox #jqxListBox-->
<!--                    [source]="source"-->
<!--                    [checkboxes]="true"-->
<!--        >-->
<!--        </jqxListBox>-->
              <ng-container *ngIf="communeTree.length>0">


                <tc-form-group>
                    <tc-form-label>{{Columnslabels.commune}}</tc-form-label>
                    <nz-cascader [nzOptions]="communeTree"
                                 [nzShowSearch]="true"
                                 [nzAutoFocus]="true"
                                 [nzSize]="'large'"
                                 formControlName="commune">

                    </nz-cascader>
                </tc-form-group>
              </ng-container>

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.typologie}}</tc-form-label>
                        <tc-select [placeholder]="'Type marché'" [options]="typologies"  formControlName="typologie"></tc-select>
                        <ng-container *ngIf="marcheForm.get('typologie').invalid && (marcheForm.get('typologie').touched || marcheForm.get('typologie').dirty)">
                            <span @fadeIn class="error" *ngIf="marcheForm.get('typologie').getError('required')">Veuillez saisir sélectionner la typologie du marché</span>
                        </ng-container>
                    </tc-form-group>

        <tc-form-group>
            <tc-form-label>{{Columnslabels.periodicite}}</tc-form-label>
            <tc-select [placeholder]="'Périodicité'" [options]="periodicites"  formControlName="periodicite"></tc-select>
            <ng-container *ngIf="marcheForm.get('periodicite').invalid && (marcheForm.get('periodicite').touched || marcheForm.get('periodicite').dirty)">
                <span @fadeIn class="error" *ngIf="marcheForm.get('periodicite').getError('required')">Veuillez saisir sélectionner la periodicité du marché</span>
            </ng-container>
        </tc-form-group>
                    <tc-form-group>
                        <tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
                    </tc-form-group>

                <span *ngIf="!marcheForm.valid && marcheForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                <button [disabled]="!marcheForm.valid" tc-button [block]="true" >
                    Valider
                </button>

    </form>

</tc-card>
</div>
</ng-container>

