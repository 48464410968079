


import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Produit} from '../models/produit';
import {ProduitService} from './produit.service';


export class ProduitsDataSource implements DataSource<Produit> {

    private produitsSubject = new BehaviorSubject<Produit[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Produit[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private produitService: ProduitService) {

    }


    loadProduits(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.produitService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(produits => this.produitsSubject.next(produits)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Produit[]> {
        return this.produitsSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.produitsSubject.complete();
        this.loadingSubject.complete();
    }

}

