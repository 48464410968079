import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
	private baseUrl = '/api/statistiques';
  private urlCartePrix = '/api/cartePrix';
  private urlComparaisonPrix = '/api/comparaisonPrix';
  private urlComparaisonPrixRegion = '/api/comparaisonPrixRegion';
  private urlStatOffresRegion = '/api/stat_offres_region';
  constructor(private http: HttpClient) {

  }
  getStatistiques(): Observable <any> {
    return this.http.get(`${this.baseUrl}`).pipe(
        map(res => res['stat'])
    );
  }
  getStatistiquesOffres(produitId: any, dateDebut: string): Observable <any> {
    return this.http.get(`${this.urlStatOffresRegion}`, {
      params: new HttpParams()
          .set('produitId', produitId)
          .set('dateDebut', dateDebut)
    }).pipe(
        map(res => res['piecharts'])
    );

  }
  getCartePrix(produitId: any, marcheId: any, mesureId: any, dateDebut: string): Observable <any> {
    return this.http.get(`${this.urlCartePrix}`, {
      params: new HttpParams()
          .set('produitId', produitId)
          .set('marcheId', marcheId)
          .set('mesureId', mesureId)
          .set('dateDebut', dateDebut)
    }).pipe(
        map(res => res['series'])
    );
  }
  getComparaison(produitId: any, marchesId: any, mesureId:any, dateDebut: string): Observable <any> {
    return this.http.get(`${this.urlComparaisonPrix}`, {
      params: new HttpParams()
          .set('produitId', produitId)
          .set('marcheId', marchesId)
          .set('mesureId', mesureId)
          .set('dateDebut', dateDebut)
    }).pipe(
        map(res => res['barcharts'])
    );
  }
  getComparaisonRegion(produitId: any, regionsId: any, mesureId:any, dateDebut: string): Observable <any> {
    return this.http.get(`${this.urlComparaisonPrixRegion}`, {
      params: new HttpParams()
          .set('produitId', produitId)
          .set('regionsId', regionsId)
          .set('mesureId', mesureId)
          .set('dateDebut', dateDebut)
    }).pipe(
        map(res => res['barcharts'])
    );
  }

}

