import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {ProduitService} from '../produit/produit.service';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';
import {IOption} from '../../../ui/interfaces/option';
import {MesureService} from '../mesure/mesure.service';
import {Mesure} from '../models/mesure';
import {StockService} from './stock.service';
import {DepotService} from '../depot/depot.service';
import {Depot} from '../models/depot';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {UserData} from '../models/userData';
import {fadeIn} from '../../../animations/form-error';
import {Acteur} from '../models/acteur';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';

@Component({
  selector: 'tc-saisie-stock',
    templateUrl: './saisie-stock.component.html',
    styleUrls: ['./saisie-stock.component.scss'],
    animations: [fadeIn]
})

export class SaisieStockComponent extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit  {
  SelectedDepotId: string;
  data: any;
  connected: Acteur;
  depotsAdapter: IOption[] = [];
  mesures: Mesure[];
  mesuresAdapter: IOption[] = [];
    depots: Depot[];
    editCache: { [key: string]: any } = {};
    listOfData: any[] = [];
    outData: any[] = [];
    produits: any[];
    @ViewChild('dateStock', {static: false}) mydateStock: jqxDateTimeInputComponent;
    dateTimeInputSettings: jqwidgets.DateTimeInputOptions = {
        formatString: 'F', showTimeButton: true, width: '200px', height: '25px'
    };
    ngAfterViewInit(): void {
        //   this.mydatePrix.createComponent(this.dateTimeInputSettings);

    }

    saveEdit(id: string): void {
        const index = this.listOfData.findIndex(item => item.id === id);
        Object.assign(this.listOfData[index], this.editCache[id].data);
        this.editCache[id].edit = true;
    }

    updateEditCache(): void {
        this.listOfData.forEach(item => {
            this.editCache[item.id] = {
                edit: true,
                data: { ...item }
            };
        });
    }



    enregistrer() {

        this.listOfData.forEach(item => {
            if ((this.editCache[item.id].data.stock > 0) ) {
            this.outData.push(this.editCache[item.id].data);
            }
        });

        this.data = {data: {
            dateStock: this.mydateStock.getDate().toLocaleDateString('en-US'),
             stocks: this.outData,
            depot: this.SelectedDepotId
        }};
        this.stockService.create(this.data) .subscribe(
            data => {

                this.toastr.success('Enregistrement réussie des stocks ', '',
                    {timeOut: 2000});
                this.onProduitsPromise();
            },
            error => console.log(error));
    }
    onProduitsPromise() {
        this.listOfData = [];
        this.produits.sort((a, b) =>  (a.nom > b.nom) ? 1 : 0);
        this.produits.forEach(produit => {
            this.listOfData.push({
                id: produit.id,
                nomProduit: produit.nom,
                stock: '',
                mesure: produit.mesure.id.toString()
            });
        });
        this.updateEditCache();
    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        for (const depot of this.depots) {
            this.depotsAdapter.push({'value' : depot.id.toString(), 'label' : depot.nom});
        }

    }

    getCodeMesure(idMesure: string): string {
        const  mesureSelected: Mesure = this.mesures.find(obj => obj.id.toString() === idMesure);
        return mesureSelected.code;
    }

  constructor( store: Store<IAppState>,
               httpSv: HttpService, private depotService: DepotService,
               private route: ActivatedRoute,
               public router: Router, private toastr: ToastrService, private stockService: StockService,
               private produitService: ProduitService, private mesureService: MesureService,
               private fb: FormBuilder, private i18n: NzI18nService, private authObject: AuthenticationService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: 'Saisie des stocks',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
    this.i18n.setLocale(fr_FR);
    this.depots = [];
    this.getData(this.depotService.getAllURL(), 'depots', 'setLoaded');

  }
    onMesuresPromise() {
        for (const mesure of this.mesures) {
            this.mesuresAdapter.push({'value' : mesure.id.toString(), 'label' : mesure.code});
        }
    }
  ngOnInit() {
    super.ngOnInit();
    this.SelectedDepotId = '1' ;
    this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
    this.produitService.getByReseaux().subscribe(produits => {
          this.produits = produits ;
          this.onProduitsPromise();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
