
<!--<div style="padding: 20px; border: dotted; box-shadow: #9b9b9b; color:darkgray; background-color: rgba(249,249,249,0.96)">-->
<p>Bienvenue <strong>{{connectedName()}}</strong> sur le module d'administration de la plateforme SIMA-BF</p>
<p>Vous êtes connecté avec le code <strong>{{connectedLogin()}}</strong>, en tant que  <strong>{{connectedRole()}}</strong></p>

<ng-container *ngIf="offresAchatsTop10 && offresAchatsTop10.length>0 && connectedUser && isGroupeAdmin()">

<tc-card [padding]="20" class="table-card">
  <div tc-card-header class="card-header mb-4">
    <div class="title-box">
      <h5 class="title">Les dernières offres d'achat</h5>
    </div>

    <div class="actions d-flex">
      <tc-icon [iconClass]="'icofont-file-excel'"  (click)="exportOffresAchat()"></tc-icon>
    </div>
  </div>

  <tc-table  [rows]="offresAchatsTop10"  [headerBgColor]="'#C7B54A'" [hovered]="true" [pagination]="true" [itemsPerPage]="5" [headerColor]="'#fff'">


    <tc-table-col [columnTitle]="'Produit'" [columnName]="'nom_produit'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Qualité'" [columnName]="'nom_qualite'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

      <tc-table-col [columnTitle]="'Quantité'" [columnName]="'quantite'">
        <ng-template #tableTDTemplate let-value>
          <span class="time">{{ value }}</span>
        </ng-template>
      </tc-table-col>

    <tc-table-col [columnTitle]="'Mesure'" [columnName]="'mesure'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Prix Unitaire'" [columnName]="'prixUnitaire'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

      <tc-table-col [columnTitle]="'Prix Global'" [columnName]="'montant'">
        <ng-template #tableTDTemplate let-value>
          <span class="time">{{ value }}</span>
        </ng-template>
      </tc-table-col>

      <tc-table-col [columnTitle]="'Nom Acheteur '" [columnName]="'nom_auteur'">
        <ng-template #tableTDTemplate let-value>
          <span class="time">{{ value }}</span>
        </ng-template>
      </tc-table-col>

      <tc-table-col [columnTitle]="'Téléphone Acheteur'" [columnName]="'telephone'">
        <ng-template #tableTDTemplate let-value>
          <span class="time">{{ value }}</span>
        </ng-template>
      </tc-table-col>

  </tc-table>
</tc-card>
</ng-container>

<ng-container *ngIf="offresVentesTop10 && offresVentesTop10.length>0 && connectedUser && isGroupeAdmin()">
<tc-card [padding]="20" class="table-card">
  <div tc-card-header class="card-header mb-4">
    <div class="title-box">
      <h5 class="title">Les dernières offres de vente</h5>
    </div>

    <div class="actions d-flex">
      <tc-icon [iconClass]="'icofont-file-excel'"  (click)="exportOffresVente()"></tc-icon>
    </div>
  </div>

  <tc-table [rows]="offresVentesTop10" [hovered]="true" [headerBgColor]="'#C7B54A'" [pagination]="true" [itemsPerPage]="5" [headerColor]="'#fff'">


    <tc-table-col [columnTitle]="'Produit'" [columnName]="'nom_produit'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Qualité'" [columnName]="'nom_qualite'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Quantité'" [columnName]="'quantite'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Mesure'" [columnName]="'mesure'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Prix Unitaire'" [columnName]="'prixUnitaire'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Prix Global'" [columnName]="'montant'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Nom Acheteur '" [columnName]="'nom_auteur'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

    <tc-table-col [columnTitle]="'Téléphone Acheteur'" [columnName]="'telephone'">
      <ng-template #tableTDTemplate let-value>
        <span class="time">{{ value }}</span>
      </ng-template>
    </tc-table-col>

  </tc-table>
</tc-card>
</ng-container>

<ng-container *ngIf="statistiques">
<div class="row">
  <div class="col col-12 col-md-6 col-xl-4">
    <tc-card class="animated zoomIn delay-03s">
      <div class="row align-items-center pt-2">
        <div class="col col-auto">
          <tc-icon
                  [tcShape]="500"
                  [tcFontSize]="35"
                  [padding]="'15px'"
                  [tcColor]="'rgb(7,7,7)'"
                  [iconClass]="'icofont-users-social'"
                  [tcBgColor]="'#C7B54A'"
          ></tc-icon>
        </div>

        <div class="col col-auto">
          <h6 class="mt-0 mb-1 wrap">Utilisateurs</h6>
          <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.totalUtilisateur}}</div>
        </div>
      </div>
    </tc-card>
  </div>




  <div class="col col-12 col-md-6 col-xl-4">
    <tc-card class="animated zoomIn delay-03s">
      <div class="row align-items-center pt-2">
        <div class="col col-auto">
          <tc-icon
                  [tcShape]="500"
                  [tcFontSize]="35"
                  [padding]="'15px'"
                  [tcColor]="'rgb(7,7,7)'"
                  [iconClass]="'icofont-users-social'"
                  [tcBgColor]="'#C7B54A'"
          ></tc-icon>
        </div>

        <div class="col col-auto">
          <h6 class="mt-0 mb-1 wrap">Enqueteurs</h6>
          <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.totalEnqueteur}}</div>
        </div>
      </div>
    </tc-card>
  </div>
  <div class="col col-12 col-md-6 col-xl-4">
    <tc-card class="animated zoomIn delay-03s">
      <div class="row align-items-center pt-2">
        <div class="col col-auto">
          <tc-icon
                  [tcShape]="500"
                  [tcFontSize]="35"
                  [padding]="'15px'"
                  [tcColor]="'rgb(7,7,7)'"
                  [iconClass]="'icofont-users-social'"
                  [tcBgColor]="'#C7B54A'"
          ></tc-icon>
        </div>

        <div class="col col-auto">
          <h6 class="mt-0 mb-1 nowrap">Animateurs</h6>
          <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.totalAnimateur}}</div>
        </div>
      </div>
    </tc-card>
  </div>

</div>
  <div class="row">
    <div class="col col-12 col-md-6 col-xl-4">
      <tc-card class="animated zoomIn delay-03s">
        <div class="row align-items-center pt-2">
          <div class="col col-auto">
            <tc-icon
                    [tcShape]="500"
                    [tcFontSize]="35"
                    [padding]="'15px'"
                    [tcColor]="'rgb(7,7,7)'"
                    [iconClass]="'icofont-users-social'"
                    [tcBgColor]="'#C7B54A'"
            ></tc-icon>
          </div>

          <div class="col col-auto">
            <h6 class="mt-0 mb-1 nowrap">Administrateurs</h6>
            <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.totalAdmin}}</div>
          </div>
        </div>
      </tc-card>
    </div>
  <div class="col col-12 col-md-6 col-xl-4">
    <tc-card class="animated zoomIn delay-03s">
      <div class="row align-items-center pt-2">
        <div class="col col-auto">
          <tc-icon
                  [tcShape]="500"
                  [tcFontSize]="35"
                  [padding]="'15px'"
                  [tcColor]="'rgb(7,7,7)'"
                  [iconClass]="'icofont-users-social'"
                  [tcBgColor]="'#C7B54A'"
          ></tc-icon>
        </div>

        <div class="col col-auto">
          <h6 class="mt-0 mb-1 wrap">Superviseurs</h6>
          <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.totalSup}}</div>
        </div>
      </div>
    </tc-card>
  </div>

  </div>

  <div class="row">
    <div class="col col-12 col-md-6 col-xl-4">
      <tc-card class="animated zoomIn delay-03s">
        <div class="row align-items-center pt-2">
          <div class="col col-auto">
            <tc-icon
                    [tcShape]="500"
                    [tcFontSize]="35"
                    [padding]="'15px'"
                    [tcColor]="'rgb(7,7,7)'"
                    [iconClass]="'icofont-abacus-alt'"
                    [tcBgColor]="'#C7B54A'"
            ></tc-icon>
          </div>

          <div class="col col-auto">
            <h6 class="mt-0 mb-1 wrap">Nombre d'offres d'achats</h6>
            <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.nbreAchats}}</div>
          </div>
        </div>
      </tc-card>
    </div>

    <div class="col col-12 col-md-6 col-xl-4">
      <tc-card class="animated zoomIn delay-03s">
        <div class="row align-items-center pt-2">
          <div class="col col-auto">
            <tc-icon
                    [tcShape]="500"
                    [tcFontSize]="35"
                    [padding]="'15px'"
                    [tcColor]="'rgb(7,7,7)'"
                    [iconClass]="'icofont-abacus-alt'"
                    [tcBgColor]="'#C7B54A'"
            ></tc-icon>
          </div>

          <div class="col col-auto">
            <h6 class="mt-0 mb-1 wrap">Nombre d'offres de vente</h6>
            <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.nbreVentes}}</div>
          </div>
        </div>
      </tc-card>
    </div>
    <div class="col col-12 col-md-6 col-xl-4">
      <tc-card class="animated zoomIn delay-03s">
        <div class="row align-items-center pt-2">
          <div class="col col-auto">
            <tc-icon
                    [tcShape]="500"
                    [tcFontSize]="35"
                    [padding]="'15px'"
                    [tcColor]="'rgb(7,7,7)'"
                    [iconClass]="'icofont-abacus-alt'"
                    [tcBgColor]="'#C7B54A'"
            ></tc-icon>
          </div>

          <div class="col col-auto">
            <h6 class="mt-0 mb-1 wrap">Nombre de prix montés</h6>
            <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.nbrePrix}}</div>
          </div>
        </div>
      </tc-card>
    </div>
  </div>
    <div class="row">
      <div class="col col-12 col-md-6 col-xl-4">
        <tc-card class="animated zoomIn delay-03s">
          <div class="row align-items-center pt-2">
            <div class="col col-auto">
              <tc-icon
                      [tcShape]="500"
                      [tcFontSize]="35"
                      [padding]="'15px'"
                      [tcColor]="'rgb(7,7,7)'"
                      [iconClass]="'icofont-money'"
                      [tcBgColor]="'#C7B54A'"
              ></tc-icon>
            </div>

            <div class="col col-auto">
              <h6 class="mt-0 mb-1 wrap">Volume des offres d'achat</h6>
              <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.volumeOffresAchats}}</div>
            </div>
          </div>
        </tc-card>
      </div>

      <div class="col col-12 col-md-6 col-xl-4">
        <tc-card class="animated zoomIn delay-03s">
          <div class="row align-items-center pt-2">
            <div class="col col-auto">
              <tc-icon
                      [tcShape]="500"
                      [tcFontSize]="35"
                      [padding]="'15px'"
                      [tcColor]="'rgb(7,7,7)'"
                      [iconClass]="'icofont-money'"
                      [tcBgColor]="'#C7B54A'"
              ></tc-icon>
            </div>

            <div class="col col-auto">
              <h6 class="mt-0 mb-1 wrap">Volume des offres de vente</h6>
              <div class="count" [tcFontSize]="20" [tcColor]="'#0c180c'">{{statistiques.volumeOffresVentes}}</div>
            </div>
          </div>
        </tc-card>
      </div>
    </div>
</ng-container>
<ng-container *ngIf="chartsReady">
  <div class="row">
    <div class="col col-12 col-md-6">
      <tc-card [title]="'Inscrits par genre'"  [padding]="2">
        <div echarts [options]="cgOptions" class="chart-container container-h-300"></div>
      </tc-card>
    </div>

    <div class="col col-12 col-md-6">
      <tc-card [title]="'Inscrits par réseaux'"  [padding]="2">
        <div echarts [options]="dOptions" class="chart-container container-h-300"></div>
      </tc-card>
    </div>
  </div>
</ng-container>



