<div class="tc-passwordinput-wrap" (click)="onFocus(disabled || readonly)" [ngStyle]="getStyles()">
  <div
    class="input-prefix input-icon"
    [ngStyle]="{ 'color': properties.prefixIconColor }"
    *ngIf="properties.prefixIconValue"
  >
    <span class="{{ properties.prefixIconValue }}"></span>
  </div>

  <div class="input-prefix" *ngIf="properties.prefixValue" [ngStyle]="{ 'color': properties.prefixColor }">
    {{ properties.prefixValue }}
  </div>

  <div class="input-infix">
    <input
      type="{{ getTypeOfPassword() }}"
      name="{{ name }}"
      placeholder="{{ placeholder }}"
      class="input-control"
      [(ngModel)]="value"
      [attr.maxlength]="charLimiting"
      [readonly]="readonly ? true : null"
      [required]="required ? true : null"
      (focus)="onFocus(disabled || readonly)"
      (blur)="onBlur(disabled || readonly)"
    >
  </div>

<!--  <div class="input-suffix" *ngIf="properties.suffixValue" [ngStyle]="{ 'color': properties.suffixColor } "-->
<!--       (click)="clickEye" >-->
<!--&lt;!&ndash;    {{ properties.suffixValue }}&ndash;&gt;-->

<!--      <i alt="show" class="far icofont-eye"-->
<!--         (click)="clickEye"-->
<!--         [class.hide]="showPassword"></i>-->
<!--      <i alt="hide" class="far icofont-eye-blocked"-->
<!--         (click)="showPassword = !showPassword"-->
<!--         [class.hide]="!showPassword"></i>-->

<!--  </div>-->

<!--  <div-->
<!--          class="input-suffix input-icon"-->
<!--          [ngStyle]="{ 'color': properties.suffixIconColor }"-->
<!--          *ngIf="properties.suffixIconValue" (click)="clickEye"-->
<!--  >-->
<!--&lt;!&ndash;    <span class="{{ properties.suffixIconValue }}" ></span>&ndash;&gt;-->
<!--  </div>-->
<!--  <button type="button"  (click)="clickEye($event)" >-->
<!--    Click Me</button>-->
<!--  <button class="input-suffix input-icon" tc-button  [block]="true"  (click)="clickEye()">Entrer</button>-->


  <div
    class="input-suffix input-icon"
    [ngStyle]="{ 'color': properties.suffixIconColor }"
    *ngIf="properties.suffixIconValue"
  >
    <span class="{{ eyeStatus }}" (click)="clickEye($event)" ></span>
  </div>

  <div class="char-limiting" *ngIf="charLimiting > 0">{{ charLength }}</div>
</div>
