var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { ToastrService } from 'ngx-toastr';
import { CommuneService } from '../../commune/commune.service';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, Validators } from '@angular/forms';
import { GenreService } from '../../../../services/sharedServices/genre.service';
import { ReseauService } from '../../reseau/reseau.service';
import { GroupeService } from '../../groupe/groupe.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MarcheService } from '../../marche/marche.service';
import { ProduitService } from '../../produit/produit.service';
import { DepotService } from '../../depot/depot.service';
import { ActeurEdit } from '../../models/acteurEdit';
import { ActiviteService } from '../../activite/activite.service';
import { Marche } from '../../models/marche';
import { Produit } from '../../models/produit';
import { Depot } from '../../models/depot';
var PageCreateAdministrateurComponent = /** @class */ (function (_super) {
    __extends(PageCreateAdministrateurComponent, _super);
    function PageCreateAdministrateurComponent(store, httpSv, administrateurService, route, router, toastr, genreService, activiteService, communeService, i18n, reseauService, marcheService, produitService, depotService, fb, groupeService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.administrateurService = administrateurService;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.genreService = genreService;
        _this.activiteService = activiteService;
        _this.communeService = communeService;
        _this.i18n = i18n;
        _this.reseauService = reseauService;
        _this.marcheService = marcheService;
        _this.produitService = produitService;
        _this.depotService = depotService;
        _this.fb = fb;
        _this.groupeService = groupeService;
        _this.intPhoneRegex = '^[+][0-9]{1,3}[0-9]{4,14}$';
        _this.communeTree = [];
        _this.genres = [];
        _this.activites = [];
        _this.marchesAdapter = [];
        _this.activitesAdapter = [];
        _this.mobilePhoneKeyUp = new Subject();
        _this.groupes = [];
        _this.reseaux = [];
        _this.tree = [];
        _this.reseauxAdapter = [];
        _this.groupesAdapter = [];
        _this.produitsAdapter = [];
        _this.depotsAdapter = [];
        _this.submitted = false;
        _this.changes = false;
        _this.Columnslabels = {
            nom: 'Nom',
            prenom: 'Prénom',
            mobilePhone: 'N° Mobile',
            genre: 'Sexe',
            email: 'Email',
            password: 'Mot de passe',
            confirmpassword: 'Confirmer le Mot de passe',
            activite: 'Activité',
            commune: 'Commune',
            reseau: 'Réseau',
            groupes: 'Groupes',
            enabled: 'Actif',
            produits: 'Produits',
            marchespreference: 'Marchés de préférence',
            marchesuivis: 'Marchés suivis',
            depotPreference: 'Dépôts de préférence',
            depotSuivi: 'Dépôts suivis'
        };
        _this.administrateur = {};
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des administrateurs',
                    route: '/vertical/list-administrateur'
                }
            ]
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    PageCreateAdministrateurComponent.getAdministrateur = function (jsonValue) {
        return ActeurEdit.fromJSON(jsonValue);
    };
    PageCreateAdministrateurComponent.prototype.getTitle = function () {
        if (this.isEditing()) {
            var prefixe = ' Modifier ';
            if (this.userType === 'administrateur') {
                return prefixe + '  un administrateur';
            }
            else if (this.userType === 'superviseur') {
                return prefixe + ' un superviseur';
            }
            else if (this.userType === 'enqueteur') {
                return prefixe + ' un enqueteur';
            }
            else if (this.userType === 'animateur') {
                return prefixe + ' un animateur';
            }
            else if (this.userType === 'utilisateur') {
                return prefixe + ' un utilisateur';
            }
            else {
                return prefixe + ' un utilisateur';
            }
        }
        else {
            var prefixe = ' créer ';
            if (this.userType === 'administrateur') {
                return prefixe + '  un administrateur';
            }
            else if (this.userType === 'superviseur') {
                return prefixe + ' un superviseur';
            }
            else if (this.userType === 'animateur') {
                return prefixe + ' un animateur';
            }
            else if (this.userType === 'enqueteur') {
                return prefixe + ' un enqueteur';
            }
            else if (this.userType === 'utilisateur') {
                return prefixe + ' un utilisateur';
            }
            else {
                return prefixe + ' un utilisateur';
            }
        }
    };
    PageCreateAdministrateurComponent.prototype.getMarcheObject = function (jsonValue) {
        return Marche.fromJSON(jsonValue);
    };
    PageCreateAdministrateurComponent.prototype.getProduitObject = function (jsonValue) {
        return Produit.fromJSON(jsonValue);
    };
    PageCreateAdministrateurComponent.prototype.getDepotObject = function (jsonValue) {
        return Depot.fromJSON(jsonValue);
    };
    PageCreateAdministrateurComponent.prototype.onMarchesPromise = function () {
        this.marchesAdapter = [];
        for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
            var marche = _a[_i];
            this.marchesAdapter.push({ 'value': marche.id.toString(), 'label': this.getMarcheObject(marche).getFullName });
        }
    };
    PageCreateAdministrateurComponent.prototype.onProduitsPromise = function () {
        this.produitsAdapter = [];
        for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
            var produit = _a[_i];
            this.produitsAdapter.push({ 'value': produit.id.toString(), 'label': this.getProduitObject(produit).getFullName });
        }
    };
    PageCreateAdministrateurComponent.prototype.onDepotsPromise = function () {
        this.depotsAdapter = [];
        for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
            var depot = _a[_i];
            this.depotsAdapter.push({ 'value': depot.id.toString(), 'label': this.getDepotObject(depot).getFullName });
        }
    };
    PageCreateAdministrateurComponent.prototype.onGenresPromise = function () {
    };
    PageCreateAdministrateurComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.pageData.title = this.getTitle();
        this.setReactiveForm();
    };
    PageCreateAdministrateurComponent.prototype.isEditing = function () {
        return (this.administrateur.id != null);
    };
    PageCreateAdministrateurComponent.prototype.initReactiveForm = function () {
        this.userForm = this.fb.group({
            mobilePhone: ['', [Validators.required, Validators.pattern(this.intPhoneRegex)]],
            nom: ['', [Validators.required, Validators.minLength(2)]],
            prenom: ['', [Validators.required, Validators.minLength(2)]],
            email: ['', [Validators.email]],
            genre: ['Masculin', Validators.required],
            depotPreference: [''],
            depotSuivi: [''],
            marcheSuivi: [''],
            marchePreference: [''],
            activite: [''],
            reseau: [''],
            groupes: [''],
            commune: [''],
            enabled: [true]
        });
    };
    PageCreateAdministrateurComponent.prototype.onSelectReseau = function ($event) {
        var _this = this;
        if (this.reseaux != null) {
            var reseauSelected = this.reseaux.find(function (obj) { return obj.id.toString() === _this.userForm.value.reseau; });
            if (reseauSelected != null) {
                this.getData(this.groupeService.getAllByReseauURL(reseauSelected.id), 'groupes', 'onGroupesPromise');
            }
        }
    };
    PageCreateAdministrateurComponent.prototype.setReactiveForm = function () {
        if (this.administrateur) {
            var admin = PageCreateAdministrateurComponent.getAdministrateur(this.administrateur);
            var selectedGroups = admin.selectedGroups;
            var reseau = (this.administrateur.reseau != null) ? this.administrateur.reseau.toString() : '';
            var marchesSuiviSelected = (admin.selectedMarchesSuivis !== []) ? admin.selectedMarchesSuivis : '';
            var marchesPreferenceSelected = (admin.selectedMarchesPreference !== []) ? admin.selectedMarchesPreference : '';
            var commune = (this.administrateur.communeTab != null) ? this.administrateur.communeTab : ['', '', ''];
            var depotssuivis = (admin.selectedDepotsSuivis !== []) ? admin.selectedDepotsSuivis : '';
            var depotspreference = (admin.selectedDepotsPreference !== []) ? admin.selectedDepotsPreference : '';
            console.log('marchesSuiviSelected : ', marchesSuiviSelected);
            this.userForm.patchValue({
                mobilePhone: this.administrateur.mobilePhone,
                nom: this.administrateur.nom,
                prenom: this.administrateur.prenom,
                enabled: this.administrateur.enabled,
                genre: this.administrateur.genre,
                email: this.administrateur.email,
                activite: (this.administrateur.activite != null) ? this.administrateur.activite.toString() : '',
                reseau: reseau,
                groupes: selectedGroups,
                marcheSuivi: marchesSuiviSelected,
                marchePreference: marchesPreferenceSelected,
                depotPreference: depotspreference,
                depotSuivi: depotssuivis,
                commune: commune
            });
        }
    };
    PageCreateAdministrateurComponent.prototype.onChangeReseau = function () {
        var _this = this;
        if (this.reseaux != null) {
            this.userForm.get('reseau').valueChanges.subscribe(function () {
                var reseauSelected = _this.reseaux.find(function (obj) { return obj.id.toString() === _this.userForm.value.reseau; });
                if (reseauSelected != null) {
                    _this.getData(_this.groupeService.getAllByReseauURL(reseauSelected.id), 'groupes', 'onSelectReseauxPromise');
                }
            });
        }
    };
    PageCreateAdministrateurComponent.prototype.onGroupesPromise = function () {
        this.groupesAdapter = [];
        for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
            var groupe = _a[_i];
            this.groupesAdapter.push({ 'value': groupe.id.toString(), 'label': groupe.code });
        }
    };
    PageCreateAdministrateurComponent.prototype.onActivitesPromise = function () {
        this.activitesAdapter = [];
        for (var _i = 0, _a = this.activites; _i < _a.length; _i++) {
            var activite = _a[_i];
            this.activitesAdapter.push({ 'value': activite.id.toString(), 'label': activite.nom });
        }
    };
    PageCreateAdministrateurComponent.prototype.resetPassword = function () {
        var _this = this;
        this.administrateurService.resetPassword(this.administrateur.mobilePhone).subscribe(function (resultat) {
            if (resultat.status === 'OK') {
                _this.toastr.success('Succès de la réinitialisation mot de passe ', '', { timeOut: 2000 });
            }
            else {
                _this.toastr.error('Echec de la réinitialisation du mot de passe ', '', { timeOut: 2000 });
            }
        });
    };
    PageCreateAdministrateurComponent.prototype.onSelectReseauxPromise = function () {
        this.groupesAdapter = [];
        for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
            var groupe = _a[_i];
            this.groupesAdapter.push({ 'value': groupe.id.toString(), 'label': groupe.nom });
        }
    };
    PageCreateAdministrateurComponent.prototype.onReseauxPromise = function () {
        for (var _i = 0, _a = this.reseaux; _i < _a.length; _i++) {
            var reseau = _a[_i];
            this.reseauxAdapter.push({ 'value': reseau.id.toString(), 'label': reseau.code });
        }
    };
    PageCreateAdministrateurComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.getData(this.genreService.getAllURL(), 'genres', 'onGenresPromise');
        this.reseauService.getCached().subscribe(function (data) {
            _this.reseaux = data;
            _this.onReseauxPromise();
        });
        this.groupeService.getCached().subscribe(function (data) {
            _this.groupes = data;
            _this.onGroupesPromise();
        });
        this.marcheService.getCached().subscribe(function (data) {
            _this.marches = data;
            _this.onMarchesPromise();
        });
        this.depotService.getCached().subscribe(function (data) {
            _this.depots = data;
            _this.onDepotsPromise();
        });
        this.activiteService.getCached().subscribe(function (data) {
            _this.activites = data;
            _this.onActivitesPromise();
        });
        this.fromURL = this.route.snapshot.url.toString();
        this.communeService.findAsTree().subscribe(function (tree) {
            _this.communeTree = tree;
        });
        this.route.params.subscribe(function (params) {
            _this.userType = params['userType'];
            _this.fromURL = params['fromURL'];
            if (params['id']) {
                _this.selectedId = params['id'];
                _this.getDataByName(_this.administrateurService.getURL(_this.selectedId), 'administrateur', 'acteur', 'setLoaded');
            }
            else {
                _this.administrateur = {};
                _this.initReactiveForm();
            }
        });
    };
    PageCreateAdministrateurComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.isEditing()) {
            this.mobilePhoneSubscription = this.mobilePhoneKeyUp.pipe(debounceTime(100), distinctUntilChanged(), tap(function () {
                if (_this.userForm.value.mobilePhone.length === 12) {
                    _this.administrateurService.checkAlreadyCreated(_this.userForm.value.mobilePhone).subscribe(function (checkActeur) {
                        if (checkActeur.found) {
                            _this.userForm.get('mobilePhone').setErrors({ alreadyExist: true });
                            _this.toastr.warning('Un utilisateur avec le numéro ' +
                                _this.userForm.value.mobilePhone + ' existe déjà !!!, son nom : ' + checkActeur.nomComplet);
                        }
                    });
                }
            })).subscribe();
        }
    };
    PageCreateAdministrateurComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageCreateAdministrateurComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-administrateur']);
    };
    PageCreateAdministrateurComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-administrateur']);
    };
    PageCreateAdministrateurComponent.prototype.isValidPhoneNumber = function (entered_number) {
        var regexStr = '^[+][0-9]{10,13}$';
        var regexp = new RegExp(regexStr);
        if (entered_number.length < 10 || entered_number.length > 13 || !entered_number.match(regexp)) {
            this.toastr.warning('Veuillez entrer un numéro de téléphone valide', '', { timeOut: 2000 });
            return false;
        }
        else {
            return true;
        }
    };
    PageCreateAdministrateurComponent.prototype.mapFormValuesToModel = function () {
        this.administrateur.mobilePhone = this.userForm.value.mobilePhone;
        this.administrateur.nom = this.userForm.value.nom;
        this.administrateur.prenom = this.userForm.value.prenom;
        this.administrateur.email = this.userForm.value.email;
        this.administrateur.reseau = this.userForm.value.reseau;
        this.administrateur.groupes = this.userForm.value.groupes;
        this.administrateur.enabled = this.userForm.value.enabled;
        this.administrateur.genre = this.userForm.value.genre;
        this.administrateur.marcheSuivi = this.userForm.value.marcheSuivi;
        this.administrateur.marchePreference = this.userForm.value.marchePreference;
        this.administrateur.depotSuivi = this.userForm.value.depotSuivi;
        this.administrateur.depotPreference = this.userForm.value.depotPreference;
    };
    PageCreateAdministrateurComponent.prototype.update = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.administrateurService.update(this.administrateur.id, this.userType, this.administrateur)
            .subscribe(function (data) {
            _this.toastr.success('Modification réussie', '', { timeOut: 2000 });
            _this.router.navigate(['/vertical/list-' + _this.userType,
                { userType: _this.userType }]);
        }, function (error) { return console.log(error); });
    };
    PageCreateAdministrateurComponent.prototype.create = function () {
        var _this = this;
        this.mapFormValuesToModel();
        this.administrateurService.create(this.userType, this.administrateur)
            .subscribe(function (data) {
            _this.toastr.success('Création réussie de ' + _this.administrateur.toString(), '', { timeOut: 2000 });
            _this.userForm.reset();
        }, function (error) { return console.log(error); });
    };
    PageCreateAdministrateurComponent.prototype.onSubmit = function () {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        }
        else {
            this.create();
        }
    };
    return PageCreateAdministrateurComponent;
}(BasePageComponent));
export { PageCreateAdministrateurComponent };
