<div class="row" *ngIf="userInfo">
  <div class="col col-12 col-md-6 mb-4 mb-md-0">
    <tc-card  [tcGradient]="['#fff', '#fbfbfb']" class="personal-info-card">
      <div class="d-flex align-items-center justify-content-between mb-3 user-actions">
        <tc-avatar [src]="'assets/img/bamig90x60.png'" [size]="90" [bgColor]="'white'" [initials]="'No'"></tc-avatar>

      </div>

<!--      <div class="d-flex align-items-center justify-content-between">-->
<!--        <h5 class="mb-0 mt-0 mr-1">{{ userInfo.mainInfo.firstName }} {{ userInfo.mainInfo.lastName }}</h5>-->

<!--        <tc-rating [value]="5" [disabled]="true"></tc-rating>-->
<!--      </div>-->

      <p [tcColor]="'#8f8f90'">{{ userInfo.mainInfo.position }}</p>

      <p>Le Bureau d’Appui en Management et Informatique de Gestion créé en 1992 est une société de Services en Ingénierie  Informatique. Notre mission est d’offrir aux entreprises et aux organisations les outils technologiques les plus modernes, les plus faciles à utiliser et les plus adaptés à leur contexte.

        Grâce à une approche multi-disciplinaire et à un management efficace et visionnaire nous avons réussi à mettre au point une suite de progiciels intégrés pour la gestion des entreprises. Il s’agit de l’ERP SIMSystem. Cet outil se décline en solutions métiers qui sont :

        SIMCommerce conforme au SYSCOHADA  pour les PME , PMI, ONG et Industries
        SIMEPE conforme à la comptabilité publique pour les Etablissements Publiques de l’Etat
        SIMSFD  conforme au référentiel BECEAO pour les systèmes financiers décentralisés.
        SIMOSS qui intègre le plan CIPRESS pour les organismes de sécurité sociale.
        Nous disposons en outre d’un laboratoire de R&D qui conçoit et développe des outils technologiques d’avant-garde et sur mesure pour les organisations et les entreprises.  C’est dans ce cadre que nous avons relevé le défi de la réalisation de la plateforme technologique SIMAgri.

        Vous avez besoin de moderniser votre système d’information ? Vous voulez réaliser un  projet technologique ambitieux ? Nous sommes à votre service à tout moment. Vous pouvez nous contacter aux adresses ci-dessous :

        Téléphones: (+226) 50363336, (+226) 50363865, (+226) 50454232 , (+226) 74009128

        e-mail :  bamigsoft@bamig.com, bamigsoft@gmail.com, tiramakantraore@gmail.com

        Site web :  www.bamig.com</p>
    </tc-card>

    <tc-card [tcGradient]="['#fff', '#fbfbfb']" [title]="'Sites Web et Réseaux Sociaux'">

      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-twitter'" [tcFontSize]="30" [tcColor]="'#1da1f2'"></tc-icon>
        </div>
        <div class="col">
          <div>Twitter</div>
          <a href="http://www.twitter.com/bamig" (click)="$event.preventDefault()">https://twitter.com/bamig</a>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-linkedin'" [tcFontSize]="30" [tcColor]="'#0073b1'"></tc-icon>
        </div>
        <div class="col">
          <div>Linkedin</div>
          <a href="http://www.linkedin.com/company/bamig" (click)="$event.preventDefault()">linkedin.com/bamig</a>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-youtube'" [tcFontSize]="30" [tcColor]="'#ff0000'"></tc-icon>
        </div>
        <div class="col">
          <div>YouTube</div>
          <a href="https://www.youtube.com/user/bamig" (click)="$event.preventDefault()">youtube.com/user/BAMIG</a>
        </div>
      </div>
    </tc-card>

    <tc-card [tcGradient]="['#fff', '#fbfbfb']" [title]="'Informations des contacts'" class="mb-md-0">
      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-ui-touch-phone'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Mobile</div>
          {{ userInfo.mainInfo.number }}
        </div>
      </div>


      <div class="row align-items-center mb-3">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-skype'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Skype</div>
          {{ userInfo.mainInfo.skype }}
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-location-pin'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>Adresse actuelle</div>
          {{ userInfo.mainInfo.address }}
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col col-auto">
          <tc-icon [iconClass]="'icofont-location-pin'" [tcFontSize]="30" [tcColor]="'#8f8f90'"></tc-icon>
        </div>
        <div class="col">
          <div>e-mail</div>
          {{ userInfo.mainInfo.email }}
        </div>
      </div>
    </tc-card>
  </div>

  <div class="col col-12 col-md-6">
    <tc-card [title]="'Experience'" [tcGradient]="['#fff', '#fbfbfb']">
      <tc-v-timeline [data]="userInfo.experience" [showIcons]="false"></tc-v-timeline>
    </tc-card>

    <tc-card [title]="'Languages de développement'" [tcGradient]="['#fff', '#fbfbfb']" class="mb-0">
      <div class="skills-list">
        <tc-badge [tcShape]="500" *ngFor="let skill of userInfo.langages">{{ skill }}</tc-badge>
      </div>
    </tc-card>

    <tc-card [title]="'Solutions'" [tcGradient]="['#fff', '#fbfbfb']" class="mb-0">
      <div class="skills-list">
        <tc-badge [tcShape]="500" *ngFor="let skill of userInfo.solutions">{{ skill }}</tc-badge>
      </div>
    </tc-card>
  </div>
</div>
