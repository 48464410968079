import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';

import {ValiderInfoService} from './validerInfo.service';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';
import {Content} from '../../../ui/interfaces/modal';
import {TCModalService} from '../../../ui/services/modal/modal.service';

export interface Info {
    id: number;
    titre: string;
    contenu: string;
    url: string;
    date: Date;
    dateExpiration: Date;
}


@Component({
  selector: 'tc-valider-info',
    templateUrl: './valider-info.component.html',
    styleUrls: ['./valider-info.component.scss'],
    animations: [fadeIn]
})

export class ValiderInfoComponent extends BasePageComponent implements OnInit, OnDestroy {

  dateValidation = new Date();
  data: any;
  causeRejet = '';
    infosAValider: Info[];
    outData: any[] = [];

    /******************/

    isAllDisplayDataChecked = false;
    isOperating = false;
    isIndeterminate = false;
    listOfDisplayData: Info[] = [];
    listOfAllDataInfo: Info[] = [];
    mapOfCheckedId: { [key: string]: boolean } = {};
    numberOfChecked = 0;
    /******************************/
    valider() {
        this.outData = [];
        this.listOfAllDataInfo.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                console.log('item', item);
            this.outData.push(item);
            }
        });

        this.data = {data: {
            dateValidation: this.dateValidation.toLocaleDateString('en-US'),
            infos: this.outData
        }};
        if (this.outData.length > 0) {
        this.validerInfoService.valider(this.data) .subscribe(
            data => {

                this.toastr.success('Validation réussie des infos ', '',
                    {timeOut: 2000});
                this.loadInfo();
            },
            error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }
  constructor(store: Store<IAppState>,
              httpSv: HttpService,
              private route: ActivatedRoute, private authService: AuthenticationService,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService,
              private validerInfoService: ValiderInfoService, private modal: TCModalService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadInfo();
  }
    closeModal() {
        this.causeRejet = '';
        this.modal.close();
    }
    validerRejet() {

        if (this.causeRejet > '') {
            this.modal.close();
            this.rejeter();
        } else {
            this.toastr.error('Vous devez saisir une cause de rejet ');
        }
    }
    rejeter() {

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                infos: this.outData,
                causeRejet: this.causeRejet
            }};
        this.validerInfoService.rejeter(this.data) .subscribe(
            data => {

                this.toastr.success('rejet réussi des lignes d\'informations  sélectionnés', '',
                    {timeOut: 2000});
                this.loadInfo();
            },
            error => console.log(error));

    }
    openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
        this.outData = [];
        this.listOfAllDataInfo.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        } else {
            this.toastr.info('Vous devez sélectionner au moins un ligne d\'information ');
        }
    }
    private loadInfo() {
        this.listOfAllDataInfo = [];
        const reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerInfoService.getAllByReseau(reseauId.toString()).subscribe(data => {
                this.infosAValider = data;
                this.infosAValider.forEach((info, index, array) => {

                    this.listOfAllDataInfo.push({
                        id: info.id,
                        dateExpiration: info.dateExpiration,
                        titre: info.titre,
                        contenu: info.contenu,
                        date: info.date,
                        url: info.url
                    });
                });
            }
        );
        this.mapOfCheckedId = {};
        this.refreshStatus();

    }

    ngOnDestroy() {
    super.ngOnDestroy();
  }

    currentPageDataChange($event: Info[]): void {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    }

    refreshStatus(): void {
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(item => this.mapOfCheckedId[item.id]);
        this.isIndeterminate =
            this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
            !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataInfo.filter(item => this.mapOfCheckedId[item.id]).length;
    }

    checkAll(value: boolean): void {
        this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
        this.refreshStatus();
    }

    operateData(): void {
        this.isOperating = true;
        setTimeout(() => {
            this.listOfAllDataInfo.forEach(item => (this.mapOfCheckedId[item.id] = false));
            this.refreshStatus();
            this.isOperating = false;
        }, 1000);
    }
    exportByExcel() {
        ExcelService.exportAsExcelFile(this.infosAValider, 'info_a_valider');
    }

}
