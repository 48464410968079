import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { GroupeService } from '../groupe.service';
import { ToastrService } from 'ngx-toastr';
import {Groupe} from '../../models/groupe';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {ReseauService} from '../../reseau/reseau.service';
import {IOption} from '../../../../ui/interfaces/option';
import {INoeud} from '../../produit/create';

@Component({
    selector: 'tc-groupe-create',
    templateUrl: './create-groupe.component.html',
     animations: [fadeIn]
})
export class PageCreateGroupeComponent extends BasePageComponent implements OnInit, OnDestroy {
    groupeTree: INoeud[];
    treeReseau: INoeud[];
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private groupeService: GroupeService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private reseauService: ReseauService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.groupe = new Groupe();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
            ]
        };
        this.initReactiveForm();
    }
    groupeForm: FormGroup;
    groupe: any;
    reseaux: any[];
    reseauxAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            code: 'Code Groupe',
            label: 'Libellé Groupe',
            reseau: 'Réseau',
            description: 'Description',
            parent: 'Groupe Parent (Optionnel)',
            actif: 'actif'

        };
    static getGroupe (jsonValue): Groupe {
        return Groupe.fromJSON(jsonValue) ;
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);

            this.setReactiveForm();
            this.pageData.title = this.getTitle();


    }


    getTitle(): string {
       return  this.isEditing() ? ' Modifier un Groupe ' : ' Créer un Groupe ' ;
    }
    isEditing(): boolean {
        return (this.groupe.id != null);
    }
   initReactiveForm() {
        this.groupeForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                description: [''],
                reseau: ['', Validators.required],
                parent: [''],
                actif: [true],
                estFils: [false],
            }
        ) ;

    }

    setReactiveForm() {
        if ( this.groupe) {
            const groupe: Groupe = PageCreateGroupeComponent.getGroupe(this.groupe);
            const reseau = (groupe.reseau != null) ? groupe.reseau.id.toString() : '' ;
            this.groupeForm.patchValue({
                    code: this.groupe.code,
                    nom: this.groupe.nom,
                    description: this.groupe.description,
                    actif: this.groupe.actif,
                    parent: this.groupe.parent,
                    reseau: reseau
                }
            );
        }


    }

    onReseauxPromise() {
        for (const reseau of this.reseaux) {
            this.reseauxAdapter.push({'value' : reseau.id.toString(), 'label' : reseau.nom});
        }

    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.reseauService.getAllURL(), 'reseaux', 'onReseauxPromise');
        this.groupeForm.get('reseau').valueChanges.subscribe(() => {
            if ((this.groupeForm.value.reseau !== null) && (this.groupeForm.value.reseau !== '')) {
                this.groupeService.findAsTreeByReseau(this.groupeForm.value.reseau).subscribe(tree => {
                        this.groupeTree = tree;
                    }
                );
            }
        });
        this.reseauService.findAsTree().subscribe(tree => {
                this.treeReseau = tree;
            }
        );

        this.groupeService.findAsTree().subscribe(tree => {
                this.groupeTree = tree;
            }
        );
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.groupeService.getURL(this.selectedId), 'groupe', 'groupe', 'setLoaded');

            } else {
                this.groupe = new Groupe();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-groupe']);
    }
    liste() {
        this.router.navigate(['/vertical/list-groupe']);
    }
    mapFormValuesToModel() {
        this.groupe.code =  this.groupeForm.value.code;
        this.groupe.nom =  this.groupeForm.value.nom;
        this.groupe.description =  this.groupeForm.value.description;
        this.groupe.actif =  this.groupeForm.value.actif;
        this.groupe.reseau =  this.groupeForm.value.reseau;
        this.groupe.parent =  this.groupeForm.value.parent;

        this.groupe.estFils = true;
       }
    update(): void {
        if (this.groupeForm.valid) {
        this.mapFormValuesToModel();
        this.groupeService.update(this.groupe.id, this.groupe)
            .subscribe(
                data => {

                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/list-groupe']);
                        }
                ,
                error => console.log(error));
        }

    }
    create(): void {
        if (this.groupeForm.valid) {
            this.mapFormValuesToModel();
            this.groupeService.create(this.groupe)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie ' , '',
                            {timeOut: 2000});

                        this.groupeForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
