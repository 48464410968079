/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./button.component";
var styles_TCButtonComponent = [i0.styles];
var RenderType_TCButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCButtonComponent, data: {} });
export { RenderType_TCButtonComponent as RenderType_TCButtonComponent };
function View_TCButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "btn-icon before-icon ", _co.beforeIcon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_TCButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "btn-icon after-icon ", _co.afterIcon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_TCButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "btn-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon icofont-spinner"]], null, null, null, null, null))], null, null); }
export function View_TCButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "btn-line"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "border-style": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCButtonComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "btn-text"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCButtonComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TCButtonComponent_3)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.tcLineStyle); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.beforeIcon; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.afterIcon; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.load; _ck(_v, 11, 0, currVal_3); }, null); }
export function View_TCButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["tc-button", ""]], [[2, "tc-btn", null], [2, "btn-block", null], [2, "btn-square", null], [2, "btn-disabled", null], [2, "btn-load", null], [2, "btn-outline", null], [2, "btn-icon-animation", null], [2, "btn-left", null], [2, "btn-right", null], [2, "btn-sm", null], [2, "btn-lg", null], [2, "btn-default", null], [2, "btn-accent", null], [2, "btn-info", null], [2, "btn-accent-gradient", null], [2, "btn-success", null], [2, "btn-warning", null], [2, "btn-error", null], [2, "btn-gradient", null]], null, null, View_TCButtonComponent_0, RenderType_TCButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.TCButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).block; var currVal_2 = i1.ɵnov(_v, 1).square; var currVal_3 = i1.ɵnov(_v, 1).disabled; var currVal_4 = i1.ɵnov(_v, 1).load; var currVal_5 = i1.ɵnov(_v, 1).outline; var currVal_6 = i1.ɵnov(_v, 1).iconAnimation; var currVal_7 = i1.ɵnov(_v, 1).alignLeft; var currVal_8 = i1.ɵnov(_v, 1).alignRight; var currVal_9 = i1.ɵnov(_v, 1).sizeSm; var currVal_10 = i1.ɵnov(_v, 1).sizeLg; var currVal_11 = i1.ɵnov(_v, 1).viewDefault; var currVal_12 = i1.ɵnov(_v, 1).viewAccent; var currVal_13 = i1.ɵnov(_v, 1).viewInfo; var currVal_14 = i1.ɵnov(_v, 1).viewSuccess; var currVal_15 = i1.ɵnov(_v, 1).viewSuccess; var currVal_16 = i1.ɵnov(_v, 1).viewWarning; var currVal_17 = i1.ɵnov(_v, 1).viewError; var currVal_18 = i1.ɵnov(_v, 1).gradientView; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17, currVal_18]); }); }
var TCButtonComponentNgFactory = i1.ɵccf("[tc-button]", i3.TCButtonComponent, View_TCButtonComponent_Host_0, { block: "block", square: "square", disabled: "disabled", load: "load", outline: "outline", iconAnimation: "iconAnimation", align: "align", size: "size", view: "view", tcLineStyle: "tcLineStyle", beforeIcon: "beforeIcon", afterIcon: "afterIcon" }, {}, ["*"]);
export { TCButtonComponentNgFactory as TCButtonComponentNgFactory };
