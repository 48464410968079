var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { FormBuilder, Validators } from '@angular/forms';
var PageSECODEVComponent = /** @class */ (function (_super) {
    __extends(PageSECODEVComponent, _super);
    function PageSECODEVComponent(store, httpSv, formBuilder) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.formBuilder = formBuilder;
        _this.pageData = {
            title: 'SECODEV',
            breadcrumbs: []
        };
        _this.gender = [
            {
                label: 'Male',
                value: 'male'
            },
            {
                label: 'Female',
                value: 'female'
            }
        ];
        _this.status = [
            {
                label: 'Approved',
                value: 'approved'
            },
            {
                label: 'Pending',
                value: 'pending'
            }
        ];
        _this.defaultAvatar = 'assets/content/anonymous-400.jpg';
        _this.currentAvatar = _this.defaultAvatar;
        _this.changes = false;
        return _this;
    }
    PageSECODEVComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getData('assets/data/secodev.json', 'userInfo', 'loadedDetect');
    };
    PageSECODEVComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageSECODEVComponent.prototype.loadedDetect = function () {
        this.setLoaded();
        this.currentAvatar = this.userInfo.mainInfo.img;
        this.inituserForm(this.userInfo.mainInfo);
    };
    // init form
    PageSECODEVComponent.prototype.inituserForm = function (data) {
        var _this = this;
        this.userForm = this.formBuilder.group({
            img: [this.currentAvatar],
            firstName: [data.firstName, Validators.required],
            lastName: [data.lastName, Validators.required],
            number: [data.number, Validators.required],
            address: [data.address, Validators.required],
            gender: [data.gender, Validators.required],
            age: [data.age, Validators.required],
            lastVisit: [data.lastVisit, Validators.required],
            status: [data.status, Validators.required]
        });
        // detect form changes
        this.userForm.valueChanges.subscribe(function () {
            _this.changes = true;
        });
    };
    // save form data
    PageSECODEVComponent.prototype.saveData = function (form) {
        if (form.valid) {
            this.userInfo.mainInfo = form.value;
            this.changes = false;
        }
    };
    // upload new file
    PageSECODEVComponent.prototype.onFileChanged = function (inputValue) {
        var _this = this;
        var file = inputValue.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function () {
            _this.currentAvatar = reader.result;
            _this.changes = true;
        };
        reader.readAsDataURL(file);
    };
    return PageSECODEVComponent;
}(BasePageComponent));
export { PageSECODEVComponent };
