import {Commune} from './commune';

export class Depot {
	id: number;
	code: string;
	nom: string;
	mobile: string;
	description: string;
	actif: boolean;
	communeTab: string;
	commune: Commune;

	static fromJSON(json: any): Depot {
		const result = new Depot();
		if (json != null) {
		for (const key in json) {
			result[key] = json[key];
		}
		}

		return result;
	}
	get nomCommune() {
		let rval = '';
		// Array:
		if (this.commune != null) {
			rval = this.commune.nom;
		}
		return rval;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const depot = Depot.fromJSON(jsonObject);
				if (depot.commune) {
					jsonObject.commune = depot.commune.nom;
				}

				rval.push(jsonObject);
			}
		}
		return rval;
	}
	toString() {
		return this.nom;
	}
	get getFullName(): string {
		return (this.nom  + ' (' + this.code + ')');
	}
	constructor() {
		this.actif = true;
		this.commune = new Commune();
	}
}
