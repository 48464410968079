export const SETTINGS = {
boxed:false,
layout:'vertical',
sidebarBg:'#3e5946',
sidebarBg2:'#2d4233',
sidebarColor:'#ffffff',
sidebarAccentColor:'#ceb834',
sidebarAccentContrastColor:'#ffffff',
sidebarOpened:false,
topbarBg:'#ffffff',
topbarColor:'#4a505c',
tableHeaderbgColor: '#c7b54a',
itemsPerPage: 10 ,
tableHeaderColor: '#fff',

};

