<tc-card>
    <div class="row">
        <div class="col-sm-4 ">
            <div class="pull-left">
                <button tc-button (click)="devalider()">Dé-Valider</button>
            </div>
        </div>
        <div class="col-sm-8 ">

        </div>

    </div>

</tc-card>

<tc-card title="LISTE DES OFFRES D'ACHATS VALIDES">
<ng-container *ngIf="listOfAllDataOffreAchat && listOfAllDataOffreAchat.length>0 ">
    <span *ngIf="numberOfChecked"> {{ numberOfChecked }} éléments sélectionnés</span>

        <nz-table
            #rowSelectionTable
            nzShowPagination
            nzShowSizeChanger
            [nzPageSize]="50"
            [nzData]="listOfAllDataOffreAchat"
            (nzCurrentPageDataChange)="currentPageDataChange($event)"
        >
            <thead>
            <tr>
                <th
                        nzShowCheckbox
                        [(nzChecked)]="isAllDisplayDataChecked"
                        [nzIndeterminate]="isIndeterminate"
                        (nzCheckedChange)="checkAll($event)"
                ></th>
                <th>Date</th>
                <th>Produit</th>
                <th>Quantité</th>
                <th>Prix Unitaire</th>
                <th>Montant</th>
                <th>Contact Acheteur</th>
                <th>Réseau</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of rowSelectionTable.data">
                <td
                        nzShowCheckbox
                        [(nzChecked)]="mapOfCheckedId[data.id]"
                        (nzCheckedChange)="refreshStatus()"
                ></td>
                <td>{{data.date}}</td>
                <td>{{data.nom_produit}}</td>
                <td>{{data.quantite}} {{data.mesure}}</td>
                <td>{{data.prixUnitaire| currency:'CFA':'symbol':'3.0-0':'fr'}}</td>
                <td>{{data.montant| currency:'CFA':'symbol':'3.0-0':'fr'}}</td>
                <td> <p> <strong> {{ data.nom_auteur }} </strong></p>
                    <p> <small> {{ data.telephone }}</small> </p>
                </td>
                <td>{{data.reseau}}</td>

            </tr>
            </tbody>
</nz-table>
</ng-container>
    <tc-card>
        <div class="row">
            <div class="col-sm-4 ">
                <div class="pull-left">
                    <button tc-button (click)="devalider()">Dé-Valider</button>
                </div>
            </div>
            <div class="col-sm-8 ">

            </div>

        </div>

    </tc-card>
</tc-card>
    <tc-card>
        <div class="buttons-list">
            <button  (click)="exportByExcel()"><i class="fa fa-file-excel-o" aria-hidden="false" style="font-size:48px;color:#C7B54A">
            </i></button>
        </div>
    </tc-card>
