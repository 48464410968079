
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {SecteurActivite} from '../models/secteurActivite';
import {SecteurActiviteService} from './secteurActivite.service';


export class SecteurActiviteDatasource implements DataSource<SecteurActivite> {

    private activitesSubject = new BehaviorSubject<SecteurActivite[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<SecteurActivite[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private activiteService: SecteurActiviteService) {

    }

    loadActivites(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.activiteService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(data => this.activitesSubject.next(data)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<SecteurActivite[]> {
        return this.activitesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.activitesSubject.complete();
        this.loadingSubject.complete();
    }

}

