import * as i0 from "@angular/core";
var ColorService = /** @class */ (function () {
    function ColorService() {
    }
    ColorService.prototype.isValid = function (color) {
        if (color.startsWith('#')) {
            return /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(color);
        }
        else if (color.startsWith('rgb')) {
            return true;
        }
    };
    ColorService.prototype.hexToRgb = function (hex) {
        var RGB = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return RGB ? {
            r: parseInt(RGB[1], 16),
            g: parseInt(RGB[2], 16),
            b: parseInt(RGB[3], 16)
        } : null;
    };
    ColorService.prototype.rgbToHex = function (r, g, b) {
        return '#' + [r, g, b].map(function (x) {
            var HEX = x.toString(16);
            return HEX.length === 1 ? '0' + HEX : HEX;
        }).join('');
    };
    ColorService.prototype.hsvToRgb = function (h, s, v) {
        var r, g, b;
        var i = Math.floor(h * 6);
        var f = h * 6 - i;
        var p = v * (1 - s);
        var q = v * (1 - f * s);
        var t = v * (1 - (1 - f) * s);
        switch (i % 6) {
            case 0:
                r = v, g = t, b = p;
                break;
            case 1:
                r = q, g = v, b = p;
                break;
            case 2:
                r = p, g = v, b = t;
                break;
            case 3:
                r = p, g = q, b = v;
                break;
            case 4:
                r = t, g = p, b = v;
                break;
            case 5:
                r = v, g = p, b = q;
                break;
        }
        return [
            Math.round(r * 255),
            Math.round(g * 255),
            Math.round(b * 255)
        ];
    };
    ColorService.prototype.rgbToHsv = function (r, g, b) {
        var max = Math.max(r, g, b);
        var min = Math.min(r, g, b);
        var h;
        var d = max - min;
        var s = (max === 0 ? 0 : d / max);
        var v = max / 255;
        switch (max) {
            case min:
                h = 0;
                break;
            case r:
                h = (g - b) + d * (g < b ? 6 : 0);
                h /= 6 * d;
                break;
            case g:
                h = (b - r) + d * 2;
                h /= 6 * d;
                break;
            case b:
                h = (r - g) + d * 4;
                h /= 6 * d;
                break;
        }
        return [h, s, v];
    };
    ColorService.prototype.checkContrastColor = function (r, g, b) {
        var color;
        if ((r * 0.299 + g * 0.587 + b * 0.114) > 186) {
            color = '#000000';
        }
        else {
            color = '#ffffff';
        }
        return color;
    };
    ColorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ColorService_Factory() { return new ColorService(); }, token: ColorService, providedIn: "root" });
    return ColorService;
}());
export { ColorService };
