
<ng-container *ngIf="genres.length>0  &&  reseaux.length>0 ">
    <div class="title-block">
        <h5 class="form-title">Création du compte</h5>
    </div>
    <form  [formGroup]="userForm"  novalidate (ngSubmit)="onSubmit()">

            <div class="row">
                <div class="col-sm-6">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.mobilePhone}} </tc-form-label>
                        <tc-input type="text" [placeholder]="'mobilePhone'" formControlName="mobilePhone" >
                        </tc-input>
                        <ng-container *ngIf="userForm.get('mobilePhone').invalid && (userForm.get('mobilePhone').touched || userForm.get('mobilePhone').dirty)">
                            <span @fadeIn class="error" *ngIf="userForm.get('mobilePhone').getError('required')">Veuillez saisir votre N° Mobile</span>
                         </ng-container>
                    </tc-form-group>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.nom}} </tc-form-label>
                        <tc-input type="text" [placeholder]="'Nom '" formControlName="nom" >
                        </tc-input>
                        <ng-container *ngIf="userForm.get('nom').invalid && (userForm.get('nom').touched || userForm.get('nom').dirty)">
                            <span @fadeIn class="error" *ngIf="userForm.get('nom').getError('required')">Veuillez saisir votre nom</span>
                            <span @fadeIn class="error" *ngIf="userForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                        </ng-container>

                    </tc-form-group>
                </div>
                <div class="col-sm-6">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.prenom}}  </tc-form-label>
                        <tc-input  type="text" [placeholder]="'Prénom (s) '" formControlName="prenom">
                        </tc-input>
                        <ng-container *ngIf="userForm.get('prenom').invalid && (userForm.get('prenom').touched || userForm.get('prenom').dirty)">
                            <span @fadeIn class="error" *ngIf="userForm.get('prenom').getError('required')">Veuillez saisir votre prénom</span>
                            <span @fadeIn class="error" *ngIf="userForm.get('prenom').getError('min')">Le prénom doit avoir plus de 2 caractères</span>
                        </ng-container>
                    </tc-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">

                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.genre}}  </tc-form-label>
                        <tc-select [placeholder]="'Genre'" [options]="genres" formControlName="genre" [selected]="userForm.get('genre').value"></tc-select>

                    </tc-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.email}} </tc-form-label>
                        <tc-input [prefixIcon]="'icofont-ui-email'" type="text" formControlName="email" >
                        </tc-input>
                        <ng-container *ngIf="userForm.get('email').invalid && (userForm.get('email').touched || userForm.get('email').dirty)">
                            <span @fadeIn class="error" *ngIf="userForm.get('email').getError('email')">Vous devez saisir un email valide</span>
                       </ng-container>
                    </tc-form-group>
                </div>
            </div>

        <ng-container *ngIf="(reseauxAdapter.length>0) ">
                <div class="row">
                    <div class="col-sm-12">

                        <tc-form-group>
                            <tc-form-label>{{Columnslabels.reseau}}</tc-form-label>

                            <tc-select [options]="reseauxAdapter"  formControlName="reseau"  ></tc-select>
                            <ng-container *ngIf="userForm.get('reseau').invalid && (userForm.get('reseau').touched || userForm.get('reseau').dirty)">
                                <span @fadeIn class="error" *ngIf="userForm.get('reseau').getError('required')">Veuillez saisir votre sélectionner le réseau</span>
                            </ng-container>
                        </tc-form-group>
                    </div>
                </div>

        </ng-container>
                 <span *ngIf="!userForm.valid && userForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                    <button tc-button type="submit" [block]="true" >
                        Valider
                    </button>

    </form>
</ng-container>
