import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import {Store} from '@ngrx/store';
import {BasePageComponent} from '../../base-page';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {DashboardService} from '../dashboard/dashboard.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {jqxDataTableComponent} from 'jqwidgets-ng/jqxdatatable';
import {ValiderPrixService} from '../../ui/valider-prix/validerPrix.service';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';
import {IOption} from '../../../ui/interfaces/option';
import {UserService} from '../../ui/administrateur/user.service';
import {ReseauService} from '../../ui/reseau/reseau.service';
import {MesureService} from '../../ui/mesure/mesure.service';
import {ValiderStockService} from '../../ui/valider-stock/validerStock.service';
import DataTableExportSettings = jqwidgets.DataTableExportSettings;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-rapports-stock',
  templateUrl: './rapportsStock.component.html',
// styleUrls: ['./monitorAlerte.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageRapportsStockComponent extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit {
  produits: any[] = [];
  depots: any[] = [];
  mesures: any[] = [];
  produitsAdapter: IOption[] = [];
  depotsAdapter: IOption[] = [];
  reseaux: any[] = [];
  reseauxAdapter: IOption[] = [];
  mesuresAdapter: IOption[] = [];
  rapportsForm: FormGroup;
  constructor(
    store: Store<IAppState>, private dashboardService: DashboardService,
    httpSv: HttpService, private validerStockService: ValiderStockService,
    private reseauService: ReseauService, private mesureService: MesureService,
     private adminService: UserService, private fb: FormBuilder
  ) {
    super(store, httpSv);
    this.pageData = {
      title: 'RAPPORT SUR LES STOCKS',
      breadcrumbs: [
      ],
    };
  }
  @ViewChild('myDataTable', { static: false }) myDataTable: jqxDataTableComponent;
  @ViewChild('dateDebut', { static: false }) dateDebut: jqxDateTimeInputComponent;
  @ViewChild('dateFin', { static: false }) dateFin: jqxDateTimeInputComponent;
  @ViewChild('log', { static: false }) log; ElementRef;
  Columnslabels =
      {
        dateDebut: 'Date début',
        dateFin: 'Date Fin'
      };
  source: any =
      { localData: []
      };
  dataAdapter: any;
  localizationObject = this.getLocalization();
  exportSettings = this.getExportSettings();
  columns: any[] =
      [
        { text: 'Réseau', dataField: 'reseau', width: '10%', cellsAlign: 'left', align: 'left' },
        { text: 'Dépot', dataField: 'nom_depot', width: '20%', cellsAlign: 'left', align: 'left'},
        { text: 'Produit', dataField: 'nom_produit', width: '30%', cellsAlign: 'left', align: 'left' },
        { text: 'Date', dataField: 'date', width: '12%', align: 'left', cellsAlign: 'left', cellsFormat: 'd' },
          // tslint:disable-next-line:max-line-length
        { text: 'Stock', dataField: 'stock', align: 'center', cellsAlign: 'center', cellsFormat: 'd', width: '18%'  },
        { text: 'Mesure', dataField: 'mesure', align: 'center', cellsAlign: 'center', width: '10%'  }
      ];
    getExportSettings(): DataTableExportSettings {
        const exportObj: DataTableExportSettings  = {};
        exportObj.columnsHeader = true;
        exportObj.hiddenColumns = false;
        exportObj.serverURL = null;
        exportObj.characterSet = null;
        exportObj.recordsInView = true;
        exportObj.fileName = 'rapport_stock';
        return exportObj;
    }
  getLocalization(): any {
    const localizationobj: any = {};
    localizationobj.pagerGoToPageString = 'Aller à la page:';
    localizationobj.pagerShowRowsString = 'Voir ligne:';
    localizationobj.pagerRangeString = ' de ';
    localizationobj.pagerNextButtonString = 'Suivant';
    localizationobj.pagerFirstButtonString = 'Premier';
    localizationobj.pagerLastButtonString = 'Dernier';
    localizationobj.pagerPreviousButtonString = 'Précedent';
    localizationobj.sortAscendingString = 'Ordre Ascendent';
    localizationobj.sortDescendingString = 'Ordre Descendant';
    localizationobj.sortRemoveString = 'Supprimer';
    localizationobj.emptydatastring = 'Pas de données';
    localizationobj.firstDay = 1;
    localizationobj.percentSymbol = '%';
    localizationobj.currencySymbol = '';
    localizationobj.currencySymbolPosition = 'après';
    localizationobj.decimalSeparator = ',';
    localizationobj.thousandsSeparator = ' ';
    localizationobj.filterapplystring = 'Appliquer';
    localizationobj.filtercancelstring = 'Annuler';
    localizationobj.filterclearstring = 'Effacer Filtre';
    localizationobj.filterstring =  'Avancé';
    localizationobj.filtersearchstring = 'Chercher';
    localizationobj.filterstringcomparisonoperators = ['vide', 'non vide', 'contient', 'contient(respecte la casse)',
          'Ne contient pas', 'Ne contient pas(respecte la casse)', 'commence par', 'commence par(respecte la casse)',
          'finit par', 'finit par(respecte la casse)', 'égale', 'égale(respecte la casse)', 'null', 'non null'];
      // tslint:disable-next-line:max-line-length
    localizationobj.filternumericcomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
      // tslint:disable-next-line:max-line-length
    localizationobj.filterdatecomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
    localizationobj.filterbooleancomparisonoperators = ['égale', 'non égale'];
    localizationobj.validationstring = 'La valeur entrée n\'est pas valide ';
    localizationobj.emptydatastring =  'Pas de données à afficher';
    localizationobj.filterselectstring =  'Selectionnez un filtre';
    localizationobj.loadtext = 'Chargement...';
    localizationobj.clearstring = 'Effacer';
    localizationobj.todaystring = 'Aujourd\'hui';
      // tslint:disable-next-line:max-line-length
    localizationobj.loadingerrormessage = 'Chargement en cours des données vous ne pouvez pas mettre à jour les propriétés ou appeler une méthode . Vous pourrez le faire après le chargement des données.';
    localizationobj.days = {
      // full day names
      names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      // abbreviated day names
      namesAbbr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      // shortest day names
      namesShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
    };

    localizationobj.patterns = {
      d: 'dd/MM/yyyy',
      D: 'dddd, d. MMMM yyyy',
      t: 'HH:mm',
      T: 'HH:mm:ss',
      f: 'dddd, d. MMMM yyyy HH:mm',
      F: 'dddd, d. MMMM yyyy HH:mm:ss',
      M: 'dd MMMM',
      Y: 'MMMM yyyy'
    };
    localizationobj.months = {
      // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
      names: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre', ''],
      // abbreviated month names
      namesAbbr: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
    };
    return localizationobj;
  }
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
        return '95%';
    }

    return 850;
  }
  excelExport(): void {
    this.myDataTable.exportData('xls');
  }  xmlExport(): void {
    this.myDataTable.exportData('xml');
  }  csvExport(): void {
    this.myDataTable.exportData('csv');
  }  tsvExport(): void {
    this.myDataTable.exportData('tsv');
  }  htmlExport(): void {
    this.myDataTable.exportData('html');
  }  jsonExport(): void {
    this.myDataTable.exportData('json');
  }  pdfExport(): void {
    this.myDataTable.exportData('pdf');
  }
  printBtnOnClick(): void {
    const gridContent = this.myDataTable.exportData('html');
    const newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>ETAT DES PRIX</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
    document.write(pageContent);
    document.close();
    newWindow.print();
  }
  onReseauxPromise() {
    this.reseauxAdapter = [];
    for (const reseau of this.reseaux) {
      this.reseauxAdapter.push({'value' : reseau.id.toString(), 'label' : reseau.code});
    }
  }
    onMesuresPromise() {
        this.mesuresAdapter = [];
        for (const mesure of this.mesures) {
            this.mesuresAdapter.push({'value' : mesure.id.toString(), 'label' : mesure.code});
        }
    }
  ngOnInit() {
    super.ngOnInit();

      this.adminService.mesProduits().subscribe(produits => {
      this.produits = produits ;
      this.produitsAdapter = [];
      for (const produit of this.produits) {
        this.produitsAdapter.push({'value' : produit.id.toString(), 'label' : produit.nom});
      }
    });
    this.reseauService.getCached().subscribe(data => {
          this.reseaux = data;
          this.onReseauxPromise();
        }
    );
      this.mesureService.getCached().subscribe(data => {
              this.mesures = data;
              this.onMesuresPromise();
          }
      );
    this.adminService.mesMarchesPreferes().subscribe(depots => {
      this.depots = depots ;
      this.depotsAdapter = [];
      for (const depot of this.depots) {
        this.depotsAdapter.push({'value' : depot.id.toString(), 'label' : depot.nom});
      }
    });
      this.initReactiveForm();

      this.setLoaded();

  }
  initReactiveForm() {
    this.rapportsForm = this.fb.group({
          produits: [''],
          depots: [''],
          mesures: [''],
          reseau: '',
          typePrix: ''
        }
    ) ;
  }
  ngAfterViewInit(): void {
    this.dateDebut.culture('fr-FR');
    this.dateFin.culture('fr-FR');
    const pdateDebut = new Date();
    pdateDebut.setFullYear(pdateDebut.getFullYear(), 0, 1);
    const pdateFin = new Date();
    pdateFin.setFullYear(pdateFin.getFullYear(), pdateFin.getMonth() , pdateFin.getDate() - 1);
    this.dateDebut.setDate(pdateDebut);
    this.dateFin.setDate(pdateFin);
      this.dateFin.onValueChanged.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined) {
                  this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value,
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value
                      ,  this.rapportsForm.get('mesures').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.dateDebut.onValueChanged.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined) {
                  this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value,
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value
                      , this.rapportsForm.get('mesures').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.rapportsForm.get('reseau').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value,
                      // tslint:disable-next-line:max-line-length
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value,
                      this.rapportsForm.get('mesures').value
                  ).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.rapportsForm.get('depots').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value,
                      // tslint:disable-next-line:max-line-length
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value,
                      this.rapportsForm.get('mesures').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });

      this.rapportsForm.get('produits').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value,
                      // tslint:disable-next-line:max-line-length
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value,
                      this.rapportsForm.get('mesures').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.rapportsForm.get('mesures').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value,
                      // tslint:disable-next-line:max-line-length
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value,
                      this.rapportsForm.get('mesures').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.rapportsForm.get('typePrix').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value,
                      // tslint:disable-next-line:max-line-length
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value,
                      this.rapportsForm.get('mesures').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.validerStockService.getStocksValides(this.dateDebut.getDate().toLocaleDateString(),
          this.dateFin.getDate().toLocaleDateString(), this.rapportsForm.get('depots').value,
          this.rapportsForm.get('produits').value, this.rapportsForm.get('reseau').value
          ,  this.rapportsForm.get('mesures').value).subscribe(datas => {
              this.source.localData = datas;
              this.dataAdapter = new jqx.dataAdapter(this.source);
          }
      );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }



}
