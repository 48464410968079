import { OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { HttpService } from '../../../services/http/http.service';
import * as SettingsActions from '../../../store/actions/app-settings.actions';
import * as PageActions from '../../../store/actions/page.actions';
var MenuComponent = /** @class */ (function () {
    function MenuComponent(httpSv, store, router, elementRef) {
        var _this = this;
        this.httpSv = httpSv;
        this.store = store;
        this.router = router;
        this.elementRef = elementRef;
        this.caret = 'icofont-rounded-right';
        this.orientation = 'vertical';
        this.store.pipe(select('appSettings')).subscribe(function (settings) {
            _this.settings = settings;
            _this.elementRef.nativeElement.style.cssText = "\n        --accent: " + _this.settings.sidebarAccentColor + ";\n        --accent-contrast: " + _this.settings.sidebarAccentContrastColor + ";\n        --sub-menu-bg: " + _this.settings.sidebarBg + ";\n      ";
        });
    }
    Object.defineProperty(MenuComponent.prototype, "horizontal", {
        get: function () {
            return this.orientation === 'horizontal';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuComponent.prototype, "vertical", {
        get: function () {
            return this.orientation === 'vertical';
        },
        enumerable: true,
        configurable: true
    });
    MenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getMenuData(this.src);
        this.routerSubscription = this.router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })).subscribe(function () { return _this.setActiveItem(); });
    };
    MenuComponent.prototype.ngOnDestroy = function () {
        this.routerSubscription.unsubscribe();
    };
    MenuComponent.prototype.getMenuData = function (url) {
        var _this = this;
        this.httpSv.getData(url).subscribe(function (data) {
            _this.menuItems = data;
            _this.setActiveItem();
        }, function (err) {
            console.log(err);
        });
    };
    MenuComponent.prototype.toggle = function (event, item, el) {
        event.preventDefault();
        var ITEMS = el.menuItems;
        if (item.active) {
            item.active = false;
        }
        else {
            for (var i = 0; i < ITEMS.length; i++) {
                ITEMS[i].active = false;
            }
            item.active = true;
        }
        this.changeRoute(item.routing, !item.sub && !this.isActive([this.orientation, item.routing]), item.layout ? item.layout : this.orientation);
    };
    MenuComponent.prototype.setActiveItem = function () {
        var _this = this;
        if (this.menuItems !== undefined) {
            this.menuItems.filter(function (item) { return item.sub; }).forEach(function (item) {
                item.sub.forEach(function (subItem) {
                    if (_this.isActive([_this.orientation, subItem.routing])) {
                        _this.closeAll();
                        item.active = true;
                        return;
                    }
                });
            });
        }
    };
    MenuComponent.prototype.subState = function (item, rla) {
        return item.active || rla ? 'active' : 'inactive';
    };
    MenuComponent.prototype.closeAll = function () {
        var _this = this;
        this.menuItems.forEach(function (item) {
            item.active = false;
            _this.closeSub(item);
        });
    };
    MenuComponent.prototype.closeSub = function (item) {
        if (item.sub && item.sub.length) {
            item.sub.forEach(function (subItem) {
                subItem.active = false;
            });
        }
    };
    MenuComponent.prototype.closeSidebar = function () {
        this.store.dispatch(new SettingsActions.SidebarState(false));
    };
    MenuComponent.prototype.getLayout = function () {
        var url = this.router.url.split('/').filter(function (n) { return n; });
        return url[0];
    };
    // change route
    MenuComponent.prototype.changeRoute = function (routing, bool, layout) {
        var _this = this;
        if (bool === void 0) { bool = true; }
        if (layout === void 0) { layout = this.orientation; }
        if (bool) {
            this.store.dispatch(new PageActions.Reset());
            setTimeout(function () {
                _this.router.navigate(["./" + layout, routing]);
            }, 0);
        }
    };
    MenuComponent.prototype.isActive = function (instruction) {
        return this.router.isActive(this.router.createUrlTree(instruction), true);
    };
    MenuComponent.prototype.getAccentColor = function (item) {
        var IS_ACTIVE = item.active || this.isActive([this.orientation, item.routing]);
        return IS_ACTIVE ? { color: this.settings.sidebarAccentColor } : {};
    };
    MenuComponent.prototype.getItemColor = function (item, show) {
        if (show === void 0) { show = false; }
        var IS_ACTIVE = item.hovered || item.active || this.isActive([this.orientation, item.routing]);
        if (!IS_ACTIVE || show) {
            return { color: this.navColor };
        }
        return { color: this.accentColor };
    };
    MenuComponent.prototype.getAccentBg = function (active) {
        if (active === void 0) { active = false; }
        if (!active) {
            return null;
        }
        return {
            'background-color': this.accentColor,
        };
    };
    MenuComponent.prototype.setAccentColor = function () {
    };
    return MenuComponent;
}());
export { MenuComponent };
