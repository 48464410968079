/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./logo.component";
var styles_LogoComponent = [i0.styles];
var RenderType_LogoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
export function View_LogoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "logo-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "logo-img"]], [[8, "src", 4], [8, "alt", 0], [8, "width", 0], [8, "height", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.src, ""); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.alt, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.width, ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", _co.height, ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
export function View_LogoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "logo", [], [[2, "logo", null]], null, null, View_LogoComponent_0, RenderType_LogoComponent)), i1.ɵdid(1, 114688, null, 0, i2.LogoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
var LogoComponentNgFactory = i1.ɵccf("logo", i2.LogoComponent, View_LogoComponent_Host_0, { src: "src", width: "width", height: "height", alt: "alt" }, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
