var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as frDateLocale from 'date-fns/locale/fr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../administrateur/user.service';
import { GenreService } from '../../../services/sharedServices/genre.service';
import { ActiviteService } from '../activite/activite.service';
import { CommuneService } from '../commune/commune.service';
import { ReseauService } from '../reseau/reseau.service';
import { GroupeService } from '../groupe/groupe.service';
import { Acteur } from '../models/acteur';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { HttpClient } from '@angular/common/http';
import { MarcheService } from '../marche/marche.service';
import { ProduitService } from '../produit/produit.service';
import { DepotService } from '../depot/depot.service';
var PageChangePasswordComponent = /** @class */ (function (_super) {
    __extends(PageChangePasswordComponent, _super);
    function PageChangePasswordComponent(store, httpSv, route, router, toastr, genreService, activiteService, communeService, i18n, reseauService, marcheService, depotService, fb, groupeService, produitService, adminService, auth, httpClient) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.genreService = genreService;
        _this.activiteService = activiteService;
        _this.communeService = communeService;
        _this.i18n = i18n;
        _this.reseauService = reseauService;
        _this.marcheService = marcheService;
        _this.depotService = depotService;
        _this.fb = fb;
        _this.groupeService = groupeService;
        _this.produitService = produitService;
        _this.adminService = adminService;
        _this.auth = auth;
        _this.httpClient = httpClient;
        _this.SERVER_URL = 'api/upload_avatar';
        _this.actualPwdKeyUp = new Subject();
        _this.Columnslabels = {
            newPassword: 'Nouveau mot de passe',
            confirmpassword: 'Confirmer le Mot de passe'
        };
        _this.administrateur = {};
        _this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        _this.i18n.setDateLocale(frDateLocale);
        _this.initReactiveForm();
        return _this;
    }
    PageChangePasswordComponent.getAdministrateur = function (jsonValue) {
        return Acteur.fromJSON(jsonValue);
    };
    PageChangePasswordComponent.prototype.MatchPassword = function (ac) {
        var pass = ac.get('password').value;
        var confirmPass = ac.get('confirmpassword').value;
        if (pass !== confirmPass) {
            ac.get('confirmpassword').setErrors({ notMatch: true });
        }
        return null;
    };
    PageChangePasswordComponent.prototype.getTitle = function () {
        return 'Compte : ' + this.getNomComplet();
    };
    PageChangePasswordComponent.prototype.getNomComplet = function () {
        return this.administrateur.nom + ' ' + this.administrateur.prenom;
    };
    PageChangePasswordComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
        this.pageData.title = this.getTitle();
        this.userType = this.administrateur.role;
    };
    PageChangePasswordComponent.prototype.initReactiveForm = function () {
        this.changePwdForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmpassword: ['', [Validators.required, Validators.minLength(8)]]
        }, { validators: this.MatchPassword });
    };
    PageChangePasswordComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.auth.currentUserDataObject === null) {
            this.router.navigate(['/vertical/dashboard']);
        }
        else {
            this.selectedId = this.auth.currentUserDataObject.id;
            this.getDataByName(this.adminService.getURL(this.selectedId), 'administrateur', 'acteur', 'setLoaded');
        }
    };
    PageChangePasswordComponent.prototype.ngAfterViewInit = function () {
    };
    PageChangePasswordComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    PageChangePasswordComponent.prototype.ajouter = function () {
        this.router.navigate(['/vertical/create-administrateur']);
    };
    PageChangePasswordComponent.prototype.liste = function () {
        this.router.navigate(['/vertical/list-administrateur']);
    };
    PageChangePasswordComponent.prototype.changePassword = function () {
        var _this = this;
        if (this.changePwdForm.valid) {
            this.adminService.changePassword(this.changePwdForm.value.password).subscribe(function (resultat) {
                if (resultat.status === 'OK') {
                    _this.toastr.success('Succès du changement du mot de passe ', '', { timeOut: 2000 });
                    _this.router.navigate(['']);
                }
                else {
                    _this.toastr.error('Echec du changement du mot de passe ', '', { timeOut: 2000 });
                }
            });
        }
        else {
            if (!this.changePwdForm.get('actualPassword').valid) {
                this.toastr.error('Le mot de passe actuel doit être renseigné et valide ', '', { timeOut: 2000 });
            }
        }
    };
    PageChangePasswordComponent.prototype.onSubmit = function () {
        this.changePassword();
    };
    return PageChangePasswordComponent;
}(BasePageComponent));
export { PageChangePasswordComponent };
