import * as i0 from "@angular/core";
var PeriodiciteService = /** @class */ (function () {
    function PeriodiciteService() {
        this.baseUrl = '/api/periodicite_marche';
    }
    PeriodiciteService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    PeriodiciteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PeriodiciteService_Factory() { return new PeriodiciteService(); }, token: PeriodiciteService, providedIn: "root" });
    return PeriodiciteService;
}());
export { PeriodiciteService };
