import { OnInit, ElementRef, AfterViewInit, EventEmitter, Renderer2 } from '@angular/core';
import { ColorService } from '../../../../services/color/color.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
export var pickerApear = trigger('pickerApear', [
    state('open', style({
        height: '100%',
        width: '100%',
        borderRadius: 0
    })),
    state('close', style({
        height: '20px',
        width: '20px',
        borderRadius: '50%'
    })),
    transition('open => close', animate('500ms ease-in-out')),
    transition('close => open', animate('600ms ease-in-out')),
]);
export var foreground = trigger('foreground', [
    state('open', style({
        zIndex: 3,
    })),
    state('close', style({
        zIndex: 3
    })),
    transition('open => close', animate('500ms ease-in-out')),
    transition('close => open', animate('100ms 500ms ease-in-out')),
]);
var TCPickerComponent = /** @class */ (function () {
    function TCPickerComponent(colorSv, element, renderer) {
        this.colorSv = colorSv;
        this.element = element;
        this.renderer = renderer;
        this.savedColor = '#0a48fd';
        this.hueHandlerColor = this.savedColor;
        this.newColor = this.savedColor;
        this.selectedHue = this.savedColor;
        this.S = 1;
        this.V = 1;
        this.circleRadius = 1;
        this.transparencyPosition = 1;
        this.alphaChanel = true;
        this.buttons = false;
        this.showPalette = true;
        this.opened = false;
        this.openPicker = false;
        this.pickerOpened = new EventEmitter();
        this.pickerClosed = new EventEmitter();
        this.colorSelected = new EventEmitter();
    }
    Object.defineProperty(TCPickerComponent.prototype, "getState", {
        get: function () {
            return !this.openPicker;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCPickerComponent.prototype, "getOpenState", {
        get: function () {
            return this.openPicker;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCPickerComponent.prototype, "getGradientState", {
        get: function () {
            return this.gradient;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCPickerComponent.prototype, "getClassOpened", {
        get: function () {
            return this.opened;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCPickerComponent.prototype, "getTopOffset", {
        get: function () {
            return this.top + 'px';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCPickerComponent.prototype, "getLeftOffset", {
        get: function () {
            return this.left + 'px';
        },
        enumerable: true,
        configurable: true
    });
    TCPickerComponent.prototype.ngOnInit = function () {
        this.newColor = this.savedColor;
        this.getHueOfColor(this.savedColor);
        this.pickerSize = this.pickerElement.nativeElement.offsetHeight;
    };
    TCPickerComponent.prototype.ngAfterViewInit = function () {
        if (this.transparencyElement) {
            this.transparency = this.transparencyElement.nativeElement;
        }
        this.hue = this.hueElement.nativeElement;
        this.color = this.colorElement.nativeElement;
        if (typeof this.width === 'number') {
            this.width = this.width + 'px';
        }
        this.renderer.setStyle(this.element.nativeElement, 'width', this.width);
        this.renderer.setStyle(this.colorsElement.nativeElement, 'height', (this.element.nativeElement.offsetWidth - 38) + 'px');
        this.pickerSize = this.pickerElement.nativeElement.offsetHeight;
        this.renderer.setStyle(this.element.nativeElement, 'height', this.pickerSize + 'px');
    };
    Object.defineProperty(TCPickerComponent.prototype, "NewColor", {
        get: function () {
            return this.newColor;
        },
        set: function (color) {
            if (this.colorSv.isValid(color)) {
                if (color.startsWith('#') && color.length === 7) {
                    var temp = this.colorSv.hexToRgb(color);
                    color = "rgb(" + temp.r + "," + temp.g + "," + temp.b + ")";
                }
                if (color.startsWith('rgb') || color.startsWith('rgba')) {
                    var newColor = color.replace('rgba(', '').replace('rgb(', '');
                    var colorArr_1 = new Array();
                    newColor.split(',').forEach(function (x) {
                        colorArr_1.push(parseFloat(x));
                    });
                    this.contrastColor = this.colorSv.checkContrastColor(colorArr_1[0], colorArr_1[1], colorArr_1[2]);
                    if (this.transparencyPosition < 1) {
                        this.newColor = "rgba(" + colorArr_1[0] + "," + colorArr_1[1] + "," + colorArr_1[2] + "," + this.transparencyPosition.toPrecision(2) + ")";
                    }
                    else if (colorArr_1.length === 4) {
                        this.transparencyPosition = parseFloat(colorArr_1[3].toPrecision(2));
                        this.newColor = "rgba(" + colorArr_1[0] + "," + colorArr_1[1] + "," + colorArr_1[2] + "," + colorArr_1[3].toPrecision(2) + ")";
                    }
                    else {
                        this.newColor = this.colorSv.rgbToHex(colorArr_1[0], colorArr_1[1], colorArr_1[2]);
                    }
                    this.saveColor();
                    colorArr_1 = this.colorSv.rgbToHsv(colorArr_1[0], colorArr_1[1], colorArr_1[2]);
                    this.S = colorArr_1[1];
                    this.V = colorArr_1[2];
                    this.huePosition = colorArr_1[0];
                    var hueColor = this.colorSv.hsvToRgb(this.huePosition, 1, 1);
                    this.selectedHue = 'rgb(' + hueColor[0] + ',' + hueColor[1] + ',' + hueColor[2] + ')';
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    TCPickerComponent.prototype.onMouseClick = function (event) {
        this.clicked = true;
        if (this.color.contains(event.target)) {
            this.calculateColor(event);
        }
        else if (this.hue.contains(event.target)) {
            this.changeHue(event);
        }
        else if (this.transparency && this.transparency.contains(event.target)) {
            this.changeTransparency(event);
        }
    };
    TCPickerComponent.prototype.onMouseMove = function (event) {
        if (this.clicked) {
            if (this.changingAlpha) {
                this.changeTransparency(event);
            }
            else if (this.changingColor) {
                this.calculateColor(event);
            }
            else if (this.changeHue) {
                this.changeHue(event);
            }
        }
    };
    TCPickerComponent.prototype.onMouseRelease = function () {
        this.clicked = false;
        this.changingAlpha = false;
        this.changingColor = false;
        this.changingHue = false;
    };
    TCPickerComponent.prototype.calculateColor = function (event) {
        this.changingColor = true;
        this.mouseX = event.pageX - this.color.getBoundingClientRect().left;
        this.mouseY = event.pageY - this.color.getBoundingClientRect().top - document.documentElement.scrollTop || window.pageYOffset;
        this.S = (Math.min(100, Math.max(0, Math.round((this.mouseX * 100) / this.color.offsetWidth)))) / 100;
        this.V = (100 - Math.min(100, Math.max(0, Math.round((this.mouseY * 100) / this.color.offsetHeight)))) / 100;
        var rgbColor = this.colorSv.hsvToRgb(this.huePosition, this.S, this.V);
        this.contrastColor = this.colorSv.checkContrastColor(rgbColor[0], rgbColor[1], rgbColor[2]);
        if (this.transparencyPosition > 0.99) {
            this.newColor = this.colorSv.rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);
        }
        else {
            this.newColor = "rgba(" + rgbColor[0] + "," + rgbColor[1] + "," + rgbColor[2] + "," + this.transparencyPosition.toPrecision(2) + ")";
        }
        this.saveColor();
    };
    TCPickerComponent.prototype.changeHue = function (event) {
        this.changingHue = true;
        var handlerPosition = (event.pageY - this.hue.getBoundingClientRect().top -
            (document.documentElement.scrollTop || window.pageYOffset)) / (this.hue.offsetHeight);
        if (handlerPosition >= 0 && handlerPosition <= 1) {
            this.huePosition = handlerPosition;
            var color = this.colorSv.hsvToRgb(this.huePosition, 1, 1);
            this.contrastColor = this.colorSv.checkContrastColor(color[0], color[1], color[2]);
            this.selectedHue = 'rgb(' + color[0] + ',' + color[1] + ',' + color[2] + ')';
            var newColor = this.colorSv.hsvToRgb(this.huePosition, this.S, this.V);
            // this.NewColor = 'rgb(' + newColor[0] + ',' + newColor[1] + ',' + newColor[2] + ')';
            this.NewColor = this.colorSv.rgbToHex(newColor[0], newColor[1], newColor[2]);
        }
    };
    TCPickerComponent.prototype.changeTransparency = function (event) {
        this.changingAlpha = true;
        var handlerPosition = (event.pageX - this.transparency.getBoundingClientRect().left) / (this.transparency.offsetWidth);
        if (handlerPosition >= 0 && handlerPosition <= 1) {
            this.transparencyPosition = handlerPosition;
            if (this.NewColor.startsWith('rgb')) {
                this.changeAlpha(this.NewColor, this.transparencyPosition);
            }
            else if (this.NewColor.startsWith('#')) {
                var color = this.colorSv.hexToRgb(this.NewColor);
                this.changeAlpha("rgb(" + color.r + ", " + color.g + ", " + color.b + ")", this.transparencyPosition);
            }
        }
    };
    TCPickerComponent.prototype.changeAlpha = function (color, alpha) {
        if (color.startsWith('rgba(')) {
            this.NewColor = color.replace(/[\d\.]+\)$/g, alpha.toPrecision(2) + ")");
        }
        else {
            this.NewColor = color.replace('rgb(', 'rgba(').replace(')', "," + alpha.toPrecision(2) + ")");
        }
        if (alpha > 0.99) {
            this.transparencyPosition = 1;
            var rgbColor = color
                .replace('rgba(', '')
                .replace('rgb(', '')
                .replace(')', '')
                .split(',')
                .map(function (x) {
                return parseInt(x, 10);
            });
            // this.NewColor = `rgb(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]})`;
            this.NewColor = this.colorSv.rgbToHex(rgbColor[0], rgbColor[1], rgbColor[2]);
        }
    };
    TCPickerComponent.prototype.changeSchema = function () {
        if (this.newColor.startsWith('rgb')) {
            var color = this.newColor
                .replace('rgb(' || ')', '')
                .split(',')
                .map(function (x) {
                return parseInt(x, 10);
            });
            this.newColor = this.colorSv.rgbToHex(color[0], color[1], color[2]);
        }
        else if (this.newColor.startsWith('#')) {
            var color = this.colorSv.hexToRgb(this.newColor);
            this.NewColor = "rgb(" + color.r + "," + color.g + "," + color.b + ")";
        }
    };
    TCPickerComponent.prototype.getHueOfColor = function (color) {
        var hsvColor;
        var colorArr;
        if (!color) {
        }
        if (color.startsWith('#')) {
            var temp = this.colorSv.hexToRgb(color);
            colorArr = [temp.r, temp.g, temp.b];
        }
        else if (color.startsWith('rgb')) {
            colorArr = color
                .replace('rgb(', '')
                .split(',')
                .map(function (x) {
                return parseInt(x, 10);
            });
        }
        hsvColor = this.colorSv.rgbToHsv(colorArr[0], colorArr[1], colorArr[2]);
        this.huePosition = hsvColor[0];
        var templColor = this.colorSv.hsvToRgb(hsvColor[0], 1, 1);
        this.selectedHue = "rgb(" + templColor[0] + "," + templColor[1] + "," + templColor[2] + ")";
    };
    TCPickerComponent.prototype.appendToBody = function () {
        if (!this.opened) {
            document.body.appendChild(this.element.nativeElement);
        }
    };
    TCPickerComponent.prototype.removeFromBody = function () {
        this.element.nativeElement.remove();
    };
    TCPickerComponent.prototype.show = function () {
        this.openPicker = true;
        this.getHueOfColor(this.savedColor);
        this.pickerOpened.emit(this.savedColor);
    };
    TCPickerComponent.prototype.hide = function () {
        if (!this.opened) {
            if (this.gradient) {
                this.left += 13;
            }
            this.openPicker = !this.openPicker;
            this.savedColor = this.NewColor;
            this.pickerClosed.emit(this.NewColor);
        }
    };
    TCPickerComponent.prototype.cancel = function () {
        this.pickerClosed.emit(this.savedColor);
        this.openPicker = false;
    };
    TCPickerComponent.prototype.saveColor = function () {
        if (!this.buttons) {
            this.colorSelected.emit({ color: this.NewColor, contrast: this.contrastColor });
        }
    };
    TCPickerComponent.prototype.save = function () {
        this.colorSelected.emit({ color: this.NewColor, contrast: this.contrastColor });
        this.hide();
    };
    TCPickerComponent.prototype.setColor = function (color) {
        this.transparencyPosition = 1;
        this.NewColor = color;
        this.savedColor = color;
    };
    TCPickerComponent.prototype.setPosition = function (top, left) {
        this.top = top;
        this.left = left;
    };
    TCPickerComponent.prototype.applyColor = function (color) {
        if (color.startsWith('#') && color.length === 4) {
            var r = color.charAt(1).toString();
            var g = color.charAt(2).toString();
            var b = color.charAt(3).toString();
            this.transparencyPosition = 1;
            this.NewColor = "#" + r + r + g + g + b + b;
        }
    };
    TCPickerComponent.prototype.getPickerStyles = function () {
        return {
            'left': this.left + 'px',
            'top': this.top + 'px',
            'max-height': this.pickerSize + 'px'
        };
    };
    TCPickerComponent.prototype.getCircleStyle = function () {
        return {
            'left': Math.max(Math.round(this.S * 100) - this.circleRadius, -this.circleRadius) + '%',
            'top': Math.max(100 - Math.round(this.V * 100) - this.circleRadius, -this.circleRadius) + '%',
            'border-color': this.contrastColor
        };
    };
    TCPickerComponent.prototype.paletteItemClass = function (item) {
        return {
            'active': item === this.NewColor,
            'black': this.contrastColor === '#000'
        };
    };
    TCPickerComponent.prototype.getPickerState = function () {
        return this.openPicker ? 'open' : 'close';
    };
    TCPickerComponent.prototype.getAnimationClass = function () {
        return {
            'close': !this.openPicker,
            'open': this.openPicker
        };
    };
    return TCPickerComponent;
}());
export { TCPickerComponent };
