import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SMSService = /** @class */ (function () {
    function SMSService(http) {
        this.http = http;
        this.baseUrl = '/api/sms';
    }
    // tslint:disable-next-line:max-line-length
    SMSService.prototype.getJournalSMS = function (dateDebut, dateFin, operateur, alerte, reseau, destination) {
        return this.http.get(this.baseUrl + "/journal", {
            params: new HttpParams()
                .set('dateDebut', dateDebut)
                .set('dateFin', dateFin)
                .set('operateur', operateur)
                .set('alerte', alerte)
                .set('reseau', reseau)
                .set('destination', destination)
        });
    };
    SMSService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SMSService_Factory() { return new SMSService(i0.ɵɵinject(i1.HttpClient)); }, token: SMSService, providedIn: "root" });
    return SMSService;
}());
export { SMSService };
