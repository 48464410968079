<tc-card>
    <div class="row">
        <div class="col-sm-4 ">
            <div class="pull-left">
                <button tc-button (click)="devalider()">Dé-Valider</button>
            </div>
        </div>
        <div class="col-sm-8 ">

        </div>

    </div>

</tc-card>

<tc-card title="LISTE DES ETALONNAGES VALIDES">
    <ng-container *ngIf="listOfAllDataEtalonnage.length>0 ">
    <span *ngIf="numberOfChecked"> {{ numberOfChecked }} éléments sélectionnés</span>

    <nz-table
        #rowSelectionTable
        nzShowPagination
        nzShowSizeChanger
        [nzPageSize]="50"
        [nzData]="listOfAllDataEtalonnage"
        (nzCurrentPageDataChange)="currentPageDataChange($event)"
    >
        <thead>
        <tr>
            <th
                    nzShowCheckbox
                    [(nzChecked)]="isAllDisplayDataChecked"
                    [nzIndeterminate]="isIndeterminate"
                    (nzCheckedChange)="checkAll($event)"
            ></th>
            <th>Marché</th>
            <th>Unité de Mesure Locale (UML)</th>
            <th>Unité de mesure Légale (UL)</th>
            <th>Equivalence</th>
        </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-data [ngForOf]="rowSelectionTable.data">
            <tr>
                <td     nzShowCheckbox
                        [(nzChecked)]="mapOfCheckedId[data.id]"
                        (nzCheckedChange)="refreshStatus()"
                ></td>
                <td>{{ data.marche }}</td>
                <td>{{ data.uml }}</td>
                <td>{{ data.ul }}</td>
                <td>{{ data.valeur }}</td>
            </tr>
        </ng-template>
        </tbody>
    </nz-table>
    </ng-container>
</tc-card>
<tc-card>
    <div class="row">
        <div class="col-sm-4 ">
            <div class="pull-left">
                <button tc-button (click)="devalider()">Dé-Valider</button>
            </div>
        </div>
        <div class="col-sm-8 ">

        </div>

    </div>

</tc-card>
<tc-card>
    <div class="buttons-list">
        <button  (click)="exportByExcel()"><i class="fa fa-file-excel-o" aria-hidden="false" style="font-size:48px;color:#C7B54A">
        </i></button>
    </div>
</tc-card>
