<ng-container *ngIf="groupe && reseauxAdapter.length>0 ">
    <div class="col-sm-9">
<form [formGroup]="groupeForm"   (ngSubmit)="onSubmit()">
<!--<div class="content-body">-->
 <tc-card [title]="'IDENTIFICATION DU GROUPE'">
        <div class="row">
            <div class="col-sm-12">

                <tc-form-group>
                    <tc-form-label>{{Columnslabels.reseau}}</tc-form-label>

                    <tc-select [options]="reseauxAdapter" [filter]="true" formControlName="reseau" [selected]="groupeForm.value.reseau"  ></tc-select>
                </tc-form-group>
            </div>
        </div>


        <div class="row">
            <div class="col-sm-4">
                    <tc-form-group>
                        <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                        <tc-input type="text"  formControlName="code" >
                        </tc-input>
                        <ng-container *ngIf="groupeForm.get('code').invalid && (groupeForm.get('code').touched || groupeForm.get('code').dirty)">
                            <span @fadeIn class="error" *ngIf="groupeForm.get('code').getError('required')">Veuillez saisir le code du groupe</span>
                            <span @fadeIn class="error" *ngIf="groupeForm.get('code').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                        </ng-container>
                    </tc-form-group>
            </div>
            <div class="col-sm-8">
                <tc-form-group>
                     <tc-form-label>{{Columnslabels.label}}</tc-form-label>
                    <tc-input type="text"  formControlName="nom">
                    </tc-input>
                    <ng-container *ngIf="groupeForm.get('nom').invalid && (groupeForm.get('nom').touched || groupeForm.get('nom').dirty)">
                        <span @fadeIn class="error" *ngIf="groupeForm.get('nom').getError('required')">Veuillez saisir le nom du marché</span>
                        <span @fadeIn class="error" *ngIf="groupeForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                    </ng-container>
                </tc-form-group>
            </div>
        </div>


     <ng-container *ngIf="groupeTree">
         <div class="row">
             <div class="col-sm-12">
                 <tc-form-group>
                     <tc-form-label>{{Columnslabels.parent}}</tc-form-label>
                     <nz-tree-select
                             [nzNodes]="groupeTree"
                             [nzSize]="'default'"
                             [nzShowSearch]="true"
                             [nzDefaultExpandAll]="true"
                             nzPlaceHolder="Veuillez sélectionner le groupe parent"
                             formControlName="parent"
                             [nzAllowClear]="false"
                     >
                     </nz-tree-select>

                 </tc-form-group>
             </div>
         </div>

     </ng-container>


                <tc-form-group>
                    <tc-form-label>{{Columnslabels.description}}</tc-form-label>
                    <tc-textarea  [charLimiter]="{ counter: 255, before: 'Il reste ', after: ' caractères' }"
                                  [tcShape]="0" [rows]="3" formControlName="description"></tc-textarea>
                </tc-form-group>


                <tc-form-group>
                    <tc-checkbox   formControlName="actif" >Actif</tc-checkbox>
                </tc-form-group>


    </tc-card>

    <tc-card >

                <span *ngIf="!groupeForm.valid && groupeForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                <button [disabled]="!groupeForm.valid && groupeForm.touched" tc-button [block]="true" >
                    Valider
                </button>

    </tc-card>

<!--</div>-->
    </form>
    </div>
</ng-container>

