import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ReseauService } from '../reseau.service';
import { ToastrService } from 'ngx-toastr';
import {Reseau} from '../../models/reseau';
import {FormBuilder,
    FormGroup, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {INoeud} from '../../produit/create';
import {TypeSIMService} from '../typeSIMService.service';
import {IOption} from '../../../../ui/interfaces/option';
import {ProduitService} from '../../produit/produit.service';
import {DepotService} from '../../depot/depot.service';
import {MarcheService} from '../../marche/marche.service';
import {MesureService} from '../../mesure/mesure.service';
import {UserService} from '../../administrateur/user.service';
import {QualiteService} from '../../qualite/qualite.service';


@Component({
    selector: 'tc-reseau-create',
    templateUrl: './create-reseau.component.html',
     animations: [fadeIn]
})

export class PageCreateReseauComponent extends BasePageComponent implements OnInit, OnDestroy {
    reseauForm: FormGroup;
    treeReseau: INoeud[];
    reseau: any;
    selectedId: number;
    submitted = false;
    changes = false;
    typesSIM: IOption[] = [];
    depots: any[];
    depotsAdapter: IOption[] = [];
    marches: any[];
    admins: any[];
    adminsAdapter: IOption[] = [];
    marchesAdapter: IOption[] = [];
    produits: any[];
    produitsAdapter: IOption[] = [];
    mesures: any[];
    mesuresAdapter: IOption[] = [];
    qualites: any[];
    qualitesAdapter: IOption[] = [];
    Columnslabels =
        {
            code: 'Code Réseau',
            label: 'Libellé Réseau',
            description: 'Description',
            typeSIM: 'Organisation',
            produits: 'Produits',
            marches: 'Marchés',
            depots: 'Dépôts',
            mesures: 'Mesures',
            qualites: 'Qualités',
            administrateur: 'Administrateur Principal',
            actif: 'Actif'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private reseauService: ReseauService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private fb: FormBuilder, private typeSIMService: TypeSIMService,
                 private produitService: ProduitService,
                 private depotService: DepotService,
                 private marcheService: MarcheService,private qualiteService: QualiteService,
                 private mesureService: MesureService, private userService: UserService

    ) {
        super(store, httpSv);
        this.reseau = new Reseau();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des réseaux',
                    route: 'list-reseaux'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.reseau) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getReseau (jsonValue): Reseau {
        return Reseau.fromJSON(jsonValue) ;
    }
    getTitle(): string {
       return  this.isEditing() ? ' Modifier un Réseau ' : ' Créer un Réseau ' ;
    }
    isEditing(): boolean {
        return (this.reseau.id != null);
    }
   initReactiveForm() {
        this.reseauForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                typeSIM: ['AUTRE', Validators.required],
                adminPrincipal: [''],
                description: [''],
                produits:  [''],
                marches: [''],
                depots:  [''],
                mesures:  [''],
                qualites:  [''],
                actif: [true]
            }
        ) ;
    }
    onProduitsPromise() {
        this.produitsAdapter = [];
        for (const produit of this.produits) {
            this.produitsAdapter.push({'value' : produit.id.toString(), 'label' : produit.nom});
        }
    }
    onDepotsPromise() {
        this.depotsAdapter = [];
        for (const depot of this.depots) {
            this.depotsAdapter.push({'value' : depot.id.toString(), 'label' : depot.nom});
        }
    }
    onMarchesPromise() {
        this.marchesAdapter = [];
        for (const marche of this.marches) {
            this.marchesAdapter.push({'value' : marche.id.toString(), 'label' : marche.nom});
        }
    }

    onMesuresPromise() {
        this.mesuresAdapter = [];
        for (const mesure of this.mesures) {
            this.mesuresAdapter.push({'value' : mesure.id.toString(), 'label' : mesure.nom});
        }
    }
    onQualitesPromise() {
        this.qualitesAdapter = [];
        for (const qualite of this.qualites) {
            this.qualitesAdapter.push({'value' : qualite.id.toString(), 'label' : qualite.nom});
        }
    }
    setReactiveForm() {
        const reseau = this.getReseau(this.reseau);
        const produits = reseau.selectedProducts !== [] ? reseau.selectedProducts : '' ;
        const marches = reseau.selectedMarches !== [] ? reseau.selectedMarches : '';
        const depots =  reseau.selectedDepots !== [] ? reseau.selectedDepots : '';
        const mesures =  reseau.selectedMesures !== [] ? reseau.selectedMesures : '';
        const qualites =  reseau.selectedQualites !== [] ? reseau.selectedQualites : '';
        const adminPrincipalId = (this.reseau.adminPrincipal != null) ? this.reseau.adminPrincipal.id.toString() : '';
        console.log('marchesAdapter',this.marchesAdapter);
        console.log('marches',marches);
        this.reseauForm.patchValue({
            code: this.reseau.code,
            nom: this.reseau.nom,
            typeSIM: this.reseau.typeSIM,
            description: this.reseau.description,
            actif: this.reseau.actif,
            produits: produits,
            adminPrincipal: adminPrincipalId,
            marches: marches,
            depots: depots,
            mesures: mesures,
            qualites: qualites

            }
    );
    }

    ngOnInit() {
        super.ngOnInit();
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.reseauService.getURL(this.selectedId), 'reseau', 'reseau', 'setLoaded');
            } else {
                this.reseau = new Reseau();
                this.initReactiveForm();
            }
        });
        this.getData(this.typeSIMService.getAllURL(), 'typesSIM');
        this.reseauService.findAsTree().subscribe(tree => {
                this.treeReseau = tree;
            }
        );
        this.getData(this.produitService.getAllURL(), 'produits', 'onProduitsPromise');
        this.getData(this.marcheService.getAllURL(), 'marches', 'onMarchesPromise');
        this.getData(this.depotService.getAllURL(), 'depots', 'onDepotsPromise');
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.getData(this.qualiteService.getAllURL(), 'qualites', 'onQualitesPromise');
        this.userService.getAll('Administrateur').subscribe(admins => {
            this.admins = admins;
            this.adminsAdapter = [];
            for (const admin of this.admins) {
                this.adminsAdapter.push({'value' : admin.id.toString(), 'label' : admin.nomCompletEtReseau});
            }

            }
        );



        // detect form changes
        this.reseauForm.valueChanges.subscribe(() => {
            this.changes = true;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-reseau']);
    }
    liste() {
        this.router.navigate(['/vertical/list-reseau']);
    }
    mapFormValuesToModel() {
        this.reseau.code =  this.reseauForm.value.code;
        this.reseau.nom =  this.reseauForm.value.nom;
        this.reseau.description =  this.reseauForm.value.description;
        this.reseau.actif =  this.reseauForm.value.actif;
        this.reseau.typeSIM =  this.reseauForm.value.typeSIM;
        this.reseau.adminPrincipal =  this.reseauForm.value.adminPrincipal;
        this.reseau.produits =  this.reseauForm.value.produits;
        this.reseau.marches =  this.reseauForm.value.marches;
        this.reseau.depots =  this.reseauForm.value.depots;
        this.reseau.mesures =  this.reseauForm.value.mesures;
        this.reseau.qualites =  this.reseauForm.value.qualites;
       }
    update(): void {
        if (this.reseauForm.valid) {
        this.mapFormValuesToModel();
        this.reseauService.update(this.reseau.id, this.reseau)
            .subscribe(
                data => {

                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/list-reseau']);
                        }
                ,
                error => console.log(error));
        }

    }
    create(): void {
        if (this.reseauForm.valid) {
            this.mapFormValuesToModel();
            this.reseauService.create(this.reseau)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie ' , '',
                            {timeOut: 2000});
                        this.reseauForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
