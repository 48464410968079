var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { SMSPush } from '../models/SMSPush';
import { GroupeService } from '../groupe/groupe.service';
import { UserService } from '../administrateur/user.service';
import { Acteur } from '../models/acteur';
import { PushSMSService } from '../push-sms/pushSMS.service';
var PushSmsMembresGroupesComponent = /** @class */ (function (_super) {
    __extends(PushSmsMembresGroupesComponent, _super);
    function PushSmsMembresGroupesComponent(store, httpSv, route, router, toastr, fb, i18n, administrateurService, pushSMSService, groupeService) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.route = route;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.administrateurService = administrateurService;
        _this.pushSMSService = pushSMSService;
        _this.groupeService = groupeService;
        _this.groupes = [];
        _this.groupesAdapter = [];
        _this.destinatairesAdapter = [];
        _this.Columnslabels = {
            groupes: 'Groupes',
            destinataires: 'Destinataires',
            message: 'Message'
        };
        _this.pageData = {
            title: '',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        return _this;
    }
    PushSmsMembresGroupesComponent.prototype.initReactiveForm = function () {
        this.pushSMSForm = this.fb.group({
            groupes: ['', Validators.required],
            destinataires: ['', Validators.required],
            message: ['', [Validators.required, Validators.minLength(2)]]
        });
    };
    PushSmsMembresGroupesComponent.prototype.envoyerSMS = function () {
        var _this = this;
        this.pushSMS.groupes = this.pushSMSForm.value.groupes;
        this.pushSMS.destinataires = this.pushSMSForm.value.destinataires;
        this.pushSMS.message = this.pushSMSForm.value.message;
        this.pushSMSService.send(this.pushSMS).subscribe(function (data) {
            _this.toastr.success(' SMS envoyé avec succès ', '', { timeOut: 2000 });
            _this.pushSMSForm.reset();
        }, function (error) { return console.log('Error', error); });
    };
    PushSmsMembresGroupesComponent.prototype.setLoaded = function (during) {
        if (during === void 0) { during = 0; }
        _super.prototype.setLoaded.call(this, during);
    };
    PushSmsMembresGroupesComponent.prototype.getUserObject = function (jsonValue) {
        return Acteur.fromJSON(jsonValue);
    };
    PushSmsMembresGroupesComponent.prototype.onChangeGroupes = function (event) {
        var _this = this;
        this.acteurs = [];
        this.destinatairesAdapter = [];
        this.groupeService.mesMembres(event).subscribe(function (tree) {
            _this.acteurs = tree;
            _this.destinatairesAdapter = [];
            for (var _i = 0, _a = _this.acteurs; _i < _a.length; _i++) {
                var acteur = _a[_i];
                // tslint:disable-next-line:max-line-length
                _this.destinatairesAdapter.push({ 'value': acteur.id.toString(), 'selected': true, 'label': _this.getUserObject(acteur).getFullName });
            }
        });
    };
    PushSmsMembresGroupesComponent.prototype.onGroupesPromise = function () {
        var _this = this;
        this.groupesAdapter = [];
        for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
            var groupe = _a[_i];
            this.groupesAdapter.push({ 'value': groupe.id, 'label': groupe.nom });
            this.groupeService.mesMembres(groupe.id).subscribe(function (tree) {
                _this.acteurs = tree;
                _this.destinatairesAdapter = [];
                for (var _i = 0, _a = _this.acteurs; _i < _a.length; _i++) {
                    var acteur = _a[_i];
                    // tslint:disable-next-line:max-line-length
                    _this.destinatairesAdapter.push({ 'value': acteur.id.toString(), 'selected': true, 'label': _this.getUserObject(acteur).getFullName });
                }
            });
        }
    };
    PushSmsMembresGroupesComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.pushSMS = new SMSPush();
        this.pageData.title = this.getTitle();
        this.groupeService.getCached().subscribe(function (data) {
            _this.groupes = data;
            _this.onGroupesPromise();
        });
        this.initReactiveForm();
    };
    PushSmsMembresGroupesComponent.prototype.getTitle = function () {
        return 'Envoyer des sms aux membres des groupes';
    };
    PushSmsMembresGroupesComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    return PushSmsMembresGroupesComponent;
}(BasePageComponent));
export { PushSmsMembresGroupesComponent };
