import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Groupe} from '../models/groupe';
import {map, startWith} from 'rxjs/operators';
import {INoeud} from '../produit/create';
import {Acteur} from '../models/acteur';
const CACHE_KEY = 'sima-groupe-cache';
@Injectable({
  providedIn: 'root'
})

export class GroupeService {
	private baseUrl = '/api/groupe';
  private groupes;

  constructor(private http: HttpClient) {

  }
  findAll(filter = '', sortField = 'nom', sortOrder = 'asc',
          offset = 0, pageSize = 10): Observable<Groupe[]> {


    return this.http.get(`${this.baseUrl}/pages`, {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sortField)
          .set('sortOrder', sortOrder)
          .set('offset', offset.toString())
          .set('max', pageSize.toString())
    }) as Observable<Groupe[]>;
  }
  findCount(): Observable<number> {
    return this.http.get(`${this.baseUrl}/count`)
        .pipe(
            map(res => res['total'])
        );
  }
  mesMembres(groupeId: number): Observable<Acteur[]> {
    return this.http.get('/api/groupe/mes_membres', {
      params: new HttpParams()
          .set('id', groupeId.toString())
    }) as Observable<Acteur[]>;
  }
  findAsTree(): Observable<INoeud[]> {
    return this.http.get(`${this.baseUrl}/tree`)
        .pipe(
            map(res => res['tree'])
        );
  }
  findAsTreeByGroupe(groupeId: number): Observable<INoeud[]> {
    return this.http.get(`${this.baseUrl}/treeByGroupe`, {
      params: new HttpParams()
          .set('groupeId', groupeId.toString())
    })
        .pipe(
            map(res => res['tree'])
        );
  }
  findAsTreeByReseau(reseauId: number): Observable<INoeud[]> {
    return this.http.get(`${this.baseUrl}/treeByReseau`, {
      params: new HttpParams()
          .set('reseauId', reseauId.toString())
    })
        .pipe(
            map(res => res['tree'])
        );
  }
  getCached(): Observable<any[]> {
    this.groupes = this.http.get<any>(`${this.baseUrl}/short`)
        .pipe(
            map(data => data.items)
        );

    this.groupes.subscribe(next => {
         localStorage[CACHE_KEY] = JSON.stringify(next);
        }

    );
    this.groupes = this.groupes.pipe(
        startWith(JSON.parse(localStorage[CACHE_KEY] || '[]'))
    );
    return this.groupes;
  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getCachedURL(): string {
    return `${this.baseUrl}/short`;
  }
  getAllByReseauURL(id: number): string {
    return `${this.baseUrl}/par_reseau/${id}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj);
  }
  update(id: number, value: any): Observable<Object> {

    return this.http.put(`${this.baseUrl}/modifier/${id}`, value);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }

}

