import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {Acteur} from '../models/acteur';
import {Marche} from '../models/marche';
import {Depot} from '../models/depot';
import {Produit} from '../models/produit';
import {INoeud} from '../produit/create';
import {ICheckActeur} from './create';
import {IchangePassword, IShowPassword} from '../profilAdmin';
const CACHE_KEY = 'sima-user-cache';
@Injectable({
  providedIn: 'root'
})
export class UserService {
	private baseUrl = '/api/acteur';
    users;
  constructor(private http: HttpClient) {

  }
  findAll(  filter = '', userType: string, sortField = 'nom', sortOrder = 'asc',
                    offset = 0, pageSize = 10): Observable<Acteur[]> {


    return this.http.get('/api/acteur/pages', {
      params: new HttpParams()
          .set('userType', userType.toUpperCase())
          .set('filter', filter)
          .set('sort', sortField)
          .set('sortOrder', sortOrder)
          .set('offset', offset.toString())
          .set('max', pageSize.toString())
    }) as Observable<Acteur[]>;
  }
  getAll(  userType: string): Observable<Acteur[]> {
    return this.http.get('/api/acteur/all', {
      params: new HttpParams()
          .set('userType', userType.toUpperCase())
    }) as Observable<Acteur[]>;
  }
    getAllReport(  userType: string): Observable<Acteur[]> {
        return this.http.get('/api/acteur/report', {
            params: new HttpParams()
                .set('userType', userType.toUpperCase())
        }) as Observable<Acteur[]>;
    }
  mesMarches(): Observable<Marche[]> {
   return this.http.get('/api/mes_marches') as Observable<Marche[]>;
  }
  mesMarchesPreferes(): Observable<Marche[]> {
        return this.http.get('/api/mes_marches_preferes') as Observable<Marche[]>;
    }
  mesDepots(): Observable<Depot[]> {
    return this.http.get('/api/mes_depots') as Observable<Depot[]>;
  }
    mesDepotsPreferes(): Observable<Depot[]> {
        return this.http.get('/api/mes_depots_preferes') as Observable<Depot[]>;
    }
  mesProduits(): Observable<Produit[]> {
    return this.http.get('/api/mes_produits') as Observable<Produit[]>;
  }

  findMe(): Observable<Acteur> {
    return this.http.get('/api/find_me') as Observable<Acteur>;
  }
  checkPassword(actualPwd: string): Observable<boolean> {
    return this.http.get('/api/check_password', {
      params: new HttpParams()
          .set('actualPwd', actualPwd)
    }) as Observable<boolean>;
  }
    resetPassword(mobilePhone: string): Observable<IchangePassword> {
        return this.http.get('/api/reset_password', {
            params: new HttpParams()
                .set('mobilePhone', mobilePhone)
        }) as Observable<IchangePassword>;
    }
  checkAlreadyCreated(mobilePhone: string): Observable<ICheckActeur> {
    return this.http.get('/api/check_if_exist', {
      params: new HttpParams()
          .set('mobilePhone', mobilePhone)
    }).pipe(
        map(result => result['checkActeur'])
    );
  }
  findByMobile(mobilePhone: string): Observable<Acteur> {
    return this.http.get('/api/findByMobile', {
      params: new HttpParams()
          .set('mobilePhone', mobilePhone)
    }) as Observable<Acteur>;
  }

 changePassword(newPassword: string): Observable<IchangePassword> {
    return this.http.get('/api/change_password', {
      params: new HttpParams()
          .set('newPassword', newPassword)
    }).pipe(
        map(result => result['reponse'])
    );
  }
    showPassword(thePhone: string): Observable<IShowPassword> {
        return this.http.get('/api/getPassword', {
            params: new HttpParams()
                .set('thePhone', thePhone)
        }).pipe(
            map(result => result['motPasseEnClair'])
        );
    }
  findCount(userType: string): Observable<number> {
    return this.http.get('/api/acteur/count', {
      params: new HttpParams()
          .set('userType', userType.toUpperCase())

    }).pipe(
            map(res => res['total'])
        );
  }
  get(id: number): Observable<Object> {
    return this.http.get(`${this.baseUrl}/${id}`);
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(userType: string, obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}/enregistrer`, obj , {
      params: new HttpParams()
          .set('userType', userType.toUpperCase())

    });
  }

  update(id: number, userType: string, value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/modifier/${id}`, value, {
      params: new HttpParams()
          .set('userType', userType ? userType.toUpperCase() : '')

    });
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }
    findAsTree(): Observable<INoeud[]> {
        return this.http.get(`${this.baseUrl}/tree`)
            .pipe(
                map(res => res['tree'])
            );
    }
  findAsTreeByGroupes(groupes: string): Observable<INoeud[]> {
    return this.http.get(`${this.baseUrl}/getTreeByGroupes`, {
      params: new HttpParams()
          .set('groupes', groupes)
    })
        .pipe(
            map(res => res['tree'])
        );
  }
    getCached(): Observable<any[]> {
        this.users = this.http.get<any>(`${this.baseUrl}/short`)
            .pipe(
                map(data => data.items)
            );

        this.users.subscribe(next => {
                localStorage[CACHE_KEY] = JSON.stringify(next);
            }

        );
        this.users = this.users.pipe(
            startWith(localStorage[CACHE_KEY] || '[]')
        );
        return this.users;
    }
    getNomReseau(id: string): Observable<Object> {
        return this.http.get(`${this.baseUrl}/nom`);

    }
}

