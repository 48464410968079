import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import {Store} from '@ngrx/store';
import {BasePageComponent} from '../../base-page';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {DashboardService} from '../dashboard/dashboard.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {jqxDataTableComponent} from 'jqwidgets-ng/jqxdatatable';
import {jqxDateTimeInputComponent} from 'jqwidgets-ng/jqxdatetimeinput';
import {IOption} from '../../../ui/interfaces/option';
import {UserService} from '../../ui/administrateur/user.service';
import {RegionService} from '../../ui/region/region.service';
import {ValiderOffreVenteService} from '../../ui/valider-offre-vente/validerOffreVente.service';
import {ReseauService} from '../../ui/reseau/reseau.service';
import DataTableExportSettings = jqwidgets.DataTableExportSettings;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'page-rapports-offres-vtes',
  templateUrl: './rapportsOffresVente.component.html',
 // styleUrls: ['./rapportsOffresVente.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageRapportsOffresVenteComponent extends BasePageComponent implements OnInit, OnDestroy, AfterViewInit {
  produits: any[] = [];
  regions: any[] = [];
  produitsAdapter: IOption[] = [];
  regionsAdapter: IOption[] = [];
  reseaux: any[] = [];
  reseauxAdapter: IOption[] = [];
  rapportsForm: FormGroup;
  constructor(
    store: Store<IAppState>, private dashboardService: DashboardService,
    httpSv: HttpService, private validerOffreVenteService: ValiderOffreVenteService,
    private regionService: RegionService, private reseauService: ReseauService,
     private adminService: UserService, private fb: FormBuilder
  ) {
    super(store, httpSv);
    this.pageData = {
      title: 'RAPPORT SUR LES OFFRES DE VENTE',
      breadcrumbs: [
      ],
    };
  }
  @ViewChild('myDataTable', { static: false }) myDataTable: jqxDataTableComponent;
  @ViewChild('dateDebut', { static: false }) dateDebut: jqxDateTimeInputComponent;
  @ViewChild('dateFin', { static: false }) dateFin: jqxDateTimeInputComponent;
  @ViewChild('log', { static: false }) log; ElementRef;
  Columnslabels =
      {
        dateDebut: 'Date début',
        dateFin: 'Date Fin'
      };
  source: any =
      { localData: []
      };
  dataAdapter: any;
  localizationObject = this.getLocalization();
  exportSettings = this.getExportSettings();
  columns: any[] =
      [
          { text: 'Réseau', dataField: 'reseau', width: '10%', cellsAlign: 'left' , align: 'left'},
          { text: 'Région', dataField: 'region', width: '10%', cellsAlign: 'left' , align: 'left'},
          { text: 'Produit', dataField: 'nom_produit', width: '12%', cellsAlign: 'left', align: 'left' },
          { text: 'Date', dataField: 'date', width: '12%', align: 'left', cellsAlign: 'right', cellsFormat: 'd' },
          { text: 'Auteur', dataField: 'auteur', width: '18%', align: 'left', cellsAlign: 'left' },
          { text: 'PU', dataField: 'prixUnitaire', cellsAlign: 'right', width: '8%', align: 'right', cellsFormat: 'n' },
          { text: 'Montant', dataField: 'montant', cellsAlign: 'right', width: '10%', align: 'right', cellsFormat: 'n' },
          { text: 'Qte', dataField: 'quantite', cellsAlign: 'right', width: '8%', align: 'right', cellsFormat: 'n'  },
          { text: 'U. Mesure', dataField: 'mesure', cellsAlign: 'center', width: '12%', align: 'center'  }
      ];
    getExportSettings(): DataTableExportSettings {
        const exportObj: DataTableExportSettings  = {};
        exportObj.columnsHeader = true;
        exportObj.hiddenColumns = false;
        exportObj.serverURL = null;
        exportObj.characterSet = null;
        exportObj.recordsInView = true;
        exportObj.fileName = 'rapport_offres_vente';
        return exportObj;
    }
  getLocalization(): any {
    const localizationobj: any = {};
    localizationobj.pagerGoToPageString = 'Aller à la page:';
    localizationobj.pagerShowRowsString = 'Voir ligne:';
    localizationobj.pagerRangeString = ' de ';
    localizationobj.pagerNextButtonString = 'Suivant';
    localizationobj.pagerFirstButtonString = 'Premier';
    localizationobj.pagerLastButtonString = 'Dernier';
    localizationobj.pagerPreviousButtonString = 'Précedent';
    localizationobj.sortAscendingString = 'Ordre Ascendent';
    localizationobj.sortDescendingString = 'Ordre Descendant';
    localizationobj.sortRemoveString = 'Supprimer';
    localizationobj.emptydatastring = 'Pas de données';
    localizationobj.firstDay = 1;
    localizationobj.percentSymbol = '%';
    localizationobj.currencySymbol = '';
      localizationobj.currencySymbolPosition = 'après';
      localizationobj.decimalSeparator = ',';
      localizationobj.thousandsSeparator = ' ';
      localizationobj.filterapplystring = 'Appliquer';
      localizationobj.filtercancelstring = 'Annuler';
      localizationobj.filterclearstring = 'Effacer Filtre';
      localizationobj.filterstring =  'Avancé';
      localizationobj.filtersearchstring = 'Chercher';
      localizationobj.filterstringcomparisonoperators = ['vide', 'non vide', 'contient', 'contient(respecte la casse)',
          'Ne contient pas', 'Ne contient pas(respecte la casse)', 'commence par', 'commence par(respecte la casse)',
          'finit par', 'finit par(respecte la casse)', 'égale', 'égale(respecte la casse)', 'null', 'non null'];
      // tslint:disable-next-line:max-line-length
      localizationobj.filternumericcomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
      // tslint:disable-next-line:max-line-length
      localizationobj.filterdatecomparisonoperators = ['égale', 'non égale', 'strictement inférieur à', 'inférieur ou égale à', 'strictement supérieur à', 'supérieur ou égale à', 'null', 'non null'];
      localizationobj.filterbooleancomparisonoperators = ['égale', 'non égale'];
      localizationobj.validationstring = 'La valeur entrée n\'est pas valide ';
      localizationobj.emptydatastring =  'Pas de données à afficher';
      localizationobj.filterselectstring =  'Selectionnez un filtre';
      localizationobj.loadtext = 'Chargement...';
      localizationobj.clearstring = 'Effacer';
      localizationobj.todaystring = 'Aujourd\'hui';
      // tslint:disable-next-line:max-line-length
      localizationobj.loadingerrormessage = 'Chargement en cours des données vous ne pouvez pas mettre à jour les propriétés ou appeler une méthode . Vous pourrez le faire après le chargement des données.';
    localizationobj.decimalSeparator = ',';
    localizationobj.thousandsSeparator = ' ';
    localizationobj.days = {
      // full day names
      names: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      // abbreviated day names
      namesAbbr: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      // shortest day names
      namesShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
    };

    localizationobj.patterns = {
      d: 'dd/MM/yyyy',
      D: 'dddd, d. MMMM yyyy',
      t: 'HH:mm',
      T: 'HH:mm:ss',
      f: 'dddd, d. MMMM yyyy HH:mm',
      F: 'dddd, d. MMMM yyyy HH:mm:ss',
      M: 'dd MMMM',
      Y: 'MMMM yyyy'
    };
    localizationobj.months = {
      // full month names (13 months for lunar calendards -- 13th month should be '' if not lunar)
      names: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre', ''],
      // abbreviated month names
      namesAbbr: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', '']
    };
    return localizationobj;
  }
  getWidth(): any {
    if (document.body.offsetWidth < 850) {
        return '95%';
    }

    return 850;
  }
  excelExport(): void {
    this.myDataTable.exportData('xls');
  }  xmlExport(): void {
    this.myDataTable.exportData('xml');
  }  csvExport(): void {
    this.myDataTable.exportData('csv');
  }  tsvExport(): void {
    this.myDataTable.exportData('tsv');
  }  htmlExport(): void {
    this.myDataTable.exportData('html');
  }  jsonExport(): void {
    this.myDataTable.exportData('json');
  }  pdfExport(): void {
    this.myDataTable.exportData('pdf');
  }
  printBtnOnClick(): void {
    const gridContent = this.myDataTable.exportData('html');
    const newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
            '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '<meta charset="utf-8" />' +
            '<title>ETAT DES PRIX</title>' +
            '</head>' +
            '<body>' + gridContent + '</body></html>';
    document.write(pageContent);
    document.close();
    newWindow.print();
  }
  onRegionsPromise() {
    this.regionsAdapter = [];
    for (const region of this.regions) {
      this.regionsAdapter.push({'value' : region.id.toString(), 'label' : region.nom});
    }
  }
    onReseauxPromise() {
        this.reseauxAdapter = [];
        for (const reseau of this.reseaux) {
            this.reseauxAdapter.push({'value' : reseau.id.toString(), 'label' : reseau.code});
        }
    }
  ngOnInit() {
    super.ngOnInit();
    this.adminService.mesProduits().subscribe(produits => {
    this.produits = produits ;
    this.produitsAdapter = [];
    for (const produit of this.produits) {
        this.produitsAdapter.push({'value' : produit.id.toString(), 'label' : produit.nom});
      }
    });

      this.initReactiveForm();
      this.reseauService.getCached().subscribe(data => {
              this.reseaux = data;
              this.onReseauxPromise();
          }
      );
    this.regionService.getCached().subscribe(data => {
          this.regions = data;
          this.onRegionsPromise();
        }
    );

      this.rapportsForm.get('reseau').valueChanges.subscribe(
          (selectedValue) => {
            if (selectedValue !== undefined)  {
              this.validerOffreVenteService.getOffresValides(this.dateDebut.getDate().toLocaleDateString(),
                  this.dateFin.getDate().toLocaleDateString(),
                  // tslint:disable-next-line:max-line-length
                  this.rapportsForm.get('produits').value, this.rapportsForm.get('regions').value, this.rapportsForm.get('reseau').value ).subscribe(datas => {
                    this.source.localData = datas;
                    this.dataAdapter = new jqx.dataAdapter(this.source);
                  }
              );
            }
          });
      this.rapportsForm.get('regions').valueChanges.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.validerOffreVenteService.getOffresValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('regions').value, this.rapportsForm.get('reseau').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.rapportsForm.get('produits').valueChanges.subscribe(
          (selectedValue) => {
            if (selectedValue !== undefined)  {
              this.validerOffreVenteService.getOffresValides(this.dateDebut.getDate().toLocaleDateString(),
                  this.dateFin.getDate().toLocaleDateString(),
                  // tslint:disable-next-line:max-line-length
                  this.rapportsForm.get('produits').value, this.rapportsForm.get('regions').value, this.rapportsForm.get('reseau').value).subscribe(datas => {
                    this.source.localData = datas;
                    this.dataAdapter = new jqx.dataAdapter(this.source);
                  }
              );
            }
          });

      this.setLoaded();

  }
  initReactiveForm() {
    this.rapportsForm = this.fb.group({
          produits: [''],
          regions: [''],
          reseau: ''
        }
    ) ;
  }
  ngAfterViewInit(): void {
    this.dateDebut.culture('fr-FR');
    this.dateFin.culture('fr-FR');
    const pdateDebut = new Date();
    pdateDebut.setFullYear(pdateDebut.getFullYear(), 0, 1);
    const pdateFin = new Date();
    pdateFin.setFullYear(pdateFin.getFullYear(), pdateFin.getMonth() , pdateFin.getDate());
    this.dateDebut.setDate(pdateDebut);
    this.dateFin.setDate(pdateFin);
      this.dateFin.onValueChanged.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.validerOffreVenteService.getOffresValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('regions').value, this.rapportsForm.get('reseau').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.dateDebut.onValueChanged.subscribe(
          (selectedValue) => {
              if (selectedValue !== undefined)  {
                  this.validerOffreVenteService.getOffresValides(this.dateDebut.getDate().toLocaleDateString(),
                      this.dateFin.getDate().toLocaleDateString(),
                      // tslint:disable-next-line:max-line-length
                      this.rapportsForm.get('produits').value, this.rapportsForm.get('regions').value, this.rapportsForm.get('reseau').value).subscribe(datas => {
                          this.source.localData = datas;
                          this.dataAdapter = new jqx.dataAdapter(this.source);
                      }
                  );
              }
          });
      this.validerOffreVenteService.getOffresValides(this.dateDebut.getDate().toLocaleDateString(),
          this.dateFin.getDate().toLocaleDateString(),
          // tslint:disable-next-line:max-line-length
          this.rapportsForm.get('produits').value, this.rapportsForm.get('regions').value, this.rapportsForm.get('reseau').value).subscribe(datas => {
              this.source.localData = datas;
              this.dataAdapter = new jqx.dataAdapter(this.source);
          }
      );

  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }



}
