import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ValiderInfoService = /** @class */ (function () {
    function ValiderInfoService(http) {
        this.http = http;
        this.baseUrl = '/api/info';
    }
    ValiderInfoService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    ValiderInfoService.prototype.getAllByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderInfoService.prototype.getAllValidesByReseau = function (reseauId) {
        return this.http.get(this.baseUrl + "/get_all_valides_by_reseau", {
            params: new HttpParams()
                .set('reseauId', reseauId)
        });
    };
    ValiderInfoService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ValiderInfoService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    ValiderInfoService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    ValiderInfoService.prototype.valider = function (obj) {
        return this.http.post(this.baseUrl + "/valider", obj);
    };
    ValiderInfoService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    ValiderInfoService.prototype.rejeter = function (obj) {
        return this.http.post(this.baseUrl + "/rejeter", obj);
    };
    ValiderInfoService.prototype.abandonner = function (obj) {
        return this.http.post(this.baseUrl + "/abandonner", obj);
    };
    ValiderInfoService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    ValiderInfoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValiderInfoService_Factory() { return new ValiderInfoService(i0.ɵɵinject(i1.HttpClient)); }, token: ValiderInfoService, providedIn: "root" });
    return ValiderInfoService;
}());
export { ValiderInfoService };
