import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class TypeSIMService {
	private baseUrl = '/api/typeSIM';
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }

}
