import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Region} from '../models/region';
import {map, startWith} from 'rxjs/operators';
const CACHE_KEY = 'sima-regions-cache';
@Injectable({
  providedIn: 'root'
})
export class RegionService {
	private baseUrl = '/api/region';
	private regions;
  constructor(private http: HttpClient) { }
  findAll(filter = '', sortField = 'nom', sortOrder = 'asc',
          offset = 0, pageSize = 10): Observable<Region[]> {


    return this.http.get('/api/region/pages', {
      params: new HttpParams()
          .set('filter', filter)
          .set('sort', sortField)
          .set('sortOrder', sortOrder)
          .set('offset', offset.toString())
          .set('max', pageSize.toString())
    }) as Observable<Region[]>;
  }
  findCount(): Observable<number> {
    return this.http.get('/api/region/count')
        .pipe(
            map(res => res['total'])
        );
  }
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }
  getURL(id: number): string {
	  return `${this.baseUrl}/${id}`;
  }
  create(obj: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}`, obj);
  }
  update(id: number, value: any): Observable<Object> {
    return this.http.put(`${this.baseUrl}/${id}`, value);
  }
  delete(id: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`, { responseType: 'text' });
  }
  getQualitesList(): Observable<any> {
    return this.http.get(`${this.baseUrl}`);
  }
  getCached(): Observable<any[]> {
    this.regions = this.http.get<any>(`${this.baseUrl}/short`)
        .pipe(
            map(data => data.items)
        );

    this.regions.subscribe(next => {
          localStorage[CACHE_KEY] = JSON.stringify(next);
        }

    );
    this.regions = this.regions.pipe(
        startWith(JSON.parse(localStorage[CACHE_KEY] || '[]'))
    );
    return this.regions;
  }
}

