import { Mesure } from './mesure';
import { Categorie } from './categorie';
var Produit = /** @class */ (function () {
    function Produit() {
        this.mesure = new Mesure();
        this.categorie = new Categorie();
        this.actif = true;
    }
    Produit.fromJSON = function (json) {
        var result = new Produit();
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    Produit.listFromJSON = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var produit = Produit.fromJSON(jsonObject);
                jsonObject.espece = produit.nomEspece;
                jsonObject.race = produit.nomRace;
                jsonObject.categorie = produit.categorie.nom;
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    Produit.listFromJSONToExport = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = { code: '', nom: '', categorie: '', espece: '', race: '', unite_de_mesure: '' };
                var produit = Produit.fromJSON(jsonObject);
                jsonObject.code = copyJson[key].code;
                jsonObject.nom = copyJson[key].nom;
                jsonObject.categorie = produit.categorie.nom;
                jsonObject.espece = produit.nomEspece;
                jsonObject.race = produit.nomRace;
                jsonObject.unite_de_mesure = copyJson[key].mesure.code;
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    Produit.prototype.toString = function () {
        return this.nom;
    };
    Object.defineProperty(Produit.prototype, "nomEspece", {
        get: function () {
            if (this.espece != null) {
                return this.espece.nom;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Produit.prototype, "nomRace", {
        get: function () {
            if (this.race != null) {
                return this.race.nom;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Produit.prototype, "categoriePrincipale", {
        get: function () {
            if (this.espece != null) {
                return this.espece.nom;
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Produit.prototype, "getFullName", {
        get: function () {
            return (this.nom + ' (' + this.code + ')');
        },
        enumerable: true,
        configurable: true
    });
    return Produit;
}());
export { Produit };
