
export class Info {
	id: number;
	titre: string;
	contenu: string;
	url: string;
	dateExpiration: Date;
	actif: boolean;
	static fromJSON(json: any): Info {
		const result = new Info();
		if (json != null) {
			for (const key in json) {
				result[key] = json[key];
			}
		}

		return result;
	}

	constructor() {
		this.actif = true;
	}
}
