import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { RegionService } from '../region.service';
import { ToastrService } from 'ngx-toastr';
import { fadeIn } from 'src/app/animations/form-error';
import {Region} from '../../models/region';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';


@Component({
    selector: 'tc-region-create',
    templateUrl: './create-region.component.html',
    styleUrls: ['./create-region.component.scss'],
    animations: [fadeIn]
})
export class PageCreateRegionComponent extends BasePageComponent implements OnInit, OnDestroy {
    regionForm: FormGroup;
    region: Region;
    submitted = false;
    changes = false;
    selectedId: number;
    Columnslabels =
        {
            code: 'Code',
            nom: 'Nom',
            actif: 'Actif'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private regionService: RegionService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.region = new Region();
        this.pageData = {
            title: 'Créer une région',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des régions',
                    route: '/vertical/list-region'
                }
            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);
        this.initReactiveForm();

    }

    setLoaded(during: number = 0) {
        super.setLoaded(during);
        this.setReactiveForm();

    }
    isEditing(): boolean {
        return (this.region.id != null);
    }

    initReactiveForm() {
        this.regionForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        if (this.region) {
            this.regionForm.patchValue({
                    code: this.region.code,
                    nom: this.region.nom,
                    actif: this.region.actif
                }
            );
        }

    }
    ngOnInit() {
        super.ngOnInit();
          this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.regionService.getURL(this.selectedId), 'region', 'region', 'setLoaded');
            } else {
                this.region = new Region();
                this.initReactiveForm();
            }
        });
        // detect form changes
        this.regionForm.valueChanges.subscribe(() => {
            this.changes = true;
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-region']);
    }
    liste() {
        this.router.navigate(['/vertical/list-region']);
    }
    mapFormValuesToModel() {
        this.region.code =  this.regionForm.value.code;
        this.region.nom =  this.regionForm.value.nom;

    }
    update(): void {
        this.mapFormValuesToModel();
        this.regionService.update(this.region.id, this.region)
            .subscribe(
                data => {

                    this.regionService.update(this.region.id, this.region).subscribe(
                        data => {
                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/list-region']);
                        }
                    );

                },
                error => console.log(error));
    }
create(): void {
        this.mapFormValuesToModel();
         this.regionService.create(this.region)
            .subscribe(
                data => {
                    this.toastr.success('Création réussie de ' + this.region.toString(), '',
                        {timeOut: 2000});
                           this.regionForm.reset();
                        }
                ,
                error => console.log(error));

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
