import {Produit} from './produit';
import {Mesure} from './mesure';
import {Qualite} from './qualite';

export class OffreVente {
	id: number;
	date: Date;
	dateExpiration: Date;
	conditions: string;
	actif: boolean;
	email: string;
	nomAuteur: string;
	telephone: string;
	quantite: number;
	montant: number;
	delaiValidite: number;
	produit: Produit;
	mesure: Mesure;
	qualite: Qualite;
	static fromJSON(json: any): OffreVente {
		const result = new OffreVente();
		for (const key in json) {
			result[key] = json[key];
		}

		return result;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const offreVente = OffreVente.fromJSON(jsonObject);
				if (offreVente.produit) {
					jsonObject.produit = offreVente.produit.nom;
				}
				if (offreVente.qualite) {
					jsonObject.qualite = offreVente.qualite.nom;
				}
				if (offreVente.mesure) {
					jsonObject.mesure = offreVente.mesure.nom;
				}
				rval.push(jsonObject);
			}
		}
		return rval;
	}

	constructor() {
		this.actif = true;
	}
}
