import { OnInit, OnChanges } from '@angular/core';
var TCBgColorDirective = /** @class */ (function () {
    function TCBgColorDirective() {
    }
    Object.defineProperty(TCBgColorDirective.prototype, "getStyle", {
        get: function () {
            return this.currentBg;
        },
        enumerable: true,
        configurable: true
    });
    TCBgColorDirective.prototype.onMouseEnter = function () {
        this.currentBg = this.hoveredBg;
    };
    TCBgColorDirective.prototype.onMouseLeave = function () {
        this.currentBg = this.defaultBg;
    };
    TCBgColorDirective.prototype.ngOnInit = function () {
        this.setColors();
    };
    TCBgColorDirective.prototype.ngOnChanges = function () {
        this.setColors();
    };
    TCBgColorDirective.prototype.setColors = function () {
        var BG_COLOR = this.tcBgColor;
        if (!BG_COLOR) {
            return null;
        }
        this.defaultBg = (typeof BG_COLOR === 'string') ? BG_COLOR : BG_COLOR[0];
        this.hoveredBg = (typeof BG_COLOR === 'string') ? BG_COLOR : BG_COLOR[1];
        this.currentBg = this.defaultBg;
    };
    return TCBgColorDirective;
}());
export { TCBgColorDirective };
