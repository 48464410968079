
<div class="row justify-content-center" >
    <div class="col-sm-12">
        <tc-card >
            <ng-container *ngIf="depotsAdapter.length>0">
                <div class="row">
                    <div class="col-sm-4 ">
                        <tc-form-group>
                            <tc-form-label>Sélectionnez le dépot</tc-form-label>

                            <tc-select [options]="depotsAdapter" [(ngModel)]="SelectedDepotId" (ngModelChange)="onSelectDepot($event)"  name="marche" [filter]="true" ></tc-select>
                        </tc-form-group>

                    </div>
                </div>
            </ng-container>
            <div class="row">
                <div class="col-sm-4 ">
                    <tc-form-group>
                        <tc-form-label>Date Saisie</tc-form-label>
                        <jqxDateTimeInput #dateStock
                                          [culture]="'fr-FR'">
                        </jqxDateTimeInput>`
                        <!--            <nz-date-picker [nzFormat]="'dd/MM/yyyy'" [ngModel]="datePrix"  name="datePrix" ></nz-date-picker>-->
                    </tc-form-group>
                </div>
                <div class="col-sm-4 ">
                </div>
                <div class="col-sm-4 ">
                    <div class="pull-right">
                        <button tc-button (click)="enregistrer()">Enregistrer</button>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="listOfData.length>0 && mesuresAdapter ">

                <nz-table #editRowTable  nzSize="middle" [nzData]="listOfData" >
                    <thead>
                    <tr>
                        <th nzWidth="60%">Nom du produit</th>
                        <th nzWidth="20%">Stock</th>
                        <th nzWidth="20%">Mesure</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of editRowTable.data">
                        <td>
                            {{ data.nomProduit }}

                        </td>

                        <td>
                            <ng-container *ngIf="!editCache[data.id].edit; else stockTpl">

                            </ng-container>
                            <ng-template #stockTpl>
                                <input currencyMask nz-input  [(ngModel)]="editCache[data.id].data.stock" [options]="{ prefix: ' ', thousands: '.', decimal: ',',precision: 0 }"/>
                            </ng-template>
                        </td>

                        <td>
                            <ng-container *ngIf="!editCache[data.id].edit; else mesureTpl">
                                {{  getCodeMesure(data.mesure) }}
                            </ng-container>
                            <ng-template #mesureTpl>
                                <nz-select
                                        style="width: 100%;"
                                        nzShowSearch
                                        nzPlaceHolder=""
                                        [(ngModel)]="editCache[data.id].data.mesure"
                                >
                                    <nz-option *ngFor="let mesure of mesures"  nzLabel={{mesure.code}}  nzValue={{mesure.id}} >
                                    </nz-option>

                                </nz-select>

                            </ng-template>
                        </td>


                    </tr>
                    </tbody>
                </nz-table>
                <div class="row">
                    <div class="col-6 col-sm-8">
                        <button tc-button (click)="enregistrer()">Enregistrer</button>
                    </div>
                </div>
            </ng-container>
        </tc-card>
    </div>
</div>

