import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import * as frDateLocale from 'date-fns/locale/fr';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {FormBuilder,
    FormGroup, Validators} from '@angular/forms';
import {UserService} from '../administrateur/user.service';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';

@Component({
    selector: 'tc-show-password',
    templateUrl: './show-password.component.html',
    styleUrls: ['./show-password.component.scss'],
    animations: [fadeIn]
})
export class ShowPasswordComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    SERVER_URL = 'api/upload_avatar';
    constructor(store: Store<IAppState>,
                httpSv: HttpService,
                private route: ActivatedRoute,
                public router: Router, private toastr: ToastrService,
                 private i18n: NzI18nService,
                private fb: FormBuilder, private adminService: UserService, private auth: AuthenticationService

    ) {
        super(store, httpSv);
        this.administrateur = {};
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [

            ]
        };
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(frDateLocale);
        this.initReactiveForm();

    }
    showPwdForm: FormGroup;
    userType: string;
    administrateur: any;
    selectedId: number;
    Columnslabels =
        {
            theMobile: 'No Mobile',
            thePassword: 'Mot de passe en clair'

        };

    getTitle(): string {
       return 'Voir un mot de passe en clair : ' ;

    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
     }


    initReactiveForm() {
       this.showPwdForm = this.fb.group({
           theMobile: ['', [Validators.required]],
           thePassword: ['']
            }
        ) ;

    }

    ngOnInit() {
        super.ngOnInit();
        if (this.auth.currentUserDataObject === null) {
            this.router.navigate(['/vertical/dashboard']);
        } else {
        this.selectedId = this.auth.currentUserDataObject.id;
      }


    }
    ngAfterViewInit() {


    }
    ngOnDestroy() {
        super.ngOnDestroy();
    }


    showPassword() {
                if  (this.showPwdForm.valid ) {

                                this.adminService.showPassword(this.showPwdForm.value.theMobile).subscribe(
                                    passWordEnClair => {
                                        this.showPwdForm.get('thePassword').setValue(passWordEnClair);

                                    }
                                );


                            }

    }
    onSubmit() {
        this.showPassword();

    }

}
