import { HttpClient, HttpParams } from '@angular/common/http';
import { map, startWith } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CACHE_KEY = 'sima-regions-cache';
var RegionService = /** @class */ (function () {
    function RegionService(http) {
        this.http = http;
        this.baseUrl = '/api/region';
    }
    RegionService.prototype.findAll = function (filter, sortField, sortOrder, offset, pageSize) {
        if (filter === void 0) { filter = ''; }
        if (sortField === void 0) { sortField = 'nom'; }
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.get('/api/region/pages', {
            params: new HttpParams()
                .set('filter', filter)
                .set('sort', sortField)
                .set('sortOrder', sortOrder)
                .set('offset', offset.toString())
                .set('max', pageSize.toString())
        });
    };
    RegionService.prototype.findCount = function () {
        return this.http.get('/api/region/count')
            .pipe(map(function (res) { return res['total']; }));
    };
    RegionService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    RegionService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    RegionService.prototype.create = function (obj) {
        return this.http.post("" + this.baseUrl, obj);
    };
    RegionService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/" + id, value);
    };
    RegionService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    RegionService.prototype.getQualitesList = function () {
        return this.http.get("" + this.baseUrl);
    };
    RegionService.prototype.getCached = function () {
        this.regions = this.http.get(this.baseUrl + "/short")
            .pipe(map(function (data) { return data.items; }));
        this.regions.subscribe(function (next) {
            localStorage[CACHE_KEY] = JSON.stringify(next);
        });
        this.regions = this.regions.pipe(startWith(JSON.parse(localStorage[CACHE_KEY] || '[]')));
        return this.regions;
    };
    RegionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegionService_Factory() { return new RegionService(i0.ɵɵinject(i1.HttpClient)); }, token: RegionService, providedIn: "root" });
    return RegionService;
}());
export { RegionService };
