var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ElementRef, EventEmitter, Renderer2, OnDestroy } from '@angular/core';
var TCModalComponent = /** @class */ (function () {
    function TCModalComponent(renderer, elementRef) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.defaultOtions = {
            height: 'auto',
            width: 500,
            closeButton: false,
            overlay: true,
            overlayClose: true
        };
        this.close = new EventEmitter();
    }
    TCModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.options = __assign({}, this.defaultOtions, this.options);
        if (typeof this.options.height === 'number') {
            this.options.height = this.options.height + 'px';
        }
        if (typeof this.options.width === 'number') {
            this.options.width = this.options.width + 'px';
        }
        this.getStringData(this.header, 'title');
        this.getStringData(this.body, 'bodyText');
        this.getStringData(this.footer, 'footerText');
        var elem = this.elementRef.nativeElement;
        setTimeout(function () {
            _this.renderer.addClass(elem, _this.opened ? 'opened' : 'closed');
        });
    };
    TCModalComponent.prototype.ngOnDestroy = function () {
    };
    TCModalComponent.prototype.getStringData = function (data, name) {
        this[name] = (typeof data === 'string') ? data : null;
    };
    TCModalComponent.prototype.hideModal = function () {
        var elem = this.elementRef.nativeElement;
        this.renderer.removeClass(elem, 'opened');
    };
    TCModalComponent.prototype.closeWindow = function (bool) {
        if (bool) {
            this.close.emit(true);
        }
    };
    return TCModalComponent;
}());
export { TCModalComponent };
