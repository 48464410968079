<div class="col-sm-8">
    <ng-container *ngIf="qualite">
        <form  [formGroup]="qualiteForm"  novalidate (ngSubmit)="onSubmit()">

            <tc-card >
                <tc-form-group>
                    <tc-form-label>{{Columnslabels.code}} </tc-form-label>
                    <tc-input type="text" [placeholder]="''" formControlName="code" >
                    </tc-input>
                    <ng-container *ngIf="qualiteForm.get('code').invalid && (qualiteForm.get('code').touched || qualiteForm.get('code').dirty)">
                        <span @fadeIn class="error" *ngIf="qualiteForm.get('code').getError('required')">Veuillez saisir le code de la qualité</span>
                    </ng-container>
                </tc-form-group>

                <tc-form-group>
                    <tc-form-label>{{Columnslabels.nom}} </tc-form-label>
                    <tc-input type="text" [placeholder]="'Nom '" formControlName="nom" >
                    </tc-input>
                    <ng-container *ngIf="qualiteForm.get('nom').invalid && (qualiteForm.get('nom').touched || qualiteForm.get('nom').dirty)">
                        <span @fadeIn class="error" *ngIf="qualiteForm.get('nom').getError('required')">Veuillez saisir le nom de la qualité</span>
                        <span @fadeIn class="error" *ngIf="qualiteForm.get('nom').getError('min')">Le nom doit avoir plus de 2 caractères</span>
                    </ng-container>
                </tc-form-group>

                <tc-form-group>
                    <tc-form-label>{{Columnslabels.coefficient}} </tc-form-label>
                    <tc-input type="text" [placeholder]="''" formControlName="coefficient" >
                    </tc-input>
                </tc-form-group>

                <tc-form-group>
                    <tc-checkbox    formControlName="actif" >Actif</tc-checkbox>
                </tc-form-group>

                <span *ngIf="!qualiteForm.valid && qualiteForm.touched" class="help-block">Veuillez entrer des données valides !</span>
                <button tc-button type="submit" [block]="true" >
                    Valider
                </button>


            </tc-card>
        </form>
    </ng-container>
</div>
