import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ActionService = /** @class */ (function () {
    function ActionService(http) {
        this.http = http;
        this.baseUrl = '/api/notifications';
    }
    ActionService.prototype.notifications = function (reseauId) {
        return this.http.get("" + this.baseUrl, {
            params: new HttpParams()
                .set('reseauId', reseauId.toString())
        });
    };
    ActionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActionService_Factory() { return new ActionService(i0.ɵɵinject(i1.HttpClient)); }, token: ActionService, providedIn: "root" });
    return ActionService;
}());
export { ActionService };
