import { Component, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import {BasePageComponent} from '../../base-page';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {DepotService} from '../depot/depot.service';
import {Depot} from '../models/depot';
export interface IDepotPosition {
  id: number;
  nom: string;
  longitude: number;
  latitude: number;
}
@Component({
  selector: 'tc-depots-maps',
  templateUrl: './depots-maps.component.html',
  styleUrls: ['./depots-maps.component.scss']
})

export class PageDepotMapsComponent extends BasePageComponent implements OnInit, OnDestroy {
	lat: number;
	lng: number;
    depots: any[];
    depotsAdapter: IDepotPosition[] = [];
  constructor(
    store: Store<IAppState>,
    httpSv: HttpService, private depotService: DepotService
  ) {
    super(store, httpSv);

    this.pageData = {
      title: 'Carte des dépôts',
      loaded: true,
      breadcrumbs: [
        {
          title: 'Tableau de bord',
          route: 'dashboard'
        }
      ],
      fullFilled: true
    };
		this.lng = -2.9197048;
		this.lat = 12.5324634;
	}
  getDepot (jsonValue): Depot {
    return Depot.fromJSON(jsonValue) ;
  }
  onDepotsPromise() {
    for (const depot of this.depots) {
      if ((depot.longitude !== 0 ) && (depot.latitude !== 0 )) {
        this.depotsAdapter.push(
            {id : depot.id.toString(), nom : depot.nom, longitude: depot.longitude,
              latitude: depot.latitude}
        );
      }

    }
  }
  ngOnInit() {
    super.ngOnInit();
    this.getData(this.depotService.getAllURL(), 'depots', 'onDepotsPromise');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
