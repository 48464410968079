var AlerteEdit = /** @class */ (function () {
    function AlerteEdit() {
        this.actif = true;
        this.valide = false;
        this.produits = [];
        this.groupes = [];
        this.depots = [];
        this.marches = [];
    }
    AlerteEdit.fromJSON = function (json) {
        var result = new AlerteEdit();
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    AlerteEdit.prototype.toString = function () {
        return this.libelle;
    };
    Object.defineProperty(AlerteEdit.prototype, "produitsText", {
        get: function () {
            var rval = '';
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    rval += produit.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlerteEdit.prototype, "selectedProducts", {
        get: function () {
            var rval = [];
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    rval.push(produit);
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlerteEdit.prototype, "selectedMarches", {
        get: function () {
            var rval = [];
            if (this.marches != null) {
                for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval.push(marche);
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlerteEdit.prototype, "selectedDepots", {
        get: function () {
            var rval = [];
            if (this.depots != null) {
                for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval.push(depot);
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlerteEdit.prototype, "selectedGroupes", {
        get: function () {
            var rval = [];
            if (this.groupes != null) {
                for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
                    var groupe = _a[_i];
                    rval.push(groupe);
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    return AlerteEdit;
}());
export { AlerteEdit };
