var ActeurEdit = /** @class */ (function () {
    function ActeurEdit() {
        this.enabled = true;
        this.groupes = [];
        this.marcheSuivi = [];
        this.marchePreference = [];
    }
    Object.defineProperty(ActeurEdit.prototype, "selectedProducts", {
        get: function () {
            var rval = [];
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    rval.push(produit.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActeurEdit.prototype, "selectedGroups", {
        get: function () {
            var rval = [];
            if (this.groupes != null) {
                for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
                    var groupe = _a[_i];
                    rval.push(groupe.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActeurEdit.prototype, "selectedMarchesSuivis", {
        get: function () {
            var rval = [];
            if (this.marcheSuivi != null) {
                for (var _i = 0, _a = this.marcheSuivi; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval.push(marche.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActeurEdit.prototype, "selectedMarchesPreference", {
        get: function () {
            var rval = [];
            if (this.marchePreference != null) {
                for (var _i = 0, _a = this.marchePreference; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval.push(marche.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActeurEdit.prototype, "selectedDepotsSuivis", {
        get: function () {
            var rval = [];
            if (this.depotSuivi != null) {
                for (var _i = 0, _a = this.depotSuivi; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval.push(depot.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActeurEdit.prototype, "selectedDepotsPreference", {
        get: function () {
            var rval = [];
            if (this.depotPreference != null) {
                for (var _i = 0, _a = this.depotPreference; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval.push(depot.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActeurEdit.prototype, "flatSelectedGroups", {
        get: function () {
            return this.selectedGroups.join(',');
        },
        enumerable: true,
        configurable: true
    });
    ActeurEdit.fromJSON = function (json) {
        var result = new ActeurEdit();
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    Object.defineProperty(ActeurEdit.prototype, "getFullName", {
        get: function () {
            return (this.nom + ' ' + this.prenom + ' (' + this.mobilePhone + ')');
        },
        enumerable: true,
        configurable: true
    });
    ActeurEdit.prototype.toString = function () {
        return this.nomComplet;
    };
    return ActeurEdit;
}());
export { ActeurEdit };
