<div class="row" >
            <tc-card [title]="''">

                    <div class="row">
                        <button tc-button (click)="downloadFile()">Télécharger l'application Android du SIMA</button>
                    </div>

            </tc-card>

</div>

