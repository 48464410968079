import {Component, OnDestroy, OnInit} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';
import {fadeIn} from '../../../animations/form-error';
import {SMSPush} from '../models/SMSPush';
import {GroupeService} from '../groupe/groupe.service';
import {UserService} from '../administrateur/user.service';
import {Commune} from '../models/commune';
import {IOption} from '../../../ui/interfaces/option';
import {Groupe} from '../models/groupe';
import {Acteur} from '../models/acteur';
import {PushSMSService} from '../push-sms/pushSMS.service';

@Component({
  selector: 'tc-push-sms-groupe',
    templateUrl: './push-sms-membres-groupes.component.html',
    styleUrls: ['./push-sms-membres-groupes.component.scss'],
    animations: [fadeIn]
})

export class PushSmsMembresGroupesComponent extends BasePageComponent implements OnInit, OnDestroy {
  pushSMSForm: FormGroup;
  pushSMS: SMSPush;
  acteurs: Acteur[];
  groupes: any[] = [];
  groupesAdapter: IOption[] = [];
  destinatairesAdapter: IOption[] = [];
  Columnslabels =
        {
            groupes: 'Groupes',
            destinataires: 'Destinataires',
            message: 'Message'

        };
    initReactiveForm() {

        this.pushSMSForm = this.fb.group({
                groupes: ['', Validators.required],
                destinataires: ['', Validators.required],
                message: ['', [Validators.required, Validators.minLength(2)]]
            }
        ) ;

    }

    envoyerSMS() {
        this.pushSMS.groupes =  this.pushSMSForm.value.groupes;
        this.pushSMS.destinataires =  this.pushSMSForm.value.destinataires;
        this.pushSMS.message =  this.pushSMSForm.value.message;
        this.pushSMSService.send(this.pushSMS) .subscribe(
            data => {
                this.toastr.success(' SMS envoyé avec succès ', '',
                    {timeOut: 2000});
                this.pushSMSForm.reset();
            },
            error => console.log('Error', error));
    }



    setLoaded(during: number = 0) {
        super.setLoaded(during);

    }


  constructor(store: Store<IAppState>,
              httpSv: HttpService,
              private route: ActivatedRoute,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService, private administrateurService: UserService,
              private pushSMSService: PushSMSService, private groupeService: GroupeService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }
    getUserObject (jsonValue): Acteur {
        return Acteur.fromJSON(jsonValue) ;
    }
  onChangeGroupes(event) {
      this.acteurs = [];
      this.destinatairesAdapter = [];
      this.groupeService.mesMembres(event).subscribe(tree => {
              this.acteurs = tree;
          this.destinatairesAdapter = [];
          for (const acteur of this.acteurs) {
              // tslint:disable-next-line:max-line-length
              this.destinatairesAdapter.push({'value': acteur.id.toString(), 'selected': true, 'label': this.getUserObject(acteur).getFullName});
          }
          }
      );
   }
    onGroupesPromise() {
        this.groupesAdapter = [];
        for (const groupe of this.groupes) {
            this.groupesAdapter.push({'value' : groupe.id, 'label' : groupe.nom});
             this.groupeService.mesMembres(groupe.id).subscribe(tree => {
                    this.acteurs = tree;
                    this.destinatairesAdapter = [];
                    for (const acteur of this.acteurs) {
                        // tslint:disable-next-line:max-line-length
                        this.destinatairesAdapter.push({'value': acteur.id.toString(), 'selected': true, 'label': this.getUserObject(acteur).getFullName});
                    }
                }
            );
        }

    }
  ngOnInit() {
      super.ngOnInit();
      this.pushSMS = new SMSPush();
      this.pageData.title = this.getTitle();
      this.groupeService.getCached().subscribe(data => {
              this.groupes = data;
              this.onGroupesPromise();
          }
      );
      this.initReactiveForm();

   }
    getTitle(): string {
        return 'Envoyer des sms aux membres des groupes';
      }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

}
