import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ValiderEtalonnageService = /** @class */ (function () {
    function ValiderEtalonnageService(http) {
        this.http = http;
        this.baseUrl = '/api/etalonnage';
    }
    ValiderEtalonnageService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    ValiderEtalonnageService.prototype.getAllNonValides = function () {
        return this.http.get(this.baseUrl + "/etalonnagesNonValides");
    };
    ValiderEtalonnageService.prototype.getAllValides = function () {
        return this.http.get(this.baseUrl + "/etalonnagesValides");
    };
    ValiderEtalonnageService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    ValiderEtalonnageService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    ValiderEtalonnageService.prototype.create = function (obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj);
    };
    ValiderEtalonnageService.prototype.devalider = function (obj) {
        return this.http.post(this.baseUrl + "/devalider", obj);
    };
    ValiderEtalonnageService.prototype.valider = function (obj) {
        return this.http.post(this.baseUrl + "/valider", obj);
    };
    ValiderEtalonnageService.prototype.update = function (id, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value);
    };
    ValiderEtalonnageService.prototype.rejeter = function (obj) {
        return this.http.post(this.baseUrl + "/rejeter", obj);
    };
    ValiderEtalonnageService.prototype.abandonner = function (obj) {
        return this.http.post(this.baseUrl + "/abandonner", obj);
    };
    ValiderEtalonnageService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/" + id, { responseType: 'text' });
    };
    ValiderEtalonnageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValiderEtalonnageService_Factory() { return new ValiderEtalonnageService(i0.ɵɵinject(i1.HttpClient)); }, token: ValiderEtalonnageService, providedIn: "root" });
    return ValiderEtalonnageService;
}());
export { ValiderEtalonnageService };
