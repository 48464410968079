<h1>
  {{title}}
</h1>
 
<div mat-dialog-content>
  <p class="paragraph"> {{message}}</p>
</div>
   <div class="row">
	   <div class="col-4 col-md-4">

	   </div>
	   <div class="buttons-list">
		   <button tc-button
				   [view]="'primary'" [size]="'sm'" (click)="onDismiss()">
			   Non
		   </button>

		   <button tc-button
				   [view]="'primary'" [size]="'sm'" (click)="onConfirm()">
			   Oui
		   </button>
	   </div>


	  <div class="col-4 col-md-4">

	   </div>			
 </div>
 
