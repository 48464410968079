import { OnInit } from '@angular/core';
var TCBadgeComponent = /** @class */ (function () {
    function TCBadgeComponent() {
        this.view = 'default';
        this.size = 'default';
    }
    Object.defineProperty(TCBadgeComponent.prototype, "xs", {
        get: function () { return this.size === 'xs'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "sm", {
        get: function () { return this.size === 'sm'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "lg", {
        get: function () { return this.size === 'lg'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "typeDefault", {
        get: function () { return this.view === 'default'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "typeAccent", {
        get: function () { return this.view === 'accent'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "typeSuccess", {
        get: function () { return this.view === 'success'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "typeInfo", {
        get: function () { return this.view === 'info'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "typeWarning", {
        get: function () { return this.view === 'warning'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "typeGradient", {
        get: function () { return this.view === 'gradient'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCBadgeComponent.prototype, "typeError", {
        get: function () { return this.view === 'error'; },
        enumerable: true,
        configurable: true
    });
    TCBadgeComponent.prototype.ngOnInit = function () { };
    return TCBadgeComponent;
}());
export { TCBadgeComponent };
