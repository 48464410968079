import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AlerteStockService = /** @class */ (function () {
    function AlerteStockService(http) {
        this.http = http;
        this.baseUrl = '/api/alerte';
    }
    AlerteStockService.prototype.findAll = function (filter, alerteType, sortField, sortOrder, offset, pageSize) {
        if (filter === void 0) { filter = ''; }
        if (alerteType === void 0) { alerteType = ''; }
        if (sortField === void 0) { sortField = 'libelle'; }
        if (sortOrder === void 0) { sortOrder = 'asc'; }
        if (offset === void 0) { offset = 0; }
        if (pageSize === void 0) { pageSize = 10; }
        return this.http.get(this.baseUrl + "/pages", {
            params: new HttpParams()
                .set('alerteType', alerteType.toUpperCase())
                .set('filter', filter)
                .set('sort', sortField)
                .set('sortOrder', sortOrder)
                .set('offset', offset.toString())
                .set('max', pageSize.toString())
        });
    };
    AlerteStockService.prototype.findCount = function (alerteType) {
        return this.http.get(this.baseUrl + "/count", {
            params: new HttpParams()
                .set('alerteType', alerteType.toUpperCase())
        })
            .pipe(map(function (res) { return res['total']; }));
    };
    AlerteStockService.prototype.get = function (id) {
        return this.http.get(this.baseUrl + "/" + id);
    };
    AlerteStockService.prototype.getAllURL = function () {
        return "" + this.baseUrl;
    };
    AlerteStockService.prototype.getAllByReseauURL = function (id) {
        return this.baseUrl + "/par_reseau/" + id;
    };
    AlerteStockService.prototype.getURL = function (id) {
        return this.baseUrl + "/" + id;
    };
    AlerteStockService.prototype.create = function (alerteType, obj) {
        return this.http.post(this.baseUrl + "/enregistrer", obj, {
            params: new HttpParams()
                .set('alerteType', alerteType.toUpperCase())
        });
    };
    AlerteStockService.prototype.update = function (id, alerteType, value) {
        return this.http.put(this.baseUrl + "/modifier/" + id, value, {
            params: new HttpParams()
                .set('alerteType', alerteType.toUpperCase())
        });
    };
    AlerteStockService.prototype.delete = function (id) {
        return this.http.post(this.baseUrl + "/supprimer", 'delete', {
            params: new HttpParams()
                .set('id', id.toString())
        });
    };
    AlerteStockService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlerteStockService_Factory() { return new AlerteStockService(i0.ɵɵinject(i1.HttpClient)); }, token: AlerteStockService, providedIn: "root" });
    return AlerteStockService;
}());
export { AlerteStockService };
