<nav class="main-menu-wrap">
  <ul class="menu-ul">
    <ng-container *ngFor="let item of menuItems">
      <li 
        class="menu-item"
        [ngStyle]="getAccentBg((item.active || isActive([orientation, item.routing])) && !item.sub)" 
        (mouseenter)="item.hovered = true"
        (mouseleave)="item.hovered = false"
        [ngClass]="{ 
          'active': (item.active || isActive([orientation, item.routing], item)),
          'has-sub': item.sub,
          'simple': !item.sub,
          'group': item.groupTitle
        }"
      >
        <div *ngIf="item.groupTitle; then group else default"></div>

        <!-- Group title -->
        <ng-template #group>
          <span class="group-title" [ngStyle]="{ 'color': accentColor }">{{ item.title }}</span>
        </ng-template>
        <!-- end Group title -->

        <!-- Default item -->
        <ng-template #default>
          <div *ngIf="!item.sub; then simple else sub"></div>

          <!-- Single item -->
          <ng-template #simple>
            <a 
              href="#"
              class="item-link"
              (click)="toggle($event, item, this); closeAll(); closeSidebar()"
            >
              <tc-icon
                class="link-icon"
                [iconClass]="item.icon.class"
                [tcFontSize]="'1.2em'"
                [tcColor]="item.icon.color"
                [tcBgColor]="item.icon.bg"
                *ngIf="item.icon && item.icon.class"
              ></tc-icon>

              <span class="link-text">{{ item.title }}</span>

              <tc-badge 
                class="link-badge"
                [size]="'sm'"
                [tcColor]="item.badge.color"
                [tcBgColor]="item.badge.bg"
                *ngIf="item.badge && item.badge.text"
              >
                {{ item.badge.text }}
              </tc-badge>
            </a>
          </ng-template>
          <!-- end Single item -->

          <!-- Item with sub -->
          <ng-template #sub>
            <a href="#" class="item-link" (click)="toggle($event, item, this)" [ngStyle]="getItemColor(item)">
              <tc-icon
                class="link-icon"
                [tcFontSize]="'1.2em'"
                [iconClass]="item.icon.class"
                [tcColor]="item.icon.color"
                [ngStyle]="getItemColor(item)"
                [tcBgColor]="item.icon.bg"
                *ngIf="item.icon && item.icon.class"
              ></tc-icon>

              <span class="link-text">{{ item.title }}</span>

              <tc-badge 
                class="link-badge"
                [size]="'sm'"
                [tcColor]="item.badge.color"
                [tcBgColor]="item.badge.bg"
                *ngIf="item.badge && item.badge.text"
              >
                {{ item.badge.text }}
              </tc-badge>

              <span class="link-caret {{ caret }}" *ngIf="item.sub" [ngStyle]="getItemColor(item)"></span>
            </a>

          </ng-template>
        </ng-template>
        <!-- Menu sub -->
        <ul 
          class="sub"
          *ngIf="!item.groupTitle && item.sub"
          [@subMenu]="subState(item, isActive([orientation, item.routing], item))"
        >
          <li 
            class="menu-item"
            *ngFor="let itemSub of item.sub"
            (mouseenter)="itemSub.hovered = true"
            (mouseleave)="itemSub.hovered = false"
            [ngClass]="{ 'active': (itemSub.active || isActive([orientation, itemSub.routing])) }"
          >
            <a
              href="#"
              class="item-link"
              (click)="
                $event.preventDefault();
                changeRoute(itemSub.routing, !isActive([orientation, itemSub.routing]), itemSub.layout ? itemSub.layout : orientation);
                closeSidebar();
                itemSub.active = true
              "
            >

              <span class="link-text">{{ itemSub.title }}</span>
            </a>
          </li>
        </ul>
        <!-- End of menu sub -->
      </li>
      <!-- end Item with sub -->
    </ng-container>
    <!-- end Default item -->
  </ul>
</nav>
