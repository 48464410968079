var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { HttpService } from '../../services/http/http.service';
import { BaseLayoutComponent } from '../base-layout';
import { Router } from '@angular/router';
import { TCModalService } from '../../ui/services/modal/modal.service';
var HorizontalLayoutComponent = /** @class */ (function (_super) {
    __extends(HorizontalLayoutComponent, _super);
    function HorizontalLayoutComponent(store, fb, httpSv, router, elRef, modal) {
        return _super.call(this, store, fb, httpSv, router, elRef, modal) || this;
    }
    HorizontalLayoutComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    HorizontalLayoutComponent.prototype.getNavbarStyles = function () {
        return {
            'background-image': "linear-gradient(188deg, " + this.appSettings.sidebarBg + ", " + this.appSettings.sidebarBg2 + " 65%)",
            'color': this.appSettings.sidebarColor
        };
    };
    return HorizontalLayoutComponent;
}(BaseLayoutComponent));
export { HorizontalLayoutComponent };
