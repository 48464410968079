
<ng-container *ngIf="listOfAllDataPrice.length>0 ">
    <tc-card>
        <div class="row">
            <div class="col-sm-4 ">
                <div class="pull-left">
                    <button tc-button (click)="devalider()">Dé-Valider</button>
                </div>
            </div>
            <div class="col-sm-8 ">

            </div>

        </div>

    </tc-card>
</ng-container>

<tc-card>
<ng-container *ngIf="listOfAllDataPrice && listOfAllDataPrice.length>0 ">
    <span *ngIf="numberOfChecked"> {{ numberOfChecked }} éléments sélectionnés</span>

<nz-table
    #rowSelectionTable
    nzShowPagination
    nzShowSizeChanger
    [nzPageSize]="300"
    [nzData]="listOfAllDataPrice"
    (nzCurrentPageDataChange)="currentPageDataChange($event)"
>
    <thead>
    <tr>
        <th
                nzShowCheckbox
                [(nzChecked)]="isAllDisplayDataChecked"
                [nzIndeterminate]="isIndeterminate"
                (nzCheckedChange)="checkAll($event)"
        ></th>
        <th nzShowExpand></th>
        <th> PRIX VALIDES </th>
        <th> ENQUETEUR</th>
        <th> RESEAU</th>
    </tr>
    </thead>
    <tbody>
       <ng-template ngFor let-data [ngForOf]="rowSelectionTable.data">
        <tr>
            <td
                    nzShowCheckbox
                    [(nzChecked)]="mapOfCheckedId[data.id]"
                    (nzCheckedChange)="refreshStatus()"
            ></td>
                <td nzShowExpand [(nzExpand)]="mapOfExpandData[data.id]">
                </td>
                <td>
                   <p> Type Prix : <strong> {{ data.typePrix }}</strong>  </p>
                   <p> Date Saisie : <strong> {{ data.date }}</strong>  </p>
                   <p> Marché : <strong> {{ data.nom_marche }}</strong> </p>
                   <p> Produit : <strong> {{ data.nom_produit }}</strong> </p>
                   <p> Prix : <strong> {{ data.prix }} / {{ data.mesure }} </strong> </p>

                </td>
               <td>
                   <p> <strong> {{ data.enqueteur }} </strong></p>
                   <p> <small> {{ data.numeroEnqueteur }}</small> </p>

               </td>
            <td>
                <p> <strong> {{ data.reseau }} </strong></p>
            </td>
           </tr>
         <tr [nzExpand]="mapOfExpandData[data.id]" colspan="3">
                 <td></td>
                 <td>
                    <p> Etat Approvionnement : <strong> {{ data.commentaires }} </strong> </p>
                    <p> Observations : <strong> {{ data.note_marche }} </strong> </p>
                    <p> Longitude : <strong> {{ data.longitude }} </strong> </p>
                    <p> Latitude : <strong> {{ data.latitude }} </strong> </p>
                </td>
         </tr>
       </ng-template>
    </tbody>
</nz-table>
</ng-container>
</tc-card>

<ng-container *ngIf="listOfAllDataPrice.length>0 ">
    <tc-card>
        <div class="row">
            <div class="col-sm-4 ">
                <div class="pull-left">
                    <button tc-button (click)="devalider()">Dé-Valider</button>
                </div>
            </div>
            <div class="col-sm-8 ">

            </div>

        </div>

    </tc-card>
</ng-container>


<tc-card>
    <div class="buttons-list">
        <button  (click)="exportByExcel()"><i class="fa fa-file-excel-o" aria-hidden="false" style="font-size:48px;color:#C7B54A">
        </i></button>
    </div>
</tc-card>
