
import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable, BehaviorSubject, of} from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators';
import {Race} from '../models/race';
import {RaceService} from './race.service';


export class RacesDatasource implements DataSource<Race> {

    private VarietesSubject = new BehaviorSubject<Race[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    private count = new BehaviorSubject<Race[]>([]);

    public loading$ = this.loadingSubject.asObservable();

    constructor(private VarieteService: RaceService) {

    }

    loadRaces(filter: string,
                sortField: string,
                sortDirection: string,
                pageIndex: number,
                pageSize: number) {
        this.loadingSubject.next(true);

        this.VarieteService.findAll(
         filter,
        sortField,
        sortDirection,
        pageIndex,
        pageSize
        ).pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(data => this.VarietesSubject.next(data)
            );

    }

    connect(collectionViewer: CollectionViewer): Observable<Race[]> {
        return this.VarietesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.VarietesSubject.complete();
        this.loadingSubject.complete();
    }

}

