import {Region} from './region';

export class Province {
	id: number;
	code: string;
	nom: string;
	region: Region;
	static fromJSON(json: any): Province {
		const result = new Province();
		if (json != null) {
			for (const key in json) {
				result[key] = json[key];
			}
		}

		return result;
	}
	static listFromJSON(json: any): any[] {
		const rval: any[] = [];
		const copyJson = JSON.parse(JSON.stringify(json));
		for (const key in copyJson) {
			if (copyJson.hasOwnProperty(key)) {
				const jsonObject = copyJson[key];
				const province = Province.fromJSON(jsonObject);
				if (province.region) {
					jsonObject.region = province.region.nom;
				}

				rval.push(jsonObject);
			}
		}
		return rval;
	}
	get nomRegion() {
		if (this.region != null) {
			return this.region.nom;
		}
		return '--';
	}


	constructor() {
	//	this.region = new Region();
	}
}
