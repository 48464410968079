var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { MarcheService } from '../marche/marche.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { ValiderEtalonnageService } from './validerEtalonnage.service';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { ExcelService } from '../../../services/sharedServices/excel-service.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
var ValiderEtalonnageComponent = /** @class */ (function (_super) {
    __extends(ValiderEtalonnageComponent, _super);
    function ValiderEtalonnageComponent(store, httpSv, marcheService, route, authService, router, toastr, fb, i18n, validerEtalonnageService, modal) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.marcheService = marcheService;
        _this.route = route;
        _this.authService = authService;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.validerEtalonnageService = validerEtalonnageService;
        _this.modal = modal;
        _this.dateValidation = new Date();
        _this.causeRejet = '';
        _this.outData = [];
        /******************/
        _this.isAllDisplayDataChecked = false;
        _this.isOperating = false;
        _this.isIndeterminate = false;
        _this.listOfDisplayData = [];
        _this.listOfAllDataEtalonnage = [];
        _this.mapOfCheckedId = {};
        _this.numberOfChecked = 0;
        _this.pageData = {
            title: '',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        return _this;
    }
    /******************************/
    ValiderEtalonnageComponent.prototype.valider = function () {
        var _this = this;
        this.outData = [];
        this.listOfAllDataEtalonnage.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                etalonnages: this.outData
            } };
        if (this.outData.length > 0) {
            this.validerEtalonnageService.valider(this.data).subscribe(function (data) {
                _this.toastr.success('Validation réussie des étalonnages ', '', { timeOut: 2000 });
                _this.loadEtalonnage();
            }, function (error) { return console.log(error); });
        }
        else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    };
    ValiderEtalonnageComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadEtalonnage();
    };
    ValiderEtalonnageComponent.prototype.closeModal = function () {
        this.causeRejet = '';
        this.modal.close();
    };
    ValiderEtalonnageComponent.prototype.validerRejet = function () {
        if (this.causeRejet > '') {
            this.modal.close();
            this.rejeter();
        }
        else {
            this.toastr.error('Vous devez saisir une cause de rejet ');
        }
    };
    ValiderEtalonnageComponent.prototype.rejeter = function () {
        var _this = this;
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                etalonnages: this.outData,
                causeRejet: this.causeRejet
            } };
        this.validerEtalonnageService.rejeter(this.data).subscribe(function (data) {
            _this.toastr.success('rejet réussi des lignes d\'étalonnage  sélectionnés', '', { timeOut: 2000 });
            _this.loadEtalonnage();
        }, function (error) { return console.log(error); });
    };
    ValiderEtalonnageComponent.prototype.openModal = function (body, header, footer, options) {
        var _this = this;
        if (header === void 0) { header = null; }
        if (footer === void 0) { footer = null; }
        if (options === void 0) { options = null; }
        this.outData = [];
        this.listOfAllDataEtalonnage.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        }
        else {
            this.toastr.info('Vous devez sélectionner au moins un ligne d\'étalonnage ');
        }
    };
    ValiderEtalonnageComponent.prototype.loadEtalonnage = function () {
        var _this = this;
        this.listOfAllDataEtalonnage = [];
        this.validerEtalonnageService.getAllNonValides().subscribe(function (data) {
            _this.etalonnageAValider = data;
            _this.etalonnageAValider.forEach(function (etalonnage, index, array) {
                _this.listOfAllDataEtalonnage.push({
                    id: etalonnage.id,
                    uml: etalonnage.uml,
                    marche: etalonnage.marche,
                    ul: etalonnage.ul,
                    valeur: etalonnage.valeur
                });
            });
        });
        // this.mapOfCheckedId = {};
        // this.refreshStatus();
    };
    ValiderEtalonnageComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ValiderEtalonnageComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    };
    ValiderEtalonnageComponent.prototype.refreshStatus = function () {
        // this.isAllDisplayDataChecked = this.listOfDisplayData
        //     .every(item => this.mapOfCheckedId[item.id]);
        // this.isIndeterminate =
        //     this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
        //     !this.isAllDisplayDataChecked;
        // this.numberOfChecked = this.listOfAllDataEtalonnage.filter(item => this.mapOfCheckedId[item.id]).length;
    };
    ValiderEtalonnageComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfDisplayData.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = value); });
        this.refreshStatus();
    };
    // operateData(): void {
    //     this.isOperating = true;
    //     setTimeout(() => {
    //         this.listOfAllDataEtalonnage.forEach(item => (this.mapOfCheckedId[item.id] = false));
    //         this.refreshStatus();
    //         this.isOperating = false;
    //     }, 1000);
    // }
    ValiderEtalonnageComponent.prototype.exportByExcel = function () {
        ExcelService.exportAsExcelFile(this.etalonnageAValider, 'etalonnage_a_valider');
    };
    return ValiderEtalonnageComponent;
}(BasePageComponent));
export { ValiderEtalonnageComponent };
