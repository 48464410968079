import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {MarcheService} from '../marche/marche.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';

import {ValiderStockService} from './validerStock.service';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';
import {Content} from '../../../ui/interfaces/modal';
import {TCModalService} from '../../../ui/services/modal/modal.service';
import {IStock} from '../liste-stock-valides/liste-stock-valides.component';


@Component({
  selector: 'tc-valider-stock',
    templateUrl: './valider-stock.component.html',
    styleUrls: ['./valider-stock.component.scss'],
    animations: [fadeIn]
})

export class ValiderStockComponent extends BasePageComponent implements OnInit, OnDestroy {

  dateValidation = new Date();
  data: any;
  causeRejet = '';
  stocksAValider: IStock[];
    outData: any[] = [];

    /******************/

    isAllDisplayDataChecked = false;
    isOperating = false;
    isIndeterminate = false;
    listOfDisplayData: IStock[] = [];
    listOfAllDataStock: IStock[] = [];
    mapOfCheckedId: { [key: string]: boolean } = {};
    numberOfChecked = 0;
    /******************************/
    valider() {
        this.outData = [];
        this.listOfAllDataStock.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                console.log('item', item);
            this.outData.push(item);
            }
        });

        this.data = {data: {
            dateValidation: this.dateValidation.toLocaleDateString('en-US'),
            stocks: this.outData
        }};
        if (this.outData.length > 0) {
        this.validerStockService.valider(this.data) .subscribe(
            data => {

                this.toastr.success('Validation réussie des stocks ', '',
                    {timeOut: 2000});
                this.loadStock();
            },
            error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }
  constructor(store: Store<IAppState>,
              httpSv: HttpService, private marcheService: MarcheService,
              private route: ActivatedRoute, private authService: AuthenticationService,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService,
              private validerStockService: ValiderStockService, private modal: TCModalService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadStock();
  }
    closeModal() {
        this.causeRejet = '';
        this.modal.close();
    }
    validerRejet() {

        if (this.causeRejet > '') {
            this.modal.close();
            this.rejeter();
        } else {
            this.toastr.error('Vous devez saisir une cause de rejet ');
        }
    }
    rejeter() {

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                stocks: this.outData,
                causeRejet: this.causeRejet
            }};
        this.validerStockService.rejeter(this.data) .subscribe(
            data => {

                this.toastr.success('rejet réussi des lignes de stock  sélectionnés', '',
                    {timeOut: 2000});
                this.loadStock();
            },
            error => console.log(error));

    }
    openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
        this.outData = [];
        this.listOfAllDataStock.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        } else {
            this.toastr.info('Vous devez sélectionner au moins un ligne de stock ');
        }
    }
    private loadStock() {
        this.listOfAllDataStock = [];
        const reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerStockService.getAllByReseau(reseauId.toString()).subscribe(data => {
                this.stocksAValider = data;
                this.stocksAValider.forEach((stock, index, array) => {

                    this.listOfAllDataStock.push({
                        id: stock.id,
                        date: stock.date,
                        nom_produit: stock.nom_produit,
                        stock: stock.stock,
                        nom_depot: stock.nom_depot,
                        reseau: stock.reseau,
                        mesure: stock.mesure
                    });
                });
            }
        );
        this.mapOfCheckedId = {};
        this.refreshStatus();

    }

    ngOnDestroy() {
    super.ngOnDestroy();
  }

    currentPageDataChange($event: IStock[]): void {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    }

    refreshStatus(): void {
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(item => this.mapOfCheckedId[item.id]);
        this.isIndeterminate =
            this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
            !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataStock.filter(item => this.mapOfCheckedId[item.id]).length;
    }

    checkAll(value: boolean): void {
        this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
        this.refreshStatus();
    }

    operateData(): void {
        this.isOperating = true;
        setTimeout(() => {
            this.listOfAllDataStock.forEach(item => (this.mapOfCheckedId[item.id] = false));
            this.refreshStatus();
            this.isOperating = false;
        }, 1000);
    }
    exportByExcel() {
        ExcelService.exportAsExcelFile(this.stocksAValider, 'stock_a_valider');
    }

}
