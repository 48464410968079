
export class ActeurEdit {

	constructor() {
        this.enabled = true;
		this.groupes = [];
		this.marcheSuivi = [];
		this.marchePreference = [];
	}
	get selectedProducts() {
		const rval = [];
		if (this.produits != null) {
			for (const produit of this.produits) {
				rval.push(produit.toString());
			}
		}
		return rval;
	}
	get selectedGroups() {
		const rval = [];
		if (this.groupes != null) {
			for (const groupe of this.groupes) {
				rval.push(groupe.toString());
			}
		}
		return rval;
	}
	get selectedMarchesSuivis() {
		const rval = [];
		if (this.marcheSuivi != null) {
			for (const marche of this.marcheSuivi) {
				rval.push(marche.toString());
			}
		}
		return rval;
	}
	get selectedMarchesPreference() {
		const rval = [];
		if (this.marchePreference != null) {
			for (const marche of this.marchePreference) {
				rval.push(marche.toString());
			}
		}
		return rval;
	}

    get selectedDepotsSuivis() {
        const rval = [];
        if (this.depotSuivi != null) {
            for (const depot of this.depotSuivi) {
                rval.push(depot.toString());
            }
        }
        return rval;
    }
    get selectedDepotsPreference() {
        const rval = [];
        if (this.depotPreference != null) {
            for (const depot of this.depotPreference) {
                rval.push(depot.toString());
            }
        }
        return rval;
    }
	get flatSelectedGroups() {
		return this.selectedGroups.join(',');
	}
	id: number;
	prenom: string;
	nomComplet: string;
	nomCompletEtReseau: string;
	nom: string;
	email: string;
	mobilePhone: string;
	secondPhone: string;
	password: string;
	genre: string;
	activite: number;
	commune: number;
	groupes: number[];
	role: string;
	enabled: boolean;
	isAdminGroup: boolean;
	reseau: number;
	marcheSuivi: number[];
	marchePreference: number[];
	depotSuivi: number[];
	depotPreference: number[];
	produits: number[];
	communeTab: string;
	static fromJSON(json: any): ActeurEdit {
		const result = new ActeurEdit();
		for (const key in json) {
			result[key] = json[key];
		}
		return result;
	}
	get getFullName(): string {
		return (this.nom + ' ' + this.prenom + ' (' + this.mobilePhone + ')');
	}
	toString() {
		return this.nomComplet  ;
	}

}
