import { OnInit } from '@angular/core';
var TCGradientDirective = /** @class */ (function () {
    function TCGradientDirective() {
    }
    TCGradientDirective.prototype.ngOnInit = function () {
        this.tcGradient.length >= 2 ? this.setGradient(this.tcGradient) : null;
    };
    Object.defineProperty(TCGradientDirective.prototype, "getGradient", {
        get: function () {
            return this.gradient;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCGradientDirective.prototype, "getClass", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    //
    TCGradientDirective.prototype.setGradient = function (colors) {
        if (colors.length <= 2) {
            this.gradient = "linear-gradient(90deg, " + colors[0] + ", " + colors[1] + " 65%)";
        }
        else {
            this.gradient = "linear-gradient(215deg, " + colors[0] + ", " + colors[1] + " 65%, " + colors[2] + ")";
        }
    };
    return TCGradientDirective;
}());
export { TCGradientDirective };
