import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {ISMS} from '../../../ui/interfaces/iSMS';

@Injectable({
  providedIn: 'root'
})
export class SMSService {
	private baseUrl = '/api/sms';
  constructor(private http: HttpClient) {

  }

  // tslint:disable-next-line:max-line-length
  getJournalSMS(dateDebut: string, dateFin: string, operateur: string, alerte: string, reseau: string, destination: string): Observable<ISMS[]> {
    return this.http.get(`${this.baseUrl}/journal`, {
      params: new HttpParams()
          .set('dateDebut', dateDebut)
          .set('dateFin', dateFin)
          .set('operateur', operateur)
          .set('alerte', alerte)
          .set('reseau', reseau)
          .set('destination', destination)
    }) as Observable<ISMS[]>;
  }

}

