import {Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {Produit} from '../../models/produit';
import { FormBuilder,
    FormGroup, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {ProduitService} from '../produit.service';
import {MesureService} from '../../mesure/mesure.service';
import {CategorieService} from '../../categorie/categorie.service';
import {EspeceService} from '../../espece/espece.service';
import {RaceService} from '../../race/race.service';
import {UserService} from '../../administrateur/user.service';
import {AuthenticationService} from '../../../../services/sharedServices/authentication.service';
import {Acteur} from '../../models/acteur';

export interface INoeud {
    value: string;
    key: string;
    title: string;
    isLeaf: boolean;
}

@Component({
    selector: 'tc-produit-create',
    templateUrl: './create-produit.component.html',
    animations: [fadeIn]
})
export class PageCreateProduitComponent extends BasePageComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(store: Store<IAppState>,
                httpSv: HttpService, private produitService: ProduitService,
                private route: ActivatedRoute, private mesureService: MesureService,
                public router: Router, private toastr: ToastrService,
                private especeService: EspeceService, private categorieService: CategorieService,
                private raceService: RaceService, private adminService: UserService, private auth: AuthenticationService,
                private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.produit = new Produit();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
            ]
        };
        this.initReactiveForm();
    }
    produitForm: FormGroup;
    produit: any;
    connectedUser: any;
    pageIndex: number;
    mesures: any[];
    mesuresAdapter: IOption[] = [];
    categories: any[];
    categoriesAdapter: IOption[] = [];
    especes: any[];
    especesAdapter: IOption[] = [];
    races: any[];
    racesAdapter: IOption[] = [];
    changes = false;
    showBetailParams = false;
    selectedId: number;
    Columnslabels =
        {
            code: 'Code Produit',
            label: 'Libellé Produit',
            nomScientifique: 'Nom Scientifique',
            uniteMesure: 'Unité de Mesure',
            categorie: 'Catégorie ',
            espece: 'Espèce',
            race: 'Race'

        };
    static getProduit (jsonValue): Produit {
        return Produit.fromJSON(jsonValue) ;
    }
    static getActeur (jsonValue): Acteur {
        return Acteur.fromJSON(jsonValue) ;
    }
    onMesuresPromise() {
        if (this.mesures) {
            this.mesures.sort((a, b) => ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)));

            for (const mesure of this.mesures) {
                this.mesuresAdapter.push({'value': mesure.id.toString(), 'label': mesure.nom});
            }
        }
        this.setReactiveForm();
    }
    ngAfterViewInit() {
        if (this.auth.currentUserDataObject === null) {
            this.router.navigate(['/vertical/dashboard']);
        } else {
            const selectedId = this.auth.currentUserDataObject.id;
            this.getDataByName(this.adminService.getURL(selectedId), 'connectedUser', 'acteur', 'onConnectedPromise');
        }
    }
    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.produit) {
            this.onCategoriesPromise();
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
       return  this.isEditing() ? ' Modifier un produit ' : ' Créer un produit ' ;
    }
    isEditing(): boolean {
         return (this.produit.id != null);
    }
    onCategoriesPromise() {
        if (this.categories) {
            this.categories.sort((a, b) =>  ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)));

        this.categoriesAdapter = [];
        for (const categorie of this.categories) {
            this.categoriesAdapter.push({'value': categorie.id.toString(), 'label': categorie.nom});
        }
     }

    }
    onEspecesPromise() {
        if (this.especes) {
            this.especes.sort((a, b) =>  ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)));

            this.especesAdapter = [];
            for (const espece of this.especes) {
                this.especesAdapter.push({'value': espece.id.toString(), 'label': espece.nom});
            }
        }

    }

    onRacesPromise() {
        if (this.races) {
            this.races.sort((a, b) =>  ((a.nom > b.nom) ? 1 : ((a.nom < b.nom) ? -1 : 0)));

            this.racesAdapter = [];
            for (const race of this.races) {
                this.racesAdapter.push({'value': race.id.toString(), 'label': race.nom});
            }
        }

    }
    onConnectedPromise() {
        if (this.connectedUser !== undefined) {
            const acteur = PageCreateProduitComponent.getActeur(this.connectedUser);
           this.showBetailParams = acteur.isSIMBetail || acteur.isDGPER ;
        }

    }
   initReactiveForm() {
        this.produitForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                nomScientifique: [''],
                categorie: ['', Validators.required],
                mesure: [''],
                espece: [''],
                race: [''],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        if (this.isEditing()) {
            // tslint:disable-next-line:max-line-length
            const categorieId = (this.produit.categorie != null) ? this.produit.categorie.id.toString() : '';
            const especeId = (this.produit.espece != null) ? this.produit.espece.id.toString() : '';
            const raceId = (this.produit.espece != null) ? this.produit.race.id.toString() : '';
            this.produitForm.patchValue({
            code: this.produit.code,
            nom: this.produit.nom,
            categorie: categorieId,
            espece: especeId,
            race: raceId,
            mesure: this.produit.mesure.id.toString(),
            nomScientifique: this.produit.nomScientifique,
            actif: this.produit.actif
            }
          );
        }
    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.mesureService.getAllURL(), 'mesures', 'onMesuresPromise');
        this.getData(this.categorieService.getAllURL(), 'categories', 'onCategoriesPromise');
        this.getData(this.especeService.getAllURL(), 'especes', 'onEspecesPromise');
        this.getData(this.raceService.getAllURL(), 'races', 'onRacesPromise');
        this.route.params.subscribe(params => {
            if (params['pageIndex']) {
                this.pageIndex = params['pageIndex'];
        }
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.produitService.getURL(this.selectedId), 'produit', 'produit', 'setLoaded');
            } else {
                this.produit = new Produit();
                this.initReactiveForm();
            }
        });
        // detect form changes
        this.produitForm.valueChanges.subscribe(() => {
            this.changes = true;
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-produit']);
    }
    liste() {
        this.router.navigate(['/vertical/list-produit', {pageIndex: this.pageIndex}]);
    }
    mapFormValuesToModel() {
        this.produit.code =  this.produitForm.value.code;
        this.produit.nom =  this.produitForm.value.nom;
        this.produit.categorie =  this.produitForm.value.categorie;
        this.produit.mesure =  this.produitForm.value.mesure;
        this.produit.nomScientifique =  this.produitForm.value.nomScientifique;
        this.produit.varietes =  this.produitForm.value.varietes;
        this.produit.especes =  this.produitForm.value.especes;
        this.produit.actif =  this.produitForm.value.actif;
    }
    update(): void {
        if (this.produitForm.valid) {
        this.mapFormValuesToModel();
        this.produitService.update(this.produit.id, this.produit)
            .subscribe(
                data => {

                            this.toastr.success('Modification réussie', '',
                                {timeOut: 2000});
                            this.router.navigate(['/vertical/list-produit']);
                        }
                ,
                error => console.log(error));
        }

    }
    create(): void {
        if (this.produitForm.valid) {
            this.mapFormValuesToModel();
            this.produitService.create(this.produit)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie ' , '',
                            {timeOut: 2000});
                        this.produitForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
