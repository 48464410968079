import { OnInit } from '@angular/core';
var TCAvatarComponent = /** @class */ (function () {
    function TCAvatarComponent() {
        this.size = 36;
        this.loaded = false;
    }
    Object.defineProperty(TCAvatarComponent.prototype, "height", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "width", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "minWidth", {
        get: function () {
            return this.size + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "fontSize", {
        get: function () {
            return this.size / 2.4 + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TCAvatarComponent.prototype, "backgroundColor", {
        get: function () {
            if (this.initials) {
                return this.bgColor || '#805aff';
            }
        },
        enumerable: true,
        configurable: true
    });
    TCAvatarComponent.prototype.ngOnInit = function () { };
    TCAvatarComponent.prototype.imageLoaded = function () {
        this.initials = '';
        this.loaded = true;
    };
    return TCAvatarComponent;
}());
export { TCAvatarComponent };
