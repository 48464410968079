/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./form-group.component";
var styles_TCFormGroupComponent = [i0.styles];
var RenderType_TCFormGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TCFormGroupComponent, data: {} });
export { RenderType_TCFormGroupComponent as RenderType_TCFormGroupComponent };
export function View_TCFormGroupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0)], null, null); }
export function View_TCFormGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tc-form-group", [], [[2, "tc-form-group", null]], null, null, View_TCFormGroupComponent_0, RenderType_TCFormGroupComponent)), i1.ɵdid(1, 114688, null, 0, i2.TCFormGroupComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
var TCFormGroupComponentNgFactory = i1.ɵccf("tc-form-group", i2.TCFormGroupComponent, View_TCFormGroupComponent_Host_0, {}, {}, ["*"]);
export { TCFormGroupComponentNgFactory as TCFormGroupComponentNgFactory };
