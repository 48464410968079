import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BasePageComponent} from '../../base-page';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../interfaces/app-state';
import {HttpService} from '../../../services/http/http.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {fr_FR, NzI18nService, NzInputDirective} from 'ng-zorro-antd';

import {ValiderNoteMarcheService} from './validerNoteMarche.service';
import {fadeIn} from '../../../animations/form-error';
import {AuthenticationService} from '../../../services/sharedServices/authentication.service';
import {ExcelService} from '../../../services/sharedServices/excel-service.service';
import {Content} from '../../../ui/interfaces/modal';
import {TCModalService} from '../../../ui/services/modal/modal.service';

export interface INoteMarche {
    id: number;
    contenu: string;
    date: Date;
    dateExpiration: Date;
}


@Component({
  selector: 'tc-valider-info',
    templateUrl: './valider-noteMarche.component.html',
    styleUrls: ['./valider-noteMarche.component.scss'],
    animations: [fadeIn]
})

export class ValiderNoteMarcheComponent extends BasePageComponent implements OnInit, OnDestroy {

  dateValidation = new Date();
  data: any;
  causeRejet = '';
    notesMarcheAValider: INoteMarche[];
    outData: any[] = [];

    /******************/

    isAllDisplayDataChecked = false;
    isOperating = false;
    isIndeterminate = false;
    listOfDisplayData: INoteMarche[] = [];
    listOfAllDataNoteMarche: INoteMarche[] = [];
    mapOfCheckedId: { [key: string]: boolean } = {};
    numberOfChecked = 0;
    /******************************/
    valider() {
        this.outData = [];
        this.listOfAllDataNoteMarche.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
            this.outData.push(item);
            }
        });

        this.data = {data: {
            dateValidation: this.dateValidation.toLocaleDateString('en-US'),
            notesMarche: this.outData
        }};
        if (this.outData.length > 0) {
        this.validerNoteMarcheService.valider(this.data) .subscribe(
            data => {

                this.toastr.success('Validation réussie des notesMarche ', '',
                    {timeOut: 2000});
                this.loadNoteMarche();
            },
            error => console.log(error));
        } else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    }
  constructor(store: Store<IAppState>,
              httpSv: HttpService,
              private route: ActivatedRoute, private authService: AuthenticationService,
              public router: Router, private toastr: ToastrService,
              private fb: FormBuilder, private i18n: NzI18nService,
              private validerNoteMarcheService: ValiderNoteMarcheService, private modal: TCModalService
  ) {
    super(store, httpSv);
    this.pageData = {
      title: '',
      subTitle: '',
      loaded: true,
      breadcrumbs: [ ]
    };
   this.i18n.setLocale(fr_FR);
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadNoteMarche();
  }
    closeModal() {
        this.causeRejet = '';
        this.modal.close();
    }
    validerRejet() {

        if (this.causeRejet > '') {
            this.modal.close();
            this.rejeter();
        } else {
            this.toastr.error('Vous devez saisir une cause de rejet ');
        }
    }
    rejeter() {

        this.data = {data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                notesMarche: this.outData,
                causeRejet: this.causeRejet
            }};
        this.validerNoteMarcheService.rejeter(this.data) .subscribe(
            data => {

                this.toastr.success('rejet réussi des lignes d\'informations  sélectionnés', '',
                    {timeOut: 2000});
                this.loadNoteMarche();
            },
            error => console.log(error));

    }
    openModal<T>(body: Content<T>, header: Content<T> = null, footer: Content<T> = null, options: any = null) {
        this.outData = [];
        this.listOfAllDataNoteMarche.forEach(item => {
            if (this.mapOfCheckedId[item.id]) {
                this.outData.push(item);
            }
        });

        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        } else {
            this.toastr.info('Vous devez sélectionner au moins un ligne d\'information ');
        }
    }
    private loadNoteMarche() {
        this.listOfAllDataNoteMarche = [];
        const reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerNoteMarcheService.getAllByReseau(reseauId.toString()).subscribe(data => {
                this.notesMarcheAValider = data;
                this.notesMarcheAValider.forEach((info, index, array) => {

                    this.listOfAllDataNoteMarche.push({
                        id: info.id,
                        dateExpiration: info.dateExpiration,
                        contenu: info.contenu,
                        date: info.date
                    });
                });
            }
        );
        this.mapOfCheckedId = {};
        this.refreshStatus();

    }

    ngOnDestroy() {
    super.ngOnDestroy();
  }

    currentPageDataChange($event: INoteMarche[]): void {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    }

    refreshStatus(): void {
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(item => this.mapOfCheckedId[item.id]);
        this.isIndeterminate =
            this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) &&
            !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataNoteMarche.filter(item => this.mapOfCheckedId[item.id]).length;
    }

    checkAll(value: boolean): void {
        this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
        this.refreshStatus();
    }

    operateData(): void {
        this.isOperating = true;
        setTimeout(() => {
            this.listOfAllDataNoteMarche.forEach(item => (this.mapOfCheckedId[item.id] = false));
            this.refreshStatus();
            this.isOperating = false;
        }, 1000);
    }
    exportByExcel() {
        ExcelService.exportAsExcelFile(this.notesMarcheAValider, 'note_a_valider');
    }

}
