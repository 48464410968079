import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import { Router,  ActivatedRoute } from '@angular/router';
import { ProvinceService } from '../province.service';
import { ToastrService } from 'ngx-toastr';
import { IOption } from 'src/app/ui/interfaces/option';
import {Province} from '../../models/province';
import {fr_FR, NzI18nService} from 'ng-zorro-antd';
import {AbstractControl, FormBuilder,
    FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {fadeIn} from '../../../../animations/form-error';
import {RegionService} from '../../region/region.service';
import {Region} from '../../models/region';


@Component({
    selector: 'tc-province-create',
    templateUrl: './create-province.component.html',
    animations: [fadeIn]
})
export class PageCreateProvinceComponent extends BasePageComponent implements OnInit, OnDestroy {
    provinceForm: FormGroup;
    province: any;
    regions: Region[];
    regionsAdapter: IOption[] = [];
    selectedId: number;
    submitted = false;
    changes = false;
    Columnslabels =
        {
            code: 'Code Province',
            label: 'Libellé Province',
            longitude: 'Longitude',
            latitude: 'Latitude',
            region: 'Région'

        };
    constructor( store: Store<IAppState>,
                 httpSv: HttpService, private provinceService: ProvinceService,
                 private route: ActivatedRoute,
                 public router: Router, private toastr: ToastrService,
                 private regionService: RegionService,
                 private i18n: NzI18nService,
                 private fb: FormBuilder

    ) {
        super(store, httpSv);
        this.province = new Province();
        this.pageData = {
            title: '',
            loaded: true,
            breadcrumbs: [
                {
                    title: 'Liste des provinces',
                    route: 'list-province'
                }
            ]
        };
        this.initReactiveForm();
    }


    setLoaded(during: number = 0) {
        super.setLoaded(during);
        if (this.province) {
            this.setReactiveForm();
            this.pageData.title = this.getTitle();
        }

    }
    getTitle(): string {
        return  this.isEditing() ? ' Modifier une province ' : ' Créer une province ' ;
    }
    isEditing(): boolean {
        return (this.province.id != null);
    }



    initReactiveForm() {
        this.provinceForm = this.fb.group({
                code: ['', Validators.required],
                nom: ['', [Validators.required, Validators.minLength(2)]],
                region: ['', Validators.required],
                actif: [true]
            }
        ) ;
    }
    setReactiveForm() {
        if (this.province) {
            const region = (this.province.region != null) ? this.province.region.id.toString() : '';
            console.log('province,region, region.id', [this.province, this.province.region,this.province.id.toString()]);
            this.provinceForm.patchValue({
                    code: this.province.code,
                    nom: this.province.nom,
                    region: region,
                    actif: this.province.actif
                }
            );

        }
    }
    getProvince (jsonValue): Province {
        return Province.fromJSON(jsonValue) ;
    }
    ngOnInit() {
        super.ngOnInit();
        this.getData(this.regionService.getAllURL(), 'regions', 'onRegionsPromise');
        this.route.params.subscribe(params => {
            if (params['id']) {
                this.selectedId = params['id'];
                this.getDataByName(this.provinceService.getURL(this.selectedId), 'province', 'province', 'setLoaded');
            } else {
                this.province = new Province();
                this.initReactiveForm();
            }
        });

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
    ajouter() {
        this.router.navigate(['/vertical/create-province']);
    }
    liste() {
        this.router.navigate(['/vertical/list-province']);
    }
    mapFormValuesToModel() {
        this.province.code =  this.provinceForm.value.code;
        this.province.nom =  this.provinceForm.value.nom;
        this.province.region =  this.provinceForm.value.region;
        this.province.actif =  this.provinceForm.value.actif;
    }
    update(): void {
        if (this.provinceForm.valid) {
            this.mapFormValuesToModel();
            this.provinceService.update(this.province.id, this.province)
                .subscribe(
                    data => {

                        this.toastr.success('Modification réussie', '',
                            {timeOut: 2000});
                        this.router.navigate(['/vertical/list-province']);
                    }
                    ,
                    error => console.log(error));
        }

    }
    onRegionsPromise() {
        for (const region of this.regions) {
            this.regionsAdapter.push({'value' : region.id.toString(), 'label' : region.nom});
        }

    }
    create(): void {
        if (this.provinceForm.valid) {
            this.mapFormValuesToModel();
            this.provinceService.create(this.province)
                .subscribe(
                    data => {
                        this.toastr.success('Création réussie  ' , '',
                            {timeOut: 2000});
                        this.provinceForm.reset();
                    }
                    ,
                    error => console.log(error));
        }

    }
    onSubmit() {
        this.submitted = true;
        if (this.isEditing()) {
            this.update();
        } else {

            this.create();
        }
    }

}
