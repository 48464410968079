import {Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/services/http/http.service';
import { BasePageComponent } from 'src/app/pages/base-page';
import { IAppState } from 'src/app/interfaces/app-state';
import {ActivatedRoute, Route, Router} from '@angular/router';
import { AlerteStockService } from '../alerteStock.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import {ExcelService} from '../../../../services/sharedServices/excel-service.service';
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {fromEvent, merge} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {AlerteStockDatasource} from '../alerteStock.datasource';
import {Alerte} from '../../models/alerte';
import {INoeud} from '../../produit/create';


@Component({
  selector: 'tc-alerte-stock-index',
  templateUrl: './alerteStock-index.component.html',
 styleUrls: ['./alerteStock-index.component.scss']
})
export class PageIndexAlerteStockComponent extends BasePageComponent implements  OnInit, AfterViewInit, OnDestroy {
	rows: any[];
    productCount = 0;
    tree: INoeud[];
    dataSource: AlerteStockDatasource;
    sortField = 'libelle';
    pageIndex = 0;
    pageSize = 5;
    alerteType: string;
    // MatPaginator Output
    displayedColumns = ['libelle', 'joursEnvoi', 'actions'];
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

    @ViewChild(MatSort, { static: false }) sort: MatSort;

    @ViewChild('input', { static: false }) input: ElementRef;
   constructor( store: Store<IAppState>,
	httpSv: HttpService, private alerteStockService: AlerteStockService,
	public dialog: MatDialog,
    private router: Router, private route: ActivatedRoute
	) {
		super(store, httpSv);
  this.pageData = {
      subTitle: '' ,
      title: 'LISTE DES ALERTES DE STOCK',
      loaded: true,
      breadcrumbs: [

      ]
	};
	this.rows = [];
}


  ngOnInit() {
	   super.ngOnInit();
      this.alerteType = this.route.snapshot.data[0].type;
      this.dataSource = new AlerteStockDatasource(this.alerteStockService);
      this.alerteStockService.findCount(this.alerteType).subscribe(count => {
              this.productCount = count;

              if (sessionStorage.getItem('alerteStockPageIndex') != null) {
                  this.pageIndex = +sessionStorage.getItem('alerteStockPageIndex');
              } else {
                  this.pageIndex = 0 ;
              }
          if (sessionStorage.getItem('alerteStockPageSize') != null) {
              this.pageSize = (+sessionStorage.getItem('alerteStockPageSize') > 0) ? +sessionStorage.getItem('alerteStockPageSize') : 5;
          } else {
              this.pageSize = 5 ;
          }
          this.paginator.pageIndex = this.pageIndex ;
          this.paginator.pageSize = this.pageSize ;
          this.dataSource.loadAll('', this.alerteType, this.sortField, 'asc', this.pageIndex, this.pageSize);

          }
      );
      this.pageData.title = this.getTitle();
  }
    getTitle(): string {
        const prefixe = ' Liste des ' ;
        return prefixe + ' Alertes de Stock';

    }
    ngAfterViewInit() {

        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

        fromEvent(this.input.nativeElement, 'keyup')
            .pipe(
                debounceTime(150),
                distinctUntilChanged(),
                tap(() => {
                   this.paginator.pageIndex = 0;
                    this.loadPage();
                })
            )
            .subscribe();

        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
                tap(() => this.loadPage())
            ).subscribe();
        this.paginator.page.subscribe(
            (page) => {
                this.pageIndex = page.pageIndex;
                this.pageSize = page.pageSize;
                sessionStorage.setItem('alerteStockPageIndex', page.pageIndex) ;
                if (page.pageSize > 0) {
                sessionStorage.setItem('alerteStockPageSize', page.pageSize) ;
                }
            }

        );

    }

    loadPage() {
        this.dataSource.loadAll(
            this.input.nativeElement.value,
            this.alerteType,
            this.sortField,
            this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);
    }

    setLoaded(during: number = 0) {
       super.setLoaded(during);
     }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
  edit(id: any) {
   	   this.router.navigate(['/vertical/create-alerte-stock', { id: id, alerteType: this.alerteType}]);
  }
   ajouter() {
	  this.router.navigate(['/vertical/create-alerte-stock', { alerteType: this.alerteType}]);
  }
    exportByExcel() {
        ExcelService.exportAsExcelFile(Alerte.listFromJSON(this.rows), 'alerteStock');
    }
    exportAsXLSX() {
        this.getData(this.alerteStockService.getAllURL(), 'rows', 'exportByExcel');
    }
    getAlerte (jsonValue): Alerte {
        return Alerte.fromJSON(jsonValue) ;
    }


    delete(id: number) {
     const message = `Confirmez-vous la suppression de l'objet ?`;

    const dialogData = new ConfirmDialogModel('Confirmation', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '500px',
	  data: dialogData,
	  panelClass: 'customDialog'
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
	  if (dialogResult === true) {
	    this.alerteStockService.delete(id)
      .subscribe(
        data => {
            this.loadPage();
        },
		error => console.log(error));
	}
    });
  }
  }

