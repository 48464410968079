var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent } from '../../base-page';
import { Store } from '@ngrx/store';
import { HttpService } from '../../../services/http/http.service';
import { MarcheService } from '../marche/marche.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { fr_FR, NzI18nService } from 'ng-zorro-antd';
import { ValiderPrixService } from './validerPrix.service';
import { AuthenticationService } from '../../../services/sharedServices/authentication.service';
import { TCModalService } from '../../../ui/services/modal/modal.service';
import { ExcelService } from '../../../services/sharedServices/excel-service.service';
var ValiderPrixComponent = /** @class */ (function (_super) {
    __extends(ValiderPrixComponent, _super);
    function ValiderPrixComponent(store, httpSv, marcheService, route, authService, router, toastr, fb, i18n, validerPrixService, modal) {
        var _this = _super.call(this, store, httpSv) || this;
        _this.marcheService = marcheService;
        _this.route = route;
        _this.authService = authService;
        _this.router = router;
        _this.toastr = toastr;
        _this.fb = fb;
        _this.i18n = i18n;
        _this.validerPrixService = validerPrixService;
        _this.modal = modal;
        _this.mapOfExpandData = {};
        _this.causeRejet = '';
        _this.dateValidation = new Date();
        _this.outData = [];
        /******************/
        _this.isAllDisplayDataChecked = false;
        _this.isOperating = false;
        _this.isIndeterminate = false;
        _this.listOfDisplayData = [];
        _this.listOfAllDataPrice = [];
        _this.mapOfCheckedId = {};
        _this.numberOfChecked = 0;
        _this.pageData = {
            title: '',
            subTitle: '',
            loaded: true,
            breadcrumbs: []
        };
        _this.i18n.setLocale(fr_FR);
        return _this;
    }
    /******************************/
    ValiderPrixComponent.prototype.valider = function () {
        var _this = this;
        this.outData = [];
        this.listOfAllDataPrice.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                console.log('item', item);
                _this.outData.push(item);
            }
        });
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                prix: this.outData
            } };
        console.log('this.data', this.data);
        if (this.outData.length > 0) {
            this.validerPrixService.valider(this.data).subscribe(function (data) {
                _this.toastr.success('Validation réussie des prix selectionnés', '', { timeOut: 2000 });
                _this.loadPrice();
            }, function (error) { return console.log(error); });
        }
        else {
            this.toastr.warning('Vous devez sélectionner au moins une ligne');
        }
    };
    ValiderPrixComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.loadPrice();
    };
    ValiderPrixComponent.prototype.closeModal = function () {
        this.causeRejet = '';
        this.modal.close();
    };
    ValiderPrixComponent.prototype.validerRejet = function () {
        if (this.causeRejet > '') {
            this.modal.close();
            this.rejeter();
        }
        else {
            this.toastr.error('Vous devez saisir une cause de rejet ');
        }
    };
    ValiderPrixComponent.prototype.rejeter = function () {
        var _this = this;
        this.data = { data: {
                dateValidation: this.dateValidation.toLocaleDateString('en-US'),
                prix: this.outData,
                causeRejet: this.causeRejet
            } };
        this.validerPrixService.rejeter(this.data).subscribe(function (data) {
            _this.toastr.success('rejet réussi des lignes de prix  sélectionnés', '', { timeOut: 2000 });
            _this.loadPrice();
        }, function (error) { return console.log(error); });
    };
    ValiderPrixComponent.prototype.openModal = function (body, header, footer, options) {
        var _this = this;
        if (header === void 0) { header = null; }
        if (footer === void 0) { footer = null; }
        if (options === void 0) { options = null; }
        this.outData = [];
        this.listOfAllDataPrice.forEach(function (item) {
            if (_this.mapOfCheckedId[item.id]) {
                _this.outData.push(item);
            }
        });
        if (this.outData.length > 0) {
            this.modal.open({
                body: body,
                header: header,
                footer: footer,
                options: options
            });
        }
        else {
            this.toastr.info('Vous devez sélectionner au moins un ligne de prix ');
        }
    };
    ValiderPrixComponent.prototype.loadPrice = function () {
        var _this = this;
        this.listOfAllDataPrice = [];
        var reseauId = this.authService.currentUserDataObject.reseau.id;
        this.validerPrixService.getAllByReseau(reseauId.toString()).subscribe(function (data) {
            _this.prixAValider = data;
            _this.prixAValider.forEach(function (prix, index, array) {
                _this.listOfAllDataPrice.push({
                    latitude: prix.latitude,
                    longitude: prix.longitude,
                    typePrix: prix.typePrix,
                    expand: false,
                    note_marche: prix.note_marche,
                    id: prix.id,
                    date: prix.date,
                    typologie: prix.typologie,
                    nom_produit: prix.nom_produit,
                    nom_marche: prix.nom_marche,
                    prix: prix.prix,
                    mesure: prix.mesure,
                    statut: prix.statut,
                    enqueteur: prix.enqueteur,
                    numeroEnqueteur: prix.numeroEnqueteur,
                    reseau: prix.reseau,
                    observations: prix.observations
                });
            });
        });
        this.mapOfCheckedId = {};
        this.refreshStatus();
    };
    ValiderPrixComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    ValiderPrixComponent.prototype.currentPageDataChange = function ($event) {
        this.listOfDisplayData = $event;
        this.refreshStatus();
    };
    ValiderPrixComponent.prototype.refreshStatus = function () {
        var _this = this;
        this.isAllDisplayDataChecked = this.listOfDisplayData
            .every(function (item) { return _this.mapOfCheckedId[item.id]; });
        this.isIndeterminate =
            this.listOfDisplayData.some(function (item) { return _this.mapOfCheckedId[item.id]; }) &&
                !this.isAllDisplayDataChecked;
        this.numberOfChecked = this.listOfAllDataPrice.filter(function (item) { return _this.mapOfCheckedId[item.id]; }).length;
    };
    ValiderPrixComponent.prototype.checkAll = function (value) {
        var _this = this;
        this.listOfDisplayData.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = value); });
        this.refreshStatus();
    };
    ValiderPrixComponent.prototype.operateData = function () {
        var _this = this;
        this.isOperating = true;
        setTimeout(function () {
            _this.listOfAllDataPrice.forEach(function (item) { return (_this.mapOfCheckedId[item.id] = false); });
            _this.refreshStatus();
            _this.isOperating = false;
        }, 1000);
    };
    ValiderPrixComponent.prototype.exportByExcel = function () {
        ExcelService.exportAsExcelFile(this.prixAValider, 'prix_a_valider');
    };
    return ValiderPrixComponent;
}(BasePageComponent));
export { ValiderPrixComponent };
