import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeMesureService {
	private baseUrl = '/api/typeMesure';
  getAllURL(): string {
	  return `${this.baseUrl}`;
  }

}
