var Alerte = /** @class */ (function () {
    function Alerte() {
        this.actif = true;
        this.valide = false;
        this.produits = [];
        this.groupes = [];
        this.depots = [];
        this.marches = [];
    }
    Alerte.fromJSON = function (json) {
        var result = new Alerte();
        for (var key in json) {
            result[key] = json[key];
        }
        return result;
    };
    Alerte.listFromJSON = function (json) {
        var rval = [];
        var copyJson = JSON.parse(JSON.stringify(json));
        for (var key in copyJson) {
            if (copyJson.hasOwnProperty(key)) {
                var jsonObject = copyJson[key];
                var alertePrix = Alerte.fromJSON(jsonObject);
                jsonObject.produits = alertePrix.produitsText;
                jsonObject.marches = alertePrix.marchesText;
                jsonObject.groupes = alertePrix.groupesText;
                jsonObject.depots = alertePrix.depotText;
                rval.push(jsonObject);
            }
        }
        return rval;
    };
    Alerte.prototype.toString = function () {
        return this.libelle;
    };
    Object.defineProperty(Alerte.prototype, "produitsText", {
        get: function () {
            var rval = '';
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    rval += produit.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alerte.prototype, "groupesText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.groupes != null) {
                for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
                    var groupe = _a[_i];
                    rval += groupe.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alerte.prototype, "marchesText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.marches != null) {
                for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval += marche.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alerte.prototype, "depotText", {
        get: function () {
            var rval = '';
            // Array:
            if (this.depots != null) {
                for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval += depot.nom;
                    rval += '\n';
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alerte.prototype, "selectedProducts", {
        get: function () {
            var rval = [];
            if (this.produits != null) {
                for (var _i = 0, _a = this.produits; _i < _a.length; _i++) {
                    var produit = _a[_i];
                    rval.push(produit.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alerte.prototype, "selectedMarches", {
        get: function () {
            var rval = [];
            if (this.marches != null) {
                for (var _i = 0, _a = this.marches; _i < _a.length; _i++) {
                    var marche = _a[_i];
                    rval.push(marche.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alerte.prototype, "selectedDepots", {
        get: function () {
            var rval = [];
            if (this.depots != null) {
                for (var _i = 0, _a = this.depots; _i < _a.length; _i++) {
                    var depot = _a[_i];
                    rval.push(depot.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alerte.prototype, "selectedGroupes", {
        get: function () {
            var rval = [];
            if (this.groupes != null) {
                for (var _i = 0, _a = this.groupes; _i < _a.length; _i++) {
                    var groupe = _a[_i];
                    rval.push(groupe.id.toString());
                }
            }
            return rval;
        },
        enumerable: true,
        configurable: true
    });
    return Alerte;
}());
export { Alerte };
