import { HttpClient, HttpParams } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MonitorAlerteService = /** @class */ (function () {
    function MonitorAlerteService(http) {
        this.http = http;
        this.baseUrl = '/api/monitorJob';
    }
    // tslint:disable-next-line:max-line-length
    MonitorAlerteService.prototype.getTriggers = function (statut) {
        return this.http.get("" + this.baseUrl, {
            params: new HttpParams()
                .set('statut', statut)
        });
    };
    MonitorAlerteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MonitorAlerteService_Factory() { return new MonitorAlerteService(i0.ɵɵinject(i1.HttpClient)); }, token: MonitorAlerteService, providedIn: "root" });
    return MonitorAlerteService;
}());
export { MonitorAlerteService };
